import React, { useState } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';

import { KeyboardAwareScrollView } from '@platform'

import { SimpleText, ButtonText } from '@quarks'
import { AppStyle, TextStyle } from "@style";
import { useAuth } from '@realm'
import { CardInput, autoCompleteType, keyboardType, autoCapitalize, LoadingModal } from '@molecules';
import { Icons } from '@icons';
import { forgotPasswordFormValidation } from 'nexto-formvalidator';
import { useSelector } from 'react-redux';
import { Icon } from '@quarks'

const ForgotPasswordScreen = (props) => {

    const styles = updateStyles()
    const { loading, logOut, requestResetPassword } = useAuth()

    // In order to force a view update
    useSelector(state => state.session.language)

    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState({})
    const [formState, setFormState] = useState({
        email: props.route?.params?.email,
    })

    React.useEffect(() => {
        props.navigation.setOptions({
            headerTitle: '$access.forgotPassword.title'.localise(),
            headerBackTitle: "$access.login.title".localise(),
            headerTruncatedBackTitle: '$button.headerTruncatedBack'.localise(),
        })
    }, [props.navigation])

    const onChangeText = (name) => (text) => {
        let tmp = { ...formState }
        tmp[name] = text
        setFormState(tmp)
    }

    const onSend = async () => {

        let actualForm = {
            email: formState.email
        }
        const { formErrors, isValid } = forgotPasswordFormValidation(actualForm)
        if (isValid) {
            setErrors({})
            await requestResetPassword(formState.email)
            setSuccess(true)
            setTimeout(() => {
                props.navigation.goBack()
            }, 30000)
        } else {
            setErrors(formErrors)
        }
    }

    let inputScreen = (<View style={{
        width: '90%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        flex: 1,
        justifyContent: 'center'
    }}>
        <SimpleText style={styles.welcomeTitle}>
            $access.forgotPassword.mainTitle
            </SimpleText>
        <SimpleText style={styles.verificationText}>
            $access.forgotPassword.secondaryText
            </SimpleText>
        <CardInput
            title={'$access.forgotPassword.email'}
            style={{ marginBottom: 15, width: '100%' }}
            icon={Icons.ENVELOPE}
            onChangeText={onChangeText('email')}
            errorText={errors.email}
            inputText={formState['email']}
            multiline={false}
            autoCompleteType={autoCompleteType.email}
            keyboardType={keyboardType.emailAddress}
            placeholder={'$access.login.emailPlaceholder'}
            autoCapitalize={autoCapitalize.none}
            returnKeyType={'go'}
            onSubmitEditing={async () => await onSend()}
        />
        <SimpleText style={styles.secondaryText}>
            $access.forgotPassword.helperText
            </SimpleText>
        {errors.message &&
            <SimpleText style={styles.errorText}>
                {errors.message}
            </SimpleText>}
        <View style={{ width: '60%', maxWidth: 350 }}>
            <ButtonText
                onPress={async () => await onSend()}
                text={'$access.forgotPassword.loginButton'}
                type={'solid'}
                loading={loading}
                style={{
                    width: '100%',
                    height: 50,
                    marginTop: 15,
                }} />
        </View>
    </View>)

    let successView = (<View style={{
        width: '90%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        flex: 1,
        justifyContent: 'center'
    }}>
        <Icon
            name={Icons.SUCCESS}
            size={40}
            color={AppStyle.mainColor}
            style={{ textAlign: 'center' }} />
        <SimpleText style={styles.welcomeTitle}>
            $access.forgotPassword.successTitle
            </SimpleText>
        <SimpleText style={[styles.secondaryText, { paddingTop: 20, paddingBottom: 20 }]}>
            $access.forgotPassword.successSecondaryTitle
            </SimpleText>
        <View style={{ width: '70%', maxWidth: 350 }}>
            <ButtonText
                onPress={async () => {
                    props.navigation.goBack()
                }}
                text={'$access.forgotPassword.understood'}
                type={'solid'}
                style={{
                    width: '100%',
                    height: 50,
                    marginTop: 15,
                }} />
        </View>
        <SimpleText style={[styles.hintText, { paddingTop: 20 }]}>
            $access.forgotPassword.successHintText
        </SimpleText>
    </View>)

    return (<KeyboardAwareScrollView
        style={{
            flex: 1,
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor,
            paddingTop: 30
        }}
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', width: '100%' }}>
        <View style={styles.mainView}>
            {success && successView}
            {!success && inputScreen}
        </View>
    </KeyboardAwareScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    mainView: {
        width: '90%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        flex: 1,
        justifyContent: 'center'
    },
    welcomeTitle: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        paddingTop: 20,
        alignSelf: 'center',
        textAlign: 'center',
    },
    secondaryTitle: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    emailText: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        marginBottom: 20,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 20,
        paddingBottom: 10,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    secondaryText: {
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 10,
    },
    hintText: {
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
        textAlignVertical: 'bottom',
        color: AppStyle.textColorLight,
        paddingBottom: 25,
        position: 'absolute',
        bottom: 0
    },
    exitText: {
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 50,
    }
});

export default ForgotPasswordScreen;
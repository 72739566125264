import React, { useState, useRef, useCallback } from 'react';
import {
    TextInput,
    StyleSheet,
    View, Platform,
    Dimensions,
    LayoutAnimation,
    Pressable
} from 'react-native';
import { Icon } from '@quarks'

import { Card } from '@atoms'
import { ButtonText } from '@quarks'
import { Icons } from '@icons'
import { AppStyle, TextStyle } from '@style'

let config = LayoutAnimation.create(300, 'easeIn', 'opacity')

export const SearchBar = React.forwardRef((props, ref) => {

    let [isFocused, setIsFocused] = useState(false)

    let _onChangeText = (text) => {
        if (typeof props.onChangeText !== 'undefined') {
            props.onChangeText(text);
        }
        if (text.length === 0) {
            if (typeof props.onSubmit !== 'undefined') {
                props.onSubmit(text);
            }
        }
    }

    let _onSubmitEditing = (text) => {

        if (typeof props.onSubmit !== 'undefined') {
            props.onSubmit(text.nativeEvent);
        }
    }
    const viewWidth = Dimensions.get('window').width * 0.90
    const styles = updateStyles()

    const _updateFocus = (isFocused) => {

        if (isFocused) {
            _onFocus()
        } else {
            _onBlur()
        }
        setIsFocused(isFocused)

    }

    const _onBlur = () => {
        if (typeof props.onBlur !== 'undefined') {
            props.onBlur();
        }
    }

    const _onFocus = () => {
        if (typeof props.onFocus !== 'undefined') {
            props.onFocus();
        }
    }

    React.useEffect(() => {
        props.onMounted()
    }, [])

    React.useEffect(() => {
        if (typeof props?.value === 'undefined' || props?.value?.length == 0) {
            LayoutAnimation.configureNext(config);
            ref?.current.clear()
        }
    }, [props.value, ref])

    return (<View style={[{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'transparent', width: viewWidth }, props.style]}>
        <Pressable
            style={styles.card}
            height={40}>
            <Icon
                name={Icons.SEARCH}
                size={18}
                style={styles.searchIcon}
                color={AppStyle.placeHolderColor} />
            <TextInput
                ref={ref}
                onFocus={() => {
                    if (!isFocused) {
                        _updateFocus(true)
                        LayoutAnimation.configureNext(config);
                    }
                }}
                onBlur={() => _updateFocus(false)}
                onChangeText={_onChangeText}
                onSubmitEditing={_onSubmitEditing}
                clearButtonMode={'while-editing'}
                multiline={false}
                value={props.value}
                fontFamily={TextStyle.weight.bold.fontFamily}
                selectTextOnFocus
                style={styles.text}
                returnKeyType={'search'}
                enablesReturnKeyAutomatically={true}
                placeholder={props.searchHint}
                placeholderTextColor={AppStyle.placeHolderColor} />
        </Pressable>
        {((isFocused || props.value?.length > 0) || props.forceShowCancel) &&
            <ButtonText text={'$common.components.buttons.cancel'}
                textStyle={{ color: AppStyle.mainColor }}
                style={{ right: 0, marginLeft: 10, }} onPress={() => {
                    ref.current.blur()
                    ref.current.clear()
                    props.onCancelPress()
                    _updateFocus(false)
                }} />}
    </View>);


})
const updateStyles = () => StyleSheet.create({
    card: {
        backgroundColor: Platform.OS === 'android' ? AppStyle.getColor('light', 1) : `${AppStyle.getColor('light', 1)}`,
        borderRadius: 8,
        borderWidth: 0.5,
        borderColor: AppStyle.textColorLight,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    text: {
        ...TextStyle.weight.regular,
        ...TextStyle.size.small,
        color: AppStyle.textColor,
        width: '100%',
        alignSelf: 'center',
        position: 'absolute',
        paddingLeft: 35,
    },
    searchIcon: {
        position: 'absolute',
        color: AppStyle.mainColor,
        left: 10
    },
    innerCard: {
        flexDirection: 'row',
        width: '100%'
    },
    placeholder: {
        color: AppStyle.textColorInverted,
    },
});
import {
    StyleSheet, View, TouchableOpacity, Animated, PixelRatio
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';

import CachedImage from '@utilities/cachedImage';
import { SimpleText } from '@quarks'
import { TextStyle, AppStyle } from '@style';
import { createImageUrl, IMAGE_SIZES } from '@utilities/images'


export function ImageCard(props) {

    /**
     * props:
     *      imgKey: String?
     *      imgType: Object(IMAGE_SIZES)?
     *      image: String?
     *      resizeMode: enum('cover', 'contain', 'stretch', 'repeat', 'center')
     * 
     */
    let borderRadius = typeof props.borderRadius !== 'undefined' ? props.borderRadius : 10;
    const styles = updateStyles(borderRadius)
    const [defaultImage, setDefaultImage] = useState(null)
    let image = props.image

    if (props.srcImage) {
        image = props.srcImage
    } else {

        if (typeof image == 'string') {
            if (image.length > 24) {
                let find = image.match(/^ObjectID\("(?<id>.{24})"\)$/)
                if (typeof find?.groups?.id !== 'undefined') {
                    image = find.groups.id
                }
            }
            image = { uri: createImageUrl(image, (typeof props.imgType != 'undefined' ? props.imgType : IMAGE_SIZES.ORIGINAL)) }
        } else {
            if ( typeof image?.base64 !== 'undefined') {
                image = image
            } else {
                image = require('../../assets/images/app/noPicture.png')
            }
        }
    }

    const [scaleAnim, setScaleAnim] = useState(new Animated.Value(1));

    const _onPressIn = () => {
        if (props.onPress) {
            Animated.timing(scaleAnim, {
                toValue: 0.96,
                duration: 100,
                useNativeDriver: false,
            }).start();
        }
    };

    const _onPressOut = () => {
        if (props.onPress) {
            Animated.timing(scaleAnim, {
                toValue: 1,
                duration: 100,
                useNativeDriver: false,
            }).start();
        }
    };

    let getImage = (touchable) => {


        let imageStyles = [styles.image, props.imageStyle]

        if (!touchable) {
            imageStyles = [styles.image, props.style]
        }

        return (
            <CachedImage
                style={imageStyles}
                isBackground={true}
                imageStyle={{borderRadius:borderRadius}}
                resizeMode={props.resizeMode ? props.resizeMode : 'cover'}
                source={defaultImage ? defaultImage: image}
                blurRadious={4}>
                {props.text &&
                    <LinearGradient colors={['transparent', props.gradientColor ? props.gradientColor : 'black']} style={styles.gradientView}>
                        {typeof props.subtitle !== 'undefined' && <SimpleText style={[styles.subtitle, props.subtitleStyle, {
                            fontSize: props.subtitleFontSize ? props.subtitleFontSize : TextStyle.size.medium.fontSize
                        }]}>
                            {props.subtitle}
                        </SimpleText>}
                        <SimpleText style={[styles.text, props.textStyle, {
                            fontSize: props.fontSize ? props.fontSize : TextStyle.size.xlarge.fontSize
                        }]}>
                            {props.text}
                        </SimpleText>
                    </LinearGradient>
                }
                {props.children}
            </CachedImage>)
    }

    if (typeof props.onPress != 'undefined') {
        return (
            <Animated.View style={[{ flex: 1, transform: [{ scale: scaleAnim }] }, props.style]}>
                <TouchableOpacity
                    style={{ width: '100%', height: '100%' }}
                    activeOpacity={1}
                    onPressIn={_onPressIn}
                    onPressOut={_onPressOut}
                    onPress={props.onPress}>
                    {getImage(true)}
                </TouchableOpacity>
            </Animated.View>
        )

    } else {
        return (getImage(false))
    }
}

let updateStyles = (borderRadius) => {

    return StyleSheet.create({
        image: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            flex: 1
        },
        gradientView: {
            width: '100%',
            borderRadius: borderRadius,
            justifyContent: 'flex-end',
            height: '60%'
        },
        text: {
            ...TextStyle.size.xlarge,
            ...TextStyle.weight.bold,
            padding: 10,
            paddingBottom: 5,
            color: AppStyle.getColor('light', 1),
            shadowOffset: {
                width: 0,
                height: 0
            },
            shadowOpacity: 0.3,
            shadowRadius: 10,
            elevation: 5,
        },
        subtitle: {
            color: AppStyle.getColor('light', 1),
            paddingLeft: 10,
            marginBottom: -10,
            paddingTop: 10
        },
    });
}
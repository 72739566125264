import { gql } from '@apollo/client';
import { getTranslationQuery } from '@utilities/locale'

const getByUserId = (offset = 0, limit = 10, active = true) => {
    return {
        query: gql`
            query ($offset: Int, $limit: Int, $active: Boolean) {
                OrdersUser (input: {limit: $limit, offset: $offset, active: $active}) {
                    _id
                    total
                    units
                    producer
                    name
                    aggregatedId
                    status
                    dates {
                        created
                    }
                }
            }`,
        param: {
            variables: { offset: offset, limit: limit, active: active }
        }
    }
}

const getById = (id) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                order (query: {_id: $id}) {
                    _id
                    status
                    aggregatedId
                    products {
                        ${getTranslationQuery('name', false)}
                        amount
                        amountUnit
                        price
                        units
                        priceUnit
                        packUnits
                    }
                    userId
                    contact {
                        phone {
                            number
                            countryCode
                        }
                        email
                    }
                    direction {
                        location {
                            street {
                                flat
                                name
                            }
                            admin_name2
                            coordinates
                            place_name
                            postal_code
                        }
                        name
                        note
                        phone {
                            countryCode
                            number
                        }
                    }
                    pickup {
                        location {
                            street
                            admin_name2
                            coordinates
                            place_name
                            postal_code
                        }
                        days {
                            day
                            times {
                                start
                                end
                            }
                        }
                    }
                    zone {
                        modality{
                            personal {
                                days {
                                    day
                                    startTime
                                    endTime
                                }
                            }
                            external {
                                time
                            }
                        }
                    }
                    tracking {
                        external {
                            sendingTime
                            company
                            trackingId
                        }
                        personal {
                            timeRange {
                                start
                                end
                            }
                            date
                            message
                            phone {
                                number
                                countryCode
                            }
                        }
                    }
                    cancel {
                        code
                        message
                    }
                    dates {
                        created
                        preparing
                        transit
                        delivered
                        dispute
                        canceled
                    }
                    dispute {
                        message
                        code
                    }
                    delivery
                    messages {
                        user
                        text
                        date
                    }
                }
            }`,
        param: { variables: { id: id } }
    }
}

const getByIdProducer = (id) => {
    return getById(id)
}

const getAllByProducer = (selectedDate, offset = 0, limit = 5) => {
    return {
        query: gql`
            query ($offset: Int, $limit: Int, $selectedDate: DateTime) {
                OrdersProducer (input: {limit: $limit, offset: $offset, selectedDate: $selectedDate}){
                    _id
                    aggregatedId
                    status
                    units
                    total
                    dates {
                        created
                    }
                    products {
                        ${getTranslationQuery('name', false)}
                        amount
                        amountUnit
                        price
                        units
                        priceUnit
                        packUnits
                    }
                    direction {
                        location {
                            place_name
                        }
                    }
                }
            }`,
        param: {
            variables: { offset: offset, limit: limit, selectedDate: selectedDate }
        },
        name: 'Order-getAllByProducer'
    }
}

const getSectionsByProducerId = (offset = 0, limit = 5) => {
    return {
        query: gql`
            query ($offset: Int, $limit: Int) {
                MainOrdersProducer(input: {limit: $limit, offset: $offset}) {
                    _id
                    status
                    aggregatedId
                    units
                    total
                    direction {
                        location {
                            place_name
                            admin_name2
                        }
                    }
                    pickup {
                        id
                    }
                    dispute {
                        code
                    }
                    dates {
                        created
                        dispute
                    }
                }
            }`,
        param: {
            variables: { offset: offset, limit: limit },
            fetchPolicy: 'cache-and-network'
        },
        name: 'Order-getSectionsByProducerId'
    }
}

const getTypenames = () => {
    return ['Order', 'ProductsProducerDispute']
}


export default {
    getByUserId,
    getById,
    getByIdProducer,
    getSectionsByProducerId,
    getAllByProducer,
    getTypenames
}
import React, { useState } from 'react';
import { View, LayoutAnimation, Keyboard, Switch, Platform, UIManager, StyleSheet, SafeAreaView, ScrollView, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useAuth } from '@realm'

import { KeyboardAwareScrollView, Realm, Alert } from '@platform'

import { productFormValidationErrors, productFormReducedValidationErrors, productFormValidationDependencies } from 'nexto-formvalidator'
import * as responseCodes from 'nexto-codes'

import { translationsAwareObjectDiff, removeField } from 'nexto-utils/objects'

import { AppStyle, TextStyle } from '@style'
import { ImageCard, Card, FloatingButton, LoadingView, ErrorView, SuccessView, ContentBorder } from '@atoms'
import { keyboardType } from '@atoms/inputIcon'
import { CardInput, OptionsPickerModal, CustomizableCardGrid, FeedbackModal } from '@molecules'
import { ButtonText, SimpleText } from '@quarks'
import { ImageUploader } from '@organisms'
import { Icons, NadiusIcons } from '@icons'
import { ALLERGENS } from '_enums'
import { productFormOperations } from '_ducks/productForm'
import { Category, Label, Product } from '@api'
import { replaceLocale, getTranslation } from '@utilities/locale'
import { sessionOperations } from '_ducks/session';
import { submitForm } from 'redux/ducks/market/actions';

const config = LayoutAnimation.create(200, 'linear', 'opacity')

if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }
}
const ProductFormScreen = (props) => {

    const dispatch = useDispatch()
    const getCategories = Category.getCategories()
    const getLabels = Label.getLabels()
    const updateOperation = Product.updateProduct()
    const insertOperation = Product.insertProduct()

    const reduxCategory = useSelector(state => state.session?.queries?.[getCategories.name])
    const reduxLabel = useSelector(state => state.session?.queries?.[getLabels.name])

    const _updateError = (result) => {

        if (result.message == `Cannot query field "productUpdate" on type "Mutation". Did you mean "productInsert"?`) {
            //This error happens sporadically when productUpdate mutation is used
            //We will force another update until it works
            setTimeout(() => {
                onSubmit({ refetch: false })
            }, 500)
            return;
        }


        if ('productUpdate' in result) {
            result = result.productUpdate
        } else if ('productInsert' in result) {
            result = result.productInsert
        } else if (!('status' in result)) {
            result = { status: responseCodes.product.RESPONSE_CODES.UNKNOWN }
        }

        switch (result.status) {
            case responseCodes.product.RESPONSE_CODES.OK:
                setFormStatus({ ...formStatus, loading: false, error: false, completed: true, completedText: '$done' })
                setTimeout(() => {
                    props.navigation.navigate('Products', { refetch: true });
                }, 1000)
                break;
            case responseCodes.product.RESPONSE_CODES.ERROR:
                const errors = typeof result.formErrors === 'object' ? result.formErrors : JSON.parse(result.formErrors)
                setErrorForm({
                    labels: [],
                    allergens: [],
                    translations: formErrors,
                    images: {
                        list: []
                    },
                    ...errors
                })
                setFormStatus({ ...formStatus, error: true, loading: false, errorText: '$productForm.errorInDataDesc' })//$productForm.errorInData


                break;

            case responseCodes.product.RESPONSE_CODES.AUTH_ERROR:

                setFormStatus({ ...formStatus, error: true, loading: false, errorText: "$productForm.authError" })//$"$productForm.errorInData"

                break;
            case responseCodes.product.RESPONSE_CODES.UNKNOWN:
            default:
                setFormStatus({ ...formStatus, error: true, loading: false, errorText: "$common.errors.unknown" })//$"$productForm.errorInData"

                break;
        }
    }
    const _updateCompleted = (response) => {
        //Update cache
        _updateError(response)
    }

    const maxTimeQueries = 3 * 60

    const [updateProduct] = useMutation(updateOperation.mutation, { onCompleted: _updateCompleted, onError: _updateError })
    const [insertProduct] = useMutation(insertOperation.mutation, { onCompleted: _updateCompleted, onError: _updateError })

    const _onCompleted = (name) => () => {
        dispatch(sessionOperations.storeQuery(name, maxTimeQueries))
    }

    const categories = useQuery(getCategories.query, {
        onCompleted: _onCompleted(getCategories.name)
    });
    const labels = useQuery(getLabels.query, {
        onCompleted: _onCompleted(getLabels.name)
    });
    const isNew = typeof props.route.params.id === 'undefined' || props.route.params.id === 'new'
    React.useEffect(() => {
        if (!categories.loading && categories.called && ((reduxCategory && reduxCategory.needsRefetch) ||
            typeof reduxCategory === 'undefined')) {
            dispatch(sessionOperations.storeQuery(getCategories.name, maxTimeQueries))
            categories.refetch()
        }
    }, [reduxCategory, categories.loading, categories.called])

    React.useEffect(() => {
        if (!labels.loading && labels.called && ((reduxLabel && reduxLabel.needsRefetch) ||
            typeof reduxLabel === 'undefined')) {
            dispatch(sessionOperations.storeQuery(getLabels.name, maxTimeQueries))
            labels.refetch()
        }
    }, [reduxLabel, labels.loading, labels.called])

    const { user, callFunction } = useAuth();

    let product, translations = ['es', 'ca'];

    const defaultWeightUnit = 'G'
    const defaultAmountUnit = 'G'
    const defaultPriceUnit = 'U'
    let defaultCategory = undefined
    const defaultPriceType = 'DEFAULT'
    const defaultAmountType = 'EXACT'


    let formLanguages = []
    let formErrors = []
    if (typeof producerLocales !== 'undefined') {
        for (let lang of producerLocales) {
            formLanguages[lang] = { name: undefined, description: undefined }
            formErrors[lang] = { name: undefined, description: undefined };
        }
    }

    const [categoriesOptions, setCategoriesOptions] = useState([{ value: undefined, label: "$productForm.defaultCategoryPlaceholder".localise() }])
    const [subcategoriesOptions, setSubcategoriesOptions] = useState([])
    let labelsOptions = []
    let allergensOptions = []


    if (typeof product === 'undefined') {
        product = {
            price: null,
            priceUnit: defaultPriceUnit,
            packUnits: null,
            amount: null,
            amountUnit: defaultAmountUnit,
            weight: null,
            weightUnit: defaultWeightUnit,
            category: defaultCategory,
            priceType: defaultPriceType,
            amountType: defaultAmountType,
            stock: null,
            isDeleted: false,
            isDisabled: false,
            ownMade: true,
            labels: [],
            allergens: [],
            translations: formLanguages,
            images: {
                stared: null,
                list: []
            },
            placeholderImages: {}
        }
    }

    let [formStatus, setFormStatus] = useState({ loading: false, error: false, completed: false })

    let [errorForm, setErrorForm] = useState(
        {
            price: undefined,
            priceUnit: undefined,
            packUnits: undefined,
            amount: undefined,
            amountUnit: undefined,
            weight: undefined,
            weightUnit: undefined,
            category: undefined,
            stock: undefined,
            isDeleted: false,
            labels: [],
            allergens: [],
            isDisabled: false,
            translations: formErrors,
            images: {
                list: []
            }
        }
    )

    let [productForm, setProductForm] = useState(product)
    let [loading, setLoading] = useState(true)

    React.useEffect(() => {
        let name = getTranslation(productForm)?.name
        props.navigation.setOptions({
            headerTitle: loading ? '' : (typeof name == 'undefined' ? '$productForm.headerTitleNew'.localise() : name),
            headerBackTitle: '$products.title'.localise(),
            headerTruncatedBackTitle: '$button.headerTruncatedBack'.localise()
        })
    }, [props.navigation, productForm, loading])

    const productQuery = Product.getByIdProducer(props.route.params?.id)
    let [producerLocales, setProducerLocales] = useState(translations)

    const _completedProd = (data) => {
        if (typeof data?.product !== 'undefined') {
            let product = JSON.parse(JSON.stringify(data.product))

            let translations = Object.keys(product.translations).filter((value) =>
                product.translations[value] !== null &&
                typeof product.translations[value] !== 'undefined' &&
                value !== '__typename' &&
                !product.translations[value]?.isDeleted)

            setProducerLocales(translations)

            let formProduct = Object.assign(
                {
                    isDeleted: false,
                    isDisabled: false,
                    category: defaultCategory,
                    priceType: defaultPriceType,
                    amountType: defaultAmountType,
                    weightUnit: defaultWeightUnit,
                    labels: [],
                    allergens: [],
                    placeholderImages: {}

                },
                product)
            setProductForm(formProduct)
            setLoading(false)
        }
        _onCompleted(productQuery.name)
    }

    const [getProduct, productResult] = useLazyQuery(productQuery.query, {
        onCompleted: _completedProd
    });

    React.useEffect(() => {
        try {
            let objId = new Realm.BSON.ObjectId(props.route.params.id)
            if (!productResult.called) {
                getProduct()
            } else if (productResult.called && loading) {
                _completedProd(productResult.data)
            }
        } catch (error) {
            console.log('New ', error)
            setLoading(false)
        }
    }, [props.route.params, getProduct, loading, productResult])

    const fillSubcategories = (value) => {
        let subcategories = [{ value: undefined, label: "$productForm.defaultSubcategoryPlaceholder".localise() }]
        for (let cat of categories.data.categories) {
            if (value == cat._id) {
                if (cat.subcategories != null && typeof cat.subcategories != 'undefined') {
                    for (let subcat of cat.subcategories) {
                        let result = getTranslation(subcat)
                        if (typeof result !== 'undefined') {
                            subcategories.push({ value: subcat._id, label: result.name })
                        }
                    }
                }
            }
        }

        LayoutAnimation.configureNext(config);
        if (subcategories.length > 1) {
            setSubcategoriesOptions(subcategories)
        } else {
            setSubcategoriesOptions([])
        }
        return subcategories.length > 1

    }

    let _onValueChange = (field, options = {}) => (value) => {
        let tmp = { ...productForm }
        if (field == 'translations') {
            if (!tmp[field][options.index]) {
                tmp[field][options.index] = {}
            }
            tmp[field][options.index][options.subfield] = value
        } else if (field == 'parentCategory') {
            let hasSubCategories = fillSubcategories(value)
            if (!hasSubCategories) {
                tmp['category'] = value
            }
            tmp[field] = value

        } else {
            tmp[field] = value
        }
        setProductForm(tmp)
    }

    for (let code in ALLERGENS) {
        let allergen = ALLERGENS[code]
        let result = getTranslation(allergen)
        if (typeof result !== 'undefined') {
            allergensOptions.push({ key: allergen.code, state: productForm.allergens.findIndex(value => allergen.code == value) != -1, name: result.name, })
        }
    }

    if (!labels.loading && !labels.error) {

        for (let label of labels.data.labels) {
            let result = getTranslation(label)
            if (typeof result !== 'undefined') {
                labelsOptions.push({ key: label._id, state: productForm.labels.findIndex(value => label._id.toString() == value) != -1, name: result.name, })
            }
        }

    }

    if (categoriesOptions?.length <= 1 && !categories.loading && !categories.error) {
        let notFound = true;
        let categoriesOpt = [...categoriesOptions]
        for (let cat of categories.data.categories) {
            let result = getTranslation(cat)
            if (typeof result !== 'undefined') {
                categoriesOpt.push({ value: cat._id, label: result.name })
            }
            if (notFound &&
                cat.subcategories?.length > 0 &&
                typeof productForm?.category != 'undefined' &&
                typeof productForm.parentCategory == 'undefined') {
                for (let subcat of cat.subcategories) {
                    if (subcat._id == productForm.category) {
                        notFound = false
                        _onValueChange('parentCategory')(cat._id)
                        break;
                    }
                }
            }
        }
        setCategoriesOptions(categoriesOpt)

    }

    let [helpStatus, setHelpStatus] = useState({
        price: false,
        amount: false,
        packUnits: false
    })


    let [isLocaleModalVisible, setLocaleModalVisible] = useState(false)
    let [isLabelsModalVisible, setLabelsModalVisible] = useState(false)
    let [isAllergensModalVisible, setAllergensModalVisible] = useState(false)

    const priceRef = React.useRef(null)
    const descRef = [React.useRef(null), React.useRef(null), React.useRef(null)]
    let imageUploaderRef = React.useRef(null)

    let displayedImages = productForm.images.list;
    displayedImages = displayedImages.concat(Object.values(productForm.placeholderImages))

    const styles = updateStyles()

    const onSubmit = async (operation = {}) => {

        let result = {}, editedProduct = {};

        if (formStatus.loading && typeof operation?.refetch === 'undefined') {
            return
        }

        setFormStatus({ ...formStatus, loading: true })

        if (operation.delete === true) {
            result = await callFunction('PRODUCER_deleteProduct', productForm._id)
            _updateCompleted(result)
        } else {
            console.log('submit1 !')

            editedProduct = { ...productForm, parentCategory: undefined }

            if (typeof editedProduct.price === 'string')
                editedProduct.price = parseFloat(editedProduct.price.replace(",", ".")).toFixed(2)
            if (typeof editedProduct.salePrice === 'string')
                editedProduct.salePrice = parseFloat(editedProduct.salePrice.replace(",", ".")).toFixed(2)
            if (typeof editedProduct.amount === 'string')
                editedProduct.amount = parseFloat(editedProduct.amount.replace(",", "."))
            if (typeof editedProduct.amountMax === 'string')
                editedProduct.amountMax = parseFloat(editedProduct.amountMax.replace(",", "."))
            if (typeof editedProduct.packUnits === 'string')
                editedProduct.packUnits = parseFloat(editedProduct.packUnits.replace(",", "."))
            if (typeof editedProduct.packUnits === 'string')
                editedProduct.packUnits = parseFloat(editedProduct.packUnits.replace(",", "."))
            if (typeof editedProduct.weight === 'string')
                editedProduct.weight = parseFloat(editedProduct.weight.replace(",", "."))


            try {
                let auxLang = {}
                for (let [lang, value] of Object.entries(editedProduct.translations)) {
                    if (lang != "__typename" && value != null && !value?.isDeleted) {
                        auxLang[lang] = {
                            name: value.name,
                            description: value.description,
                        }
                    }
                }
                editedProduct.translations = auxLang
                let formState
                let submitForm
                if (isNew) {
                    formState = productFormValidationErrors(editedProduct)

                    submitForm = editedProduct

                    if (formState.isValid) {

                        removeField(submitForm, '__typename')
                        insertProduct(insertOperation.makeParam(submitForm))

                    } else {
                        console.log('form state26 ', formState)
                        _updateError({
                            status: responseCodes.product.RESPONSE_CODES.ERROR,
                            formErrors: formState.formErrors
                        })
                    }

                } else {

                    let changes = translationsAwareObjectDiff(productResult.data?.product, editedProduct, ['placeholderImages'], [], productFormValidationDependencies)

                    if (changes.isEqual && !changes.translateDeleted) {

                        setFormStatus({ ...formStatus, loading: false })
                        return props.navigation.goBack()
                    }


                    formState = productFormReducedValidationErrors(changes.diff)

                    if (formState.isValid) {

                        if (changes.translateDeleted) {
                            changes.diff['translations'] = Object.assign({}, editedProduct['translations'])
                        }
                        submitForm = changes.diff
                        removeField(submitForm, '__typename')
                        submitForm._id = productForm._id
                        console.log('submit124 !', submitForm)
                        updateProduct(updateOperation.makeParam(submitForm))

                    } else {
                        _updateError({
                            status: responseCodes.product.RESPONSE_CODES.ERROR,
                            formErrors: formState.formErrors
                        })
                    }
                }

            } catch (error) {
                console.warn(error)
                _updateError({
                    status: responseCodes.product.RESPONSE_CODES.UNKNOWN
                })
            }
        }
    }

    React.useEffect(() => {
        // On exit the view, set modal visible to false in order to prevent opening it again.
        const unsubscribeBlur = props.navigation.addListener('blur', () => {
            if (formStatus.loading)
                setFormStatus({ ...formStatus, loading: false })
            dispatch(productFormOperations.resetForm())
        });
        return unsubscribeBlur;
    }, [props.navigation]);

    let _deleteProduct = () => {
        Alert(dispatch).alert(
            '$productForm.deleteProductAlertTitle'.localise(),
            "$productForm.deleteProductAlertDesc".localise(),
            [
                {
                    text: '$button.cancel'.localise(),
                    style: 'cancel',
                },
                {
                    text: '$button.delete'.localise(),
                    onPress: () => {
                        onSubmit({ delete: true })
                    }
                },
            ],
            { cancelable: true },
        );
    }

    const _placeHolderImageUpdate = (placeholderId, image) => {

        let tmp = { ...productForm }

        if (image?.error || image?.loading) {
            tmp.placeholderImages[placeholderId] = image
        } else {
            delete tmp.placeholderImages[placeholderId]
            tmp.images.list.push(image)
        }
        setProductForm(tmp)
    }

    const _imageUploaded = (placeholderId, image) => {

        let tmp = { ...productForm }

        delete tmp.placeholderImages[placeholderId]
        tmp.images.list.push(image)
        setProductForm(tmp)
    }

    const _imageDelete = (placeholderId, image) => {

        let tmp = { ...productForm }

        let i = tmp.images.list.indexOf(image)
        if (i != -1) {
            tmp.images.list.splice(i, 1)
        } else {
            if (typeof tmp.placeholderImages[placeholderId] != 'undefined')
                delete tmp.placeholderImages[placeholderId]
        }
        LayoutAnimation.configureNext(config);
        setProductForm(tmp)
    }

    const _renderLabelItem = ({ item, index }) => {

        return <Card style={{
            backgroundColor: AppStyle.cardBackgroundInverted,
            maxWidth: '100%', width: '100%', padding: 15, paddingTop: 5, paddingBottom: 5
        }} >
            <SimpleText style={{ color: AppStyle.textColorInverted }}>{searchArray(labelsOptions, 'key', item).name} </SimpleText>
        </Card>
    }

    const _renderAllergenItem = ({ item, index }) => {
        return <Card style={{
            backgroundColor: AppStyle.cardBackgroundInverted,
            maxWidth: '100%', width: '100%', padding: 15, paddingTop: 5, paddingBottom: 5
        }} >
            <SimpleText style={{ color: AppStyle.textColorInverted }}>{getTranslation(ALLERGENS[item])?.name} </SimpleText>
        </Card>
    }

    let _focusInput = (field, state) => {
        let aux = {
            price: false,
            amount: false,
            packUnits: false
        }

        aux[field] = state;
        setHelpStatus(aux)
    }

    const _renderLocalesContent = (locales) => {

        if (typeof locales === 'undefined') return

        let elements = []

        let ourLocales = ["es", "ca"].filter(element => element != user.locale)
        ourLocales = [user.locale, ...ourLocales]

        ourLocales.forEach((item, index) => {
            if (locales.indexOf(item) != -1) {
                elements.push(<ContentBorder
                    key={index}
                    style={{
                        borderRadius: 5,
                        color: AppStyle.textColorLight, flex: 1,
                        padding: 10, margin: 5, minWidth: 350
                    }}
                    title={`$locale.${item}`}>
                    <CardInput
                        style={[styles.cardInput]}
                        title={'$productForm.name'}
                        locale={item}
                        icon={Icons.TAG}
                        onChangeText={_onValueChange('translations', { index: item, subfield: 'name' })}
                        keyboardType={keyboardType.default}
                        autoCompleteType={undefined}
                        inputText={productForm.translations ? productForm.translations[item]?.name : undefined}
                        placeholder={'$productForm.namePlaceholder.' + item}
                        errorText={errorForm.translations[item]?.name}
                        infoText={'$productForm.nameDesc'}
                        returnKeyType={'next'}
                        onSubmitEditing={() => {
                            descRef[index].current.focus()
                        }}
                    />
                    <CardInput
                        style={styles.cardInput}
                        ref={descRef[index]}
                        title={'$productForm.description'}
                        minHeight={150}
                        maxHeight={200}
                        textStyle={{ padding: 5 }}
                        icon={Icons.DESCRIPTION}
                        onChangeText={_onValueChange('translations', { index: item, subfield: 'description' })}
                        keyboardType={keyboardType.default}
                        multiline
                        autoCompleteType={undefined}
                        inputText={productForm.translations ? productForm.translations[item]?.description : undefined}
                        placeholder={'$productForm.descriptionPlaceholder.' + item}
                        errorText={errorForm.translations[item]?.description}
                        infoText={'$productForm.descriptionDesc'}
                        returnKeyType={'next'}
                    />
                </ContentBorder>
                )
            }
        })

        return elements
    }

    const _addOrRemoveLocale = ({ key, state }) => {

        let tmp = { ...productForm }
        let plTmp = [...producerLocales]


        if (state) {
            let i = plTmp.findIndex(value => key == value)
            if (i == -1) {
                plTmp.push(key)
            }

            if (typeof tmp.translations[key] === 'undefined' || tmp.translations[key] == null) {
                tmp.translations[key] = { name: undefined, description: undefined, isDeleted: false }
            } else {
                tmp.translations[key].isDeleted = false
            }


        } else {
            let i = plTmp.findIndex(value => key == value)

            if (i != -1)
                plTmp.splice(i, 1)

            if (typeof tmp.translations[key] !== 'undefined' && tmp.translations[key] !== null) {
                tmp.translations[key].isDeleted = true
            }

        }

        setProducerLocales(plTmp)
        setProductForm(tmp)
        LayoutAnimation.configureNext(config);

    }

    const searchArray = (array, attribute, value) => {

        for (let obj of array) {
            if (obj[attribute] == value)
                return obj
        }

        return {}

    }

    const _addOrRemoveLabel = ({ key, state }) => {

        let tmp = { ...productForm }

        if (state) {
            tmp.labels.push(key)
        } else {
            let i = tmp.labels.findIndex(value => key == value)
            if (i != -1)
                tmp.labels.splice(i, 1)
        }

        LayoutAnimation.configureNext(config);
        setProductForm(tmp)

    }

    const _addOrRemoveAllergen = ({ key, state }) => {

        let tmp = { ...productForm }

        if (state) {
            tmp.allergens.push(key)
        } else {
            let i = tmp.allergens.findIndex(value => key == value)
            if (i != -1)
                tmp.allergens.splice(i, 1)
        }

        LayoutAnimation.configureNext(config);
        setProductForm(tmp)
    }


    const _showError = (message) => {
        if (message) {
            return <SimpleText style={styles.errorText}>
                {message}
            </SimpleText>
        }
        return null
    }

    const _showHelp = (show, message) => {
        if (show) {
            return <SimpleText style={styles.infoText}>
                {message}
            </SimpleText>
        }
        return null
    }

    return (
        <>
            <ScrollView>
                {(productResult.loading || loading) && <LoadingView></LoadingView>}
                {!(productResult.loading || loading) && <KeyboardAwareScrollView
                    style={{
                        width: '100%',
                        alignSelf: 'center',
                        backgroundColor: AppStyle.backgroundColor
                    }}
                    keyboardShouldPersistTaps='handled'
                    contentContainerStyle={{ width: '100%', alignSelf: 'center' }}>
                    <View style={{ width: "90%", height: "100%", maxWidth: 850, alignSelf: 'center', backgroundColor: 'white' }}>

                        <ContentBorder
                            separator
                            titleViewStyle={[styles.contentBorderStyle, { marginTop: 30 }]}
                            headerButtonText={'$button.addOrRemoveTranslation'}
                            icon={NadiusIcons.MORE}
                            iconSet={NadiusIcons.iconSet}
                            headerButtonOnPress={() => setLocaleModalVisible(true)}
                            contentStyle={[styles.contentBorderStyle, { zIndex: 0, paddingTop: 10, maxWidth: '90%' }]}
                            title={`$productForm.nameDescSection`}>
                            <View style={{
                                justifyContent: 'center',
                                flexDirection: 'row',
                                flexWrap: 'wrap', maxWidth: 800,
                                alignSelf: 'center',
                                alignItems: 'flex-start',
                                flex: 1,
                            }}>
                                {_renderLocalesContent(producerLocales)}
                            </View>
                            <SimpleText style={{
                                ...TextStyle.size.small,
                                color: AppStyle.textColorLight, alignSelf: 'center', width: '100%', textAlign: 'center'
                            }}>$productForm.languagesDesc</SimpleText>
                        </ContentBorder>

                        <View style={styles.viewPack}>
                            <ContentBorder
                                style={styles.viewSubPack}
                                titleViewStyle={styles.contentBorderStyle}
                                contentStyle={styles.contentBorderStyle}
                                title={`$productForm.priceSection`}>
                                <CardInput
                                    style={styles.cardInput}
                                    title={'$productForm.priceType'}
                                    icon={Icons.CATEGORIES}
                                    noInput
                                    picker
                                    pickerValue={productForm?.priceType}
                                    pickerItems={
                                        [
                                            { value: 'DEFAULT', label: '$productForm.priceTypeLabelNoSale'.localise() },
                                            { value: 'ONSALE', label: '$productForm.priceTypeLabelOnSale'.localise() },
                                        ]
                                    }
                                    onValueChange={_onValueChange('priceType')}
                                    errorText={errorForm.priceType}

                                />
                                <View style={{ flexDirection: 'row' }}>

                                    <CardInput
                                        ref={priceRef}
                                        title={productForm?.priceType === 'ONSALE' ? '$productForm.priceDefault' : '$productForm.price'}
                                        icon={Icons.EURO}
                                        iconStyle={{ marginLeft: productForm?.priceType === 'ONSALE' ? 5 : 15 }}
                                        style={{ flex: 1 }}
                                        onChangeText={_onValueChange('price')}
                                        keyboardType={keyboardType.decimalPad}
                                        autoCompleteType={undefined}
                                        inputText={productForm.price ? productForm.price.toString() : undefined}
                                        placeholder={'20.50'}
                                        onFocus={() => _focusInput('price', true)}
                                        onBlur={() => _focusInput('price', false)}
                                    />
                                    {productForm?.priceType === 'ONSALE' &&
                                        <CardInput
                                            title={'$productForm.priceOnSale'}
                                            icon={Icons.EURO}
                                            iconStyle={{ marginLeft: 5 }}
                                            style={{ marginLeft: 5, flex: 1, height: '100%' }}
                                            onChangeText={_onValueChange('salePrice')}
                                            keyboardType={keyboardType.decimalPad}
                                            autoCompleteType={undefined}
                                            inputText={productForm.salePrice ? productForm.salePrice.toString() : undefined}
                                            placeholder={'14.90'}
                                            onFocus={() => _focusInput('salePrice', true)}
                                            onBlur={() => _focusInput('salePrice', false)}
                                        />
                                    }
                                    <CardInput
                                        title={'$productForm.priceUnit'}
                                        noInput
                                        style={{ marginLeft: 5, flex: 1, height: '100%' }}
                                        picker
                                        pickerValue={productForm.priceUnit ? productForm.priceUnit.toUpperCase() : undefined}
                                        pickerItems={[
                                            { value: 'U', label: replaceLocale('$unit.u') },
                                            { value: 'PKG', label: replaceLocale('$unit.pkg') }
                                        ]}
                                        onValueChange={_onValueChange('priceUnit')}
                                    />

                                </View>
                                {_showHelp(helpStatus['price'], productForm?.priceType === 'ONSALE' ? '$productForm.priceDescWithSale' : '$productForm.priceDesc')}
                                {_showHelp(helpStatus['salePrice'], '$productForm.priceOnSaleInfo')}
                                {_showError(errorForm.price)}
                                {_showError(errorForm.salePrice)}
                                {_showError(errorForm.priceUnit)}
                                {productForm?.priceUnit === 'PKG' &&
                                    <CardInput
                                        style={[styles.cardInput, { marginTop: 10 }]}
                                        title={'$productForm.packUnits'}
                                        onChangeText={_onValueChange('packUnits')}
                                        keyboardType={keyboardType.decimalPad}
                                        autoCompleteType={undefined}
                                        inputText={productForm.packUnits ? productForm.packUnits.toString() : undefined}
                                        placeholder={'6'}
                                        errorText={errorForm.packUnits}
                                        infoText={'$productForm.packUnitsInfo'}
                                        onFocus={() => _focusInput('packUnits', true)}
                                        onBlur={() => _focusInput('packUnits', false)}
                                    />
                                }

                            </ContentBorder>


                            <ContentBorder
                                style={styles.viewSubPack}
                                titleViewStyle={styles.contentBorderStyle}
                                contentStyle={styles.contentBorderStyle}
                                title={`$productForm.amountSection`}>
                                <CardInput
                                    style={styles.cardInput}
                                    title={'$productForm.amountType'}
                                    icon={Icons.CATEGORIES}
                                    noInput
                                    picker
                                    pickerValue={productForm?.amountType}
                                    pickerItems={
                                        [
                                            { value: 'EXACT', label: replaceLocale('$productForm.amountFixed') },
                                            { value: 'RANGE', label: replaceLocale('$productForm.amountRange') },
                                        ]
                                    }
                                    onValueChange={_onValueChange('amountType')}
                                    infoText={'$productForm.amountType'}
                                    errorText={errorForm.amountType}


                                />
                                <View style={{ flexDirection: 'row' }}>

                                    <CardInput
                                        title={productForm?.amountType === 'RANGE' ? '$productForm.amountFrom' : '$productForm.amount'}
                                        style={{ flex: 1 }}
                                        onChangeText={_onValueChange('amount')}
                                        keyboardType={keyboardType.decimalPad}
                                        autoCompleteType={undefined}
                                        inputText={productForm.amount ? productForm.amount.toString() : productForm.amount}
                                        placeholder={'250'}
                                        onFocus={() => _focusInput('amount', true)}
                                        onBlur={() => _focusInput('amount', false)}
                                    />
                                    {productForm?.amountType === 'RANGE' &&
                                        <CardInput
                                            title={'$productForm.amountMax'}
                                            style={{ marginLeft: 5, flex: 1 }}
                                            onChangeText={_onValueChange('amountMax')}
                                            keyboardType={keyboardType.decimalPad}
                                            autoCompleteType={undefined}
                                            inputText={productForm.amountMax ? productForm.amountMax.toString() : productForm.amountMax}
                                            placeholder={'300'}
                                        />

                                    }

                                    <CardInput
                                        title={'$productForm.amountUnit'}
                                        style={{ marginLeft: 5, flex: 1 }}
                                        noInput
                                        picker
                                        pickerValue={productForm.amountUnit ? productForm.amountUnit.toUpperCase() : undefined}
                                        pickerItems={[
                                            { value: 'U', label: replaceLocale('$unit.u') },
                                            { value: 'MG', label: replaceLocale('$unit.mg') },
                                            { value: 'G', label: replaceLocale('$unit.g') },
                                            { value: 'KG', label: replaceLocale('$unit.kg') },
                                            { value: 'ML', label: replaceLocale('$unit.ml') },
                                            { value: 'CL', label: replaceLocale('$unit.cl') },
                                            { value: 'L', label: replaceLocale('$unit.l') },
                                        ]}
                                        onValueChange={_onValueChange('amountUnit')}
                                    />

                                </View>

                                {_showHelp(helpStatus['packUnits'], '$productForm.packUnitsInfo')}
                                {_showHelp(helpStatus['amount'], '$productForm.amountDesc')}
                                {_showError(errorForm.amount)}
                                {_showError(errorForm.amountMax)}
                                {_showError(errorForm.amountUnit)}
                            </ContentBorder>
                        </View>

                        <View style={styles.viewPack}>

                            <ContentBorder
                                style={styles.viewSubPack}

                                titleViewStyle={styles.contentBorderStyle}
                                contentStyle={styles.contentBorderStyle}
                                title={`$productForm.weightSection`}>

                                <View style={{ flexDirection: 'row' }}>

                                    <CardInput
                                        title={'$productForm.weight'}
                                        style={{ flex: 1 }}
                                        onChangeText={_onValueChange('weight')}
                                        keyboardType={keyboardType.decimalPad}
                                        autoCompleteType={undefined}
                                        inputText={productForm.weight ? productForm.weight.toString() : productForm.weight}
                                        placeholder={'250'}
                                        onFocus={() => _focusInput('weight', true)}
                                        onBlur={() => _focusInput('weight', false)}
                                    />
                                    <CardInput
                                        title={'$productForm.weightUnit'}
                                        style={{ marginLeft: 5, flex: 1 }}
                                        noInput
                                        picker
                                        pickerValue={productForm.weightUnit ? productForm.weightUnit.toUpperCase() : undefined}
                                        pickerItems={[
                                            { value: 'KG', label: replaceLocale('$unit.kg') },
                                            { value: 'G', label: replaceLocale('$unit.g') },
                                            { value: 'MG', label: replaceLocale('$unit.mg') },
                                        ]}
                                        onValueChange={_onValueChange('weightUnit')}
                                    />

                                </View>


                                {_showHelp(true, '$productForm.weightDesc')}
                                {_showError(errorForm.weight)}
                                {_showError(errorForm.weightUnit)}
                            </ContentBorder>
                            <ContentBorder
                                style={styles.viewSubPack}

                                titleViewStyle={styles.contentBorderStyle}
                                contentStyle={styles.contentBorderStyle}
                                title={`$productForm.stock`}>
                                <CardInput
                                    style={styles.cardInput}
                                    title={'$productForm.stock'}
                                    icon={Icons.WAREHOUSE}
                                    onChangeText={_onValueChange('stock')}
                                    keyboardType={keyboardType.numberPad}
                                    autoCompleteType={undefined}
                                    inputText={productForm.stock ? productForm.stock.toString() : productForm.stock}
                                    placeholder={'100'}
                                    errorText={errorForm.stock}
                                    infoText={'$productForm.stockDesc'}
                                />
                            </ContentBorder>
                        </View>

                        <View style={styles.viewPack}>
                            <ContentBorder
                                style={styles.viewSubPack}
                                titleViewStyle={styles.contentBorderStyle}
                                contentStyle={styles.contentBorderStyle}
                                title={`$productForm.classification`}>

                                <CardInput
                                    style={[styles.cardInput, { marginTop: 10 }]}
                                    title={'$productForm.category'}
                                    icon={Icons.TAG}
                                    noInput
                                    picker
                                    pickerValue={productForm.parentCategory}
                                    pickerItems={categoriesOptions}
                                    onValueChange={_onValueChange('parentCategory')}
                                    infoText={'$productForm.category'}
                                    errorText={subcategoriesOptions.length <= 0 ? errorForm.category : undefined}
                                />
                                {subcategoriesOptions.length > 0 &&
                                    <CardInput
                                        style={[styles.cardInput, { marginTop: 10 }]}
                                        title={'$productForm.subcategory'}
                                        icon={Icons.TAG}
                                        noInput
                                        picker
                                        pickerValue={productForm.category ? productForm.category : undefined}
                                        pickerItems={subcategoriesOptions}
                                        onValueChange={_onValueChange('category')}
                                        infoText={'$productForm.category'}
                                        errorText={errorForm.category}
                                    />
                                }
                            </ContentBorder>

                            <ContentBorder
                                style={styles.viewSubPack}
                                titleViewStyle={styles.contentBorderStyle}
                                contentStyle={styles.contentBorderStyle}
                                title={``}>
                                <View style={{
                                    flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
                                    alignContent: 'center', marginTop: 5, marginBottom: 5, alignSelf: 'center'
                                }}>
                                    <SimpleText style={{}}>$productForm.labels</SimpleText>
                                    <ButtonText
                                        style={{ alignSelf: 'flex-end', padding: 5, paddingLeft: 10, paddingRight: 10 }}
                                        type={'secondary'}
                                        iconSize={20}
                                        onPress={() => setLabelsModalVisible(true)}
                                        text={'$button.add'}>
                                    </ButtonText>
                                </View>
                                <View style={{
                                    alignSelf: 'center', width: '100%',
                                    borderWidth: 0.5, borderColor: AppStyle.textColorLight, borderRadius: 5,
                                    height: productForm.labels?.length > 2 || productForm.labels?.length == 0 ? 100 : 60,
                                    alignContent: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 10
                                }}>
                                    <CustomizableCardGrid
                                        style={{ width: '100%' }}
                                        cards={productForm.labels}
                                        renderItem={_renderLabelItem}
                                        contentContainerStyle={{ alignItems: 'flex-start' }}
                                        listType='list'
                                        horizontal
                                        itemDimension={30}
                                        emptyContentText={'$productForm.labelsHint'}
                                        noContentTextStyle={{
                                            color: AppStyle.placeHolderColor,
                                            ...TextStyle.size.medium, height: 80, padding: 5
                                        }}
                                    />
                                </View>
                                {_showError(errorForm.labels[0])}

                                <View style={{
                                    flexDirection: 'row', marginTop: 5, width: '100%',
                                    justifyContent: 'space-between', alignItems: 'center', alignContent: 'center', marginBottom: 5,
                                    alignSelf: 'center'
                                }}>
                                    <SimpleText >$productForm.allergens</SimpleText>
                                    <ButtonText
                                        style={{ alignSelf: 'flex-end', padding: 5, paddingLeft: 10, paddingRight: 10 }}
                                        type={'secondary'}
                                        iconSize={20}
                                        onPress={() => setAllergensModalVisible(true)}
                                        text={'$button.add'}>
                                    </ButtonText>
                                </View>
                                <View style={{
                                    alignSelf: 'center', width: '100%',
                                    height: productForm.allergens?.length > 2 || productForm.allergens?.length == 0 ? 100 : 60,
                                    borderWidth: 0.5, borderColor: AppStyle.textColorLight, borderRadius: 5,
                                    alignContent: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 10
                                }}>
                                    <CustomizableCardGrid
                                        style={{ width: '100%' }}
                                        cards={productForm.allergens}
                                        renderItem={_renderAllergenItem}
                                        contentContainerStyle={{ alignItems: 'flex-start' }}
                                        listType='list'
                                        horizontal
                                        itemDimension={30}
                                        emptyContentText={'$productForm.allergensHint'}
                                        noContentTextStyle={{ color: AppStyle.placeHolderColor, ...TextStyle.size.medium, height: 80, padding: 5 }}
                                    />
                                </View>
                                {_showError(errorForm.allergens[0])}

                            </ContentBorder>
                        </View>

                        <ContentBorder
                            separator
                            titleViewStyle={styles.contentBorderStyle}
                            contentStyle={styles.contentBorderStyle}
                            headerButtonText={'$common.components.imageUploader.selectButton'}
                            headerButtonIcon={Icons.UPLOAD}
                            headerButtonOnPress={() => imageUploaderRef.current.pickImage()}
                            title={`$productForm.images`}>
                            <ImageUploader
                                style={{ minHeight: 200, width: '100%', marginTop: 10 }}
                                ref={imageUploaderRef}
                                form={productForm}
                                imageDelete={_imageDelete}
                                imageUploaded={_imageUploaded}
                                imageToUpload={_placeHolderImageUpdate}
                                images={displayedImages} />
                            {_showHelp(true, '$productForm.imageShouldContainIngredientsDisclaimer')}
                            {_showError(errorForm.images.list[0])}
                        </ContentBorder>
                        <ContentBorder
                            titleViewStyle={styles.contentBorderStyle}
                            contentStyle={[styles.contentBorderStyle, styles.viewPack]}
                            title={`$productForm.productManagment`}>

                            <View style={[{
                                width: '100%', borderWidth: 2,
                                borderColor: AppStyle.getColor('secondary', 4), alignSelf: 'center',
                                padding: 20, borderRadius: 20, marginTop: 10
                            }, styles.viewManagePack]}>
                                <View style={{
                                    width: '100%',
                                    alignSelf: 'center', flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <Switch
                                        trackColor={{
                                            ...Platform.select({
                                                web: {
                                                    true: AppStyle.getColor('dark', 1),
                                                    false: AppStyle.getColor('dark', 1),
                                                },
                                                native: {
                                                    true: AppStyle.mainColor
                                                }
                                            })
                                        }}
                                        thumbColor={Platform.select({
                                            web: AppStyle.mainColor,
                                            native: AppStyle.getColor('light', 1)
                                        })}
                                        onValueChange={_onValueChange('isDisabled')}
                                        value={productForm.isDisabled}
                                    />
                                    <SimpleText style={{ marginLeft: 10 }}> $productForm.disableButton </SimpleText>

                                </View>
                                {_showHelp(true, '$productForm.info.disableButton')}
                            </View>

                            <View style={[{
                                width: '100%',
                                borderWidth: 2, borderColor: AppStyle.getColor('secondary', 4),
                                padding: 20, borderRadius: 20,
                                marginTop: 10
                            }, styles.viewManagePack]}>

                                <View style={{
                                    width: '100%', alignSelf: 'center',
                                    flexDirection: 'row',
                                }}>
                                    <Switch
                                        trackColor={{
                                            ...Platform.select({
                                                web: {
                                                    true: AppStyle.getColor('dark', 1),
                                                    false: AppStyle.getColor('dark', 1),
                                                },
                                                native: {
                                                    true: AppStyle.mainColor
                                                }
                                            })
                                        }}
                                        thumbColor={Platform.select({
                                            web: AppStyle.mainColor,
                                            native: AppStyle.getColor('light', 1)
                                        })}
                                        onValueChange={_onValueChange('ownMade')}
                                        value={productForm.ownMade}
                                    />
                                    <SimpleText style={{ marginLeft: 10 }}> $productForm.ownMade </SimpleText>

                                </View>
                                {_showHelp(productForm.ownMade, '$productForm.info.ownMadeTrue')}
                                {_showHelp(!productForm.ownMade, '$productForm.info.ownMadeFalse')}

                            </View>

                            {!isNew &&
                                <View style={[{
                                    width: '100%', borderWidth: 2,
                                    borderColor: AppStyle.errorColor, alignSelf: 'center', padding: 30,
                                    borderRadius: 20, marginTop: 10
                                }, styles.viewManagePack]}>

                                    <ButtonText
                                        style={{ padding: 10, borderColor: AppStyle.errorColor }}
                                        textStyle={{ color: AppStyle.errorColor }}
                                        type={'outline'}
                                        onPress={_deleteProduct}
                                        text={'$productForm.deleteButton'}>
                                    </ButtonText>

                                    {_showHelp(true, '$productForm.info.deleteButton')}

                                </View>
                            }
                        </ContentBorder>
                        <View style={[{ width: '80%', alignSelf: 'center', padding: 0, marginTop: 20 }, styles.lastItem]}></View>
                    </View>
                </KeyboardAwareScrollView>}
                <FeedbackModal
                    style={{ alignSelf: 'center' }}
                    error={formStatus.error}
                    onFeedbackPress={() => setFormStatus({ ...formStatus, error: false, errorText: '' })}
                    errorText={formStatus.errorText}
                    success={formStatus.completed}
                    successView={
                        <SuccessView
                            style={{ height: 100, width: 100 }}
                        />
                    }
                />

                {typeof producerLocales !== 'undefined' && <OptionsPickerModal
                    close={() => setLocaleModalVisible(false)}
                    visible={isLocaleModalVisible}
                    options={[
                        {
                            key: 'es', state: (producerLocales.indexOf('es') != -1),
                            name: '$locale.es', disabled: (producerLocales.indexOf('es') != -1 && producerLocales.length == 1)
                        },
                        {
                            key: 'ca', state: (producerLocales.indexOf('ca') != -1),
                            name: `$locale.ca`, disabled: (producerLocales.indexOf('ca') != -1 && producerLocales.length == 1)
                        }]}
                    onValueChange={_addOrRemoveLocale}
                />}

                <OptionsPickerModal
                    close={() => setLabelsModalVisible(false)}
                    visible={isLabelsModalVisible}
                    options={labelsOptions}
                    onValueChange={_addOrRemoveLabel}
                />

                <OptionsPickerModal
                    close={() => setAllergensModalVisible(false)}
                    visible={isAllergensModalVisible}
                    options={allergensOptions}
                    onValueChange={_addOrRemoveAllergen}
                />
            </ScrollView>
            {!(productResult.loading || loading) &&
                Object.keys(productForm.placeholderImages).length == 0 && <FloatingButton
                    loading={formStatus.loading}
                    style={{ width: "50%" }}
                    text={formStatus.loading ? (formStatus.loadingText ? formStatus.loadingText : '$button.save') : '$button.save'}
                    onPress={() => onSubmit()}
                />}
        </>
    )
};

/*
             <SimpleText style={{alignSelf:'center', ...TextStyle.padding.medium}}>Etiquetes</SimpleText>
             <CustomizableCardGrid
                 style={{ backgroundColor:AppStyle.cardBackground, alignSelf:'center', maxWidth:'100%', maxHeight:400, borderRadius: 15, marginBottom:10}}
                 cards={productForm.allTags}
                 renderItem={_renderTagItem}
                 contentContainerStyle={{alignItems:'flex-start', alignContent:'center'}}
                 listType='list'
                 itemDimension={100}
             />
             <SimpleText>{errorForm.tags}</SimpleText>
*/

const updateStyles = () => ({

    cardInput: {
        marginBottom: 10,
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    contentBorderStyle: {
        maxWidth: 600, justifyContent: 'flex-start',
        alignSelf: 'center', width: '90%'
    },
    infoText: {
        color: AppStyle.getColor('secondary', 1),
        ...TextStyle.size.medium,
        width: '100%',
        marginTop: 10,
        maxWidth: 400,
        alignSelf: 'center',
        textAlign: 'left',
    },
    centeredView: {
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    modalView: {
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    lastItem: {
        marginBottom: 80,
    },
    viewPack: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: 800,
        width: '100%',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 10,
    },
    viewSubPack: {
        minWidth: 350,
        flex: 1,
        width: '100%',
    },
    viewManagePack: {
        minWidth: 350,
        alignSelf: 'flex-start',
        maxWidth: 400,
        margin: 10,
        flex: 1,
        width: '100%',
    },
})

export default ProductFormScreen;


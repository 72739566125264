import React, { useState } from 'react';
import { View, StyleSheet, Switch, useWindowDimensions, FlatList, Platform, Dimensions } from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';
import { useQuery, useApolloClient } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux'

import { SimpleText, ButtonText, InView } from '@quarks';
import { PriceUnit, FloatingButton, ImageCard } from '@atoms';
import { AppStyle, TextStyle } from '@style';
import { Category } from '@api'

import { Product, Producer } from '@api'
import { getTranslation } from '@utilities/locale'
import { sessionOperations } from '_ducks/session';
import { ScrollView } from 'react-native-gesture-handler';
import { ErrorView, LoadingView } from '../../../common-assets/components/atoms';

const ProductsScreen = (props) => {

    const dispatch = useDispatch()

    const limit = 100

    const { query, param, name } = Product.getFromProducerId(0, limit)

    const getCategories = Category.getCategories()
    const categoriesResult = useQuery(getCategories.query)

    const _onCompleted = () => {
        dispatch(sessionOperations.storeQuery(name))
    }

    const { loading, error, data, called, refetch, fetchMore } = useQuery(query,
        { ...param, onCompleted: _onCompleted, fetchPolicy: 'cache-and-network' });

    React.useEffect(() => {
        if (props.route?.params?.refetch === true || (typeof data == 'undefined' && called)) {
            refetch()
        }
    }, [props.route.params])

    const queryRedux = useSelector(state => state.session.queries[name])

    React.useEffect(() => {
        if (!loading && called && ((queryRedux && queryRedux.needsRefetch) ||
            typeof queryRedux === 'undefined')) {
            dispatch(sessionOperations.storeQuery(name))
            refetch()
            if (typeof categoriesResult?.data?.categories === 'undefined') {
                categoriesResult.refetch()
            }
        }
    }, [queryRedux, loading, called])

    let products;

    if (typeof data != 'undefined') {
        products = data.ProductsProducer
    }

    const styles = updateStyles(useHeaderHeight())

    const _addItem = (item) => {
        //dispatch(productFormOperations.setProduct())
        props.navigation.navigate('ProductForm', { id: 'new' })
    }

    const footer = (item, stock) => {


        const _onPress = (item) => () => {
            //dispatch(productFormOperations.setProduct(item))
            props.navigation.navigate('ProductForm', { id: `${item._id}` })
        }
        return (<View style={{ alignSelf: 'center', marginLeft: 15 }}>
            <SimpleText
                numberOfLines={2}
                ellipsizeMode={'tail'}
                style={[styles.productNameText]}>
                {getTranslation(item).name}
            </SimpleText>
            <PriceUnit
                style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                product={item} />
            <ButtonText type={'outline'}
                onPress={_onPress(item)}
                style={{
                    padding: 5, paddingLeft: 20, paddingRight: 20,
                    alignSelf: 'flex-start'
                }} text={'Modificar'} />
        </View>)
    }

    const _renderItem = ({ item, section, index }) => {
        let missing;

        if (item.stock === 0) {
            missing = { backgroundColor: AppStyle.errorLightColor }
        } else if (item.stock < 5) {
            missing = { backgroundColor: AppStyle.warningLightColor }
        }

        return (<View style={[styles.imageWithBadgeContainer]}>
            <View style={{
                flexDirection: 'row', alignSelf: 'center',
                justifyContent: 'flex-start', flex: 1, minWidth: 270, paddingBottom: 20
            }}>
                <ImageCard
                    onPress={() => {
                        dispatch(sessionOperations.toggleProductModal(item))
                    }}
                    fontSize={TextStyle.size.small.fontSize}
                    text={item.isDisabled ? '$products.disabled' : undefined}
                    style={{ height: 80, width: 80, maxHeight: 80, maxWidth: 80, alignSelf: 'center' }}
                    image={item.images.stared} />
                {footer(item, item.stock)}
            </View>
            <View style={{ flex: 1, flexDirection: 'row', minWidth: 100 }}>
                <View style={{ alignSelf: 'center', flex: 1, justifyContent: 'center', }}>
                    <SimpleText style={{
                        textAlign: 'center', flex: 1, maxWidth: 150,
                        paddingLeft: 10, paddingRight: 10, padding: 5,
                        backgroundColor: AppStyle.getColor('secondary', 1), borderRadius: 20
                    }}>
                        {item.stock} restants
                    </SimpleText>
                </View>
                <View style={{ alignSelf: 'center', justifyContent: 'center', flex: 1, }}>
                    <Switch
                        trackColor={{
                            ...Platform.select({
                                web: {
                                    true: AppStyle.getColor('dark', 1),
                                    false: AppStyle.getColor('dark', 1),
                                },
                                native: {
                                    true: AppStyle.mainColor
                                }
                            })
                        }}
                        style={{ justifyContent: 'center', alignSelf: 'center' }}
                        thumbColor={Platform.select({
                            web: AppStyle.mainColor,
                            native: AppStyle.getColor('light', 1)
                        })}
                        value={!item.isDisabled}
                    />
                </View>
            </View>
        </View>
        );
    }

    const dimensions = useWindowDimensions();

    return (<ScrollView style={{
        height: '100%', backgroundColor: AppStyle.backgroundColor
    }}>
        <View style={{
            paddingTop: '5%', paddingBottom: 40,
            width: '80%', alignSelf: 'center', justifyContent: 'flex-start'
        }}>
            <ButtonText
                text={'$products.addButton'}
                type={'solid'}
                style={{ alignSelf: 'flex-start', width: 200, height: 50, borderRadius: 5 }}
                onPress={_addItem}
            />
        </View>
        <View style={{
            height: '100%', paddingTop: 10,
            width: dimensions.width < 768 ? '95%' : '80%', padding: 20, alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColorWithIntensity(1),
        }}>
            <SimpleText style={{
                paddingTop: 10, paddingBottom: 10, ...TextStyle.size.large,
                textAlign: 'center'
            }}>Productes</SimpleText>
            <View
                style={{
                    flexDirection: 'row', justifyContent: 'space-between',
                    width: '100%', minWidth: 300,
                    backgroundColor: AppStyle.getColor('dark', 5), paddingTop: 10, paddingBottom: 10
                }}>
                <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>PRODUCTE</SimpleText>
                <View
                    style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row' }}>
                    <SimpleText style={{ ...TextStyle.size.small, flex: 1, maxWidth: 150, textAlign: 'center' }}>STOCK</SimpleText>
                    <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>VISIBILITAT</SimpleText>
                </View>
            </View>
            {error && <ErrorView />}
            {loading && <LoadingView />}
            {!(loading || error) && <FlatList
                style={{
                    width: '100%',
                    padding: 20, minWidth: 300,
                    backgroundColor: AppStyle.backgroundColorWithIntensity(1),
                    alignSelf: 'center',
                }}
                data={products}
                ListEmptyComponent={<View style={{ width: '100%', height: 200, justifyContent: 'center' }}>
                    <SimpleText style={{ textAlign: 'center', color: AppStyle.textColorLight }}>No hi han productes</SimpleText>
                </View>}
                keyExtractor={item => item._id}
                renderItem={_renderItem} />}

        </View>

    </ScrollView>)
};

const updateStyles = () => StyleSheet.create({
    main: {
        backgroundColor: AppStyle.backgroundColor,
    },
    locationIcon: {
        alignSelf: 'center',
        flex: 1,
        marginLeft: 30
    },
    itemStyle: {
        alignSelf: 'center',
        flex: 6,
        marginRight: 10,
    },
    addButton: {
        width: 50,
        paddingRight: 10
    },
    name: {
        ...TextStyle.weight.bold,
        textAlignVertical: 'center'
    },
    productNameText: {
        ...TextStyle.weight.regular,
        ...TextStyle.size.medium,
        color: AppStyle.textColor
    },
    imageWithBadgeContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%', flex: 1,
        justifyContent: 'space-between',
        paddingBottom: 20
    },
    badgeContainer: {
        top: 0,
        backgroundColor: AppStyle.cardBackgroundWithIntensity(4),
        left: 0,
        zIndex: 1,
        width: 110,
        height: 35,
        borderRadius: 10,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 2,
        alignSelf: 'flex-end',
        justifyContent: 'center',
        position: 'absolute'
    },
    iconStyle: {
        position: 'absolute',
        top: 20,
        right: 30,
        paddingTop: 4,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
        alignSelf: 'flex-end',
    },
});

export default ProductsScreen
/*

import React, { useState } from 'react';
import { View, StyleSheet, Switch, useWindowDimensions, FlatList, Platform, Dimensions, createElement } from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';
import { useQuery, useApolloClient } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux'
import ReactDataSheet from 'react-datasheet';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-datasheet/lib/react-datasheet.css';

import { SimpleText, ButtonText, InView } from '@quarks';
import { PriceUnit, FloatingButton, ImageCard } from '@atoms';
import { HeaderCard, CardInput} from '@molecules';
import { ProductCardGrid } from '@organisms';
import { AppStyle, TextStyle } from '@style';
import { Category } from '@api'
import { PRODUCER_ICONS } from '@images'
import { SafeAreaView } from 'react-native-safe-area-context';

import { Product, Producer } from '@api'
import { getTranslation } from '@utilities/locale'
import { sessionOperations } from '_ducks/session';
import { ScrollView } from 'react-native-gesture-handler';
import { ErrorView, LoadingView } from '../../../common-assets/components/atoms';
import { nullPlaceholder } from 'i18n-js';

const ProductsScreen = (props) => {

    const dispatch = useDispatch()

    const limit = 100

    const { query, param, name } = Product.getFromProducerId(0, limit)
    const [isEditState, setEditState] = useState(false)
    const getCategories = Category.getCategories()
    const categoriesResult = useQuery(getCategories.query)

    const _onCompleted = () => {
        dispatch(sessionOperations.storeQuery(name))
    }

    const { loading, error, data, called, refetch, fetchMore } = useQuery(query,
        { ...param, onCompleted: _onCompleted, fetchPolicy: 'cache-and-network' });

    React.useEffect(() => {
        if (props.route?.params?.refetch === true || (typeof data == 'undefined' && called)) {
            refetch()
        }
    }, [props.route.params])

    const queryRedux = useSelector(state => state.session.queries[name])

    React.useEffect(() => {
        if (!loading && called && ((queryRedux && queryRedux.needsRefetch) ||
            typeof queryRedux === 'undefined')) {
            dispatch(sessionOperations.storeQuery(name))
            refetch()
            if (typeof categoriesResult?.data?.categories === 'undefined') {
                categoriesResult.refetch()
            }
        }
    }, [queryRedux, loading, called])

    let products;

    if (typeof data != 'undefined') {
        products = data.ProductsProducer
    }

    const styles = updateStyles(useHeaderHeight())

    const _addItem = (item) => {
        //dispatch(productFormOperations.setProduct())
        props.navigation.navigate('ProductForm', { id: 'new' })
    }

    const footer = (item, stock) => {


        const _onPress = (item) => () => {
            //dispatch(productFormOperations.setProduct(item))
            props.navigation.navigate('ProductForm', { id: `${item._id}` })
        }
        return (<View style={{ alignSelf: 'center', marginLeft: 15 }}>
            <SimpleText
                numberOfLines={2}
                ellipsizeMode={'tail'}
                style={[styles.productNameText]}>
                {getTranslation(item).name}
            </SimpleText>
            <PriceUnit
                style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                product={item} />
            <ButtonText type={'outline'}
                onPress={_onPress(item)}
                style={{
                    padding: 5, paddingLeft: 20, paddingRight: 20,
                    alignSelf: 'flex-start'
                }} text={'Modificar'} />
        </View>)
    }

    const _renderItem = ({ item, section, index }) => {
        let missing;

        if (item.stock === 0) {
            missing = { backgroundColor: AppStyle.errorLightColor }
        } else if (item.stock < 5) {
            missing = { backgroundColor: AppStyle.warningLightColor }
        }

        return (<View style={[styles.imageWithBadgeContainer]}>
            <View style={{
                flexDirection: 'row', alignSelf: 'center',
                justifyContent: 'flex-start', flex: 1, minWidth: 270, paddingBottom: 20
            }}>
                <ImageCard
                    onPress={() => {
                        dispatch(sessionOperations.toggleProductModal(item))
                    }}
                    fontSize={TextStyle.size.small.fontSize}
                    text={item.isDisabled ? '$products.disabled' : undefined}
                    style={{ height: 80, width: 80, maxHeight: 80, maxWidth: 80, alignSelf: 'center' }}
                    image={item.images.stared} />
                {footer(item, item.stock)}
            </View>
            <View style={{ flex: 1, flexDirection: 'row', minWidth: 100 }}>
                <View style={{ alignSelf: 'center', flex: 1, justifyContent: 'center', }}>
                    <SimpleText style={{
                        textAlign: 'center', flex: 1, maxWidth: 150,
                        paddingLeft: 10, paddingRight: 10, padding: 5,
                        backgroundColor: AppStyle.getColor('secondary', 1), borderRadius: 20
                    }}>
                        {item.stock} restants
                    </SimpleText>
                </View>
                <View style={{ alignSelf: 'center', justifyContent: 'center', flex: 1, }}>
                    <Switch
                        trackColor={{
                            ...Platform.select({
                                web: {
                                    true: AppStyle.getColor('dark', 1),
                                    false: AppStyle.getColor('dark', 1),
                                },
                                native: {
                                    true: AppStyle.mainColor
                                }
                            })
                        }}
                        style={{ justifyContent: 'center', alignSelf: 'center' }}
                        thumbColor={Platform.select({
                            web: AppStyle.mainColor,
                            native: AppStyle.getColor('light', 1)
                        })}
                        value={!item.isDisabled}
                    />
                </View>
            </View>
        </View>
        );
    }
    const _onPress = (item) => () => {
        //dispatch(productFormOperations.setProduct(item))
        props.navigation.navigate('ProductForm', { id: `${item._id}` })
    }

    const _renderEditCell = ({value, cell, col, row, onCommit}) => { 
        const item = cell

        return <CardInput
        autofocus
        style={[styles.cardInput]}
        title={'$productForm.name'}
        inputText={getTranslation(item).name}
        onSubmitEditing={(what)=>onCommit('hola',what)}
        returnKeyType={'next'}
    />

    }

    const _renderCell = ({value, cell, col, row}) => {
        const item = cell

        switch(col) {

            case 0:
                return <ImageCard
                onPress={() => {
                    dispatch(sessionOperations.toggleProductModal(item))
                }}
                fontSize={TextStyle.size.small.fontSize}
                text={item.isDisabled ? '$products.disabled' : undefined}
                style={{ height: 80, width: 80, maxHeight: 80, maxWidth: 80, alignSelf: 'center' }}
                image={item.images.stared} />
            case 1:
                return <View style={{ alignSelf: 'center', marginLeft: 15 }}>
                <SimpleText
                    numberOfLines={2}
                    ellipsizeMode={'tail'}
                    style={[styles.productNameText]}>
                    {getTranslation(item).name}
                </SimpleText>
                <PriceUnit
                    style={{ paddingBottom: 10, alignItems: 'flex-start' }}
                    product={item} />
                <ButtonText type={'outline'}
                    onPress={_onPress(item)}
                    style={{
                        padding: 5, paddingLeft: 20, paddingRight: 20,
                        alignSelf: 'flex-start'
                    }} text={'Modificar'} />
            </View>
            case 2:
                return <View style={{ alignSelf: 'center', flex: 1, justifyContent: 'center', }}>
                <SimpleText style={{
                    textAlign: 'center', flex: 1, maxWidth: 150,
                    paddingLeft: 10, paddingRight: 10, padding: 5,
                    backgroundColor: AppStyle.getColor('secondary', 1), borderRadius: 20
                }}>
                    {item.stock} restants
                </SimpleText>
            </View>
            case 3:
                return  <View style={{ alignSelf: 'center', justifyContent: 'center', flex: 1, }}>
                <Switch
                    trackColor={{
                        ...Platform.select({
                            web: {
                                true: AppStyle.getColor('dark', 1),
                                false: AppStyle.getColor('dark', 1),
                            },
                            native: {
                                true: AppStyle.mainColor
                            }
                        })
                    }}
                    style={{ justifyContent: 'center', alignSelf: 'center' }}
                    thumbColor={Platform.select({
                        web: AppStyle.mainColor,
                        native: AppStyle.getColor('light', 1)
                    })}
                    value={!item.isDisabled}
                />
            </View>
        }

    }

    const dimensions = useWindowDimensions();
    //valueViewer={_renderCell}
    const table = products?.map((product)=>[ 
        {_id: product._id, value: getTranslation(product).name, field: 'name'}, 
        {_id: product._id, value: product.price, field: 'price'}, 
        {_id: product._id, value: product.amount, field: 'amount'}, 
        {_id: product._id, value: product.stock, field: 'stock'}
    ])
    return (<ScrollView style={{
        height: '100%', backgroundColor: AppStyle.backgroundColor
    }}>
        <View style={{
            paddingTop: '5%', paddingBottom: 40,
            width: '80%', alignSelf: 'center', justifyContent: 'flex-start'
        }}>
            <ButtonText
                text={'$products.addButton'}
                type={'solid'}
                style={{ alignSelf: 'flex-start', width: 200, height: 50, borderRadius: 5 }}
                onPress={_addItem}
            />
        </View>
        <View style={{
            height: '100%', paddingTop: 10,
            width: dimensions.width < 768 ? '95%' : '80%', padding: 20, alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColorWithIntensity(1),
        }}>
            <SimpleText style={{
                paddingTop: 10, paddingBottom: 10, ...TextStyle.size.large,
                textAlign: 'center'
            }}>Productes</SimpleText>
            <Switch
                        trackColor={{
                            ...Platform.select({
                                web: {
                                    true: AppStyle.getColor('dark', 1),
                                    false: AppStyle.getColor('dark', 1),
                                },
                                native: {
                                    true: AppStyle.mainColor
                                }
                            })
                        }}
                        style={{ justifyContent: 'center', alignSelf: 'center' }}
                        thumbColor={Platform.select({
                            web: AppStyle.mainColor,
                            native: AppStyle.getColor('light', 1)
                        })}
                        value={isEditState}
                        onValueChange={setEditState}
                    />

            {error && <ErrorView />}
            {loading && <LoadingView />}
            {!(loading || error) && 
            <>  
                {!isEditState && 
                <>
                        <View
                        style={{
                            flexDirection: 'row', justifyContent: 'space-between',
                            width: '100%', minWidth: 300,
                            backgroundColor: AppStyle.getColor('dark', 5), paddingTop: 10, paddingBottom: 10
                        }}>
                        <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>PRODUCTE</SimpleText>
                        <View
                            style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row' }}>
                            <SimpleText style={{ ...TextStyle.size.small, flex: 1, maxWidth: 150, textAlign: 'center' }}>STOCK</SimpleText>
                            <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>VISIBILITAT</SimpleText>
                        </View>
                    </View>
                    <FlatList
                        style={{
                            width: '100%',
                            padding: 20, minWidth: 300,
                            backgroundColor: AppStyle.backgroundColorWithIntensity(1),
                            alignSelf: 'center',
                        }}
                        data={products}
                        ListEmptyComponent={
                            <View style={{ width: '100%', height: 200, justifyContent: 'center' }}>
                                <SimpleText style={{ textAlign: 'center', color: AppStyle.textColorLight }}>No hi han productes</SimpleText>
                            </View>
                        }
                        keyExtractor={item => item._id}
                        renderItem={_renderItem} />
                </>
                }
                {isEditState && 
                <>
                <View
                        style={{
                            flexDirection: 'row', justifyContent: 'space-between',
                            width: '100%', minWidth: 300,
                            backgroundColor: AppStyle.getColor('dark', 5), paddingTop: 10, paddingBottom: 10
                        }}>
                        <View
                            style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row' }}>
                            <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>PRODUCTE</SimpleText>
                            <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>PREU</SimpleText>
                            <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>AMOUNT</SimpleText>
                            <SimpleText style={{ ...TextStyle.size.small, flex: 1, textAlign: 'center' }}>STOCK</SimpleText>
                        </View>
                    </View>
                    <ReactDataSheet
                        data={table}
                        sheetRenderer={(({data, className, children}) => <table className={className} style={{width:'100%'}}>{children}</table>)}
                        valueViewer={(({value, row, col, cell}) => <SimpleText style={{alignSelf: 'center', textAlign: 'center'}}>{value}</SimpleText>)}
                        valueRenderer={cell => cell.value}
                        overflow={'wrap'}
                    />
                    </>
                }
                </>
                }
        </View>

    </ScrollView>)
};

const updateStyles = () => StyleSheet.create({
    main: {
        backgroundColor: AppStyle.backgroundColor,
    },
    locationIcon: {
        alignSelf: 'center',
        flex: 1,
        marginLeft: 30
    },
    itemStyle: {
        alignSelf: 'center',
        flex: 6,
        marginRight: 10,
    },
    addButton: {
        width: 50,
        paddingRight: 10
    },
    name: {
        ...TextStyle.weight.bold,
        textAlignVertical: 'center'
    },
    productNameText: {
        ...TextStyle.weight.regular,
        ...TextStyle.size.medium,
        color: AppStyle.textColor
    },
    imageWithBadgeContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%', flex: 1,
        justifyContent: 'space-between',
        paddingBottom: 20
    },
    badgeContainer: {
        top: 0,
        backgroundColor: AppStyle.cardBackgroundWithIntensity(4),
        left: 0,
        zIndex: 1,
        width: 110,
        height: 35,
        borderRadius: 10,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 2,
        alignSelf: 'flex-end',
        justifyContent: 'center',
        position: 'absolute'
    },
    iconStyle: {
        position: 'absolute',
        top: 20,
        right: 30,
        paddingTop: 4,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
        alignSelf: 'flex-end',
    },
});

export default ProductsScreen
*/
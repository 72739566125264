import React from 'react';

import { SimpleText } from '@quarks'
import { TextStyle } from "@style";

export const PrivacyPolicyCatalan = () => {
  return (<>
    <SimpleText style={{
      ...TextStyle.size.xxxlarge,
      ...TextStyle.weight.bold,
      width: '90%',
      alignSelf: 'center',
      textAlign: 'center',
    }}>Política de privacitat</SimpleText>
    <div className="container-s">
      <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
        A la política següent, Nadius fa referència al servei que ofereix Nadius (l '"empresa" o "nosaltres") a través del lloc web nadius.cat i de l'aplicació Nadius (el "servei"). Aquesta Política de privadesa explica (i) quina informació recopilem a través del vostre accés i ús del nostre Servei (ii) l’ús que fem d’aquesta informació; i (iii) el nivell de seguretat que proporcionem per protegir aquesta informació.
                  <br /> <br />
                  En visitar i utilitzar els Serveis proporcionats aquí a través de nadius.cat i l’aplicació mòbil Nadius (conjuntament "Plataforma"), accepteu els termes descrits en aquesta política de privadesa.
                  <h6 className="mt-3 mb-16" data-revelar-delay="200">
          Marc legal
                  </h6>
                  La companyia té el seu domicili a Espanya a la següent adreça:
                  <br />
                  Nadius, Avinguda Madrid 191, 08014 Barcelona
                  <br />
                  La infraestructura d’emmagatzematge de dades es troba a les instal·lacions d’AWS Frankfurt i Irlanda i, per tant, es regeix per les lleis i regulacions d’aquests països i la UE.
                  <h5 className="mt-3 mb-16" data-revelar-delay="200">
          Dades relacionades amb l'obertura d'un compte
                  </h5>
                  Quan us registreu com a membre de la Plataforma, haureu de proporcionar les dades següents per dur a terme el procediment de registre i accedir al vostre compte Nadius:
                  <br /> <br /> El vostre nom;
                  <br /> adreça de correu electrònic;
                  <br /> Confirmació que teniu més de 18 anys;
                  <br /> Contrasenya. <br /> <br />
        <br />
                  Quan us registreu com a membre de la Plataforma com a Venedor, haureu de proporcionar les dades previament esmentades i següents per dur a terme el procediment de registre i accedir al vostre compte Nadius:
                  <br /><br />El vostre nom complet;
                  <br />Nom de l'empresa o entitat que realitza la venda;
                  <br />Dades fiscals;
                  <br />Documents que verifiquin la titularitat de l'empresa;
                  <br />Numero de telèfon;
                  <br />Any de naixement;
                  <br />Nacionalitat;
                  <br />País de residència;
                  La base legal per a la recopilació i ús de dades és la realització d’un contracte del qual sou part o per prendre mesures a petició vostra abans de celebrar un contracte (art. 6 (1) (b) ) del GDPR).
                  <br /> <br />
                  Aquestes dades només s’utilitzaran per contactar-vos amb notificacions importants sobre Nadius, per enviar-vos informació relacionada amb la seguretat, per mantenir-vos informat de les comandes i productes obtinguts a través de la Plataforma, per verificar el vostre compte de Nadius o per enviar-vos enllaços de recuperació de contrasenyes si activeu l'opció. També us podem informar sobre els nous productes Nadius que us poden interessar. En qualsevol moment, sou lliure de desactivar aquestes funcions a través del tauler de configuració del compte.
                  <br /> <br />
        <h4 className="mt-3 mb-16" data-revelar-delay="200">
          Recopilació de dades
                  </h4>
        <p style={{ fontWeight: 'bold' }}>La política general de la nostra empresa és recopilar la mínima informació d’usuari possible per garantir una experiència d’usuari privada quan s’utilitza el Servei.</p>
                  La recopilació de dades d’usuari del servei es limita al següent:
                  <br /> <br />
        <p style={{ fontWeight: 'bold' }}> Visitar el nostre lloc web: <p style={{ fontWeight: 'normal' }}> Qualsevol sol·licitud realitzada a través del lloc web genera un registre temporal al nostre servei al núvol, que conté el IP i data d'aquesta sol·licitud, que posteriorment s'elimina al cap de diversos dies. </p> </p>
        <p style={{ fontWeight: 'bold' }}> Comunicació amb Nadius: <p style={{ fontWeight: 'normal' }}> Les vostres comunicacions amb l'empresa, com ara sol·licituds d'assistència, informes d'errors o funcions poden ser desats pel nostre personal. La base legal per al processament és el nostre legítim interès per solucionar problemes de manera més eficient i millorar la qualitat de la plataforma Nadius. </p> </p>
        <p style={{ fontWeight: 'bold' }}> Registre d'IP: <p style={{ fontWeight: 'normal' }}> Per defecte, Nadius no guarda registres IP permanents. No obstant això, es poden guardar registres IP temporalment per combatre l'abús i el frau. La base legal d’aquest processament és el nostre legítim interès per protegir el nostre servei contra activitats nefastes. </p> </p>
        <p style={{ fontWeight: 'bold' }}> Rebre articles: <p style={{ fontWeight: 'normal' }}> Si decidiu introduir la vostra informació d’enviament o quan compreu articles, recopilem i fem servir el dades següents que proporcioneu amb finalitats d'enviament i enviament:
                  <br /> <br /> Nom complet;
                  <br /> País;
                  <br /> Ciutat;
                  <br /> Adreça;
                  <br /> Número de telèfon;
                  <br /> Altra informació d’enviament requerida per un proveïdor de serveis d’enviament concret.
                  <br /> <br />
                  La base legal per a la recopilació i l’ús de dades és l’execució d’un contracte del qual sou part (art. 6 (1) (b) del GDPR).
                  <br /> <br />
                  Les dades personals són necessàries per realitzar un contracte amb vosaltres. En cas que no ens proporcioneu aquestes dades personals, no podrem concloure i executar un contracte amb vosaltres.
                  <br /> <br />
                  Proporcionem les dades personals recopilades i utilitzades amb aquesta finalitat al venedor de l’article i correspon al venedor seguir les instruccions i proporcionar les seves dades al proveïdor d’enviament.
                  </p> </p>
        <p style={{ fontWeight: 'bold' }}> Informació de pagament: <p style={{ fontWeight: 'normal' }}> L'empresa confia en tercers per processar transaccions amb targeta de crèdit, de manera que la companyia ha de compartir informació de pagament necessàriament amb tercers. La base legal d’aquest tractament és la necessitat de l’execució del contracte entre vostè i nosaltres. </p> </p>
        <p style={{ fontWeight: 'bold' }}> Aplicacions natives: <p style={{ fontWeight: 'normal' }}>
          Quan utilitzeu les nostres aplicacions natives, nosaltres (o els proveïdors de la plataforma d'aplicacions mòbils) podem recopilar certa informació, a més de la informació esmentada en altres llocs d'aquesta política.
          Podem utilitzar programari d’anàlisi mòbil (com ara stadístiques d’aplicacions Play Store, estadístiques d’aplicacions App Store o informes d’errors i analítica d'appcenter) per enviar informació d’errors als nostres desenvolupadors de manera que puguem corregir els errors ràpidament .
                    <br /> <br /> Algunes plataformes (com ara Google Play Store o Apple App Store) també poden recopilar estadístiques agregades i anònimes com el tipus de dispositius i sistemes operatius que s’utilitzen més habitualment (com el percentatge d’Android 6. x contra Android 7.x), el nombre total d’instal·lacions, el nombre total de desinstal·lacions i el nombre total d’usuaris actius, i es pot regir per la política de privadesa i els termes i condicions de Google Play Store o Apple App Store.
                    <br /> <br /> Cap dels programes de les nostres aplicacions no accedirà ni farà un seguiment de cap informació basada en la ubicació del vostre dispositiu en cap moment.
                    <br /> <br /> Les dades personals adquirides durant aquest procés s’anonimitzen. </p> </p>
        <h4 className="mt-3 mb-16" data-revelar-delay="200">
          Ús de dades
                  </h4>
                  No tenim publicitat al nostre lloc. Les dades que tinguem mai es compartiran excepte en les circumstàncies descrites a continuació a la secció de divulgació de dades. Podem fer anàlisis de les dades limitades que tenim, però sempre per millorar els nostres serveis i / o proporcionar estadístiques agregades als proveïdors de la plataforma.
                  <h4 className="mt-3 mb-16" data-revelar-delay="200">
          Emmagatzematge de dades
                  </h4>
                  Tots els servidors utilitzats en relació amb el subministrament del servei són operats per Nadius i propietat de MongoDB, Inc. Només els empleats de la companyia tenen accés als servidors. Les dades SEMPRE s’emmagatzemen en format xifrat als nostres servidors. Les còpies de seguretat fora de línia es poden emmagatzemar periòdicament, però també es xifren.
                  <h4 className="mt-3 mb-16" data-revelar-delay="200">
          Dret d’accés, rectificació, esborrat, portabilitat i dret a presentar una reclamació
                  </h4>
                  A través del Servei, podeu accedir directament, editar-los, suprimir-los o exportar-los a les dades personals processades per l’empresa en l’ús que feu del Servei.
                  <br /> <br />
                  Si el vostre compte s'ha suspès per incompliment dels nostres termes i condicions i voleu exercir els drets relacionats amb les vostres dades personals, podeu sol·licitar-ho al nostre equip d'assistència.
                  <br /> <br />
                  En cas de vulneració dels vostres drets, teniu dret a presentar una reclamació a l'autoritat de control competent.
                  <h4 className="mt-3 mb-16" data-revelar-delay="200">
          Retenció de dades
                  </h4>
                  Quan es tanca un compte Nadius, totes les dades que no necessiten guardar-se sota cap obligació legal s’eliminen de forma immediata. La resta es marca com a inaccessible als nostres servidors i es manté fins que es compleixin totes les obligacions legals. Les dades actives es conservaran de manera indefinida.
                  <br /> <br />
        <h4 className="mt-3 mb-16" data-revelar-delay="200">
          Divulgació de dades
                  </h4>
                  Només revelarem les dades limitades d’usuari que posseïm si se’ns demana que ho faci mitjançant una sol·licitud totalment vinculant de les autoritats competents (obligació legal).
                  <br /> <br />
                  Tot i que podem complir els avisos lliurats electrònicament, les dades divulgades només es poden utilitzar judicialment després d’haver rebut una còpia original de l’ordre judicial per correu certificat o presencialment i donar una resposta formal.
                  <br /> <br />
                  Si la llei ho permet, Nadius sempre es posarà en contacte amb un usuari abans de qualsevol divulgació de dades.
                  <br /> <br />
                  Nadius pot de tant en tant contestar sol·licituds si hi ha interès públic en fer-ho. En aquestes situacions, la Companyia no complirà la sol·licitud fins que s'hagin esgotat tots els recursos legals o d'altres. Per tant, no totes les sol·licituds conduiran a la divulgació de dades.
                  <br /> <br />
        <h4 className="mt-3 mb-16" data-revelar-delay="200">
          Modificacions a la política de privadesa
                  </h4>
                  Nadius es reserva el dret de revisar i canviar periòdicament aquesta política de tant en tant. L’ús continuat del Servei es considerarà l’acceptació d’aquests canvis.
      </p>
    </div>
  </>
  );
}
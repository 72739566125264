import * as types from "./types";

export const submitForm = ( content ) => ( {
    type: types.ON_SUBMIT,
    form: content,
} );

export const formError = ( errors ) => ( {
    type: types.ON_FORM_VALIDATION_ERROR,
    errors,
} );

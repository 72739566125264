import React, { useState } from 'react';
import {
    Animated,
    TouchableOpacity,
    StyleSheet,
    View
} from 'react-native';

import { AppStyle } from '@style'

export function Card(props) {

    const styles = updateStyle(props.height, props.width, props.margin)
    const [scaleAnim, setScaleAnim] = useState(new Animated.Value(1));

    const _onPresIn = () => {
        if (props.onPress && !props.noAnimation) {
            Animated.timing(scaleAnim, {
                toValue: 0.99,
                duration: 100,
                useNativeDriver: false,
            }).start();
        }
    };

    const _onPressOut = () => {
        if (props.onPress && !props.noAnimation) {
            Animated.timing(scaleAnim, {
                toValue: 1,
                duration: 100,
                useNativeDriver: false,
            }).start();
        }
    };

    const _onPress = () => {
        if (props.onPress) {
            props.onPress()
        }
    };

    return (
        <Animated.View
            style={[styles.itemContainer, (props.noShadow === true ? {} : styles.shadow),
            { transform: [{ scale: scaleAnim }] }, props.style]}
        >
            <TouchableOpacity
                activeOpacity={1}
                style={[styles.touchable, props.contentContainerStyle]}
                onPressIn={_onPresIn}
                onPressOut={_onPressOut}
                onPress={_onPress}>
                {props.children}
            </TouchableOpacity>
        </Animated.View>
    );
}

let updateStyle = (height, width, margin) => {
    return StyleSheet.create({
        itemContainer: {
            borderRadius: 5,
            alignSelf: 'center',
            borderColor: AppStyle.textColorLight,
            backgroundColor: AppStyle.backgroundColorWithIntensity(1),
            minHeight: height,
            maxHeight: height,
            width: width,
            maxWidth: 600,
            flex: 1
        },
        shadow: {
            shadowOffset: {
                width: 0,
                height: 0
            },
            shadowOpacity: 0.1,
            shadowRadius: 8,
            elevation: 4,
        },
        touchable: {
            height: '100%',
            width: '100%',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
        }
    });
}
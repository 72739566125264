import {
    StyleSheet,
    Text,
    Linking
} from 'react-native';
import React from 'react';

import { AppStyle, TextStyle } from "@style";
import { replaceLocale } from '@utilities/locale'


const treatChildrenLocale = (childs) => {


    if (typeof childs === 'object') {

        let copy = {...childs}
        for (let i in childs) {
            let child = copy[i]
            if (typeof child === 'string') {
                copy[i] = child.localise()
            }
        }

        return childs
    } else if (typeof childs === 'string') {
        return childs.localise()
    }
    return childs
}


export function SimpleText(props) {

    const style = StyleSheet.create({
        base: {
            ...TextStyle.weight.medium,
            ...TextStyle.size.medium,
            color: AppStyle.textColor
        }
    });
    if (typeof props.children === 'undefined' || props.children == null)
        return null
    let content = treatChildrenLocale(props.children)
    return (
        <Text selectable={props.selectable} style={[style.base, props.style]}
            onPress={props.onPress}
            ellipsizeMode={props.ellipsizeMode}
            numberOfLines={props.numberOfLines}>
            {content}
        </Text>
    );


}


export const transformToHyperLink = (str, style) => {

    const regex = /^(?<start>.*)\[(?<text>.+)\]\((?<url>[^ ]+)\)(?<end>.*)$/;

    const res = regex.exec(str)

    if (typeof res?.groups?.text !== 'undefined' && typeof res?.groups?.url !== 'undefined') {
        return <SimpleText style={style}>
            {transformToHyperLink(res?.groups?.start, style)}
            <SimpleText
                style={{ color: AppStyle.hyperlinkColor, ...style }}
                onPress={() => { Linking.openURL(res?.groups?.url) }}>
                {res?.groups?.text}
            </SimpleText>
            {res?.groups?.end}
        </SimpleText>
    }

    //<SimpleText onPress={() => {Linking.openURL(m["url"])}}>{m["text"]}</SimpleText>
    return str
}
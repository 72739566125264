
const Colors = {
    light: {
        sat: '#FFFFFF',
        1: '#FFFFFF',
        2: '#FFFFFF',
        3: '#FFFFFF',
        4: '#FFFFFF',
        5: '#FFFFFF',
    },
    main: {
        1: '#F08070',
        2: '#F39C8C',
        3: '#F7B7A8',
        4: '#FCDCD4',
        5: '#F08070',
        'inv': '#E1EFE300',
    },
    secondary: {
        1: '#A9C23E',
        2: '#BCCE69',
        3: '#CEDA93',
        4: '#DFE7BA',
        5: '#EFF3DE',
    },
    secondaryAccent: {
        1: '#E7CEB5',
        2: '#ECD8C4',
        3: '#F0E1D3',
        4: '#F5EBE1',
        5: '#FBF5F1',
    },
    mainAccent: {
        1: '#0091B3',
        2: '#2AA6C1',
        3: '#71BCD0',
        4: '#A3D2DF',
        5: '#D1E8EF',
    },
    dark: {
        1: '#4A4F54',
        2: '#6B6F73',
        3: '#8D9094',
        4: '#B1B4B6',
        5: '#D7D8D9',
        6: '#F5F5F5',
    },
    transparent: 'rgba(0, 0, 0, 0)',
    card: '#DCE1E5',
    errorRedLight: '#FFDFE3',
    successGreenLight: '#DDF9EA'
};


let AppStyle = {
    smallBorderRadius: 10,
    borderRadius: 15,
    xborderRadius: 20
}

AppStyle.getColor = (branch, code) => Colors[branch][code]
AppStyle.update = (colorScheme) => {
    AppStyle.colorScheme = colorScheme
    AppStyle.errorColor = '#ff0000'
    AppStyle.errorLightColor = Colors.errorRedLight
    AppStyle.warningColor = "#ff9000"
    AppStyle.warningLightColor = "#ffC000"
    AppStyle.mainColor = AppStyle.getColor('main', 1)
    if (colorScheme === 'dark') {
        AppStyle.backgroundColor = AppStyle.getColor('dark', 0)
        AppStyle.cardBackground = AppStyle.getColor('dark', 1)
        AppStyle.cardBackgroundInverted = AppStyle.getColor('secondaryAccent', 3)
        AppStyle.textHeaderColor = AppStyle.getColor('main', 1)
        AppStyle.textColor = AppStyle.getColor('light', 1)
        AppStyle.textColorInverted = AppStyle.getColor('dark', 1)
        AppStyle.backgroundColorInverted = AppStyle.getColor('light', 1)
        AppStyle.textColorWithIntensity = (bright) => AppStyle.getColor('light', bright)
        AppStyle.textColorInvertedWithIntensity = (bright) => AppStyle.getColor('dark', bright)
        AppStyle.backgroundColorWithIntensity = (bright) => AppStyle.getColor('dark', bright)
        AppStyle.backgroundColorInvertedWithIntensity = (bright) => AppStyle.getColor('light', bright)
        AppStyle.cardBackgroundWithIntensity = (bright) => AppStyle.getColor('dark', bright)
        AppStyle.cardBackgroundInvertedWithIntensity = (bright) => AppStyle.getColor('dark', bright)

        AppStyle.primaryButton = AppStyle.getColor('main', 1)
        AppStyle.secondaryButton = AppStyle.getColor('main', 1)
        AppStyle.tertiaryButton = AppStyle.getColor('main', 1)
    } else {
        AppStyle.backgroundColor = '#F8F9FA'
        AppStyle.cardBackground = Colors.card
        AppStyle.cardBorder = AppStyle.getColor('dark', 1)
        AppStyle.cardBackgroundInverted = AppStyle.getColor('dark', 1)
        AppStyle.textHeaderColor = AppStyle.getColor('main', 1)
        AppStyle.textColor = '#000000'
        AppStyle.textColorInverted = '#FFFFFF'
        AppStyle.backgroundColorInverted = AppStyle.getColor('dark', 0)
        AppStyle.textColorWithIntensity = (bright) => AppStyle.getColor('dark', bright)
        AppStyle.textColorInvertedWithIntensity = (bright) => AppStyle.getColor('light', bright)
        AppStyle.backgroundColorWithIntensity = (bright) => AppStyle.getColor('light', bright)
        AppStyle.backgroundColorInvertedWithIntensity = (bright) => AppStyle.getColor('dark', bright)
        AppStyle.cardBackgroundWithIntensity = (bright) => AppStyle.getColor('secondaryAccent', bright)
        AppStyle.cardBackgroundInvertedWithIntensity = (bright) => AppStyle.getColor('dark', bright)

    }
    AppStyle.placeHolderColor = AppStyle.textColor + '33'
    AppStyle.textColorLight = AppStyle.textColor + '70'
    AppStyle.textColorSemiLight = AppStyle.textColor + 'B0'
    AppStyle.hyperlinkColor = AppStyle.getColor('mainAccent', 1)
}

export {
    AppStyle
}
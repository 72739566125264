import React, { useState } from 'react';
import {
    StyleSheet,
    Dimensions,
    View
} from 'react-native';
import { useHeaderHeight } from "@react-navigation/stack";
import { useSafeArea } from 'react-native-safe-area-context';

import { Card } from '@atoms';
import { AppStyle } from '@style'

export function HeaderCard(props) {

    let safeArea = { ...useSafeArea() };

    let headerHeight = 50
    let extra = (typeof props.extraHeight !== 'undefined' ? props.extraHeight : 0)

    if (props.useSafeArea) {
        safeArea.top = safeArea.top + extra
    }
    const styles = updateStyles(headerHeight + extra, safeArea)

    headerHeight += extra

    return (<View style={{ marginBottom: 30, zIndex: 1 }}>
            <View
                style={{
                    backgroundColor: AppStyle.mainColor,
                    height: 2000,
                    position: 'absolute',
                    top: -2000,
                    left: 0,
                    right: 0,
                    ...props.style,
                }}
            />
            <View style={{
                backgroundColor: AppStyle.mainColor,
                minHeight: headerHeight,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 999, minWidth: 250,
                ...props.style,
            }}>
                <Card
                    onPress={props.onPress}
                    style={[styles.card, props.cardStyle]}>
                    {props.children}
                </Card>
            </View>
        </View>

    );
}

const updateStyles = (headerHeight, safeArea) => StyleSheet.create({
    card: {
        top: 10,
        minHeight: 80,
        borderWidth: 0,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.2,
        shadowRadius: 4,
        elevation: 10,
        width: '90%',
        backgroundColor: AppStyle.backgroundColor,
    }
});
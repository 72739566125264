export const APP = {
    'mangopay': require('./app/mangopay.png')
}

export const CATEGORIES = {
    'fruit-vegatbles': require('./categories/fruit-vegetables.png'),
    'meat-sausages': require('./categories/meat-sausages.png'),
    'oils': require('./categories/oil.png'),
    'honey-jam': require('./categories/honey-jam.png'),
    'dairy': require('./categories/dairy.png'),
    'pantry': require('./categories/pantry.png'),
    'winery': require('./categories/winery.png'),
    'spices-aromatic': require('./categories/spices.png'),
    'coffee-tea': require('./categories/coffe-tea.png'),
    'chocolate-sweets': require('./categories/chocolate-sweets.png'),
    'fish-seaweed-seafood': require('./categories/fish-seaweed-seafood.png'),
    'other': require('./categories/other.png')
}

export const ILLUSTRATIONS = {
    'waiting': require('./illustrations/waiting.png'),
    'working': require('./illustrations/working.png'),
    'noResults': require('./illustrations/noResults.png'),
    'notFound': require('./illustrations/notFound.png'),
    'hello': require('./illustrations/hello.png'),
    'update': require('./illustrations/update.png'),
    'mail': require('./illustrations/mail.png'),
    'localSearch': require('./illustrations/localSearch.png'),
    'confirm': require('./illustrations/confirm.png'),
}

export const PRODUCER_ICONS = {
    'settings': require('./producerIcons/producerSettings.png'),
    'finances': require('./producerIcons/producerFinances.png'),
    'orders': require('./producerIcons/producerOrders.png'),
    'warehouse': require('./producerIcons/warehouse.png'),
}


export const LANGUAGES = {
    'ca': require('./languages/cat.svg'),
    'es': require('./languages/es.svg'),
    'en': require('./languages/en.svg'),
}

export const APP_ICONS = {
    'logo': require('./app/logo.png'),
    'logoHorizontal': require('./app/logoHorizontal.png'),
    'logoBase': require('./app/logoBase.png'),
}
import React, { useState, useRef } from 'react';
import {
    Platform,
    View,
    Dimensions,
    LayoutAnimation
} from 'react-native';
import { Icon } from '@quarks'

import { Card } from '@atoms'
import { SimpleText } from '@quarks'
import { Icons } from '@icons'
import { AppStyle, TextStyle } from '@style'

export const FakeSearchBar = (props) => {

    const text = props.text
    const onPress = props.onPress
    const asInput = props.asInput
    const viewWidth = Dimensions.get('window').width * 0.90

    return <View style={{
        flexDirection: 'row',
        alignItems: 'center', backgroundColor: 'transparent', width:viewWidth 
    }}>
        <Card
            style={{
                borderColor: AppStyle.getColor('dark', 1),
                borderWidth:1,
                borderRadius: 8,
                shadowRadius: 0,
                flexDirection: 'row',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center'
            }}
            noAnimation
            contentContainerStyle={{
                flexDirection: 'row',
                width: '100%',
            }}
            onPress={onPress}
            height={35}>
            <Icon
                name={Icons.SEARCH}
                size={18}
                text={text}
                style={{
                    position: 'absolute',
                    left: 10,
                }}
                color={AppStyle.mainColor} />

            {!asInput &&
                <SimpleText style={{
                    ...TextStyle.weight.thin,
                    ...TextStyle.size.small,
                    color: AppStyle.placeHolderColor,
                    width: '100%',
                    alignSelf: 'center',
                    position: 'absolute',
                    paddingLeft: 35,
                }} ellipsizeMode={'tail'} numberOfLines={1} >{text}</SimpleText>
            }
            {asInput &&
                <SimpleText style={{
                    ...TextStyle.weight.thin,
                    ...TextStyle.size.small,
                    width: '100%',
                    alignSelf: 'center',
                    position: 'absolute',
                    paddingLeft: 35,
                }} ellipsizeMode={'tail'} numberOfLines={1} >{text}</SimpleText>
            }

        </Card>
    </View>


}
import React, { useState } from 'react';
import { View, StyleSheet, FlatList, Platform, ScrollView, Pressable } from 'react-native';
import { CheckBox } from '@platform'

import { SimpleText } from '@quarks';
import { AppStyle } from '@style';

export function SimpleCheckBox(props) {

    const styles = updateStyle(props.height, props.width, props.margin)

    const _onValueChange = typeof props.onValueChange === 'function' ? props.onValueChange : () => { }
    const value = props.value
    const text = typeof props.text === 'string' ? <SimpleText style={props.textStyle}>{props.text}</SimpleText> : props.text
    const _onTextPressed = typeof props.onTextPressed === 'function' ? props.onTextPressed : () => { }

    return <View style={[styles.viewStyle, props.style]}>
        {Platform.OS === "ios" &&
            <View style={[styles.checkbox, props.checkBoxStyle]}>
                <CheckBox
                    hideBox
                    value={value}
                    style={{ height: 20, width: 20 }}
                    onCheckColor={AppStyle.getColor('main', 1)}
                    onValueChange={_onValueChange}
                />
            </View>
        }
        {Platform.OS != "ios" &&
            <CheckBox
                style={[styles.checkbox, props.checkBoxStyle]}
                value={value}
                onValueChange={_onValueChange} />
        }
        {<SimpleText style={[styles.textStyle,props.textStyle]} onPress={_onTextPressed}>
            {text}
        </SimpleText>}
    </View>
}

let updateStyle = () => {
    return StyleSheet.create({
        textStyle: {
            flex: 1,
            paddingLeft: 5
        },
        checkbox: {
            marginRight: 10,
            borderColor: AppStyle.getColor('main', 1),
            borderWidth: 1,
            height: 22,
            width: 22,
            borderRadius: 5
        },
        viewStyle: {
            width: '100%',
            height: 60,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingBottom: 5,
            paddingTop: 5,
        },
    });
}
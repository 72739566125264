import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    id: 0,
    ...
}
*/

const expandContractItem = createReducer( {id: 0} )( {
    [ types.EXPAND_ITEM ]: ( state, action ) => {
        if (state.id === action.meta.id) {
            return {id: 0}
        }
        return action.meta
    }
} );

const updateBasket = createReducer( {id: 0} )( {
    [ types.ADD_ITEM ]: ( state, action ) => {
        let newState = Object.assign({}, state)
        let indexFound = newState.list.indexOf(action.item)
        if (indexFound == -1) {
            newState.list.push(Object.assign({}, action.item))
        } else {
            newState.list[indexFound].selected += 1
        }
        newState.total += newState.list[indexFound].total
        newState.items += 1
        return newState
    },
    [ types.DELETE_ITEM ]: ( state, action ) => {
        let newState = Object.assign({}, state)
        let indexFound = newState.list.indexOf(action.item)
        if (indexFound != -1) {
            let itemFound = newState.list[indexFound]
            if (itemFound.selected === 1) {
                newState.list.splice(indexFound, 1)
            } else {
                itemFound.selected -= 1
            }
            newState.items -= 1
            newState.total -= newState.list[indexFound].price
        }
        return newState
    }
} );

export default combineReducers( {
    expandedItem: expandContractItem,
    sumary: updateBasket
} );

export const Icons = {
    QUESTION: 'question',
    UPLOAD: 'upload',
    CLOCK: 'clock',
    SEARCH: 'search',
    ENVELOPE: 'envelope',
    PHONE: 'phone',
    POSTALCODE: 'mail-bulk',
    MARKET: 'tag',
    LOCATION: 'map-marker-alt',
    LOCATION_ARROW: 'location-arrow',
    STORE: 'store-alt',
    MAP_PIN: 'map-pin',
    PAPER_PLANE: 'paper-plane',
    PAPER_PLANE_O: 'paper-plane-o',
    CITY: 'city',
    MAILBOX: 'mail-bulk',
    DIRECTIONS: 'directions',
    CALENDAR: 'calendar',
    PICTURE: 'picture',
    USER: 'user-alt',
    USER_ACCOUNT: 'user-circle',
    USER_SETTINGS: 'user-cog',
    SETTING: 'cog',
    SETTINGS: 'cogs',
    ORDERS: 'truck-loading',
    KEY: 'key',
    LOCK: 'lock',
    MAIL: 'envelope',
    PLUS: 'plus',
    MINUS: 'minus',
    USERS: 'users',
    BACK: 'chevron-left',
    FORWARD: 'chevron-right',
    DOWN: 'chevron-down',
    UP: 'chevron-up',
    FILTER: 'filter',
    CLOSE: 'times',
    EDIT: 'pen',
    SECURITY: 'user-lock',
    KEY: 'key',
    REDO: 'redo',
    WARNING: 'exclamation-triangle',
    LOCK: 'lock',
    LOCKOPEN: 'lock-open',
    EYESLASH: 'eye-slash',
    EYE: 'eye',
    CARD: 'credit-card',
    CARDNAME: 'address-card',
    TIMES: 'times',
    SUCCESS: 'check-circle',
    HOME: 'home',
    WEB: 'mouse-pointer',
    PICKUP: 'people-carry',
    DELIVERY: 'truck',
    BASKET: 'shopping-basket',
    ERROR: 'exclamation-circle',
    LOGOUT: 'sign-out-alt',
    CART: 'shopping-cart',
    WAITING: 'hourglass-start',
    CHECK: 'clipboard-check',
    TRUCK: 'truck',
    TRUCKLOADING: 'truck-loading',
    WORLD: 'globe-europe',
    BUILDING: 'building',

    PDF: 'file-pdf',
    IMAGE: 'file-image',
    FILE: 'file',

    EURO: 'euro-sign',
    WEIGHT: 'weight-hanging',
    TAGS: 'tags',
    TAG: 'tag',
    TICKET: 'ticket-alt',
    WAREHOUSE: 'warehouse',
    PENCIL: 'pencil-alt',
    DESCRIPTION: 'comment-alt',
    VISA: 'cc-visa',
    MASTERCARD: 'cc-mastercard',
    AMEX: 'cc-amex',
    DISCOVER: 'cc-discover',
    JCB: 'cc-jcb',
    DINERS: 'cc-diners-club',
    CARDNAME: 'address-card',
    STAR: 'star',
    BANK: 'university',
    LANGUAGE: 'language',

    TRANSFER: 'exchange-alt'
}

export const NadiusIcons = {
    HOME : 'home',
    certificates : 'certificates',
    delivery : 'delivery',
    FARM : 'farm',
    status : 'status',
    INFO : 'info',
    PRODUCT : 'product',
    INVOICE : 'invoice',
    SETTINGS : 'settings',
    ORDER : 'order',
    MORE : 'more',
    notification : 'notification',
    check : 'check',
    truckDelivery : 'truck-delivery',
    calendar : 'calendar',
    downArrow : 'down-arrow',
    DOWN : 'down',
    leftArrow : 'left-arrow',
    left : 'left',
    RIGHT_ARROW : 'right-arrow',
    RIGHT : 'right',
    upArrow : 'up-arrow',
    UP : 'up',
    time : 'time',
    arrowSQLeft : 'arrow-sq-left',
    arrowSQDown : 'arrow-sq-down',
    arrowSQRight : 'arrow-sq-right',
    arrowSQUp : 'arrow-sq-up',
    CLOSE : 'close',
    euOraginc : 'eu-oraginc',
    likeO : 'like-o',
    like : 'like',
    localizacion : 'localizacion',
    spot : 'spot',
    minus : 'minus',
    minusO : 'minus-o',
    plus : 'plus',
    plusO : 'plus-o',
    shopCart : 'shop-cart',
    star : 'star',
    TRASH : 'trash',
    creditcard : 'creditcard',
    check : 'check',
    filter : 'filter',
    localizacion : 'localizacion',
    menu : 'menu',
    notification : 'notification',
    search : 'search',
    favorites : 'favorites',
    news : 'news',
    shop : 'shop',
    ordes : 'order',


    iconSet : 'nadius'
}







export const ADD_PRODUCTS = require('./addProducts.png')
export const ADD_PRODUCTS_PENDING = require('./addProductsPending.png')
export const ADD_PRODUCTS_COMPLETED = require('./addProductsCompleted.png')

export const COMPANY_INFO = require('./comanyInfo.png')
export const COMPANY_INFO_PENDING = require('./comanyInfoPending.png')
export const COMPANY_INFO_COMPLETED = require('./comanyInfoCompleted.png')

export const DELIVERY_ZONE = require('./deliveryZone.png')
export const DELIVERY_ZONE_PENDING = require('./deliveryZonePending.png')
export const DELIVERY_ZONE_COMPLETED = require('./deliveryZoneCompleted.png')

export const PUBLIC_PROFILE = require('./publicProfile.png')
export const PUBLIC_PROFILE_PENDING = require('./publicProfilePending.png')
export const PUBLIC_PROFILE_COMPLETED = require('./publicProfileCompleted.png')
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { Icon } from '@quarks'

import { ImageCard, Banner } from '@atoms'
import { AppStyle, TextStyle } from '@style';
import { ButtonText, SimpleText } from '@quarks';
import { CollapsileElement, CustomizableCardGrid } from '@molecules';
import { Icons } from '@icons';
import { IMAGE_SIZES } from '@utilities/images'
import { order } from 'nexto-codes'
import { getOptimizedZone, getUpCommingDelivery, getDeliveryDays } from '@utilities/delivery'

export function ProfileHeader(props) {

    let [imageViewState, setImageViewState] = useState(false)
    let [deliveryCollapsed, setDeliveryCollapsed] = useState(true)

    const producer = props.producer;

    let sizes = Dimensions.get('window')
    let coverHeight = sizes.height * 0.25;
    let imageSize = 180

    const styles = updateStyles(imageSize - 30, coverHeight)

    const _locationHeader = (location) => {
        if (!location?.place_name) return

        return (<View style={styles.locationView}>
            <Icon
                name={Icons.LOCATION}
                size={20}
                color={AppStyle.textColor}
                style={[{ alignSelf: 'center' }]}
            />
            <SimpleText numberOfLines={2}
                ellipsizeMode={'tail'}
                style={styles.location}>{location?.place_name}</SimpleText>
        </View>)
    }

    const generateSendingDays = (dayArray) => {
        if (dayArray.length == 0) {
            return
        }
        let days = [...dayArray]
        days.sort((a, b) => a - b)

        if (days.length === 7) {
            return '$common.components.profileHeader.allDays'
        }

        let checker = (arr, target) => target.every(v => arr.includes(v));
        if (days.length > 4 && checker(days, [0, 1, 2, 3, 4])) {
            return '$common.components.profileHeader.allWeek'
        }

        let text = '$common.components.profileHeader.days'.localise() + ' ';
        for (let index = 0; index < days.length; index++) {
            const element = (order.SENDING_SCHEDULE_LABEL[days[index]]).localise();
            if (days.length > 1 && index === days.length - 1) {
                text += ` ${'$common.components.profileHeader.and'} ${element}`
            } else {
                if (days.length == 1 || index === days.length - 2) {
                    text += `${element}`
                } else {
                    text += `${element}, `
                }
            }
        }
        return text
    }

    let options = []

    let zone, index;
    if (props.zones) {
        let result = getOptimizedZone(props.zones, producer.delivery.zones)
        zone = result?.zone
        index = result?.index
    }


    if (typeof zone !== 'undefined') {

        if (typeof zone.modality?.external?.time !== 'undefined' &&
            zone.modality?.external?.time != null) {
            options.push({
                title: '$common.components.profileHeader.time',
                value: order.SENDING_TIME_LABEL[zone.modality.external.time],
                icon: Icons.CLOCK
            })
        }

        if (typeof zone.modality?.personal !== 'undefined' &&
            zone.modality?.personal != null) {
            options.push({
                title: '$common.components.profileHeader.upcomingDelivery',
                value: getUpCommingDelivery(zone.modality),
                icon: Icons.CLOCK
            })

            options.push({
                title: '$common.components.profileHeader.deliveryDays',
                value: getDeliveryDays(zone.modality),
                icon: Icons.CLOCK
            })
        }

        if (typeof zone.availableFrom != 'undefined' &&
            zone.availableFrom != null &&
            zone.availableFrom > 0) {
            options.push({
                title: '$common.components.profileHeader.minimum',
                value: parseFloat(zone.availableFrom),
                icon: Icons.DELIVERY
            })
        }

        if (typeof zone.price != 'undefined' &&
            zone.price != null) {
            let value;
            if (typeof zone.freeFrom != 'undefined' &&
                zone.freeFrom != null) {
                value = parseFloat(zone.freeFrom)
            }
            if (zone.price > 0) {
                if (typeof value !== 'undefined' && value !== 0) {
                    options.push({
                        title: '$common.components.profileHeader.freeDelivery',
                        prefix: '+',
                        value: value,
                        icon: Icons.DELIVERY
                    })
                }
                options.push({
                    title: '$common.components.profileHeader.delivery',
                    value: parseFloat(zone.price),
                    icon: Icons.DELIVERY
                })
            } else {
                if (typeof value !== 'undefined' && value === 0) {
                    options.push({
                        title: '$common.components.profileHeader.delivery',
                        value: value,
                        icon: Icons.DELIVERY
                    })
                }
            }
        }


    }

    let pickup = producer.delivery?.pickups?.[0]?.isEnabled

    const _renderOption = ({ index, item }) => {
        return <View style={styles.optionsItem}>
            <View style={{
                justifyContent: 'center', paddingLeft: index === 0 ? 10 : 0,
                paddingRight: index === options.length - 1 ? 20 : 0
            }}>
                <SimpleText style={styles.optionsHeader}>{item.title}</SimpleText>
                <SimpleText style={[styles.optionsHeader]}>
                    {typeof item.value === 'number' && item.value > 0 && <SimpleText style={styles.optionsText}>{item.prefix} {item.value?.toFixed(2) + ' €'}</SimpleText>}
                    {typeof item.value === 'number' && item.value === 0 && <SimpleText style={styles.optionsText}>$common.components.profileHeader.free</SimpleText>}
                    {typeof item.value === 'string' && <SimpleText style={styles.optionsText}>{item.value}</SimpleText>}
                    {typeof item.value === 'object' && item.value}
                </SimpleText>

            </View>
            {index !== options.length - 1 &&
                <View style={{
                    borderRightWidth: 1, marginLeft: 10, marginRight: 10, height: 50,
                    borderColor: AppStyle.placeHolderColor
                }} />}
        </View>
    }

    return (<View style={{
        maxWidth: 500,
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: AppStyle.backgroundColor
    }}>
        <View style={{ flexDirection: 'row', alignSelf: 'center', height: imageSize }}>
            <ImageCard
                borderRadius={20}
                style={styles.profile}
                image={producer.images?.profile}
                imgType={IMAGE_SIZES.CARD}>
            </ImageCard>
            <View style={{ paddingLeft: 40, paddingTop: 30, flex: 1 }}>
                <SimpleText ellipsizeMode={'tail'} numberOfLines={2} style={{
                    ...TextStyle.size.xlarge,
                    ...TextStyle.weight.semibold
                }}>
                    {producer.name}
                </SimpleText>
                {producer.location && _locationHeader(producer.location)}
                <ButtonText
                    type={'solid'}
                    style={styles.buttonStyle}
                    iconSize={25}
                    text={'$common.components.profileHeader.viewProfile'}
                    onPress={props.onPress}
                />
            </View>
        </View>
        {
            typeof props.banner != 'undefined' &&
            <Banner
                style={{ alignSelf: 'center', marginTop: 10 }}
                banner={props.banner} />
        }<View style={{
            alignSelf: 'center', marginTop: 10,
            justifyContent: 'flex-start', marginBottom: 15
        }}>
            {zone && <View style={{ marginBottom: pickup ? 5 : -10, justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', marginLeft: '8%', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon
                            name={Icons.DELIVERY}
                            size={20}
                            color={AppStyle.textColor}
                        />
                        <SimpleText style={{ marginLeft: 10, ...TextStyle.size.small }}>$common.components.profileHeader.deliveryConditions</SimpleText>
                    </View>
                    {!pickup && <ButtonText iconRight icon={Icons.FORWARD} iconStyle={{ ...TextStyle.size.mini, marginLeft: 5 }}
                        type={'clear'} style={{ paddingLeft: 10, paddingRight: 10 }}
                        onPress={props.onViewMoreDeliveryInfo && props.onViewMoreDeliveryInfo(index)}
                        text={'$common.components.profileHeader.seeMore'} textStyle={{ alignSelf: 'center', ...TextStyle.size.small }} />}
                </View>
                <View style={{ width: '100%' }}>
                    <CustomizableCardGrid
                        style={{ alignSelf: 'center', height: 70, paddingLeft: 20 }}
                        cards={options}
                        horizontal
                        renderItem={_renderOption}
                        listType='list'
                        itemDimension={100}
                    />
                </View>
            </View>}
            {pickup && <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', marginLeft: '8%', }}>
                    <Icon
                        name={Icons.STORE}
                        size={20}
                        color={AppStyle.textColor}
                    />
                    <SimpleText style={{ marginLeft: 10, ...TextStyle.size.small }}>$common.components.profileHeader.pickupEnabled</SimpleText>
                </View>
                <ButtonText iconRight icon={Icons.FORWARD} iconStyle={{ ...TextStyle.size.mini, marginLeft: 5 }}
                    type={'clear'} style={{ paddingLeft: 10, paddingRight: 10 }}
                    onPress={props.onViewMoreDeliveryInfo && props.onViewMoreDeliveryInfo(index)}
                    text={'$common.components.profileHeader.seeMore'} textStyle={{ alignSelf: 'center', ...TextStyle.size.small }} />
            </View>}
        </View>
        {typeof producer.delivery?.days != 'undefined' &&
            producer.delivery?.days != null &&
            <SimpleText ellipsizeMode={'tail'} numberOfLines={2} style={{ textAlign: 'center', maxWidth: '80%', alignSelf: 'center' }}>
                {generateSendingDays(producer.delivery.days)}</SimpleText>}
        {(typeof props.separator === 'undefined' ||
            (typeof props.separator !== 'undefined' && props.separator)) && <View style={{
                borderBottomWidth: 1,
                borderColor: AppStyle.placeHolderColor,
                height: 10, width: '70%', alignSelf: 'center',
                marginBottom: 10
            }} />}
    </View>)
}

const updateStyles = (imageSize, coverDimensions) => StyleSheet.create({
    imageViewFooter: {
        marginBottom: 50,
        alignSelf: 'center'
    },
    footerText: {
        ...TextStyle.size.large,
        ...TextStyle.padding.large,
        ...TextStyle.weight.regular,
        ...TextStyle.align.center,
        color: AppStyle.getColor('light', 1)
    },
    imageView: {
        height: '100%',
        width: '100%',
    },
    cover: {
        width: '100%',
        height: coverDimensions,
    },
    profile: {
        left: 20,
        marginTop: 25,
        width: imageSize,
        maxWidth: imageSize,
        height: imageSize,
    },
    locationView: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 5
    },
    location: {
        ...TextStyle.size.regular,
        color: AppStyle.mainColor,
        paddingLeft: 10,
        width: '90%'
    },
    title: {
        marginLeft: 150
    },
    buttonStyle: {
        alignSelf: 'flex-start',
        marginTop: 10,
        paddingTop: 4,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 20,
        backgroundColor: AppStyle.getColor('main', 2)
    },
    optionsText: {
        color: AppStyle.textColor,
        ...TextStyle.size.small,
        ...TextStyle.weight.bold,
        ...TextStyle.align.center,
    },
    optionsHeader: {
        color: AppStyle.textColor,
        ...TextStyle.size.mini,
        ...TextStyle.weight.regular,
        ...TextStyle.align.center,
        marginRight: 5,
    },
    optionsContainer: {
        flexDirection: 'row',
        width: '80%',
        height: 'auto',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: AppStyle.cardBackground,
        borderRadius: 10,
        alignSelf: 'center',
        marginBottom: 0,
        marginTop: 10,
        paddingTop: 5,
        paddingBottom: 5
    },
    optionsItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
    }
});

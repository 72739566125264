import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Icon } from '@quarks'

import { AppStyle, TextStyle } from '@style';
import { SimpleText } from '@quarks';
import { Icons } from '@icons';

export function Direction(props) {

    const direction = props.direction
    const edit = props.edit
    const [height, setHeight] = useState(0)

    const onLayout = (e) => {
        setHeight(e.nativeEvent.layout.height)
    }

    const styles = updateStyles()

    let color = AppStyle.textColor
    if (typeof props.available !== 'undefined' && !props.available) {
        color = AppStyle.placeHolderColor
    }

    if (typeof direction == 'undefined') {
        return null
    }

    return (<View
        onLayout={onLayout}
        style={[{
            alignSelf: 'center',
            padding: 10, width: '100%'
        }, props.selected && {
            borderWidth: 2, borderRadius: 20, borderColor: AppStyle.mainColor,
        }, props.style]}>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center'
        }}>
            <View style={{ minWidth: '20%', justifyContent: 'center' }}>
                <Icon
                    name={props.pickup === true ? Icons.CARDNAME : Icons.LOCATION}
                    size={40}
                    color={color ? color : AppStyle.textColor}
                    style={styles.locationIcon} />
            </View>
            <View style={[styles.itemStyle, { paddingLeft: 5 }]}>
                <SimpleText style={{ color: color, ...TextStyle.weight.bold }}>{direction.name}</SimpleText>
                {typeof props.reduced === 'undefined' && direction.phone && <SimpleText style={{ color: color, ...TextStyle.size.small }}>{direction.phone.countryCode + ' ' + direction.phone.number}</SimpleText>}
                {direction.location?.street?.name && props.pickup !== true && <SimpleText
                    numberOfLines={2}
                    ellipsizeMode={'tail'}
                    style={{
                        color: color
                    }}>
                    {direction.location.street.name} {direction.location.street.flat ? `, ${direction.location.street.flat}` : ''}
                </SimpleText>}
                {direction.location && props.pickup !== true &&
                    <SimpleText
                        numberOfLines={2}
                        ellipsizeMode={'tail'}
                        style={{ color: color }}>
                        {direction.location.place_name} {direction.location.postal_code}, {direction.location.admin_name2}
                    </SimpleText>}
            </View>
        </View>
        {props.pickup !== true && typeof direction.note !== 'undefined' && direction.note !== null &&
            <View style={{ marginTop: 5, justifyContent: 'center', alignSelf: 'center', width: '90%' }}>
                <SimpleText style={{ color: color, ...TextStyle.weight.bold }}>Nota</SimpleText>
                <SimpleText style={{ color: color, ...TextStyle.size.small }}>{direction.note}</SimpleText>
            </View>}
        {edit &&
            <Icon
                name={Icons.FORWARD}
                size={20}
                color={'black'}
                style={[styles.editIcon, { top: height / 2 - 10 }]} />
        }
    </View>
    )
}

const updateStyles = () => StyleSheet.create({
    itemStyle: {
        alignSelf: 'center',
        width: '90%',
        maxWidth: '80%'
    },
    locationIcon: {
        alignSelf: 'center',
        marginLeft: -5
    },
    editIcon: {
        position: "absolute",
        right: 0,
        width: 50,
        marginRight: 10
    }
});

import React, { useState } from 'react';
import { createIconSet, AntDesign, FontAwesome5 } from '@expo/vector-icons';

const glyphMap = {
    'home': '\u0041',
    'certificates': '\u0042',
    'delivery': '\u0043',
    'farm': '\u0044',
    'status': '\u0045',
    'info': '\u0046',
    'product': '\u0047',
    'invoice': '\u0048',
    'settings': '\u0049',
    'order': '\u004a',
    'more': '\u004b',
    'notification': '\u004c',
    'check': '\u004d',
    'truck-delivery': '\u004e',
    'calendar': '\u004f',
    'down': '\u0051',
    'down-arrow': '\u0052',
    'left-arrow': '\u0054',
    'up-arrow': '\u0056',
    'right-arrow': '\u0050',
    'left': '\u0053',
    'right': '\u0055',
    'up': '\u0057',
    'time': '\u0058',
    'arrow-sq-left': '\u0059',
    'arrow-sq-down': '\u005a',
    'arrow-sq-right': '\u0061',
    'close': '\u0062',
    'eu-oraginc': '\u0063',
    'like-o': '\u0064',
    'like': '\u0065',
    'localizacion': '\u0066',
    'spot': '\u0067',
    'minus': '\u0068',
    'minus-o': '\u0069',
    'plus': '\u006a',
    'plus-o': '\u006b',
    'shop-cart': '\u006c',
    'star': '\u006d',
    'arrow-sq-up': '\u006e',
    'trash': '\u006f',
    'creditcard': '\u0070',
    'check': '\u0071',
    'filter': '\u0072',
    'localizacion': '\u0073',
    'menu': '\u0074',
    'notification': '\u0075',
    'search': '\u0076',
    'favorites': '\u0077',
    'news': '\u0078',
    'shop': '\u007a',
    'order': '\u0030',
};

export const NadiusIcon = createIconSet(glyphMap, 'Nadius-Icons', '@fonts/nadius-icons.ttf');


export function Icon(props) {

    /**
     * 
     * props:
     *          iconSet: nadius | fontawesome | ...  
     *          name: the icon name
     *          ...props // Are propagated
     * 
     */

     switch (props.iconSet) {

        case 'nadius':
            return <NadiusIcon {...props}/>
        case 'AntDesign':
            return <AntDesign {...props}/>

        case 'Icon':
        default:
            return <FontAwesome5 {...props}/>
     }

}

import React from 'react';
import { Card } from '@atoms';
import { FontAwesome } from '@expo/vector-icons';
import { SimpleText } from '@quarks';
import { StyleSheet } from 'react-native';
import { AppStyle } from '@style';


export function CardIcon (props) {
    const styles = updateStyles()

    return (
        <Card 
            style={[styles.card, props.style]}
            onPress={props.onPress}
            height={50}>

            <FontAwesome
            name={props.icon}
            size={20}
            color={'black'}
            style={styles.iconStyle} />

            <SimpleText style={styles.text}>{props.text}</SimpleText>
        </Card>
    )
}

const updateStyles = () => StyleSheet.create({
    card: {
        marginBottom: 10,
        flex: 1,
        backgroundColor: AppStyle.cardBackground,
        shadowRadius: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '90%',
        paddingLeft: 5,
        paddingRight: 5
    },
    iconStyle: {
        color: AppStyle.textColor,
        flex: 1,
        paddingLeft: 30,
        alignSelf: 'center',
    },
    text: {
        color: AppStyle.textColor,
        paddingLeft: 15,
        flex: 10,
        width: '90%'
    },
});
import React from 'react';
import {
    BlurModal
} from '@atoms';

import { FeedbackView } from './feedbackView'
import { Platform } from 'react-native';

export const FeedbackModal = (props) => {

    const loading = props.loading
    const error = props.error
    const success = props.success
    
    return (<BlurModal
        visible={(loading || error || success) === true}
        style={[{ alignSelf: 'center' }, props.style]}>
        <FeedbackView
            timeoutIn={props.timeoutIn}
            onSuccess={props.onSuccess}
            onPress={props.onFeedbackPress}
            onTimeout={props.onTimeout}
            error={props.error}
            errorText={props.errorText}
            loading={props.loading}
            loadingText={props.loadingText}
            success={props.success}
            successText={props.successText} />
    </BlurModal>
    )
};


import React, { useState, useRef } from 'react';
import { View, StyleSheet, Image, ScrollView, Dimensions } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import i18n from 'i18n-js';
import moment from "moment/min/moment-with-locales"

import { SimpleText, ButtonText } from '@quarks'
import { Card, FloatingButton } from '@atoms'
import { replaceLocale } from '@utilities/locale'
import { TextStyle, AppStyle } from '@style'
import { ILLUSTRATIONS } from '@images'

const LanguageScreen = (props) => {

    const styles = updateStyles();
    let [language, setLanguage] = useState(undefined)

    const imgWidth = Dimensions.get("screen").width * 1
    let maxImgWidth = Dimensions.get("screen").width * 0.5
    if (maxImgWidth > 400) maxImgWidth = 400

    return (<View style={{ height: '100%', width: '100%', backgroundColor: AppStyle.backgroundColor }}>
        <ScrollView style={{ height: '100%', width: '100%' }}
            contentContainerStyle={{ width: '100%', justifyContent: 'center' }}>
            <Image
                style={{
                    height: imgWidth, width: imgWidth,
                    maxWidth: maxImgWidth, maxHeight: maxImgWidth,
                    alignSelf: 'center', marginTop: 0, marginBottom: -25
                }}
                source={ILLUSTRATIONS.hello}
            />
            <View style={styles.titleView}>
                <SimpleText
                    style={{
                        ...TextStyle.size.xxlarge,
                        ...TextStyle.weight.bold,
                        textAlign: 'center',
                        alignSelf: 'center'
                    }}>
                    {replaceLocale('$access.language.welcome', language)}
                </SimpleText>
            </View>
            <SimpleText
                style={{
                    ...TextStyle.size.regular,
                    width: '90%',
                    textAlign: 'center',
                    alignSelf: 'center',
                    paddingBottom: 20
                }}>
                {replaceLocale('$access.language.first', language)}
            </SimpleText>
            <SimpleText
                style={{
                    ...TextStyle.size.large,
                    width: '90%',
                    textAlign: 'center',
                    alignSelf: 'center',
                    paddingBottom: 20
                }}>
                {replaceLocale('$access.language.language', language)}
            </SimpleText>
            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingBottom: 100 }}>
                <Card style={[{
                    maxHeight: 100,
                    maxWidth: 100,
                    marginRight: 30
                }, language === 'ca' && styles.activeCard]}
                    onPress={() => {
                        setLanguage('ca')
                    }}>
                    <SimpleText style={{ textAlign: 'center' }}>
                        {replaceLocale('$access.language.catalan', language)}
                    </SimpleText>
                </Card>
                <Card style={[{
                    maxHeight: 100,
                    maxWidth: 100
                }, language === 'es' && styles.activeCard]}
                    onPress={() => {
                        setLanguage('es')
                    }}>
                    <SimpleText style={{ textAlign: 'center' }}>
                        {replaceLocale('$access.language.spanish', language)}
                    </SimpleText>
                </Card>
            </View>
        </ScrollView>
        {language && <FloatingButton
            style={{ width: '50%', marginBottom: 20 }}
            text={replaceLocale('$access.language.button', language)}
            onPress={async () => {
                await AsyncStorage.setItem('@nadius:locale', language)
                i18n.locale = language
                moment.locale(language)
                props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Login' }],
                });
            }}
        />}
    </View>

    )
};


const updateStyles = () => StyleSheet.create({
    cardView: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    activeCard: {
        borderColor: AppStyle.mainColor,
        borderWidth: 2
    },
    titleView: {
        paddingBottom: 20,
        width: '90%',
        alignSelf: 'center',
    },
    descriptionView: {
        paddingTop: 50,
        width: '90%',
        alignSelf: 'center'
    },
    main: {
        height: '100%',
        alignContent: 'center',
        justifyContent: 'center',
    }
});


export default LanguageScreen;

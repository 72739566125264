import React, { useState } from 'react';
import { StyleSheet, Keyboard, UIManager, Platform, Image, View, KeyboardAvoidingView, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'

import { useAuth } from '@realm'
import { CardInput, keyboardType, autoCompleteType, textContentType, autoCapitalize, FeedbackModal } from '@molecules'
import { ButtonText, SimpleText, transformToHyperLink } from '@quarks'
import { AppStyle, TextStyle } from "@style";
import { Icons } from '@icons'
import { sessionOperations } from '_ducks/session'
import { registerFormValidation } from 'nexto-formvalidator';
import { SimpleCheckBox } from '@atoms'
import { KeyboardAwareScrollView } from '@platform'

const RegisterScreen = (props) => {

    const styles = updateStyles()
    const dispatch = useDispatch()
    let emailRef = React.useRef(null)
    let passwordRef = React.useRef(null)
    let retypePasswordRef = React.useRef(null)

    // In order to force a view update
    useSelector(state => state.session.language)

    const { signUp, loading } = useAuth();
    const [state, setState] = useState({
        success: false,
        error: undefined,
        loading: false
    })
    const [form, setForm] = useState({
        accept: false
    })

    const navigate = () => {
        props.navigation.replace('AccessVerification');
    }

    const onSignUp = async () => {

        if (!form.accept) {
            return
        }

        setState({ ...state, loading: true })
        let actualForm = {
            name: form.name,
            confirmationPassword: form.confirmationPassword,
            password: form.password,
            email: form.email,
        }
        let result = registerFormValidation(actualForm)
        if (result.isValid) {
            Keyboard.dismiss()
            let user = await signUp(actualForm)
            if (typeof user === 'undefined') {
                setState({ ...state, loading: false, success: true, error: undefined })
                dispatch(sessionOperations.storeRegisterForm(form))
                navigate()
            } else {
                setState({ ...state, loading: false, error: user })
            }
        } else {
            setState({ ...state, loading: false, error: result.formErrors })
        }
    }

    const onChangeText = (name) => (text) => {
        let tmpForm = { ...form }
        tmpForm[name] = text
        setForm(tmpForm)
    }

    if (Platform.OS === 'android') {
        if (UIManager.setLayoutAnimationEnabledExperimental) {
            UIManager.setLayoutAnimationEnabledExperimental(true);
        }
    }

    return (<KeyboardAwareScrollView style={{
        width: '100%', flex: 1,
        alignSelf: 'center',
    }} contentContainerStyle={styles.mainView}>

        <CardInput
            style={styles.cardInput}
            title={'$access.register.nameTitle'}
            icon={Icons.USER}
            onChangeText={onChangeText('name')}
            errorText={state.error?.name}
            multiline={false}
            placeholder={'$access.register.namePlaceholder'}
            textContentType={textContentType.givenName}
            autoCompleteType={autoCompleteType.username}
            onSubmitEditing={() => emailRef.current.focus()}
            returnKeyType={'next'}
        />

        <CardInput
            style={styles.cardInput}
            ref={emailRef}
            title={'$access.register.emailTitle'}
            icon={Icons.ENVELOPE}
            onChangeText={onChangeText('email')}
            autoCompleteType={autoCompleteType.email}
            errorText={state.error?.email}
            multiline={false}
            keyboardType={keyboardType.emailAddress}
            textContentType={textContentType.username}
            placeholder={'$access.register.emailPlaceholder'}
            autoCapitalize={autoCapitalize.none}
            onSubmitEditing={() => passwordRef.current.focus()}
            returnKeyType={'next'}
        />

        <View style={{ width: '100%' }}>
            <CardInput
                style={styles.cardInput}
                ref={passwordRef}
                title={'$access.register.passwordTitle'}
                icon={Icons.LOCK}
                onChangeText={onChangeText('password')}
                errorText={state.error?.password}
                multiline={false}
                placeholder={'$access.register.passwordPlaceholder'}
                autoCompleteType={autoCompleteType.password}
                textContentType={textContentType.newPassword}
                autoCapitalize={autoCapitalize.none}
                password={true}
                onSubmitEditing={() => retypePasswordRef.current.focus()}
                returnKeyType={'next'}
            /></View>

        <CardInput
            style={styles.cardInput}
            ref={retypePasswordRef}
            title={'$access.register.retypePasswordTitle'}
            icon={Icons.LOCK}
            onChangeText={onChangeText('confirmationPassword')}
            errorText={state.error?.confirmationPassword}
            multiline={false}
            placeholder={'$access.register.retypePasswordPlaceholder'}
            autoCompleteType={autoCompleteType.password}
            textContentType={textContentType.newPassword}
            autoCapitalize={autoCapitalize.none}
            password={true}
            onSubmitEditing={form.accept && onSignUp}
            returnKeyType={'send'}

        />
        <SimpleCheckBox
            style={styles.checkboxes}
            value={form.accept}
            onValueChange={(state) => { setForm({ ...form, accept: state }); }}
            text={transformToHyperLink('$access.createProducerAccount.iAccept'.localise())} />

        {(typeof state.error?.message !== 'undefined' || typeof state.error == 'string') &&
            <SimpleText style={styles.errorText}>
                {typeof state.error == 'string' ? state.error : state.error.message}
            </SimpleText>}

        <View style={{ width: '60%', maxWidth: 350, paddingBottom: 20 }}>
            <ButtonText
                onPress={onSignUp}
                disabled={!form.accept}
                text={'$access.register.registerButton'}
                type={'solid'}
                loading={loading}
                style={{
                    width: '100%',
                    height: 50,
                    marginTop: 20
                }} />
        </View>
    </KeyboardAwareScrollView>)
}


const updateStyles = () => StyleSheet.create({
    mainView: {
        width: '95%',
        flex: 1, marginTop: 30,
        paddingLeft: 10, paddingRight: 10,
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
    },
    cardInput: {
        alignItems: 'center',
        width: '90%',
        marginBottom: 15
    },
    errorMessage: {
        marginTop: 10,
        marginLeft: 10,
        color: AppStyle.errorColor
    },
    checkboxes: {
        width: '90%', marginTop: 5,
        alignItems: 'center'
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
        paddingTop: 20
    },
});

export default RegisterScreen;
import React, { useState } from 'react';
import {
    Platform,
    StyleSheet,
    View
} from 'react-native';

import { CheckBox as CheckBoxCom } from '@platform'

import { AppStyle, TextStyle } from '@style'

export function CheckBox(props) {
    
    const styles = updateStyle()

    if (Platform.OS === "ios") {
        return (
            <View style={[styles.checkbox, props.style]}>
                <CheckBoxCom
                    hideBox
                    value={props.value}
                    style={{ height: 20, width: 20 }}
                    onCheckColor={AppStyle.getColor('main', 1)}
                    onValueChange={props.onCheckboxPress}
                />
            </View>
        )
    } else {
        return (
            <CheckBoxCom
                style={styles.checkbox}
                value={props.value}
                onValueChange={props.onCheckboxPress} />)
    }

}

let updateStyle = () => {
    return StyleSheet.create({         
        checkbox: {
            marginRight: 20,
            borderColor: AppStyle.getColor('main', 1),
            borderWidth: 1,
            height: 22,
            width: 22,
            borderRadius: 5
        },
    });
}

import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image, View, RefreshControl, Platform } from 'react-native';

import { SimpleText, ButtonText } from '@quarks'
import { sessionOperations } from '_ducks/session'
import { AppStyle, TextStyle } from "@style";
import { useAuth } from '@realm'
import { ILLUSTRATIONS } from '@images'
import { useSelector } from 'react-redux';

export const WaitValidationScreen = (props) => {

    const styles = updateStyles()
    const { user, logOut, loading, fetchCurrentUser } = useAuth()

    // In order to force a view update
    useSelector(state => state.session.language)

    if ((props.navigation.isFocused() || Platform.OS == 'web') && typeof user !== 'undefined' &&
        (typeof user.toBecomeProducer === 'undefined' ||
            user.toBecomeProducer == null)) {
        props.navigation.replace('CreateProducerAccount');
    } else if ((props.navigation.isFocused() || Platform.OS == 'web') && typeof user?.producer !== 'undefined') {
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Login' }],
        });
    }

    return (<ScrollView
        refreshControl={
            <RefreshControl
                refreshing={loading}
                onRefresh={() => {
                    if (!loading) {
                        fetchCurrentUser()
                    }
                }} />
        }
        style={{
            height: '100%',
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }} contentContainerStyle={{ justifyContent: 'center', flexGrow: 1 }}>
        <View style={styles.mainView}>
            <SimpleText style={styles.welcomeTitle}>
                $access.waitValidation.mainTitle
            </SimpleText>
            <Image
                style={{ height: 250, width: 350, alignSelf: 'center', marginTop: 10 }}
                source={ILLUSTRATIONS.working}
            />
            <SimpleText style={styles.verificationText}>
                $access.waitValidation.verificationText
            </SimpleText>
            <SimpleText style={styles.emailText}>
                {user?.email}
            </SimpleText>
            <SimpleText style={styles.secondaryText}>
                $access.waitValidation.secondaryText
            </SimpleText>
            <ButtonText
                loading={loading}
                style={{
                    bottom: 5, textAlign: 'center', padding: loading ? 10 : 0, paddingTop: loading ? 5 : 0, paddingBottom: loading ? 5 : 0
                }} text={'$access.waitValidation.closeSession'}
                onPress={async () => {
                    await logOut()
                    props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'Welcome' }]
                    })
                }} />
        </View>
    </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({

    mainView: {
        paddingBottom: 50,
        paddingTop: 50,
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center'
    },
    welcomeTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    secondaryTitle: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    emailText: {
        ...TextStyle.size.large,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        marginBottom: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    secondaryText: {
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 10,
    },
    exitText: {
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 50,
    }
});
import React, { useState } from 'react';

import { View, TextInput, StyleSheet, Platform, LayoutAnimation, UIManager, Pressable, TouchableOpacity } from 'react-native';
import { NadiusIcons } from '@icons'

import { Picker as PickerSelect } from '@platform'
import { SimpleText, ButtonText } from '@quarks';
import { Icon } from '@quarks'
import { AppStyle, TextStyle } from '@style';
import { replaceLocale } from '@utilities/locale'

let config = LayoutAnimation.create(200, 'linear', 'opacity')

if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }
}

export const autoCompleteType = {
    off: 'off',
    username: 'username',
    password: 'password',
    email: 'email',
    name: 'name',
    tel: 'tel',
    streetAddress: 'street-address',
    postalCode: 'postal-code',
    ccNumber: 'ofccNumberf',
    ccCsc: 'ccCsc',
    ccExp: 'ccExp',
    ccExpMonth: 'ccExpMonth',
    ccExpYear: 'ccExpYear',
}

export const autoCapitalize = {
    none: 'none',
    sentences: 'sentences',
    words: 'words',
    characters: 'characters',
}

export const clearButtonMode = {
    on: 'while-editing',
    off: 'never'
}

export const keyboardType = {
    default: 'default',
    numberPad: 'number-pad',
    decimalPad: 'decimal-pad',
    numeric: 'numeric',
    emailAddress: 'email-address',
    phonePad: 'phone-pad'
}

export const textContentType = {
    none: 'none',
    URL: 'URL',
    addressCity: 'addressCity',
    addressCityAndState: 'addressCityAndState',
    addressState: 'addressState',
    countryName: 'countryName',
    creditCardNumber: 'creditCardNumber',
    emailAddress: 'emailAddress',
    familyName: 'familyName',
    fullStreetAddress: 'fullStreetAddress',
    givenName: 'givenName',
    jobTitle: 'jobTitle',
    location: 'location',
    middleName: 'middleName',
    name: 'name',
    namePrefix: 'namePrefix',
    nameSuffix: 'nameSuffix',
    nickname: 'nickname',
    organizationName: 'organizationName',
    postalCode: 'postalCode',
    streetAddressLine1: 'streetAddressLine1',
    streetAddressLine2: 'streetAddressLine2',
    sublocality: 'sublocality',
    telephoneNumber: 'telephoneNumber',
    username: 'username',
    password: 'password',
    newPassword: 'newPassword',
    oneTimeCode: 'oneTimeCode'
}


export const CardInput = React.forwardRef((props, ref) => {

    let [showHelp, setShowHelp] = useState(false)
    const [firstTime, setFirstTime] = useState(true)
    let [status, setStatus] = useState({
        readOnly: false,
        focused: false,
        timeoutUpdate: undefined
    })

    let innerRef = React.useRef(null)
    let pickerRef = React.useRef(null)

    React.useImperativeHandle(ref, () => innerRef.current, [])
    const minHeight = props.minHeight ? props.minHeight : undefined
    const styles = updateStyles(minHeight, props.multiline ? undefined : minHeight)

    const _isFocused = () => {
        return innerRef?.current != null && innerRef.current.isFocused()
    }

    const _focus = () => {
        _onPressReadOnly()
        if (innerRef?.current != null && !innerRef.current.isFocused() && !props.editable) {
            innerRef.current.focus();
        }
        if (props.noInput && pickerRef !== null && pickerRef.current !== null) {
            pickerRef.current.togglePicker?.()
        }
        if (props.onPress) {
            props.onPress()
        }
    }


    const _onFocus = () => {
        _showHelp(true)
        setStatus({ ...status, focused: true })
        if (props.onFocus) props.onFocus()
    }

    const _onBlur = () => {
        _showHelp(false)
        setStatus({ ...status, focused: false })
        if (props.onBlur) props.onBlur()
    }

    const _showHelp = (state = true) => {
        if (typeof props.infoText != 'undefined' && props.infoText.length > 0) {
            LayoutAnimation.configureNext(config)
            setShowHelp(state)
        }
        //Else nothing to show.. so no need to rerender
    }

    const _onChangeText = (text) => {
        if (Platform.OS == 'web') {

            if (status.timeoutUpdate) {
                clearTimeout(status.timeoutUpdate);
            }

            setStatus({
                ...status, timeoutUpdate: setTimeout(() => {
                    if (props.onChangeText) {
                        props.onChangeText(text)
                    }
                }, 100)
            });
        } else {
            if (props.onChangeText) {
                props.onChangeText(text)
            }
        }
    }

    const _onValueChange = (value, index) => {
        if (typeof props.onValueChange !== 'undefined') {
            if (Platform.OS == 'web') {
                props.onValueChange(props.pickerItems[index].value)
            } else {
                props.onValueChange(value)
            }
        }
    }

    const _onPressReadOnly = () => {
        if (typeof props.readOnly !== 'undefined' && props.readOnly) {

            if (typeof props.onPressReadOnly !== 'undefined') {
                props.onPressReadOnly()

            } else {
                setStatus({
                    ...status,
                    readOnly: true
                })
            }
        }
    }

    let pickerElement = (
        <View 
        key={props.pickerValue}
            style={[{
            alignSelf: Platform.OS == 'native' ? 'flex-start' : 'center',
            paddingTop: Platform.OS === 'android' || Platform.OS == 'web' ? 5 : 0, flex: 1
        }, props.pickerStyle]}>
            {Platform.OS == 'native' && <PickerSelect
                key={props.title}
                ref={pickerRef}
                style={{
                    alignSelf: Platform.OS == 'native' ? 'flex-start' : 'center',
                    inputIOS: {
                        textAlign: 'center',
                        color: AppStyle.textColor,
                        ...TextStyle.weight.medium,
                        ...TextStyle.size.medium
                    },
                    inputAndroid: {
                        textAlign: 'center',
                        color: AppStyle.textColor,
                        ...TextStyle.weight.medium,
                        ...TextStyle.size.medium,
                        padding: 0,
                        marginRight: 20
                    },
                    placeholder: {
                        color: AppStyle.placeHolderColor,
                    }
                }}
                fixAndroidTouchableBug={true}
                value={props.pickerValue}
                itemKey={props.pickerItemKey}
                placeholder={props.pickerPlaceholder ? props.pickerPlaceholder : {}}
                Icon={() => <ButtonText
                    icon={'down'}
                    iconSet={'nadius'}
                    iconSize={20}
                    onPress={() => {
                        pickerRef.current.togglePicker?.()
                    }}
                    style={{ right: 0, top: 0 }}
                    color={AppStyle.mainColor}
                />}
                useNativeAndroidPickerStyle={false}
                onValueChange={_onValueChange}
                items={typeof props.pickerItems === 'object' ? props.pickerItems : []}
                disabled={typeof props.readOnly !== 'undefined' ? props.readOnly : false}
                touchableWrapperProps={{
                    onPressIn: _onPressReadOnly
                }} >
            </PickerSelect>}
            {Platform.OS == 'web' && <View key={props.pickerValue} style={{
                height: '100%', marginLeft: 20,
                flexDirection: 'row', width: '100%', alignSelf: 'center'
            }}>
                <PickerSelect
                    ref={pickerRef}
                    style={{
                        alignSelf: Platform.OS == 'native' ? 'flex-start' : 'center',
                        textAlign: 'center',
                        width: '100%',
                        WebkitAppearance: "none",
                        MozAppearance: "none",
                        ...TextStyle.size.small,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        paddingTop: 5,
                        paddingBottom: 5,
                        zIndex: 1,
                    }}
                    dropdownIconColor={'#FFFFFF'}
                    value={JSON.stringify(props.pickerValue)}
                    onValueChange={_onValueChange}>
                    {props.pickerItems?.map((val) => <PickerSelect.Item key={val.label} label={val.label} value={JSON.stringify(val.value)} />)}
                </PickerSelect>
                <Icon
                    name={NadiusIcons.DOWN}
                    iconSet={NadiusIcons.iconSet}
                    size={20}
                    color={AppStyle.mainColor}
                    style={{
                        right: 15, top: 5,
                        zIndex: 0, position: 'absolute'
                    }}
                />
            </View>}
        </View>
    )

    let val = Platform.OS == 'native' ? props.inputText : undefined
    if (firstTime && innerRef?.current !== null &&
        (typeof innerRef.current?.value == 'undefined' || innerRef.current?.value == '') &&
        (typeof props.inputText !== 'undefined' && props.inputText != '')) {
        innerRef.current.setNativeProps({ text: props.inputText })
        setFirstTime(false)
    }

    const CARD_WRAPPER = Platform.OS === 'web' ? View : Pressable


    return (
        <View style={[styles.main, props.style]}>
            <CARD_WRAPPER
                onPress={_focus}
                style={[styles.card, _isFocused() ? styles.focused : undefined, props.cardStyle]}>
                {props.title && <SimpleText style={styles.titleText}>{props.title.localise()}
                    {props.isOptional === true &&
                        <SimpleText style={styles.optionalText}> - $common.components.cardInput.optionalField</SimpleText>
                    }
                </SimpleText>}
                <View style={{ flexDirection: 'row', ...Platform.select({ web: { alignSelf: 'center', width: '90%' } }) }}>
                    {typeof props.icon != 'undefined' &&
                        <Icon
                            name={props.icon}
                            iconSet={props.iconSet}
                            size={20}
                            style={[styles.iconStyle, props.iconStyle]}
                            color={AppStyle.textColor}
                            onPress={_focus}
                        />
                    }

                    {typeof props.pickerLeft != 'undefined' &&
                        pickerElement
                    }

                    {!props.noInput &&
                        <View style={[styles.cardTextInput, props.textStyle]}>
                            <TextInput
                                pointerEvents={(typeof props.readOnly !== 'undefined' ? props.readOnly : false) ? 'none' : 'auto'}
                                ref={innerRef}
                                onChangeText={_onChangeText}
                                style={[styles.textInput, props.textStyle]}
                                keyboardType={props.keyboardType}
                                autoCompleteType={props.autoCompleteType}
                                autoCapitalize={props.autoCapitalize}
                                autoFocus={props.autoFocus}
                                textAlignVertical={props.multiline === true ? 'top' : undefined}
                                value={val}
                                defaultValue={props.inputText}
                                placeholderTextColor={AppStyle.placeHolderColor}
                                placeholder={replaceLocale(props.placeholder)}
                                onFocus={_onFocus}
                                textContentType={props.textContentType}
                                onBlur={_onBlur}
                                multiline={props.multiline}
                                returnKeyType={props.returnKeyType}
                                secureTextEntry={props.password}
                                onSubmitEditing={props.onSubmitEditing}
                                clearButtonMode={'while-editing'}
                                maxLength={props.maxLength}
                                editable={typeof props.readOnly !== 'undefined' ? !props.readOnly : (typeof props.editable !== 'undefined' ? props.editable : true)}
                            />
                        </View>
                    }
                    {
                        props.noInput && typeof props.renderCustomInput === 'function' &&
                        props.renderCustomInput()
                    }

                    {(props.picker || props.pickerRight) &&
                        pickerElement
                    }
                </View>

            </CARD_WRAPPER>
            { showHelp &&
                <SimpleText style={styles.infoText}>
                    {props.infoText}
                </SimpleText>
            }
            { props.readOnly && status.readOnly &&
                <SimpleText style={styles.errorText}>
                    {props.readOnlyMessage ? props.readOnlyMessage : "$common.components.cardInput.readOnly"}
                </SimpleText>
            }
            { typeof props.errorText === 'string' &&
                <SimpleText style={styles.errorText}>
                    {props.errorText}
                </SimpleText>
            }
        </View>

    )
})

const updateStyles = (minHeight, maxHeight) => StyleSheet.create({
    main: {
        alignSelf: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'transparent',
    },
    focused: {
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 5,
        backgroundColor: AppStyle.backgroundColor,
        borderColor: AppStyle.mainColor,
        borderWidth: 1,
    },
    card: {
        paddingTop: Platform.OS !== 'android' ? 5 : 0,
        paddingBottom: Platform.OS !== 'android' ? 10 : 5,
        shadowRadius: 0,
        backgroundColor: AppStyle.getColor('dark', 6),
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 4,
        maxWidth: 600,
        marginBottom: 0,
        borderRadius: 10,
    },
    iconStyle: {
        marginLeft: 15,
        paddingRight: Platform.OS == 'web' ? 10 : 0,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: Platform.OS === 'ios' ? 0 : 5,
    },
    cardTextInput: {
        flex: 1
    },
    textInput: {
        ...TextStyle.weight.medium,
        ...TextStyle.size.medium,
        outlineWidth: 0,
        marginLeft: 15,
        minHeight: minHeight,
        color: AppStyle.textColor,
        WebkitAppearance: "none",
        MozAppearance: "none",
        appearance: "none",
        borderWidth: 0, borderBottomWidth: 0, borderColor: 'transparent',
        marginTop: Platform.OS === 'ios' ? 0 : Platform.OS == 'android' ? -5 : 5,
        marginBottom: Platform.OS === 'ios' ? 0 : Platform.OS == 'android' ? -10 : 0
    },
    titleText: {
        alignSelf: 'center',
        textAlign: 'left',
        ...TextStyle.size.mini,
        paddingLeft: 10,
        marginRight: -10,
        width: '100%',
        marginTop: Platform.OS !== 'android' ? 0 : 5,
        marginBottom: Platform.OS !== 'android' ? 0 : 5,
        color: AppStyle.textColorLight
    },
    optionalText: {
        alignSelf: 'center',
        textAlign: 'center',
        paddingLeft: 0,
        color: AppStyle.textColorLight,
        ...TextStyle.size.mini,
        ...TextStyle.weight.bold
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    infoText: {
        color: AppStyle.getColor('mainAccent', 1),
        ...TextStyle.size.medium,
        width: '100%',
        maxWidth: 550,
        alignSelf: 'center',
        textAlign: 'left',
    }
}
);
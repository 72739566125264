

const BaseSkeleton = {
    image: {
        isSet: true,
        style: {}
    },
    text: "",
    price: false,
};

let CardStyle = {
    "market": {

    },
    "product": {}
};

Object.keys(CardStyle).forEach((key)=>{
    let aux = CardStyle[key];
    CardStyle[key] = {};
    Object.assign(CardStyle[key],BaseSkeleton,aux)
});


export {
    CardStyle
}

import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Icons } from '@icons';
import { AppStyle } from '@style'
import { ButtonText } from '@quarks';
import { Card } from '@atoms';
import { CustomizableCardGrid } from '@molecules';

export function ItemsCardList(props) {

    const styles = updateStyles(props.itemHeight)

    const _removeItem = (index, item) => () => {
        if (props.onPressRemoveButton) {
            props.onPressRemoveButton(index, item)
        }
    }

    const _onItemPress = (item, index) => () => {
        props.onPressItem(item, index)
    }

    const _renderItem = ({ index, item }) => {
        return (<View style={[{
            paddingTop: index === 0 ? 10 : 0,
            flexDirection: 'row',
            marginBottom: index === props.items.length - 1 ? props.marginBottom : 0
        }, props.itemStyle]} key={index}>
            <View style={{ width: '100%' }}>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Card
                        style={{ borderRadius: 20, backgroundColor: AppStyle.backgroundColorWithIntensity(1) }}
                        height={props.itemHeight}
                        onPress={props.onPressItem && _onItemPress(item, index)}>
                        {props.renderItem(item, index)}
                    </Card>
                    {props.showEdit && <View style={{ alignSelf: 'center', marginLeft: 10 }}>
                        <ButtonText
                            style={styles.deleteButton}
                            icon={'trash-alt'}
                            iconSize={25}
                            haptic={'medium'}
                            onPress={_removeItem(index, item)}>
                        </ButtonText>
                    </View>}
                </View>
                {typeof props.renderItemFooter !== 'undefined' && props.renderItemFooter(item)}
            </View>
        </View>)
    }

    return (<CustomizableCardGrid
        cards={props.items}
        headerComponent={props.headerComponent}
        footerComponent={props.footerComponent}
        refreshControl={props.refreshControl}
        itemDimension={props.itemSize || 300}
        emptyContentText={props.emptyContentText}
        renderItem={_renderItem}
        style={props.style}
        spacing={props.spacing ? props.spacing : 15}
        contentContainerStyle={props.contentContainerStyle}
        listType='list' />
    )
};

const updateStyles = () => StyleSheet.create({
    viewDeleteButton: {
        justifyContent: 'center',
        marginRight: 10,
    }
});

import React from 'react';
import { View, ActivityIndicator, Dimensions } from 'react-native';

import { AppStyle, TextStyle } from '@style';
import { SimpleText } from '@quarks';

export const LoadingView = (props) => {

    const height = Dimensions.get('window').height;

    return (
        <View style={[{ alignSelf: 'center', height: height / 2, justifyContent: 'center' }, props.style]}>
            <ActivityIndicator size="small" color={AppStyle.mainColor} />
            {typeof props.text !== 'undefined' && <SimpleText style={[{ alignSelf: 'center', paddingTop: 30, maxWidth: 150, textAlign: 'center' }, props.textStyle]}>
                {props.text}
            </SimpleText>}
        </View>
    )
};

import React, { useState } from 'react';
import {
  View, StyleSheet, Image,
  useWindowDimensions, Pressable, Dimensions
} from 'react-native';
import { Icon } from '@quarks'

import { AppStyle } from '@style';
import { ButtonText } from '@quarks';
import { CATEGORIES } from '@images'
import { SimpleText } from '../quarks';

export const ToolbarDropdown = (props) => {

  const dimensions = useWindowDimensions();
  const styles = updateStyles()
  const [showMenu, setShow] = React.useState(false)

  return (<View style={{
    flexDirection: 'row',
    justifyContent: 'center', paddingRight: 30
  }}>
    {
      Dimensions.get('window').width >= 500 &&
      <Pressable
        onPress={() => setShow(true)}
        style={{
          flexDirection: 'row'
        }}>
        <SimpleText style={[{ alignSelf: 'center', marginRight: 5 }]} >{'$common.components.toolbarDropdown.hello'.transFormat({name: props.name})} </SimpleText>
        <Icon
          name={'chevron-down'}
          size={20}
          color={AppStyle.mainColor}
          style={[{ alignSelf: 'center', marginRight: 15 }]}
        />

        <View
          style={{
            backgroundColor: AppStyle.mainColor,
            borderRadius: 40, width: 50, height: 50, justifyContent: 'center',
          }}>
          <Image
            style={{
              height: 40, width: 40,
              alignSelf: 'center',
            }}
            source={CATEGORIES['honey-jam']}
          />
        </View>
      </Pressable>
    }
    { Dimensions.get('window').width < 500 &&
      <Pressable
        onPress={() => setShow(true)}
        style={{
          flexDirection: 'row'
        }}>

        <View
          style={{
            backgroundColor: AppStyle.mainColor,
            borderRadius: 40, width: 40, height: 40, justifyContent: 'center',
          }}>
          <Image
            style={{
              height: 30, width: 30,
              alignSelf: 'center',
            }}
            source={CATEGORIES['honey-jam']}
          />
        </View>

        <Icon
          name={'chevron-down'}
          size={20}
          color={AppStyle.mainColor}
          style={[{ alignSelf: 'center', marginLeft: 5, marginRight: -5 }]}
        />

      </Pressable>
    }

    {showMenu &&
      <Pressable
        onPress={() => setShow(false)}
        style={{
          position: 'absolute', right: 0,
          height: Dimensions.get('window').height - 50,
          width: Dimensions.get('window').width,
          backgroundColor: 'transparent',
        }}>
        <View
          style={{
            top: props.headerHeight - 10, right: 10,
            width: 150, minHeight: 50,
            backgroundColor: 'white',
            alignSelf: 'flex-end',
            padding: 5,
            paddingLeft: 10,
            shadowOffset: {
              width: 0,
              height: 0
            },
            shadowOpacity: 0.1,
            shadowRadius: 8,
            elevation: 4,
            justifyContent: 'center'
          }}>
          {props.children}
        </View>
      </Pressable>
    }

  </View>)
};

const updateStyles = () => StyleSheet.create({

});


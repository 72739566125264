import React, { useState } from 'react';
import { StyleSheet, View, LayoutAnimation } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'

import { KeyboardAwareScrollView } from '@platform'
import { useAuth } from '@realm'

import { becomeProducerValidationErrors } from 'nexto-formvalidator';
import { ButtonText, SimpleText } from '@quarks'
import {
    FeedbackModal, CardInput,
    keyboardType, autoCompleteType, autoCapitalize, textContentType
} from '@molecules'
import { BlurModal } from '@atoms';

import { PhoneCardInput, LocationInput} from '@organisms';
import { sessionOperations } from '_ducks/session'
import { AppStyle, TextStyle } from "@style";
import { Icons } from '@icons'
import { user as codes } from 'nexto-codes'

let config = LayoutAnimation.create(200, 'linear', 'opacity')

export const CreateProducerAccountScreen = (props) => {

    const { user, callFunction, logOut, refreshSession, fetchCurrentUser } = useAuth();

    const [actionState, setActionState] = useState({ success: false, loading: false, error: false, errorMessage: undefined })
    const [isAccessCodeVisible, setAccessCodeVisible] = useState(undefined)
    const [becomeProducer, setBecomeProducer] = useState({ producerName: "", message: "", website: "", phone: "", location: {} })
    const [code, setCode] = useState("")
    const [codeState, setCodeState] = useState({success: false, loading: false, error: false, errorMessage: undefined})

    const [formState, setFormState] = useState({
        errorForm: { producerName: undefined, message: undefined, website: undefined, phone: undefined},
        focusState: { producerName: false, message: false, website: false }
    })
    const styles = updateStyles()
    const dispatch = useDispatch()

    // In order to force a view update
    useSelector(state => state.session.language)

    React.useEffect(() => {
        fetchCurrentUser()
    }, [props.navigation])

    const _backToLogin = async () => {
        await logOut(true)
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Login' }],
        });
    }
    const _navigate = (target = 'Main') => {
        return
    }

    if (props.navigation.isFocused() && typeof user?.producer !== 'undefined' &&
        actionState.success === false) { // Just in case this scene is pushed and shouldnt
        _navigate()
    }

    if (props.navigation.isFocused() && typeof user?.toBecomeProducer !== 'undefined') {
        _navigate('WaitingValidation')
    }

    const _animateTransition = () => {
        LayoutAnimation.configureNext(config);
    }

    const _createAccountFromCode = () => {

        
        if (code.length != 24) {
            setCodeState({ ...codeState, errorMessage: '$access.createProducerAccount.invalidCode'})
        } else {
            setCodeState({ ...codeState, errorMessage: undefined, loading: true})
            
            callFunction("USER_requestBecomeProducerFromCode", code)
            .then(async result => {
                switch (result.status) {
                    case codes.RESPONSE_CODES.OK:
                        setCodeState({ ...codeState, errorMessage: undefined, loading: false, success: true})
                        await refreshSession()
                        await fetchCurrentUser()
                        props.navigation.reset({
                            index: 0,
                            routes: [{ name: 'Main' }],
                        });
                        break;
                    case codes.RESPONSE_CODES.ERROR:
                        _animateTransition()
                            setCodeState({ ...codeState, errorMessage: '$access.createProducerAccount.invalidCode',  loading: false})
                        break;
                    case codes.RESPONSE_CODES.USER_IS_ALREADY_A_PRODUCER:
                        //They are fooling us
                        setCodeState({ ...codeState, errorMessage: undefined, loading: false, success: true})
                        break;
                    case codes.RESPONSE_CODES.UNKNOWN_ERROR:
                    default:
                        setCodeState({ ...codeState, loading: false, error: true, errorMessage: '$common.errors.unknown' })
                        break;
                }
            })
        }

    }

    const _createAccount = () => {

        let form = {
            message: becomeProducer.message?.trim(),
            website: becomeProducer.website?.trim().replace(/\s+/g, ''),
            phone: becomeProducer.phone,
            producerName: becomeProducer.producerName?.trim(),
            location: becomeProducer.location
        }

        if (typeof becomeProducer.phone?.number == 'string') {
            becomeProducer.phone.number = becomeProducer.phone?.number.trim().replace(/\s+/g, '')
        }

        let result = becomeProducerValidationErrors(form)
        if (result.isValid) {
            setActionState({ ...actionState, loading: true })
            callFunction("USER_requestBecomeProducer", form)
                .then(async result => {
                    switch (result.status) {
                        case codes.RESPONSE_CODES.OK:
                            setActionState({ ...actionState, success: true, loading: false, error: false })
                            dispatch(sessionOperations.storeRegisterForm(form))
                            await refreshSession()
                            await fetchCurrentUser()
                            props.navigation.replace('WaitingValidation');
                            break;
                        case codes.RESPONSE_CODES.ERROR:
                            if (typeof result.formErrors != 'undefined') {
                                _animateTransition()
                                setFormState({ ...formState, errorForm: JSON.parse(result.formErrors) })
                                setActionState({ ...actionState, success: false, loading: false, error: true, errorMessage: '$access.createProducerAccount.errorInDataDesc' })
                            }
                            break;
                        case codes.RESPONSE_CODES.USER_IS_ALREADY_A_PRODUCER:
                            //They are fooling us
                            setActionState({ ...actionState, success: true, loading: false, error: false })
                            break;
                        case codes.RESPONSE_CODES.UNKNOWN_ERROR:
                        default:
                            setActionState({ loading: false, error: true, errorMessage: '$common.errors.unknown' })
                            break;
                    }
                })
                .catch(err => {
                    console.error(err)
                    setActionState({ loading: false, success: false, error: true, errorMessage: '$common.errors.unknown' })
                })
        } else {
            _animateTransition()
            setFormState({ ...formState, errorForm: result.formErrors })
        }


    }

    const _onValueChange = (field) => (value) => {
        let form = { ...becomeProducer }
        form[field] = value
        setBecomeProducer(form)
    }
    return (<KeyboardAwareScrollView style={{
        width: '100%',
        alignSelf: 'center',
        backgroundColor: AppStyle.backgroundColor,
    }}
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', width: '100%' }}>
        { isAccessCodeVisible != false && <View style={styles.mainView}>

            <SimpleText style={styles.welcomeTitle}>
                {'$access.createProducerAccount.mainHeader ' + user?.name}
            </SimpleText>
            <SimpleText style={styles.welcomeDesciption}>
                $access.createProducerAccount.options
                        </SimpleText>

            <View style={{ width: '60%', maxWidth: 350 }}>
                <ButtonText
                    onPress={() => setAccessCodeVisible(true)}
                    text={'$access.createProducerAccount.yes'}
                    type={'solid'}
                    style={{
                        marginTop: 10,
                        width: '100%',
                        height: 50,
                    }} />
            </View>
            <View style={{ width: '60%', maxWidth: 350 }}>
                <ButtonText
                    onPress={() => setAccessCodeVisible(false)}
                    text={'$access.createProducerAccount.no'}
                    type={'outline'}
                    style={{
                        marginTop: 10,
                        width: '100%',
                        height: 50,
                    }} />
            </View>
            <View style={{ width: '60%', maxWidth: 350 }}>
                <ButtonText
                    onPress={(_backToLogin)}
                    text={'$button.cancel'}
                    disabled={actionState.loading}
                    type={'clear'}
                    style={{
                        width: '100%',
                        height: 50,
                    }} />
            </View>

        </View>}
        { isAccessCodeVisible === false && <View style={styles.mainView}>

            <SimpleText style={styles.welcomeTitle}>
                {'$access.createProducerAccount.mainHeader ' + user?.name}
            </SimpleText>
            <SimpleText style={styles.welcomeDesciption}>
                {'$access.createProducerAccount.description'.transFormat({name: user?.name})}
            </SimpleText>

            <View style={styles.verificationView}>

                <CardInput
                    style={styles.cardInput}
                    title={'$access.createProducerAccount.form.producerName'}
                    icon={Icons.TAG}
                    onChangeText={_onValueChange('producerName')}
                    keyboardType={keyboardType.default}
                    autoCompleteType={autoCompleteType.off}
                    inputText={becomeProducer?.producerName}
                    textContentType={textContentType.organizationName}
                    placeholder={'$access.createProducerAccount.form.producerNamePlaceholder'}
                    errorText={formState?.errorForm?.producerName}
                    infoText={'$access.createProducerAccount.form.producerNameDesc'}
                />

                <CardInput
                    style={styles.cardInput}
                    title={'$access.createProducerAccount.form.message'}
                    minHeight={120}
                    icon={Icons.DESCRIPTION}
                    cardStyle={{ padding: 10 }}
                    onChangeText={_onValueChange('message')}
                    keyboardType={keyboardType.default}
                    multiline
                    inputText={becomeProducer?.message}
                    placeholder={'$access.createProducerAccount.form.messagePlaceholder'}
                    errorText={formState?.errorForm?.message}
                    infoText={'$access.createProducerAccount.form.messageDesc'}
                />

                <LocationInput
                    key={becomeProducer?.location}
                    style={styles.cardInput}
                    value={becomeProducer?.location}
                    onError={(errors) => {
                        setFormState({ ...formState, errorForm: { ...formState.errorForm, location: { ...errors } }})
                    }}
                    errors={formState?.errorForm?.location}
                    onValueChange={_onValueChange('location')}
                />

                <PhoneCardInput
                    style={styles.cardInput}
                    value={becomeProducer?.phone}
                    onValueChange={_onValueChange('phone')}
                    errorText={formState?.errorForm?.phone} />

                <CardInput
                    style={styles.cardInput}
                    title={'$access.createProducerAccount.form.website'}
                    icon={Icons.WEB}
                    isOptional
                    onChangeText={_onValueChange('website')}
                    keyboardType={keyboardType.default}
                    autoCompleteType={autoCompleteType.off}
                    autoCapitalize={autoCapitalize.none}
                    textContentType={textContentType.URL}
                    inputText={becomeProducer?.website}
                    placeholder={'www.nextoapp.com'}
                    errorText={formState?.errorForm?.website}
                    infoText={'$access.createProducerAccount.form.websiteDesc'}
                    returnKeyType={'next'}
                />


            </View>

            <View style={{ width: '60%', maxWidth: 350 }}>
                <ButtonText
                    onPress={_createAccount}
                    type={'solid'}
                    loading={actionState.loading}
                    style={{ width: '100%', height: 50, marginTop: 20 }}
                    text={'$access.createProducerAccount.createAccount'} />
            </View>

            <View style={{ width: '60%', maxWidth: 350 }}>
                <ButtonText
                    onPress={() => setAccessCodeVisible(undefined)}
                    text={'$button.cancel'}
                    disabled={actionState.loading}
                    type={'clear'}
                    style={{
                        width: '100%',
                        height: 50,
                    }} />
            </View>

            <FeedbackModal
                error={actionState.error}
                errorText={actionState.errorMessage}
                onFeedbackPress={() => {
                    setActionState({ error: false, success: false, loading: false })
                }}
                onTimeout={() => {
                    setActionState({ error: false, success: false, loading: false })
                }}
            />
        </View>
        }

        <BlurModal closeButton visible={isAccessCodeVisible} onClose={() => setAccessCodeVisible(undefined)}>
            <View style={{ margin: 50 }}>
                <CardInput
                    style={styles.cardInput}
                    title={'$access.createProducerAccount.form.accessCode'}
                    icon={Icons.LABEL}
                    onChangeText={setCode}
                    keyboardType={keyboardType.default}
                    autoCompleteType={autoCompleteType.off}
                    autoCapitalize={autoCapitalize.none}
                    inputText={code}
                    placeholder={'qwertasdfzxcv1234yuiohjkl'}
                    errorText={codeState.errorMessage}
                    onSubmitEditing={_createAccountFromCode}
                    returnKeyType={'send'}
                />
                <ButtonText
                    onPress={_createAccountFromCode}
                    type={'solid'}
                    loading={codeState.loading}
                    style={{ width: '100%', height: 50, marginTop: 20 }}
                    text={'$access.createProducerAccount.form.enterCode'} />
            </View>
        </BlurModal>
    </KeyboardAwareScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    mainView: {
        paddingBottom: 50,
        paddingTop: 50,
        width: '100%',
        maxWidth: 600,
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center'
    },
    welcomeTitle: {
        ...TextStyle.size.xxlarge,
        ...TextStyle.weight.bold,
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.mainColor,
        marginBottom: 10
    },
    welcomeDesciption: {

        alignSelf: 'center',
        textAlign: 'center',
        marginBottom: 20
    },
    listView: {
        alignSelf: 'center',
        marginBottom: 100
    },
    listItem: {
        flexDirection: 'row',
        marginTop: 20,
        paddingBottom: 20,
        borderBottomWidth: 0.5
    },
    listIcon: { width: 65, height: 65, alignSelf: 'flex-start' },
    listText: {
        marginLeft: 20,
        ...TextStyle.size.large
    },
    iconStyle: {
        alignSelf: 'center',
        backgroundColor: 'red',
        marginRight: 10,
    },
    listTextView: {
    },
    cardInput: {
        marginBottom: 15,
        marginTop: 0,
        width: '100%',
        alignItems: 'center'
    },
    checkboxes: {
        width: '100%',
        alignItems: 'center'
    },
    button: {
        alignSelf: 'flex-start',
        width: '50%',
        marginTop: 10,
        marginLeft: 20,
        height: 35,
    },
    verificationView: {
        width: '90%',
        alignSelf: 'center',
    },
    verificationText: {
        width: '90%',
        alignSelf: 'center',
        marginBottom: 20,
    },
    listIconView: {
        justifyContent: 'center',
    }

});
import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18n-js';
import moment from "moment/min/moment-with-locales"
import AsyncStorage from '@react-native-community/async-storage';

import {SUPORTED_LOCALES} from '@utilities/locale'
import { ButtonText, SimpleText } from '@quarks'
import { sessionOperations } from '_ducks/session'
import { useAuth } from '@realm'
import { AppStyle, TextStyle } from "@style";
import { resetPasswordFormValidation } from 'nexto-formvalidator';
import { CardInput, keyboardType, autoCompleteType, textContentType, autoCapitalize, FeedbackModal } from '@molecules'
import { Icons } from '@icons';

const EmailVerification = (props) => {

    const styles = updateStyles()
    const dispatch = useDispatch()
    let passwordRef = React.useRef(null)
    let retypePasswordRef = React.useRef(null)

    const { resetPassword, loading } = useAuth()

    const locale = props.route.params.locale
    const token = props.route.params.token
    const tokenId = props.route.params.tokenId

    React.useEffect(() => {
        if (SUPORTED_LOCALES.includes(locale)) {
            i18n.locale = locale
            moment.locale(i18n.locale)
            AsyncStorage.setItem('@nadius:locale', locale)
            dispatch(sessionOperations.updateLanguage(locale))
        }
    }, [locale])

    const [state, setState] = useState({
        form: {
            password: undefined,
            confirmationPassword: undefined
        },
        title: "$access.resetPassword.title",
        description: "",
    })

    const onChangeText = (field) => (value) => {
        let newState = { ...state }
        newState.form[field] = value
        setState(newState)
    }

    // In order to force a view update
    useSelector(state => state.session.language)

    const _onPress = async () => {

        if (state.success) {
            props.navigation.navigate('AccessLogin')
            return
        }

        let result = resetPasswordFormValidation(state.form)
        if (result.isValid) {
            let code = await resetPassword(token, tokenId, state.form.password)
            if (code) {
                switch (code) {
                    case 400:
                    case 404:
                        setState({
                            title: "$access.resetPassword.errorTitle",
                            description: "$access.resetPassword.invalidToken"
                        })
                        break;
                    default:
                        setState({
                            title: "$common.errors.unknownReduced",
                            description: "$common.errors.unknownRetry",
                        })
                        break;
                }
            } else {
                setState({
                    success: true,
                    title: "$access.resetPassword.successTitle",
                    description: "$access.resetPassword.successDescription"
                })
            }
        } else {
            setState({ ...state, error: result.formErrors })
        }
    }

    return (
        <ScrollView style={{
            flex: 1,
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>

            <SimpleText style={styles.welcomeTitle}>
                {state.title}
            </SimpleText>
            <SimpleText style={styles.descriptionText}>
                {state.description}
            </SimpleText>

            {state.success != true && <>
                <CardInput
                    style={styles.cardInput}
                    ref={passwordRef}
                    title={'$access.resetPassword.new'}
                    icon={Icons.LOCK}
                    onChangeText={onChangeText('password')}
                    errorText={state.error?.password}
                    multiline={false}
                    placeholder={'$access.resetPassword.newPlaceholder'}
                    autoCompleteType={autoCompleteType.password}
                    textContentType={textContentType.newPassword}
                    autoCapitalize={autoCapitalize.none}
                    password={true}
                    onSubmitEditing={() => retypePasswordRef.current.focus()}
                    returnKeyType={'next'}
                />
                <CardInput
                    style={styles.cardInput}
                    ref={retypePasswordRef}
                    title={'$access.resetPassword.repeat'}
                    icon={Icons.LOCK}
                    onChangeText={onChangeText('confirmationPassword')}
                    errorText={state.error?.confirmationPassword}
                    multiline={false}
                    placeholder={'$access.resetPassword.retypePasswordPlaceholder'}
                    autoCompleteType={autoCompleteType.password}
                    textContentType={textContentType.newPassword}
                    autoCapitalize={autoCapitalize.none}
                    password={true}
                    onSubmitEditing={_onPress}
                    returnKeyType={'send'}

                />
            </>}
            <ButtonText
                onPress={_onPress}
                loading={loading}
                text={state.success != true ? 
                    '$access.resetPassword.submitButton' : '$access.resetPassword.goToLogin'}
                type={'solid'}
                style={{
                    marginTop: 20,
                    height: 50,
                    width: '60%',
                    textAlign: 'center'
                }} />

        </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 5,
    },
    descriptionText: {
        color: AppStyle.textColorLight,
        paddingBottom: 30,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    cardInput: {
        alignItems: 'center',
        width: '90%',
        marginBottom: 15
    },
});

export default EmailVerification;
import React, { useState } from 'react';
import { View, Alert, Dimensions, LayoutAnimation, Platform, UIManager, StyleSheet, ScrollView, Button } from 'react-native';

import { useQuery } from '@apollo/client';

import moment from "moment/min/moment-with-locales"
import { ButtonText, SimpleText } from '@quarks'
import { FeedbackModal } from '@molecules'
import { AppStyle, TextStyle } from "@style";
import { Producer } from '@api'
import { useAuth } from '@realm'
import {
    companyFormValidation, companyFormReducedValidation,
    companyGlobalValidators, companyLegalRepresentativeValidators,
    companyGlobalValidation, companyLegalRepresentativeValidation
} from 'nexto-formvalidator'
import { removeField, getProps, isAllNull, positiveObjectDiff } from 'nexto-utils/objects'
import { replaceLocale } from '@utilities/locale'
import { ProgressSteps, ProgressStep } from 'react-native-progress-steps';
import { sessionOperations } from '_ducks/session'
import { useSelector, useDispatch } from 'react-redux'
import { GeneralForm, LegalRepresentativeForm, FinantialForm } from '_scenes/companyForm'
import { KeyboardAwareScrollView } from '@platform'

import * as responseCodes from 'nexto-codes'
import { useHeaderHeight } from '@react-navigation/stack';

if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }
}


const CompanyFormScreen = (props) => {

    const { user, callFunction } = useAuth();
    const width = Dimensions.get('window').width;
    const navParams = props.route.params
    const [status, setStatus] = useState({
        start: typeof navParams !== 'undefined' && typeof navParams.start !== 'undefined' ? navParams.start : false,
        show: false,
        uploading: false,
        success: false,
        errorView: undefined,
        isValid: true,
        step: 0,
        timeout: false
    })

    const dispatch = useDispatch()
    const reduxForm = useSelector(state => state.session.companyForm)
    const storedForm = reduxForm.form

    const locationMongoToMango = {
        place_name: 'City',
        admin_name2: 'Region',
        postal_code: 'PostalCode',
        country_code: 'Country',
    }
    const scrollRef = React.useRef(null)

    let initialState = {
        HeadquartersAddress: {
            AddressLine1: undefined,
            City: undefined,
            Region: undefined,
            PostalCode: undefined,
            Country: undefined
        },
        LegalRepresentativeAddress: {
            AddressLine1: undefined,
            AddressLine2: undefined,
            City: undefined,
            Region: undefined,
            PostalCode: undefined,
            Country: undefined
        },
        HeadquartersLocation: {},
        LegalRepresentativeLocation: {},
        Name: undefined,
        LegalPersonType: 'BUSINESS',
        LegalRepresentativeFirstName: undefined,
        LegalRepresentativeLastName: undefined,
        LegalRepresentativeEmail: undefined,
        LegalRepresentativeBirthday: moment().year(1975),
        LegalRepresentativeNationality: "ES",
        LegalRepresentativeCountryOfResidence: "ES",
        CompanyNumber: undefined,
        Email: undefined,
        PhoneNumber: undefined,
        payment: {
            IBAN: undefined,
            OwnerName: undefined,
            OwnerAddress: {
                AddressLine1: undefined,
                AddressLine2: undefined,
                City: undefined,
                Region: undefined,
                PostalCode: undefined,
                Country: undefined
            },
            OwnerLocation: {}
        },
        ownerAddressHeadquartersChecked: false,
        ownerAddressLegalRepresentativeChecked: false,
        legalRepresentativeChecked: false,
    }

    let initialErrors = {
        HeadquartersAddress: {
            AddressLine1: undefined,
            City: undefined,
            Region: undefined,
            PostalCode: undefined,
            Country: undefined
        },
        LegalRepresentativeAddress: {
            AddressLine1: undefined,
            AddressLine2: undefined,
            City: undefined,
            Region: undefined,
            PostalCode: undefined,
            Country: undefined
        },
        HeadquartersLocation: {},
        LegalRepresentativeLocation: {},
        Name: undefined,
        LegalPersonType: undefined,
        LegalRepresentativeFirstName: undefined,
        LegalRepresentativeLastName: undefined,
        LegalRepresentativeEmail: undefined,
        LegalRepresentativeBirthday: undefined,
        LegalRepresentativeNationality: undefined,
        LegalRepresentativeCountryOfResidence: undefined,
        CompanyNumber: undefined,
        Email: undefined,
        PhoneNumber: undefined,
        payment: {
            IBAN: undefined,
            OwnerName: undefined,
            OwnerAddress: {
                AddressLine1: undefined,
                AddressLine2: undefined,
                City: undefined,
                Region: undefined,
                PostalCode: undefined,
                Country: undefined
            },
            OwnerLocation: {}
        }
    }

    const formValidators = [companyGlobalValidators, companyLegalRepresentativeValidators]
    let [errors, setErrors] = useState(initialErrors)

    console.log(storedForm)
    const [form, setForm] = useState(Object.assign(initialState, storedForm))

    const _onCompanyReceived = (data) => {
        if (companyResult.error) {
            setStatus({
                ...status,
                errorView: 'Error'
            })
        }

        if (typeof data?.producer !== 'undefined' && data?.producer !== null) {
            let prod = data.producer
            let newForm = { ...form }
            if (prod.contact.email) {
                newForm.Email = prod.contact.email
            }
            if (prod.contact.phone) {
                newForm.PhoneNumber = prod.contact.phone
            }
            if (prod.contact.phone) {
                newForm.PhoneNumber = prod.contact.phone
            }
            if (prod.location) {
                Object.keys(prod.location).forEach(key => {
                    if (Object.keys(locationMongoToMango).includes(key)) {
                        newForm.HeadquartersAddress[locationMongoToMango[key]] = prod.location[key]
                    }
                })
                newForm.HeadquartersLocation = prod.location
            }

            setForm(newForm)
        }
    }

    const companyQuery = Producer.getContactProducer(user?.producer?._id)
    let companyResult = useQuery(companyQuery.query, {
        ...companyQuery.param,
        onCompleted: _onCompanyReceived
    })

    const _updateResult = async (result) => {

        switch (result.status) {
            case responseCodes.producer.RESPONSE_CODES.OK:
                setStatus({
                    ...status,
                    success: true,
                    uploading: false,
                    errorView: undefined,
                    isValid: true,
                    timeout: false
                })
                setErrors(initialErrors)
                await companyResult.refetch()
                break

            case responseCodes.producer.RESPONSE_CODES.FORM_ERRORS:
                const resultErrors = typeof result.formErrors === 'object' ? result.formErrors : JSON.parse(result.formErrors)
                setErrors({
                    ...initialErrors,
                    ...resultErrors
                })
                let stepError = getStepFromErrors(resultErrors)
                stepError = stepError !== -1 ? stepError : 2
                setStatus({
                    ...status,
                    uploading: false,
                    success: false,
                    errorView: typeof replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[result.status]) !== 'undefined' ?
                        replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[result.status]) :
                        replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[responseCodes.producer.RESPONSE_CODES.UNKNOWN_ERROR]),
                    isValid: false,
                    step: stepError
                })
                break
            default:
                setStatus({
                    ...status,
                    uploading: false,
                    success: false,
                    errorView: (typeof result.status !== 'undefined' && typeof replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[result.status]) !== 'undefined') ?
                        replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[result.status]) :
                        replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[responseCodes.producer.RESPONSE_CODES.UNKNOWN_ERROR]),
                    isValid: false
                })
                break
        }
    }

    React.useEffect(() => {
        props.navigation.setOptions({
            headerTitle: headerFromStep(status.step),
            headerBackTitle: '$button.headerTruncatedBack'.localise(),
            headerTruncatedBackTitle: '$button.headerTruncatedBack'.localise(),
        })
    }, [props.navigation, status.step])

    React.useEffect(() => {
        const unsubscribe = props.navigation.addListener('blur', () => {
            dispatch(sessionOperations.storeCompanyForm(form))
        });
        return unsubscribe;
    }, [props.navigation, form])

    let _onValueChange = (field) => (value) => {
        let tmp = { ...form }

        if (field === 'phone') {
            tmp.PhoneNumber = value

        } else if (field === 'HeadquartersAddress') {
            Object.keys(value).forEach(key => {
                if (typeof tmp.HeadquartersLocation === 'undefined') tmp.HeadquartersLocation = {}

                tmp.HeadquartersLocation[key] = value[key]

                if (Object.keys(locationMongoToMango).includes(key)) {
                    tmp.HeadquartersAddress[locationMongoToMango[key]] = value[key]
                }
            })

        } else if (field === 'HeadquartersStreet') {
            tmp.HeadquartersAddress.AddressLine1 = value

        } else if (field === 'LegalRepresentativeAddress') {
            Object.keys(value).forEach(key => {
                tmp.LegalRepresentativeLocation[key] = value[key]
                if (Object.keys(locationMongoToMango).includes(key)) {
                    tmp.LegalRepresentativeAddress[locationMongoToMango[key]] = value[key]
                }
            })

        } else if (field === 'OwnerStreet') {
            tmp.payment.OwnerAddress.AddressLine1 = value

        } else if (field === 'OwnerAddress') {
            Object.keys(value).forEach(key => {
                tmp.payment.OwnerLocation[key] = value[key]

                if (Object.keys(locationMongoToMango).includes(key)) {
                    tmp.payment.OwnerAddress[locationMongoToMango[key]] = value[key]
                }
            })

        } else if (field === 'LegalRepresentativeStreet') {
            tmp.LegalRepresentativeAddress.AddressLine1 = value

        } else if (field === 'IBAN' || field === 'OwnerName') {
            tmp.payment = typeof tmp.payment !== 'undefined' ? tmp.payment : {}
            tmp.payment[field] = value

        } else if ((field === 'ownerAddressHeadquartersChecked' || field === 'ownerAddressLegalRepresentativeChecked') && value) {
            tmp[field] = value
            tmp[field === 'ownerAddressHeadquartersChecked' ? 'ownerAddressLegalRepresentativeChecked' : 'ownerAddressHeadquartersChecked'] = false
        } else {
            tmp[field] = value

        }

        setForm(tmp)


        if (typeof errors[field] !== 'undefined') {

            let errorsForm = errors
            errorsForm[field] = undefined
            setErrors(errorsForm)
            setStatus({
                ...status,
                errorView: undefined
            })

        }
    }

    let getStepFromErrors = (formErrors, parentKey = undefined) => {
        let step = -1, stepAux;
        Object.keys(formErrors).forEach(key => {
            if (typeof formErrors[key] === 'object') {
                stepAux = getStepFromErrors(formErrors[key], typeof parentKey !== 'undefined' ? parentKey : key)
                if (stepAux != -1) step = stepAux

            } else if (typeof formErrors[key] !== 'undefined') {
                for (let i = 0; i < 2; i++) {
                    if (Object.keys(formValidators[i]).includes(parentKey) ||
                        (formValidators[i] && Object.keys(formValidators[i][parentKey]).includes(key))) {
                        step = i;
                        return;
                    }
                }
            }
        })

        return step;
    }

    let _onContinue = async () => {
        console.log('STATUS ', status)
        dispatch(sessionOperations.storeCompanyForm(form))
        let errorsForm, formAux
        switch (status.step) {
            case 2:
                setStatus({
                    ...status,
                    uploading: true
                })

                let formData
                formAux = JSON.parse(JSON.stringify(form))

                formAux.LegalRepresentativeAddress = form.legalRepresentativeChecked ? form.HeadquartersAddress : formAux.LegalRepresentativeAddress
                formAux.payment.OwnerAddress = form.ownerAddressHeadquartersChecked ? form.HeadquartersAddress : formAux.payment.OwnerAddress
                formAux.payment.OwnerAddress = form.ownerAddressLegalRepresentativeChecked ? formAux.LegalRepresentativeAddress : formAux.payment.OwnerAddress
                formAux.payment.IBAN = formAux.payment.IBAN?.replace?.(/\s/g, "")


                errorsForm = companyFormValidation(formAux)
                formData = formAux


                if (typeof Object.keys(formData).includes('LegalRepresentativeBirthday') && typeof formData.LegalRepresentativeBirthday !== 'number') {
                    formData.LegalRepresentativeBirthday = Math.ceil(new Date(formData.LegalRepresentativeBirthday).getTime() / 1000)
                }

                removeField(formData, '__typename')
                removeField(formData, 'HeadquartersLocation')
                removeField(formData, 'LegalRepresentativeLocation')
                removeField(formData, 'OwnerLocation')
                removeField(formData, 'AddressLine2')
                removeField(formData, 'legalRepresentativeChecked')
                removeField(formData, 'ownerAddressHeadquartersChecked')
                removeField(formData, 'ownerAddressLegalRepresentativeChecked')

                if (errorsForm.isValid) {
                    dispatch(sessionOperations.storeCompanyForm(form))
                    let result = await callFunction('PRODUCER_companyForm', formData)
                    await _updateResult(result)
                } else {
                    setErrors(errorsForm.formErrors)
                    let stepError = getStepFromErrors(errorsForm.formErrors)
                    stepError = stepError !== -1 ? stepError : 2
                    setStatus({
                        ...status,
                        errorView: replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[responseCodes.producer.RESPONSE_CODES.FORM_ERRORS]),
                        uploading: false,
                        isValid: false,
                        step: stepError
                    })
                }
                break;
            default:
                formAux = form

                if (status.step == 1) {
                    formAux.LegalRepresentativeAddress = form.legalRepresentativeChecked ? form.HeadquartersAddress : formAux.LegalRepresentativeAddress
                    errorsForm = companyLegalRepresentativeValidation(form)
                } else {
                    errorsForm = companyGlobalValidation(form)
                }

                if (errorsForm.isValid) {
                    dispatch(sessionOperations.storeCompanyForm(form))
                    setErrors(initialErrors)
                    setStatus({
                        ...status,
                        step: status.step + 1,
                        errorView: undefined,
                        isValid: true
                    })
                    scrollRef?.current?.scrollToPosition?.(0)

                } else {
                    setErrors(errorsForm.formErrors)
                    let stepError = getStepFromErrors(errorsForm.formErrors)
                    stepError = stepError !== -1 ? stepError : 2
                    setStatus({
                        ...status,
                        errorView: replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[responseCodes.producer.RESPONSE_CODES.FORM_ERRORS]),
                        uploading: false,
                        isValid: false,
                        step: stepError
                    })
                }
        }



    }

    const _onBackwards = () => {
        setStatus({
            ...status,
            step: status.step > 0 ? status.step - 1 : 0
        })
        if (status.step > 0) scrollRef?.current?.scrollToPosition?.(0)
    }

    const _successClose = () => {
        setStatus({
            ...status,
            success: false
        })
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Main' }],
        });
    }

    const _onLocationError = (field) => (locationErrors) => {
        switch (field) {
            case 'General':
                setErrors({ ...errors, HeadquartersAddress: { ...locationErrors } })
                break

            case 'LegalRepresentative':
                setErrors({ ...errors, LegalRepresentativeAddress: { ...locationErrors } })
                break

            case 'Finantial':
                setErrors({
                    ...errors,
                    payment: {
                        ...errors.payment,
                        OwnerAddress: { ...locationErrors }
                    }
                })
                break
        }

        setStatus({
            ...status,
            errorView: replaceLocale(responseCodes.producer.RESPONSE_MESSAGES[responseCodes.producer.RESPONSE_CODES.FORM_ERRORS]),
            isValid: false
        })
    }

    const _onLocationChecked = (field) => () => {
        switch (field) {
            case 'LegalRepresentative':
                setForm({
                    ...form,
                    legalRepresentativeChecked: !form.legalRepresentativeChecked
                })
                break;

            case 'FinantialHeadquarters':
                setForm({
                    ...form,
                    ownerAddressHeadquartersChecked: !form.ownerAddressHeadquartersChecked
                })
                break;

            case 'FinantialLegalRepresentative':
                setForm({
                    ...form,
                    ownerAddressLegalRepresentativeChecked: !form.ownerAddressLegalRepresentativeChecked
                })
                break;
        }

    }

    const headerFromStep = (step) => {
        switch (step) {
            case 0:
                return "$companyForm.companyForm.generalInfo".localise()
            case 1:
                return "$companyForm.companyForm.legalRepresentative".localise()
            case 2:
                return "$companyForm.companyForm.finantialData".localise()
        }
    }

    return (<KeyboardAwareScrollView
        ref={scrollRef}
        style={{
            flex: 1,
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }}
        contentContainerStyle={{}}>
        <View style={{ maxWidth: 800, width: '100%', backgroundColor: 'white', alignSelf: 'center' }}>

            <ProgressSteps
                activeStep={status.step}
                activeStepIconBorderColor={status.isValid ? AppStyle.mainColor : AppStyle.errorColor}
                activeLabelColor={status.isValid ? AppStyle.mainColor : AppStyle.errorColor}
                activeStepNumColor={status.isValid ? AppStyle.mainColor : AppStyle.errorColor}>
                <ProgressStep
                    label={headerFromStep(0)}
                    removeBtnRow={true}>
                </ProgressStep>
                <ProgressStep
                    label={headerFromStep(1)}
                    removeBtnRow={true}>
                </ProgressStep>
                <ProgressStep
                    label={headerFromStep(2)}
                    removeBtnRow={true}>
                </ProgressStep>
            </ProgressSteps>


            {status.step === 0 &&
                <GeneralForm
                    form={form}
                    errors={errors}
                    onValueChange={_onValueChange}
                    onLocationError={_onLocationError('General')} />}

            {status.step === 1 &&
                <LegalRepresentativeForm
                    form={form}
                    errors={errors}
                    onValueChange={_onValueChange}
                    onLocationError={_onLocationError('LegalRepresentative')}
                    locationChecked={form.legalRepresentativeChecked}
                    onLocationCheck={_onLocationChecked('LegalRepresentative')} />}

            {status.step === 2 &&
                <FinantialForm
                    form={form}
                    errors={errors}
                    onValueChange={_onValueChange}
                    onLocationError={_onLocationError('Finantial')}
                    locationHeadquartersChecked={form.ownerAddressHeadquartersChecked}
                    locationLegalRepresentativeChecked={form.ownerAddressLegalRepresentativeChecked}
                    onLocationHeadquartersChecked={_onLocationChecked('FinantialHeadquarters')}
                    onLocationLegalRepresentativeChecked={_onLocationChecked('FinantialLegalRepresentative')} />}

            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-evenly', marginTop: 30,
                marginBottom: 30, minWidth: 300, flexWrap: 'wrap'
            }}>

                {status.step > 0 && <ButtonText
                    onPress={_onBackwards}
                    text={'Enrere'}
                    disabled={(companyResult.loading && !status.timeout) || status.uploading}
                    type={'$companyForm.common.backButton'}
                    style={{
                        height: 50,
                        textAlign: 'center',
                        width: width / 2 * 0.8,
                        maxWidth: 150
                    }} />}

                <ButtonText
                    onPress={_onContinue}
                    text={'$companyForm.common.continueButton'}
                    type={'solid'}
                    loading={(companyResult.loading && !status.timeout) || status.uploading}
                    style={{
                        height: 50,
                        textAlign: 'center',
                        width: (width / 2) * 0.8,
                        maxWidth: 150
                    }} />

            </View>
        </View>

        <FeedbackModal
            style={{ alignSelf: 'center' }}
            error={typeof status.errorView !== 'undefined'}
            onFeedbackPress={() => setStatus({ ...status, errorView: undefined, timeout: true })}
            errorText={status.errorView}
            loading={(companyResult.loading && !status.timeout) || status.uploading}
            success={status.success}
            onSuccess={_successClose}
            onTimeout={() => setStatus({ ...status, errorView: undefined, uploading: false, success: false })}
        />

    </KeyboardAwareScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 100
    },
    inputTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 10,
        paddingTop: 50,
    },
    emailText: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 30,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    directionText: {
        width: '80%',
        alignSelf: 'center',
        ...TextStyle.size.large
    },
    titleText: {
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.padding.medium,
        paddingLeft: 0,
        marginTop: 10
    },
    separator: {
        width: '80%',
        alignSelf: 'center',
        borderBottomWidth: 3,
        paddingTop: 10,
        marginBottom: 10
    },
    date: {
        alignSelf: 'center',
        width: '100%',
        height: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 10,
        marginTop: 0
    },
    halfCard: {
        flex: 2,
    },
    cardConatiner: {
        flexDirection: 'row',
        alignSelf: 'center',
        width: '100%',
    },
    iconStyle: {
        marginRight: 10,
        alignSelf: 'center',
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    card: {
        backgroundColor: AppStyle.cardBackground,
        shadowRadius: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
        maxHeight: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 0,
        borderRadius: 30,
    },
    cardText: {
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.padding.medium,
        paddingLeft: 0,

    },
    infoText: {
        color: AppStyle.getColor('secondary', 1),
        ...TextStyle.size.medium,
        width: '100%',
        alignSelf: 'center',
        textAlign: 'center',
    }
});

export default CompanyFormScreen;
import React from 'react';
import { View, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { TextStyle, AppStyle } from '@style';
import { SimpleText } from '@quarks';
import { ButtonText } from '@quarks';
import { sessionOperations } from '_ducks/session';


export const AlertView = (props) => {

    const dispatch = useDispatch()
    const alert = useSelector(state => state.session.modals.alert)

    let buttons;
    if (alert.buttons) {
        buttons = alert.buttons.map((button) => {

            let onPress = () => {
                if (button.onPress) button.onPress()
                dispatch(sessionOperations.toggleAlertModal(undefined))
            }

            return <ButtonText
                key={button.text}
                text={button.text}
                onPress={onPress}
                textStyle={{ padding: 5, paddingLeft: 20, paddingRight: 20 }}
                type={button.style == 'cancel' ? 'outline' : 'solid'}
            />
        })
    }

    return (
        <View style={[{
            alignSelf: 'center',
            textAlign: 'center',
            width: '100%'
        }, props.style]}>
            <SimpleText style={{
                ...TextStyle.size.large,
                ...TextStyle.weight.bold,
                paddingBottom: 10,
            }}>{alert.title}</SimpleText>
            <SimpleText style={{ paddingBottom: 20 }}>{alert.description}</SimpleText>
            <View style={{ width: '90%', alignSelf: 'center', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap' }}>
                {buttons}
            </View>
        </View>
    )
};

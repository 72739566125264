import React from 'react';
import { Image, View, LayoutAnimation, Pressable, Dimensions } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import AsyncStorage from '@react-native-community/async-storage';
import { AppStyle } from '@style'
import { useAuth } from '@realm'
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment/min/moment-with-locales"

import { WelcomeScreen } from '_scenes/access';
import { TermsScreen } from '_scenes/legal/terms';
import { PrivacyScreen } from '_scenes/legal/privacy';

import { LANGUAGES, APP_ICONS } from '@images'
import { sessionOperations } from 'redux/ducks/session';
import { CreateProducerAccountScreen } from "_scenes/access/createProducerAccount"
import WelcomeProcessScreen from '_scenes/access/welcomeProcess';
import { WaitValidationScreen } from '_scenes/access/waitValidation'

const AccessStack = createStackNavigator();

function AccessNavigation(props) {

    const config = LayoutAnimation.create(200, 'linear', 'opacity')
    const dispatch = useDispatch()

    const { user } = useAuth();
    const [state, setState] = React.useState({
        showing: false
    })
    const isRegisterPerformed = useSelector(state => state.session.register.done)
    
    useSelector(state => state.session.language)

    const onLanguagePress = (language, navigation, name) => async () => {
        if (!state.showing) {
            LayoutAnimation.configureNext(config);
            setState({ ...state, showing: true })
            return
        }

        i18n.locale = language
        moment.locale(i18n.locale)
        await AsyncStorage.setItem('@nadius:locale', language)
        setState({ ...state, showing: false })
        dispatch(sessionOperations.updateLanguage(language))
    }

    const baseOptions = {
        headerTintColor: AppStyle.textHeaderColor,
        headerTransparent: false,
        animationEnabled: true,
    }

    let initialRouteName;
    if (user !== null && typeof user !== 'undefined' &&
        typeof user.producer === 'undefined') {
        if (isRegisterPerformed && typeof user.toBecomeProducer !== 'undefined') {
            initialRouteName = 'WaitingValidation'
        } else if (typeof user.producer == 'undefined') {
            initialRouteName = 'WelcomeProcess'
        } else {
            props.navigation.reset({
                index: 0,
                routes: [{ name: 'Main' }],
            });
        }
    }

    return (<AccessStack.Navigator
        screenOptions={({ route, navigation }) => {
            return {
                ...baseOptions,
                title: `Nadius - ${`$root.screens.${route.name}`.localise()}`,
                headerTitle: () => (
                  Dimensions.get('window').width > 500 ?
                    <Image //2492 - 200 x 968  
                      resizeMode={'contain'}
                      style={{ height: 120, width: 150 }}
                      source={APP_ICONS.logoHorizontal}
                    />
                    :
                    <Image //2492 - 200 x 968  
                      resizeMode={'contain'}
                      style={{ height: 40, width: 40, marginLeft:-10 }}
                      source={APP_ICONS.logoBase}
                    />
                ),
                headerRight: () => {
                    return <View style={{ flexDirection: 'row' }}>
                        {(state.showing || i18n.locale == 'ca') &&
                            <Pressable onPress={onLanguagePress('ca')}>
                                <Image //2492 - 200 x 968  
                                    resizeMode={'contain'}
                                    style={{ height: 35, width: 35, marginRight: 10 }}
                                    source={LANGUAGES.ca}
                                />
                            </Pressable>}
                        {(state.showing || i18n.locale == 'es') &&
                            <Pressable onPress={onLanguagePress('es')}>
                                <Image //2492 - 200 x 968  
                                    resizeMode={'contain'}
                                    style={{ height: 35, width: 35, marginRight: 10 }}
                                    source={LANGUAGES.es}
                                />
                            </Pressable>}
                        {(state.showing || i18n.locale == 'en') &&
                            <Pressable onPress={onLanguagePress('en')}>
                                <Image //2492 - 200 x 968  
                                    resizeMode={'contain'}
                                    style={{ height: 35, width: 35, marginRight: 10 }}
                                    source={LANGUAGES.en}
                                />
                            </Pressable>}
                    </View>
                }
            }
        }
        }
        initialRouteName={initialRouteName}
    >
        <AccessStack.Screen
            name="Welcome"
            component={WelcomeScreen}
        />
        <AccessStack.Screen
            name="TermsScreen"
            component={TermsScreen}
        />
        <AccessStack.Screen
            name="PrivacyScreen"
            component={PrivacyScreen}
        />
        <AccessStack.Screen
            name="WelcomeProcess"
            component={WelcomeProcessScreen}
            options={baseOptions}
        />
        <AccessStack.Screen
            name="CreateProducerAccount"
            component={CreateProducerAccountScreen}
            options={baseOptions}
        />
        <AccessStack.Screen
            name="WaitingValidation"
            component={WaitValidationScreen}
            options={baseOptions}
        />
    </AccessStack.Navigator>
    );
}

export default AccessNavigation;

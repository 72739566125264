import * as types from "./types";

export const basketCardExpandItem = ( productId ) => ( {
    type: types.EXPAND_ITEM,
    meta: {
        id: productId
    }
} );

export const basketAddItem = ( item ) => ( {
    type: types.ADD_ITEM,
    item : item
} );

export const basketRemoveItem = ( item ) => ( {
    type: types.DELETE_ITEM,
    item : item
} );
import React from 'react';
import { View, StyleSheet, Platform, Keyboard, Pressable, Dimensions } from 'react-native';
import { AppStyle } from '@style';
import { FeedbackView } from '@molecules';
import { BottomSheetModal, BlurModal } from '@atoms';

export const BottomModal = (props) => {

    const styles = updateStyles()

    const _onRequestClose = () => {
        if (props.onRequestClose) {
            props.onRequestClose()
        }
    }

    let content = (<>
        {!props.loading && !props.success && !props.error && props.children}
        {(props.loading || props.success || props.error) &&
            <FeedbackView
                height={150}
                visible={props.visible}
                onSuccess={props.onSuccess}
                onPress={props.onFeedbackPress}
                error={props.error}
                errorText={props.errorText}
                loading={props.loading}
                loadingText={props.loadingText}
                success={props.success}
                successText={props.successText} />}
    </>)

    if (Platform.OS == 'web') {
        return (<BlurModal closeButton={props.closeButton} style={props.style} visible={props.visible} onClose={_onRequestClose}>
            {content}
        </BlurModal>)
    } else {
        return (<BottomSheetModal
            modalVisible={props.visible}
            onClose={_onRequestClose}
            onBackdropPress={_onRequestClose}
            ContentModalStyle={styles.Modal}
            ContentModal={(<View style={styles.containerContent}>
                <Pressable
                    onPress={() => Keyboard.dismiss()}
                    style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20, paddingTop: 35,
                        backgroundColor: AppStyle.backgroundColor,
                    }}>
                    <View style={{
                        width: 50, alignSelf: 'center', position: 'absolute',
                        height: 6, borderRadius: 10,
                        backgroundColor: AppStyle.textColorLight, top: 10
                    }}></View>
                    {!props.loading && !props.success && !props.error && props.children}
                    {(props.loading || props.success || props.error) &&
                        <FeedbackView
                            height={150}
                            visible={props.visible}
                            onSuccess={props.onSuccess}
                            onPress={props.onFeedbackPress}
                            error={props.error}
                            errorText={props.errorText}
                            loading={props.loading}
                            loadingText={props.loadingText}
                            success={props.success}
                            successText={props.successText} />}
                </Pressable>
            </View>
            )}
        />)
    }
};

const updateStyles = () => StyleSheet.create({
    centeredView: {
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: "center"
    },
    headerContent: {
        marginTop: 0,
    },
    Modal: {
        marginTop: 0,
        position: 'absolute',
        bottom: 0,
        width: '100%'
    }
});


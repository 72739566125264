import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image } from 'react-native';

import { SimpleText, ButtonText } from '@quarks'
import { sessionOperations } from '_ducks/session'
import { AppStyle, TextStyle } from "@style";
import { useAuth } from '@realm'
import { ILLUSTRATIONS } from '@images'

const KycDoneScreen = (props) => {

    const styles = updateStyles()

    React.useEffect(() => {
        props.navigation.setOptions({
            headerTitle: '$finances.kycDone.headerTitle'.localise()
        })
    }, [props.navigation])

    return (<ScrollView style={{
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        backgroundColor: AppStyle.backgroundColor
    }}
        contentContainerStyle={{ flexGrow: 1, height: '100%', justifyContent: 'center' }}>
        <SimpleText style={styles.welcomeTitle}>
            $finances.kycDone.mainTitle
            </SimpleText>
        <Image
            style={{ height: 250, width: 350, alignSelf: 'center', marginTop: 10 }}
            source={ILLUSTRATIONS.working}
        />
        <SimpleText style={styles.verificationText}>
            $finances.kycDone.verificationText
            </SimpleText>
        <SimpleText style={styles.secondaryText}>
            $finances.kycDone.secondaryText
            </SimpleText>
    </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    secondaryTitle: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    emailText: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        marginBottom: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    secondaryText: {
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingTop: 20
    },
    exitText: {
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 50,
    }
});

export default KycDoneScreen;
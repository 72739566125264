import React, { useState, useRef } from 'react';
import {
  StyleSheet, View, Dimensions, Platform
} from 'react-native';

import SegmentedControlTab from "react-native-segmented-control-tab";
import { Carousel } from '@platform';

import { useHeaderHeight } from "@react-navigation/stack";

import { ButtonText } from '@quarks'
import { AppStyle } from '@style'
import { replaceLocale } from '@utilities/locale'
import { Icons } from '@icons';

export const TabbedCarousel = React.forwardRef((props, ref) => {
  {

    let paddingHeader = useHeaderHeight()
    const windowWidth = Dimensions.get('window').width;
    const styles = updateStyles(paddingHeader)
    let initialIndex = typeof props.selectedIndex !== 'undefined' ? props.selectedIndex : 0
    let [selectedIndex, setSelectedIndex] = useState(initialIndex)
    let [errors, setErrors] = useState(props.errors)

    let carouselRef = useRef(null)

    let _indexPress = (index) => {
      if (Platform.OS == 'native') {
        carouselRef?.current?.snapToItem?.(index)
      } else {
        carouselRef?.current?.goTo?.(index)
      }
      setSelectedIndex(index)
    }

    React.useImperativeHandle(ref, () => ({
      indexPress: _indexPress
    }), [])

    let values = []
    props.values.forEach(element => {
      values.push(replaceLocale(element))
    });

    React.useEffect(() => {
      if (selectedIndex > (values.length - 1) && values.length > 0) {
        _indexPress(selectedIndex - 1)
      }
    }, [selectedIndex, values])

    if (Platform.OS == 'web') {
      if (typeof carouselRef?.current?.children !== 'undefined') {
        if (carouselRef?.current?.children?.length !== values.length || errors != props.errors) {
          carouselRef.current.children = values?.map((item, index) => props.renderItem({ item, index }))
          carouselRef.current.count = values.length
        }
      }
    }

    React.useEffect(() => {
      setErrors(props.errors)
    }, [props.errors])

    return (
      <View style={props.style}>
        <View style={{
          flexDirection: 'row',
          width: windowWidth * 0.85, maxWidth: 400,
          alignSelf: 'center', justifyContent: 'center'
        }}>
          {
            typeof props.leftHeader !== 'undefined' && props.leftHeader
          }
          {values.length > 1 &&
            <SegmentedControlTab
              badges={props.badges}
              activeTabBadgeStyle={{ color: 'red' }}
              tabsContainerStyle={[styles.tabsContainerStyle, { width: windowWidth * 0.85 - 45, maxWidth: 350, alignSelf: 'center' }]}
              tabStyle={styles.tabStyle}
              firstTabStyle={styles.firstTabStyle}
              lastTabStyle={styles.lastTabStyle}
              tabTextStyle={styles.tabTextStyle}
              activeTabStyle={styles.activeTabStyle}
              activeTabTextStyle={styles.activeTabTextStyle}
              selectedIndex={selectedIndex}
              allowFontScaling={false}
              values={values}
              onTabPress={_indexPress}
            />
          }
          {
            typeof props.rightHeader !== 'undefined' && props.rightHeader
          }
        </View>
        {Platform.OS == 'native' && <Carousel
          ref={carouselRef}
          data={values}
          renderItem={props.renderItem}
          sliderWidth={windowWidth}
          itemWidth={windowWidth * props.contentWidth}
          firstItem={initialIndex}
          hasParallaxImages={true}
          inactiveSlideScale={0.95}
          inactiveSlideOpacity={0.6}
          scrollEnabled={false}
          decelerationRate={'normal'}
          containerCustomStyle={styles.slider}
          contentContainerCustomStyle={styles.sliderContentContainer}
          removeClippedSubviews={false}
          style={{ flex: 5, alignSelf: 'center' }}
        />}
        {Platform.OS == 'web' && <Carousel
          ref={carouselRef}
          from={initialIndex}
          minDistanceForAction={0.1}
          gesturesEnabled={() => false}
          controlsEnabled={true}
          containerStyle={props.containerStyle}
          style={{ flex: 5, alignSelf: 'center' }}
          controlsProps={{
            dotsTouchable: true,
            prevPos: 'left',
            nextPos: 'right',
            PrevComponent: ({ onPress }) => (<ButtonText
              onPress={onPress}
              icon={Icons.BACK}
              iconColor={AppStyle.mainColor}
              type={'solid'}
              style={{
                width: 30, height: 30, paddingRight: 2.5,
                marginLeft: windowWidth == 400 ? 0 : 10
              }}
              color={AppStyle.backgroundColor}
            />),
            NextComponent: ({ onPress }) => (<ButtonText
              onPress={onPress}
              icon={Icons.FORWARD}
              iconColor={AppStyle.mainColor}
              type={'solid'}
              style={{
                width: 30, height: 30, paddingLeft: 2.5,
                marginRight: windowWidth == 400 ? 0 : 10
              }}
              color={AppStyle.backgroundColor}
            />),
          }} >
          {values?.map((item, index) => props.renderItem({ item, index }))}
        </Carousel>}
      </View >

    );
  }
})

const updateStyles = (paddingHeader) => StyleSheet.create({
  sliderContentContainer: {
    paddingVertical: 10, // for custom animation
  },
  slider: {
    overflow: 'visible', // for custom animations
    alignSelf: 'center',
  },
  tabsContainerStyle: {

    //custom styles
  },
  tabStyle: {
    borderColor: AppStyle.mainColor
    //custom styles
  },
  firstTabStyle: {
    //custom styles
  },
  lastTabStyle: {
    //custom styles
  },
  tabTextStyle: {
    color: AppStyle.mainColor
    //custom styles
  },
  activeTabStyle: {
    backgroundColor: AppStyle.mainColor,
    //custom styles
  },
  activeTabTextStyle: {
    color: AppStyle.backgroundColor
    //custom styles
  },
  tabBadgeContainerStyle: {
    //custom styles
  },
  activeTabBadgeContainerStyle: {
    //custom styles
  },
  tabBadgeStyle: {
    //custom styles
  },
  activeTabBadgeStyle: {
    //custom styles
  }
});

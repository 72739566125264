import { PixelRatio } from 'react-native';
const IMAGES_URL = 'https://images.nextoapp.com'

/**
 * fit ? -> https://i.stack.imgur.com/1Bf6d.png
 * @param {} param0 
 */
const createImageRequest = ({ bucket = "nexto-es-img", key, width = undefined, height = undefined, fit = 'cover'}) => {
    
    let request = {
        bucket: bucket,
        key: key
    }

    if (typeof width !== 'undefined' || typeof height !== 'undefined') {
        request.edits = {
            resize: {
                fit: fit
            }
        }
        if (typeof width !== 'undefined') {
            request.edits.resize.width = width
        }
        if (typeof height !== 'undefined') {
            request.edits.resize.height = height
        }
    }

    return request

}

export const IMAGE_SIZES = Object.freeze({
    PREVIEW:    {width: PixelRatio.getPixelSizeForLayoutSize(50), height: PixelRatio.getPixelSizeForLayoutSize(50)},
    CARD:       {width: PixelRatio.getPixelSizeForLayoutSize(150), height: PixelRatio.getPixelSizeForLayoutSize(150)},
    COVER:      {width: PixelRatio.getPixelSizeForLayoutSize(320), height: PixelRatio.getPixelSizeForLayoutSize(180)},
    ORIGINAL:   {width: PixelRatio.getPixelSizeForLayoutSize(600), height: PixelRatio.getPixelSizeForLayoutSize(500)}
})
export const createImageUrl = (imageKey, props) => {

    let requestBody = createImageRequest({key:imageKey, ...props})

    const Buffer = require("buffer").Buffer;
    const requestString = new Buffer(JSON.stringify(requestBody)).toString("base64");


    return `${IMAGES_URL}/${requestString}`

}

import { gql } from '@apollo/client';
import { getTranslationQuery } from '@utilities/locale'

const getCategories = () => {
    return {
        query: gql`
            query  {
                categories  {
                    _id
                    isDisabled
                    code
                    ${getTranslationQuery('name useName', false, true)}
                    subcategories {
                        _id
                        isDisabled
                        code
                        ${getTranslationQuery('name', false, true)}
                    }
                }
            }`,
        name: 'Category-getCategories'
    }
}

export default {
    getCategories,
}



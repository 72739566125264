import React from 'react';
import {
    StyleSheet, View
} from 'react-native';
import { FlatGrid, SectionGrid } from 'react-native-super-grid';

import { SimpleText, ButtonText } from '@quarks'
import { LoadingView } from '@atoms';
import { TextStyle, AppStyle } from '@style'

export function CustomizableCardGrid(props) {

    const styles = updateStyles()

    let _sectionHeader = props.renderSectionHeader
    if (!_sectionHeader) {
        _sectionHeader = ({ section: { title, subtitle } }) => {
            return <View>
                <SimpleText style={[styles.sectionHeader, props.sectionHeaderStyle]}>
                    {title}
                </SimpleText>
                {subtitle && <SimpleText style={styles.subtitleHeader}>
                    {subtitle}
                </SimpleText>}
            </View>
        }
    }

    let footer = props.footerComponent
    if (props.paginationLoading) {
        footer = <LoadingView style={{ height: 80, paddingBottom: 20 }} />
    }

    if (props.pagination && !props.paginationMoreData && props.paginationPerformed) {
        footer = <SimpleText style={{
            textAlign: 'center', ...TextStyle.size.small,
            color: AppStyle.textColorLight, paddingBottom: 10
        }}>$common.components.customizableCardGrid.endReached</SimpleText>
    }

    const _onEndReached = () => {
        if (props.paginationPress && props.paginationMoreData) {
            props.paginationPress()
        }
    }

    let emptyContent = (<View style={[{
        minHeight: 100, width: '90%',
        alignSelf: 'center', justifyContent: 'center'
    }]}>
        <SimpleText style={[styles.noContentText, props.noContentTextStyle]}>
            {props.emptyContentText}
        </SimpleText>
    </View>)

    if (props.customEmptyView) {
        emptyContent = props.customEmptyView
    }

    let list;
    if (props.listType === "list") {
        list = <FlatGrid
            refreshControl={props.refreshControl}
            onEndReached={_onEndReached}
            onEndReachedThreshold={0.5}
            keyboardShouldPersistTaps={props.keyboardShouldPersistTaps}
            ListHeaderComponent={props.headerComponent}
            ListFooterComponent={footer}
            style={props.style}
            ListEmptyComponent={emptyContent}
            contentContainerStyle={props.contentContainerStyle}
            itemContainerStyle={props.itemContainerStyle}
            itemDimension={props.itemDimension}
            data={props.cards ? props.cards : []}
            horizontal={props.horizontal}
            renderItem={props.renderItem}
            showsHorizontalScrollIndicator={false}
            spacing={typeof props.spacing !== 'undefined' ? props.spacing : undefined} // Spacing and horizontal doesn't work properly!
        />;
    } else {
        list = <SectionGrid
            refreshControl={props.refreshControl}
            onEndReached={_onEndReached}
            keyboardShouldPersistTaps={props.keyboardShouldPersistTaps}
            onEndReachedThreshold={0.5}
            ListHeaderComponent={props.headerComponent}
            ListFooterComponent={footer}
            contentContainerStyle={props.contentContainerStyle}
            itemContainerStyle={props.itemContainerStyle}
            style={props.style}
            ListEmptyComponent={emptyContent}
            itemDimension={props.itemDimension}
            sections={props.cards ? props.cards : []}
            renderItem={props.renderItem}
            horizontal={props.horizontal}
            showsHorizontalScrollIndicator={false}
            spacing={props.spacing ? props.spacing : undefined}
            stickySectionHeadersEnabled={false}
            renderSectionHeader={_sectionHeader} />;
    }
    return list;
}

const updateStyles = () => StyleSheet.create({
    noContentView: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.regular,
        textAlign: 'center',
        justifyContent: 'center',
    },
    noContentText: {
        ...TextStyle.size.large,
        ...TextStyle.weight.regular,
        textAlign: 'center',
        alignSelf: 'center',
        textAlignVertical: 'center',
    },
    sectionHeader: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        marginLeft: 30,
    },
    subtitleHeader: {
        ...TextStyle.size.large,
        marginLeft: 30,
    },
});

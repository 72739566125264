
const ca = {
    order: {
        monday: "Dilluns",
        tuesday: "Dimarts",
        wednesday: "Dimecres",
        thursday: "Dijous",
        friday: "Divendres",
        saturday: "Dissabtes",
        sunday: "Diumenges",
        
        shortDays: {
            monday: "Dl",
            tuesday: "Dt",
            wednesday: "Dc",
            thursday: "Dj",
            friday: "Dv",
            saturday: "Ds",
            sunday: "Dg",
        },
        
        allWeek: "Cada dia",
        oneDay: "24h",
        oneTwoDays: "24/48h",
        threeFourDays: "48/72h",
        week: "1 set.",
        invalidCard: "La targeta seleccionada no és vàlida",
        producerNotFound: "El productor ja no forma part de la plataforma",
        minimumNotReached: "La comanda no arriba al mínim establert pel productor",
        productNotFound: "No s'ha trobat aquest producte",
        newDeliveryPrice: "El preu d'enviament s'ha actualitzat",
        noStock: "No hi ha estoc disponible",
        priceUpdate: "S'ha actualitzat el preu recentment",
        amountUpdate: "S'ha actualitzat la quantitat per producte recentment",
        amountUnitUpdate: "S'ha actualitzat la unitat de quantitat per producte recentment",
        totalProducerMismatch: "El preu final no correspon amb el final",
        directionNotFound: "La direcció seleccionada no existeix",
        paymentError: "Hi ha hagut un problema amb el pagament",
        unableStorePayment: "No hem pogut desar correctament el pagament",
        paymentNotDone: "No s'ha efectuat el pagament",
        refundError: "Hi ha hagut un problema amb el reemborsament",
        userNotFound: "No s'ha trobat l'usuari associat a la comanda",
        orderNotFound: "No hem trobat la comanda",
        cancelNotPermitted: "No es pot cancelar la comanda amb l'estat actual",
        pickupNotEnabled: "La recollida ja no està habilitada"
    },
    user: {
        ok: '',
        userPasswordInvalid:"Correu i/o contrasenya no són vàlids",
        validationNotDone:"El teu compte encara no ha estat validat, consulta el teu correu electrònic.",
        emailExists:"El correu electrònic ja està registrat.",
        emailUnableVerify:"El correu electrònic no pot ser verificat.",
    },
    mangopay: {
        unableCreateUser: "No s'ha pogut crear l'usuari en Mangopay",
        unableCreateWallet: "No s'ha pogut crear el wallet a Mangopay",
        unableUpdatePayment: "No s'ha pogut actualitzar les dades de pagament",
        producerNotFound: "No s'ha trobat el productor associat a l'usuari",
        onlyCreated: "No s'ha acabat de processar el pagament",
        unableCreateDocument: "No s'ha pogut desar el document",
        uboFormNotValid: "Hi ha un error en el formulari de la Declaració d'accionistes",
        missingKycDocuments: "No hem detectat cap dels documents necessaris, has seleccionat guardar?",
        missingIdentityDocument: "Falta el document d'identificació d'identitat",
        missingRegistryDocument: "Falta el document de prova de registre",
        missingArticles: "Falta el document amb els estatuts",
        formNotStored: "No s'ha pogut desar el formulari",
        formAlreadySubmited: "El formulari ja s'ha enviat",
        unableSubmitDocument: "No hem pogut enviar el document a revisar.\nPosat en contacte amb nosaltres i ho resoldrem.",
        succeeded: "Operació realitzada satisfactoriament",
        created: "Operació pendent",

        //Operation failed
        '001999': "S'ha produït un incident o problema de connexió i s'han tancat totes les transaccions.",
        '001001': "Saldo insuficient",
        '001002': "L'autor no és el propietari de la cartera",
        '001011': "El valor de la transacció és major al màxim permès",
        '001012': "El valor de la transacció és menor al mínim permès",
        '001013': "El valor de la transacció no és vàlid",
        '001014': "Els fons acreditats han de ser més de 0",
        '002999': "Bloquejada per falta d'informació personal que verifiqui la teva identitat",
        '002998': "Bloquejada per falta d'informació personal que verifiqui la teva identitat",
        '001401': "La transacció ja s'ha reemborsat correctament",
        '005403': "El reemborsament no pot excedir el valor de la transacció inicial",
        '005404': "Hi ha hagut un problema en realitzar el reemborsament, atès que s'excedeix del valor de la transacció inicial",
        '005405': "No hi ha saldo suficient en el teu usuari per fer el reemborsament",
        '005407': "Operació duplicada: no pot reemborsar la mateixa quantitat més d'una vegada per transacció durant el mateix dia",
        '001403': "La transacció no es pot reemborsar (màxim 11 mesos)",
        '105101': "Nombre de targeta invàlid",
        '105102': "Nom de titular de la targeta no vàlid",
        '105103': "Codi PIN no vàlid",
        '105104': "Format de PIN no vàlid",
        '101399': "L'autenticació 3DSecure no està disponible",
        '101304': "La sessió d'autenticació de 3DSecure ha expirat",
        '101303': "La targeta no és compatible amb 3DSecure",
        '101302': "La targeta no està registrada amb 3DSecure",
        '101301': "L'autenticació 3DSecure ha fallat",
        '009103': "Error de configuració de PSP",
        '009199': "Error en processar el pagament. Pot ser a causa de: \n - La targeta no és compatible amb el proveïdor de pagament \n - S'ha arribat al valor màxim per transacció",
        '009499': "Error tècnic de la teva entitat bancària",
        '009999': "Error tècnic causat pel proveïdor de pagament. Contacta amb nosaltres o intenti-ho més tard.",
        '009101': "Temps d'espera màxim assolit, si us plau intenti més tard",
        '101202': "Banc no compatible amb Giropay",
        '101101': "Transacció rebutjada pel banc. Pot ser causat per: \n - Es va aconseguir la quantitat màxima gastada per mes en aquesta targeta \n - Es va aconseguir la quantitat màxima gastada a Internet per mes en aquesta targeta \n - No hi ha més fons en el compte bancari ",
        '101102': "Transacció rebutjada pel banc. Pot ser causat per: \n - Es va aconseguir la quantitat màxima preautorizada \n - Es va aconseguir la quantitat màxima gastada per mes en aquesta targeta \n - Es va aconseguir la quantitat màxima gastada a Internet per mes en aquesta targeta ",
        '101103': "Transacció rebutjada, intenta fer servir una altra targeta.",
        '101104': "Transacció rebutjada pel banc (límit de targeta aconseguit)",
        '101105': "La targeta ha caducat",
        '101106': "La targeta està inactiva segons la teva entitat bancària.",
        '101108': "Transacció rebutjada, no es pot transferir a un mateix.",
        '101109': "El període de pagament ha expirat",
        '101110': "El pagament ha estat rebutjat a causa d'un problema de transferència bancària, la transferència bancària ha estat rebutjada o cancel·lada. Torna-ho a provar.",
        '101410': "La targeta no està activa",
        '101111': "Nombre màxim d'intents assolits",
        '101112': "S'ha superat la quantitat màxima. Aquesta és una limitació de la targeta sobre la quantitat gastada",
        '101113': "S'ha arribat al nombre màxim d'usos amb aquesta targeta. Intenteu-ho de nou passades 24h.",
        '101115': "Límit de dèbit excedit",
        '101116': "No podem gestionar més transaccions en el dia d'avui. Contacta amb nosaltres o torna-ho a intentar demà.",
        '101118': "Encara està pendent una transacció inicial amb la mateixa targeta",
        '002996': "Bloquejat causa de limitacions de saldo per usuari (es va assolir la quantitat màxima de propietat)",
        '101199': "Transacció rebutjada per la teva entitat bancària",
        '205001': "Error de validació de dades de Paypal",
        '008999': "Hem tingut un problema en comprovar el frau de la transacció",
        '008001': "El teu banc ha reportat la targeta utilitzada com a falsa, posa't en contacte amb ells per solucionar-ho",
        '008002': "El teu banc ha desactivat la targeta per un excés de pagaments o intents",
        '008003': "El teu banc ha desactivat la targeta per un excés de pagaments o intents",
        '008004': "S'ha bloquejat la targeta per l'equip de prevenció de frau del proveïdor que processa el pagament",
        '008005': "S'ha detectat un comportament inusual amb aquesta targeta i per tant s'ha bloquejat",
        '008006': "La targeta ha estat bloquejada temporalment pel departament de frau del servei que processa el pagament, torneu-ho a provar més tard.",
        '008007': "La targeta ha estat bloquejada temporalment pel departament de frau del servei que processa el pagament, torneu-ho a provar més tard.",
        '008000': "La transacció no compleix amb els protocols d'antifrau del proveïdor de pagament",
        '008504': "La quantitat de transacció excedeix la permesa",
        '008505': "S'ha arribat al límit en el nombre de transaccions diàries",
        '008506': "El pagament des del país que et trobes, no està autoritzat",
        '008507': "Es va superar el valor acumulat de les transaccions",
        '008508': "El país emissor de la targeta, no està autoritzat",
        '008509': "S'ha superat el nombre de targetes permeses",
        '008510': "S'ha superat el nombre de clients per targeta",
        '008511': "L'autenticació de 3DSecure ha fallat a causa d'un problema en les comprovacions addicionals impostos durant la verificació",
        '008512': "Ubicació d'IP diferent a país emissor de la targeta",
        '008513': "S'ha superat la quantitat de dispositius permesos",
        '008515': "Autenticació no disponible: no es beneficia del canvi de responsabilitat. Ha de contactar amb el seu banc per resoldre aquest problema i permetre el canvi de responsabilitat.",
        '008514': "BIN de targeta no autoritzat",
        '008600': "El sistema antifrau l'ha bloquejat",
        '008700': "El sistema antifrau l'ha bloquejat",
    },
    producer: {

        twoHalf: "2.5Kg",
        five: "5Kg",
        ten: "10Kg",

        nifExists: "Aquest NIF ja està registrat",
        unableInsertCompany: "No s'ha pogut desar l'empresa",
        unableCreateProducer: "No s'ha pogut crear un productor nou",
        userNotExists: "No existeix l'usuari",
        formErrors: "Hi ha errors al formulari",
        unablePerformUpdate: "No hem pogut actualitzar el teu perfil. \n \n Vuélvelo a provar de nou i si persisteix, posa't en contacte amb nosaltres",
    }
}

const es = {
    order: {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábados",
        sunday: "Domingos",

        shortDays: {
            monday: "L",
            tuesday: "M",
            wednesday: "Mi",
            thursday: "J",
            friday: "V",
            saturday: "S",
            sunday: "D",
        },

        allWeek: "Todos los días",
        oneDay: "24h",
        oneTwoDays: "24/48h",
        threeFourDays: "48/72h",
        week: "1 sem.",
        invalidCard: "La tarjeta seleccionada no es válida",
        producerNotFound: "El productor ya no forma parte de la plataforma",
        minimumNotReached: "El pedido no llega al mínimo establecido por el productor",
        productNotFound: "No se ha encontrado este producto",
        newDeliveryPrice: "El precio de envío se ha actualizado",
        noStock: "No hay stock disponible",
        priceUpdate: "Se ha actualizado el precio recientemente",
        amountUpdate: "Se ha actualizado la cantidad por producto recientemente",
        amountUnitUpdate: "Se ha actualizado la unidad de cantidad por producto recientemente",
        totalProducerMismatch: "El precio final no corresponde con el final",
        directionNotFound: "La dirección seleccionada no existe",
        paymentError: "Ha habido un problema con el pago",
        unableStorePayment: "No hemos podido guardar correctamente el pago",
        paymentNotDone: "No se ha realizado el pago",
        refundError: "Ha habido un problema con el reembolso",
        userNotFound: "No se ha encontrado el usuario asociado al pedido",
        orderNotFound: "No hemos encontrado el pedido",
        cancelNotPermitted: "No se puede cancelar el pedido en al estado actual",
        pickupNotEnabled: "La recogida ya no está disponible"
    },
    user: {
        ok: '',
        userPasswordInvalid: "Correo y/o contraseña no son válidos",
        validationNotDone: "Tu cuenta aún no ha sido validada, consulta tu correo electrónico.",
        emailExists: "El correo electrónico ya está registrado.",
        emailUnableVerify: "El correo electrónico no puede ser verificado.",  
    },
    mangopay: {
        unableCreateUser: "No se ha podido crear el usuario en Mangopay",
        unableCreateWallet: "No se ha podido crear el wallet en Mangopay",
        unableUpdatePayment: "No se ha podido actualizar los datos de pagamento",
        producerNotFound: "No se ha encontrado el productor asociado al usuario",
        onlyCreated: "No se ha acabado de procesar el pago",
        unableCreateDocument: "No se ha podido guardar el documento",
        uboFormNotValid: "Hay un error en el formulario de la Declaración de accionistas",
        missingKycDocuments: "No hemos detectado ninguno de los documentos necesarios, has seleccionado guardar?",
        missingIdentityDocument: "Falta el documento de identificación de identidad",
        missingRegistryDocument: "Falta el documento de prueba de registro",
        missingArticles: "Falta el documento con los estatutos",
        maxUbosStored: "No se puede tener más de 4 accionistas con más de 25%",
        formNotStored: "No se ha podido guardar el formulario",
        formAlreadySubmited: "El formulario ya se ha enviado",
        unableSubmitDocument: "No hemos podido enviar este documento a revisar.\nPonte en contacto con nosotros y lo resoldremos.",
        succeeded: "Operaciñon realitzada satisfactoriamente",
        created: "Operación pendiente",

        //Operation failed
        '001999': "Se ha producido un incidente o problema de conexión y se han cerrado todas las transacciones.",
        '001001': "Saldo insuficiente",
        '001002': "El autor no es el propietario de la billetera",
        '001011': "El valor de la transacción es mayor al máximo permitido",
        '001012': "El valor de la transacción es menor al mínimo permitido",
        '001013': "El valor de la transacción no es válido",
        '001014': "Los fondos acreditados deben ser más de 0",
        '002999': "Bloqueada por falta de información personal que verifique tu identidad",
        '002998': "Bloqueada por falta de información personal que verifique tu identidad",
        '001401': "La transacción ya se ha reembolsado correctamente",
        '005403': "El reembolso no puede exceder el valor de la transacción inicial",
        '005404': "Ha habido un problema al realizar el reembolso, dado que se excede del valor de la transacción inicial",
        '005405': "No hay saldo suficiente en tu usuario para hacer el reembolso",
        '005407': "Operación duplicada: no puede reembolsar la misma cantidad más de una vez por transacción durante el mismo día",
        '001403': "La transacción no se puede reembolsar (máximo 11 meses)",
        '105101': "Número de tarjeta inválido",
        '105102': "Nombre del titular de la tarjeta no válido",
        '105103': "Código PIN no válido",
        '105104': "Formato de PIN no válido",
        '101399': "La autenticación 3DSecure no está disponible",
        '101304': "La sesión de autenticación de 3DSecure ha expirado",
        '101303': "La tarjeta no es compatible con 3DSecure",
        '101302': "La tarjeta no está registrada con 3DSecure",
        '101301': "La autenticación 3DSecure ha fallado",
        '009103': "Error de configuración de PSP",
        '009199': "Error al procesar el pago. Puede ser debido a:\n - La tarjeta no es compatible con el proveedor de pago\n - Se ha alcanzado el valor máximo por transacción",
        '009499': "Error técnico de tu entidad bancaria",
        '009999': "Error técnico causado por el proveedor de pago. Contacta con nosotros o inténtelo más tarde.",
        '009101': "Tiempo de espera máximo alcanzado, por favor intente más tarde",
        '101202': "Banco no compatible con Giropay",
        '101101': "Transacción rechazada por el banco. Puede ser causado por: \n - Se alcanzó la cantidad máxima gastada por mes en esta tarjeta\n - Se alcanzó la cantidad máxima gastada en Internet por mes en esta tarjeta\n - No hay más fondos en la cuenta bancaria",
        '101102': "Transacción rechazada por el banco. Puede ser causado por: \n - Se alcanzó la cantidad máxima preautorizada\n - Se alcanzó la cantidad máxima gastada por mes en esta tarjeta\n - Se alcanzó la cantidad máxima gastada en Internet por mes en esta tarjeta",
        '101103': "Transacción rechazada, intenta usar otra tarjeta.",
        '101104': "Transacción rechazada por el banco (límite de tarjeta alcanzado)",
        '101105': "La tarjeta ha caducado",
        '101106': "La tarjeta está inactiva según tu entidad bancaria.",
        '101108': "Transacción rechazada, no se puede transferir a uno mismo.",
        '101109': "El período de pago ha expirado",
        '101110': "El pago ha sido rechazado debido a un problema de transferencia bancaria, la transferencia bancaria ha sido rechazada o cancelada. Inténtalo de nuevo.",
        '101410': "La tarjeta no está activa",
        '101111': "Número máximo de intentos alcanzados",
        '101112': "Se excedió la cantidad máxima. Esta es una limitación de la tarjeta sobre la cantidad gastada",
        '101113': "Se ha alcanzado el número máximo de usos con esta tarjeta. Inténtelo de nuevo pasadas 24h. ",
        '101115': "Límite de débito excedido",
        '101116': "No podemos gestionar más transacciones en el día de hoy. Contacta con nosotros o vuélvelo a intentar mañana.",
        '101118': "Aún está pendiente una transacción inicial con la misma tarjeta",
        '002996': "Bloqueado debido a limitaciones de saldo por usuario (se alcanzó la cantidad máxima de propiedad)",
        '101199': "Transacción rechazada por tu entidad bancaria",
        '205001': "Error de validación de datos de Paypal",
        '008999': "Hemos tenido un problema al comprobar el fraude de la transacción",
        '008001': "Tu banco ha reportado la tarjeta usada como falsa, ponte en contacto con ellos para solucionarlo",
        '008002': "Tu banco ha desactivado la tarjeta por un exceso de pagos o intentos",
        '008003': "Tu banco ha desactivado la tarjeta por un exceso de pagos o intentos",
        '008004': "Se ha bloqueado la tarjeta por el equipo de prevención de fraude del proveedor que procesa el pago",
        '008005': "Se ha detectado un comportamiento inusual con esta tarjeta y por consiguiente se ha bloqueado",
        '008006': "La tarjeta ha sido bloqueada temporalmente por el departamento de fraude del servicio que procesa el pago, inténtelo de nuevo más tarde.",
        '008007': "La tarjeta ha sido bloqueada temporalmente por el departamento de fraude del servicio que procesa el pago, inténtelo de nuevo más tarde.",
        '008000': "La transacción no cumple con los protocolos de anti-fraude del proveedor de pago",
        '008504': "La cantidad de transacción excede la permitida",
        '008505': "Se ha llegado al límite en el número de transacciones diarias",
        '008506': "El pago des del país que te encuentras, no está autorizado",
        '008507': "Se superó el valor acumulado de las transacciones",
        '008508': "El país emisor de la tarjeta, no está autorizado",
        '008509': "Se ha superado el número de tarjetas permitidas",
        '008510': "Se ha superado el número de clientes por tarjeta",
        '008511': "La autenticación de 3DSecure ha fallado debido a un problema en las comprobaciones adicionales impuestos durante la verificación",
        '008512': "Ubicación de IP diferente al país emisor de la tarjeta",
        '008513': "Se ha superado la cantidad de dispositivos permitidos",
        '008515': "Autenticación no disponible: no se beneficia del cambio de responsabilidad. Debe contactar a su banco para resolver este problema y permitir el cambio de responsabilidad.",
        '008514': "BIN de tarjeta no autorizado",
        '008600': "El sistema anti-fraude le ha bloqueado",
        '008700': "El sistema anti-fraude le ha bloqueado",
    },
    producer: {
        
        twoHalf: "2.5Kg",
        five: "5Kg",
        ten: "10Kg",

        nifExists: "Este NIF ya está registrado",
        unableInsertCompany: "No se ha podido guardar la empresa",
        unableCreateProducer: "No se ha podido crear un productor nuevo",
        userNotExists: "No existe el usuario",
        formErrors: "Hay errores en el formulario",
        unablePerformUpdate: "No hemos podido actualizar tu perfil. \n\nVuélvelo a intentar de nuevo y si persiste, contacta con nosotros",
    }


}

export {
    es,
    ca
}
import { gql } from '@apollo/client';
import { getTranslationQuery } from '@utilities/locale'

const getLabels = () => {
    return {
        query: gql`
            query  {
                labels  {
                    _id
                    ${getTranslationQuery('name', false, true)}
                }
            }`,
        name: 'Label-getLabels'
    }
}

export default {
    getLabels,
}



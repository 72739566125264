import React, { useState, useContext } from 'react';
import moment from "moment/min/moment-with-locales"
import i18n from 'i18n-js';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-community/async-storage';

import { User } from '@api'
import { Producer } from '@api'
import * as Notifications from '@utilities/notifications';

const AuthContext = React.createContext(null);

const delay = async (time) => {
    return new Promise((resolve) =>
        setTimeout(
            () => { resolve('result') },
            time
        )
    );
}

const AuthProvider = (props) => {

    let current = app.currentUser
    const client = useApolloClient()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState((typeof current === 'undefined' || current === null || (current !== null && current.customData === null)) ?
        undefined : current.customData)

    const producerQuery = Producer.getById(typeof user !== 'undefined' ? user.producer : user)
    const [getProducer, producerResult] = useLazyQuery(producerQuery.query, {
        ...producerQuery.param,
        onCompleted: (data) => {
            setUser({ ...user, producer: data.producer })
        }
    });

    if (typeof user !== 'undefined') {

        moment.locale(i18n.locale)

        if (typeof user.producer !== 'undefined' &&
            typeof producerResult?.data?.producer !== 'undefined') {
            user.producer = producerResult.data.producer
        }

    }

    const logIn = async (email, password, app) => {
        if (!loading) {
            setLoading(true)
            try {
                let user = await User.login(email, password)
                if (user != null && typeof user?.id !== 'undefined' && user.customData !== null && typeof user.customData !== 'undefined') {
                    setUser(user.customData)
                    await Notifications.register(() => { }, () => { }, app)
                    if (user.customData) {
                        if (user.customData.producer && app === 'producer') {
                            getProducer({ variables: { id: user.customData.producer } })
                        }
                        if (user.customData.locale) {
                            i18n.locale = user.customData.locale
                        }
                    }
                    return
                }
                return user
            } catch (error) {
                console.warn(error)
            } finally {
                setLoading(false)
            }
        }
    };

    const logOut = async (cleanApollo = false, anonymous = true) => {
        if (user == null) {
            console.warn("Not logged in -- can't log out!");
            return;
        }
        await Notifications.unRegister()
        try {
            dispatch({ type: 'USER_LOGOUT' })
            await User.logout(anonymous)
        } catch (error) {
            console.warn(error)
        } finally {
            setLoading(false)
            setUser(undefined)
            if (cleanApollo) {
                await client.clearStore()
            }
        }
    };

    const signUp = async (form) => {
        if (!loading) {
            setLoading(true)
            try {
                let user = await User.signUp(form)
                if (typeof user === 'undefined' || user == null) {
                    if (app.currentUser == null) {
                        user = await User.login()
                    }
                    let retries = 0;
                    do {
                        try {
                            let result = await app.currentUser.callFunction("USER_sendConfirmEmail", {
                                email: form.email,
                                name: form.name,
                                locale: i18n.locale
                            });
                            if (result != null && typeof result.status !== 'undefined' && result.status === 0) {
                                user = undefined
                            } else {
                                user = result?.message
                            }
                            break
                        } catch (error) {
                            console.warn(error, error.code)
                            if (error.code == 2 && error.message == "invalid session") {
                                await User.logout()
                                user = await User.login()
                            } else {
                                break
                            }
                        }
                    } while (retries < 5)
                }
                return user
            } catch (error) {
                console.warn(error)
            } finally {
                setLoading(false)
            }
        }
    };

    const requestResetPassword = async (email) => {
        let user = app.currentUser
        let toReturn;
        try {
            setLoading(true)
            if (user !== null && user.customData != null) {
                await app.emailPasswordAuth.callResetPasswordFunction(user.customData.email, '******');
            } else {
                await app.emailPasswordAuth.callResetPasswordFunction(email, '******');
            }
        } catch (e) {
            toReturn = e
        } finally {
            setLoading(false)
        }
        return toReturn
    };

    const resetPassword = async (token, tokenId, newPassword) => {
        try {
            setLoading(true)
            await app.emailPasswordAuth.resetPassword(token, tokenId, newPassword);
        } catch (error) {
            return error.statusCode
        } finally {
            setLoading(false)
        }
    };

    const refreshSession = async () => {
        await AsyncStorage.removeItem('@nadius:access_token')
    }

    const fetchCurrentUser = async (reLogin = false) => {
        if (loading) return
        setLoading(true)

        let inUser = app.currentUser
        if (inUser !== null) {
            if (inUser.customData != null) {
                // We might logOut
                let result = await User.refreshCustomData(reLogin)
                if (typeof result !== 'undefined' && result.customData !== null) {
                    let tmpUser = { ...user, ...result.customData }
                    setUser(tmpUser)
                    if (props.app !== 'client' && typeof result.customData.producer !== 'undefined') {
                        if (typeof tmpUser.producer?._id != 'undefined') {
                            //Refetch
                            console.log('refetch')
                            let data = await producerResult.refetch({ variables: { id: inUser.customData.producer } })
                            let refetchedProducer = data?.data?.producer
                            if (typeof refetchedProducer != 'undefined') {
                                setUser({ ...tmpUser, ...result.customData, producer: refetchedProducer })
                            }
                        } else {
                            //initialise
                            if (typeof result.customData.producer == 'object') {
                                getProducer({ variables: { id: result.customData.producer['$oid'] } })
                            } else {
                                getProducer({ variables: { id: result.customData.producer } })
                            }
                        }
                    }
                }

            } else if (reLogin) {
                await User.login()
                setUser(undefined)
            }
        }
        setLoading(false)
    }

    const callFunction = async (functionName, ...args) => {
        let error;

        let arg = args;
        if (Array.isArray(args) && args.length === 1) {
            arg = args[0]
        }

        try {
            let result = await current.callFunction(functionName, arg);
            if (typeof result.status !== 'undefined') {
                return result
            }
            console.warn('result ', result)
        } catch (e) {
            error = e
            if (error.code === 2 && error.message === 'invalid session') {
                console.log('refetching session ', error)
                await fetchCurrentUser(true)
                let result = await app.currentUser.callFunction(functionName, arg);
                if (typeof result.status !== 'undefined') {
                    return result
                }
            } else {
                console.warn('ERROR ', error)
            }
        }
        return { status: 2, error: error }
    }

    const confirmUser = async (tokenId, token) => {
        setLoading(true)
        try {
            await app.emailPasswordAuth.confirmUser(token, tokenId);
        } catch (error) {
            return error.statusCode
        } finally {
            setLoading(false)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                logIn,
                logOut,
                signUp,
                fetchCurrentUser,
                callFunction,
                requestResetPassword,
                resetPassword,
                confirmUser,
                refreshSession,
                user,
                loading,
            }}>
            {props.children}
        </AuthContext.Provider>
    );
};

// The useAuth hook can be used by components under an AuthProvider to access
// the auth context value.
const useAuth = () => {
    const auth = useContext(AuthContext);
    if (auth == null) {
        throw new Error('useAuth() called outside of a AuthProvider?');
    }
    return auth;
};

export { AuthProvider, useAuth };
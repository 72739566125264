import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppStyle } from '@style'

import InitialScreens from '_scenes/initial';

const InitialStack = createStackNavigator();

function InitialNavigation(props) {


    const baseOptions = {
        headerTintColor: AppStyle.textHeaderColor,
        headerTransparent: true,
        headerTitle: '',
        headerBackTitleVisible: false,
    }

    return (
        <InitialStack.Navigator
        
            >
            <InitialStack.Screen
                name="language"
                component={InitialScreens.language}
                options={baseOptions}
            />
            
        </InitialStack.Navigator>
    );
}

export default InitialNavigation;

import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AppStyle } from '@style'

import OrdersScreen from '_scenes/orders';
import OrderListScreen from '_scenes/order/list';

const OrdersStack = createStackNavigator();


const baseOptions = {
    headerStyle: {
        backgroundColor: AppStyle.mainColor,
        elevation: 0,
        shadowOpacity: 0
    },
    animationEnabled: true,
    headerTintColor: AppStyle.textColor,
    headerTitle: ''
}

function OrderScreenNav() {

    return (
        <OrdersStack.Navigator
            screenOptions={({ route, navigation }) => ({
                title: `Nadius - ${`$root.screens.${route.name}`.localise()}`
            })}
            options={baseOptions}>

            <OrdersStack.Screen
                name="Orders"
                component={OrdersScreen}
                options={{ ...baseOptions, headerShown: false }}
            />
            <OrdersStack.Screen
                name="OrderList"
                component={OrderListScreen}
                options={baseOptions}
            />
        </OrdersStack.Navigator>
    );
}

export default OrderScreenNav;

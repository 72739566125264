import React, { useRef } from 'react';
import { View, StyleSheet, ScrollView, Keyboard, Pressable } from 'react-native';
import i18n from 'i18n-js';
import moment from "moment/min/moment-with-locales"

import { Alert } from '@platform'
import { useAuth } from '@realm'
import { ButtonText, SimpleText } from '@quarks';
import { Card } from '@atoms';
import { Icons, NadiusIcons } from '@icons';
import { AppStyle } from '@style';
import { autoCompleteType, textContentType, keyboardType, CardInput } from '@molecules';
import { Icon } from '@quarks'
import { replaceLocale } from '@utilities/locale'
import { User } from '@api'
import { BottomModal } from '@organisms';
import UserInfoEditScreen from '_scenes/account/userInfoEdit';
import { useDispatch } from 'react-redux';

const UserInfoScreen = (props) => {

    const dispatch = useDispatch()
    const { user, resetPassword, loading, callFunction } = useAuth();
    const safeAreaRef = useRef(null)

    let initialState = {
        success: undefined,
        modal: false,
        alert: false,
        error: undefined,
        form: undefined,
        loading: undefined,
        timeout: false,
        errorInput: undefined
    }

    const [pid, setPid] = React.useState('')
    let [state, setState] = React.useState(initialState)
    const styles = updateStyles()

    let _itemCard = (item, content, editFunction) => {
        let style = styles.text
        if (!content) {
            content = '$account.userInfo.noData'
            style = styles.textNoData
        }
        return (<View style={{ width: '100%', alignSelf: 'center' }}>
            <View style={styles.textView}>
                <SimpleText style={styles.text}>{item.title}</SimpleText>
            </View>
            <Pressable
                style={styles.card}
                onPress={editFunction ? editFunction(item) : undefined}>
                <Icon
                    name={item.icon}
                    size={20}
                    color={AppStyle.textColor}
                    style={styles.iconStyle} />
                <SimpleText style={style}>{content}</SimpleText>
                {editFunction && <Icon
                    name={NadiusIcons.RIGHT_ARROW}
                    iconSet={NadiusIcons.iconSet}
                    size={20}
                    color={AppStyle.textColor}
                    style={styles.iconStyle} />}
            </Pressable>
        </View>
        )
    }

    let name = {
        title: '$account.userInfoEdit.nameAndSurnames',
        icon: Icons.USER,
        mutation: User.updateNameAndSurname(),
        fields: {
            name: {
                title: '$account.userInfoEdit.name',
                autoCompleteType: autoCompleteType.name,
                textContentType: textContentType.givenName,
                keyboardType: keyboardType.default,
                value: user?.name,
                icon: Icons.USER,
            },
            surname: {
                title: '$account.userInfoEdit.surname',
                autoCompleteType: autoCompleteType.name,
                textContentType: textContentType.middleName,
                autoCompleteType: autoCompleteType.name,
                keyboardType: keyboardType.default,
                icon: Icons.USER,
                value: user?.surname
            }
        }
    }

    let email = {
        title: '$account.userInfoEdit.email',
        icon: Icons.ENVELOPE,
        fields: {
            email:
            {
                title: '$account.userInfoEdit.email',
                fieldTitle: '$account.userInfoEdit.emailTitle',
                value: user?.email
            }
        }
    }

    let phone = {
        title: '$account.userInfoEdit.phone',
        icon: Icons.PHONE,
        mutation: User.updatePhone(),
        fields: {
            phone: {
                title: '$account.userInfoEdit.phone',
                autoCompleteType: autoCompleteType.tel,
                keyboardType: keyboardType.phonePad,
                icon: Icons.PHONE,
                value: user?.phone
            }
        }
    }

    let language = {
        title: '$account.language.language',
        icon: Icons.LANGUAGE,
        mutation: User.updateLocale(),
        onCompleted: (form) => {
            i18n.locale = form.locale.value
            moment.locale(i18n.locale)
        },
        fields: {
            locale: {
                title: '$account.language.language',
                fieldTitle: '$account.language.language',
                icon: Icons.LANGUAGE,
                value: user?.locale,
                picker: true,
                noInput: true,
                pickerItems: [
                    { value: 'es', label: '$locale.es'.localise() },
                    { value: 'ca', label: '$locale.ca'.localise() }
                ]
            }
        }
    }

    let password = {
        title: '$account.security.titlePassword',
        icon: Icons.SECURITY,
    }

    const editFieldNavigate = (item) => () => {
        setState({
            ...initialState,
            form: item,
            modal: true
        })
    }

    const changePassword = () => () => {
        if (!state.alert) {
            setState({
                ...state,
                alert: true
            })
            Alert(dispatch).alert(
                replaceLocale('$account.security.alertTitle'),
                replaceLocale('$account.security.alertDescription'),
                [
                    {
                        text: replaceLocale('$account.security.cancel'),
                        style: 'cancel',
                    },
                    {
                        text: replaceLocale('$account.security.ok'), onPress: async () => {
                            setState({
                                ...state,
                                loading: true,
                                modal: true
                            })
                            let result = await resetPassword()
                            if (typeof result === 'undefined') {
                                setState({
                                    ...state,
                                    loading: false,
                                    success: true
                                })
                            }
                        }
                    }
                ],
                { cancelable: false },
            );
        }
    }

    const _onModalClose = () => {
        Keyboard.dismiss()
        setState(initialState)
    }

    return (<ScrollView style={styles.main} contentContainerStyle={styles.mainContent}>
        <View style={{ flex: 1 }}>
            {user._id == '5fdba1e44da9c30bb8cd18f8' &&
                <View style={{ width: '90%', alignSelf: 'center', marginBottom: 20 }}>
                    <CardInput
                        title='Enter Pid'
                        icon={pid != 'Done' ? 'door-closed' : 'door-open'}
                        style={{ marginBottom: 15, paddingTop: 10 }}
                        onChangeText={(text) => setPid(text)}
                        inputText={pid}
                        errorText={state.errorInput}
                        returnKeyType={'next'}
                    />
                    <ButtonText type={'solid'}
                        style={{ padding: 5, paddingLeft: 40, paddingRight: 40, marginBottom: 30 }}
                        loading={loading} text={'Submit'}
                        onPress={async () => {
                            if (pid.length == 24) {
                                setState({ ...state, loading: true })
                                let res = await callFunction('USER_pid', pid)
                                if (res.status == 0) {
                                    await fetchCurrentUser()
                                    setState({ ...state, errorInput: 'Done' })
                                } else {
                                    setState({ ...state, errorInput: JSON.stringify(res) })
                                }
                                setState({ ...state, loading: false })
                            } else {
                                setState({ ...state, errorInput: 'Invalid input' })
                            }
                        }
                        } />
                </View>
            }
            {_itemCard(name, `${user.name} ${user.surname ? user.surname : ''}`, editFieldNavigate)}
            {_itemCard(phone, user.phone ? `${user.phone.countryCode} ${user.phone.number}` : undefined, editFieldNavigate)}
            {_itemCard(email, user.email)}
            {_itemCard(password, '$account.security.changePassword', changePassword)}
            {_itemCard(language, `$locale.${user.locale}`, editFieldNavigate)}
        </View>
        {state.modal && <BottomModal
            safeAreaRef={safeAreaRef}
            closeButton
            style={{ maxWidth: 500, width: '100%', alignSelf: 'center' }}
            onSuccess={_onModalClose}
            loading={loading || state.loading}
            onTimeout={() => {
                setState({ ...state, timeout: true })
            }}
            success={state.success}
            error={typeof state.error !== 'undefined'}
            onRequestClose={_onModalClose}
            visible={state.modal}>
            <UserInfoEditScreen
                onEqual={_onModalClose}
                loading={loading}
                onError={(error) => {
                    setState({ ...state, error: error })
                }}
                onCompleted={() => {
                    setState({ ...state, success: true })
                }}
                form={state.form} />
        </BottomModal>}
    </ScrollView>
    )
};

const updateStyles = () => StyleSheet.create({
    main: {
        flex: 1,
        backgroundColor: AppStyle.backgroundColor,
        paddingTop: 15
    },
    mainContent: {
        justifyContent: 'center',
        alignContent: 'center',
        width: '90%',
        alignSelf: 'center',
        paddingBottom: 30
    },
    card: {
        marginBottom: 20,
        flex: 1,
        width: '100%',
        backgroundColor: 'white',
        paddingTop: 12,
        paddingBottom: 12,
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 10,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 4,
    },
    iconStyle: {
        flex: 1,
        marginLeft: 30,
        textAlign: 'center',
    },
    text: {
        color: AppStyle.textColor,
        marginLeft: 15,
        flex: 9
    },
    textNoData: {
        color: AppStyle.getColor('dark', 4),
        paddingLeft: 15,
        flex: 9
    },
    textView: {
        maxWidth: 550,
        flex: 1,
        paddingBottom: 10
    },
});

export default UserInfoScreen;
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AppStyle } from '@style'
import NextStepsScreen from '_scenes/access/nextSteps';
import KYCFormScreen from '_scenes/finances/kycForm';
import KYCSubmitScreen from '_scenes/finances/kycSubmit';
import PorfileNavigation from './profile'
import CompanyFormScreen from '_scenes/companyForm/companyForm'

const NextStepsStack = createStackNavigator();


const baseOptions = {
    headerStyle: {
        backgroundColor: AppStyle.mainColor,
        elevation: 0,
        shadowOpacity: 0
    },
    animationEnabled: true,
    headerTintColor: AppStyle.textColor,
    headerTitle: ''
}

function NextStepsNavigation() {

    return (
        <NextStepsStack.Navigator
            screenOptions={({ route, navigation }) => ({
                title: `Nadius - ${`$root.screens.${route.name}`.localise()}`
            })}
            options={baseOptions}>
            <NextStepsStack.Screen
                name="NextSteps"
                component={NextStepsScreen}
                options={baseOptions}
            />
            <NextStepsStack.Screen
                name="ProducerProfile"
                component={PorfileNavigation}
                options={baseOptions}
            />
            <NextStepsStack.Screen
                name="CompanyForm"
                component={CompanyFormScreen}
                options={baseOptions}
            />
            <NextStepsStack.Screen
                name="KYCForm"
                component={KYCFormScreen}
                options={baseOptions}
            />
            <NextStepsStack.Screen
                name="KYCSubmit"
                component={KYCSubmitScreen}
                options={baseOptions}
            />
        </NextStepsStack.Navigator>
    );
}

export default NextStepsNavigation;

import React, { useState } from 'react';
import { View, LayoutAnimation, Platform, UIManager, StyleSheet, ScrollView, RefreshControl } from 'react-native';

import { useQuery } from '@apollo/client';

import { LoadingView, ErrorView, Banner } from '@atoms'
import { AppStyle } from "@style";
import { Producer } from '@api'
import { useAuth } from '@realm'
import { isAllNull } from 'nexto-utils/objects'
import { replaceLocale } from '@utilities/locale'
import { GeneralView, FinantialView } from '_scenes/companyForm'

let config = LayoutAnimation.create(200, 'linear', 'opacity')

if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }
}


const FiscalScreen = (props) => {

    const { user } = useAuth();
    const styles = updateStyles()
    const locationFields = ['AddressLine1', 'City', 'Region', 'PostalCode', 'Country']
    const locationMongoToMango = {
        place_name: 'City', 
        admin_name2: 'Region',
        postal_code: 'PostalCode',
        country_code: 'Country',
    }
    const locationMangoToMongo = {
        City: 'place_name',
        Region: 'admin_name2',
        PostalCode: 'postal_code',
        Country: 'country_code'
    }
    const scrollRef = React.useRef(null)

    let initialState = {
        HeadquartersAddress: {
            AddressLine1: undefined,
            City: undefined,
            Region: undefined,
            PostalCode: undefined,
            Country: undefined
        },
        HeadquartersLocation: {},
        Name: undefined,
        LegalPersonType: 'BUSINESS',
        CompanyNumber: undefined,
        Email: undefined,
        PhoneNumber: undefined,
        payment: {
            IBAN: undefined,
            OwnerName: undefined, 
            OwnerAddress: {
                AddressLine1: undefined,
                AddressLine2: undefined,
                City: undefined,
                Region: undefined,
                PostalCode: undefined,
                Country: undefined
            },
            OwnerLocation: {}
        }
    }

    let [form, setForm] = useState(initialState)
    let [status, setStatus] = useState({
        checkRefetch: true,
        error: false,
        loading: true,
        success: false
    })

    const _onFiscalDataReceived = (data) => {
        if (data.FiscalData !== null && typeof data.FiscalData !== 'undefined' && !isAllNull(data.FiscalData, ['__typename'])) {
            setForm(data.FiscalData)
        }
    }

    const fiscalDataQuery = Producer.getFiscalData(user?.producer?._id)
    let fiscalDataResult = useQuery(fiscalDataQuery.query, {
        ...fiscalDataQuery.param,
    })

    const checkRefetch = () => {
        if (status.checkRefetch && !fiscalDataResult.error && !fiscalDataResult.loading && typeof fiscalDataResult.data !== 'undefined'){
            setStatus({
                ...status,
                checkRefetch: false,
                loading: false
            })
            _onFiscalDataReceived(fiscalDataResult.data)
        } else if(status.checkRefetch && fiscalDataResult.error) {
            setStatus({
                ...status,
                checkRefetch: false,
                loading: false,
                error: true
            })
        }
    }
    checkRefetch()
    
    React.useEffect(() => {
        props.navigation.setOptions({
            headerTitle: replaceLocale('$companyForm.fiscal.header'),
            headerBackTitle: '$choose.title'.localise(),
            headerTruncatedBackTitle:'$button.headerTruncatedBack'.localise()
        })
    }, [props.navigation])
    
    return (
        <ScrollView 
            ref={scrollRef}
            refreshControl={
                <RefreshControl
                    refreshing={typeof fiscalDataResult?.loading === 'undefined' ? false : fiscalDataResult.loading}
                    onRefresh={() => {
                        if (typeof fiscalDataResult?.refetch !== 'undefined') {
                            setStatus({
                                ...status,
                                checkRefetch: true
                            })
                            fiscalDataResult.refetch()
                        }
                    }} />
            }
            style={{
                flex: 1,
                width: '100%',
                alignSelf: 'center',
                backgroundColor: AppStyle.backgroundColor
            }}
            contentContainerStyle={(status.error || status.loading) ? { flexGrow: 1, justifyContent: 'center' } : {}}>
            
            {!status.error && !status.loading && <View>
                <Banner
                    style={{ alignSelf: 'center', marginTop: 20 }}
                    type={'info'}
                    textStyle={{ textAlign: 'center' }}
                    numberOfLines={10}
                    message={'$companyForm.fiscal.readOnly'} />

                <GeneralView 
                    form={form}/>

                <FinantialView 
                    form={form}/>

            </View>}

            {status.error && 
                <View>
                    <ErrorView
                        style={[{ height: 260, width: 280 }, props.style]}
                        errorText={'$companyForm.fiscal.error'}
                    />
                </View>}

            {status.loading && 
                <LoadingView
                style={[{ height: 100, width: 100, padding: 10 }, props.style]}
            />}
            
        </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    date: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 350,
        height: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 10,
        marginTop: 0
    },
    card: {
        backgroundColor: AppStyle.cardBackground,
        shadowRadius: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
        maxWidth: 350,
        maxHeight: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 0,
        borderRadius:30,
    }
});

export default FiscalScreen;
import React, { useState } from 'react';
import { View, StyleSheet, FlatList, Switch, Dimensions, Platform, TouchableOpacity, ScrollView } from 'react-native';
import { SimpleText } from '@quarks';
import { BlurModal } from '@atoms';
import { AppStyle, TextStyle } from '@style';
import { ButtonText } from '../quarks/buttonText';

export const OptionsPickerModal = (props) => {
    /**
     * prop.options should be:
     *  [
     *      {
     *          key!: 'id',
     *          name!: 'string'
     *          state?: boolean
     *          disabled?: true || false
     *      }, ...
     *  ]
     *  props.onValueChange is called for any status change on any item
     * 
     */

    let height = Dimensions.get('window').height

    if (Platform.OS === "ios") {
        height = height / 2.5
    } else {
        height = height / 2
    }

    let state = {}

    for (let opt of props.options) {
        state[opt.key] = opt.state === true ? true : false
    }


    const [enabledStatus, setEnabledStatus] = useState(state);

    let shouldUpdate = false
    for (let key in state) {
        if (enabledStatus[key] != state[key]) {
            shouldUpdate = true
        }
    }
    if (shouldUpdate) {
        setEnabledStatus(state)
    }

    const toggleSwitch = (key) => () => {
        let tmp = { ...enabledStatus }
        tmp[key] = !tmp[key]
        setEnabledStatus(tmp);
        props.onValueChange({ key: key, state: tmp[key] })
    }

    const renderItem = ({ item }) => (
        <View style={{
            flexDirection: 'row',
            alignContent: 'flex-start', paddingBottom: 5, paddingTop: 5
        }}>
            <Switch
                trackColor={{ true: AppStyle.mainColor }}
                thumbColor={AppStyle.getColor('light', 1)}
                onValueChange={toggleSwitch(item.key)}
                value={enabledStatus[item.key]}
                disabled={item.disabled}
            />
            <SimpleText style={{ marginLeft: 10 }}>{item.name}</SimpleText>
        </View>
    );

    return (
        <BlurModal visible={props.visible} contentStyle={{ maxWidth: 400, justifyContent: 'center' }} >
            <FlatList
                contentContainerStyle={{ justifyContent: "center", marginLeft: 10 }}
                data={props.options}
                renderItem={renderItem}
            />
            <ButtonText style={{
                alignSelf: 'center',
                padding: 5,
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 10
            }} type={'solid'} text={'$common.components.buttons.done'} onPress={props.close}>
            </ButtonText>
        </BlurModal>
    )

};

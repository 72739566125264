import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image, View, SafeAreaView, RefreshControl, InteractionManager, Dimensions, Platform } from 'react-native';
import { ProgressSteps, ProgressStep } from 'react-native-progress-steps';
import { useQuery, useCallback } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux'
import { isAllNull, removeField } from 'nexto-utils/objects';
import { ButtonText, SimpleText } from '@quarks'
import { Banner, FloatingButton } from '@atoms'
import { FeedbackModal } from '@molecules'
import { sessionOperations } from '_ducks/session'
import { AppStyle, TextStyle } from "@style";
import { Icons, NadiusIcons } from '@icons'
import { ICONS } from '_images'
import { useAuth } from '@realm'
import { producer as codes } from 'nexto-codes'
import { Producer } from '@api'

const NextStepsScreen = (props) => {

    const dispatch = useDispatch()
    const reduxForm = useSelector(state => state.session.kycForm)
    const { user, logOut, callFunction, fetchCurrentUser } = useAuth();
    const { query, param } = Producer.getToValidateById(user?.producer?._id)
    const { loading, error, data, refetch, called } = useQuery(query, {
        ...param
    });

    const companyQuery = Producer.getMangoCompany(user?.producer?._id)
    let companyResult = useQuery(companyQuery.query, {
        ...companyQuery.param
    })

    let initialState = { success: false, loading: false, error: false, errorMessage: undefined }
    const [actionState, setActionState] = useState(initialState)
    const producer = !loading && !error ? data?.producer : user?.producer

    const producerStatus = user?.producer?.status?.code

    if (props.navigation.isFocused() &&
        typeof producerStatus != 'undefined' &&
        //producerStatus != codes.STATUS.VALIDATED &&
        producerStatus != codes.STATUS.WAITING_OUR_VALIDATION &&
        producerStatus != codes.STATUS.WAITING_REQUIRED_INFORMATION) {
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Main' }],
        });
    }

    const styles = updateStyles()

    const _refreshData = async () => {
        await refetch()
        await fetchCurrentUser()
        await companyResult.refetch()
    }

    React.useEffect(() => {
        if (producerStatus == codes.STATUS.WAITING_REQUIRED_INFORMATION &&
            companyResult.called && called) {
            companyResult.refetch()
            refetch()
        }
        props.navigation.setOptions({
            headerTitle: "",
            headerShown: false
        })
    }, [props.navigation]);

    const checkPublicProfile = () => {
        if (!producer) {
            return false
        }

        return (
            typeof producer.name === 'string' &&
            producer.images?.cover &&
            producer.images?.profile &&
            producer.location?.place_name &&
            typeof producer.contact?.email === 'string' &&
            (typeof producer.translations?.ca?.description === 'string' ||
                typeof producer.translations?.es?.description === 'string')
        )
    }

    const isPublicProfileCompleted = checkPublicProfile()

    const checkCompanyForm = () => {
        let company = companyResult?.data?.MangoCompany
        console.log(company)
        if (!isAllNull(company, ['__typename'])) {
            return true
        }
        return false //validate company required fields
    }

    const checkKYCForm = () => {

        if (!companyResult.called) return false

        let company = companyResult?.data?.MangoCompany?.payment?.kyc

        if (company == null || typeof company === 'undefined') {
            if (reduxForm.pending !== false || reduxForm.done !== false) {
                dispatch(sessionOperations.resetKycForm())
            }
        } else if (typeof company !== 'undefined') {
            let isDone = company.done
            let isPending = company.pending
            if (typeof isDone !== 'undefined' && isDone != null) {
                if (isDone === true && reduxForm.done === false) {
                    dispatch(sessionOperations.doneKycForm())
                } else if (isDone === false && reduxForm.done === true) {
                    // This case helps reseting the KYC form status from cloud
                    dispatch(sessionOperations.resetKycForm())
                }
            } else if (typeof isPending !== 'undefined' &&
                isPending != null && reduxForm.pending === false
                && reduxForm.done === false) {
                if (isPending === true) {
                    dispatch(sessionOperations.pendingKycForm())
                } else if (isPending === false && companyResult.refetch) {
                    companyResult.refetch()
                }
            } else if ((reduxForm.pending !== false || reduxForm.done !== false) &&
                (typeof isPending === 'undefined' || isPending == null) &&
                (typeof isDone === 'undefined' || isDone == null)) {
                dispatch(sessionOperations.resetKycForm())
            }
        }

        return reduxForm?.done == true //validate company required fields
    }

    const isCompanyFormCompleted = checkCompanyForm()
    const isKYCFormCompleted = checkKYCForm()
    const isKYCFormPending = reduxForm?.pending

    const isValidated = producerStatus == codes.STATUS.VALIDATED
    const getStep = () => {
        switch (producerStatus) {
            case codes.STATUS.WAITING_REQUIRED_INFORMATION:
                return 0;
            case codes.STATUS.WAITING_OUR_VALIDATION:
                return 1;
            case codes.STATUS.VALIDATED:
                return 2;
        }
    }
    const currentStep = getStep()

    const companyIcon = !isCompanyFormCompleted ?
        ICONS.COMPANY_INFO
        : ICONS.COMPANY_INFO_COMPLETED

    const kycIcon = isKYCFormPending || isKYCFormCompleted ? ICONS.COMPANY_INFO_COMPLETED : ICONS.COMPANY_INFO

    const profileIcon = !isPublicProfileCompleted ?
        ICONS.PUBLIC_PROFILE
        : ICONS.PUBLIC_PROFILE_COMPLETED

    const _submitForValidation = () => {
        if (isPublicProfileCompleted &&
            isCompanyFormCompleted &&
            isKYCFormPending) {
            setActionState({ success: false, loading: true, error: false })

            callFunction("PRODUCER_sendForValidation")
                .then(result => {
                    switch (result.status) {
                        case codes.RESPONSE_CODES.OK:
                            setActionState(initialState)
                            refetch()
                            break;
                        case codes.RESPONSE_CODES.ERROR:
                        case codes.RESPONSE_CODES.UNKNOWN_ERROR:
                            setActionState({ ...initialState, error: true, errorMessage: '$common.errors.unknown' })
                            break;
                        case codes.RESPONSE_CODES.NEXTSTEP_SUBMIT_INVALID_PRODUCER_STATUS:
                            setActionState({ ...initialState, error: true, errorMessage: '$access.nextSteps.onSumbitInvalidStatus' })
                            break;
                        case codes.RESPONSE_CODES.NEXTSTEP_SUBMIT_COMPANY_PENDING:
                            setActionState({ ...initialState, error: true, errorMessage: '$access.nextSteps.errorCompanyPending' })
                            break;
                        case codes.RESPONSE_CODES.NEXTSTEP_SUBMIT_PROFILE_PENDING:
                            setActionState({ ...initialState, error: true, errorMessage: '$access.nextSteps.errorProfilePending' })
                            break;
                    }
                })
                .catch(err => {
                    setActionState({ ...initialState, error: true, errorMessage: '$common.errors.unknown' })
                })
        }
    }


    const _onValidated = async () => {
        setActionState({ success: false, loading: true, error: false, errorMessage: '' })

        try {
            let result = await callFunction("PRODUCER_enable")

            switch (result.status) {
                case codes.RESPONSE_CODES.OK:
                    await fetchCurrentUser()

                    props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'Main' }],
                    });

                    break;
                case codes.RESPONSE_CODES.ERROR:
                    setActionState({ success: false, loading: false, error: true, errorMessage: '$access.nextSteps.onGoLiveInvalidStatus' })
                    break;
                case codes.RESPONSE_CODES.UNKNOWN_ERROR:
                default:
                    setActionState({ success: false, loading: false, error: true, errorMessage: '$common.errors.unknown' })
                    break;
            }
        } catch (err) {
            setActionState({ success: false, loading: false, error: true, errorMessage: '$common.errors.unknown' })
        }


    }


    return (<>
        <ScrollView style={{
            paddingTop: 50, height: '100%',
            backgroundColor: 'white', width: Dimensions.get('window').width < 768 ? '100%' : '80%', alignSelf: 'center'
        }}
            refreshControl={Platform.OS !== 'web' &&
                <RefreshControl
                    refreshing={typeof loading === 'undefined' ? false : typeof companyResult?.loading === 'undefined' ? loading : loading || companyResult?.loading}
                    onRefresh={_refreshData} />
            }
            contentContainerStyle={{
                width: '95%', maxWidth: 750, justifyContent: 'center',
                alignSelf: 'center'
            }}>

            <SimpleText style={styles.welcomeTitle}>
                {`$access.nextSteps.mainHeader`}
            </SimpleText>
            <SimpleText style={styles.stepMessage}>
                {currentStep == 0 ? `$access.nextSteps.description` : `$access.nextSteps.validating`}
            </SimpleText>
            <View
                style={styles.listItem}
            >
                <View style={styles.iconAndText}>
                    <Image
                        style={styles.listIcon}
                        source={companyIcon}
                    />
                    <SimpleText style={styles.listText}>
                        $access.nextSteps.companyForm
                        </SimpleText>

                </View>
                {!isCompanyFormCompleted &&
                    <View style={styles.buttonView}>
                        <ButtonText
                            onPress={() => {
                                props.navigation.navigate('CompanyForm')
                            }}
                            icon={NadiusIcons.RIGHT_ARROW}
                            iconSet={NadiusIcons.iconSet}
                            iconSize={25}
                            iconRight
                            iconStyle={{ marginLeft: 20 }}
                            type={'clear'}
                            style={styles.button} />
                    </View>}

            </View>
            <View
                style={styles.listItem}
            >
                <View style={styles.iconAndText}>
                    <Image
                        style={styles.listIcon}
                        source={kycIcon}
                    />
                    <View style={{ maxWidth: '90%' }}>
                        <SimpleText style={styles.listText}>
                            $access.nextSteps.kycForm
                            </SimpleText>
                        {!isCompanyFormCompleted && <SimpleText style={styles.listTextDesc}>
                            $access.nextSteps.kycFormDisabled
                            </SimpleText>}
                    </View>

                </View>
                {!isKYCFormCompleted && !isKYCFormPending &&
                    <View style={styles.buttonView}>
                        <ButtonText
                            onPress={() => {
                                props.navigation.navigate('KYCForm')
                            }}
                            icon={isCompanyFormCompleted && NadiusIcons.RIGHT_ARROW}
                            iconSet={NadiusIcons.iconSet}
                            iconSize={25}
                            iconRight
                            disabled={!isCompanyFormCompleted}
                            iconStyle={{ marginLeft: 20 }}
                            type={'clear'}
                            style={styles.button} />
                    </View>
                }
            </View>

            <View
                style={styles.listItem}
            >
                <View style={styles.iconAndText}>
                    <Image
                        style={styles.listIcon}
                        source={profileIcon}
                    />
                    <SimpleText style={styles.listText}>
                        $access.nextSteps.publicProfile
                        </SimpleText>
                </View>
                <View style={styles.buttonView}>
                    <ButtonText
                        onPress={() => {
                            props.navigation.navigate('ProducerProfile')
                        }}
                        icon={NadiusIcons.RIGHT_ARROW}
                        iconSet={NadiusIcons.iconSet}
                        iconSize={25}
                        iconRight
                        iconStyle={{ marginLeft: 20 }}
                        type={'clear'}
                        style={styles.button} />
                </View>

            </View>
            {currentStep == 0 && <ButtonText
                onPress={_submitForValidation}
                disabled={(!isPublicProfileCompleted
                    || !isKYCFormPending
                    || !isCompanyFormCompleted)
                    && currentStep == 0}
                type={'solid'}
                style={{ marginTop: 25, marginBottom: 25, paddingRight: 10, paddingLeft: 10 }}
                textStyle={{ padding: 10, paddingLeft: 30, paddingRight: 30 }}
                loading={actionState.loading}
                text={'$access.nextSteps.submit'} />}
            <FeedbackModal
                error={actionState.error}
                errorText={actionState.errorMessage}
                onFeedbackPress={() => {
                    setActionState({ ...actionState, error: false })
                }}
            />
        </ScrollView>



    </>)
}


const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        color: AppStyle.mainColor,
        alignSelf: 'center',
        textAlign: 'center',
    },
    listView: {
        width: '90%',
        alignSelf: 'center',
        marginBottom: 100
    },
    listItem: {
        flexDirection: 'row',
        alignContent: "space-between",
        backgroundColor: AppStyle.backgroundColor,
        marginTop: 20,
        paddingBottom: 10,
        paddingTop: 10,
        borderRadius: AppStyle.smallBorderRadius
    },
    listIcon: { width: 65, height: 65, alignSelf: 'center' },
    listText: {
        marginLeft: 20,
        ...TextStyle.size.large
    },
    listTextDesc: {
        marginLeft: 20,
        color: AppStyle.textColorLight
    },
    iconStyle: {
        alignSelf: 'center',
        backgroundColor: 'red',
        marginRight: 10,
    },
    listTextView: {
        alignSelf: 'center'
    },
    buttonView: {
        alignSelf: 'center',
    },
    iconAndText: {
        flex: 1,
        maxWidth: '90%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: 10,
        height: 35, paddingLeft: 20, paddingRight: 20, padding: 5
    },
    verificationView: {
        width: '80%',
        alignSelf: 'center',
    },
    verificationText: {
        ...TextStyle.size.large
    },
    listIconView: {
        justifyContent: 'center',
    },
    stepMessage: {
        marginTop: 15,
        marginBottom: 10,
        alignSelf: 'center'
    }

});

export default NextStepsScreen;



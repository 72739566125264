import React, { useState } from 'react';
import { View, LayoutAnimation, StyleSheet, ScrollView, Dimensions } from 'react-native';
import { useQuery } from '@apollo/client';

import { Maps } from '@platform'
import { KeyboardAwareScrollView } from '@platform'
import { AppStyle, TextStyle } from '@style'
import { ButtonText, SimpleText } from '@quarks';
import { FloatingButton, LoadingView, ErrorView } from '@atoms'
import { useAuth } from '@realm'
import { MultipleLanguage, LocationInput, ImageUploader } from '@organisms';
import {
    CardInput,
    keyboardType, autoCompleteType, textContentType, FeedbackModal,
} from '@molecules'

import { producerFormReducedValidationErrors } from 'nexto-formvalidator';
import { replaceLocale } from '@utilities/locale';
import { producer as codes } from 'nexto-codes'
import { Icons } from '@icons'
import { Producer } from '@api'
import { translationsAwareObjectDiff, removeField } from 'nexto-utils/objects'

const FormScreen = (props) => {
    const windowHeight = Dimensions.get('window').height;
    const styles = updateStyles(Dimensions.get('window').width, windowHeight)


    const { user, callFunction } = useAuth();


    let imageUploaderRef = React.useRef(null)

    const _onProducerReceived = (data) => {
        console.log('we are inside')
        console.log(data)
        if (typeof data === 'undefined') return;
        let prod = { ...data.producer }

        let toStore = JSON.parse(JSON.stringify({ ...producerForm, ...prod }))
        if (!toStore.images.list) {
            toStore.images.list = []
        }


        setErrorForm(errorForm)
        setProducerForm(toStore)
        setRegion({
            longitude: typeof prod?.location?.coordinates?.[0] === 'undefined' ? -3.5933755943578944 : prod.location.coordinates[0],
            latitude: typeof prod?.location?.coordinates?.[1] === 'undefined' ? 40.33362216621484 : prod.location.coordinates[1],
            latitudeDelta: typeof prod?.location?.coordinates?.[0] === 'undefined' ? 10 : 0.0922,
            longitudeDelta: typeof prod?.location?.coordinates?.[0] === 'undefined' ? 10 : 0.0421,
        })
    }

    const producerQuery = Producer.getProfile(user?.producer?._id, true);
    const producerResult = useQuery(producerQuery.query, {
        ...producerQuery.param,
        onCompleted: _onProducerReceived,
        fetchPolicy: 'cache-and-network'
    })


    let config = LayoutAnimation.create(200, 'linear', 'opacity')

    let initialStatus = { loading: false, error: false, success: false }
    let [formStatus, setFormStatus] = useState(initialStatus);


    let [producerForm, setProducerForm] = useState({
        name: undefined,
        location: {
            type: "Point",
            postal_code: undefined,
        },
        translations: {},
        images: {
            cover: undefined,
            profile: undefined,
            list: []
        },
        placeholders: {
            cover: {},
            list: {},
            profile: {},
        },
        picker: undefined
    })

    if (typeof producerForm.deliveryPrice === 'undefined') {
        producerForm.deliveryPrice = 0.0
    }

    let [region, setRegion] = useState(undefined)

    let initialErrorForm = {
        contact: {
            email: undefined,
            phone: undefined,
            website: undefined
        },
        name: undefined,
        location: {
            postal_code: undefined,
            street: undefined
        },
        translations: {},
        images: {
            cover: undefined,
            profile: undefined,
            list: []
        }
    }
    let [errorForm, setErrorForm] = useState(initialErrorForm)

    let displayedImages = [];
    if (producerForm.images.list) {
        displayedImages = producerForm.images.list.concat(Object.values(producerForm.placeholders.list))
    }

    const _placeHolderImageUpdate = (field) => (placeholderId, image) => {
        let tmp = { ...producerForm }
        tmp.placeholders[field][placeholderId] = image
        LayoutAnimation.configureNext(config);
        setProducerForm(tmp)
    }

    const _imageUploaded = (field) => (placeholderId, id) => {

        let tmp = { ...producerForm }
        switch (field) {
            case 'list':
                tmp.images[field].push(id)
                break
            case 'cover':
            case 'profile':
                tmp.images[field] = id
                break;
        }

        delete tmp.placeholders[field][placeholderId]

        let errors = { ...errorForm }
        if (errors?.images?.[field]) {
            errors.images[field] = undefined
            setErrorForm(errors)
        }
        LayoutAnimation.configureNext(config);
        setProducerForm(tmp)

    }

    const _imageDelete = (field) => (placeholderId, image) => {

        let tmp = { ...producerForm }

        if (typeof placeholderId != 'undefined') {
            delete tmp.placeholders[field][placeholderId]

        } else {
            if (field == 'list') {
                let i = tmp.images.list.indexOf(image)
                if (i != -1) {
                    tmp.images.list.splice(i, 1)
                }
            } else {
                tmp.images[field] = undefined
            }
        }

        LayoutAnimation.configureNext(config);
        setProducerForm(tmp)
    }

    React.useEffect(() => {
        if (typeof props.route?.params?.location != 'undefined') {
            let aux = { ...producerForm }
            aux.location.coordinates = JSON.parse(props.route.params.location).coordinates
            setProducerForm(aux)

            setRegion({
                longitude: typeof aux?.location?.coordinates?.[0] === 'undefined' ? -3.5933755943578944 : aux.location.coordinates[0],
                latitude: typeof aux?.location?.coordinates?.[1] === 'undefined' ? 40.33362216621484 : aux.location.coordinates[1],
                latitudeDelta: typeof aux?.location?.coordinates?.[0] === 'undefined' ? 10 : 0.0922,
                longitudeDelta: typeof aux?.location?.coordinates?.[0] === 'undefined' ? 10 : 0.0421,
            })
        }
    }, [props.route?.params?.location])

    const _onValueChange = (field, options = {}) => (value) => {
        let tmp = { ...producerForm }
        if (field == 'translations') {

            if (tmp[field][options.index] == null || typeof tmp[field][options.index] == 'undefined') {
                tmp[field][options.index] = { description: undefined, isDeleted: false }
            }

            tmp[field][options.index][options.subfield] = value
        } else if (field === 'contact') {
            tmp[field][options.subfield] = value
        } else if (field === 'street') {
            tmp.location[field] = value
        } else if (field === 'location') {

            if (tmp[field] == null || typeof tmp[field] === 'undefined') tmp[field] = {}

            if (value?.coordinates && (!tmp[field].coordinates ||
                value?.place_name !== tmp[field].place_name ||
                value?.admin_name2 !== tmp[field].admin_name2)) {
                let reg = { ...region }
                value.coordinates = { ...value?.location?.coordinates }
                reg.longitude = value?.coordinates[0]
                reg.latitude = value?.coordinates[1]
                reg.latitudeDelta = 0.0922
                reg.longitudeDelta = 0.0421
                setRegion(reg)
                tmp[field].coordinates = Object.values(value?.coordinates)
            }
            if (typeof value?.place_name != 'undefined') {
                tmp[field].place_name = value?.place_name
            }
            if (typeof value?.admin_name2 != 'undefined') {
                tmp[field].admin_name2 = value?.admin_name2
            }

            if (typeof value?.postal_code != 'undefined') {
                tmp[field].postal_code = value?.postal_code
            }
        } else {
            tmp[field] = value
        }

        LayoutAnimation.configureNext(config);
        setProducerForm(tmp)
    }

    const _addOrRemoveLocale = (key, state) => {

        console.log('ADD LOCALE ', key, state)

        let tmp = { translations: { [key]: { description: undefined } }, ...producerForm }
        let tmpError = { translations: { [key]: { description: undefined } }, ...errorForm }

        if (typeof tmp.translations[key] === 'undefined' || tmp.translations[key] == null) {
            tmp.translations[key] = { description: undefined }
        }
        if (typeof tmpError.translations[key] === 'undefined' || tmpError.translations[key] == null) {
            tmpError.translations[key] = { description: undefined }
        }

        tmp.translations[key].isDeleted = state
        tmpError.translations[key].isDeleted = state

        console.log('tmp.translations ', tmp.translations)

        setProducerForm(tmp)
        LayoutAnimation.configureNext(config);

    }

    const _onSubmit = async () => {

        if (formStatus.loading) return

        setFormStatus({ loading: true, error: false, success: false })

        let prForm = {
            name: producerForm.name,
            images: producerForm.images,
            location: producerForm.location,
            translations: producerForm.translations
        }
        let producer = producerResult?.data?.producer
        let currentProducer = {
            name: producer.name,
            translations: producer.translations,
            images: producer.images,
            location: producer.location
        }

        console.log('TRANSLATIONS ', producer.translations)

        let auxLang = {}
        if (prForm.translations != null && typeof prForm.translations !== 'undefined') {
            for (let [lang, value] of Object.entries(prForm.translations)) {
                if (lang != "__typename" && value != null && !value.isDeleted) {
                    auxLang[lang] = {
                        description: value?.description?.trim()
                    }
                    if (typeof value?.slogan != 'undefined' && value?.slogan != null) {
                        auxLang[lang].slogan = value.slogan.trim()
                    }
                } else if (value != null && value.isDeleted) {
                    delete auxLang[lang]
                }
            }
            prForm.translations = auxLang
        }

        console.log('PR FORM ', prForm)

        let changes = translationsAwareObjectDiff(currentProducer, prForm, ["__typename"], ['phone'])
        if (changes.isEqual && !changes.translateDeleted) {
            setFormStatus({ loading: false, error: false, success: true })
            setTimeout(() => {
                props.navigation.goBack()
            }, 1000)
            return
        }

        let { formErrors, isValid } = producerFormReducedValidationErrors(changes.diff)

        if (isValid) {
            if (changes.translateDeleted) {
                changes.diff['translations'] = Object.assign({}, prForm['translations'])
            }
            removeField(changes.diff, '__typename')
            setErrorForm(initialErrorForm);

            let result = await callFunction("PRODUCER_producerForm", changes.diff)
            switch (result.status) {
                case codes.RESPONSE_CODES.OK:
                    setFormStatus({ loading: false, error: false, success: true })
                    setTimeout(() => {
                        props.navigation.navigate('Main', { update: true })
                    }, 1000)
                    break
                case codes.RESPONSE_CODES.FORM_NOT_VALID:
                    if (typeof result?.formErrors != 'undefined') {
                        if (typeof result.formErrors === 'string') {
                            setErrorForm(JSON.parse(result.formErrors))
                        }
                    }
                    break
                default:
                    setFormStatus({ loading: false, error: true, errorText: replaceLocale(codes.RESPONSE_MESSAGES[result.status]), success: false })
                    break

            }
        } else {
            setFormStatus({ loading: false, error: true, errorText: "$profile.form.errorInDataDesc".localise(), success: false })
            setErrorForm(formErrors)

        }
    }


    const _getUniqueErrors = (repitedErrors) => {
        let errors = []

        if (!repitedErrors) return errors

        for (let i = 0; i < repitedErrors.length; i++) {
            if (!errors.includes(replaceLocale(repitedErrors[i]))) {
                errors.push(replaceLocale(repitedErrors[i]))
            }
        }
        return errors;
    }

    const _onMapPress = (event) => {

        props.navigation.navigate('map', { location: JSON.stringify(producerForm.location) })

    }


    let coverImage = undefined
    let profileImage = undefined
    if (typeof producerForm !== 'undefined') {
        coverImage = Object.keys(producerForm.placeholders.cover).length === 0 ? producerForm.images.cover : producerForm.placeholders.cover[Object.keys(producerForm.placeholders.cover)[0]]
        profileImage = Object.keys(producerForm.placeholders.profile).length === 0 ? producerForm.images.profile : producerForm.placeholders.profile[Object.keys(producerForm.placeholders.profile)[0]]
    }

    const _renderLocalesContent = (locale) => {


        return <CardInput
            key={locale}
            style={styles.cardInput, { maxWidth: undefined }}
            title={'$profile.form.description'}
            minHeight={150}
            onChangeText={_onValueChange('translations', { index: locale, subfield: 'description' })}
            keyboardType={keyboardType.default}
            multiline
            autoCompleteType={undefined}
            inputText={producerForm?.translations?.[locale]?.description}
            placeholder={replaceLocale('$profile.form.descriptionPlaceholder', locale)}
            errorText={errorForm?.translations?.[locale]?.description}
            infoText={'$profile.form.descriptionDesc'}
            showHelp={true}
        />

    }

    return (
        <>
            <ScrollView style={{
                height: '100%', paddingLeft: 0,
                backgroundColor: AppStyle.backgroundColor
            }}>
                {producerResult.error && <ErrorView error={producerResult.error} />}
                {producerResult.loading && <LoadingView />}

                {!producerResult.loading && !producerResult.error &&
                    <KeyboardAwareScrollView style={styles.scroll}
                        contentContainerStyle={{
                            width: '100%', alignSelf: 'center',
                            justifyContent: 'center', backgroundColor: 'white', maxWidth: Dimensions.get('window').width < 768 ? '95%' : '80%',
                        }}>

                        <View style={{ width: "90%", height: "100%", maxWidth: 850, alignSelf: 'center', backgroundColor: 'white', justifyContent: 'center', }}>
                            <View style={{
                                flexDirection: 'row',
                                width: '90%',
                                marginTop: 20,
                                alignSelf: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}>
                                <View style={{ backgroundColor: AppStyle.mainColor, height: 1, flex: 5 }} />
                                <View style={{ margin: 15 }}>
                                    <SimpleText style={{ color: AppStyle.mainColor, alignSelf: "center", textAlign: "center", paddingLeft: 10, paddingRight: 10, ...TextStyle.weight.bold, ...TextStyle.size.large, }}>Perfil del Productor</SimpleText>
                                </View>
                                <View style={{ backgroundColor: AppStyle.mainColor, height: 1, flex: 5 }} />
                            </View>

                            <View style={[styles.viewPack, { flexWrap: 'wrap-reverse', }]}>


                                <CardInput
                                    style={styles.cardInput}
                                    title={'$profile.form.name'}
                                    icon={Icons.TAG}
                                    onChangeText={_onValueChange('name')}
                                    keyboardType={keyboardType.default}
                                    autoCompleteType={undefined}
                                    inputText={producerForm.name}
                                    placeholder={'$profile.form.namePlaceholder'}
                                    errorText={errorForm.name}
                                    infoText={'$profile.form.nameDesc'}
                                    returnKeyType={'next'}
                                    showHelp={true}
                                />
                                <View style={{ maxWidth: 350, alignSelf: 'center', flex: 1, minWidth: 200 }}>
                                    <ImageUploader
                                        width={120}
                                        height={120}
                                        form={producerForm}
                                        title={"$profile.form.profileImage"}
                                        error={() => typeof errorForm?.images?.profile != 'undefined' ? <SimpleText style={styles.errorText}>{errorForm.images.profile}</SimpleText> : null}
                                        maxImages={1}
                                        type={"circle"}
                                        withButton
                                        imageDelete={_imageDelete('profile')}
                                        imageUploaded={_imageUploaded('profile')}
                                        imageToUpload={_placeHolderImageUpdate('profile')}
                                        images={typeof profileImage == 'undefined' || profileImage == null ? [] : [profileImage]} />
                                </View>


                            </View>

                            <View style={styles.viewPack}>
                                <CardInput
                                    style={[styles.cardInput, { alignSelf: 'flex-start' }]}
                                    title={'$profile.form.street'}
                                    icon={Icons.TAG}
                                    cardStyle={{ marginBottom: 0 }}
                                    onChangeText={_onValueChange('street')}
                                    keyboardType={keyboardType.default}
                                    autoCompleteType={autoCompleteType.street}
                                    textContentType={textContentType.fullStreetAddress}
                                    inputText={producerForm.location?.street}
                                    placeholder={'$profile.form.streetPlaceHolder'}
                                    errorText={errorForm.location && errorForm.location?.street}
                                    returnKeyType={'next'}
                                />
                                <LocationInput
                                    style={styles.cardInput}
                                    value={producerForm.location}
                                    onError={(errors) => {
                                        setErrorForm({ ...errorForm, location: { ...errors } })
                                    }}
                                    errors={errorForm.location}
                                    onValueChange={_onValueChange('location')}
                                />
                            </View>
                            <MultipleLanguage
                                style={styles.individualViewPack}
                                renderItem={_renderLocalesContent}
                                defaultLocales={Object.keys(producerForm.translations).filter(val => {
                                    return val !== '__typename' && producerForm.translations[val] != null
                                })}
                                updateLocale={_addOrRemoveLocale}
                            />

                            {false && typeof region !== 'undefined' &&
                                region.latitude !== null && region.longitude !== null &&
                                <Maps.MapView
                                    style={styles.map}
                                    region={region}
                                    mapType={"hybrid"}
                                    provider={null}
                                    zoomEnabled={false}
                                    zoomTapEnabled={false}
                                    zoomControlEnabled={false}
                                    rotateEnabled={false}
                                    scrollEnabled={false}
                                    pitchEnabled={false}
                                    toolbarEnabled={false}
                                    onPress={_onMapPress}>
                                    {(typeof producerForm?.location?.coordinates?.[1] !== 'undefined' &&
                                        typeof producerForm?.location?.coordinates?.[0] !== 'undefined' &&
                                        <Maps.Marker
                                            coordinate={{
                                                latitude: producerForm.location.coordinates[1],
                                                longitude: producerForm.location.coordinates[0]
                                            }}>
                                        </Maps.Marker>)}
                                </Maps.MapView>}
                            {false && <SimpleText style={styles.mapHelp}>$profile.form.mapHelp</SimpleText>}


                            <View style={styles.individualViewPack}>


                                <View style={{
                                    flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center',
                                    marginBottom: 5, marginTop: 10, maxWidth: 350, alignSelf: 'center'
                                }}>
                                    <SimpleText style={{ marginLeft: 20 }}>{'$profile.form.coverImage'}</SimpleText>
                                </View>
                                <ImageUploader
                                    style={[styles.imagesView, { maxWidth: 350 }]}
                                    maxImages={1}
                                    form={producerForm}
                                    withButton
                                    width={270}
                                    imageDelete={_imageDelete('cover')}
                                    imageUploaded={_imageUploaded('cover')}
                                    imageToUpload={_placeHolderImageUpdate('cover')}
                                    images={typeof coverImage == 'undefined' || coverImage == null ? [] : [coverImage]} />

                                {typeof errorForm?.images?.cover != 'undefined' && <SimpleText style={styles.errorText}>{errorForm.images.cover}</SimpleText>}
                            </View>

                            <View style={styles.individualViewPack}>


                                <View style={{
                                    flexDirection: 'row', maxWidth: 800, width: '100%',
                                    justifyContent: 'space-between', alignItems: 'center', alignContent: 'center',
                                    marginBottom: 5, marginTop: 20, alignSelf: 'center'
                                }}>
                                    <SimpleText style={{ marginLeft: 20 }}>{'$profile.form.galery'}</SimpleText>
                                    <ButtonText
                                        style={styles.addButton}
                                        textStyle={styles.addButtonText}
                                        type={'secondary'}
                                        iconSize={20}
                                        onPress={() => imageUploaderRef.current.pickImage()}
                                        text={'$button.add'}>
                                    </ButtonText>
                                </View>
                                <ImageUploader
                                    style={styles.imagesView}
                                    ref={imageUploaderRef}
                                    form={producerForm}
                                    imageDelete={_imageDelete('list')}
                                    imageUploaded={_imageUploaded('list')}
                                    imageToUpload={_placeHolderImageUpdate('list')}
                                    images={displayedImages} />
                                <SimpleText style={styles.errorText}>{_getUniqueErrors(errorForm?.images?.list).join('\n')}</SimpleText>
                            </View>


                        </View>
                    </KeyboardAwareScrollView>}

                <FeedbackModal
                    style={{ alignSelf: 'center' }}
                    error={formStatus.error}
                    onSuccess={() => setFormStatus(initialStatus)}
                    errorText={formStatus.errorText}
                    onTimeout={() => {
                        setFormStatus(initialStatus)
                    }}
                    onFeedbackPress={() => setFormStatus(initialStatus)}
                    success={formStatus.success}
                />


            </ScrollView >
            {Object.keys(producerForm.placeholders.list).length == 0 &&
                Object.keys(producerForm.placeholders.profile).length == 0 &&
                Object.keys(producerForm.placeholders.cover).length == 0 &&

                <FloatingButton
                    loading={formStatus.loading}
                    text={'$button.save'}
                    onPress={_onSubmit}
                />
            }
        </>);
}

const updateStyles = (windowWidth, windowHeight) => StyleSheet.create({
    view: {
        backgroundColor: AppStyle.backgroundColor
    },
    scroll: {
        alignSelf: 'center',
        width: '100%',
        paddingLeft: 0,
        paddingTop: 50,
        paddingBottom: 100,
        backgroundColor: AppStyle.backgroundColor,
    },
    addButton: {
        marginRight: 20,
        alignSelf: 'flex-end',
    },
    addButtonText: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
    },
    addProfile: {
        position: 'absolute',
        bottom: 10,
        alignSelf: 'center',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: AppStyle.backgroundColor
    },
    addCover: {
        position: 'absolute',
        right: 10,
        bottom: 10,
        alignSelf: 'flex-end',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: AppStyle.backgroundColor
    },
    singleImagesView: {
        minHeight: 200,
        maxHeight: 200,
        backgroundColor: AppStyle.cardBackground,
        borderRadius: 15,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        paddingRight: 5
    },
    cardInput: {
        width: '100%',
        flex: 1,
        maxWidth: 350,
        minWidth: 200,
        margin: 10,

    },
    imagesView: {
        borderRadius: 15,
        flex: 1, minHeight: 100,
        width: '100%', maxWidth: 800,
        alignSelf: 'center',
        alignItems: 'flex-start', alignContent: 'flex-start',
    },
    imageWithBadgeContainer: {
        flexDirection: 'row',
        marginTop: 10
    },
    badgeContainer: {
        top: -4,
        left: -10,
        zIndex: 1,
        width: 25,
        height: 25,
        borderRadius: 20,
        elevation: 2,
        alignSelf: 'flex-end',
        justifyContent: 'center',
        position: 'absolute',
    },
    badgeButton: {
        width: 25,
        height: 25,
        paddingRight: 0,
        borderRadius: 20,
        shadowOffset: {
            width: 5,
            height: 5
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        justifyContent: 'center',
        backgroundColor: AppStyle.errorColor
    },
    cover: {
        width: '100%',
        height: windowHeight * 0.25,
    },
    profile: {
        width: 130,
        maxHeight: 130,
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 20
    },
    map: {
        width: windowWidth * 0.8,
        height: 250,
        alignSelf: 'center',
        borderRadius: 20,
        marginTop: 20,
    },
    mapHelp: {
        color: AppStyle.getColor('secondary', 1),
        ...TextStyle.size.medium,
        alignSelf: 'center',
        textAlign: 'left',
        marginTop: 5,
        marginBottom: 20
    },
    marker: {
        width: 50,
        height: 50,
        marginBottom: 70,
        borderColor: AppStyle.getColor('main', 1),
        borderWidth: 3,
        borderRadius: 25
    },
    pin: {
        borderLeftWidth: 4,
        borderLeftColor: AppStyle.getColor('main', 1),
        height: 23,
        bottom: 50,
        alignSelf: 'center',
        borderRadius: 2
    },
    titleText: {
        marginTop: 10,
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.padding.medium,
        paddingLeft: 0,
    },
    descriptionText: {
        paddingTop: 20,
        width: '90%',
        alignSelf: 'center',
    },
    mapModal: {
        width: '100%',
        height: '80%',
        alignSelf: 'center',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 50,
    },
    mapModalContentContainerStyle: {
        width: '100%',
        height: '100%',
        backgroundColor: AppStyle.backgroundColor,
        borderRadius: 20,
    },
    mapModalInnerContent: {
    },
    modalMap: {
        borderRadius: 19,
        width: '100%',
        height: '100%',
    },
    viewPack: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: 800,
        width: '100%',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginBottom: 10,
    },
    individualViewPack: {
        maxWidth: 800,
        width: '100%',
        marginBottom: 10,
        alignSelf: 'center',
    }
});

export default FormScreen
import React, { useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Icon } from '@quarks'
import { useQuery } from '@apollo/client';

import { ImageCard, QuantitySteper, PriceUnit, Card } from '@atoms';
import { CustomizableCardGrid, ViewCard } from '@molecules'
import { AppStyle, TextStyle } from '@style';
import { SimpleText, ButtonText } from '@quarks';
import { getTranslation, replaceLocale } from '@utilities/locale'
import { Icons } from '@icons'
import { getProp } from 'nexto-utils/objects'
import { order } from 'nexto-codes'
import { IMAGE_SIZES } from '@utilities/images'
import { getCategory } from '@utilities/category'
import { getOptimizedZone, getUpCommingDelivery } from '@utilities/delivery'

export function ProducerCard(props) {

    const itemSize = {
        height: props.height ? props.height : 170,
        width: props.width
    }
    const styles = updateStyles(itemSize.width)
    const producer = props.producer
    const basketContent = props.basket
    const categories = props.categories

    const _onProducerPress = (producer) => {
        if (props.onProducerPress) {
            props.onProducerPress(producer)
        }
    }
    const _onProductPress = (product) => {
        if (props.onProductPress) {
            props.onProductPress(product)
        }
    }

    const _onItemAdd = typeof props.onProductAdd != 'undefined' ? props.onProductAdd : () => () => { }
    const isDisabled = props.isDisabled === true;

    let optimizedZone = getOptimizedZone(props.zones, producer?.delivery?.zones)

    const _renderProducerProps = (item) => {
        if (!item.delivery) {
            return
        }

        let zone = optimizedZone?.zone

        if (typeof zone === 'undefined') return

        return (<View style={[styles.optionsContainer]}>
            <View style={styles.optionsItem}>
                <Icon
                    name={Icons.CLOCK}
                    size={15}
                    color={AppStyle}
                    style={styles.iconStyle} />
                <SimpleText style={[styles.optionsText]}>{getUpCommingDelivery(zone.modality)}</SimpleText>
            </View>

            {typeof zone?.availableFrom != 'undefined' &&
                zone?.availableFrom != null && zone.availableFrom > 0 &&
                <View style={styles.optionsItem}>
                    <Icon
                        name={Icons.BASKET}
                        size={15}
                        color={'black'}
                        style={styles.iconStyle} />
                    <SimpleText style={styles.optionsHeader}>{'$common.components.producerCard.minimum'}</SimpleText>
                    <SimpleText style={styles.optionsText}>{zone.availableFrom + '€'}</SimpleText>
                </View>}

            {typeof zone?.freeFrom != 'undefined' &&
                zone?.freeFrom != null &&
                <View style={styles.optionsItem}>
                    <Icon
                        name={Icons.DELIVERY}
                        size={15}
                        color={'black'}
                        style={styles.iconStyle} />
                    {zone.freeFrom == 0 && <SimpleText style={styles.optionsHeaderEmpty}>{'$common.components.producerCard.freeFrom'}</SimpleText>}
                    {zone.freeFrom > 0 && <SimpleText style={styles.optionsHeader}>{'$common.components.producerCard.freeFrom'}</SimpleText>}
                    {zone.freeFrom > 0 && <SimpleText style={styles.optionsText}>+{zone.freeFrom + ' €'}</SimpleText>}
                </View>}
        </View>

        );
    }

    const _renderProduct = ({ item, index }) => {

        if (item.product == null || typeof item.product == 'undefined' ||
            item.total == null || typeof item.total == 'undefined') {
            return null
        }
        const { product, total } = item

        if (product?._id == null || typeof product?._id == 'undefined') {
            return null
        }

        let unitsInBasket = 0
        if (typeof basketContent.producers[producer._id] != 'undefined'
            && typeof basketContent.producers[producer._id].products[product._id] != 'undefined')
            unitsInBasket = basketContent.producers[producer._id].products[product._id].units
        let lastItemStyle = index == total - 1 ? styles.lastProductStyle : {}

        return (
            <View
                key={product._id}
                style={[styles.itemContainer, lastItemStyle]}>
                <View style={{ height: 75, width: 75, alignSelf: 'center' }}>
                    <ImageCard
                        image={typeof product.images?.stared === 'undefined' || product.images.stared == null ?
                            product.images?.list[0] : product.images?.stared}
                        imgType={IMAGE_SIZES.COVER}
                        onPress={() => _onProductPress(product)}
                    />
                </View>

                <View style={{ alignSelf: 'center', paddingLeft: 10 }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, width: styles.itemContainer.width - 80 }}>
                        {getTranslation(product)?.name}
                    </SimpleText>
                    <PriceUnit product={product} inLine></PriceUnit>
                    {!isDisabled && <QuantitySteper
                        quantity={unitsInBasket}
                        baseSize={100}
                        width={150}
                        height={35}
                        overCard={true}
                        iconSize={15}
                        unit={product.amount}
                        style={{ marginTop: 5, marginLeft: 10, alignSelf: 'flex-start', }}
                        onItemAdd={_onItemAdd(product)}
                    />}
                </View>
            </View>
        )

    }

    const _renderProducerProducts = (producer) => {

        const products = producer.products

        let data = []
        for (let prod of products)
            data.push({ product: prod, total: products.length })

        let seeMore = { more: false, numMore: 0 }

        if (producer.products.length < producer.productsIds.length) {
            seeMore.more = true
            seeMore.numMore = producer.productsIds.length - producer.products.length
        }

        return <View style={{ marginTop: -15 }}>
            <View style={[styles.optionsContainer, { flexDirection: 'column' }]}>

                <CustomizableCardGrid
                    style={[{ width: '100%' }]}
                    cards={data}
                    contentContainerStyle={{ padding: 0 }}
                    renderItem={_renderProduct}
                    listType='list'
                    itemDimension={240}
                    spacing={5}
                />
            </View>
            {producer.productsIds?.length > 0 && <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5, paddingRight: 20, paddingLeft: 20 }}>
                {producer.productsIds?.length > 3 && <SimpleText>{'$resultsNum ' + producer.productsIds.length}</SimpleText>}
                {seeMore.more &&
                    <ButtonText text={'$button.seeMore'} onPress={() => _onProducerPress(producer)}></ButtonText>
                }
            </View>}
        </View>
    }

    let subtitle = props.subtitle;
    if (props.subTitleField) {
        if (Array.isArray(props.subTitleField)) {
            if (props.subTitleField.length > 1) {
                subtitle = getProp({ ...producer }, props.subTitleField[0].split('.'))
                if (subtitle) {
                    for (let index = 1; index < props.subTitleField.length; index++) {
                        let value = getProp({ ...producer }, props.subTitleField[index].split('.'))
                        if (value) {
                            subtitle += (', ' + value)
                        }
                    }
                }
            } else if (props.subTitleField.length === 1) {
                if (props.subTitleField[0] === 'distance') {
                    subtitle = `A ${(producer[props.subTitleField[0]].toFixed(0) / 1000).toFixed(1)} Km`
                } else {
                    subtitle = getProp({ ...producer }, props.subTitleField[0].split('.'))
                }
            }
        } else {
            subtitle = getProp({ ...producer }, props.subTitleField.split('.'))
        }
    }

    const _renderLabelItem = ({ item, index }) => {

        const { name } = getCategory(item.categoryId, categories)


        if (typeof name === 'undefined')
            return

        return <Card noShadow style={[{
            height: 25, marginRight: 5,
            maxWidth: '100%', maxHeight: 40,
            borderRadius: 20, paddingLeft: 10, paddingRight: 10, borderWidth: 0.5
        }]} >
            <SimpleText style={[{
                alignSelf: 'center', color: AppStyle.textColor, textAlignVertical: 'center',
                ...TextStyle.size.xmini, padding: 0
            }]}>{name}</SimpleText>
        </Card>
    }

    let subheader;
    if (producer?.categories?.length > 0 && categories) {
        subheader = (<CustomizableCardGrid
            style={{ maxWidth: '100%', width: itemSize.width, height: 45, marginTop: -10, paddingLeft: 10 }}
            cards={producer.categories}
            renderItem={_renderLabelItem}
            contentContainerStyle={{ alignItems: 'flex-start' }}
            listType='list'
            horizontal
            spacing={0}
            itemDimension={30}
        />)
    }

    return <View style={[{
        width: '100%', maxWidth: 400
    }, props.style]}>
        {isDisabled &&
            <View style={{
                marginTop: -10, maxWidth: '100%', width: itemSize.width, padding: 5, paddingLeft: 10, paddingRight: 10, borderTopRightRadius: 10,
                borderTopLeftRadius: 10, paddingBottom: 15, alignSelf: 'center', backgroundColor: AppStyle.warningLightColor, opacity: 0.8
            }}>
                {((typeof producer?.delivery?.pickups == 'undefined' || producer?.delivery?.pickups == null) || producer?.delivery?.pickups.length == 0) && <SimpleText style={{ ...TextStyle.weight.bold }}>$common.components.producerCard.doesNotShipToYourLocation</SimpleText>}
                {typeof producer?.delivery?.pickups !== 'undefined' && producer?.delivery?.pickups !== null && producer?.delivery?.pickups.length != 0 && <SimpleText style={{ ...TextStyle.weight.bold }}>$common.components.producerCard.onlyPickup</SimpleText>}
            </View>
        }
        <View style={[{
            width: '100%', maxWidth: 400, backgroundColor: 'transparent', marginTop: -10
        }]}>
            <ViewCard
                viewStyle={[{
                    height: itemSize.height, zIndex: 0,
                    width: itemSize.width, maxWidth: '100%',
                    alignSelf: 'center', marginBottom: isDisabled && producer?.products?.length > 0 ? 10 : 0
                }]}
                title={producer?.name}
                subtitle={subtitle}
                image={producer.images?.cover}
                onPress={() => _onProducerPress(producer)}
                description={producer.description}
            />
            <View style={{ position: 'absolute', maxWidth: '100%', top: 0, width: itemSize.width, padding: 5, paddingLeft: 0, paddingRight: 0, borderTopRightRadius: 10, borderTopLeftRadius: 10, alignSelf: 'center' }}>
                {subheader}
            </View>
            {false && _renderProducerProps(producer)}
            {typeof producer.products != 'undefined' && producer.products != null && producer.products.length > 0 && _renderProducerProducts(producer)}
        </View>
    </View>
}

const updateStyles = (width) => StyleSheet.create({

    optionsContainer: {
        flexDirection: 'row',
        maxWidth: '90%',
        paddingLeft: 5,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        zIndex: -1,
    },
    optionsItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
    },
    iconStyle: {
        color: AppStyle.getColor('mainAccent', 3),
        ...TextStyle.weight.bold,
        marginRight: 5
    },
    optionsText: {
        color: AppStyle.textColor,
        ...TextStyle.size.small,
        ...TextStyle.weight.bold,
        ...TextStyle.align.center,
    },
    optionsHeader: {
        color: AppStyle.textColor,
        ...TextStyle.size.small,
        ...TextStyle.weight.bold,
        ...TextStyle.align.center,
        marginRight: 5,
    },
    optionsHeaderEmpty: {
        color: AppStyle.textColor,
        ...TextStyle.size.small,
        ...TextStyle.weight.bold,
        ...TextStyle.align.center,
    },
    itemContainer: {
        borderRadius: 10,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 0,
        width: width * 0.9,
        alignSelf: 'center',
        flexDirection: 'row',
        borderBottomWidth: 0.5,
        paddingBottom: 10,
        paddingTop: 10,
        borderBottomColor: AppStyle.backgroundColorInverted
    },
    lastProductStyle: {
        borderBottomWidth: 0,
        paddingBottom: 0,

    }
});

import { Platform } from 'react-native'


export function normalize(size) {
    return size;
}

const TextStyle = {
    size: {
        mini: {
            fontSize: Platform.OS === 'android' ? normalize(12) : normalize(13),
        },
        xmini: {
            fontSize: normalize(13),
        },
        small: {
            fontSize: normalize(15),
        },
        medium: {
            fontSize: normalize(17),
        },
        regular: {
            fontSize: normalize(18),
        },
        large: {
            fontSize: normalize(20),
        },
        xlarge: {
            fontSize: normalize(24),
        },
        xxlarge: {
            fontSize: normalize(28),
        },
        xxxlarge: {
            fontSize: normalize(34),
        },
        superLarge: {
            fontSize: normalize(54),
        }
    },
    padding: {

        mini: {
            padding: 1,
        },
        small: {
            padding: 2,
        },
        medium: {
            padding: 3,
        },
        regular: {
            padding: 4,
        },
        large: {
            padding: 5,
        },
        xlarge: {
            padding: 8,
        }
    },
    weight: {
        bold: {
            fontFamily: 'Font-Bold'
        },
        light: {
            fontFamily: 'Font-Light'
        },
        medium: {
            fontFamily: 'Font-Medium'
        },
        regular: {
            fontFamily: 'Font-Regular'
        },
        semibold: {
            fontFamily: 'Font-SemiBold'
        }
    },
    /*
    weight: {
        light: {
            fontFamily: 'FuturaLtd-Light'
        },
        lightItalic: {
            fontFamily: 'FuturaLtd-Light-Italic'
        },
        regularItalic: {
            fontFamily: 'FuturaLtd-Medium-Italic'
        },
        regular: {
            fontFamily: 'FuturaLtd-Medium'
        },
        mediumItalic: {
            fontFamily: 'FuturaLtd-Book-Italic'
        },
        medium: {
            fontFamily: 'FuturaLtd-Book'
        },
        semiboldItalic: {
            fontFamily: 'FuturaLtd-Bold-Italic'
        },
        semibold: {
            fontFamily: 'FuturaLtd-Bold'
        },
        boldItalic: {
            fontFamily: 'FuturaLtd-Heavy-Italic'
        },
        bold: {
            fontFamily: 'FuturaLtd-Heavy'
        }
    },

    titleWeight: {
        light: {
            fontFamily: 'OptimaLtd'
        },
        regular: {
            fontFamily: 'OptimaLtd-Medium'
        },
        medium: {
            fontFamily: 'OptimaLtd-Demi'
        },
        semibold: {
            fontFamily: 'OptimaLtd-DemiBold'
        },
        bold: {
            fontFamily: 'OptimaLtd-Bold'
        },
        black: {
            fontFamily: 'OptimaLtd-Black'
        },
        xblack: {
            fontFamily: 'OptimaLtd-XBlack'
        },

        lightItalic: {
            fontFamily: 'OptimaLtd-Italic'
        },
        regularItalic: {
            fontFamily: 'OptimaLtd-Medium-Italic'
        },
        mediumItalic: {
            fontFamily: 'OptimaLtd-Demi-Italic'
        },
        semiboldItalic: {
            fontFamily: 'OptimaLtd-DemiBold-Italic'
        },
        boldItalic: {
            fontFamily: 'OptimaLtd-Bold-Italic'
        },
        blackItalic: {
            fontFamily: 'OptimaLtd-Black-Italic'
        },
        xblackItalic: {
            fontFamily: 'OptimaLtd-XBlack-Italic'
        }

    },*/
    align: {
        justify: {
            textAlign: 'justify'
        },
        left: {
            textAlign: 'left'
        },
        right: {
            textAlign: 'right'
        },
        center: {
            textAlign: 'center'
        }
    }
}

export {
    TextStyle
}
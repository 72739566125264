import React from 'react';
import { StyleSheet, ScrollView, View, RefreshControl } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import i18n from 'i18n-js';
import moment from "moment/min/moment-with-locales"
import AsyncStorage from '@react-native-community/async-storage';

import { AppStyle, TextStyle } from "@style";
import { sessionOperations } from '_ducks/session'
import { SUPORTED_LOCALES } from '@utilities/locale'

import { TermsEnglish, TermsSpanish } from './terms/';

export const TermsScreen = (props) => {

    const styles = updateStyles()
    const dispatch = useDispatch()

    // In order to force a view update
    useSelector(state => state.session.language)

    const locale = props.route.params?.locale
    React.useEffect(() => {
        if (SUPORTED_LOCALES.includes(locale) || locale == 'en') {
            i18n.locale = locale
            moment.locale(i18n.locale)
            AsyncStorage.setItem('@nadius:locale', locale)
            dispatch(sessionOperations.updateLanguage(locale))
        }
    }, [locale])

    return (<ScrollView
        style={{
            height: '100%',
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }} contentContainerStyle={{ justifyContent: 'center', flexGrow: 1 }}>
        <View style={styles.mainView}>
            {i18n.locale == 'es' || i18n.locale == 'ca' ? <TermsSpanish /> : <TermsEnglish />}
        </View>
    </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({

    mainView: {
        paddingBottom: 50,
        paddingTop: 50,
        width: '90%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center'
    },
    welcomeTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    secondaryTitle: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    emailText: {
        ...TextStyle.size.large,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        marginBottom: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    secondaryText: {
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 10,
    },
    exitText: {
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 50,
    }
});
import React, { useState } from 'react';
import {
    Animated,
    StyleSheet,
    View,
    LayoutAnimation,
    Image
} from 'react-native';
import { useSelector } from 'react-redux'

import { getTranslation } from '@utilities/locale'
import { TextStyle, AppStyle } from '@style'
import { ButtonText,SimpleText } from '@quarks'
import { CATEGORIES } from '@images'


export function FilterBanner(props) {
    

    const styles = updateStyles(props.bottomMargin, props.topMargin)
    const filters = useSelector(state => state.session.filters)

    let selected = filters !== null ? filters.filter(item => item.state ? item: undefined) : []
    const [lastNumber, setLastNumber] = useState(0)
    const [gone, setGone] = useState(false)
    const [appeared, setAppeared] = useState(false)
    let scaleAnim  = new Animated.Value(0);

    if (lastNumber != selected.length) {
        setLastNumber(selected.length)
    } else if (lastNumber == 1 && selected.length == 1 && !appeared) {
        scaleAnim = new Animated.Value(0)
        Animated.timing(scaleAnim, {
            toValue: 1,
            duration: 250,
            useNativeDriver: false,
        }).start(({ finished }) => {
            if (finished)
                setAppeared(true)
                setGone(false)
          });

    } else if (lastNumber == 0 && selected.length == 0 && !gone) {
        scaleAnim = new Animated.Value(1)

        Animated.timing(scaleAnim, {
            toValue: 0,
            duration: 250,
            useNativeDriver: false,
        }).start(({ finished }) => {
            if (finished)
                setGone(true)
                setAppeared(false)
          });

    }


    return (
        <Animated.View style={styles.floatingView}>
            
            <View style={[{flex: 1, height:'100%', justifyContent:'center',}]}>
                <ButtonText 
                    style={styles.filterButton} 
                    type={'solid'}
                    icon={'filter'}
                    onPress={props.onFiltersPress}
                    text={'Filtros'}>
                </ButtonText>
                
                <Animated.View style={[styles.badgeContainer, { transform: [{ scale: scaleAnim }]}]}>
                    <SimpleText style={styles.badge}>
                        {selected.length === 0? 1: selected.length}
                    </SimpleText>
                </Animated.View>
                

            </View>
        </Animated.View>
    );
}

const updateStyles = (bottomMargin, topMargin) => StyleSheet.create({
    floatingView: {
        position: 'absolute',
        bottom: 0,
        width:'100%',
        zIndex: 10,
        opacity:1,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 10,
        flexDirection:'row'
    },
    filterButton : { 
        height: 40, 
        paddingLeft:15,
        paddingRight:15,
        borderRadius: 50, 
        marginBottom: 10,
        marginRight:20,
        alignSelf:'flex-end'
    },
    badgeContainer: {
        top: -10,
        backgroundColor: AppStyle.errorColor,
        right: 10,
        zIndex: 1,
        width: 30,
        height: 30,
        borderRadius: 20,
        shadowOffset: {
            width: 5,
            height: 5
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        alignSelf: 'flex-end',
        justifyContent: 'center',
        position: 'absolute'
    },
    badge: {
        ...TextStyle.size.regular,
        ...TextStyle.weight.bold,
        textAlign: 'center',
        color:AppStyle.getColor('light',1)
    },
});
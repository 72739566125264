import React, { useState } from 'react';
import { View, Button, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as moment from 'moment';

import { Icons } from '@icons';
import { AppStyle } from '@style';
import { ButtonText, SimpleText } from '@quarks';
import { CardInput } from '@molecules';
import { BottomModal } from './bottomModal';

export function RangeHourPicker(props) {

    const [show, setShow] = useState(undefined);
    const [form, setForm] = useState({
        start: props.range?.start ? props.range.start : new Date(moment().set({ hour: 9, minutes: 0 })),
        end: props.range?.end ? props.range.end : new Date(moment().set({ hour: 18, minutes: 0 }))
    });

    const _onValueChange = (value) => {
        if (props.onValueChange) props.onValueChange(value)
        setForm(value)
    };

    let isiOS14 = Platform.OS == 'ios' && parseInt(Platform.Version, 10) > 13
    let isNotiOS14 = Platform.OS == 'ios' && parseInt(Platform.Version, 10) < 14

    const _onChange = (show) => (event, selectedDate) => {
        if (!isNotiOS14) {
            setShow(undefined);
        }

        if (typeof selectedDate === 'undefined') return

        const currentDate = selectedDate;
        let value = { ...form }
        if (show === 'start' && currentDate > value.end) {
            value.start = value.end
            value.end = currentDate
        } else if (show === 'end' && currentDate < value.start) {
            value.end = value.start
            value.start = currentDate
        } else {
            value[show] = currentDate
        }
        _onValueChange(value)
    };

    return (<View style={[{
        alignSelf: 'center',
        width: '100%',
    }, props.style]}>
        <CardInput
            title={'Rang horari'}
            icon={Icons.CALENDAR}
            returnKeyType={'next'}
            noInput={true}
            renderCustomInput={() => <View style={{
                flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5
            }}>
                {
                    (Platform.OS != 'ios' || isNotiOS14) && typeof form.start !== 'undefined' && typeof form.end !== 'undefined' &&
                    form.start != null && form.end != null &&
                    <View style={{ width: '80%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <ButtonText type={'outline'} style={{ height: 35, paddingLeft: 15, paddingRight: 15 }}
                            text={moment(form.start).format('H:mm')} onPress={() => setShow('start')}>
                        </ButtonText>
                        <SimpleText style={{ alignSelf: 'center' }}> - </SimpleText>
                        <ButtonText type={'outline'} style={{ height: 35, paddingLeft: 15, paddingRight: 15 }}
                            text={moment(form.end).format('H:mm')} onPress={() => setShow('end')}>
                        </ButtonText>
                    </View>
                }
                {
                    isiOS14 && typeof form.start !== 'undefined' && typeof form.end !== 'undefined' &&
                    form.start != null && form.end != null &&
                    <View style={{ flexDirection: 'row', width: '80%' }}>
                        <DateTimePicker
                            style={{ flex: 1, minWidth: 100 }}
                            testID="dateTimePicker"
                            value={new Date(form.start)}
                            is24Hour={true}
                            minuteInterval={5}
                            textColor={AppStyle.mainColor}
                            mode={'time'}
                            display="compact"
                            onChange={_onChange('start')}
                        />
                        <SimpleText style={{ paddingTop: 3 }}> - </SimpleText>
                        <DateTimePicker
                            style={{ marginLeft: 10, flex: 1, minWidth: 100 }}
                            testID="dateTimePicker"
                            value={new Date(form.end)}
                            is24Hour={true}
                            minuteInterval={5}
                            textColor={AppStyle.mainColor}
                            mode={'time'}
                            display="compact"
                            onChange={_onChange('end')}
                        />
                    </View>
                }
            </View>}
            errorText={props.errorText}
        />
        {isNotiOS14 && <BottomModal
            onRequestClose={() => {
                setShow(undefined)
            }}
            visible={typeof show !== 'undefined'}>
            {
                typeof form.start !== 'undefined' && typeof form.end !== 'undefined' &&
                form.start != null && form.end != null &&
                <DateTimePicker
                    style={{ width: '100%' }}
                    testID="dateTimePicker"
                    value={new Date(form[show])}
                    is24Hour={true}
                    minuteInterval={5}
                    textColor={AppStyle.textColor}
                    mode={'time'}
                    display="compact"
                    onChange={_onChange(show)}
                />
            }
        </BottomModal>}
        {Platform.OS != 'ios' && typeof show !== 'undefined' &&
            <DateTimePicker
                testID="dateTimePicker"
                value={new Date(form[show])}
                is24Hour={true}
                mode={'time'}
                display="spinner"
                onChange={_onChange(show)}
            />}
    </View>);
}

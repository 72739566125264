import { Notifications } from 'react-native-notifications';
import AsyncStorage from '@react-native-community/async-storage';
import * as Crypto from 'expo-crypto';
import { AppCenter } from '@platform'
import { Platform } from 'react-native'

// Prevent same session login and logout
let subscriptions = {
    registered: undefined,
    foreGround: undefined,
    opened: undefined
}

const register = async (onForeground, onOpen, app) => {
    if (subscriptions.registered) subscriptions.registered.remove()
    if (subscriptions.foreGround) subscriptions.foreGround.remove()
    if (subscriptions.opened) subscriptions.opened.remove()

    Notifications.registerRemoteNotifications();

    subscriptions.opened = Notifications.events().registerNotificationReceivedForeground((notification, completion) => {
        console.log(`Notification received in foreground: ${JSON.stringify(notification)}`);
        if (onForeground) {
            onForeground(notification)
        }
        completion({ alert: false, sound: false, badge: false });
    });

    subscriptions.foreGround = Notifications.events().registerNotificationOpened((notification, completion) => {
        console.log(`Notification opened: ${JSON.stringify(notification.payload)}`);
        if (onOpen) {
            onOpen(notification)
        }
        completion();
    });

    subscriptions.registered = Notifications.events().registerRemoteNotificationsRegistered(async (event) => {

        let user = global.app.currentUser
        if (!user || user.customData === null) {
            return
        }

        const installId = await AppCenter.getInstallId();

        let args = {
            token: event.deviceToken,
            installId: installId,
            platform: Platform.OS,
            app: app
        }
        const hash = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA256,
            JSON.stringify(args)
        )

        let callFunction = false
        let fromStorage = await AsyncStorage.getItem('@nadius:notifications')
        if (fromStorage !== null) {
            fromStorage = JSON.parse(fromStorage)
            if (fromStorage.hash !== hash) {
                callFunction = true
                args.previous = fromStorage
            }
        }

        if (fromStorage === null || callFunction) {
            console.log('Register device')
            let result = await global.app.currentUser.callFunction('USER_registerDevice', [args]);
            console.log(result)
            if (result && result.status === 0) {
                console.log('Register ok')
                await AsyncStorage.setItem('@nadius:notifications', JSON.stringify({
                    ...result,
                    hash: hash
                }));
                await AsyncStorage.removeItem('@nadius:tmp_notification')
            }
        }
    });

    Notifications.events().registerRemoteNotificationsRegistrationFailed((event) => {
        if (event.code != 3010) {
            console.error(event);
        }
    });

}

const unRegister = async () => {
    if (subscriptions.registered) subscriptions.registered.remove()
    if (subscriptions.foreGround) subscriptions.foreGround.remove()
    if (subscriptions.opened) subscriptions.opened.remove()

    let fromStorage = await AsyncStorage.getItem('@nadius:notifications')

    if (fromStorage !== null) {
        fromStorage = JSON.parse(fromStorage)
        let device = {
            subscriptions: fromStorage.subscriptions,
            endpoint: fromStorage.endpoint,
            installId: await AppCenter.getInstallId()
        }
        await AsyncStorage.removeItem('@nadius:notifications');
        await global.app.currentUser.callFunction('USER_unRegisterDevice', [device]);
    }
}

export {
    register,
    unRegister
}
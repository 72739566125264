import {
    Animated,
    View,
    AppState
} from 'react-native';
import React from 'react';

import { SimpleText, ButtonText } from '@quarks'
import { TextStyle, AppStyle } from '@style'

export function QuantitySteper(props) {

    let _onAdd = (step) => () => {
        if (props.onItemAdd) {
            if (props.exceeded) {
                if (step < 0) {
                    props.onItemAdd(step)
                }
            } else {
                props.onItemAdd(step)
            }
        }
    }
    const overAllWidth = props.width ? props.width : 150
    const overAllHeight = props.height ? props.height : props.style.height ? props.style.height : 40
    const overCard = props.overCard === true
    return (
        <Animated.View style={[{ width: overAllWidth, maxWidth: overAllWidth, height:overAllHeight, maxHeight:overAllHeight }, props.style]}>
            {props.quantity < 1 &&
                <ButtonText 
                    haptic
                    style={[{ width: overAllWidth, height: overAllHeight },overCard?{ backgroundColor: AppStyle.getColor('main', 2)}:{}]}
                    type={overCard ? 'solid': 'outline'}
                    disabled={props.exceeded}
                    color={props.exceeded ? AppStyle.warningColor : undefined}
                    text={props.exceeded ? '$button.noneLeft' : '$button.add'}
                    onPress={_onAdd(1)}
                    removePadding />
            }
            {props.quantity >= 1 &&
                <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', width: overAllWidth, maxWidth: overAllWidth,height:overAllHeight, maxHeight:overAllHeight}}>
                    <ButtonText haptic iconSize={props.iconSize} style={[{ width: 50, flex: 1, height: overAllHeight },overCard?{ backgroundColor: AppStyle.getColor('main', 3)}:{}]} type={overCard ? 'solid': 'outline'} icon={'minus'} onPress={_onAdd(-1)} removePadding></ButtonText>
                    <View style={{ flex: 2, alignItems: 'center' }}>
                        <SimpleText style={{ ...TextStyle.weight.bold }}>{`x ${props.quantity}`}</SimpleText>
                    </View>
                    <ButtonText
                        haptic
                        iconSize={props.iconSize}
                        disabled={props.exceeded}
                        color={props.exceeded ? AppStyle.placeHolderColor : undefined}
                        style={[{ width: 50, flex: 1, height: overAllHeight, borderRadius:20 }]}
                        type={'solid'} icon={'plus'} onPress={_onAdd(1)} >

                    </ButtonText>
                </View>
            }
        </Animated.View>

    );
}


import {
    StyleSheet,
    TouchableOpacity,
    Animated,
    View,
    ActivityIndicator
} from 'react-native';
import React from 'react';
import { Icon } from './icon';
import { ImpactFeedbackStyle, impactAsync } from 'expo-haptics';

import { TextStyle, AppStyle } from '@style'
import { SimpleText } from './simpleText'

export function ButtonText(props) {

    let state = {
        scaleAnim: new Animated.Value(1),
    };

    const _handlePressIn = () => {
        Animated.spring(state.scaleAnim, {
            toValue: 0.98,
            useNativeDriver: false,
        }).start();

    };

    const _handlePressOut = () => {
        Animated.spring(state.scaleAnim, {
            toValue: 1,
            friction: 3,
            tension: 5,
            useNativeDriver: false,
        }).start();
    };

    let hapticFeedback = ImpactFeedbackStyle.Light
    switch (props.haptic) {
        case 'light':
            hapticFeedback = ImpactFeedbackStyle.Light
            break;
        case 'medium':
            hapticFeedback = ImpactFeedbackStyle.Medium
            break;
        case 'high':
            hapticFeedback = ImpactFeedbackStyle.High
            break;
        default:
            break;
    }


    const _handlePress = async () => {
        if (props.disabled) {
            return
        }

        if (props.haptic) {
            impactAsync(ImpactFeedbackStyle.Light)
        }
        if (props.onPress) await props.onPress()
    };

    const animatedStyle = {
        transform: [{ scale: state.scaleAnim }]
    }

    let type = props.type
    if (props.loading) {
        type = 'outline'
    }

    const colorIcon = props.iconColor || props.color || (
        type === 'solid' || type === 'secondary' ?
            AppStyle.getColor('light', 1) :
            AppStyle.getColor('main', 1))

    const styles = {

        buttonStyle: (type) => ({
            borderRadius: typeof props.borderRadius != 'undefined' ? props.borderRadius : 50,
            alignContent: 'center',
            alignItems: 'center',
            maxWidth: 450,
            opacity: 1,
            alignSelf: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            padding: 0,
            borderWidth: type === 'outline' ? 1 : 0,
            borderColor: props.color ? props.color : AppStyle.getColor('main', 1),
            backgroundColor: type === 'solid' ?( props.disabled ? AppStyle.getColor('dark', 4) : (props.color ? props.color : AppStyle.getColor('main', 1)))
                 :
                type === 'secondary' ? AppStyle.getColor('main', 2) : (props.loading ? AppStyle.backgroundColor : 'transparent')
        }
        ),

        textStyle: (type) => ({
            textAlign: 'center',
            opacity: props.disabled && (type !== 'solid' && type !== 'secondary') ? 0.6 : 1,
            ...TextStyle.weight.bold,
            ...TextStyle.size.medium,
            color: props.color ? props.color :
                (type === 'solid' || type === 'secondary' ? AppStyle.getColor('light', 1) :
                    AppStyle.getColor('main', 1))
        }
        ),

    };

    let icon
    if (props.icon) {
        icon = (<Icon
            name={props.icon}
            iconSet={props.iconSet}
            size={props.iconSize || 20}
            color={colorIcon}
            style={[{
                alignSelf: 'center',
                paddingRight: typeof props.text != 'undefined' && typeof props.iconRight === 'undefined' ? 10 : 0
            }, props.iconStyle]}
        />)
    }

    return (
        <Animated.View
            style={animatedStyle}>
            <TouchableOpacity
                disabled={(props.disabled || props.loading)}
                activeOpacity={0.9}
                onPressIn={_handlePressIn}
                onPressOut={_handlePressOut}
                onPress={_handlePress}
                style={[styles.buttonStyle(type), props.style]}>
                {!props.loading && <View style={{ flexDirection: 'row' }}>
                    {(props.iconLeft || (typeof props.iconLeft === 'undefined' && typeof props.iconRight === 'undefined')) &&
                        icon
                    }
                    {props.text &&
                        <SimpleText
                            ellipsizeMode={props.ellipsizeMode}
                            numberOfLines={props.numberOfLines}
                            style={[styles.textStyle(type), props.textStyle]}>
                            {props.text}
                        </SimpleText>}
                    {(props.iconRight) &&
                        icon
                    }
                </View>}
                {props.loading && <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                    <ActivityIndicator size="small" style={{ paddingRight: 10 }} color={AppStyle.mainColor} />
                    <SimpleText
                        ellipsizeMode={props.ellipsizeMode}
                        numberOfLines={props.numberOfLines}
                        style={[styles.textStyle(type), props.textStyle]}>
                        {props.text}
                    </SimpleText>
                </View>}
            </TouchableOpacity>
        </Animated.View>

    );
}

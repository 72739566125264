const es = {
    errors: {
        unknownReduced: "Hemos detectado un problema inesperado.",
        unknownRetry: "Vuélvelo a intentar de nuevo y si persiste, contacta con nosotros.",
        unknown: "Hemos detectado un problema inesperado.\n\nVuélvelo a intentar de nuevo y si persiste, contacta con nosotros.",
    }

}

const ca = {
    errors: {
        unknownReduced: "Hem detectat un problema inesperat.",
        unknownRetry: "Prova'ho de nou i si persisteix, posa't en contacte amb nosaltres.",
        unknown: "Hem detectat un problema inesperat. \n\nProva'ho de nou i si persisteix, posa't en contacte amb nosaltres.",
    }
}

export {
    es,
    ca
}
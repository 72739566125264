
const es = {
    phoneCardInput: {
        title: "Teléfono"
    },
    loadingView: {
        message: "Cargando"
    },
    errorView: {
        title: "Error",
        networkErrorText: "No hay conexión a internet"
    },
    priceUnit: {
        unit: "ud.",
        units: "uds.",
        of: "de",
        pack: "pack"
    },
    categoryCard: {
        seeProducersAtCategory: "Ver productores en:",
        category: "Categoria"
    },
    noResultsView: {
        noResults: "No hemos encontrado resultados",
        noResultsFor: "No hemos encontrado resultados para",
    },
    basketCard: {
        recogida: "Recogida",
        enablePickup: "Recogeré los productos",
        disablePickup: "Cancelar recogida",
        areYouSure: "Confirma que quieres eliminar este producto",
        deleteFromBasket: "Se eliminará definitivamente de tu cesta",
        cancel: "Cancelar",
        delete: "Eliminar",
        youNeed: "Te quedan",
        toMinOrder: "€ para llegar al pedido minimo establecido por",
        subTotal: "Subtotal",
        delivery: "Envío",
        total: "Total",
        free: "Gratuito",
        freeDeliveryForFirst: "Por",
        freeDeliveryForSecond: "más, el envío es gratuito!",
        noMoreUnits: "No hay más unidades disponibles",
        done: "Hecho",
        edit: "Modificar",
        seeProduct: "Ver"
    },
    streetInput: {
        street: "Nombre de la calle y número",
        flat: "Piso, Puerta, Escalera, ...",
    },
    locationInput: {
        postalCodePlaceHolder: "Ej. 25737",
        postalCode: "Código postal",
        postalCodeDesc: "Código Postal de la ubicación",
        postalCodeError: "Este código postal no existe",
        city: "Ciudad, Región",
        downloadingLocations: "Descargando localizaciones...",
        selectLocation: "Selecciona una ubicación"
    },
    producerCard: {
        withoutMin: 'Sin mín.',
        minimum: 'Mín.',
        time: "Envio en",
        doesNotShipToYourLocation: "No envia a tu ubicación",
        onlyPickup: "Solo recogida",
        freeFrom: "Gratuíto",
    },
    dateCardInput: {
        year: 'Año',
        month: 'Mes',
        day: 'Día',
        select: 'Selecciona'
    },
    profileHeader: {
        and: "y",
        days: "Envia los",
        time: "Envio en",
        upcomingDelivery: "Próximo envío",
        deliveryDays: "Días de envío",
        viewProfile: "Ver perfil",
        delivery: "Envio",
        freeDelivery: "Envio gratuito",
        from: "desde",
        minimum: "Cesta mínima",
        free: "Gratuito",
        allWeek: "Envía los días laborables",
        allDays: "Envía todos los días",
        pickupEnabled: "Recogida disponible",
        deliveryConditions: "Condiciones de envío",
        seeMore: "Ver más"
    },
    buttons: {
        done: "Hecho",
        cancel: "Cancelar",
        close: "Cerrar",
        ok: "OK"
    },
    fileInput: {
        stored: "Guardado",
        save: "Guardar",
        addAnother: "Añadir otro documento",
        select: "Selecciona un fichero",
        cancel: "No",
        confirm: "Si",
        duplicate: "Documento duplicado",
        confirmSelect: "Seleccionar igualmente?",
        invalidType: "El formato del fichero no es válido, tiene que ser "
    },
    productCardGrid: {
        noProductMessage: "No hay ningún producto"
    },
    feedbackModal: {
        timeout: "Lo sentimos, la operación está tardando demasiado..."
    },
    feedbackView: {
        loadingTimeout: "Esta tardando mas de lo normal...",
        errorTimeout: "La operación ha tardado demasiado, vuelvelo a intentar más tarde."
    },
    cardInput: {
        readOnly: "No se puede modificar este campo",
        optionalField: "Opcional"
    },
    buttonText: {
        loading: "Cargando"
    },
    imageUploader: {
        selectButton: "Añadir",
        imagesHint: "Pulse el boton \"Añadir\" para añadir imagenes",
        retryUpload: "Reintentar",
        uploadingImage: "Enviando imagen",
        dropHint: "Déjala aquí"
    },
    weekDayPicker: {
        setupTimeRange: "Definir horario",
    },
    customizableCardGrid: {
        endReached: "Has llegado al final",
    },
    toolbarDropdown: {
        hello: "Hola {name}"
    }
}

const ca = {
    phoneCardInput: {
        title: "Telèfon"
    },
    loadingView: {
        message: "Carregant"
    },
    errorView: {
        title: "Error",
        networkErrorText: "No hi ha connexió a internet"
    },
    priceUnit: {
        unit: "u.",
        units: "us.",
        of: "de",
        pack: "pack"
    },
    categoryCard: {
        seeProducersAtCategory: "Veure productors en:",
        category: "Categoria"
    },
    noResultsView: {
        noresults: "No hem trobat resultats",
        noResultsFor: "No hem trobat resultats per a",
    },
    basketCard: {
        recogida: "Recollida",
        enablePickup: "Veure opció de recollida",
        disablePickup: "Cancel·lar recollida",
        areYouSure: "Eliminar producte",
        deleteFromBasket: "S'eliminarà definitivament de la teva cistella",
        cancel: "Cancel·lar",
        delete: "Eliminar",
        youNeed: "Et queden",
        toMinOrder: "€ per arribar a la comanda mínim establert per",
        Subtotal: "Subtotal",
        delivery: "Enviament",
        total: "Total",
        free: "Gratuït",
        freeDeliveryForFirst: "Per",
        freeDeliveryForSecond: "més, l'enviament és gratuït!",
        noMoreUnits: "No hi ha més unitats disponibles",
        done: "Fet",
        edit: "Modificar",
        seeProduct: "Veure"
    },
    streetInput: {
        street: "Nom del carrer i número",
        flat: "Pis, Porta, Escala, ...",
        streetPlaceHolder: "Avinguda Madrid, 203",
        flatPlaceHolder: "2, 2A",
    },
    locationInput: {
        postalCodePlaceHolder: "Ex. 25737",
        postalCode: "Codi postal",
        postalCodeDesc: "Codi Postal de la ubicació",
        postalCodeError: "Aquest codi postal no existeix",
        city: "Ciutat, Regió",
        downloadingLocations: "S'està baixant localitzacions ...",
        selectLocation: "Selecciona una ubicació"
    },
    dateCardInput: {
        year: 'Any',
        month: 'Mes',
        day: 'Dia',
        select: 'Selecciona'
    },
    producerCard: {
        withoutMin: 'Sense mín.',
        minimum: 'Mín.',
        time: "Enviament a",
        doesNotShipToYourLocation: "No envia a la teva ubicació",
        onlyPickup: "Només recollida",
        freeFrom: "Gratuït",
    },
    profileHeader: {
        and: "i",
        days: "Envia els",
        time: "Enviament a",
        upcomingDelivery: "Pròxim enviament",
        deliveryDays: "Dies d'enviament",
        viewProfile: "Veure perfil",
        delivery: "Enviament",
        freeDelivery: "Enviament gratuït",
        from: "des de",
        minimum: "Cistella mínima",
        free: "Gratuït",
        allWeek: "Envia els dies laborables",
        allDays: "Envia tots els dies",
        pickupEnabled: "Recollida disponible",
        deliveryConditions: "Condicions d'enviament",
        seeMore: "Veure més"
    },
    buttons: {
        done: "Fet",
        cancel: "Cancel·lar",
        close: "Tancar",
        ok: "D'acord"
    },
    fileInput: {
        stored: "Desat",
        save: "Desar",
        addAnother: "Afegir un altre document",
        select: "Seleccionar un fitxer",
        cancel: "No",
        confirm: "Sí",
        duplicate: "Document duplicat",
        confirmSelect: "Seleccioneu igualment?",
        invalidType: "El format de l'arxiu no és vàlid, ha de ser"
    },
    productCardGrid: {
        noProductMessage: "No hi ha cap producte"
    },
    feedbackModal: {
        timeout: "Malauradament, l'operació està trigant massa ..."
    },
    feedbackView: {
        loadingTimeout: "Està trigant més del normal ...",
        errorTimeout: "L'operació ha trigat massa, torna-ho a intentar més tard."
    },
    cardInput: {
        readOnly: "No es pot modificar aquest camp",
        optionalField: "Opcional"
    },
    buttonText: {
        loading: "Carregant"
    },
    imageUploader: {
        selectButton: "Afegir",
        imagesHint: "Premi el botó \"Afegir\" per afegir imatges",
        retryUpload: "Reintentar",
        uploadingImage: "Enviant imatge",
        dropHint: "Deixa-la anar aquí"
    },
    weekDayPicker: {
        setupTimeRange: "Definir horari",
    },
    customizableCardGrid: {
        endReached: "Has arribat al final",
    },
    toolbarDropdown: {
        hello: "Hola {name}"
    }
}

export {
    es,
    ca
}
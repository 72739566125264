import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image, View } from 'react-native';

import { SimpleText, ButtonText } from '@quarks'
import { sessionOperations } from '_ducks/session'
import { AppStyle, TextStyle } from "@style";
import { useAuth } from '@realm'
import { ILLUSTRATIONS } from '@images'

const UpdateScreen = (props) => {

    const styles = updateStyles()

    return (<ScrollView style={{
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        backgroundColor: AppStyle.backgroundColor
    }} contentContainerStyle={{ justifyContent: 'center', flexGrow: 1 }}>
        <View style={styles.mainView}>
            <SimpleText style={styles.welcomeTitle}>
                $update.mainTitle
            </SimpleText>
            <Image
                style={{ height: 300, width: 250, alignSelf: 'center', marginTop: 10 }}
                source={ILLUSTRATIONS.update}
            />
            <SimpleText style={styles.secondaryText}>
                {props.progress}
            </SimpleText>
        </View>
    </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({

    mainView: {
        paddingBottom: 50,
        paddingTop: 50,
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center'
    },
    welcomeTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    secondaryTitle: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    emailText: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        marginBottom: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    secondaryText: {
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 10,
    },
    exitText: {
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        paddingBottom: 50,
    }
});

export default UpdateScreen;
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    details: product,
    list: [ product ],
}
*/

const reduceGetInfo = (state, action) => {
    if (typeof action.result != 'undefined' && typeof action.result[0] != 'undefined') {
        console.log('reducer producer')
        let newState = {
            name: action.result[0].name,
            profileImage: action.result[0].profileImage,
            coverImage: action.result[0].coverImage,
            slogan: action.result[0].slogan,
            description: action.result[0].description,
            images: action.result[0].images,
        }
        return newState
    }
    return state
}

const dataReducer = createReducer(null)({
    [types.GET_INFO]: reduceGetInfo
});

export default combineReducers(
    {
        data: dataReducer,
    }
);
import * as types from "./types";

export const storeRegisterForm = ( form ) => ( {
    type: types.STORE_REGISTER_FORM,
    form: form
} );

export const waitingVerification = (  ) => ( {
    type: types.WAITING_VERIFICATION
} );

export const storeKycForm = ( form ) => ( {
    type: types.STORE_KYC_FORM,
    form: form
} );

export const pendingKycForm = ( ) => ( {
    type: types.PENDING_KYC_FORM
} );

export const doneKycForm = ( ) => ( {
    type: types.DONE_KYC_FORM
} );

export const resetKycForm = ( ) => ( {
    type: types.RESET_KYC_FORM
} );

export const storeCompanyForm = ( form ) => ( {
    type: types.STORE_COMPANY_FORM,
    form: form
} );

export const storeQuery = ( query, maxTime = 30 ) => ( {
    type: types.STORE_QUERY,
    query: query,
    maxTime: maxTime
} );

export const refetchMainQueries = ( force = false ) => ( {
    type: types.REFETCH_MAIN_QUERIES,
    force: force
} );

export const toggleProductModal = ( show ) => ( {
    type: types.PRODUCT_MODAL,
    show: show
} );

export const toggleAlertModal = ( show, alert ) => ( {
    type: types.ALERT_MODAL,
    show: show,
    alert: alert
} );

export const toggleUserModal = ( show ) => ( {
    type: types.USER_MODAL,
    show: show,
} );

export const updateLanguage = ( language ) => ( {
    type: types.UPDATE_LANGUAGE,
    language: language,
} );
import React from 'react';
import { View, ActivityIndicator, Dimensions } from 'react-native';
import { Icon } from '@quarks'

import { AppStyle, TextStyle } from '@style';
import { SimpleText } from '@quarks';
import { Icons } from '@icons';

export const SuccessView = (props) => {

    const height = Dimensions.get('window').height;

    return (
        <View style={[{ alignSelf: 'center', textAlign: 'center', height: height / 2, justifyContent: 'center' }, props.style]}>
            <Icon
                name={Icons.SUCCESS}
                size={40}
                color={AppStyle.mainColor}
                style={{ textAlign: 'center'}} />
            <SimpleText style={{ paddingTop: 10, ...TextStyle.size.large, textAlign: 'center' }}>{typeof props.text !== 'undefined' ? props.text : '$success'}</SimpleText>
        </View>
    )
};

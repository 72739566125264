import React from 'react';
import { View, RefreshControl, StyleSheet, Dimensions } from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';
import { useQuery } from '@apollo/client';
import moment from "moment/min/moment-with-locales"

import { SimpleText } from '@quarks';
import { Card, ErrorView, LoadingView } from '@atoms';
import { CustomizableCardGrid, ViewCard, HeaderCard } from '@molecules';
import { AppStyle, TextStyle } from '@style';
import { order } from 'nexto-codes'

import { Order } from '@api'
import { replaceLocale } from '@utilities/locale'

const OrderListScreen = (props) => {

    React.useEffect(() => {
        props.navigation.setOptions({
            headerTintColor: AppStyle.textColor,
            headerTitle: replaceLocale('$order.list.headerTitle'),
            headerBackTitle: '$order.list.headerBackTitle'.localise()
        })
    }, [props.navigation])

    const limit = 15

    const [orders, setOrders] = React.useState(undefined)

    const [state, setState] = React.useState({
        moreData: true,
        fetching: false
    })

    const _onCompleted = (data) => {

        let localOrders = typeof data === 'undefined' ? data : data.OrdersProducer
        if (typeof localOrders === 'undefined') return

        let sectionElements = {}
        let total = 0;
        if (typeof props.route.params === 'undefined') {
            localOrders.forEach(element => {
                total += 1
                if (typeof sectionElements[element.status] === 'undefined') {
                    sectionElements[element.status] = {}
                    if (element.status < order.STATUS.CREATED) {
                        sectionElements[element.status].title = '$order.list.disputesTitle'
                    } else if (element.status < order.STATUS.PREPARING) {
                        sectionElements[element.status].title = '$order.list.pendingTitle'
                    } else if (element.status < order.STATUS.TRANSIT) {
                        sectionElements[element.status].title = '$order.list.preparingTitle'
                    } else if (element.status < order.STATUS.DELIVERED) {
                        sectionElements[element.status].title = '$order.list.transitTitle'
                    } else if (element.status === order.STATUS.DELIVERED) {
                        sectionElements[element.status].title = '$order.list.deliveredTitle'
                    } else if (element.status === order.STATUS.CANCELED_PRODUCER) {
                        sectionElements[element.status].title = '$order.list.canceledTitle'
                    }
                    sectionElements[element.status].data = []
                }
                sectionElements[element.status].data.push(element)
            });
            setOrders(Object.values(sectionElements))
        } else {
            setOrders(localOrders)
        }
    }


    const { query, param } = Order.getAllByProducer(0, limit, props.route.params)
    const { loading, error, data, fetchMore, refetch } = useQuery(query, {
        ...param,
        onCompleted: _onCompleted
    });

    const styles = updateStyles(useHeaderHeight())

    const _renderItem = ({ item, index }) => {

        let from;
        if (item.status <= order.STATUS.OTHER_DISPUTE) {
            from = moment(item.dates.dispute)
        } else {
            from = moment(item.dates.created)
        }

        let difference = moment(new Date()).diff(from, 'hours')
        from = from.fromNow()
        const fromCapitalized = from.charAt(0).toUpperCase() + from.slice(1)

        let disputeTitle = undefined;
        let color = AppStyle.mainColor
        switch (item.status) {
            case order.STATUS.CREATED:
                if (difference > 20 && difference < 40) {
                    color = AppStyle.warningColor
                } else if (difference > 40) {
                    color = AppStyle.errorColor
                }
                break;
            case order.STATUS.PREPARING:
                if (difference > 48 && difference < 72) {
                    color = AppStyle.warningColor
                } else if (difference > 72) {
                    color = AppStyle.errorColor
                }
                break;
            case order.STATUS.TRANSIT:
                if (difference > 72 && difference < 96) {
                    color = AppStyle.warningColor
                } else if (difference > 96) {
                    color = AppStyle.errorColor
                }
                break;
            case order.STATUS.DELIVERED:
                break;
            case order.STATUS.DISPUTE:
                switch (item.dispute.code) {
                    case order.DISPUTE_CODES.NOT_ARRIVED:
                        disputeTitle = "$orders.notArrived"
                        break;
                    case order.DISPUTE_CODES.UNWANTED_PRODUCTS:
                        disputeTitle = "$orders.unwantedProducts"
                        break;
                    case order.DISPUTE_CODES.BAD_STATE:
                        disputeTitle = "$orders.badStatus"
                        break;
                    case order.DISPUTE_CODES.OTHER_DISPUTE:
                        disputeTitle = "$orders.other"
                        break;
                }
                break;
        }

        return (<View style={{ height: 160, width: '100%' }}>
            <Card
                style={{ justifyContent: 'center' }}
                onPress={() => {
                    props.navigation.navigate('OrderDetails', item)
                }}>
                <View style={{ width: '100%', justifyContent: 'center' }}>
                    <View style={{ flexDirection: 'row', width: '80%', alignSelf: 'center', justifyContent: 'center' }}>
                        <SimpleText style={{
                            ...TextStyle.weight.bold,
                            ...TextStyle.size.small
                        }}>{item.aggregatedId}</SimpleText>
                    </View>
                    {typeof disputeTitle !== 'undefined' &&
                        <View style={{ alignSelf: 'center', width: '80%', justifyContent: 'space-between' }}>
                            <SimpleText >{disputeTitle}</SimpleText>
                        </View>}
                    {typeof disputeTitle === 'undefined' && <View style={{ alignSelf: 'center', width: '80%', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <SimpleText>{parseFloat(item.total).toFixed(2)}€</SimpleText>
                        </View>
                    </View>}
                    <View style={{ flexDirection: 'row', width: '80%', alignSelf: 'center' }}>
                        {item.pickup == null && item.direction?.location?.place_name && <SimpleText ellipsizeMode={'tail'} numberOfLines={2} style={{ color: AppStyle.getColor('mainAccent', 2) }}>{item.direction.location.place_name}</SimpleText>}
                        {item.pickup != null && <SimpleText ellipsizeMode={'tail'} numberOfLines={2} style={{ color: AppStyle.getColor('secondary', 2) }}>$orders.pickup</SimpleText>}
                    </View>
                </View>
            </Card>
            <SimpleText style={{ alignSelf: 'center', paddingTop: 5, color: color }}>{fromCapitalized}</SimpleText>
        </View>)
    }


    React.useEffect(() => {

        let title;
        switch (props.route.params) {
            case order.STATUS.CREATED:
                title = replaceLocale('$order.list.pending')
                break;
            case order.STATUS.PREPARING:
                title = replaceLocale('$order.list.preparing')
                break;
            case order.STATUS.TRANSIT:
                title = replaceLocale('$order.list.transit')
                break;
            case order.STATUS.DELIVERED:
                title = replaceLocale('$order.list.delivered')
                break;
            case order.STATUS.DISPUTE:
                title = replaceLocale('$order.list.disputes')
                break;
            case order.STATUS.DISPUTE:
                title = replaceLocale('$order.list.canceled')
                break;
            default:
                title = replaceLocale('$order.list.title')
                break;
        }

        props.navigation.setOptions({
            headerTitle: title
        })
    }, [props.navigation])

    const sectionHeader = ({ section: { title, subtitle } }) => {
        return <View style={{
            borderBottomWidth: 1,
            borderBottomColor: AppStyle.getColor('dark', 4),
            width: '90%',
            alignSelf: 'center',
            paddingBottom: 10,
            marginBottom: 10,
            marginTop: 15
        }}>
            <SimpleText style={styles.sectionHeader}>
                {title}
            </SimpleText>
        </View>
    }

    const _refreshData = async () => {
        try {
            await refetch()
        } catch (error) {
            console.log('Unable to perform refetch ', error)
        }
    }

    const _viewMore = () => {

        if (loading || !state.moreData || !orders ||
            (state.moreData && data && data.OrdersProducer.length < limit))
            return

        setState({ ...state, fetching: true })

        fetchMore({
            variables: {
                limit: limit,
                offset: data.OrdersProducer.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {

                if (!fetchMoreResult) {
                    setState({ ...state, moreData: false, fetching: false })
                    return prev
                }

                let mergeArray = [...prev.OrdersProducer, ...fetchMoreResult.OrdersProducer]

                if (fetchMoreResult.OrdersProducer.length < limit) {
                    setState({ ...state, moreData: false, fetching: false })
                } else {
                    setState({ ...state, fetching: false })
                }

                return mergeArray
                return Object.assign({}, {
                    OrdersProducer: mergeArray.filter(function (order, pos) {
                        return mergeArray.findIndex((el => el._id == order._id)) == pos;
                    })
                })
            }
        })
    }

    return (
        <View style={{ height: '100%', width: '100%', backgroundColor: AppStyle.backgroundColor }}>
            {error &&
                <ErrorView error={error} />}
            {(loading && !state.fetching && typeof data === 'undefined' || typeof orders === 'undefined') &&
                <LoadingView />}
            {!(loading && state.fetching) && typeof data !== 'undefined' && typeof orders !== 'undefined'
                && !error && typeof props.route.params === 'undefined' && <CustomizableCardGrid
                    cards={orders}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={_refreshData} />
                    }
                    pagination
                    paginationLoading={state.fetching}
                    paginationPress={_viewMore}
                    paginationMoreData={state.moreData}
                    renderSectionHeader={sectionHeader}
                    itemDimension={Dimensions.get('window').width / 3.5}
                    style={{ width: '100%', height: '100%' }}
                    contentContainerStyle={{paddingTop: 5, marginTop: -10}}
                    emptyContentText={'$order.list.emptyOrders'}
                    renderItem={_renderItem}
                    listType='section' />}
            {!loading && typeof orders !== 'undefined' && typeof data !== 'undefined' && !error && typeof props.route.params !== 'undefined' &&
                <CustomizableCardGrid
                    cards={orders}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={_refreshData} />
                    }
                    contentContainerStyle={{paddingTop: 5, marginTop: -10}}
                    itemDimension={Dimensions.get('window').width / 3.5}
                    loading={loading}
                    emptyContentText={'$order.list.emptyOrders'}
                    renderItem={_renderItem}
                    listType='list' />}
        </View>
    )
};

const updateStyles = () => StyleSheet.create({
    sectionHeader: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
    }
});

export default OrderListScreen
import {
    StyleSheet,
    Animated,
    View
} from 'react-native';
import React from 'react';

import { TextStyle, AppStyle } from '@style'
import { SimpleText } from '@quarks'
import { getTranslation, replaceLocale } from '@utilities/locale'

export function PriceUnitString(product) {

    if (typeof product === 'undefined') {
        return ''
    }

    let priceUnit
    let itemWeight
    switch (product.priceUnit.toLowerCase()) {
        case 'u':
            priceUnit = '$common.components.priceUnit.unit'.localise()
            itemWeight = `${product.amount} ${product.amountUnit}`
            break;
        case 'pkg':
            priceUnit = '$common.components.priceUnit.pack'.localise()
            itemWeight = `${product.packUnits} ${'$common.components.priceUnit.units $common.components.priceUnit.of'.localise()} ${product.amount} ${product.amountUnit}`
            break;
    }
    return { priceUnit: priceUnit, itemUnit: itemWeight}
}

export function PriceUnit(props) {

    let styles = updateStyles()

    const product = props.product

    if (typeof product === 'undefined') {
        return null
    }

    let priceUnit
    let itemWeight
    switch (product.priceUnit?.toLowerCase()) {
        case 'u':
            priceUnit = replaceLocale('$common.components.priceUnit.unit')
            itemWeight = <SimpleText style={styles.itemWeight}>
                {product.amount} {product.amountUnit}
            </SimpleText>

            break;
        case 'pkg':
            priceUnit = replaceLocale('$common.components.priceUnit.pack')
            itemWeight = <SimpleText style={{ color: AppStyle.textColorWithIntensity(3) }}>
                {product.packUnits} {replaceLocale('$common.components.priceUnit.units $common.components.priceUnit.of')} {product.amount} {product.amountUnit}
            </SimpleText>
            break;
    }

    return (<View style={[props.inLine == true ? { flexDirection: 'row-reverse', alignSelf: 'flex-start' } : { flexDirection: 'column', alignItems: 'flex-start' }, props.style]}>
        <SimpleText
            style={[styles.priceUnitText, props.unitStyle]} ellipsizeMode={'tail'} numberOfLines={1}>{props.inLine === true ? '    ' : ''}{itemWeight}
        </SimpleText>
        <SimpleText ellipsizeMode={'tail'} numberOfLines={1} >
            <SimpleText style={[styles.priceText, props.priceStyle]} ellipsizeMode={'tail'} numberOfLines={1}>{product.price} €</SimpleText>
            <SimpleText style={[styles.unitText]} ellipsizeMode={'tail'} numberOfLines={1}>/{priceUnit}</SimpleText>
        </SimpleText>
    </View>

    );


}

let updateStyles = () => {
    return StyleSheet.create({
        priceText: {
            ...TextStyle.size.medium,
            ...TextStyle.weight.bold,
            color: AppStyle.textColor,
            paddingRight: 0,
            paddingBottom: 0

        },
        priceUnitText: {
            ...TextStyle.size.small,
            ...TextStyle.weight.medium,
            color: AppStyle.textColor,
            paddingLeft: 0
        },
        unitText: {
            ...TextStyle.size.medium,
            color: AppStyle.textColorWithIntensity(2),
            paddingRight: 0,
            paddingBottom: 0,
        },
        itemWeight: {
            ...TextStyle.size.small,
            ...TextStyle.weight.medium,
            color: AppStyle.textColorWithIntensity(2),
            paddingLeft: 0
        }

    });
}
import {
    StyleSheet,
    Button,
} from 'react-native';
import React from 'react';
import {TouchableHighlight, Image} from 'react-native'

export function ButtonIcon(props) {
    return (
        <TouchableHighlight onPress={props.onPress} style={props.style}>
            <Image
                source={props.picture}
            />
        </TouchableHighlight>
    );
}

const styles = StyleSheet.create({

});

export const APP_ICON = require('./app/icon.png')

export const MAIN_BACKGROUND = require('./app/background/main.png')

export const ICONS = require('./app/icons/index.js')


export const PRODUCER_WELCOME= {
    'process-1': require('./app/process-1.svg'),
    'process-2': require('./app/process-2.svg'),
    'process-3': require('./app/process-3.svg'),
    'process-4': require('./app/process-4.svg'),
}

import React from 'react';
import { Card, LoadingView, ErrorView } from '@atoms'
import { StyleSheet,Platform, View } from 'react-native';
import moment from "moment/min/moment-with-locales"
import i18n from 'i18n-js';

import { order } from 'nexto-codes'
import { AppStyle, TextStyle } from '@style';
import { getTranslation } from '@utilities/locale'
import { openMapsPickupLocation } from '@utilities/delivery'
import { ButtonText, SimpleText } from '@quarks'
import { HeaderCard } from './headerCard';
import { CardInput, keyboardType } from './cardInput';
import { Direction } from './direction';
import { Icons } from '@icons';
import { PriceUnitString } from '@atoms'
import { Icon } from '@quarks'

const styles = StyleSheet.create({
    main: {
        backgroundColor: AppStyle.backgroundColor
    },
    itemViewTitle: {
        width: '100%', alignSelf: 'center', flexDirection: 'row', justifyContent: 'center',
        paddingBottom: 5, marginBottom: 5
    },
    itemTitleColumns: {
        ...TextStyle.size.regular,
        ...TextStyle.weight.bold,
        flex: 1,
    },
    activeStatus: {
        ...TextStyle.weight.bold,
        ...TextStyle.size.regular,
    },
    statusItem: {
        ...TextStyle.size.regular,
        flex: 1,
        alignSelf: 'flex-start',
    },
    timeActive: {
        ...TextStyle.weight.bold,
        color: AppStyle.mainColor
    },
    time: {
        paddingLeft: 10
    },
    itemUnits: {
        flex: 1,
        textAlign: 'center'
    },
    itemName: {
        ...TextStyle.size.regular,
        flex: 3,
        paddingLeft: 10
    },
    itemPrice: {
        flex: 1,
        alignSelf: 'flex-end',
        ...TextStyle.size.regular,
        textAlign: 'center'
    },
    headerTitle: {
        textAlign: 'center',
        ...TextStyle.padding.regular,
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        ...TextStyle.padding.xlarge
    },
    contentTitlesView: {
        alignSelf: 'center',
        width: '90%',
        marginBottom: 10,
    },
    contentTitles: {
        ...TextStyle.size.xlarge,
    },
    orderId: {
        ...TextStyle.weight.bold,
        ...TextStyle.size.mini,
        paddingBottom: 10
    },
    aggregatedId: {
        ...TextStyle.weight.bold,
        ...TextStyle.size.mini,
        paddingBottom: 5
    },
    insideTitle: {
        ...TextStyle.size.large,
    },
    listCard: {
        marginTop: 10,
        flexDirection: 'column',
        marginBottom: 20,
        padding: 5,
        borderRadius: 20,
        width: '90%'
    },
    creditCard: {
        minHeight: 80,
        width: '90%',
        marginBottom: 20
    },
    direction: {
        minHeight: 80,
        width: '90%',
        marginBottom: 20
    },
    separator: {
        width: '100%', borderBottomWidth: 1, borderBottomColor: AppStyle.backgroundColorInverted,
        marginTop: 10, marginBottom: 10
    },
});

const baseCard = (loading, error) => (title, children) => {

    return (<View style={{maxWidth: 600, minWidth: '75%',alignSelf: 'center'}}>
        <View style={styles.contentTitlesView}>
            {typeof title == 'string' && <SimpleText style={styles.contentTitles}>{title.localise()}</SimpleText>}
            {typeof title !== 'string' && title}
        </View>
        <Card style={{ maxWidth: '90%', minWidth: '90%', flex: 1, marginBottom: 20, borderRadius: 20 }}>
            <View style={styles.listCard}>
                {loading && !error && <LoadingView style={{ height: 100 }} />}
                {!loading && error && <ErrorView error={error} />}
                {!loading && !error && children}
            </View>
        </Card>
    </View>)

}

const trackingCard = (loading, error) => (tracking) => {

    if (typeof tracking == 'undefined' || tracking == null) return

    let timeTitle;
    if (tracking.external !== null) {
        switch (tracking.external.sendingTime) {
            case 0:
                timeTitle = '$order.details.oneDay'
                break;
            case 1:
                timeTitle = '$order.details.oneTwoDays'
                break;
            case 2:
                timeTitle = '$order.details.threeToFour'
                break;
            case 3:
                timeTitle = '$order.details.oneWeek'
                break;
            case 4:
                timeTitle = '$order.details.moreThanOneWeek'
                break;
        }
    }

    let calendarLocale = {
        'ca': {
            lastDay: '[Ahir (]DD/MM/YYYY[)]',
            sameDay: '[Avui (]DD/MM/YYYY[)]',
            nextDay: '[Demà (]DD/MM/YYYY[)]',
            lastWeek: 'dddd [passat (]DD/MM/YYYY[)]',
            nextWeek: 'dddd [que ve (]DD/MM/YYYY[)]',
            sameElse: 'DD/MM/YYYY'
        },
        'es': {
            lastDay: '[Ayer (]DD/MM/YYYY[)]',
            sameDay: '[Hoy (]DD/MM/YYYY[)]',
            nextDay: '[Mañana (]DD/MM/YYYY[)]',
            lastWeek: 'dddd [pasado (]DD/MM/YYYY[)]',
            nextWeek: 'dddd [que viene (]DD/MM/YYYY[)]',
            sameElse: 'DD/MM/YYYY'
        }
    }

    return baseCard(loading, error)('$order.details.sendingData', <View>
        <View style={{ justifyContent: 'space-between' }}>
            <SimpleText style={{ ...TextStyle.weight.bold }}>$order.details.method </SimpleText>
            {tracking.personal === null &&
                <SimpleText style={{ maxWidth: '50%' }}>{tracking.external.company}</SimpleText>}
            {tracking.external === null &&
                <SimpleText >$order.details.personally</SimpleText>}
        </View>
        {tracking.personal === null && <View style={{ paddingTop: 10, justifyContent: 'space-between' }}>
            <SimpleText style={{ ...TextStyle.weight.bold }}>$order.details.sendingTime </SimpleText>
            <SimpleText>{timeTitle}</SimpleText>
        </View>}
        {tracking.personal === null && tracking.external?.trackingId != null &&
            <View style={{ paddingTop: 10, justifyContent: 'space-between' }}>
                <SimpleText style={{ ...TextStyle.weight.bold }}>$order.details.idTracking </SimpleText>
                <SimpleText selectable={true} style={{ maxWidth: '50%' }}>{tracking.external?.trackingId}</SimpleText>
            </View>}
        {tracking.external === null && tracking.personal.date != null &&
            <View>
                <SimpleText style={{ ...TextStyle.weight.bold, paddingTop: 10 }}>$order.details.deliveryDay</SimpleText>
                <View style={{ flexDirection: 'row' }}>
                    <SimpleText selectable={true} >{moment(tracking.personal.date).utc().utcOffset(-60).calendar(null, calendarLocale[i18n.locale])}</SimpleText>
                    {tracking.external === null && tracking.personal.timeRange != null && <SimpleText selectable={true} > de {moment(tracking.personal.timeRange.start).utc().utcOffset(60).format('HH:mm')} - {moment(tracking.personal.timeRange.end).utc().utcOffset(60).format('HH:mm')}h</SimpleText>}
                </View>
            </View>}
        {tracking.external === null && tracking.personal.message != null &&
            <View>
                <SimpleText style={{ ...TextStyle.weight.bold, paddingTop: 10 }}>$order.details.ownMessage</SimpleText>
                <SimpleText selectable={true} >{tracking.personal.message}</SimpleText>
            </View>}
        {tracking.external === null && tracking.personal.phone != null &&
            <View>
                <SimpleText style={{ ...TextStyle.weight.bold, paddingTop: 10 }}>$order.details.contactPhone</SimpleText>
                <SimpleText selectable={true} >{tracking.personal.phone.countryCode} {tracking.personal.phone.number}</SimpleText>
            </View>}
    </View>)
}

const cancelCard = (loading, error, status) => (cancel) => {

    if (typeof cancel == 'undefined' || cancel == null) return
    if (status !== order.STATUS.CANCELED) return

    let cancelTitle;
    switch (cancel.code) {
        case order.CANCEL_CODES.TOOK_TOO_MUCH:
            cancelTitle = "$order.details.cancelTookTooMuch"
            break;
        case order.CANCEL_CODES.MISSTAKE:
            cancelTitle = "$order.details.cancelMisstake"
            break;
        case order.CANCEL_CODES.WRONG_ITEMS:
            cancelTitle = "$order.details.cancelWrongItems"
            break;
        case order.CANCEL_CODES.OTHER:
            cancelTitle = "$order.details.cancelOther"
            break;
    }

    return baseCard(loading, error)('$order.details.cancelTitle',
        <View>
            <View>
                <SimpleText style={{ ...TextStyle.weight.bold }}>$order.details.cancelReason </SimpleText>
                <SimpleText style={{}}>{cancelTitle}</SimpleText>
            </View>
            {cancel.message !== null && typeof cancel.message !== 'undefined' &&
                <View >
                    <SimpleText style={{ ...TextStyle.weight.bold, paddingTop: 10 }}>$order.details.disputeMessage</SimpleText>
                    <SimpleText>{cancel.message}</SimpleText>
                </View>}
        </View>)
}

const disputeCard = (loading, error, status) => (dispute) => {

    if (typeof dispute == 'undefined' || dispute == null) return

    let disputeTitle;
    switch (dispute.code) {
        case order.DISPUTE_CODES.NOT_ARRIVED:
            disputeTitle = "$order.details.notArrived"
            break;
        case order.DISPUTE_CODES.UNWANTED_PRODUCTS:
            disputeTitle = "$order.details.notCorresponding"
            break;
        case order.DISPUTE_CODES.BAD_STATE:
            disputeTitle = "$order.details.badStatus"
            break;
        case order.DISPUTE_CODES.OTHER_DISPUTE:
            disputeTitle = "$order.details.other"
            break;
    }

    return baseCard(loading, error)(<View style={{ flexDirection: 'row' }}>
        <SimpleText style={styles.contentTitles}>$order.details.disputeTitle -</SimpleText>
        <SimpleText style={{
            paddingLeft: 10, color: status === order.STATUS.DISPUTE ? AppStyle.errorColor : AppStyle.mainColor,
            ...TextStyle.size.large,
            textAlignVertical: 'bottom'
        }}>
            {status === order.STATUS.DISPUTE ? '$order.details.disputeSolvedPending' : '$order.details.disputeSolvedStatus'}
        </SimpleText>
    </View>, <View>
        <SimpleText style={{ ...TextStyle.weight.bold }}>$order.details.typeDispute: </SimpleText>
        <SimpleText style={{}}>{disputeTitle}</SimpleText>
        <SimpleText style={{ ...TextStyle.weight.bold, paddingTop: 10 }}>$order.details.disputeMessage</SimpleText>
        <SimpleText>{dispute.message}</SimpleText>
    </View>)
}

const summaryCard = (loading, error) => (products, delivery, pickup, total) => {

    const _renderItem = ({ item, index }) => {
        let translation = getTranslation(item)

        return (<View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10 }}>
            <SimpleText style={styles.itemUnits}>
                {item.units}x<SimpleText style={{
                    ...TextStyle.size.mini
                }}>{item.price}€</SimpleText>
            </SimpleText>
            <View style={styles.itemName}>
                <SimpleText >
                    {translation?.name}
                </SimpleText>
                <SimpleText style={{
                    color: AppStyle.textColorLight,
                    ...TextStyle.size.small
                }}>
                    {PriceUnitString(item)?.itemUnit}
                </SimpleText>
            </View>
            <View style={{ flex: 1, alignSelf: 'flex-start' }}>
                <SimpleText style={styles.itemPrice}>
                    {parseFloat(item.price * item.units).toFixed(2)}
                </SimpleText>
            </View>
        </View>)
    }

    return baseCard(loading, error)('$order.details.summaryTitle', <View>
        <View style={styles.itemViewTitle}>
            <SimpleText style={[styles.itemTitleColumns, { alignSelf: 'flex-start' }]}>
                $order.details.units
                </SimpleText>
            <SimpleText style={[styles.itemTitleColumns, { alignSelf: 'center', textAlign: 'center' }]}>
                $order.details.name
                </SimpleText>
            <SimpleText style={[styles.itemTitleColumns, { alignSelf: 'flex-end', textAlign: 'right' }]}>
                $order.details.total (€)
                </SimpleText>
        </View>
        <View style={{ width: '100%', alignSelf: 'center' }}>
            {products && products.map(product => _renderItem(product))}
        </View>
        <View style={styles.separator} />
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View >
                {(typeof pickup == 'undefined' || pickup == null) && <SimpleText >
                    $order.details.sendingPrice
                </SimpleText>}
                {(typeof pickup !== 'undefined' && pickup != null) && <SimpleText >
                    $order.details.pickupPrice
                </SimpleText>}
                <SimpleText >
                    $order.details.totalPrice
                </SimpleText>
            </View>
            <View style={{ marginLeft: 40, alignItems: 'flex-end' }} >
                <SimpleText>
                    {delivery === 0 ? 'Gratuit' : delivery + ' €'}
                </SimpleText>
                <SimpleText >
                    {parseFloat(total + delivery).toFixed(2)} €
                </SimpleText>
            </View>
        </View>
    </View>)
}

const messageCard = (loading, error, status, state, _onValueChange, _orderMessage) =>
    (user, producer, messages) => {

        const _renderMessage = (message, index) => {
            let messageHeader = producer.name
            if (message.user === user._id) {
                messageHeader = '$order.details.yourMessage'.localise()
            }
            let messageTime = moment(message.date).calendar()

            return (
                <View key={index} style={{ borderBottomWidth: messages.length - 1 === index ? 0 : 0.5, paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'row', width: '100%', paddingTop: 10, justifyContent: message.user === user._id ? 'flex-start' : 'flex-end' }}>
                        <SimpleText style={{
                            ...TextStyle.weight.bold,
                        }} selectable={true}>{messageHeader} </SimpleText>
                        <SimpleText style={{
                            ...TextStyle.size.small,
                            ...TextStyle.weight.bold,
                            color: AppStyle.placeHolderColor,
                            alignSelf: 'flex-end'
                        }} selectable={true}>- {messageTime}</SimpleText>
                    </View>
                    <SimpleText style={{
                        textAlign: message.user === user._id ? 'left' : 'right'
                    }} selectable={true}>{message.text}</SimpleText>
                </View>
            )
        }

        let buttonText = '$order.details.sendMessage'
        let renderMessages = []
        if (typeof messages === 'undefined' || messages === null) {
            renderMessages = (<View style={{ justifyContent: 'center', width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                <SimpleText style={{
                    color: AppStyle.placeHolderColor,
                    textAlign: 'center'
                }}>$order.details.noMessages</SimpleText>
            </View>
            )
        } else {
            let index = 0
            messages.forEach(message => {
                renderMessages.push(_renderMessage(message, index))
                index += 1
            })

            buttonText = '$order.details.respond'
            if (messages[messages.length - 1].user === user._id) {
                buttonText = '$order.details.sendAgain'
            }
        }

        if (status === order.STATUS.CANCELED) {
            return baseCard(loading, error)('$order.details.messagesTitle', <View>
                {renderMessages}
            </View>)
        }

        return baseCard(loading, error)('$order.details.messagesTitle', <View>
            {renderMessages}
            <CardInput
                title={'$order.details.message'}
                multiline
                maxLength={300}
                onChangeText={_onValueChange}
                inputText={state?.updateForm.message}
                keyboardType={keyboardType.default}
                placeholder={'Hola...'}
                errorText={state?.errorForm.message}
                infoText={"$order.details.max300"}
                showHelp={true}
                minHeight={120}
                cardStyle={{
                    elevation: Platform.OS === 'android' ? 0 : 10,
                    padding: 5,
                    maxWidth: '100%',
                    backgroundColor: AppStyle.placeHolderColor
                }}
            />
            <ButtonText
                onPress={async () => {
                    await _orderMessage()
                }}
                loading={state?.sendingMessage}
                text={`${buttonText}`}
                disabled={state?.updating || state?.successView || state?.errorView}
                type={(state?.updating || state?.successView || state?.errorView) ? 'outline' : 'solid'}
                style={{ height: 45, width: '90%', marginTop: 10 }}>
            </ButtonText>
            <SimpleText style={{
                color: AppStyle.placeHolderColor,
                textAlign: 'center',
                paddingTop: 5
            }}>$order.details.contactDescription</SimpleText>
        </View>)
    }

const headerCard = (loading, error, status, headerStyle) => (dates, pickup) => {


    if (dates == null || typeof dates === 'undefined') return

    let isPickup = pickup != null && typeof pickup !== 'undefined'

    const renderHeaderLine = (state, date, active = true) => {

        let text = '--';
        if (date != null) {
            text = moment(date).calendar()
        }

        return (<View key={date} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <SimpleText key={1} style={active ? styles.activeStatus : styles.statusItem}>
                {state ? state : '---'}
            </SimpleText>
            <SimpleText key={2} style={[active && { ...styles.activeStatus, color: AppStyle.mainColor, textAlign: 'right' }]}>
                {text}
            </SimpleText>
        </View>
        )
    }

    const generateHeader = (dates, isPickup) => {
        let renderItems = []
        Object.keys(dates).forEach(moment => {
            switch (moment) {
                case 'transit':
                    if (dates.transit !== null || (dates.transit === null && dates.preparing !== null)) {
                        renderItems.push(renderHeaderLine(isPickup ? '$order.details.pickupReady' : '$order.details.transit',
                            dates.transit, status === order.STATUS.TRANSIT))
                    }
                    break;
                case 'preparing':
                    renderItems.push(renderHeaderLine('$order.details.preparing', dates.preparing, status === order.STATUS.PREPARING))
                    break;
                case 'created':
                    renderItems.push(renderHeaderLine('$order.details.created', dates.created, status === order.STATUS.CREATED))
                    break;
                case 'canceled':
                    if (status === order.STATUS.CANCELED || status === order.STATUS.CANCELED_PRODUCER) {
                        renderItems.push(renderHeaderLine('$order.details.cancelled', dates.canceled, true))
                    }
                    break;
                case 'delivered':
                    if (dates.delivered !== null || (dates.delivered === null && dates.transit !== null)) {
                        renderItems.push(renderHeaderLine(isPickup ? '$order.details.pickupDone' : '$order.details.delivered',
                            dates.delivered, status === order.STATUS.DELIVERED))
                    }
                    break;
                default:
                    break;
            }
        });
        if (status === order.STATUS.DISPUTE &&
            typeof dates['dispute'] !== 'undefined' &&
            dates['dispute'] !== null) {
            renderItems.push(renderHeaderLine('$order.details.dispute', dates.dispute, status === order.STATUS.DISPUTE))
        }
        return renderItems
    }

    return (<HeaderCard style={headerStyle} cardStyle={{borderRadius: 20, backgroundColor: AppStyle.backgroundColorWithIntensity(1)}}>
        <View style={styles.listCard}>
            <View style={[styles.itemViewTitle, styles.separator, { paddingBottom: 5, marginBottom: 5 }]}>
                <SimpleText style={[styles.itemTitleColumns, { alignSelf: 'flex-start' }]}>
                    $order.details.state
                        </SimpleText>
                <SimpleText style={[styles.itemTitleColumns, { alignSelf: 'flex-end', textAlign: 'right' }]}>
                    $order.details.update
                        </SimpleText>
            </View>
            <View style={{ width: '100%', alignSelf: 'center' }}>
                {!loading && !error && generateHeader(dates, isPickup)}
                {loading && <LoadingView style={{ height: 100 }} />}
            </View>
        </View>
    </HeaderCard>)
}

const userDataCard = (loading, error) => (direction, pickup) => {

    if (typeof direction == 'undefined' || direction == null) return

    let isPickup = typeof pickup !== 'undefined' && pickup !== null

    let title;
    if (isPickup) {
        title = '$order.details.userData'
    } else {
        title = '$order.details.direction'
    }

    return baseCard(loading, error)(title, <View
        style={{ alignSelf: 'center', marginBottom: -5, width: '100%', justifyContent: 'flex-start' }}>
        <Direction pickup={isPickup} direction={direction} />
    </View>)
}

const producerDataCard = (loading, error) => (contact, producerName) => {

    if (typeof contact == 'undefined' || contact == null) return
    if ((typeof contact.phone == 'undefined' || contact.phone == null) &&
        (typeof contact.email == 'undefined' || contact.email == null)) {
        return
    }

    return baseCard(loading, error)('$order.details.producerContact'.transFormat({ name: producerName }), <View>
        {(typeof contact.phone !== 'undefined' && contact.phone !== null) &&
            <View>
                <SimpleText style={{ ...TextStyle.weight.bold }}>$order.details.phone</SimpleText>
                <SimpleText>{contact.phone.countryCode} {contact.phone.number}</SimpleText>
            </View>}
        {(typeof contact.email !== 'undefined' && contact.email !== null) &&
            <View>
                <SimpleText style={{ ...TextStyle.weight.bold }}>$order.details.email</SimpleText>
                <SimpleText>{contact.email}</SimpleText>
            </View>}
    </View>)
}

const pickupCard = (loading, error) => (pickup) => {

    if (typeof pickup == 'undefined' || pickup == null) return

    const _renderItem = (item) => {
        return (<View key={item.day} style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            <SimpleText>{order.SENDING_SCHEDULE_LABEL[item.day]}</SimpleText>
            <View style={{ marginLeft: 20 }}>
                {item.times?.map((times) => (<View key={times.start} style={{ flexDirection: 'row' }}>
                    <SimpleText>{moment(times.start).format('H:mm')}</SimpleText>
                    <SimpleText style={{ alignSelf: 'center' }}> - </SimpleText>
                    <SimpleText>{moment(times.end).format('H:mm')}</SimpleText>
                </View>))}
            </View>
        </View>)
    }

    let renderData = []
    if (typeof pickup?.days !== 'undefined') {
        for (const key in order.SENDING_SCHEDULE) {
            let day = order.SENDING_SCHEDULE[key]

            let item = pickup.days?.find(obj => obj.day == day)
            if (typeof item !== 'undefined') {
                renderData.push(_renderItem({
                    ...item
                }))
            }
        }
    }

    return baseCard(loading, error)('$order.details.pickupInfo', <View style={{ alignSelf: 'center' }} >
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <Icon
                name={Icons.CLOCK}
                size={20}
                color={AppStyle.textColor}
                style={{ alignSelf: 'center' }}
            />
            <SimpleText style={{ marginLeft: 10, ...TextStyle.weight.bold }}>$order.details.pickupHours de recogida</SimpleText>
        </View>
        <View style={{ width: '90%' }}>
            {renderData}
        </View>
        <View style={{ flexDirection: 'row', marginTop: 10, paddingBottom: 5 }}>
            <Icon
                name={Icons.DIRECTIONS}
                size={20}
                color={AppStyle.textColor}
                style={{ alignSelf: 'center' }}
            />
            <SimpleText style={{ marginLeft: 10, ...TextStyle.weight.bold }}>$order.details.pickupDirection</SimpleText>
            <ButtonText onPress={() => {
                openMapsPickupLocation(pickup?.location?.coordinates[1], pickup?.location?.coordinates[0])
            }} text={'Ver en mapa'} type={'secondary'} style={{ marginLeft: 30, paddingLeft: 15, paddingRight: 15 }} />
        </View>
        {pickup.location && <SimpleText>{pickup.location.street}</SimpleText>}
        {pickup.location && <SimpleText>{pickup.location.place_name} {pickup.location.postal_code}, {pickup.location.admin_name2}</SimpleText>}

    </View>)
}

export const OrderComponents = {
    tracking: trackingCard,
    cancel: cancelCard,
    dispute: disputeCard,
    summary: summaryCard,
    messages: messageCard,
    header: headerCard,
    user: userDataCard,
    producer: producerDataCard,
    pickup: pickupCard
}
import React, { useState } from 'react';

import { View, Alert, StyleSheet, Platform, LayoutAnimation, UIManager } from 'react-native';
import { getDocumentAsync } from 'expo-document-picker'
import * as FileSystem from 'expo-file-system';

import { SimpleText, ButtonText } from '@quarks';
import { Card, ErrorView, LoadingView, SuccessView } from '@atoms';
import { Icon } from '@quarks'
import { AppStyle, TextStyle } from '@style';
import { replaceLocale } from '@utilities/locale'
import { Icons } from '@icons'

let config = LayoutAnimation.create(200, 'linear', 'opacity')

if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }
}

export const autoCompleteType = {
    off: 'off',
    username: 'username',
    password: 'password',
    email: 'email',
    name: 'name',
    tel: 'tel',
    streetAddress: 'street-address',
    postalCode: 'postal-code',
    ccNumber: 'ofccNumberf',
    ccCsc: 'ccCsc',
    ccExp: 'ccExp',
    ccExpMonth: 'ccExpMonth',
    ccExpYear: 'ccExpYear',
}

export const clearButtonMode = {
    on: 'while-editing',
    off: 'never'
}

export function FileInput(props) {

    const [state, setState] = useState({
        loading: false,
        success: typeof props.markAsUploaded !== 'undefined' ? props.markAsUploaded : false
    })
    let [showHelp, setShowHelp] = useState(false)
    let [files, setFiles] = useState(typeof props.files !== 'undefined' ? props.files : [])
    const styles = updateStyles(props.minHeight, props.multiline ? undefined : props.minHeight ? props.minHeight : 50)

    let _showHelp = (state = true) => {
        LayoutAnimation.configureNext(config);
        setShowHelp(state)
    }

    if (!showHelp && props.showHelp) {
        _showHelp()
    }

    if (showHelp && !props.showHelp) {
        _showHelp(false)
    }

    const _onValueChange = (value) => {
        if (props.onValueChange) {
            props.onValueChange(value)
        }
    }

    const getUrlExtension = (url) => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    const iconFromFileType = (file) => {

        if (!file)
            return

        let icon = Icons.FILE
        switch (getUrlExtension(file.name)) {
            case 'jpg':
            case 'png':
            case 'jpeg':
            case 'gif':
                icon = Icons.IMAGE
                break;
            case 'pdf':
                icon = Icons.PDF
                break;
        }

        return (
            <Icon
                name={icon}
                size={30}
                style={styles.iconStyle}
                color={AppStyle.textColor}
            />
        )
    }

    const checkPossibleFiles = () => {
        if (files.length !== 0 && props.possibleFileTypes && props.possibleFileTypes.length > 0) {
            files.forEach(element => {
                if (!props.possibleFileTypes.includes(getUrlExtension(element.name))) {
                    return false
                }
                if (typeof props.limitFileSizeMb !== 'undefined') {
                    if (element.size, props.limitFileSizeMb * 1000000) {
                        return false
                    }
                }
            });
        }
        return true
    }

    const _onSave = async () => {
        if (props.onSave) {
            setState({ ...state, loading: true, error: undefined })
            let result = await props.onSave()
            if (result.status) {
                setState({
                    ...state, loading: false,
                    success: true,
                    error: undefined
                })
            } else {
                setState({ ...state, loading: false, error: result.message })
            }
        }
    }

    const selectDocument = async () => {
        let document = await getDocumentAsync({type: 'image/*,application/pdf'})
        if (document.type === 'success') {
            let index = files.findIndex((value) => document.name === value.name && document.size === value.size)
            if (index != -1) {
                Alert.alert(
                    '$common.components.fileInput.duplicate'.localise(),
                    '$common.components.fileInput.confirmSelect'.localise(),
                    [
                        {
                            text: '$common.components.fileInput.confirm'.localise(),
                            onPress: () => {
                                if (checkPossibleFiles()) {
                                    setFiles([...files, document])
                                    _onValueChange([...files, document])
                                }
                            }
                        },
                        {
                            text: '$common.components.fileInput.cancel'.localise(),
                            style: 'cancel',
                        },
                    ],
                    { cancelable: true },
                );
            } else {
                if (checkPossibleFiles()) {
                    setFiles([...files, document])
                    _onValueChange([...files, document])
                }
            }
        }
    }

    let errorText;
    if (files.length !== 0 && !checkPossibleFiles()) {
        errorText = ""
        if (props.possibleFileTypes.length < 2) {
            errorText = props.possibleFileTypes[0]
        } else {
            for (let index = 0; index < props.possibleFileTypes.length; index++) {
                const element = props.possibleFileTypes[index];
                if (index === props.possibleFileTypes.length - 1) {
                    errorText += ` o .${element}`
                } else {
                    if (index === props.possibleFileTypes.length - 2) {
                        errorText += `.${element}`
                    } else {
                        errorText += `.${element}, `
                    }
                }
            }
        }
        errorText = `${replaceLocale('$common.components.fileInput.invalidType')} ${errorText}`
    }

    const onRemoveFile = (file) => async () => {
        if (state.success) setState({ ...state, success: false })
        let newFiles = files.filter(element => element.uri !== file.uri)
        _onValueChange(newFiles)
        setFiles(newFiles)
        if (Platform.OS == 'native') {
            try {
                // Usually fails when trying to delete an already deleted file...
                await FileSystem.deleteAsync(file.uri)
            } catch (error) {
                console.log(error)
            }
        }
    }

    let renderFiles = []
    if (files.length > 0) {
        files.forEach((document, index) => {
            renderFiles.push(<View
                key={index}
                style={{
                    flexDirection: 'row', padding: 10, borderWidth: 0.5,
                    margin: 10, borderRadius: 20, maxWidth: '85%',
                    justifyContent: 'center', alignSelf: 'center'
                }}>
                {iconFromFileType(document)}
                <SimpleText numberOfLines={2}
                    ellipsizeMode={'tail'}
                    style={styles.textInput}>{document.name}</SimpleText>
                <ButtonText
                    icon={Icons.CLOSE}
                    iconSize={20}
                    style={{ width: 20 }}
                    onPress={onRemoveFile(document)}
                    color={AppStyle.textColor}
                />
            </View>)
        });
    }

    return (
        <View style={[styles.main, props.style]}>
            <SimpleText style={styles.titleText}>{props.title}</SimpleText>
            <Card
                style={[styles.card, props.cardStyle]}
            >
                <View style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
                    {renderFiles}
                    {(props.markAsUploaded || state.success) &&
                        <SuccessView style={{ height: 100 }} text={'$common.components.fileInput.stored'} />}
                    {typeof state.error !== 'undefined' &&
                        <ErrorView errorText={state.error} />}
                    {state.loading && <LoadingView style={{ height: 100 }} />}
                    {!state.loading && !(props.markAsUploaded || state.success) &&
                        files.length > 0 &&
                        <ButtonText onPress={_onSave}
                            type={'secondary'}
                            disabled={state.loading}
                            style={{
                                paddingLeft: 30,
                                paddingRight: 30
                            }}
                            text={'$common.components.fileInput.save'} />}
                    {!state.loading && !(props.markAsUploaded || state.success) &&
                        !(props.single && files.length > 0) &&
                        <ButtonText onPress={selectDocument}
                            type={files.length > 0 ? 'clean' : 'secondary'}
                            textStyle={{
                                paddingLeft: 15,
                                paddingRight: 15
                            }}
                            text={files.length > 0 ? '$common.components.fileInput.addAnother' : '$common.components.fileInput.select'} />}
                </View>
            </Card>
            {showHelp && !errorText && typeof props.infoText != 'undefined' && props.infoText.length > 0 && <SimpleText style={styles.infoText}>{props.infoText}</SimpleText>}
            {(typeof props.errorText != 'undefined' || errorText) &&
                <SimpleText style={styles.errorText}>{props.errorText ? props.errorText : errorText}</SimpleText>}
        </View>

    )
}

const updateStyles = (minHeight, maxHeight) => StyleSheet.create({
    main: {
        backgroundColor: 'transparent',
    },
    innerCardView: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        width: '100%',
    },
    card: {
        backgroundColor: AppStyle.cardBackground,
        shadowRadius: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
        maxWidth: '100%',
        paddingTop: 20,
        paddingBottom: 20,
        maxWidth: 350,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 0
    },
    iconStyle: {
        width: 50,
        textAlign: 'center',
    },
    textInput: {
        ...TextStyle.weight.medium,
        ...TextStyle.size.medium,
        width: '75%',
        maxWidth: '80%',
        paddingRight: 20,
    },
    titleText: {
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.padding.medium,
        paddingLeft: 0,

    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    infoText: {
        color: AppStyle.getColor('mainAccent', 1),
        ...TextStyle.size.medium,
        width: '100%',
        maxWidth: 320,
        alignSelf: 'center',
        textAlign: 'left',
    }
}
);
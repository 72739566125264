import React, { useState } from 'react';

import { View, Keyboard, StyleSheet, ActivityIndicator, LayoutAnimation, UIManager, Platform } from 'react-native';
import { Icons } from '@icons'

import { SimpleText } from '@quarks';
import { AppStyle, TextStyle } from '@style';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Location, Producer } from '@api';
import { CardInput, keyboardType, autoCompleteType, textContentType } from '@molecules'
import { replaceLocale } from '@utilities/locale';

export const LocationInput = React.forwardRef((props, ref) => {
    /*
        props.value must have at least postal_code, place_name and admin_name2 fields
    */
    let config = LayoutAnimation.create(300, 'easeIn', 'opacity')

    let [location, setLocation] = useState(props.value)
    let [list, setList] = useState([])
    let innerRef = React.useRef(null)

    React.useImperativeHandle(ref, () => innerRef.current, [])

    let errors = typeof props.errors !== 'undefined' ? props.errors : {}

    const _getLocationValue = (item) => {
        if (!item) return item
        return `${item.place_name}, ${item.admin_name2}`
    }

    const _onValueChange = (field) => (value) => {
        
        if (props.onValueChange) {

            if (field === 'postal_code' && value.length === 5) {
                getLocations({ variables: { postal_code: value } })
                Keyboard.dismiss();

                setLocation({
                    ...location,
                    postal_code: value
                })
                if (Platform.OS == 'web') return

            }

            let toStore = {}

            if (!field && value) {
                toStore = { 
                    ...value, 
                    location: undefined,
                    postal_code: value.postal_code?.length === 5 ? value.postal_code : location.postal_code,
                    coordinates: value.location?.coordinates
                }
            } else {
                toStore[field] = value
            }
            if ((typeof props.value?.place_name === 'undefined' || props.value?.place_name == null) || value.place_name != props.value.place_name) {
                props.onValueChange(toStore)
            }
            setLocation(toStore)
        }
    }

    const _onCompleted = (data) => {
        if (typeof data !== 'undefined') {
            let list = []

            if (data.locations.length > 0) {

                LayoutAnimation.configureNext(config);

                if (!props.value) {
                    list.push({ value: undefined, label: replaceLocale('$common.components.locationInput.selectLocation'), key: undefined, displayValue: false })
                }

                let position = 0
                let index = 0
                
                data.locations.forEach((location) => {
                    let newLocation = { ...location }
                    
                    if ((typeof location.postal_code === 'undefined' || location.postal_code == null) &&
                        typeof props.value?.postal_code !== 'undefined') {
                        newLocation.postal_code = props.value.postal_code
                    }

                    if (typeof props.value !== 'undefined' &&
                        location.place_name === props.value?.place_name) {
                        position = index
                    }

                    let value = _getLocationValue(location)
                    
                    list.push({ value: newLocation, label: value, key: value, displayValue: false })
                    index += 1
                })

                _onValueChange()(list[position].value)
                setList(list)

                if (errors.postal_code) {
                    let error = { ...errors }
                    error.postal_code = undefined
                    if (props.onError) {
                        props.onError(error)
                    }
                }
                return
            }

            let error = { ...errors }
            error.postal_code = '$common.components.locationInput.postalCodeError'
            if (props.onError) {
                props.onError(error)
            }
            setList([])
        }
    }

    let { query, param } = Location.getByPostalCode(undefined);
    let [getLocations, locationResult] = useLazyQuery(query, { onCompleted: _onCompleted });

    React.useEffect(() => {
        if (typeof props.value?.postal_code !== 'undefined' &&
            props.value?.postal_code !== null &&
            props.value?.postal_code?.length === 5) {
            
            getLocations({ variables: { postal_code: props.value?.postal_code } })
        }

    }, [props.navigation, props.value])

    let error = typeof errors.postal_code === 'undefined' ? errors.PostalCode : errors.postal_code

    const showPostalCodeOptions = () => {
        if (!error && !locationResult.loading && typeof locationResult.data !== 'undefined') {
            if (list.length > 0) {

                let position = 0
                list.forEach((item, index) => {
                    if (typeof props.value !== 'undefined' &&
                        item.value &&
                        item.value?.place_name === location?.place_name) {
                        position = index
                    }
                })

                if (list.length == 1) {
                    return (<CardInput 
                        title={'$common.components.locationInput.city'}
                        icon={Icons.CITY}
                        style={{ width: '100%' }}
                        inputText={list[position].key}
                        cardStyle={{ marginTop: 10 }}
                        onPressReadOnly={() => {}}
                        readOnly={true}
                    />)
                }

                return (
                    <CardInput
                        key={position}
                        title={'$common.components.locationInput.city'}
                        icon={Icons.CITY}
                        noInput
                        picker
                        pickerValue={list[position].value}
                        pickerItemKey={list[position].key}
                        pickerItems={list}
                        onValueChange={_onValueChange()}
                        style={{ width: '100%' }}
                        cardStyle={{ marginTop: 10 }}
                        readOnly={typeof props.readOnly !== 'undefined' ? props.readOnly : false}
                        onPressReadOnly={typeof props.onPressReadOnly !== 'undefined' ? props.onPressReadOnly : () => { }}
                    />
                )

            }
        }
    }

    let postalCode = location?.postal_code

    return (<View key={location?._id} style={[{ alignSelf: 'center' }, props.style]}>
            <CardInput
                ref={innerRef}
                title={'$common.components.locationInput.postalCode'}
                icon={Icons.POSTALCODE}
                autoFocus={props.autoFocus}
                onChangeText={_onValueChange('postal_code')}
                keyboardType={keyboardType.numberPad}
                autoCompleteType={autoCompleteType.postalCode}
                textContentType={textContentType.postalCode}
                inputText={postalCode}
                placeholder={'$common.components.locationInput.postalCodePlaceHolder'}
                returnKeyType={'next'}
                maxLength={5}
                errorText={error}
                readOnly={typeof props.readOnly !== 'undefined' ? props.readOnly : false}
                onPressReadOnly={typeof props.onPressReadOnly !== 'undefined' ? props.onPressReadOnly : () => { }}
            />

            {!error && typeof postalCode !== 'undefined' && postalCode != null &&
                postalCode.length === 5 &&
                <View style={{ minHeight: 80, width: '100%' }}>

                    {showPostalCodeOptions()}

                    {locationResult.loading &&
                        <ActivityIndicator
                            size="small"
                            color={AppStyle.mainColor}
                            style={{ marginTop: 10, marginBottom: 20 }} />
                    }
                    {locationResult.loading && <SimpleText
                        style={{ alignSelf: 'center', marginBottom: 18 }}
                    >$common.components.locationInput.downloadingLocations</SimpleText>}
                </View>}
        </View>
    )
})
import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'

import { ButtonText, SimpleText } from '@quarks'
import { sessionOperations } from '_ducks/session'
import { AppStyle, TextStyle } from "@style";
import { ILLUSTRATIONS } from '@images'

const VerificationScreen = (props) => {

    const register = useSelector(state => state.session.register.form)
    const styles = updateStyles()
    const dispatch = useDispatch()
    
    // In order to force a view update
    useSelector(state => state.session.language)

    React.useEffect(() => {
        props.navigation.setOptions({
            headerTitle: "",
        })
    }, [props.navigation])

    let status = {
        mainTitle: `$access.verification.welcomeTitle ${register.name}!`,
        verificationText: "$access.verification.emailExplain",
        secondaryText: "$access.verification.nextToValidationExplain"
    }

    const _onPress = () => {
        dispatch(sessionOperations.waitingVerification())
        props.navigation.replace('AccessLogin');
    }

    return (
        <ScrollView style={{
            flex: 1,
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>

            <SimpleText style={styles.welcomeTitle}>
                {status.mainTitle}
            </SimpleText>
            <Image
                style={{ height: '30%', maxHeight: 250, width: '50%', maxWidth: 350, alignSelf: 'center' }}
                source={ILLUSTRATIONS.mail}
            />
            <SimpleText style={styles.verificationText}>
                {status.verificationText}
            </SimpleText>
            <SimpleText style={styles.emailText}>
                {register.email}
            </SimpleText>
            <SimpleText style={styles.secondaryText}>
                {status.secondaryText}
            </SimpleText>
            <ButtonText
                onPress={_onPress}
                text={'$access.verification.okButton'}
                type={'solid'}
                style={{
                    marginTop: 20,
                    height: 50,
                    width: '60%',
                    textAlign: 'center'
                }} />
        </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center'
    },
    emailText: {
        ...TextStyle.size.large,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 20,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    secondaryText: {
        paddingTop: 30,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        color: AppStyle.textColorLight,
        ...TextStyle.size.medium
    }
});

export default VerificationScreen;
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";
/* State shape
{
    details: producer,
    list: [ producer ],
}
*/

const modifyObject = (obj, fields, value) => {
    console.log('modify obect fields', fields)
    if (fields.length > 1) {
        obj[fields[0]] = modifyObject(obj[fields[0]], fields.slice(1), value)
        return obj;
    } else if (fields.length == 1) {
        obj[fields[0]] = value;
        console.log('modifyObj', obj)
        return obj;
    }
}

const updateChildsField = (obj, field, value) => {
    console.log('update child', obj)
    if (typeof obj !== 'object') {
        return obj;
    }
    
    fields = Object.keys(obj)
    console.log('fields', fields)
    if (fields.includes(field)) {
        obj[field] = value
    }

    fields.forEach(child => {
        if (typeof obj[child] !== 'object') {
            return
        }
        obj[child] = updateChildsField(obj[child], field, value)
    })
    return obj
}

const checkObjectAndChilds = (obj, fields, value) => {
    console.log('modify obect fields', fields)
    if (fields.length > 1) {
        obj[fields[0]] = checkObjectAndChilds(obj[fields[0]], fields.slice(1), value)
        return obj;
    } else if (fields.length == 1) {
        obj[fields[0]] = value;
        obj = updateChildsField(obj, 'checked', value)
        console.log('after update', obj)
        return obj;
    }
}

const reduceSelectRegion = (state, action) => {
    console.log('reduce select region', state, action)
    let fields = []
    fields.push(...action.region)
    fields.push('checked')

    let newState = checkObjectAndChilds(state, fields, action.select)
    console.log('newstate', newState)
    return Object.assign({}, newState)
}

const reduceDropdownRegion = (state, action) => {
    console.log('reduce dropdown region', state, action)
    let fields = []
    fields.push(...action.region)
    fields.push('dropdown')

    let newState = modifyObject(state, fields, action.dropdown)
    console.log('newstate', newState)
    return Object.assign(state, newState)
}

const regionsReducer = createReducer(null)({
    [types.ON_SELECT_REGION]: reduceSelectRegion,
    [types.ON_DROPDOWN_REGION]: reduceDropdownRegion,
});

export default combineReducers(
    {
        data: regionsReducer,
    }
);
import React, { Component } from 'react';
import { ActivityIndicator, Image, ImageBackground, View, Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Crypto from 'expo-crypto';
import { AppStyle } from '@style';

export default class CachedImage extends Component {

  state = {
    imgURI: '',
    loading: false
  }

  async componentDidMount() {
    if (this.props.source && typeof this.props.source !== 'number' && Platform.OS != 'web') {
      this.setState({ ...this.state, loading: true })
      const filesystemURI = await this.getImageFilesystemKey(this.props.source.uri);
      await this.loadImage(filesystemURI, this.props.source.uri);
    }
  }

  async componentDidUpdate() {
    if (this.props.source && typeof this.props.source !== 'number' && Platform.OS != 'web') {
      const filesystemURI = await this.getImageFilesystemKey(this.props.source.uri);
      if (this.props.source.uri === this.state.imgURI ||
        filesystemURI === this.state.imgURI) {
        return null;
      }
      await this.loadImage(filesystemURI, this.props.source.uri);
    }
  }

  async getImageFilesystemKey(remoteURI) {
    const hashed = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      remoteURI
    );
    return `${FileSystem.cacheDirectory}${hashed}`;
  }

  async loadImage(filesystemURI, remoteURI) {
    try {
      // Use the cached image if it exists
      const metadata = await FileSystem.getInfoAsync(filesystemURI);
      if (metadata.exists) {
        this.setState({
          imgURI: filesystemURI,
          loading: false
        });
        return;
      }

      // otherwise download to cache
      const imageObject = await FileSystem.downloadAsync(
        remoteURI,
        filesystemURI
      );
      this.setState({
        imgURI: imageObject.uri,
        loading: false
      });
    } catch (err) {
      console.warn('Image loading error:', err);
      this.setState({ imgURI: remoteURI });
    }
  }
  render() {
    let source = this.state.imgURI ? { uri: this.state.imgURI } : this.props.source
    if (!source && !this.state.loading) return
    if (this.state.loading) {
      return <View {...this.props} style={[this.props.style, {
        justifyContent: 'center', backgroundColor: AppStyle.cardBackground + '70'
      }]} >
        <ActivityIndicator size={'small'} style={{ alignSelf: 'center' }} color={AppStyle.textColor} />
      </View>
    }
    if (this.props.isBackground) {
      return (
        <ImageBackground
          {...this.props}
          source={source}
        >
          {this.props.children}
        </ImageBackground>);
    } else {
      return (
        <Image
          {...this.props}
          source={source}
        />
      );
    }
  }
}


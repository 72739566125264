import React from 'react';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js/mobile'

import { Icons } from '@icons'
import { CardInput, keyboardType, autoCompleteType, textContentType } from '@molecules'

export const PhoneCardInput = React.forwardRef((props, ref) => {

    let countries = getCountries()
    let list = []
    let innerRef = React.useRef(null)

    React.useImperativeHandle(ref, () => innerRef.current, [])
    
    countries.forEach((country) => {
        let code = getCountryCallingCode(country)
        list.push({ value: `+${code}`, label: `${country}: +${code}`, displayValue: true })
    })

    list.sort((a, b) => {
        return a.value - b.value
    })

    let initialState = {
        number: undefined,
        countryCode: '+34'
    }

    let [form, setForm] = React.useState(initialState)

    React.useEffect(() => {

        if (typeof props.value === 'object') {
            setForm({
                number: props.value?.number,
                countryCode: props.value?.countryCode
            })
        }

    }, [props.value]);

    let _onValueChange = (field) => (value) => {
        let tmp = {};
        if (field === 'countryCode') {
            tmp = {
                number: form.number,
                countryCode: value
            }
        } else if (field === 'phone') {
            tmp = {
                number: value,
                countryCode: form.countryCode
            }
        }
        if (props.onValueChange) {
            props.onValueChange(tmp)
        }
        setForm(tmp)
    }

    return (
        <CardInput
            ref={innerRef}
            style={props.style}
            title={props.title ? props.title : '$common.components.phoneCardInput.title'}
            locale={'ca'}
            pickerLeft
            isOptional={props.isOptional}
            pickerValue={form.countryCode}
            pickerItems={list}
            icon={Icons.PHONE}
            onValueChange={_onValueChange('countryCode')}
            onChangeText={_onValueChange('phone')}
            keyboardType={keyboardType.numberPad}
            autoCompleteType={autoCompleteType.phone}
            textContentType={textContentType.telephoneNumber}
            onSubmitEditing={props.onSubmitEditing}
            inputText={form.number}
            placeholder={'Ex. 657665515'}
            errorText={props.errorText}
            returnKeyType={'next'}
        />
    );
})

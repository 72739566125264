import { AppStyle } from "@style";
import React, { useState, useRef, useEffect, useMemo } from "react";

import {
  View, Platform,
  StyleSheet,
  Animated,
  Dimensions,
  PanResponder,
  TouchableWithoutFeedback,
  Easing,
  ImageBackground,
  Keyboard, StatusBar, KeyboardAvoidingView, ScrollView
} from "react-native";

import Modal from 'react-native-modal'

const { height } = Dimensions.get("window");

export const BottomSheetModal = (props) => {

  const _onClose = () => {
    Keyboard.dismiss()
    StatusBar.setBarStyle('dark-content')
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor('transparent', true)
    }
    if (props.onClose) {
      props.onClose();
    }
  }

  const TIMING_CONFIG = {
    duration: props.duration ? props.duration : 450,
    easing: Easing.inOut(Easing.ease),
  };

  const pan = useRef(new Animated.ValueXY()).current;

  let [topPosition, setTopPosition] = useState(undefined);
  let [isAnimating, setIsAnimating] = useState(
    props.DisableHandAnimation ? true : false
  );

  let animatedValueX = 0;

  let animatedValueY = 0;

  const panResponder = PanResponder.create({
    // Ask to be the responder:
    onStartShouldSetPanResponder: () => false,
    onStartShouldSetPanResponderCapture: () => false,
    onMoveShouldSetPanResponder: (evt, gestureState) => {
      if (isAnimating) {
        return false;
      }
      if (topPosition && gestureState.moveY > topPosition) {
        return false
      }
      if (gestureState.dy > 22) {
        return true;
      }
      return false;
    },
    onPanResponderGrant: () => {
      pan.setOffset({
        x: animatedValueX,
        y: animatedValueY,
      });
      pan.setValue({ x: 0, y: 0 }); // Initial value
    },
    onPanResponderMove: (evt, gestureState) => {
      if (gestureState.dy > 0) {
        pan.setValue({ x: 0, y: gestureState.dy });
      }
    },
    onPanResponderRelease: (evt, gestureState) => {
      // The user has released all touches while this view is the
      // responder. This typically means a gesture has succeeded
      // Flatten the offset so it resets the default positioning
      if (gestureState.dy > 0 && gestureState.vy > 0) {
        if (gestureState.vy <= -0.7 || gestureState.dy <= -100) {
          setIsAnimating(true);
          Animated.timing(pan, {
            toValue: { x: 0, y: -height },
            ...TIMING_CONFIG,
            useNativeDriver: false,
          }).start(() => {
            setIsAnimating(false);
            _onClose();
          });
        } else if (gestureState.vy >= 0.5 || gestureState.dy >= 100) {
          setIsAnimating(true);
          Animated.timing(pan, {
            toValue: { x: 0, y: height },
            ...TIMING_CONFIG,
            useNativeDriver: false,
          }).start(() => {
            setIsAnimating(false);
            _onClose();
          });
        } else {
          setIsAnimating(true);
          Animated.spring(pan, {
            toValue: 0,
            useNativeDriver: false,
          }).start(() => {
            setIsAnimating(false);
            // props.onClose();
          });
        }
      } else {
        setIsAnimating(true);
        Animated.spring(pan, {
          toValue: 0,
          useNativeDriver: false,
        }).start(() => {
          setIsAnimating(false);
          // props.onClose();
        });
      }
    },
  })

  useEffect(() => {
    if (props.modalVisible) {
      animatedValueX = 0;
      animatedValueY = 0;
      pan.setOffset({
        x: animatedValueX,
        y: animatedValueY,
      });
      pan.setValue({
        x: 0,
        y: props.OpenModalDirection == "up" ? -height : height,
      }); // Initial value
      pan.x.addListener((value) => (animatedValueX = value.value));
      pan.y.addListener((value) => (animatedValueY = value.value));
    }
  }, [props.modalVisible]);

  const animateToClose = () => {
    Keyboard.dismiss()
    setIsAnimating(true);
    Animated.timing(pan, {
      toValue: {
        x: 0,
        y: height,
      },
      ...TIMING_CONFIG,
      useNativeDriver: false,
    }).start(() => {
      setIsAnimating(false);
      _onClose();
    });
  }

  useEffect(() => {
    if (props.PressToanimate) {
      setIsAnimating(true);
      Animated.timing(pan, {
        toValue: {
          x: 0,
          y: props.PressToanimateDirection == "up" ? -height : height,
        },
        ...TIMING_CONFIG,
        useNativeDriver: false,
      }).start(() => {
        setIsAnimating(false);
        _onClose();
      });
    }
  }, [props.PressToanimate]);

  let handleGetStyle = (opacity) => {
    return [
      [
        styles.container,
        {
          transform: [{ translateX: pan.x }, { translateY: pan.y }],
          opacity: opacity,
        },
        [props.HeaderStyle],
      ],
    ];
  };

  let handleGetStyleBody = (opacity) => {
    return [
      [
        styles.background,
        {
          transform: [{ translateX: pan.x }, { translateY: pan.y }],
          opacity: opacity,
        },
      ],
      [props.ContentModalStyle],
    ];
  };
  let handleMainBodyStyle = (opacity) => {
    return [
      [
        styles.ContainerModal,
        {
          opacity: opacity,
        },
      ],
      [props.MainContainerModal],
    ];
  };

  if (props.modalVisible) {
    StatusBar.setBarStyle('light-content')
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor('rgba(0,0,0,0.5)', true)
    }
  } else {
    StatusBar.setBarStyle('dark-content')
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor('transparent', true)
    }
  }

  return (<Modal
    transparent={true}
    avoidKeyboard={true}
    swipingDirection={'down'}
    visible={props.modalVisible}
    onRequestClose={animateToClose}
    style={{ width: '100%', margin: 0, height: 200, alignSelf: 'center' }}
    onShow={() => {
      setIsAnimating(true);
      Animated.timing(pan, {
        ...TIMING_CONFIG,
        toValue: { x: 0, y: 0 },
        useNativeDriver: false,
      }).start(() => {
        setIsAnimating(false);
      });
    }}
  >
    <TouchableWithoutFeedback
      onPress={animateToClose}
      style={styles.TouchWithoutFeedBack}
    >
      <Animated.View style={handleMainBodyStyle()}>
        <Animated.View
          onLayout={(event) => {
            setTopPosition(event.nativeEvent.layout.y + 100)
          }}
          style={handleGetStyleBody()}
          {...panResponder.panHandlers}
        >
          <ImageBackground
            source={props.ImageBackgroundModal && props.ImageBackgroundModal}
            style={styles.ImageBackground}
            imageStyle={
              props.ImageBackgroundModalStyle &&
              props.ImageBackgroundModalStyle
            }
          >
            {props.ContentModal}
          </ImageBackground>
        </Animated.View>
        <Animated.View
          style={handleGetStyle()}
          {...panResponder.panHandlers}
        >
          <TouchableWithoutFeedback>
            {props.HeaderContent ? props.HeaderContent : <View />}
          </TouchableWithoutFeedback>
        </Animated.View>
      </Animated.View>
    </TouchableWithoutFeedback>
  </Modal>
  );
};

const styles = StyleSheet.create({
  background: {
    opacity: 0,
    flex: 1,
    marginTop: 55,
  },
  container: {
    marginTop: 50,
    position: "absolute",
    width: "100%",
  },
  ContainerModal: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    flex: 1,
  },
  ImageBackground: {
    width: "100%",
    borderRadius: 20,
    height: "100%",
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 20,
    backgroundColor: AppStyle.backgroundColor
  },
  TouchWithoutFeedBack: { flex: 1 },
});

import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    details: product,
    list: [ product ],
}
*/

const formReducer = createReducer(null)({
    [types.ON_SUBMIT]: (state, action) => Object.assign({}, state, action.form),
    [types.ON_FORM_VALIDATION_ERROR]: (state, action) => ({
        ...state,
        errors: {
            ...state.errors,
            ...action.errors
        }
    })
});


export default combineReducers({
    form: formReducer
});
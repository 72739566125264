import React, { useState } from 'react';
import {
    Platform,
    StyleSheet,
    View
} from 'react-native';

import { AppStyle, TextStyle } from '@style'
import { Icon } from '@quarks'
import { SimpleText } from '@quarks'

export function Banner(props) {

    const banner = { type: 'info', ...props }
    const backColor = banner.type === 'info' ? AppStyle.getColor('mainAccent', 2)
        : banner.type === 'error' ? AppStyle.errorLightColor
            : banner.type === 'warning' ? AppStyle.warningColor
                : AppStyle.getColor('secondary', 3) //success

    const styles = updateStyle(backColor)
    const numberOfLines = typeof props.numberOfLines === 'number' ? props.numberOfLines : 2
    /**
     * banner options
     * 
     * {
     *      message: String
     *      icon   : String | Component
     *      type   : info | error | warning | success
     *      numberOfLines : Number
     * }
     * 
     */

    const icon = typeof banner.icon === 'string' ? <Icon
        name={banner.icon}
        size={30}
        color={AppStyle.textColor}
        style={styles.iconStyle} />
        :
        banner.icon


    return (
        <View style={[styles.bannerView, props.style]}>
            <View style={{ flexDirection: 'row' }}>
                {icon}
                <SimpleText
                    ellipsizeMode={'tail'}
                    numberOfLines={numberOfLines}
                    style={[styles.textStyle, props.textStyle]}>
                    {banner.message}
                </SimpleText>

            </View>
            {props.children}
        </View>
    );
}

let updateStyle = (backColor) => {
    return StyleSheet.create({
        bannerView: {
            backgroundColor: backColor,
            borderRadius: 15,
            padding: 10,
            maxWidth: 600,
            marginBottom: 10, marginTop: 5,
            width: '90%',
            alignItems: 'center',
            justifyContent: 'center',
            shadowColor: AppStyle.backgroundColorInverted,
            shadowOffset: {
                width: 0,
                height: 2
            },
            shadowOpacity: 0.2,
            shadowRadius: 3.84,
            elevation: 5,
        },
        textStyle: {
            paddingLeft: 10,
            paddingRight: 25,
            color: AppStyle.getColor('light', 1),
            ...TextStyle.weight.semibold,
            ...Platform.select({
                native: {
                    shadowColor: AppStyle.getColor('dark', 1),
                    shadowOffset: {
                        width: 0,
                        height: 0
                    },
                    shadowOpacity: 0.2,
                    shadowRadius: 2,
                    elevation: 2,
                }
            })
        },
        iconStyle: {
            paddingLeft: 15
        }
    });
}
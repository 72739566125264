import React from 'react';
import { View, Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';

import { AppStyle } from '@style'

import ProductsScreen from '_scenes/products';
import ProductFormScreen from '_scenes/productForm';
import ProductScreen from '_scenes/product'
import { sessionOperations } from '_ducks/session';
import { BlurModal } from '@atoms';

const PublicStack = createStackNavigator();

const baseOptions = {
    headerStyle: {
        backgroundColor: AppStyle.backgroundColor,
        elevation: 0,
        shadowOpacity: 0
    },
    animationEnabled: true,
    headerTitle: ''
}

const ProductNav = (props) => {

    const dispatch = useDispatch()

    return <BlurModal closeButton visible={props.showModal} onClose={() => {
        dispatch(sessionOperations.toggleProductModal(undefined))
    }}>
        <ProductScreen />
    </BlurModal>
}

function ProductsNavigator() {
    return (
        <PublicStack.Navigator
        screenOptions={({ route, navigation }) => ({
            title: `Nadius - ${`$root.screens.${route.name}`.localise()}`
        })}>
            <PublicStack.Screen
                name="Products"
                component={ProductsScreen}
                options={{ ...baseOptions, headerShown: false }}
            />
            <PublicStack.Screen
                name="ProductForm"
                component={ProductFormScreen}
                options={baseOptions}
            />
        </PublicStack.Navigator>
    );
}

const ProductsNavigation = (props) => {

    const navigationRef = React.useRef(null);
    const modals = useSelector(state => state.session.modals)

    return (<View style={{ flex: 1, backgroundColor: AppStyle.backgroundColor }}>
        {modals.product && <NavigationContainer ref={navigationRef} independent={true}>
            <ProductNav navigation={navigationRef?.current} showModal={typeof modals.product !== 'undefined'} />
        </NavigationContainer>}
        <ProductsNavigator />
    </View>
    )
};


export default ProductsNavigation;

import React, { useState } from 'react';
import { View, Platform, StyleSheet, Dimensions } from 'react-native';
import { useAuth } from '@realm'
import * as ImagePicker from 'expo-image-picker';
import { BlurView } from 'expo-blur';
import * as ImageManipulator from 'expo-image-manipulator';

import { ImageCard, LoadingView } from '@atoms'
import { AppStyle } from '@style';
import { SimpleText, ButtonText } from '@quarks';
import { NadiusIcons, Icons } from '@icons'
import { IMAGE_SIZES } from '@utilities/images'
import { App } from 'realm-web';

const DropZone = ({ children, ...props }) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [isDragActive, setDragActive] = React.useState(false);
    const ref = React.createRef();
    const fileIsOver = typeof props.fileIsOver === 'function'? props.fileIsOver : ()=>{}
    const onDragEnter = React.useCallback((e) => {
        setIsVisible(true);
        fileIsOver(true)
        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);
    const onDragOver = React.useCallback((e) => {
        setIsVisible(true);
        fileIsOver(true)
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);
    const onGeneralDragStart = React.useCallback((e) => {
        setDragActive(true);
        return false;
    }, []);
    const onGeneralDragOver = React.useCallback((e) => {
        setDragActive(false);
        return false;
    }, []);
    const onDragLeave = React.useCallback((e) => {
        setIsVisible(false);
        fileIsOver(false)
        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);
    const onDrop = React.useCallback((e) => {
        setDragActive(false);
        setIsVisible(false);
        fileIsOver(false)
        e.preventDefault();
        const files = e.dataTransfer.files;
        console.log('Files dropped: ', files);
        // Upload files
        for (var i = 0; i < e.dataTransfer.items.length; i++) {
            
            const ensureIsAnImage = e.dataTransfer.items[i].kind === 'file' && ( e.dataTransfer.items[i].type === 'image/png' || e.dataTransfer.items[i].type === 'image/jpeg')
            if (ensureIsAnImage) {
                var file = e.dataTransfer.items[i].getAsFile();
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => props.uploadImage(reader.result);
                reader.onerror = error => console.log(error);
            }
        }
        setIsVisible(false);
        return false;
    }, [props.form]);
   
    React.useEffect(() => {
        let node = ref.current
        document.addEventListener("dragenter", onGeneralDragStart);
        document.addEventListener("dragend", onGeneralDragOver);     
        document.addEventListener("mouseup", onGeneralDragOver);                       
        node.addEventListener('mouseup', onDragLeave);
        node.addEventListener('dragleave', onDragLeave);
        node.addEventListener('dragenter', onDragEnter);
        node.addEventListener('dragover', onDragOver);
        node.addEventListener('drop', onDrop);
        return () => {
            document.removeEventListener("dragstart", onGeneralDragStart);
            document.removeEventListener("dragend", onGeneralDragOver);       
            document.removeEventListener('mouseup', onGeneralDragOver);       
            node.removeEventListener('mouseup', onDragLeave);
            node.removeEventListener('dragleave', onDragLeave);
            node.removeEventListener('dragenter', onDragEnter);
            node.removeEventListener('dragover', onDragOver);
            node.removeEventListener('drop', onDrop);
        };
    }, [onDragEnter, onDragLeave, onDragOver, onDrop, onGeneralDragStart, onGeneralDragOver]);

    return (<View ref={ref} style={isVisible ? props.highlightStyle : isDragActive ? props.dragPossibleStyle: props.style} onDragLeave={onDragLeave}>
        {children}
    </View>
    );
};


export const ImageUploader = React.forwardRef((props, ref) => {

    /*
    {

        types:
            FSImage: {
                //File system fields
                loading: bool,
                error: bool,
            }
            image: ImageId

        props:
            images: Array of Images (both FSImage and image)
                images to display, this component does not handle state,
            
            width: fixed image width

            maxImages: maximum images allowed
            
            style

            title: string or component

            error: component

            type: "default" | "circle"

        callbacks: 
            props.imageToUpload(id, FSImage)
                Image sent to backend, returns filesystem image, and image status
            props.imageUploaded(toUploadId, image)
                Image sent to backend returned ok
                
        
    }
    */

    const minImageWidth = props.width ? props.width : 50
    const maxImageWidth = props.width ? props.width : 150

    let tmpWidth = (Dimensions.get('window').width * 0.75) / 2
    const width = tmpWidth > maxImageWidth ? maxImageWidth : tmpWidth
    const [dropping, setIsDropping] = useState(false)
    const height = props.height ? props.height : 150
    const styles = updateStyles()
    const { user, callFunction } = useAuth();
    const displayedImages = props.images

    let _getImageName = (image) => {
        if (typeof image?.uri === 'string') {
            let name = image.uri.split('/')
            return name[name.length - 1]
        } else if (typeof image?.url === 'string') {
            let name = image.url.split('/')
            return name[name.length - 1]
        } else if (typeof image?.url === 'object')
            return _getImageName(image.url)
    }

    const _addImageFromDrop = async (image)=> _addImage(await _compressImage(image))

    let _addImage = async (image) => {
        console.log(image)
        if (image == null || typeof image == 'undefined') return

        let currImage = {
            ...image,
            loading: true,
            error: false
        }
        let name = _getImageName(image)

        currImage.base64 = currImage.base64.replace(/data:image\/.*;base64,/gm, '')

        if (typeof currImage.type == 'undefined') {
            currImage.type = 'image'
        }

        props.imageToUpload(name, currImage)

        try {
            let result = await callFunction("IMAGE_upload", { fileName: name, fileType: currImage.type, base64EncodedImage: currImage.base64 })
            if (result?.status == 0) {
                props.imageUploaded(name, result.id)
            } else {
                props.imageToUpload(name, { ...image, loading: false, error: true })
            }
            console.log(result)
        } catch (error) {
            console.warn(error)
            props.imageToUpload(name, { ...image, loading: false, error: true })
        }

    }

    const _deleteImage = (item) => {

        if (typeof item?.error != 'undefined') {
            props.imageDelete(_getImageName(item), item)
        } else {
            props.imageDelete(undefined, item)
        }
    }
    const _compressImage = async (base64) => {
        return await ImageManipulator.manipulateAsync(
            base64, {}, {
            base64: true, compress: 0.8,
            format: ImageManipulator.SaveFormat.JPEG
        });
    }
    let _pickImage = async () => {
        try {
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: Platform.OS == 'ios',
                quality: 0.8,
                allowsMultipleSelection: true,
                base64: true,
            });
            for (let index = 0; index < result.selected.length; index++) {
                const image = result.selected[index]
                const manipResult = await ImageManipulator.manipulateAsync(
                    image.localUri || image.uri, {}, {
                    base64: true, compress: 0.8,
                    format: ImageManipulator.SaveFormat.JPEG
                });
                await _addImage(manipResult)
                console.log(manipResult)
            }
        } catch (E) {
            console.warn(E);
        }
    }

    React.useImperativeHandle(ref, () => ({
        pickImage: _pickImage,
    }), [props.form])

    const _renderItem = (item, index) => {
        return (
            <View key={index} style={[styles.imageWithBadgeContainer, { width: width, minWidth: minImageWidth, maxWidth: maxImageWidth, height: height }]}>
                <View style={[styles.badgeContainer]} >
                    <ButtonText
                        style={styles.badgeButton}
                        type={'solid'}
                        onPress={() => _deleteImage(item)}
                        iconSet={NadiusIcons.iconSet}
                        iconSize={15}
                        icon={NadiusIcons.TRASH}  
                    />
                </View>
                <ImageCard image={item}
                    borderRadius={props.type == 'circle' ? height / 2 : undefined}
                    imgType={IMAGE_SIZES.ORIGINAL}
                    style={{ minWidth: minImageWidth, maxWidth: maxImageWidth, height: height, width: props.type == 'circle' ? height : undefined, alignSelf: 'center' }} />
                { (item?.error || item?.loading) &&
                    <BlurView intensity={80} tint={'light'} style={{
                        top: 0, position: 'absolute', alignSelf: 'center',
                        width: '100%', height: height, borderRadius: props.type == 'circle' ? height / 2 : 8, justifyContent: 'center',
                    }}>
                        {item.loading &&
                            <LoadingView style={{ height: height, backgroundColor: 'transparent' }}></LoadingView>
                        }
                        {item.error &&
                            <ButtonText icon={'redo-alt'} iconSize={30} iconStyle={{ color: AppStyle.errorColor }} onPress={async () => await _addImage(item)}></ButtonText>
                        }
                        {item.error &&
                            <ButtonText textStyle={{ color: AppStyle.errorColor }} text={'$common.components.imageUploader.retryUpload'} onPress={async () => await _addImage(item)}></ButtonText>
                        }
                    </BlurView>
                }
            </View>)
    }

    let title = null

    if (typeof props.title === 'string') {
        title = <SimpleText style={{ marginRight: 20, marginTop: 20, textAlign: 'center' }}>{props.title}</SimpleText>
    } else if (typeof props.title === 'function') {
        title = props.title()
    }
    let dropStyle = {
        padding: 15, flex: 1,
        flexDirection: 'row', flexWrap: 'wrap',
        justifyContent: 'center', alignContent: 'center',
        alignItems: 'center', 
        borderWidth: 1, alignItems: 'flex-start',
        flex: 1, minHeight: 100,
        width: '100%', maxWidth: 800,
        alignSelf: 'center',
        borderColor: AppStyle.textColorLight,
        borderStyle: 'dotted',
        borderRadius: 20
    }

    if (props.type == 'circle') {
        dropStyle.height = height
        dropStyle.width = height
        dropStyle.maxHeight = height
        dropStyle.maxWidth = height
        dropStyle.minHeight = height
        dropStyle.minWidth = height
        dropStyle.borderRadius = (height + dropStyle.padding) / 2
    }

    let imagesToRender = []
    for (let index = 0; index < displayedImages.length; index++) {
        imagesToRender.push(_renderItem(displayedImages[index], index))
    }
    return Platform.OS == 'web' ?
        (
            <View
                style={[{ justifyContent: 'center', alignContent: 'center', alignItems: 'flex-start', flexDirection: 'row' }, props.style]}>
                {title}
                <DropZone
                    style={dropStyle}
                    form={props.form}
                    highlightStyle={[dropStyle, { backgroundColor: AppStyle.getColor('main', 3) }, props.highlightStyle]}
                    dragPossibleStyle={[dropStyle, { backgroundColor: AppStyle.getColor('mainAccent', 4) }, props.dragPossibleStyle]}
                    uploadImage={_addImageFromDrop}
                    fileIsOver={(state)=>setIsDropping(state)}
                >
                    {displayedImages.length == 0 && !dropping ? (
                        props.withButton === true ?
                            (
                                <ButtonText
                                    style={styles.selectButton}
                                    textStyle={styles.selectTextButton}
                                    iconStyle={{ paddingLeft: 10 }}
                                    type={'secondary'}
                                    icon={Icons.UPLOAD}
                                    onPress={_pickImage}
                                    text={'$common.components.imageUploader.selectButton'}>
                                </ButtonText>)
                            :
                            (<SimpleText style={{ color: AppStyle.textColorLight, textAlign: 'center' }}>$common.components.imageUploader.imagesHint</SimpleText>)
                    ) : null
                    }
                    {dropping &&
                        <SimpleText style={{ color: AppStyle.textColorLight, textAlign: 'center' }}>$common.components.imageUploader.dropHint</SimpleText>
                    }
                    {imagesToRender}
                </DropZone>
            </View>
        )
        :
        (
            <View style={[{ flex: 1, padding: 15, flexDirection: 'row', flexWrap: 'wrap' }, props.style]} >
                {displayedImages.length == 0 ? (
                    props.withButton === true ?
                        (<ButtonText
                            style={styles.selectButton}
                            textStyle={styles.selectTextButton}
                            type={'secondary'}
                            icon={Icons.UPLOAD}
                            onPress={_pickImage}
                            text={'$common.components.imageUploader.selectButton'}>
                        </ButtonText>)
                        : null
                ) : null
                }(<SimpleText style={{ color: AppStyle.textColorLight, textAlign: 'center' }}>$common.components.imageUploader.imagesHint</SimpleText>)
                {imagesToRender}
            </View>
        )
})

const updateStyles = () => StyleSheet.create({
    imageWithBadgeContainer: {
        flexDirection: 'row',
        marginBottom: 5,
        marginLeft: 5, marginRight: 5
    },
    badgeContainer: {
        top: -10,
        left: -10,
        zIndex: 1,
        width: 25,
        height: 25,
        borderRadius: 20,
        elevation: 2,
        alignSelf: 'flex-end',
        justifyContent: 'center',
        position: 'absolute',
    },
    badgeButton: {
        width: 25,
        height: 25,
        paddingRight: 0,
        borderRadius: 20,
        shadowOffset: {
            width: 5,
            height: 5
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 2,
        justifyContent: 'center',
        backgroundColor: AppStyle.errorColor
    },
    selectButtonView: {
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: 150
    },
    selectButton: {
        paddingLeft: 10,
        paddingRight: 10
    },
    selectTextButton: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 10,
    }
}
);

import React, { useState } from 'react';
import { StyleSheet, Platform, UIManager, View, Image, Pressable, Keyboard, Dimensions } from 'react-native';
import { useSelector } from 'react-redux'

import { useAuth } from '@realm'
import { loginFormValidation } from 'nexto-formvalidator'
import { CardInput, keyboardType, autoCompleteType, autoCapitalize, textContentType, LoadingModal } from '@molecules'
import { ButtonText, SimpleText } from '@quarks'
import { AppStyle, TextStyle } from "@style";
import { Icons } from '@icons'
import { APP_ICONS } from '@images'
import { KeyboardAwareScrollView } from '@platform'

const LoginScreen = (props) => {

    const styles = updateStyles()
    const { logIn, user, loading } = useAuth();

    let passwordRef = React.useRef(null)
    let register = useSelector(state => state.session.register)

    // In order to force a view update
    useSelector(state => state.session.language)

    const [errors, setErrors] = useState({})
    const [formState, setFormState] = useState({
        email: register.email,
    })

    let form = formState

    const navigate = () => {
        if (typeof user?.producer === 'undefined') {
            if (typeof user?.toBecomeProducer !== 'undefined') {
                props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Main' }],
                });
            } else {
                props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'CreateProducerAccount' }],
                });
            }
        } else {
            props.navigation.reset({
                index: 0,
                routes: [{ name: 'Main' }],
            });
        }
    }

    const onLogin = async () => {
        setFormState(form)

        let actualForm = {
            email: form.email,
            password: form.password
        }
        const { formErrors, isValid } = loginFormValidation(actualForm)
        if (isValid) {
            Keyboard.dismiss()
            setErrors({})
            let error = await logIn(actualForm.email, actualForm.password, 'producer')
            if (error) {
                passwordRef?.current?.setNativeProps({ text: '' })
                form['password'] = undefined
                setErrors({
                    message: error
                })
            }
        } else {
            setErrors(formErrors)
        }
    }

    const onChangeText = (name) => (text) => {
        let tmp = { ...form }
        tmp[name] = text
        setFormState(tmp)
    }

    if (Platform.OS === 'android') {
        if (UIManager.setLayoutAnimationEnabledExperimental) {
            UIManager.setLayoutAnimationEnabledExperimental(true);
        }
    }

    if (props.navigation.isFocused() && user && user.email) {
        navigate()
    }

    return (<View style={{
        height: '100%',
        width: '90%',
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }} >

        <CardInput
            style={{ marginBottom: 15, width: '90%' }}
            icon={Icons.ENVELOPE}
            onChangeText={onChangeText('email')}
            errorText={errors.email}
            inputText={form['email']}
            title={'$access.register.emailTitle'}
            multiline={false}
            autoCompleteType={autoCompleteType.email}
            keyboardType={keyboardType.emailAddress}
            textContentType={textContentType.username}
            placeholder={'$access.login.emailPlaceholder'}
            autoCapitalize={autoCapitalize.none}
            returnKeyType={'next'}
            autoCompleteType={autoCompleteType.username}
            onSubmitEditing={() => passwordRef.current.focus()}
        />

        <CardInput
            ref={passwordRef}
            style={{ marginBottom: 15, width: '90%' }}
            icon={Icons.LOCK}
            onChangeText={onChangeText('password')}
            errorText={errors.password}
            title={'$access.register.passwordTitle'}
            textContentType={textContentType.password}
            multiline={false}
            autoCompleteType={autoCompleteType.password}
            inputText={form['password']}
            placeholder={'$access.login.passwordPlaceholder'}
            password={true}
            autoCapitalize={'none'}
            returnKeyType={'go'}
            onSubmitEditing={onLogin}
        />

        {errors.message &&
            <SimpleText style={styles.errorText}>
                {errors.message}
            </SimpleText>}

        <View style={{ width: '80%', alignSelf: 'center', maxWidth: 350 }}>
            <ButtonText
                onPress={onLogin}
                text={'$access.login.loginButton'}
                type={'solid'}
                loading={loading}
                style={{
                    width: '100%',
                    height: 50,
                    marginTop: 15,
                }} />
        </View>

        <ButtonText
            onPress={() => {
                props.navigation.navigate('AccessForgot')
            }}
            disabled={loading}
            text={'$access.login.forgotPassword'}
            type={'clear'}
            style={{
                height: 50,
                width: '80%',
                maxWidth: 350,
                fontWeight: 'bold'
            }} />
    </View>
    )
}


const updateStyles = () => StyleSheet.create({
    errorMessage: {
        marginTop: 10,
        marginLeft: 10,
        color: AppStyle.errorColor
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
});

export default LoginScreen;
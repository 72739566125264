import React, { useState } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, ScrollView, TextInput, Alert } from 'react-native';
import { fieldValidator } from '@utilities/formValidators'
import { useMutation } from '@apollo/client';
import { positiveObjectDiff } from 'nexto-utils/objects'

import { useAuth } from '@realm'
import { FloatingButton } from '@atoms'

import { ButtonText, SimpleText } from '@quarks';
import { PhoneCardInput } from '@organisms';
import { CardInput, autoCapitalize, LoadingModal } from '@molecules'
import { User } from '@api'
import { AppStyle } from '@style';

const UserInfoEditScreen = (props) => {

    const { user, loading, fetchCurrentUser } = useAuth();
    let [errors, setErrors] = useState({})

    const card = props.form
    let [form, setForm] = useState(props.form?.fields)
    let [state, setState] = useState({
        loading: false
    })

    const _onCompleted = async (fetch = true) => {
        if (fetch)
            await fetchCurrentUser()

        if (props.onCompleted) {
            props.onCompleted()
        }

        if (typeof card.onCompleted === 'function') {
            card.onCompleted(form)
        }
    }


    const [updateValues,
        { loading: mutationLoading,
            error
        }] = useMutation(card.mutation, {
            onCompleted: _onCompleted
        });

    const _onSave = async () => {

        setState({ ...state, loading: true })

        let error = { ...errors }
        Object.keys(form).forEach((field) => {
            error[field] = fieldValidator(field, form[field].value)
        })
        if (Object.keys(error).some((value) => typeof error[value] !== 'undefined')) {
            setErrors(error)
        } else {
            setErrors({})
            let cleanForm = {}
            Object.keys(form).forEach(key => {
                if (form[key].value) {
                    cleanForm[key] = form[key].value
                }
            })
            let change = false;
            Object.keys(cleanForm).forEach(key => {
                if (user[key] !== cleanForm[key]) {
                    change = true;
                }
            })
            if (!change) {
                await _onCompleted(false)
            } else {
                updateValues(User.updateValues(cleanForm, user))
                return
            }
        }
        setState({ ...state, loading: false })
    }

    const _onChangeText = (itemName) => (value) => {
        let tmp = { ...form }
        tmp[itemName].value = value
        setForm(tmp)
    }

    React.useEffect(() => {
        if (typeof error !== 'undefined' && props.onError) {
            props.onError(error)
        }
    }, [error, mutationLoading])

    const _renderItem = (item, key, index) => {
        if (key === 'phone') {
            return <PhoneCardInput
                key={index}
                value={item.value}
                onValueChange={_onChangeText(key)}
                errorText={errors[key]} />
        }

        return <CardInput
            key={index}
            style={{ marginBottom: 15, paddingTop: 10 }}
            title={item.title}
            icon={item.icon}
            autofocus
            noInput={item.noInput === true}
            picker={item.picker === true}
            pickerValue={item.value}
            pickerItems={item.pickerItems}
            textContentType={item.textContentType}
            autoCompleteType={item.autoCompleteType}
            onValueChange={_onChangeText(key)}
            onChangeText={_onChangeText(key)}
            errorText={errors[key]}
            inputText={item.value}
            keyboardType={item.keyboardType}
            autoCapitalize={autoCapitalize.none}
            returnKeyType={'next'}
        />
    }

    const updateList = () => {
        let tmpList = []
        let index = 0
        Object.keys(form).forEach((key) => {
            tmpList.push(_renderItem(form[key], key, index))
            index += 1
        })
        return tmpList
    }

    return (<ScrollView
        contentContainerStyle={{
            width: '90%',
            marginTop: 30,
            paddingLeft: 5, paddingRight: 5,
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor,
            justifyContent: 'space-around',
        }} style={{
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }}>
        <View style={{
            paddingBottom: 80
        }}>
            {updateList()}
        </View>
        <ButtonText
            text={'$button.save'}
            onPress={_onSave}
            loading={props.loading || loading || state.loading}
            type={'solid'}
            style={{
                width: '60%', bottom: 30, height: 50,
                maxWidth: 350
            }}
        />
    </ScrollView>
    )
};

export default UserInfoEditScreen;
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";
import { SUBMIT_RESPONSE_STATUS } from "_enums"
/* State shape
{
    details: product,
    list: [ product ],
}
*/

const reduceErrorForm = (state, action) => {
    console.log(action.errors)
    for (let key of Object.keys(action.errors)) {
        state[key] = action.errors[key]
    }

    return Object.assign({}, state)
}


const reduceSetForm = (state, action) => {
    let product = action.product

    if (product) {
        let newState = {
            id: product.objectId,
            name: product.name,
            description: product.description,
            price: product.price,
            amount: product.amount,
            amountUnit: product.amountUnit,
            stock: product.stock,
            images: product.images,
            locale: Object.keys(product.name)
        }

        return Object.assign({}, newState)
    }
    
    let newState = {
        id: undefined,
        name: {ca:undefined, es:undefined},
        description: {ca:undefined, es:undefined},
        price: undefined,
        amount: undefined,
        amountUnit: undefined,
        stock: undefined,
        images: [],
        locale: ['ca','es']
    }

    return Object.assign(state, newState)
}

const clearErrors = (state, action) => {
    return {
        name: {ca:undefined, es:undefined},
        description: {ca:undefined, es:undefined},
        price: undefined,
        amount: undefined,
        amountUnit: undefined,
        stock: undefined,
        tags: undefined,
        images: undefined,
    }
}

const defineStatus = (status) => (state, action) => {
    return status
}

const formReducer = createReducer(null)({
    [types.RESET_FORM]: reduceSetForm,
    [types.SET_PRODUCT]: reduceSetForm,
});

const formErrorReducer = createReducer(null)({
    [types.ON_SUBMIT_FAILURE]: reduceErrorForm,
    [types.RESET_FORM]: clearErrors,
});

const reduceStatus = createReducer(null)({
    [types.ON_SUBMIT_FAILURE]: defineStatus(SUBMIT_RESPONSE_STATUS.FAILURE),
    [types.ON_SUBMIT_SUCCESS]: defineStatus(SUBMIT_RESPONSE_STATUS.SUCCESS),
    [types.RESET_FORM]: defineStatus(SUBMIT_RESPONSE_STATUS.UNDEFINED),
});



export default combineReducers(
    {
        form: formReducer,
        errors: formErrorReducer,
        status: reduceStatus,
    }
);
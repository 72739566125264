import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";
import { REGISTER_STATUS } from '_enums'
import moment from "moment/min/moment-with-locales"

const handleRegister = createReducer({ done: false, form: {} })({
    [types.STORE_REGISTER_FORM]: (state, action) => {
        return {
            done: true,
            form: action.form
        }
    },
    [types.WAITING_VERIFICATION]: (state, action) => {

        return { ...state, status: REGISTER_STATUS.WAITING_VERIFICATION }
    },
})

const handleKycForm = createReducer({
    done: false, pending: false,
    form: {}
})({
    [types.STORE_KYC_FORM]: (state, action) => {
        let newState = Object.assign({}, state)
        newState.form = action.form
        return newState
    },
    [types.DONE_KYC_FORM]: (state, action) => {
        return {
            ...state,
            pending: false,
            done: true
        }
    },
    [types.PENDING_KYC_FORM]: (state, action) => {
        return {
            ...state,
            pending: true,
            done: false
        }
    },
    [types.RESET_KYC_FORM]: (state, action) => {
        return {
            form: {},
            pending: false,
            done: false
        }
    }
})

const handleCompanyForm = createReducer(
    {
        form: {}
    })(
        {
            [types.STORE_COMPANY_FORM]: (state, action) => {
                let newState = Object.assign({}, state)
                newState.form = action.form
                return newState
            }
        })


const handleQueries = createReducer({})(
    {
        [types.STORE_QUERY]: (state, action) => {
            let newState = Object.assign({}, state)
            newState[action.query] = {
                needsRefetch: false,
                date: new moment(),
                every: action.maxTime
            }
            return newState
        },
        [types.REFETCH_MAIN_QUERIES]: (state, action) => {
            let newState = Object.assign({}, state)

            let array = Object.keys(newState)
            for (let index = 0; index < array.length; index++) {
                if (action.force) {
                    newState[array[index]] = {
                        needsRefetch: true
                    }
                } else if (newState[array[index]] && newState[array[index]].date) {
                    let currentTime = new moment()
                    let passedTime = new moment(newState[array[index]].date)
                    if (moment.duration(passedTime.diff(currentTime)).asMinutes() > newState[array[index]].every) {
                        newState[array[index]] = {
                            needsRefetch: true,
                            date: currentTime
                        }
                    }
                }
            }

            return newState
        },
    })

const handleModals = createReducer({
    product: undefined
})({
    [types.PRODUCT_MODAL]: (state, action) => {
        return {
            ...state,
            product: action.show
        }
    },
    [types.ALERT_MODAL]: (state, action) => {

        if (action.show == false) {
            return {...state, alert: false}
        }

        return {
            ...state,
            alert: action.alert
        }
    },
    [types.USER_MODAL]: (state, action) => {
        return {
            ...state,
            user: action.show
        }
    },
})

const handleLanguage = createReducer('es')({
    [types.UPDATE_LANGUAGE]: (state, action) => {
        return action.language
    },
})

export default combineReducers({
    register: handleRegister,
    kycForm: handleKycForm,
    companyForm: handleCompanyForm,
    queries: handleQueries,
    modals: handleModals,
    language: handleLanguage
});
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    details: product,
    list: [ product ],
}
*/

const reduceList = ( state, action ) => {
    if (typeof action.result != 'undefined') {
        return Object.assign([], action.result)
    }
    return state
}

const listReducer = createReducer( null )( {
    [ types.LIST_PRODUCTS ]: reduceList,
} );


export default combineReducers( 
    {
        list: listReducer,
    }
);
import React from 'react';

import { SimpleText } from '@quarks'
import { TextStyle } from "@style";

export const TermsEnglish = () => {
  return (<>
    <SimpleText style={{
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    }}>
      Nadius Terms and Conditions of Use
    </SimpleText>
    The online proximity product purchase and sale service named NADIUS (hereinafter referred to as the “Site”).
    <br /> <br />
                  Before registering on the Site, every User must read, accept and agree to comply with these General Conditions of Use (hereinafter, called "General Conditions"), the Privacy Policy (as indicated in the definitions, which will also be updated ), as well as the other mandatory provisions of the Site.
    <h5 className="mt-3 mb-16 " data-reveal-delay="200">
      1. Objective
                  </h5>
                  1.1 These General Conditions regulate the relations between NADIUS and the Users or Visitors.
    <br /> <br />
                  1.2 You can contact NADIUS by filling in the form by clicking here.
    <br /> <br />
                  1.3 The Site offers the User the opportunity to participate in the sale and/or purchase of items, and to use the other Additional Services offered by the Site.
    <br /> <br />
                  1.4 The Services are not intended for minors. In case of use of these services by a minor, their parent or guardian (adult) must previously register and supervise said use.
    <br /> <br />
                  All Sellers must be professional in nature and may only carry out sales actions if NADIUS authorizes such action.
    <br /> <br />
                  1.5 When registering on the Site, the User must indicate their name, email address and password (explicitly), to allow the Site to identify the User each time they access it. NADIUS may request, for security reasons, that certain confirmations be made in relation to your account. Such confirmations, among others, may consist of confirmation through your telephone number; the provision of proof of ownership of the bank card; the provision of a one-time PIN code or any other confirmation that NADIUS may enter at its sole discretion. All data collected as a result of said confirmation will be processed following our Privacy Policy, which is part of the contractual agreement between NADIUS and the User.
    <br /> <br />
                  NADIUS may, in a fully discretionary manner, modify the General Conditions in order to:
    <br /> <br />
                  a) adapt the General Conditions to the legislative or regulatory provisions in force or in the process of adoption;
    <br /> <br />
                  b) adapt the General Conditions to any resolution issued by a Court, consumer agency or any competent authority, which affects the content of the Site;
    <br /> <br />
                  c) rectify any errors (eg, spelling errors or other types of errors that do not alter the meaning of existing phrases) not detected so far.
    <br /> <br />
                   d) make these conditions clearer or more impartial;
    <br /> <br />
                   e) reflect changes and developments in the management of our business activity;
    <br /> <br />
                   f) reflect changes in market conditions or customary industry practices.
    <br /> <br />
                  Within the limits of the applicable laws, in case of modification of the General Conditions not foreseen in the three previous sections, (including the modification in the Price List, for example, to add new services) the Users will be informed by notification in the Site fifteen (15) days before the modification becomes effective or, in the event of a more substantial modification (eg, modifications that increase the obligations of the Users or affect the main functionalities of the platform), through a email sent to the email address provided at the time of registration four (4) weeks before the modification is effective. If you are not satisfied with any of the proposed modifications, you may end your relationship with NADIUS and close your NADIUS account, as described in section 15.3. If you continue to use the Site after the periods indicated above, it will be considered that you have accepted the modification and the Services will be provided in accordance with the modified General Conditions. Any modification of the General Conditions published on the Site will be applied immediately to Visitors.
    <br /> <br />
                  1.6 The User is reminded that the Internet is not a secure network. The User acknowledges having been fully informed about the low reliability of the Internet, especially about the lack of security in the transmission of data and the non-guarantee of performance regarding the volume and speed of data transmission. We try to keep NADIUS as safe as possible. However, the Internet is not a secure network and the reliability of the system could be interrupted regardless of the will of NADIUS, NADIUS will not be responsible for unpredictable events such as lack of security in the transmission of data or the guarantee of performance regarding the volume and speed of data transmission. Under these conditions, it is up to the User to adopt adequate measures to protect their own data and / or software, especially against the infection of viruses that circulate on the Internet.
    <br /> <br />
    <h5 className="mt-3 mb-16 " data-reveal-delay="200">
      2. Definitions
    </h5>
    <p style={{ fontWeight: 'bold' }}> Buyer <p style={{ fontWeight: 'normal' }}> means any User who buys or wishes to buy one or more items on the Site; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Articles <p style={{ fontWeight: 'normal' }}> means the goods / articles whose advertising is not prohibited by national legislation, and that the User authorized by NADIUS advertises in the corresponding Catalog of the Site with the aim of selling or donating them; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Catalog <p style={{ fontWeight: 'normal' }}> means the electronic catalog in which the same type of articles is advertised, it being understood that all Users must comply with the catalog rules established by NADIUS; </p> </p>

    <p style={{ fontWeight: 'bold' }}> User Account or Account <p style={{ fontWeight: 'normal' }}> means the User's registration on the Site; the account created contains personal data; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Accounts and Social Networks <p style={{ fontWeight: 'normal' }}> means the accounts of Facebook, Google+, Twitter, Instagram, Pinterest, Youtube and other networks social networks in which information about the Site and the Services it offers is provided, sharing the content published by Users; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Receipt Confirmation <p style={{ fontWeight: 'normal' }}> means the confirmation of receipt of the item by the Buyer or the User; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Price List <p style={{ fontWeight: 'normal' }}> means the document that shows the prices of NADIUS's Payment Services; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Private messages <p style={{ fontWeight: 'normal' }}> means communication by means of the exchange of private messages between Users through the messaging system of the Site that only the Users in question will be able to consult; </p> </p>

    <p style={{ fontWeight: 'bold' }}> News <p style={{ fontWeight: 'normal' }}> means short and long-term offers, competitions or new Services published on the Site; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Privacy Policy <p style={{ fontWeight: 'normal' }}> means the document that defines how NADIUS collects, uses, stores and transfers personal data and other important information about a Visitor or a User; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Services <p style={{ fontWeight: 'normal' }}> means all services, including Main Services and Additional Services; </p> </ p >

    <p style={{ fontWeight: 'bold' }}> Additional Services <p style={{ fontWeight: 'normal' }}> means the additional paid services provided by NADIUS and published on the Site (as shown in the Price List), such as, for example, Identity Verification Services, which are not considered main services and which will be subject to independent general conditions of use; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Integrated Payment Service <p style={{ fontWeight: 'normal' }}> means the Online Payment Service for Items accessible to the Buyer on the Site. Payment services are provided by the NADIUS partner; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Main Services <p style={{ fontWeight: 'normal' }}> means the option that NADIUS offers to Users and Visitors, especially, but without limitation, to advertise the Items by Sellers in the corresponding Catalog, examine the Items, participate directly in sales and communicate with each other privately; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Site <p style={{ fontWeight: 'normal' }}> means the NADIUS website, as well as the NADIUS mobile applications downloaded to smartphones or tablets; </p> </p>

    <p style={{ fontWeight: 'bold' }}> Transaction <p style={{ fontWeight: 'normal' }}> means all transactions that transfer and from an Item from one User to another, including sales contracts formalized directly between the Buyer and the Seller and any other transaction carried out between Users; </p> </p>

    <p style={{ fontWeight: 'bold' }}> User <p style={{ fontWeight: 'normal' }}> means any person or company who, after registering on the Site, is authorized to use all the Services of the Site to meet your personal or business needs. </p> </p>

    <p style={{ fontWeight: 'bold' }}> Seller <p style={{ fontWeight: 'normal' }}> means any User authorized by NADIUS who advertises one or more Articles in the corresponding Catalog of the Site; </ p> </p>

    <p style={{ fontWeight: 'bold' }}> Visitor <p style={{ fontWeight: 'normal' }}> means any person not registered on the Site who can use the Site without registering, in accordance with the Terms General. </p> </p>

    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      3. SERVICES
                   </h4>
                   3.1 NADIUS is an online intermediary for hosting services that offers Buyers and Sellers a virtual place of sale, called the Site or Application and a systematized Catalog. Sellers have the opportunity to advertise their Items in the corresponding Catalog of the Site and Buyers have the opportunity to buy any Item that appears in the Catalog. As well as, Users can communicate by sending Private Messages and use other Additional Services offered by the Site. NADIUS does not participate in any Transaction between Users. Under no circumstances will NADIUS buy, sell or exchange the Articles presented on the Site or claim to do so. NADIUS offers Services that facilitate Transactions.
    <br /><br />
                   3.2 The General Conditions, the Privacy Policy and the other regulations published on the NADIUS Site are applicable to the Users and Visitors of NADIUS's Social Network Accounts, and must comply with them.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      4. ANNOUNCEMENT OF ARTICLES
                   </h4>
                   4.1 As a prerequisite:
    <br /><br />
                  Only Users verified and approved by NADIUS will be eligible to post Articles. <br />
                   Only the Items stipulated in the Catalog rules can be listed on the Site. <br />
                   The Seller must ensure that its Items comply with the Catalog standards. Some article categories cannot be uploaded. <br />
                   The Seller shall be the owner of these Items and have the right to sell or donate them. <br />
                   The sale, use or possession of said Articles must not violate the rights of third parties, must respect intellectual property rights and must not violate any local, national or international laws or regulations. <br />
                   The Item shall, depending on the nature of the Transaction, meet the minimum market requirements for the type of Item unless Buyer and Seller agree otherwise. <br />
    <br /><br />
                  4.2 The Seller, when advertising an Item in the Catalog, must complete a questionnaire in relation to the listing of the Item. The Seller shall describe the Item as precisely as possible, indicate any possible defects and establish the price of the Item. By putting the Item for sale, the Seller declares that it complies with the description provided and that this description is exhaustive. The listing of Items in the Site Catalog is free. Sellers may choose to pay for Additional Services, for example, to improve the visibility of their items.
    <br /> <br />
                  4.3 The Article advertised in the Catalog will not only include a description but will also include a photograph. The photograph will be placed next to the description of the Item. At least one good quality photograph must be downloaded when posting the Item (the use of photos downloaded from the Internet and / or photos of a similar Item is prohibited). The photograph must reflect the actual quality and external appearance of the Item, as well as the presence of any defect in it.
    <br /> <br />
                  4.4 The number of Articles advertised in the Site Catalog is unlimited. It is forbidden to advertise the same Article more than once.
    <br /> <br />
                  4.5 The Seller who has put an Item up for sale may withdraw the Item or modify its price at any time, but without affecting past sales or sales in the process.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      5. SALE OF ITEMS
                  </h4>
                  5.1 The fact of putting a Catalog Item up for sale will constitute an offer to sell the Item in question by the Seller, which may be accepted by a Buyer.
    <br /> <br />
                  5.2 Without an agreement established by NADIUS and the Seller, the Seller will be solely responsible for arranging the shipment of the Item, with no option for NADIUS to provide assistance.
    <br /> <br />
                  5.3 In the case of a sale, the Buyer acknowledges that the acceptance of the offer has been made taking into account the description of the Item that is the object of sale and that represents a firm commitment to enter into a contract with the Seller according to the conditions of the offer.
    <br /> <br />
                  5.4 The Seller is fully responsible for the condition of the products and that they comply with the necessary regulations for their sale.
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      6. INTEGRATED PAYMENT SERVICE
                  </h4>
    <br /> <br />
                  6.1 In the event that Integrated Payment Services are used, payment will be facilitated and guaranteed. Therefore, by virtue of said additional Buyer Protection, NADIUS collects the commissions corresponding to said services. In this case, for each Transaction, the Seller will be charged a commission that will be added to the purchase price for the use of this Service offered by NADIUS. Said commission will be established by NADIUS and the Seller in the authorization process as a Seller on the Site.
    <br /> <br />
                  6.2 As part of the Integrated Payment Service, the Buyer must pay in advance for the Item (s) purchased from the Seller by credit or debit card, or any other payment method that may be introduced in the future, using the Integrated Payment Service available on the Site.
    <br /> <br />
                  6.3 The amounts paid by the Buyer will be held by a financial services provider selected by NADIUS in an electronic wallet that will function as an escrow account until (i) the Buyer confirms to NADIUS that the Item has been received, that it complies with the offered and that you are satisfied about it or (ii) one week after the date on which the Item was to be delivered. The sale transaction will be completed (i) once the Buyer confirms to NADIUS that it is satisfied with the Item and that it complies with what was offered, or (ii) after the expiration of the controversy period, or (iii) after the resolution of a controversy, if any. Upon completion of the transaction the funds will be immediately transferred to the Seller's e-wallet. The money from the User's electronic wallet may be withdrawn at any time from his bank account, without any withdrawal fee, regardless of the amount.
    <br /> <br />
                  6.4 If suspicions of fraud of any kind arise with respect to a User, NADIUS will immediately suspend the account of the User in question, as well as the transactions in progress, pending the results of the investigation that is carried out.
    <br /> <br />
                  6.5 In the case of any problem reported to NADIUS by the provider responsible for the Integrated Payment Service, NADIUS will immediately suspend the account of the User in question, as well as the transactions in progress, pending the results of the investigation carried out. cape.
    <br /> <br />
                  6.6 NADIUS reserves the right to suspend a User's account if the User raises a dispute through an external payment service provider and NADIUS has legitimate reasons to do so (for example, in the event of non-compliance with applicable legislation and / or or of these General Conditions, and / or in the event that improper use of the Site is probable).
    <br /> <br />
                  6.7 NADIUS has an external service provider to process the payment and archive the information regarding the credit or debit card. In order to be able to use the Integrated Payment Service, the Buyer declares that he accepts the general conditions of this provider. The buyer will be solely responsible for the transmission of information related to the credit or debit card and in accordance with these general conditions, NADIUS is exonerated of all responsibility.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      7. Integrated Payment Service / Refund / Acceptance of items / Buyer Protection
                  </h4>
                  7.1 The Buyer using the Integrated Payment Service will be eligible for a refund under Buyer Protection if the conditions described below are met:
    <br /> <br />
                  7.1.1 If the item does not substantially comply with the description offered by the Seller, even if the item has been damaged during shipping, the Buyer will have five (5) business days, counted from the date of delivery of the item, to inform NADIUS by clicking on the incident report section in the order details. This will suspend the Transaction and NADIUS will retain the entire purchase price. The Buyer and the Seller shall have the right to resolve the situation on their own and to inform NADIUS of their joint decision. In such case, NADIUS will undertake to abide by said decision and to reimburse the Buyer or the Seller. If the Buyer and the Seller do not reach an agreement directly, or if they do not wish to resolve their situation directly, either of them may raise the matter to NADIUS. Once NADIUS has analyzed the situation and in the event that NADIUS considers that, indeed, the item is not substantially as described in the catalog, NADIUS will cancel the Transaction informing the third party financial services provider that manages the escrow account. to refund the Buyer once the item in question has been returned to the Seller. The Buyer will receive a refund for the full purchase price. Please note that NADIUS does not offer return shipping services and that the Buyer must pay for the return shipping, unless otherwise agreed with the Seller. Exceptions to the obligation to return the item may be given specifically when there is strong evidence that the item is a counterfeit.
    <br /> <br />
                  7.1.2 If the Buyer did not receive an item, he must inform NADIUS indicating the problem by clicking on the incident report section in the order details that suspends the Transaction within a period of five (5) days from the date of the date mentioned to the Buyer on which the Item was expected to be delivered. In this case, and since the Seller is fully responsible for the Shipping process, the Seller will be informed and he has to take charge of managing said situation. In case of not being resolved within a period of one (1) week, the Buyer will be refunded the full amount and the payment processing commission that NADIUS may have had at the time of processing the payment during the purchase process will be deducted from Seller.
    <br /> <br />
                  7.1.3 If the Buyer does not contact NADIUS within the five (5) days stipulated in sections 7.1.1 and 7.1.2, or confirms on the Site that the item received was acceptable (during said period of 5 days ), the Transaction will be deemed complete and Buyer's refund protection will end. In such case, the Buyer will not be entitled to any refund from NADIUS.
    <br /> <br />
                  7.1.4 In the event that the item conforms to the description offered by the Seller, the Buyer will not be able to request a refund through NADIUS and NADIUS will complete the Transaction. Once the Transaction has been completed and the money held in guarantee has been made available to the Seller, the Buyer may attempt to negotiate directly with the Seller for the return of the item (eg agreement regarding reimbursement, shipping costs of the return, etc.). Note that in such a case, the Seller may also refuse to cooperate with the Buyer on the grounds that the Seller has fulfilled its obligations relating to the Transaction.
    <br /> <br />
                  7.2 If the Buyer does not use the Integrated Payment Service:
    <br /> <br />
                  7.2.1 In this case, NADIUS will not charge any commission, but neither will it guarantee the security of the payments made. NADIUS will not offer assistance to the Buyer or the Seller. The Buyer and the Seller will be solely responsible for the implementation of the Transaction and must negotiate all the details of the Transaction directly by themselves (eg payment terms, shipping, item return conditions, lost packages, etc.) .
    <br /> <br />
                  7.2.2 In the event of improper conduct on the part of the Buyer or the Seller, NADIUS may offer assistance to the affected party. NADIUS will also collaborate with government institutions.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      8. PAYMENT
                  </h4>
                  8.1 NADIUS is authorized to collect the amounts as part of the Services provided in accordance with the prices indicated in the Price List. The User accepts that NADIUS is authorized to modify the Price List according to the conditions of section 1.5 above. If the User does not agree with any of the proposed modifications, they may choose to end their relationship with NADIUS and close their NADIUS account, as described in section 14.3. Modifications in Prices will not apply to Transactions that are in progress (especially those for which the Buyer has already made the payment).
    <br /> <br />
                  8.2 All prices are expressed in euros (EUR) and include all taxes.
    <br /> <br />
                  8.3 In the event that a Transaction is made through the Integrated Payment Service, the Buyer's payment will be made by virtual or bank card, or by any other payment method that is introduced at the time, in the integrated payment interface of the Site. If the Integrated Payment is not used, the payment will be made by the method established between the Seller and the Buyer.
    <br /> <br />
                  8.4 In the event that a Transaction is carried out through the Integrated Payment Service, the Buyer will be charged the total amount (price of the item (s) and the Seller will collect the amount owed to him once the receipt of the item by party is confirmed of the Buyer minus the commission established by NADIUS, at the time of authorization as Seller If the Buyer does not receive the item or is not satisfied with it, a credit will be made to his account.
    <br /> <br />
                  8.5 In the event that a transaction is carried out without the Integrated Payment Service, the Buyer and the Seller must agree on the payment conditions as described in section 7.2.1 above. In such case, NADIUS will not charge any commission on the Transaction.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      9. INTERACTION AND MESSAGES ON THE SITE
                  </h4>
                  9.1 Private messages
    <br /> <br />
                  The main purpose of the exchange of private messages is the exchange of information between Users, regarding an order or Article. Consequently, if a User or a Visitor sends Private Messages to another User, they must ensure that they do not send:
    <br /> <br />
                      messages or information that include advertising; <br />
                      spam or content that spreads viruses or Trojans; <br />
                      mass sending messages of any kind (if a message is sent to more than five Users or if the same message is copied and sent to Users who have not requested to receive it); <br />
                      messages that contain texts contrary to the standards of morality and public order, insulting or defamatory messages, and also those that are considered incompatible with these General Conditions and the interests of the Users; <br />
                      messages of an illicit nature or intended to cause damage to other Users and / or the Site. <br />
    <br /> <br />
                  In the use of algorithms, NADIUS uses automated software that allows it to detect any offer contrary to the standards of morality or public order, or those that are inappropriate, insulting or defamatory and communicated by a User through Private Messages. In the event that a Private Message contains any of the aforementioned conditions is detected by the automated software, the Private Message may be automatically blocked and / or hidden from the User who receives the Private Message. The User who is sending the Private Message that has been blocked and / or hidden, will be warned about their obligation to comply with the General Conditions. NADIUS has access to the content of Private Messages, including those that are blocked and / or hidden by automated software.
    <br /> <br />
                  9.2 Exchange of evaluation between Users
    <br /> <br />
                  A Buyer has the right to write reviews about a Seller only if a Transaction has taken place between them. No remuneration will be offered to Buyers or NADIUS in exchange for their online ratings.
    <br /> <br />
                  A Buyer's evaluation of a Seller must always be fair and honest. Lies and insults are prohibited.
    <br /> <br />
                  NADIUS does not review the ratings before they are published by the Users.
    <br /> <br />
                  If the assessment obtained is inappropriate or abusive, Users must notify NADIUS.
    <br /> <br />
                  NADIUS is authorized to remove from the Site any evaluation that infringes these General Conditions or the rights of other Users, including Users who do not have the right to write evaluations under the terms of these General Conditions.
    <br /> <br />
                  NADIUS, after identifying the User who has not respected these rules, may make use of their rights to block the account of the User in question, totally or partially.
    <br /> <br />
                  Visitors or Sellers have the right to write reviews about Sellers.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      10. LIABILITY
                  </h4>
                  10.1 Each Seller is fully responsible in his capacity as editor, for all the information he publishes on the Site and, if applicable, for the Articles that they consequently advertise and / or sell. In particular, it is stated that if a Seller publishes Articles in the corresponding Catalog, the Seller acknowledges and agrees to be fully responsible for the download of the Article in the corresponding Catalog, its description, the accuracy of the other data provided, the communication with Buyers and, in general, with the Transactions carried out with Buyers and the controversies that may arise from said Transactions (hereinafter referred to as the “Content”).
    <br /> <br />
                  10.2 In this sense, the Visitor and the User must respect all applicable regulations. Users and Visitors must refrain from: (i) violating the rights of third parties, (ii) violating intellectual property rights or advertising counterfeit items, (iii) inciting offenses of crime, discrimination, hatred or racial violence, by reason of origin ethnicity or nationality, (iv) communicate incorrect or confidential information, (v) make defamatory offers, (vi) take actions that could endanger minors, (vi) publish the personal data of other people or violate privacy rights or (vii) usurp the identity of others.
    <br /> <br />
                  10.3 In the event that the Content does not respect the applicable rules in accordance with article 10.2 or, in general, a User or Visitor does not respect these General Conditions, the User or Visitor agrees to be solely responsible for direct and / or indirect damages with respect to a third party or NADIUS. Consequently, the User or Visitor acknowledges and accepts that, as host, NADIUS will not under any circumstances carry out any verification of the Content nor will it be responsible for such losses unless (i) it has been informed of the existence of illegal content in the sense of current legislation, in accordance with the procedure described in section 10.4, and (ii) and has not acted diligently to remove it from the Web. Specifically, NADIUS will not be responsible for: (i) actions or omissions of Visitors and Users, (ii) the information published on the Site by Users and Visitors, its topics, its precision, its completeness and / or its compliance with the rules applicable, or (iii) the quality and quantity of Items that they sell or buy through the use of the Site, or their conformity with the description provided.
    <br /> <br />
                  Catalogue
    <br /> <br />
                  10.4 NADIUS is not responsible for the Online Content. The Sellers will be responsible for the Content uploaded online and NADIUS may remove content from the Sellers that have been duly notified in accordance with the applicable legislation and that violate said legislation and / or the policies of NADIUS. We may also take action against the Seller's account. If a User or Visitor discovers Content that advocates crimes against humanity, incites racial hatred and / or violence or refers to child pornography, NADIUS must be informed immediately:
    <br /> <br />
                      through the information procedure described here; <br />
                      or by writing an email to the address hola@nadius.cat; <br />
    <br /> <br />
                  In all these cases, NADIUS will immediately suspend the account of the User in question, as well as the transactions in progress, pending the results of the investigation to be carried out.
    <br /> <br />
                  If the User considers that any Content is likely to affect their rights or those of a third party (falsification, insult, violation of privacy), the User can notify NADIUS:
    <br /> <br />
                      through the information procedure described here; <br />
                      or by writing an email to the address hola@nadius.cat; <br />
    <br /> <br />
                  More generally, Users should refrain from uploading content that is dishonest, false or inappropriate. Users must act and use the platform in good faith.
    <br /> <br />
                  10.5 Once the Buyer has made the purchase of the Items displayed by the Seller, the Seller is responsible for delivering the item correctly and within the appropriate time to the Buyer. In any case, if a discrepancy arises between the Buyer and the Seller regarding the delivery of the Item, the Seller must prove that the Item has been shipped (this can be done by means of the tracking number, an invoice or a company document express courier).
    <br /> <br />
                  10.6 In the case of a Transaction carried out without the Integrated Payment System offered by NADIUS, the Buyer is responsible for the payment of the requested Item correctly and within the appropriate period, in accordance with the conditions communicated by the Seller or according to the conditions jointly agreed.
    <br /> <br />
                  10.7 In the event that a Transaction is carried out without the Integrated Payment Service offered by NADIUS, the User accepts that NADIUS is not responsible for disputes that occur between Buyers and Sellers, that it will not offer assistance and that it will not get involved. in its resolution. Such disputes may be resolved in accordance with applicable national law, unless the parties have jointly decided that the laws of another country shall apply.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      11. OBLIGATIONS OF THE USER
                  </h4>
                  11.1 The User agrees:
    <br /> <br />
                      when registering on the Site, to provide truthful information about yourself, specifically your username and email address; <br />
                      to register on the Site only once by not creating more than one Account, In the event that a third party has access to your Account, a new one may be created as long as you have informed NADIUS about it and blocked the previous Account; <br />
                      to refrain from using the Site and / or the Services in order to carry out dishonest actions or transactions or carry out fraudulent actions; <br />
                      when using the Site, to provide objective, correct, exhaustive and detailed information about the sale that is intended to be made; <br />
                      to ensure that the price proposed for the sale of the Items, as well as other information regarding them, are correct; <br />
                      not to copy the information that appears on the Site and is published by NADIUS or another User on the Site, or use it dishonestly. <br />
    <br /> <br />
                  11.2 The User and the Visitor undertake, when using the Site, to provide information and data, especially electronic data (files, private messages, etc.) that:
    <br />
                      are not misleading or incorrect; <br />
                      do not promote the acquisition of goods whose sale is prohibited or restricted; <br />
                      do not use photographs (i) for which intellectual property rights are not held (in general, these are photographs found on the Internet), or (ii) that contain links to other websites; <br />
                      do not use photographs (i) in which other persons besides themselves are visible, unless the persons have given their consent for the publication of these photographs; or (ii) that are or may be considered erotic or pornographic photographs, contrary to moral standards or public order; <br />
                      do not offer for sale / purchase / exchange or transfer Articles that have been manufactured in violation of the intellectual property rights of the owners of the registered trademark; <br />
                      do not violate property rights or personal rights that are not property rights of third parties (including intellectual property rights); <br />
                      do not violate the applicable regulations; <br />
                      are not contrary to public order or moral standards; do not contain viruses, programs or files (i) likely to disturb the normal operation of the Site and / or the Services, (ii) that are located on the Users' computers and, therefore, that generate losses to the User and that deprive the User of the use of the Site, the Services and the User's computer; <br />
                      do not offer links to other websites that provide services similar to those offered by the Site. <br />
    <br /> <br />
                  11.3 The User and the Visitor agree to refrain from simultaneously collecting, maintaining, transmitting to third parties, making public, publishing or disclosing, data of the Users of the Site or data on the actions of the Users of the Site, including Transactions, their number, type, price etc., if this information is received illegally or as a result of a dishonest action or an omission (except in relation to statistical information that is published on the Site). The User and the Visitor also agree not to collect, simultaneously maintain, transmit to third parties, make public, publish or disclose information that appears on the Site, if it is likely to affect the rights of other Users. This restriction does not apply to the "sharing" function that exists on the Site and gives Users the option to share public information available on the Site and on Social Media Accounts, to be sent by email.
    <br /> <br />
                  11.4 Within the limits of the applicable legislation, NADIUS is not responsible for the behavior of a User when he or she uses the Site or the Services. In particular, NADIUS is not responsible for a defect or failure in the Transactions by the Users.
    <br /> <br />
                  11.5 The User agrees to maintain the confidentiality of their login details and their password, without taking into account the persons who have been authorized by the User to use their login details.
    <br /> <br />
                  11.6 The User agrees to immediately update on the Site the information that is no longer accurate due to changes in their data (especially that provided during their registration on the Site), as well as the information on the Articles advertised in the Catalog and your state.
    <br /> <br />
                  11.7 By using the Site, the User and the Visitor declare:
    <br /> <br />
                  who are at least 18 years of age (otherwise their parent (s) or legal guardian will need to register instead), who use all the Services on the Site to meet their personal needs without being associated with any activities professional, and that they have full capacity and all rights to carry out Transactions on the Site; <br />
                      that accept and comply with the General Conditions and any other contractual document, such as the Privacy Policy, the Security Policy, the Cookies Policy and other rules of the Site; <br />
                      that they understand that they are fully responsible for the Transactions carried out; <br />
                      that they understand that, after ordering an Item, they undertake to purchase it, and that failure to carry out this Transaction may oblige them to reimburse the losses suffered by the Seller; <br />
                      who understand that they must pay NADIUS if they decide to use the Payment Services in accordance with the charges and the procedure indicated in the Price List. <br />
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      12. RIGHTS AND OBLIGATIONS OF NADIUS
                  </h4>
                  12.1 NADIUS may totally or partially prohibit the use of the Site (establishing the applicable limitation conditions) or terminate the User or Visitor's option to use the Site, in particular by removing the Content posted on the Site, canceling the User's account and preventing the User who re-registers on the Site or deprives the Visitor of access to the Site, subject to NADIUS sending a prior official notification to this User and Visitor, if he or she:
    <br /> <br />
                      infringes or does not comply with the obligations derived from the General Conditions, the Privacy Policy or other rules of the Site; <br />
                      provides incorrect, misleading and / or incomplete information according to the General Conditions, at the time of your registration on the Site or when using the Site; <br />
                      knowingly and knowingly discloses incorrect information on the Site, insults others or acts inappropriately; <br />
                      uses the identity of another User or acts dishonestly; <br />
                      Log in from the same IP address or from the same computer that was blocked for committing violations; or <br />
                      has received at least three warnings from NADIUS. <br />
    <br /> <br />
                  12.2 Based on the same circumstances as those described above, NADIUS may, by sending an official notification to the User / Visitor in question, totally or partially limit the User's Account or the Visitor's access. Partial blocking means that the User will not be able to advertise articles in the Catalog or communicate with other Users. Total blocking means that the User's Account will be blocked and / or canceled, blocking the option to use the Site from the User's device. The User must know that, after the total blocking, he will not have the right to re-register on the Site. Cancellation of the User's Account does not mean that NADIUS deletes all relevant information from the Account, in particular, the User's personal data, insofar as they can be saved to find out more about the circumstances surrounding NADIUS's decision to cancel. the User's Account, and to transfer this data to the institutions and authorities responsible for applying the legislation, in accordance with the Privacy Policy.
    <br /> <br />
                  12.3 NEXT, after having been informed in accordance with article
                  10.5 above, you will remove the Dishonest Content. NADIUS may at any time remove from the Site the Articles announced by the User in the Catalog or any other information provided by the User in the event of a violation of the General Conditions, applicable laws or if it affects morality or public order.
    <br /> <br />
                  12.4 If the User or the Visitor does not accept this right of NADIUS, they must refrain from using the Site. NADIUS can investigate any violation of the General Conditions and inform the competent institutions and authorities, responsible for applying the legislation on the matter.
    <br /> <br />
                  12.5 NADIUS may at any time reorganize the Catalog, the ad spaces or other information on the Articles, subject to the fact that these changes do not modify the Content provided by the User, in order to facilitate the use of the Site. NADIUS can publish News, by publishing the description, instructions or associated rules.
    <br /> <br />
                  12.6 NADIUS may at any time publish short and long-term offers on the Site, contests, games or lotteries that comply with national legislation, to offer new Services and, as a result, these General Conditions will not be modified. Information regarding offers, contests, games and lotteries that comply with national legislation will be provided on the Site. In the event of inconsistency between the General Conditions and the special conditions published on the site (such as rules, instructions and descriptions regarding News), the latter will prevail.
    <br /> <br />
                  12.7 NADIUS may terminate, suspend or assign the operation of the Site to a third party, subject to notification to the Users and Visitors of the Site, respecting a notification period of thirty (30) days.
    <br /> <br />
                  12.8 NADIUS may apply a "pause" status to any User who has been inactive for a reasonably long period (eg 60 days or more). In this case, the items offered for sale by said User will be hidden from other Users. NADIUS reserves the right to remove the items offered for sale by any User in the event that such items have not been sold for a reasonably long period of time.
    <br /> <br />
                  Before the application of the "pause" status and / or the elimination of any article, NADIUS will send a notification to the User, by which it will inform the User of the planned change for its status and / or the articles announced in the catalog and will allow the User reject such change. The User must inform NADIUS of their rejection of the change within the notification period indicated in the communication. If the User does not express her rejection of the change, it will be considered that she has accepted the change by NADIUS of her status and / or the elimination of the articles advertised.
    <br /> <br />
    <h4 className="mt-3 mb-16" data-reveal-delay="200">
      13. PERSONAL DATA
                  </h4>
                  The Privacy Policy is available at the link https://productor.nadius.cat/privacy that describes what Personal Data of the User NADIUS collects and for what purposes NADIUS processes said data.
    <br /> <br />
                  14 FINAL PROVISIONS
    <br /> <br />
                  14.1 The General Conditions are governed by the User's national legislation.
    <br /> <br />
                  14.2 In the event of disagreement between NADIUS and the user, the user can:
    <br /> <br />
                      First, send a written complaint to the support team using the contact form. <br />
                      In the absence of a response from the aforementioned department within a reasonable period of (1) month, or if the user is not satisfied with the response obtained, the user and NADIUS will try to resolve the case through an amicable agreement, before raising any discrepancy. before the corresponding court of jurisdiction. <br />
    <br /> <br />
                      Users may resort to other dispute resolution methods such as consumer mediation, although NADIUS is not committed or obligated to use such methods to resolve disputes with users. Also, disputes between Buyers and Sellers do not apply to consumer mediator organizations. <br />
                      In Spain, the Municipal Consumer Office is available. <br />
                      The European Commission has developed a platform for the resolution of disputes to collect possible complaints from consumers when making an online purchase and to direct it to the competent national mediation institutions. This platform is available here: http://ec.europa.eu/consumers/odr. <br />
    <br /> <br />
                  14.3 The User may terminate their relationship with NADIUS at any time and with immediate effect after being released from all their obligations and after canceling their Account on the Site. The User may end their relationship with NADIUS in writing or by email. NADIUS may terminate its relationship with the User by means of a notification given with 30 days' notice for the following reasons: (1) NADIUS's decision to stop providing the Services, (2) NADIUS's decision to stop performing your activities, (3) NADIUS's decision to change the administrator of your activities on the Site in whole or in part. NADIUS may terminate its relationship with the User, with immediate effect, in the event of a violation of the law or the General Conditions by the User.
    <br /> <br />
                  14.4 NADIUS holds all intellectual property rights with respect to the Site, its system (the Catalog, its transmission, etc.), the design of the Site and the software used by the Site, including trademarks and domain names.
    <br /> <br />
                  14.5 The fact of providing information or data - especially photographs on the Site, that is, the "Content" - will mean the concession hereby, by the Users who use the Site or the Services, to NADIUS and to any company belonging to to the NADIUS Group of a non-exclusive universal license for the use of the Content throughout the world, which will be applicable during the period of validity of the applicable rights, if any (and their possible extensions). Said license includes the right to use, copy, reproduce, display and adapt the Content. The User accepts that NADIUS may use said Content, in any medium known or unknown to date and, in particular, television, paper, the Internet (such as banners and articles, or place them on other websites) and social networks (Facebook, Twitter, Instagram etc.) for commercial, advertising and internal purposes of the NADIUS Group. The User will be solely responsible for the Content and confirms that he or she owns all rights to it. By uploading the Content to the Site, the User may prohibit the use of the Content in the manner and for the purposes indicated in this section, modifying the User Configuration and deactivating an option within the Privacy Policy configuration.
    <br /> <br />
                  14.6 All notifications, requests and other information between the User and NADIUS will be sent as indicated in the General Conditions through the contact form that appears on the Site, and to the User by email, to the email address that provided when registering on the Site.
    <br /> <br />
                  14.7 In no case will it be considered that there is an agency or franchise relationship between NADIUS and the Users by virtue of these General Conditions.
    <br /> <br />
                  14.8 NADIUS may, subject to the applicable data protection legislation, transfer and / or assign all the rights and obligations derived from these General Conditions, to third parties, especially in the case of transferring a sector of activity, a merger for the founding of a new company, a merger by absorption, spin-off or any change in control that affects NADIUS. Such a transfer would release NADIUS in the future. In the event that NADIUS transfers and / or assigns these rights and obligations derived from these General Conditions to third parties, the User will have the right to immediately terminate their relations with NADIUS.
    <br /> <br />
                  14.9 Within the framework of the applicable Spanish legislation, and in order to transmit honest, clear and transparent information to our users, we ask you to go to the portals of the Spanish Tax and Social Security Authorities, whose addresses are listed below to Know what tax and social obligations you are subject to as part of the transactions you will carry out on our site:
    <br /> <br />
                      Tax obligations; <br />
                      Social obligations. <br />
    <br />
                  14.10 Users can express their objections regarding the access, use or operation of the Site or the Services by notifying NADIUS. Notifications must be submitted to NADIUS using the form that appears on the Site. Please contact this address for all legal matters: hola@nadius.cat.
    <br /> <br />
  </>)
}

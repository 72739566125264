import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AppStyle } from '@style'


import PublicProfile from '_scenes/profile';
import AboutScreen from '_scenes/profile/about';
import FormScreen from '_scenes/profile/form';

const SettingsStack = createStackNavigator();

const baseOptions = {
    headerTintColor: AppStyle.textColor,
    headerStyle: {
        backgroundColor: AppStyle.mainColor,
        elevation: 0,
        shadowOpacity: 0
    },
    animationEnabled: true,
    headerShown:false
}


function PorfileNavigation() {
    return (
        <SettingsStack.Navigator
            screenOptions={({ route, navigation }) => ({
                title: `Nadius - ${`$root.screens.${route.name}`.localise()}`
            })}
            options={baseOptions}>
            
            <SettingsStack.Screen
                name="ProfileForm"
                component={FormScreen}
                options={baseOptions}
            />
        </SettingsStack.Navigator>
    );
}

export default PorfileNavigation;

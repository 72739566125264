
const es = {
    emptyField: "El campo no puede estar vacio",
    wrongDecimalFormat: "El campo no está en el formato adecuado: 00.00",
    invalidCharacters: "El campo contiene caracteres inválidos",
    invalidValue: "El valor introducido no es válido",
    invalidValueMin: "El valor introducido no es válido",
    invalidValueMax: "El valor introducido no es válido",
    imageNumberExceded: "Solo un maximo de 5 imagenes pueden ser añadidas.",
    noMatch: "La opción seleccionada no es valida",
    passwordIncorrectRequirement: "La contraseña tiene un formato invalido.\nDebe de tener minimo 6 carácteres con mínimo una mínuscula, mayúscula y un número.",
    passwordNoMatch: "No coincide",
    sellingZones: "Debes de seleccionar mínimo una zona de venta",
    invalidArrayMin: "No llega al mínimo requerido",
    invalidArrayMax: "Has sobrepasado el límite permitido",

    birthdayMinor: "Tienes que ser mayor de 18 años",
    deliveryDaysWrongValue: "Una selección o más són invalidas.",

    passwordMustContainLetters: "Debe contener una letra como mínimo",
    passwordMustContainNumbers: "Debe contener un numero como mínimo",
    passwordMustContainSymbols: "Debe contener un símbolo como mínimo",
    passwordMinLength: "Debe contener 6 carácteres o más",

    modalityUnSelected: "Debes de seleccionar una de las opciones",
    startAndEndTimeWrongOrder: "La primera hora tiene que ser antes que la última"
}

const ca = {
    emptyField: "El camp no pot estar buit",
    wrongDecimalFormat: "El camp no està en el format adequat: 00.00",
    invalidCharacters: "El camp conté caràcters invàlids",
    invalidValue: "El valor introduït no és vàlid",
    invalidValueMin: "El valor introduït no és vàlid",
    invalidValueMax: "El valor introduït no és vàlid",
    imageNumberExceded: "Només un màxim de 5 imatges poden ser afegides.",
    noMatch: "L'opció seleccionada no és vàlida",
    passwordIncorrectRequirement: "La contrasenya té un format vàlid. \n Ha de tenir mínim 6 caràcters amb mínim una minúscula, majúscula i un número.",
    passwordNoMatch: "No coincideix",
    sellingZones: "Has de seleccionar mínim una zona de venda",
    deliveryDaysWrongValue: "Hi ha una o més seleccions invàlides.",
    invalidArrayMin: "No arriba al mínim",
    invalidArrayMax: "Has sobrepassat el límit permés",

    birthdayMinor: "Has de ser major de 18 anys",

    passwordMustContainLetters: "Ha de contenir una lletra com a mínim",
    passwordMustContainNumbers: "Ha de contenir un número com a mínim",
    passwordMustContainSymbols: "Ha de contenir un símbol com a mínim",
    passwordMinLength: "Ha de contenir 6 caràcters o més",
    modalityUnSelected: "Has de seleccionar una de les opcions",
    startAndEndTimeWrongOrder: "La primera hora ha de ser abans que l'última"
}

export {
    es,
    ca
}
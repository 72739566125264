import React, { useState } from 'react';
import { View, StyleSheet, FlatList, Platform, ScrollView, Pressable } from 'react-native';
import { SimpleText } from '@quarks';
import { AppStyle, TextStyle } from '@style';
import { CheckBox } from '@platform'
import { Icon } from '@quarks'
import { Icons } from '@icons'

export const DropdownPicker = (props) => {
    /**
     * prop.options should be:
     *  [
     *      {
     *          name: any type,
     *          code: any type,
     *          checked: boolean,
     *          dropdown: boolean,
     *          subitems: [
     *              {
     *                  name: any type,
     *                  code: any type,
     *                  checked: boolean,
     *                  dropdown: boolean,
     *                  subitems: [
     *                      ...
     *                  ]
     *              }, ...
     *          ]
     *      }, ...
     *  ]
     *  
     *  props.onCheckboxPress is called for any checbox item pressed, it receives
     * 
     */

    const styles = updateStyles()

    const checkbox = (item, style) => {
        if (Platform.OS === "ios") {
            return (
                <View style={[styles.checkbox, style]}>
                    <CheckBox
                        hideBox
                        value={item.checked}
                        style={{ height: 20, width: 20 }}
                        onCheckColor={AppStyle.getColor('main', 1)}
                        onValueChange={props.onCheckboxPress(item, props.parents)}
                    />
                </View>
            )
        } else {
            return (
                <CheckBox
                    style={styles.checkbox}
                    value={item.checked}
                    onValueChange={props.onCheckboxPress(item, props.parents)} />)
        }
    }

    const renderItem = ({ item, index }) => {
        let parentFields = []
        if (props.parents)
            parentFields.push(...props.parents)
        parentFields.push(item.code)

        let level = 0
        if (typeof props.parents === 'undefined' || props.parents.length == 0) {
            level = 1
        } else {
            level = props.parents.length + 1
        }

        let itemStyle = styles.itemFirst
        let checkboxStyle = styles.checkboxFirst
        let textStyle = styles.textFirst
        let borderStyle = styles.borderFirst
        switch (level) {
            case 1:
                itemStyle = styles.itemFirst
                checkboxStyle = styles.checkboxFirst
                textStyle = styles.textFirst
                borderStyle = styles.borderFirst
                break;

            case 2:
                itemStyle = styles.itemSecond
                checkboxStyle = styles.checkboxSecond
                textStyle = styles.textSecond
                borderStyle = styles.borderSecond
                break;

            case 3:
                itemStyle = styles.itemThird
                checkboxStyle = styles.checkboxThird
                textStyle = styles.textThird
                borderStyle = styles.borderThird
                break;
        }
        return (
            <Pressable key={index} onPress={props.onDropdownPress(item, props.parents)}>
                <View style={itemStyle}>
                    {checkbox(item, checkboxStyle)}
                    <SimpleText style={textStyle}>{item.name}</SimpleText>
                    {(level < props.levels) &&
                        <Icon
                            style={{ position: 'absolute', end: 30 }}
                            name={item.dropdown ? Icons.UP : Icons.DOWN}
                            size={20}
                            color={AppStyle.mainColor}
                        />}
                </View>

                <View style={styles.borderContainer}>
                    <View style={borderStyle} />
                </View>
                {item.dropdown === true &&
                    typeof item.subitems !== 'undefined' &&
                    item.subitems.length > 0 &&
                    <DropdownPicker
                        options={item.subitems}
                        style={{marginLeft: 30}}
                        parents={parentFields}
                        onDropdownPress={props.onDropdownPress}
                        onCheckboxPress={props.onCheckboxPress}
                        levels={props.levels} />}
            </Pressable>);
    };

    return (
        <FlatList
            keyExtractor={(item, index) => item.code}
            style={[styles.list ,props.style]}
            contentContainerStyle={styles.listContentContainerStyle}
            data={props.options}
            renderItem={renderItem}
        />
    )

};

const updateStyles = () => StyleSheet.create({
    list: {
        alignContent:'center',
        alignSelf:'center',
        width:'100%'
    },
    listContentContainerStyle: {
        alignContent:'center',
        alignSelf:'center',
        justifyContent:'center',
        width:'100%'
    },
    itemFirst: {
        backgroundColor: AppStyle.backgroundColor,
        width: '100%',
        height: 60,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf:'center',
        marginLeft: 15,
        paddingBottom: 5,
        paddingTop: 5,
    },
    itemSecond: {
        backgroundColor: AppStyle.backgroundColor,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf:'center',
        paddingBottom: 5,
        marginLeft: 15,
        paddingTop: 5,
    },
    itemThird: {
        backgroundColor: AppStyle.backgroundColor,
        width: '100%',
        height: 50,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingBottom: 5,
        paddingTop: 5,
    },
    borderContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    borderFirst: {
        flex: 1,
        borderColor: AppStyle.textColor,
        borderBottomWidth: 1,
    },
    borderSecond: {
        flex: 0.95,
        borderColor: AppStyle.textColor,
        borderBottomWidth: 1,
    },
    borderThird: {
        flex: 0.9,
        borderColor: AppStyle.textColor,
        borderBottomWidth: 1,
    },
    textFirst: {
        ...TextStyle.weight.semibold,
        ...TextStyle.padding.large,
    },
    textSecond: {
        ...TextStyle.weight.regular,
        ...TextStyle.padding.large,
    },
    textThird: {
        ...TextStyle.size.regular,
        ...TextStyle.weight.regular,
        ...TextStyle.padding.large,
    },
    checkbox: {
        marginRight: 20,
        borderColor: AppStyle.getColor('main', 1),
        borderWidth: 1,
        height: 22,
        width: 22,
        borderRadius: 5
    },
    checkboxFirst: {},
    checkboxSecond: {
        marginLeft: 15
    },
    checkboxThird: {
        marginLeft: 30
    }
});

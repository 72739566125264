import { gql } from '@apollo/client';
import { getTranslationQuery } from '@utilities/locale'

const getAvailablePickups = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer(query: {_id: $id}) {
                    delivery {
                        pickups {
                            id
                            isEnabled
                            name
                            location{
                                admin_name2
                                coordinates
                                place_name
                                postal_code
                                street
                            }
                            days {
                                day
                                times {
                                    start
                                    end
                                }
                            }
                        }
                    }
                }
            }`,
            param: { variables: { id: producerId } },
    }
}

const getHeaderById = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer(query: {_id: $id}) {
                    _id
                    images {
                        profile
                        cover
                    }
                    location {
                        place_name
                        admin_name2
                    }
                    name,
                    delivery {
                        pickups {
                            isEnabled
                        }
                        zones {
                            availableFrom
                            freeFrom
                        }
                    }
                }
            }`,
        param: { variables: { id: producerId } },
    }
}

const getById = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer(query: {_id: $id}) {
                    _id
                    status {
                        code
                    }
                }
            }`,
        param: {
            variables: { id: producerId }
        }
    }
}

const getToValidateById = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer(query: {_id: $id}) {
                    _id
                    counters {
                        orders {
                            total
                            preparing
                            inLogistics
                            disputes
                        }
                        products {
                            total
                        }
                    }
                    delivery {
                        zones {
                            id
                        }
                    }
                    status {
                        code
                    }
                    images {
                        cover
                        profile
                    }
                    name
                    ${getTranslationQuery('description', true)}
                    contact {
                        email
                    }
                    location {
                        place_name
                    }
                    
                }
            }`,
        param: { variables: { id: producerId } }
    }
}

const getProducerByCategory = (categoriesId) => {
    return {
        query: gql`
            query ($categoriesId: [ObjectId]!, $skip: Float, $limit: Float, $places:[String], $coordinates: ProducerByCategoryInputCoordinate) {
                producerByCategory(input: {categoriesId: $categoriesId, places:$places, skip: $skip, limit: $limit, coordinates: $coordinates}) {
                    _id
                    images {
                        cover
                        profile
                    }
                    categories {
                        categoryId
                    }
                    location {
                        place_name
                        admin_name2
                    }
                    delivery {
                        pickups {
                            id
                            isEnabled
                        }
                        zones {
                            id
                            freeFrom
                            availableFrom
                            price
                            places
                            suplements {
                                weight {
                                    interval
                                    value
                                }
                            }
                            modality {
                                personal {
                                    days {
                                        day
                                        startTime
                                        endTime
                                    }
                                }
                                external {
                                    time
                                }
                            }
                        }
                    }
                    distance
                    name
                }
            }`,
        param: { variables: { categoriesId: categoriesId } },
        makeParam: ({ categoriesId, places, coordinates, skip, limit }) => ({ variables: { categoriesId: categoriesId, places: places, coordinates: coordinates, skip: skip, limit: limit } })
    }
}

const getProfile = (producerId, allTranslations = false) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer(query: {_id: $id}) {
                _id
                images {
                    list
                    cover
                    profile
                }
                location {
                    place_name
                    admin_name2
                    postal_code
                    street
                    coordinates
                }
                contact {
                    phone {
                        number
                        countryCode
                    }
                    website
                    email
                }
                name
                ${getTranslationQuery('description slogan', allTranslations)}
                }
            }`,
        param: { variables: { id: producerId } }
    }
}

//params example: ES, CT, L, locationId
const getMainFeed = (places, coordinates, limit = 5, offset = 0) => {

    let coord = {...coordinates}
    if (typeof coord.__typename !== 'undefined') delete coord.__typename

    return {
        query: gql`
            query ($places: [String]!, $coordinates: MainFeedInCoordinate!, $limit: Int, $offset: Int) {
                MainFeed (input: {places: $places, coordinates: $coordinates, limit: $limit, offset: $offset}){
                    _id
                    images {
                        cover
                        profile
                    }
                    categories {
                        categoryId
                    }
                    location {
                        place_name
                        admin_name2
                    }
                    delivery {
                        pickups {
                            id
                            isEnabled
                        }
                        zones {
                            id
                            freeFrom
                            availableFrom
                            price
                            places
                            suplements {
                                weight {
                                    interval
                                    value
                                }
                            }
                            modality {
                                personal {
                                    days {
                                        day
                                        startTime
                                        endTime
                                    }
                                }
                                external {
                                    time
                                }
                            }
                        }
                    }
                    distance
                    name
                }
            }`,
        param: { variables: { places: places, coordinates: coord, offset: offset, limit: limit } }
    }
}

const getTransactions = (limit = 15, offset = 0) => {
    return {
        query: gql`
            query ($limit: Int, $offset: Int) {
                ProTransactions (input: {limit: $limit, offset: $offset}){
                    balance
                    transactions {
                        Id
                        Tag
                        CreationDate
                        DebitedFunds {
                            Amount
                        }
                        ResultCode
                        Type
                        Status
                    }
                }
            }`,
        param: {
            variables: { offset: offset, limit: limit }
        },
        name: 'Producer-getTransactions'
    }
}

const getBankAccounts = (limit = 15, offset = 0) => {
    return {
        query: gql`
            query ($limit: Int, $offset: Int) {
                ProBankAccounts (input: {limit: $limit, offset: $offset}){
                    accounts {
                        IBAN
                        owner
                    }
                }
            }`,
        param: {
            variables: { offset: offset, limit: limit }
        }
    }
}

const getZones = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer(query: {_id: $id}) {
                    _id
                    location {
                        coordinates
                    }
                    delivery {
                        zones {
                            id
                            name
                            availableFrom,
                            freeFrom,
                            price
                            places
                            creationDate
                            suplements {
                                weight {
                                    interval
                                    value
                                }
                            }
                            modality {
                                personal {
                                    days {
                                        day
                                        startTime
                                        endTime
                                    }
                                }
                                external {
                                    time
                                }
                            }
                        }
                    }
                }
            }`,
        param: { variables: { id: producerId } }
    }
}


const getPickups = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer(query: {_id: $id}) {
                    _id
                    name
                    location {
                        postal_code
                        street
                        coordinates
                    }
                    delivery {
                        pickups {
                            id
                            name
                            isEnabled
                            location {
                                admin_name2
                                coordinates
                                place_name
                                postal_code
                                street
                                type
                            }
                            days {
                                day
                                times {
                                    start
                                    end
                                }
                            }
                        }
                    }
                }
            }`,
        param: { variables: { id: producerId } }
    }
}

const getWallets = (producerIds) => {
    return {
        query: gql`
            query ($ids: [ObjectId]!) {
                producers (query: {_id_in: $ids}) {
                    _id
                    company {
                        _id
                        payment {
                            wallet
                        }
                    }
                }
            }`,
        param: { variables: { ids: producerIds } }
    }

}

const getCompany = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                company (query: {producers: [$id]}) {
                    LegalPersonType
                    payment {
                        kyc {
                            pending
                            done
                            IDENTITY_PROOF
                            REGISTRATION_PROOF
                            ARTICLES_OF_ASSOCIATION
                            SHAREHOLDER_DECLARATION
                            ADDRESS_PROOF
                        }
                    }
                }
            }`,
        param: { variables: { id: producerId } }
    }
}

const getCompanyKyc = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                company (query: {producers: [$id]}) {
                    payment {
                        kyc {
                            pending
                            done
                        }
                    }
                }
            }`,
        param: { variables: { id: producerId } },
        name: 'Producer-getCompanyKyc'
    }
}

const getMangoCompany = (producerId) => {
    return {
        query: gql`
            query {
                MangoCompany {
                    Name
                    CompanyNumber
                    payment {
                        kyc {
                            pending
                            done
                        }
                    }
                }
            }`,
        param: { variables: { id: producerId } }
    }
}

const getFiscalData = (producerId) => {
    return {
        query: gql`
            query {
                FiscalData {
                    HeadquartersAddress {
                        AddressLine1
                        AddressLine2
                        City
                        Region
                        PostalCode
                        Country
                    }
                    Name
                    CompanyNumber
                    Email
                    PhoneNumber {
                        number
                        countryCode
                    }
                    payment {
                        OwnerName
                        IBAN
                    }
                }
            }`,
        param: { variables: { id: producerId } }
    }
}

const getContactProducer = (producerId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                producer (query: {_id: $id}) {
                    contact {
                        phone {
                            number
                            countryCode
                        }
                        email
                    }
                    location {
                        postal_code
                        street
                        place_name
                        admin_name2
                        country_code
                    }
                }
            }`,
        param: { variables: { id: producerId } }
    }
}

const updateCompany = () => {
    return {
        mutation: gql`    
            mutation (
                $birthday_unset: Boolean, $nif: String, $phoneNumber: CompanyPhoneNumberUpdateInput, $payment_unset: Boolean, $nif_unset: Boolean, $email_unset: Boolean, $street_unset: Boolean, $legalRepresentativeFirstName_unset: Boolean, $_id: ObjectId, $legalRepresentativeLastName: String, $name_unset: Boolean, $phoneNumber_unset: Boolean, $payment: CompanyPaymentUpdateInput, $birthday: DateTime, $location: CompanyLocationRelationInput, $legalRepresentativeLastName_unset: Boolean, $legalRepresentativeFirstName: String, $street: String, $region: String, $email: String, $name: String, $location_unset: Boolean, $_id_unset: Boolean, $region_unset: Boolean
            ) {
            updateCompany (input: {
                birthday_unset: $birthday_unset, nif: $nif, phoneNumber: $phoneNumber, payment_unset: $payment_unset, nif_unset: $nif_unset, email_unset: $email_unset, street_unset: $street_unset, legalRepresentativeFirstName_unset: $legalRepresentativeFirstName_unset, _id: $_id, legalRepresentativeLastName: $legalRepresentativeLastName, name_unset: $name_unset, phoneNumber_unset: $phoneNumber_unset, payment: $payment, birthday: $birthday, location: $location, legalRepresentativeLastName_unset: $legalRepresentativeLastName_unset, legalRepresentativeFirstName: $legalRepresentativeFirstName, street: $street, region: $region, email: $email, name: $name, location_unset: $location_unset, _id_unset: $_id_unset, region_unset: $region_unset 

            }) {
                status
                message
                formErrors
            }
            }`,
        makeParam: ({ birthday_unset, nif, phoneNumber, payment_unset, nif_unset, email_unset, street_unset, legalRepresentativeFirstName_unset, _id, legalRepresentativeLastName, name_unset, phoneNumber_unset, payment, birthday, location, legalRepresentativeLastName_unset, legalRepresentativeFirstName, street, region, email, name, location_unset, _id_unset, region_unset }) =>
            ({
                variables: {
                    birthday_unset: birthday_unset, nif: nif, phoneNumber: phoneNumber, payment_unset: payment_unset, nif_unset: nif_unset, email_unset: email_unset, street_unset: street_unset, legalRepresentativeFirstName_unset: legalRepresentativeFirstName_unset, _id: _id, legalRepresentativeLastName: legalRepresentativeLastName, name_unset: name_unset, phoneNumber_unset: phoneNumber_unset, payment: payment, birthday: birthday, location: location, legalRepresentativeLastName_unset: legalRepresentativeLastName_unset, legalRepresentativeFirstName: legalRepresentativeFirstName, street: street, region: region, email: email, name: name, location_unset: location_unset, _id_unset: _id_unset, region_unset: region_unset
                }
            })
    }
}

const sellsAtDirection = (producer, direction) => {

    let arrayPlaces = producer?.delivery?.zones.map(ele => ele.places)
    if (typeof arrayPlaces == 'undefined' || arrayPlaces == null) {
        return false
    }
    if ( arrayPlaces.length == 0) {
        return false
    }

    let places = []
    arrayPlaces.forEach(element => {
        places.push(...element)
    });

    for (let index = 0; index < places.length; index++) {
        const element = places[index];
        if (direction.location.places.includes(element)) {
            return true
        }
    }

    return false
}


export default {
    getById,
    getToValidateById,
    getProfile,
    getMainFeed,
    getHeaderById,
    getZones,
    getPickups,
    getAvailablePickups,
    getTransactions,
    getBankAccounts,
    getWallets,
    getCompany,
    getCompanyKyc,
    getMangoCompany,
    getProducerByCategory,
    sellsAtDirection,
    updateCompany,
    getFiscalData,
    getContactProducer
}

export const SUBMIT_RESPONSE_STATUS = Object.freeze({
    UNDEFINED: -1,
    SUCCESS: 0,
    FAILURE: 1,
    FORM_ERROR: 2,
    UPLOADING: 3,
    UPDATING: 4,
    LOADING: 5,
})

export const PROFILE_SECTIONS = Object.freeze({
    PRODUCTS: 0,
    PROFILE: 1,
})

export const LANGUAGES = Object.freeze({
    CAT: 'ca',
    ES: 'es',
    ENG: 'en',
})

export const REGISTER_STATUS = Object.freeze({
    START: 0,
    WAITING_VERIFICATION: 1,
    STORE_COMPANY_FISCAL: 2,
    NEXTO_VERIFICATION: 3,
})

export const ALLERGENS = Object.freeze({
    eggs: {
        code: "eggs",
        translations: {
            ca: {
                name: "Ous",
            },
            es: {
                name:"Huevos",
            }
        }
    },
    fish: {
        code: "fish",
        translations: {
            ca: {
                name: "Peix",
            },
            es: {
                name:"Pescado",
            }
        }
    },
    peanuts: {
        code: "peanuts",
        translations: {
            ca: {
                name: "Cacauets",
            },
            es: {
                name:"Cacahuetes",
            }
        }
    },
    soy: {
        code: "soy",
        translations: {
            ca: {
                name: "Soja",
            },
            es: {
                name:"Soja",
            }
        }
    },
    milk: {
        code: "milk",
        translations: {
            ca: {
                name: "Làctics",
            },
            es: {
                name:"Lácteos",
            }
        }
    },
    gluten: {
        code: "gluten",
        translations: {
            ca: {
                name: "Gluten",
            },
            es: {
                name:"Gluten",
            }
        }
    },
    crustaceans: {
        code: "crustaceans",
        translations: {
            ca: {
                name: "Crustàcis",
            },
            es: {
                name:"Crustáceos",
            }
        }
    },
    nuts: {
        code: "nuts",
        translations: {
            ca: {
                name: "Fruits secs",
            },
            es: {
                name:"Frutos secos",
            }
        }
    },
    celery: {
        code: "celery",
        translations: {
            ca: {
                name: "Api",
            },
            es: {
                name:"Apio",
            }
        }
    },
    mustard: {
        code: "mustard",
        translations: {
            ca: {
                name: "Mostassa",
            },
            es: {
                name:"Mostaza",
            }
        }
    },
    sesame: {
        code: "sesame",
        translations: {
            ca: {
                name: "Sésam",
            },
            es: {
                name:"Sésamo",
            }
        }
    },
    sulphites: {
        code: "sulphites",
        translations: {
            ca: {
                name: "Diòxid de sofre i sulfits",
            },
            es: {
                name:"Dióxido de azufre y sulfitos",
            }
        }
    },
    lupins: {
        code: "lupins",
        translations: {
            ca: {
                name: "Tramussos",
            },
            es: {
                name:"Altramuz",
            }
        }
    },
    mollusks: {
        code: "mollusks",
        translations: {
            ca: {
                name: "Mol·luscs ",
            },
            es: {
                name:"Moluscos",
            }
        }
    },


})

export const LOCATIONS = [
    {
        "name":"Andalucia",
        "code":"AN",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Almería",
                "code":"AL",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Cádiz",
                "code":"CA",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Córdoba",
                "code":"CO",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Granada",
                "code":"GR",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Huelva",
                "code":"H",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Jaén",
                "code":"J",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Málaga",
                "code":"MA",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Sevilla",
                "code":"SE",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Canarias",
        "code":"CN",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Las Palmas",
                "code":"GC",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Santa Cruz de Tenerife",
                "code":"TF",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Cantabria",
        "code":"CB",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Cantabria",
                "code":"S",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Castilla - La Mancha",
        "code":"CM",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Albacete",
                "code":"AB",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Ciudad Real",
                "code":"CR",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Cuenca",
                "code":"CU",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Guadalajara",
                "code":"GU",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Toledo",
                "code":"TO",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Aragón",
        "code":"AR",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Huesca",
                "code":"HU",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Teruel",
                "code":"TE",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Zaragoza",
                "code":"Z",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Castilla - Leon",
        "code":"CL",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Burgos",
                "code":"BU",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"León",
                "code":"LE",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Palencia",
                "code":"P",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Salamanca",
                "code":"SA",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Segovia",
                "code":"SG",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Soria",
                "code":"SO",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Valladolid",
                "code":"VA",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Zamora",
                "code":"ZA",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Ávila",
                "code":"AV",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Asturias",
        "code":"AS",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Asturias",
                "code":"O",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Baleares",
        "code":"IB",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Baleares",
                "code":"PM",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Catalunya",
        "code":"CT",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Barcelona",
                "code":"B",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Girona",
                "code":"GI",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Lleida",
                "code":"L",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Tarragona",
                "code":"T",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Ceuta",
        "code":"CE",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Ceuta",
                "code":"CE",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Extremadura",
        "code":"EX",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Badajoz",
                "code":"BA",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Cáceres",
                "code":"CC",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Comunidad Valenciana",
        "code":"VC",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Alicante",
                "code":"A",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Castellón",
                "code":"CS",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Valencia",
                "code":"V",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Galicia",
        "code":"GA",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"A Coruña",
                "code":"C",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Lugo",
                "code":"LU",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Ourense",
                "code":"OR",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Pontevedra",
                "code":"PO",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"La Rioja",
        "code":"RI",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"La Rioja",
                "code":"LO",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Navarra",
        "code":"NC",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Navarra",
                "code":"NA",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Murcia",
        "code":"MC",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Murcia",
                "code":"MU",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Melilla",
        "code":"ML",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Melilla",
                "code":"ME",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Madrid",
        "code":"MD",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Madrid",
                "code":"M",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    },
    {
        "name":"Pais Vasco",
        "code":"PV",
        "checked":false,
        "dropdown":false,
        "subitems":[
            {
                "name":"Guipúzcoa",
                "code":"SS",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Vizcaya",
                "code":"BI",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            },
            {
                "name":"Álava",
                "code":"VI",
                "checked":false,
                "dropdown":false,
                "subitems":[

                ]
            }
        ]
    }
]

export const COUNTRY_CODES = [
    {
        "label":"España",
        "value":"ES",
        "country-code":"724"
    },
    {
        "label":"Afghanistan",
        "value":"AF",
        "country-code":"004"
    },
    {
        "label":"Åland Islands",
        "value":"AX",
        "country-code":"248"
    },
    {
        "label":"Albania",
        "value":"AL",
        "country-code":"008"
    },
    {
        "label":"Algeria",
        "value":"DZ",
        "country-code":"012"
    },
    {
        "label":"American Samoa",
        "value":"AS",
        "country-code":"016"
    },
    {
        "label":"Andorra",
        "value":"AD",
        "country-code":"020"
    },
    {
        "label":"Angola",
        "value":"AO",
        "country-code":"024"
    },
    {
        "label":"Anguilla",
        "value":"AI",
        "country-code":"660"
    },
    {
        "label":"Antarctica",
        "value":"AQ",
        "country-code":"010"
    },
    {
        "label":"Antigua and Barbuda",
        "value":"AG",
        "country-code":"028"
    },
    {
        "label":"Argentina",
        "value":"AR",
        "country-code":"032"
    },
    {
        "label":"Armenia",
        "value":"AM",
        "country-code":"051"
    },
    {
        "label":"Aruba",
        "value":"AW",
        "country-code":"533"
    },
    {
        "label":"Australia",
        "value":"AU",
        "country-code":"036"
    },
    {
        "label":"Austria",
        "value":"AT",
        "country-code":"040"
    },
    {
        "label":"Azerbaijan",
        "value":"AZ",
        "country-code":"031"
    },
    {
        "label":"Bahamas",
        "value":"BS",
        "country-code":"044"
    },
    {
        "label":"Bahrain",
        "value":"BH",
        "country-code":"048"
    },
    {
        "label":"Bangladesh",
        "value":"BD",
        "country-code":"050"
    },
    {
        "label":"Barbados",
        "value":"BB",
        "country-code":"052"
    },
    {
        "label":"Belarus",
        "value":"BY",
        "country-code":"112"
    },
    {
        "label":"Belgium",
        "value":"BE",
        "country-code":"056"
    },
    {
        "label":"Belize",
        "value":"BZ",
        "country-code":"084"
    },
    {
        "label":"Benin",
        "value":"BJ",
        "country-code":"204"
    },
    {
        "label":"Bermuda",
        "value":"BM",
        "country-code":"060"
    },
    {
        "label":"Bhutan",
        "value":"BT",
        "country-code":"064"
    },
    {
        "label":"Bolivia (Plurinational State of)",
        "value":"BO",
        "country-code":"068"
    },
    {
        "label":"Bonaire, Sint Eustatius and Saba",
        "value":"BQ",
        "country-code":"535"
    },
    {
        "label":"Bosnia and Herzegovina",
        "value":"BA",
        "country-code":"070"
    },
    {
        "label":"Botswana",
        "value":"BW",
        "country-code":"072"
    },
    {
        "label":"Bouvet Island",
        "value":"BV",
        "country-code":"074"
    },
    {
        "label":"Brazil",
        "value":"BR",
        "country-code":"076"
    },
    {
        "label":"British Indian Ocean Territory",
        "value":"IO",
        "country-code":"086"
    },
    {
        "label":"Brunei Darussalam",
        "value":"BN",
        "country-code":"096"
    },
    {
        "label":"Bulgaria",
        "value":"BG",
        "country-code":"100"
    },
    {
        "label":"Burkina Faso",
        "value":"BF",
        "country-code":"854"
    },
    {
        "label":"Burundi",
        "value":"BI",
        "country-code":"108"
    },
    {
        "label":"Cabo Verde",
        "value":"CV",
        "country-code":"132"
    },
    {
        "label":"Cambodia",
        "value":"KH",
        "country-code":"116"
    },
    {
        "label":"Cameroon",
        "value":"CM",
        "country-code":"120"
    },
    {
        "label":"Canada",
        "value":"CA",
        "country-code":"124"
    },
    {
        "label":"Cayman Islands",
        "value":"KY",
        "country-code":"136"
    },
    {
        "label":"Central African Republic",
        "value":"CF",
        "country-code":"140"
    },
    {
        "label":"Chad",
        "value":"TD",
        "country-code":"148"
    },
    {
        "label":"Chile",
        "value":"CL",
        "country-code":"152"
    },
    {
        "label":"China",
        "value":"CN",
        "country-code":"156"
    },
    {
        "label":"Christmas Island",
        "value":"CX",
        "country-code":"162"
    },
    {
        "label":"Cocos (Keeling) Islands",
        "value":"CC",
        "country-code":"166"
    },
    {
        "label":"Colombia",
        "value":"CO",
        "country-code":"170"
    },
    {
        "label":"Comoros",
        "value":"KM",
        "country-code":"174"
    },
    {
        "label":"Congo",
        "value":"CG",
        "country-code":"178"
    },
    {
        "label":"Congo, Democratic Republic of the",
        "value":"CD",
        "country-code":"180"
    },
    {
        "label":"Cook Islands",
        "value":"CK",
        "country-code":"184"
    },
    {
        "label":"Costa Rica",
        "value":"CR",
        "country-code":"188"
    },
    {
        "label":"Côte d'Ivoire",
        "value":"CI",
        "country-code":"384"
    },
    {
        "label":"Croatia",
        "value":"HR",
        "country-code":"191"
    },
    {
        "label":"Cuba",
        "value":"CU",
        "country-code":"192"
    },
    {
        "label":"Curaçao",
        "value":"CW",
        "country-code":"531"
    },
    {
        "label":"Cyprus",
        "value":"CY",
        "country-code":"196"
    },
    {
        "label":"Czechia",
        "value":"CZ",
        "country-code":"203"
    },
    {
        "label":"Denmark",
        "value":"DK",
        "country-code":"208"
    },
    {
        "label":"Djibouti",
        "value":"DJ",
        "country-code":"262"
    },
    {
        "label":"Dominica",
        "value":"DM",
        "country-code":"212"
    },
    {
        "label":"Dominican Republic",
        "value":"DO",
        "country-code":"214"
    },
    {
        "label":"Ecuador",
        "value":"EC",
        "country-code":"218"
    },
    {
        "label":"Egypt",
        "value":"EG",
        "country-code":"818"
    },
    {
        "label":"El Salvador",
        "value":"SV",
        "country-code":"222"
    },
    {
        "label":"Equatorial Guinea",
        "value":"GQ",
        "country-code":"226"
    },
    {
        "label":"Eritrea",
        "value":"ER",
        "country-code":"232"
    },
    {
        "label":"Estonia",
        "value":"EE",
        "country-code":"233"
    },
    {
        "label":"Eswatini",
        "value":"SZ",
        "country-code":"748"
    },
    {
        "label":"Ethiopia",
        "value":"ET",
        "country-code":"231"
    },
    {
        "label":"Falkland Islands (Malvinas)",
        "value":"FK",
        "country-code":"238"
    },
    {
        "label":"Faroe Islands",
        "value":"FO",
        "country-code":"234"
    },
    {
        "label":"Fiji",
        "value":"FJ",
        "country-code":"242"
    },
    {
        "label":"Finland",
        "value":"FI",
        "country-code":"246"
    },
    {
        "label":"France",
        "value":"FR",
        "country-code":"250"
    },
    {
        "label":"French Guiana",
        "value":"GF",
        "country-code":"254"
    },
    {
        "label":"French Polynesia",
        "value":"PF",
        "country-code":"258"
    },
    {
        "label":"French Southern Territories",
        "value":"TF",
        "country-code":"260"
    },
    {
        "label":"Gabon",
        "value":"GA",
        "country-code":"266"
    },
    {
        "label":"Gambia",
        "value":"GM",
        "country-code":"270"
    },
    {
        "label":"Georgia",
        "value":"GE",
        "country-code":"268"
    },
    {
        "label":"Germany",
        "value":"DE",
        "country-code":"276"
    },
    {
        "label":"Ghana",
        "value":"GH",
        "country-code":"288"
    },
    {
        "label":"Gibraltar",
        "value":"GI",
        "country-code":"292"
    },
    {
        "label":"Greece",
        "value":"GR",
        "country-code":"300"
    },
    {
        "label":"Greenland",
        "value":"GL",
        "country-code":"304"
    },
    {
        "label":"Grenada",
        "value":"GD",
        "country-code":"308"
    },
    {
        "label":"Guadeloupe",
        "value":"GP",
        "country-code":"312"
    },
    {
        "label":"Guam",
        "value":"GU",
        "country-code":"316"
    },
    {
        "label":"Guatemala",
        "value":"GT",
        "country-code":"320"
    },
    {
        "label":"Guernsey",
        "value":"GG",
        "country-code":"831"
    },
    {
        "label":"Guinea",
        "value":"GN",
        "country-code":"324"
    },
    {
        "label":"Guinea-Bissau",
        "value":"GW",
        "country-code":"624"
    },
    {
        "label":"Guyana",
        "value":"GY",
        "country-code":"328"
    },
    {
        "label":"Haiti",
        "value":"HT",
        "country-code":"332"
    },
    {
        "label":"Heard Island and McDonald Islands",
        "value":"HM",
        "country-code":"334"
    },
    {
        "label":"Holy See",
        "value":"VA",
        "country-code":"336"
    },
    {
        "label":"Honduras",
        "value":"HN",
        "country-code":"340"
    },
    {
        "label":"Hong Kong",
        "value":"HK",
        "country-code":"344"
    },
    {
        "label":"Hungary",
        "value":"HU",
        "country-code":"348"
    },
    {
        "label":"Iceland",
        "value":"IS",
        "country-code":"352"
    },
    {
        "label":"India",
        "value":"IN",
        "country-code":"356"
    },
    {
        "label":"Indonesia",
        "value":"ID",
        "country-code":"360"
    },
    {
        "label":"Iran (Islamic Republic of)",
        "value":"IR",
        "country-code":"364"
    },
    {
        "label":"Iraq",
        "value":"IQ",
        "country-code":"368"
    },
    {
        "label":"Ireland",
        "value":"IE",
        "country-code":"372"
    },
    {
        "label":"Isle of Man",
        "value":"IM",
        "country-code":"833"
    },
    {
        "label":"Israel",
        "value":"IL",
        "country-code":"376"
    },
    {
        "label":"Italy",
        "value":"IT",
        "country-code":"380"
    },
    {
        "label":"Jamaica",
        "value":"JM",
        "country-code":"388"
    },
    {
        "label":"Japan",
        "value":"JP",
        "country-code":"392"
    },
    {
        "label":"Jersey",
        "value":"JE",
        "country-code":"832"
    },
    {
        "label":"Jordan",
        "value":"JO",
        "country-code":"400"
    },
    {
        "label":"Kazakhstan",
        "value":"KZ",
        "country-code":"398"
    },
    {
        "label":"Kenya",
        "value":"KE",
        "country-code":"404"
    },
    {
        "label":"Kiribati",
        "value":"KI",
        "country-code":"296"
    },
    {
        "label":"Korea (Democratic People's Republic of)",
        "value":"KP",
        "country-code":"408"
    },
    {
        "label":"Korea, Republic of",
        "value":"KR",
        "country-code":"410"
    },
    {
        "label":"Kuwait",
        "value":"KW",
        "country-code":"414"
    },
    {
        "label":"Kyrgyzstan",
        "value":"KG",
        "country-code":"417"
    },
    {
        "label":"Lao People's Democratic Republic",
        "value":"LA",
        "country-code":"418"
    },
    {
        "label":"Latvia",
        "value":"LV",
        "country-code":"428"
    },
    {
        "label":"Lebanon",
        "value":"LB",
        "country-code":"422"
    },
    {
        "label":"Lesotho",
        "value":"LS",
        "country-code":"426"
    },
    {
        "label":"Liberia",
        "value":"LR",
        "country-code":"430"
    },
    {
        "label":"Libya",
        "value":"LY",
        "country-code":"434"
    },
    {
        "label":"Liechtenstein",
        "value":"LI",
        "country-code":"438"
    },
    {
        "label":"Lithuania",
        "value":"LT",
        "country-code":"440"
    },
    {
        "label":"Luxembourg",
        "value":"LU",
        "country-code":"442"
    },
    {
        "label":"Macao",
        "value":"MO",
        "country-code":"446"
    },
    {
        "label":"Madagascar",
        "value":"MG",
        "country-code":"450"
    },
    {
        "label":"Malawi",
        "value":"MW",
        "country-code":"454"
    },
    {
        "label":"Malaysia",
        "value":"MY",
        "country-code":"458"
    },
    {
        "label":"Maldives",
        "value":"MV",
        "country-code":"462"
    },
    {
        "label":"Mali",
        "value":"ML",
        "country-code":"466"
    },
    {
        "label":"Malta",
        "value":"MT",
        "country-code":"470"
    },
    {
        "label":"Marshall Islands",
        "value":"MH",
        "country-code":"584"
    },
    {
        "label":"Martinique",
        "value":"MQ",
        "country-code":"474"
    },
    {
        "label":"Mauritania",
        "value":"MR",
        "country-code":"478"
    },
    {
        "label":"Mauritius",
        "value":"MU",
        "country-code":"480"
    },
    {
        "label":"Mayotte",
        "value":"YT",
        "country-code":"175"
    },
    {
        "label":"Mexico",
        "value":"MX",
        "country-code":"484"
    },
    {
        "label":"Micronesia (Federated States of)",
        "value":"FM",
        "country-code":"583"
    },
    {
        "label":"Moldova, Republic of",
        "value":"MD",
        "country-code":"498"
    },
    {
        "label":"Monaco",
        "value":"MC",
        "country-code":"492"
    },
    {
        "label":"Mongolia",
        "value":"MN",
        "country-code":"496"
    },
    {
        "label":"Montenegro",
        "value":"ME",
        "country-code":"499"
    },
    {
        "label":"Montserrat",
        "value":"MS",
        "country-code":"500"
    },
    {
        "label":"Morocco",
        "value":"MA",
        "country-code":"504"
    },
    {
        "label":"Mozambique",
        "value":"MZ",
        "country-code":"508"
    },
    {
        "label":"Myanmar",
        "value":"MM",
        "country-code":"104"
    },
    {
        "label":"Namibia",
        "value":"NA",
        "country-code":"516"
    },
    {
        "label":"Nauru",
        "value":"NR",
        "country-code":"520"
    },
    {
        "label":"Nepal",
        "value":"NP",
        "country-code":"524"
    },
    {
        "label":"Netherlands",
        "value":"NL",
        "country-code":"528"
    },
    {
        "label":"New Caledonia",
        "value":"NC",
        "country-code":"540"
    },
    {
        "label":"New Zealand",
        "value":"NZ",
        "country-code":"554"
    },
    {
        "label":"Nicaragua",
        "value":"NI",
        "country-code":"558"
    },
    {
        "label":"Niger",
        "value":"NE",
        "country-code":"562"
    },
    {
        "label":"Nigeria",
        "value":"NG",
        "country-code":"566"
    },
    {
        "label":"Niue",
        "value":"NU",
        "country-code":"570"
    },
    {
        "label":"Norfolk Island",
        "value":"NF",
        "country-code":"574"
    },
    {
        "label":"North Macedonia",
        "value":"MK",
        "country-code":"807"
    },
    {
        "label":"Northern Mariana Islands",
        "value":"MP",
        "country-code":"580"
    },
    {
        "label":"Norway",
        "value":"NO",
        "country-code":"578"
    },
    {
        "label":"Oman",
        "value":"OM",
        "country-code":"512"
    },
    {
        "label":"Pakistan",
        "value":"PK",
        "country-code":"586"
    },
    {
        "label":"Palau",
        "value":"PW",
        "country-code":"585"
    },
    {
        "label":"Palestine, State of",
        "value":"PS",
        "country-code":"275"
    },
    {
        "label":"Panama",
        "value":"PA",
        "country-code":"591"
    },
    {
        "label":"Papua New Guinea",
        "value":"PG",
        "country-code":"598"
    },
    {
        "label":"Paraguay",
        "value":"PY",
        "country-code":"600"
    },
    {
        "label":"Peru",
        "value":"PE",
        "country-code":"604"
    },
    {
        "label":"Philippines",
        "value":"PH",
        "country-code":"608"
    },
    {
        "label":"Pitcairn",
        "value":"PN",
        "country-code":"612"
    },
    {
        "label":"Poland",
        "value":"PL",
        "country-code":"616"
    },
    {
        "label":"Portugal",
        "value":"PT",
        "country-code":"620"
    },
    {
        "label":"Puerto Rico",
        "value":"PR",
        "country-code":"630"
    },
    {
        "label":"Qatar",
        "value":"QA",
        "country-code":"634"
    },
    {
        "label":"Réunion",
        "value":"RE",
        "country-code":"638"
    },
    {
        "label":"Romania",
        "value":"RO",
        "country-code":"642"
    },
    {
        "label":"Russian Federation",
        "value":"RU",
        "country-code":"643"
    },
    {
        "label":"Rwanda",
        "value":"RW",
        "country-code":"646"
    },
    {
        "label":"Saint Barthélemy",
        "value":"BL",
        "country-code":"652"
    },
    {
        "label":"Saint Helena, Ascension and Tristan da Cunha",
        "value":"SH",
        "country-code":"654"
    },
    {
        "label":"Saint Kitts and Nevis",
        "value":"KN",
        "country-code":"659"
    },
    {
        "label":"Saint Lucia",
        "value":"LC",
        "country-code":"662"
    },
    {
        "label":"Saint Martin (French part)",
        "value":"MF",
        "country-code":"663"
    },
    {
        "label":"Saint Pierre and Miquelon",
        "value":"PM",
        "country-code":"666"
    },
    {
        "label":"Saint Vincent and the Grenadines",
        "value":"VC",
        "country-code":"670"
    },
    {
        "label":"Samoa",
        "value":"WS",
        "country-code":"882"
    },
    {
        "label":"San Marino",
        "value":"SM",
        "country-code":"674"
    },
    {
        "label":"Sao Tome and Principe",
        "value":"ST",
        "country-code":"678"
    },
    {
        "label":"Saudi Arabia",
        "value":"SA",
        "country-code":"682"
    },
    {
        "label":"Senegal",
        "value":"SN",
        "country-code":"686"
    },
    {
        "label":"Serbia",
        "value":"RS",
        "country-code":"688"
    },
    {
        "label":"Seychelles",
        "value":"SC",
        "country-code":"690"
    },
    {
        "label":"Sierra Leone",
        "value":"SL",
        "country-code":"694"
    },
    {
        "label":"Singapore",
        "value":"SG",
        "country-code":"702"
    },
    {
        "label":"Sint Maarten (Dutch part)",
        "value":"SX",
        "country-code":"534"
    },
    {
        "label":"Slovakia",
        "value":"SK",
        "country-code":"703"
    },
    {
        "label":"Slovenia",
        "value":"SI",
        "country-code":"705"
    },
    {
        "label":"Solomon Islands",
        "value":"SB",
        "country-code":"090"
    },
    {
        "label":"Somalia",
        "value":"SO",
        "country-code":"706"
    },
    {
        "label":"South Africa",
        "value":"ZA",
        "country-code":"710"
    },
    {
        "label":"South Georgia and the South Sandwich Islands",
        "value":"GS",
        "country-code":"239"
    },
    {
        "label":"South Sudan",
        "value":"SS",
        "country-code":"728"
    },
    {
        "label":"Sri Lanka",
        "value":"LK",
        "country-code":"144"
    },
    {
        "label":"Sudan",
        "value":"SD",
        "country-code":"729"
    },
    {
        "label":"Surilabel",
        "value":"SR",
        "country-code":"740"
    },
    {
        "label":"Svalbard and Jan Mayen",
        "value":"SJ",
        "country-code":"744"
    },
    {
        "label":"Sweden",
        "value":"SE",
        "country-code":"752"
    },
    {
        "label":"Switzerland",
        "value":"CH",
        "country-code":"756"
    },
    {
        "label":"Syrian Arab Republic",
        "value":"SY",
        "country-code":"760"
    },
    {
        "label":"Taiwan, Province of China",
        "value":"TW",
        "country-code":"158"
    },
    {
        "label":"Tajikistan",
        "value":"TJ",
        "country-code":"762"
    },
    {
        "label":"Tanzania, United Republic of",
        "value":"TZ",
        "country-code":"834"
    },
    {
        "label":"Thailand",
        "value":"TH",
        "country-code":"764"
    },
    {
        "label":"Timor-Leste",
        "value":"TL",
        "country-code":"626"
    },
    {
        "label":"Togo",
        "value":"TG",
        "country-code":"768"
    },
    {
        "label":"Tokelau",
        "value":"TK",
        "country-code":"772"
    },
    {
        "label":"Tonga",
        "value":"TO",
        "country-code":"776"
    },
    {
        "label":"Trinidad and Tobago",
        "value":"TT",
        "country-code":"780"
    },
    {
        "label":"Tunisia",
        "value":"TN",
        "country-code":"788"
    },
    {
        "label":"Turkey",
        "value":"TR",
        "country-code":"792"
    },
    {
        "label":"Turkmenistan",
        "value":"TM",
        "country-code":"795"
    },
    {
        "label":"Turks and Caicos Islands",
        "value":"TC",
        "country-code":"796"
    },
    {
        "label":"Tuvalu",
        "value":"TV",
        "country-code":"798"
    },
    {
        "label":"Uganda",
        "value":"UG",
        "country-code":"800"
    },
    {
        "label":"Ukraine",
        "value":"UA",
        "country-code":"804"
    },
    {
        "label":"United Arab Emirates",
        "value":"AE",
        "country-code":"784"
    },
    {
        "label":"United Kingdom of Great Britain and Northern Ireland",
        "value":"GB",
        "country-code":"826"
    },
    {
        "label":"United States of America",
        "value":"US",
        "country-code":"840"
    },
    {
        "label":"United States Minor Outlying Islands",
        "value":"UM",
        "country-code":"581"
    },
    {
        "label":"Uruguay",
        "value":"UY",
        "country-code":"858"
    },
    {
        "label":"Uzbekistan",
        "value":"UZ",
        "country-code":"860"
    },
    {
        "label":"Vanuatu",
        "value":"VU",
        "country-code":"548"
    },
    {
        "label":"Venezuela (Bolivarian Republic of)",
        "value":"VE",
        "country-code":"862"
    },
    {
        "label":"Viet Nam",
        "value":"VN",
        "country-code":"704"
    },
    {
        "label":"Virgin Islands (British)",
        "value":"VG",
        "country-code":"092"
    },
    {
        "label":"Virgin Islands (U.S.)",
        "value":"VI",
        "country-code":"850"
    },
    {
        "label":"Wallis and Futuna",
        "value":"WF",
        "country-code":"876"
    },
    {
        "label":"Western Sahara",
        "value":"EH",
        "country-code":"732"
    },
    {
        "label":"Yemen",
        "value":"YE",
        "country-code":"887"
    },
    {
        "label":"Zambia",
        "value":"ZM",
        "country-code":"894"
    },
    {
        "label":"Zimbabwe",
        "value":"ZW",
        "country-code":"716"
    }
]

export const LEGALPERSON_TYPES = [
   {
        label: '$companyForm.general.legalPersonType.business',
        value: 'BUSINESS'
    },
    {
        label: '$companyForm.general.legalPersonType.organization',
        value: 'ORGANIZATION'
    },
    {
        label: '$companyForm.general.legalPersonType.soletrader',
        value: 'SOLETRADER'
    }
]
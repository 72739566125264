import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-community/async-storage';
import * as reducers from "./ducks";
import { createLogger, queryService } from "./middlewares";
import initialState from "_redux/initialState";

const basePersistConfig = {
    key: 'root',
    storage: AsyncStorage
}


const appReducer = combineReducers({
    productForm: reducers.productForm,
    session: reducers.session,
    market: reducers.market,
    products: reducers.products,
    producer: reducers.producer,
    producerForm: reducers.producerForm,
    regions: reducers.regions
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        AsyncStorage.removeItem('persist:root')
        state = {...initialState}
    }

    return appReducer(state, action)
}

function configureStore() {
    const persistedReducer = persistReducer(basePersistConfig, rootReducer)
    return createStore(
        persistedReducer,
        initialState,
        applyMiddleware(
            thunkMiddleware,
            createLogger(false)
        ),
    );
}

export const store = configureStore();
export const persistor = persistStore(store);
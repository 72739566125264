import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Icon } from '@quarks'

import { AppStyle } from '@style';
import { SimpleText } from '@quarks';
import { cardBrandToIcon } from '@utilities/stringUtils'

export function CreditCard(props) {

    const card = props.card
    const styles = updateStyles()

    return (
        <View
            style={{
                minHeight: 120,
                width: '100%',
                flexDirection: 'row',
            }}>
            <Icon
                name={cardBrandToIcon(card.CardProvider)}
                size={40}
                color={AppStyle.textColor}
                style={styles.locationIcon} />
            <View style={styles.itemStyle}>
                {card.Alias && <SimpleText>**** {card.Alias.split('XXXXXX')[1]}</SimpleText>}
                {card.ExpirationDate && <SimpleText>{card.ExpirationDate.slice(0, 2)}/{card.ExpirationDate.slice(2, 4)}</SimpleText>}
                {card.Currency && <SimpleText>{card.Currency}</SimpleText>}
            </View>
        </View>
    )
}

const updateStyles = () => StyleSheet.create({
    itemStyle: {
        alignSelf: 'center',
        flex: 4,
        marginRight: 10,
    },
    locationIcon: {
        alignSelf: 'center',
        flex: 1,
        marginLeft: 20, marginRight: 10,
    },
    editIcon: {
        position: "absolute",
        right: 0,
        marginRight: 10
    }
});

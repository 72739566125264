const initialState = {
    productForm: {
        form: {
            name: {ca:undefined, es:undefined},
            description: {ca:undefined, es:undefined},
            price: undefined,
            amount: undefined,
            amountUnit: undefined,
            stock: undefined,
            tags: [],
            allTags: ['Apicultor', 'Ecològic', 'Premiat', 'Làctic'],
            images: [],
            locale: ['ca','es','en']
        },
        errors: {
            name: {ca:undefined, es:undefined},
            description: {ca:undefined, es:undefined},
            price: undefined,
            amount: undefined,
            amountUnit: undefined,
            stock: undefined,
            tags: undefined,
            images: undefined,
        },
        status: undefined
    },
    market: {
        form: {
            marketName: '',
            description: '',
            place: '',
            day: null,
            image: null,
            errors: {
                marketName: null,
                description: null,
                place: null,
                day: null,
                image: null,
            }
        }
    },
    producer: {
        data: {
            name: undefined,
            profileImage: undefined,
            coverImage: undefined,
            slogan: {
                'ca': undefined, 
                'es': undefined, 
                'en': undefined
            },
            description: {
                'ca': undefined, 
                'es': undefined, 
                'en': undefined
            },
            images: []
        }
    },
    producerForm: {
        form: {
            name: undefined,
            profileImage: undefined,
            coverImage: undefined,
            slogan: {
                'ca': undefined, 
                'es': undefined, 
                'en': undefined
            },
            description: {
                'ca': undefined, 
                'es': undefined, 
                'en': undefined
            },
            images: []
        },
        errors: {
            name: undefined,
            profileImage: undefined,
            coverImage: undefined,
            slogan: {
                'ca': undefined, 
                'es': undefined, 
                'en': undefined
            },
            description: {
                'ca': undefined, 
                'es': undefined, 
                'en': undefined
            },
            images: undefined
        }
    },
    products: {
        list: []
    },
    session: {
        register: {
            done: false,
            pending: false,
            form: {}
        },
        companyForm: {
            form: {}
        },
        queries: {},
        kycForm: {
            done: false, 
            pending: false,
            form: {}
        }
    }
}
export default initialState
import React, {useState} from 'react';
import { View } from 'react-native';

import { NadiusIcons } from '@icons'
import { ButtonText } from '@quarks'
import { ContentBorder } from '@atoms'
import { OptionsPickerModal } from '@molecules'
import { AppStyle } from '@style'

export const MultipleLanguage = (props) => {

    /**
     * 
     * props:
     *          style
     *          renderItem()
     *          updateLocale()
     *          locales? Available locales to pick from
     *          initInUse
     *          defaultLocales?
     */

    const availableLocales = ["es", "ca"]

     
    let [isLocaleModalVisible, setLocaleModalVisible] = useState(false)
    let [usedLocales, setUsedLocales] = useState(props.defaultLocales ? props.defaultLocales : ["ca"])

    if (usedLocales.length == 0 && props.defaultLocales?.length > 0) {
        setUsedLocales(props.defaultLocales)
    }

    const _addOrRemoveLocale = ({ key, state }) => {

       
        let uLocales = [...usedLocales]

        if (state) {
            let i = uLocales.findIndex(value => key == value)
            if (i == -1) {
                uLocales.push(key)
            }
        } else {
            let i = uLocales.findIndex(value => key == value)

            if (i != -1) {
                uLocales.splice(i, 1)
            }
        }

        setUsedLocales(uLocales)
        props.updateLocale(key, !state)

    }

    return (
        <View style={props.style}>
            {
                usedLocales.map((locale, index) =><ContentBorder
                        key={index}
                        titleViewStyle={{
                            maxWidth: 600, justifyContent: 'flex-start',
                            alignSelf: 'center', width: '90%'
                        }}
                        style={{
                            borderRadius: 5,
                            color: AppStyle.textColorLight, flex: 1,
                            padding: 10, margin: 5, marginBottom: 10, minWidth: 300
                        }}
                        backgroundColor={"white"}
                        title={`$locale.${locale}`}>

                            {props.renderItem(locale)}
                        
                    </ContentBorder>
                )
            }

            <ButtonText
                style={{ padding: 5, paddingLeft: 10, paddingRight: 10, alignSelf: 'center' }}
                type={'secondary'}
                icon={NadiusIcons.MORE}
                iconSet={NadiusIcons.iconSet}
                text={'$button.addOrRemoveTranslation'}
                onPress={() => setLocaleModalVisible(true)}
            />

        <OptionsPickerModal
            close={() => setLocaleModalVisible(false)}
            visible={isLocaleModalVisible}
            options={availableLocales.map(locale => ({
                    key: locale, state: (usedLocales.indexOf(locale) != -1),
                    name: `$locale.${locale}`, disabled: (usedLocales.indexOf(locale) != -1 && usedLocales.length == 1)
                }))}
            onValueChange={_addOrRemoveLocale}
    />
    </View>
    
    );
}

import { Realm } from '@platform'
import AsyncStorage from '@react-native-community/async-storage';
import * as Localization from 'expo-localization';
import { User } from '@api'

// Returns the shared instance of the Realm app.
export const initializeApp = async (appId) => {
  console.log('Initializing App... ' + appId)
  let app = Realm.App.getApp(appId)
  if (app === null || typeof app === 'undefined') {
    const appConfig = {
      id: appId,
      timeout: 150
    };
    console.log('------------------')
    console.log('Creating new APP!')
    console.log('------------------')
    app = new Realm.App(appConfig)
  }
  return app;
}

export const getAccessToken = async (app) => {
  if (typeof app !== 'undefined') {
    let user = app.currentUser
    if (user !== null && typeof user?.id !== 'undefined' && user.id != null) {
      let access_token = await AsyncStorage.getItem('@nadius:access_token');
      if (access_token !== null) {
        access_token = JSON.parse(access_token)
        if (isAccessTokenValid(access_token)) {
          console.log('Access token retrieved from local storage')
          return access_token.value
        }
      }
      console.log('Refreshing token')
      let response = await fetch('https://realm.mongodb.com/api/client/v2.0/auth/session', {
        method: 'POST',
        headers: { "Authorization": `Bearer ${user.refreshToken}`, 'Content-Type': 'application/json' },
      });
      let json = await response.json()
      if (json.error && json.error_code == "InvalidSession") {
        console.warn('Invalid Session')
      }
      access_token = {
        userId: user.id,
        value: json.access_token,
        date: new Date()
      }
      try {
        await AsyncStorage.setItem(
          '@nadius:access_token',
          JSON.stringify(access_token)
        );
      } catch (error) {
        console.error(error)
      }
      return access_token.value
    }
  }
  return undefined
}

export const isAccessTokenValid = (access_token) => {
  // We need to check if 30 minutes have passed since the last refresh
  // TODO: Automatically refresh every 30m (better be done every 25 just in case)
  const maxElapsedTime = 25
  let user = app.currentUser
  return ((new Date() - new Date(access_token.date)) / 1000) / 60 < maxElapsedTime &&
    user.id == access_token.userId
}

export const isUserAnonymous = (app) => {
  if (app !== undefined) {
    let user = app.currentUser
    return user.customData === null
  }
}

const delay = async (time) => {
  return new Promise((resolve) =>
    setTimeout(
      () => { resolve('result') },
      time
    )
  );
}

export const loginUser = async (app, loginAnonymous = true) => {
  let user = app.currentUser
  let generalRetries = 0
  let locale = 'es';

  console.log('Login user')
  do {
    try {
      if ((user === null || typeof user === 'undefined')) {
        if (loginAnonymous) {
          user = await app.logIn(Realm.Credentials.anonymous())
        }
      } else if (user.customData !== null) {
        user = await User.refreshCustomData(loginAnonymous)
      }
      
      if (typeof user === 'undefined' ||
        user == null ||
        user.customData == null ||
        typeof user.customData.locale === 'undefined' ||
        user.customData.locale == null) {
        let fromStorage = await AsyncStorage.getItem('@nadius:locale');
        if (fromStorage === null) {
          locale = Localization.locale.split('-')[0];
        } else {
          locale = fromStorage
        }
      } else {
        locale = user.customData.locale;
      }

      break
    } catch (error) {
      console.log(error)
    }
    generalRetries += 1
    console.log('Retrying in ', 50 * generalRetries)
    await delay(50 * generalRetries);
  } while (generalRetries < 5)

  if (locale !== 'es' && locale !== 'ca') {
    locale = 'es'
  }
  console.log('Logged with locale ', locale)

  return locale
}

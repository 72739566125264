import i18n from 'i18n-js';


export const SUPORTED_LOCALES = Object.freeze(['es', 'ca'])

/**
 * @deprecated 
 * please use String.prototype.localise()
 */
export const replaceLocale = (text, locale) => {
    if (typeof text !== 'string') {
        return
    }
    //Match keys following this pattern: ($hello $hello.hello ...)
    let pattern = /\$(\w+(\.|))*/gm
    if (typeof locale !== 'undefined') {
        return text.replace(pattern, (key) => i18n.t(key.slice(1), { locale: locale }))
    }
    return text.replace(pattern, (key) => i18n.t(key.slice(1)))
}

export const getTranslationLocale = (object, selectedLocale) => {
    let translation = object;
    if (typeof translation === 'undefined' || translation == null) return undefined
    if (typeof translation.translations !== 'undefined' && translation.translations !== null) {
        translation = object.translations
    }

    let locale = selectedLocale ? selectedLocale : i18n.locale

    if (typeof translation[locale] === 'undefined' || translation[locale] == null)
        locale = 'main'

    if (typeof translation[locale] === 'undefined' || translation[locale] == null) {
        for (const key in SUPORTED_LOCALES) {
            if (typeof translation[SUPORTED_LOCALES[key]] !== 'undefined' &&
                translation[SUPORTED_LOCALES[key]] != null) {
                locale = SUPORTED_LOCALES[key]
                break
            }
        }
    }

    return locale
}

export const getTranslation = (object, selectedLocale) => {
    let translation = object;
    if (typeof translation === 'undefined' || translation == null) return undefined
    if (typeof translation.translations !== 'undefined' && translation.translations !== null) {
        translation = object.translations
    }

    return translation[getTranslationLocale(object, selectedLocale)]
}

export const getTranslationQuery = (fields, allLocales = false, noMain = false, includeLocale) => {
    let query = '';
    if (allLocales) {
        Object.keys(i18n.translations).forEach(locale => {
            query = query + `${locale} { ${fields} } `
        })
    } else {
        if (!noMain) {
            query = ` main { ${fields} locale } `;
            if (SUPORTED_LOCALES.includes(i18n.locale)) {
                query = query + ` ${i18n.locale} { ${fields} }`
            }
        } else {
            if (SUPORTED_LOCALES.includes(i18n.locale)) {
                query = query + ` ${i18n.locale} { ${fields} }`
            } else {
                query = query + ` main { ${fields} }`
            }
        }
        if (typeof includeLocale !== 'undefined' && includeLocale != i18n.locale) {
            query = query + ` ${includeLocale} { ${fields} }`
        }

    }

    if (query.length > 0) {
        return `translations { ${query} }`
    } else {
        return ''
    }
}
import React, { useState } from 'react';
import { StyleSheet, ScrollView, Image, View, SafeAreaView, RefreshControl, InteractionManager, Dimensions, Platform } from 'react-native';

import { ButtonText, SimpleText } from '@quarks'
import { AppStyle, TextStyle } from "@style";
import { PRODUCER_WELCOME } from '_images'
import { useAuth } from '@realm'
import { syntaxError } from 'graphql';


const WelcomeProcessScreen = (props) => {

    const styles = updateStyles()

    return (<>
        <ScrollView style={{
            paddingTop: 50, height: '100%',
            backgroundColor: 'white', width: Dimensions.get('window').width < 768 ? '100%' : '90%', alignSelf: 'center'
        }}
            contentContainerStyle={{
                width: '95%', justifyContent: 'center',
                alignSelf: 'center'
            }}>

            <SimpleText style={styles.welcomeTitle}>
            $access.welcomeProcess.title
            </SimpleText>
            <SimpleText style={styles.welcomeDescription}>
            $access.welcomeProcess.description
             </SimpleText>
            <SimpleText style={styles.welcomeSubtitle}>
            $access.welcomeProcess.subtitle
            </SimpleText>
            <View style={{
                flexDirection: 'row', alignSelf: 'center',
                width: '100%', justifyContent: 'center',
                flexWrap: 'wrap'
            }}>
                <View style={styles.stepView}>
                    <Image
                        style={styles.stepImage}
                        source={PRODUCER_WELCOME['process-1']}
                    />
                    <SimpleText style={styles.stepTitle}>
                    $access.welcomeProcess.step1Title
                                        </SimpleText>
                    <SimpleText style={styles.stepMessage}>
                    $access.welcomeProcess.step1Description
                    </SimpleText>
                </View>
                <View style={styles.stepView}>
                    <Image
                        style={styles.stepImage}
                        source={PRODUCER_WELCOME['process-2']}
                    />
                    <SimpleText style={styles.stepTitle}>
                    $access.welcomeProcess.step2Title
                    </SimpleText>
                    <SimpleText style={styles.stepMessage}>
                    $access.welcomeProcess.step2Description
                    </SimpleText>
                </View>
                <View style={styles.stepView}>
                    <Image
                        style={styles.stepImage}
                        source={PRODUCER_WELCOME['process-3']}
                    />
                    <SimpleText style={styles.stepTitle}>
                    $access.welcomeProcess.step3Title
                    </SimpleText>
                    <SimpleText style={styles.stepMessage}>
                    $access.welcomeProcess.step3Description
                    </SimpleText>
                </View>
                <View style={styles.stepView}>
                    <Image
                        style={styles.stepImage}
                        source={PRODUCER_WELCOME['process-4']}
                    />
                    <SimpleText style={styles.stepTitle}>
                    $access.welcomeProcess.step4Title
                    </SimpleText>
                    <SimpleText style={styles.stepMessage}>
                    $access.welcomeProcess.step4Description
                    </SimpleText>
                </View>
            </View>
            <ButtonText
                type={'solid'}
                style={{marginTop: 25, marginBottom: 25, paddingRight: 10, paddingLeft: 10}}
                textStyle={{ padding: 10, paddingLeft: 30, paddingRight: 30 }}
                text={'$access.welcomeProcess.startButton'} 
                onPress={()=>props.navigation.navigate('CreateProducerAccount')}/>
        </ScrollView>



    </>)
}


const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        color: AppStyle.mainColor,
        alignSelf: 'center',
        textAlign: 'center',
    },
    welcomeDescription: {
        marginTop: 15,
        width: '75%',
        maxWidth: 450,
        marginBottom: 10,
        color: AppStyle.getColor('dark', 1),
        alignSelf: 'center',
        textAlign: 'center'
    },
    welcomeSubtitle: {
        ...TextStyle.weight.bold,
        marginTop: 15,
        color: AppStyle.getColor('dark', 1),
        marginBottom: 10,
        alignSelf: 'center',
        textAlign: 'center'
    },
    stepView: {
        alignSelf: 'flex-start', margin: 10, width: 225,
        justifyContent: 'center'
    },
    stepMessage: {
        marginTop: 15,
        marginBottom: 10,
        color: AppStyle.getColor('dark', 1),
        alignSelf: 'center', textAlign: 'center'
    },
    stepImage: {
        height: 125, width: 125, margin: 5,
        alignSelf: 'center',
        textAlign: 'center'
    },
    stepTitle: {
        ...TextStyle.weight.bold,
        marginTop: 15,
        color: AppStyle.getColor('dark', 1),
        marginBottom: 10,
        alignSelf: 'center',
        textAlign: 'center'

    }

});

export default WelcomeProcessScreen;



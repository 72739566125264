import React from 'react';

import { SimpleText } from '@quarks'
import { TextStyle } from "@style";

export const PrivacyPolicySpanish = () => {

  return (
    <>
      <SimpleText style={{
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
      }}>
        Política de privacidad
    </SimpleText>
      <div className="container-s">
        <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
          En la siguiente política, Nadius se refiere al servicio ofrecido por Nadius (la "Compañía" o "Nosotros") a través del sitio web nadius.cat y la aplicación Nadius (el "Servicio"). Esta Política de privacidad explica (i) qué información recopilamos a través de su acceso y uso de nuestro Servicio (ii) el uso que hacemos de dicha información; y (iii) el nivel de seguridad que proporcionamos para proteger dicha información.
                  <br /> <br />
                  Al visitar y utilizar los Servicios proporcionados aquí a través de nadius.cat y la Aplicación Móvil Nadius (en conjunto, "Plataforma"), usted acepta los términos descritos en esta política de privacidad.
                  <h6 className="mt-3 mb-16 " data-reveal-delay="200">
            Marco legal
                  </h6>
                  La Compañia tiene su domicilio en España en la siguiente dirección:
                  <br />
                  Nadius, Avinguda Madrid 191, 08014 Barcelona
                  <br />
                  La infraestructura de almacenamiento de datos se encuentra en las instalaciones de AWS Frankfurt e Irlanda y, por lo tanto, se rige por las leyes y regulaciones de dichos países y de la UE.
                  <h5 className="mt-3 mb-16 " data-reveal-delay="200">
            Datos relacionados con la apertura de una cuenta
                  </h5>
                  Cuando te registras como miembro en la Plataforma como Comprador, debes proporcionar los siguientes datos para poder realizar el trámite de registro y acceder a tu cuenta Nadius:
                  <br /><br />Tu nombre;
                  <br />Correo electrónico;
                  <br />Confirmación de que eres mayor de 18 años;
                  <br />Contraseña. <br /> <br />
          <br />
                  Cuando te registras como miembro en la Plataforma como Vendedor, debes proporcionar los datos previamente detallados y los siguientes para poder realizar el trámite de registro y acceder a tu cuenta Nadius:
                  <br /><br />Tu nombre completo;
                  <br />Nombre de la empresa o entidad que realiza la Venta.
                  <br />Datos fiscales;
                  <br />Documentos que verifiquen la titularidad de la empresa;
                  <br />Número de teléfono;
                  <br />Año de nacimiento;
                  <br />Nacionalidad;
                  <br />País de residencia;

                  La base legal para la recopilación y el uso de datos es la ejecución de un contrato en el que usted es parte o para tomar medidas a su solicitud antes de celebrar un contrato (Art. 6 (1) (b ) del RGPD).
                  <br /> <br />
                  Dichos datos solo serán utilizados para contactarlo con notificaciones importantes sobre Nadius, para enviarle información relacionada con la seguridad, para mantenerlo actualizado sobre pedidos y productos obtenidos a través de la Plataforma, para verificar su cuenta de Nadius o para enviarle enlaces de recuperación de contraseña si habilitas la opción. También podemos informarle sobre nuevos productos Nadius en los que podría estar interesado. Usted es libre, en cualquier momento, de darse de baja de esas funciones a través del panel de configuración de la cuenta.
                  <br /> <br />
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Recopilación de datos
                  </h4>
          <p style={{ fontWeight: 'bold' }}>La política primordial de nuestra empresa es recopilar la menor cantidad posible de información de usuario para garantizar una experiencia de usuario privada al utilizar el Servicio.</p>
                  La recopilación de datos del usuario del servicio se limita a lo siguiente::
                  <br /> <br />
          <p style={{ fontWeight: 'bold' }}>Visitando nuestro sitio web: <p style={{ fontWeight: 'normal' }}>Cualquier solicitud realizada a través del sitio web, genera un registro temporal en nuestro Servicio de Cloud, que contiene la IP y la fecha de dicha solicitud, que luego se elimina después de varios días.</p></p>
          <p style={{ fontWeight: 'bold' }}>Comunicarse con Nadius: <p style={{ fontWeight: 'normal' }}>Nuestro personal puede guardar sus comunicaciones con la Compañía, como solicitudes de soporte, informes de errores o solicitudes de funciones. La base legal para el procesamiento es nuestro interés legítimo en solucionar problemas de manera más eficiente y mejorar la calidad de la plataforma Nadius.</p></p>
          <p style={{ fontWeight: 'bold' }}>Registro de IP: <p style={{ fontWeight: 'normal' }}>Por defecto, Nadius no mantiene registros de IP permanentes. Sin embargo, los registros de IP se pueden mantener temporalmente para combatir el abuso y el fraude. La base legal de este procesamiento es nuestro interés legítimo en proteger nuestro servicio contra actividades nefastas.</p></p>
          <p style={{ fontWeight: 'bold' }}>Recibir productos: <p style={{ fontWeight: 'normal' }}>Si elige ingresar su información de envío o cuando compra artículos, recopilamos y usamos los siguientes datos que usted proporciona para fines de envío:
                  <br /> <br />Nombre completo;
                  <br />País;
                  <br />Ciudad;
                  <br />Dirección;
                  <br />Número de teléfono;
                  <br />Otra información de envío requerida por el proveedor de servicios de envío en particular.
                  <br /> <br />
                  La base legal para la recopilación y el uso de datos es la ejecución de un contrato en el que usted es parte (Art. 6 (1) (b) del GDPR).
                  <br /> <br />
                  Los datos personales son necesarios para realizar un contrato con usted. En caso de que no nos proporcione estos datos personales, no podremos celebrar y ejecutar un contrato con usted.
                  <br /> <br />
                  Proporcionamos los datos personales recopilados y utilizados para este propósito al Vendedor del artículo y el vendedor debe seguir las instrucciones y proporcionar sus datos al proveedor de envío.
                  </p></p>
          <p style={{ fontWeight: 'bold' }}>Información de pago: <p style={{ fontWeight: 'normal' }}>La Compañía depende de terceros para procesar las transacciones con tarjetas de crédito, por lo que la Compañía necesariamente debe compartir la información de pago con terceros. La base legal de este procesamiento es la necesidad para la ejecución del contrato entre usted y nosotros.</p></p>
          <p style={{ fontWeight: 'bold' }}>Aplicaciones nativas: <p style={{ fontWeight: 'normal' }}>
            Cuando utiliza nuestras aplicaciones nativas, nosotros (o los proveedores de la plataforma de aplicaciones móviles) podemos recopilar cierta información además de la información mencionada en otra parte de esta Política.
            Podemos utilizar software de análisis móvil (como estadísticas de la aplicación Play Store, estadísticas de la aplicación App Store o informes de fallos y analítica de appcenter) para enviar información sobre fallos a nuestros desarrolladores para que podamos corregir errores rápidamente. .
                    <br /> <br /> Algunas plataformas (como Google Play Store o Apple App Store) también pueden recopilar estadísticas agregadas y anónimas, como qué tipo de dispositivos y sistemas operativos se utilizan con más frecuencia (como el porcentaje de Android 6. x vs Android 7.x), el número total de instalaciones, el número total de desinstalaciones y el número total de usuarios activos, y pueden regirse por la política de privacidad y los términos y condiciones de Google Play Store o Apple App Store.
                    <br /> <br /> Ninguno de los programas de nuestras aplicaciones accederá ni rastreará información basada en la ubicación de su dispositivo en ningún momento.
                    <br /> <br /> Todos los datos personales adquiridos durante este proceso se anonimizan. </p> </p>
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Uso de datos
                  </h4>
                  No tenemos publicidad en nuestro sitio. Cualquier dato que tengamos nunca será compartido, excepto bajo las circunstancias descritas a continuación en la Sección de Divulgación de Datos. Podemos hacer análisis de los datos limitados que poseemos, pero siempre para mejorar nuestros servicios y / o proporcionar estadísticas agregadas a los proveedores de la plataforma.
                  <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Almacenamiento de datos
                  </h4>
                  Todos los servidores utilizados en relación con la provisión del Servicio son controlados por Nadius y gestionados por MongoDB, Inc. Solo los empleados de la Compañía tienen acceso a los servidores. Los datos SIEMPRE se almacenan en formato cifrado en nuestros servidores. Las copias de seguridad sin conexión pueden almacenarse periódicamente, pero también están cifradas.
                  <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Derecho de acceso, rectificación, supresión, portabilidad y derecho a presentar una denuncia
                  </h4>
                  A través del Servicio, puede acceder, editar, eliminar o exportar directamente los datos personales procesados por la Compañía en su uso del Servicio.
                  <br /><br />
                  Si su cuenta ha sido suspendida por incumplimiento de nuestros términos y condiciones, y desea ejercer los derechos relacionados con sus datos personales, puede realizar una solicitud a nuestro equipo de soporte.
                  <br /><br />
                  En caso de violación de sus derechos, tiene derecho a presentar una queja ante la autoridad supervisora competente.
                  <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Retención de datos
                  </h4>
                  Cuando se cierra una cuenta de Nadius, todos los datos que no necesitan guardarse bajo ninguna obligación legal se eliminan de inmediato. Todo lo restante se marca como inaccesible desde nuestros servidores de producción y se guarda hasta que se cumplan todas las obligaciones legales. Los datos de las cuentas activas se conservarán de forma indefinida.
                  <br /><br />
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Divulgación de datos
                  </h4>
                  Solo divulgaremos los datos limitados del usuario que poseemos si así se nos indica mediante una solicitud totalmente vinculante procedente de las autoridades competentes (obligación legal).
                  <br /><br />
                  Si bien podemos cumplir con las notificaciones enviadas electrónicamente, los datos divulgados solo se pueden usar en el tribunal después de haber recibido una copia original de la orden judicial por correo certificado o en persona, y brindar una respuesta formal.
                  <br /><br />
                  Si lo permite la ley, Nadius siempre se comunicará con el usuario antes de cualquier divulgación de datos.
                  <br /><br />
                  Nadius puede, de vez en cuando, impugnar las solicitudes si existe un interés público en hacerlo. En tales situaciones, la Compañía no cumplirá con la solicitud hasta que se hayan agotado todos los recursos legales o de otro tipo. Por lo tanto, no todas las solicitudes darán lugar a la divulgación de datos.
                  <br /><br />
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Modificaciones a la política de privacidad
                  </h4>
                  Nadius se reserva el derecho de revisar y cambiar periódicamente esta política de vez en cuando. El uso continuado del Servicio se considerará como aceptación de dichos cambios.
                  </p>
      </div>
    </>
  );
}
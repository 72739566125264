import React, { useState } from 'react';
import { Dimensions, View, StyleSheet, ScrollView, Platform, LayoutAnimation, NativeModules, StatusBar } from 'react-native';
import Swiper from 'react-native-web-swiper';
import { LinearGradient } from 'expo-linear-gradient';
const { StatusBarManager } = NativeModules;
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { mergeDeep } from 'nexto-utils/objects'

import { useAuth } from '@realm'
import { TextStyle, AppStyle } from "@style";
import { SimpleText, ButtonText } from '@quarks'
import { ImageCard, LoadingView, ErrorView, PriceUnit, QuantitySteper } from '@atoms';
import { Product } from '@api'
import { getTranslation } from '@utilities/locale'
import { sessionOperations } from '_ducks/session';
import { Icons } from '@icons';


const ProductScreen = (props) => {

    const { user } = useAuth();
    const dispatch = useDispatch()
    let product = useSelector(state => state.session.modals.product)

    const [statusHeight, setStatusHeight] = React.useState(StatusBarManager?.HEIGHT)

    if (Platform.OS === 'ios') {
        StatusBarManager.getHeight((statusBarHeight) => {
            if (statusBarHeight?.height != statusHeight) {
                setStatusHeight(statusBarHeight?.height)
            }
        })
    }

    const styles = updateStyles();
    let windowWidth = Dimensions.get('window').width;

    if (Platform.OS == 'web' && windowWidth > 768) {
        windowWidth = 400
    }

    const carouselRef = React.useRef(null)

    let { query, param } = Product.getById(product._id);
    const { loading, error, data } = useQuery(query, param);

    if (!loading && !error && typeof data !== 'undefined') {
        product = JSON.parse(JSON.stringify({
            ...data.product,
            ...product,
            images: {
                ...product.images,
                ...data.product.images,
            },
            translations: mergeDeep ({}, product.translations, data.product.translations)
        }))
        if (data.product?.translations) {
            Object.keys(data.product?.translations).forEach(key => {
                if (key !== '__typename' && data.product?.translations?.[key]) {
                    product.translations[key] = {
                        ...product.translations[key],
                        ...data.product.translations[key]
                    }
                }
            })
        }
    }

    let _renderItem = function (item, index) {
        return (
            <ImageCard
                key={index}
                image={item.image}
                borderRadius={0}
                style={{
                    borderBottomLeftRadius: 0,
                    width: '100%', height: '100%',
                }}
            />
        )
    }

    let carouselItems = []
    if (typeof product?.images?.list !== 'undefined' && product?.images?.list !== null) {
        for (let i = 0; i < product.images.list.length; i++) {
            const image = product.images.list[i];
            carouselItems.push({ image: image })
        }
    }

    let translation = getTranslation(product)

    return (<ScrollView
        contentInsetAdjustmentBehavior="never"
        style={{ backgroundColor: AppStyle.backgroundColor }}
        contentContainerStyle={{ alignItems: 'center', alignContent: 'center', marginTop: -5 }}>
        <StatusBar translucent={true} backgroundColor={'transparent'}
            barStyle={'light-content'} />
        <LinearGradient colors={["#101329", "transparent"]} style={{
            position: 'absolute', top: 0,
            opacity: 0.5, height: statusHeight + 10, width: '100%', zIndex: 10,
        }} />
        {carouselItems.length > 0 && <View style={{
            height: windowWidth,
            width: windowWidth,
        }}>
            <Swiper
                ref={carouselRef}
                from={0}
                minDistanceForAction={0.1}
                controlsProps={{
                    dotsTouchable: true,
                    prevPos: 'left',
                    nextPos: 'right',
                    PrevComponent: ({ onPress }) => (<ButtonText
                        onPress={onPress}
                        icon={Icons.BACK}
                        iconColor={AppStyle.mainColor}
                        type={'solid'}
                        style={{ width: 30, height: 30, paddingRight: 2.5, 
                            marginLeft: windowWidth == 400 ? 0 : 10 }}
                        color={AppStyle.backgroundColor}
                    />),
                    NextComponent: ({ onPress }) => (<ButtonText
                        onPress={onPress}
                        icon={Icons.FORWARD}
                        iconColor={AppStyle.mainColor}
                        type={'solid'}
                        style={{ width: 30, height: 30, paddingLeft: 2.5, 
                            marginRight: windowWidth == 400 ? 0 : 10 }}
                        color={AppStyle.backgroundColor}
                    />),
                }} >
                {carouselItems.map((item, index) => _renderItem(item, index))}
            </Swiper>
        </View>}
        <SimpleText style={[styles.productNameText, { paddingTop: 20 }]}>{translation?.name}</SimpleText>
        <SimpleText style={styles.producerNameText}>{user.producer?.name}</SimpleText>
        {product?.stock === 0 && <View style={[styles.producerNameText]}>
            <ButtonText type={'solid'} disabled
                style={{ padding: 5, marginTop: 15, backgroundColor: AppStyle.getColor('secondary', 'sat') }} text={'No hay más unidades'} />
        </View>}
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            width: '85%',
            paddingTop: 10
        }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end', alignSelf: 'center' }}>
                <PriceUnit style={{ flex: 1 }} product={product} priceStyle={styles.priceText} unitStyle={styles.priceUnitText}></PriceUnit>
            </View>
        </View>
        <View style={{ width: '90%', alignItems: 'flex-start', paddingBottom: 50 }}>
            {!loading && !error &&
                <SimpleText style={[styles.descriptionText, { marginTop: 15 }]}>{translation.description}</SimpleText>}
            {loading && <LoadingView style={{ height: 200 }} />}
            {error && <ErrorView error={error} style={{ marginTop: 0, height: 100 }} />}
        </View>


    </ScrollView>
    )
}


export default ProductScreen;

let updateStyles = () => {
    return StyleSheet.create({
        sliderContentContainer: {
            paddingVertical: 10 // for custom animation
        },
        slider: {
            marginTop: 15,
            overflow: 'visible' // for custom animations
        },
        itemContainer: {
            borderRadius: 10,
            shadowOffset: {
                width: 0,
                height: 0
            },
            shadowOpacity: 0.1,
            shadowRadius: 10,
            elevation: 5,
            alignSelf: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent'
        },
        itemViewName: {
            padding: 10,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
        },
        productNameText: {
            ...TextStyle.size.xxxlarge,
            ...TextStyle.padding.xlarge,
            ...TextStyle.weight.bold,
            color: AppStyle.textColor,
            flex: 3,
        },
        producerNameText: {
            ...TextStyle.size.large,
            ...TextStyle.weight.bold,
            color: AppStyle.textColor,

        },
        priceText: {
            ...TextStyle.size.xlarge
        },
        priceUnitText: {
            ...TextStyle.weight.medium

        },
        descriptionText: {
            ...TextStyle.size.small,
            ...TextStyle.weight.light,
            color: AppStyle.textColor,

        },
        floatingView: {
            backgroundColor: 'transparent',
            position: 'absolute',
            alignSelf: 'flex-end',
            top: 15,
            right: 15
        },

        basketTitleView: {
            width: '100%',
            alignSelf: 'center',
            paddingBottom: 15,
            flexDirection: 'row',
            alignItems: 'flex-end',
        },
        basketTitle: {
            color: AppStyle.textColor,
            ...TextStyle.size.xxxlarge,
            flex: 3,
            alignItems: 'flex-end'
        },
    });
}
import React, { useState } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import i18n from 'i18n-js';
import moment from "moment/min/moment-with-locales"
import AsyncStorage from '@react-native-community/async-storage';
import { useSelector, useDispatch } from 'react-redux'

import { AppStyle } from "@style";
import {SUPORTED_LOCALES} from '@utilities/locale'
import { sessionOperations } from '_ducks/session'

import { PrivacyPolicyEnglish, PrivacyPolicySpanish, PrivacyPolicyCatalan } from './privacy/';

export const PrivacyScreen = (props) => {

    const styles = updateStyles()
    const dispatch = useDispatch()
    // In order to force a view update
    useSelector(state => state.session.language)

    const locale = props.route.params?.locale
    React.useEffect(() => {
        if (SUPORTED_LOCALES.includes(locale)) {
            i18n.locale = locale
            moment.locale(i18n.locale)
            AsyncStorage.setItem('@nadius:locale', locale)
            dispatch(sessionOperations.updateLanguage(locale))
        }
    }, [locale])

    return (<ScrollView
        style={{
            height: '100%',
            width: '100%',
            alignSelf: 'center',
            backgroundColor: AppStyle.backgroundColor
        }} contentContainerStyle={{ justifyContent: 'center', flexGrow: 1 }}>
        <View style={styles.mainView}>
            {(() => {
                switch (i18n.locale) {
                    case 'es':
                        return <PrivacyPolicySpanish />
                    case 'ca':
                        return <PrivacyPolicyCatalan />
                    default:
                        return <PrivacyPolicyEnglish />
                }
            })()}
        </View>
    </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    mainView: {
        paddingBottom: 50,
        paddingTop: 50,
        width: '90%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center'
    }
});
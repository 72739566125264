import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";
import { SUBMIT_RESPONSE_STATUS } from "_enums"
/* State shape
{
    details: producer,
    list: [ producer ],
}
*/

const reduceErrorForm = (state, action) => {
    console.log(action.errors)
    for (let key of Object.keys(action.errors)) {
        state[key] = action.errors[key]
    }

    return Object.assign({}, state)
}


const reduceSetForm = (state, action) => {
    let producer = action.producer

    if (producer) {
        let newState = {
            slogan: producer.name,
            description: producer.description,
            images: producer.images,
        }

        return Object.assign({}, newState)
    }
    
    let newState = {
        slogan: undefined,
        description: undefined,
        images: [],
    }

    return Object.assign(state, newState)
}

/*const clearErrors = (state, action) => {
    return {
        name: {ca:undefined, es:undefined},
        description: {ca:undefined, es:undefined},
        price: undefined,
        amount: undefined,
        amountUnit: undefined,
        weight: undefined,
        stock: undefined,
        tags: undefined,
        images: undefined,
    }
}

const defineStatus = (status) => (state, action) => {
    return status
}*/

const formReducer = createReducer(null)({
    //[types.RESET_FORM]: reduceSetForm,
    [types.SET_PRODUCER]: reduceSetForm,
});

const formErrorReducer = createReducer(null)({
    //[types.RESET_FORM]: clearErrors,
    [types.ON_SUBMIT_FAILURE]: reduceErrorForm,
});
/*
const reduceStatus = createReducer(null)({
    [types.ON_SUBMIT_FAILURE]: defineStatus(SUBMIT_RESPONSE_STATUS.FAILURE),
    [types.ON_SUBMIT_SUCCESS]: defineStatus(SUBMIT_RESPONSE_STATUS.SUCCESS),
    [types.RESET_FORM]: defineStatus(SUBMIT_RESPONSE_STATUS.UNDEFINED),
});
*/


export default combineReducers(
    {
        form: formReducer,
        errors: formErrorReducer,
        //status: reduceStatus,
    }
);
import React from 'react';

import { SimpleText } from '@quarks'
import { TextStyle } from "@style";

export const TermsSpanish = () => {
  return (
    <>
    <SimpleText style={{
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    }}>
        Términos y Condiciones de Uso de Nadius
    </SimpleText>
      El servicio de compra y venta de productos de proximidad online NADIUS (en lo sucesivo, denominado el “Sitio”).
      <br /> <br />
                  Antes de registrarse en el Sitio, todo Usuario deberá leer, aceptar y comprometerse a cumplir estas Condiciones Generales de uso (en lo sucesivo, denominadas “Condiciones Generales”), la Política de privacidad (según se indica en las definiciones, que también se actualizarán), así como las demás disposiciones obligatorias del Sitio.
      <h5 className="mt-3 mb-16 " data-reveal-delay="200">
        1. Objectivo
                  </h5>
                  1.1 Estas Condiciones Generales regulan las relaciones entre NADIUS y los Usuarios o Visitantes.
      <br /> <br />
                  1.2 Puede contactar con NADIUS cumplimentando el formulario haciendo clic aquí.
      <br /> <br />
                  1.3 El Sitio brinda al Usuario la oportunidad de pariticpar en la venta y/o compra de artículos, y de utilizar los demás Servicios Adicionales ofrecidos por el Sitio.
      <br /> <br />
                  1.4 Los Servicios no están destinados a menores. En caso de uso de estos servicios por parte de un menor, su padre o tutor (adulto) deberá registrarse previamente y supervisar dicho uso.
      <br /> <br />
                  Todos los Vendedores deberán ser de carácter profesional y solo podrán realizar las acciones de venta si NADIUS autoriza dicha acción.
      <br /> <br />
                  1.5 Al registrarse en el Sitio, el Usuario deberá indicar su nombre, su dirección de correo electrónico y su contraseña (explícitamente), para permitir que el Sitio identifique al Usuario cada vez que acceda al mismo. NADIUS puede solicitar, por motivos de seguridad, que se hagan ciertas confirmaciones en relación a su cuenta. Tales confirmaciones, entre otras, pueden consistir la confirmación a través de su número de teléfono; la aportación de una prueba de titularidad de la tarjeta bancaria; la provisión de un código PIN de uso único o cualquier otra confirmación que NADIUS pueda introducir a su entera discreción. Todos los datos recabados como resultado de dicha confirmación serán procesados siguiendo nuestra Política de Privacidad, que forma parte del acuerdo contractual entre NADIUS y el Usuario.
      <br /> <br />
                  NADIUS podrá, de manera plenamente discrecional, modificar las Condiciones Generales a fin de:
      <br /> <br />
                  a) adaptar las Condiciones Generales a las disposiciones legislativas o normativas vigentes o en proceso de adopción;
      <br /> <br />
                  b) adaptar las Condiciones Generales a cualquier resolución dictada por un Juzgado, agencia de consumidores o cualquier autoridad competente, que afecte al contenido del Sitio;
      <br /> <br />
                  c) rectificar cualquier error (p. ej., errores ortográficos u otros tipos de errores que no alteren el significado de frases existentes) no detectado hasta el momento.
      <br /> <br />
                  d) hacer que las presentes condiciones sean más claras o más imparciales;
      <br /> <br />
                  e) reflejar cambios y evoluciones en la gestión de nuestra actividad empresarial;
      <br /> <br />
                  f) reflejar cambios en las condiciones de mercado o prácticas habituales de la industria.
      <br /> <br />
                  Dentro de los límites de las leyes aplicables, en caso de modificación de las Condiciones Generales no prevista en los tres apartados anteriores, (incluida la modificación en la Lista de Precios, por ejemplo, para añadir nuevos servicios) los Usuarios serán informados mediante notificación en el Sitio quince (15) días antes de que la modificación sea efectiva o, en caso de una modificación más sustancial (p. ej., modificaciones que incrementen las obligaciones de los Usuarios o afecten a las funcionalidades principales de la plataforma), mediante un correo electrónico enviado a la dirección de correo electrónico proporcionada en el momento del registro cuatro (4) semanas antes de que la modificación sea efectiva. Si no está conforme con alguna de las modificaciones propuestas, podrá poner fin a su relación con NADIUS y cerrar su cuenta de NADIUS, tal como se describe en el apartado 15.3. Si continúa utilizando el Sitio después de los plazos antes indicados, se considerará que ha aceptado la modificación y los Servicios se prestarán con arreglo a las Condiciones Generales modificadas. Cualquier modificación de las Condiciones Generales publicada en el Sitio se aplicará de forma inmediata a los Visitantes.
      <br /> <br />
                  1.6 Se recuerda al Usuario que Internet no es una red segura. El Usuario reconoce haber sido completamente informado sobre la baja fiabilidad de Internet, en especial sobre la ausencia de seguridad en la transmisión de datos y sobre la no garantía de rendimiento respecto del volumen y velocidad de la transmisión de datos. Intentamos mantener NADIUS lo más seguro posible. No obstante, Internet no es una red segura y la fiabilidad del sistema podría interrumpirse independientemente de la voluntad de NADIUS, NADIUS no será responsable de sucesos impredecibles tales como ausencia de seguridad en la transmisión de datos ni de la garantía de rendimiento respecto del volumen y velocidad de la transmisión de datos. Bajo estas condiciones, depende del Usuario la adopción de medidas adecuadas para proteger sus propios datos y/o software, sobre todo ante la infección de virus que circulen por Internet.
      <br /> <br />
      <h5 className="mt-3 mb-16 " data-reveal-delay="200">
        2. Definiciones
                  </h5>
      <p style={{ fontWeight: 'bold' }}>Comprador<p style={{ fontWeight: 'normal' }}>significa cualquier Usuario que compre o desee comprar uno o más artículos en el Sitio;</p></p>

      <p style={{ fontWeight: 'bold' }}>Artículos<p style={{ fontWeight: 'normal' }}>significa los bienes/artículos cuya publicidad no esté prohibida por la legislación nacional, y que el Usuario autorizado por NADIUS anuncia en el Catálogo correspondiente del Sitio con el objetivo de venderlos o donarlos;</p></p>

      <p style={{ fontWeight: 'bold' }}>Catálogo<p style={{ fontWeight: 'normal' }}>significa el catálogo electrónico en el que se anuncia el mismo tipo de artículos, entendiéndose que todos los Usuarios deben cumplir las normas del catálogo establecidas por NADIUS;</p></p>

      <p style={{ fontWeight: 'bold' }}>Cuenta del Usuario o Cuenta<p style={{ fontWeight: 'normal' }}>significa el registro del Usuario en el Sitio; la cuenta creada contiene datos personales;</p></p>

      <p style={{ fontWeight: 'bold' }}>Cuentas y Redes sociales<p style={{ fontWeight: 'normal' }}>significa las cuentas de Facebook, Google+, Twitter, Instagram, Pinterest, Youtube y otras redes sociales en las que se proporciona información sobre el Sitio y sobre los Servicios que ofrece, compartiendo los contenidos publicados por Usuarios;</p></p>

      <p style={{ fontWeight: 'bold' }}>Confirmación de Recepción<p style={{ fontWeight: 'normal' }}>significa la confirmación de recepción del artículo por el Comprador o el Usuario;</p></p>

      <p style={{ fontWeight: 'bold' }}>Lista de Precios<p style={{ fontWeight: 'normal' }}>significa el documento que muestra los precios de los Servicios de pago de NADIUS;</p></p>

      <p style={{ fontWeight: 'bold' }}>Mensajes privados<p style={{ fontWeight: 'normal' }}>significa la comunicación mediante el intercambio de mensajes privados entre Usuarios a través del sistema de mensajería del Sitio que solamente los Usuarios en cuestión podrán consultar;</p></p>

      <p style={{ fontWeight: 'bold' }}>Noticias<p style={{ fontWeight: 'normal' }}>significa ofertas a corto y largo plazo, competiciones o nuevos Servicios publicados en el Sitio;</p></p>

      <p style={{ fontWeight: 'bold' }}>Política de privacidad<p style={{ fontWeight: 'normal' }}>significa el documento que define la forma en que NADIUS recopila, utiliza, almacena y transfiere datos personales y demás información importante sobre un Visitante o un Usuario;</p></p>

      <p style={{ fontWeight: 'bold' }}>Servicios<p style={{ fontWeight: 'normal' }}>significa todos los servicios, incluyendo los Servicios Principales y los Servicios Adicionales;</p></p>

      <p style={{ fontWeight: 'bold' }}>Servicios Adicionales<p style={{ fontWeight: 'normal' }}>significa los servicios de pago adicionales proporcionados por NADIUS y publicados en el Sitio (según se muestra en la Lista de Precios), tales como, por ejemplo, los Servicio de Verificación de Identidad, que no se consideran servicios principales y que estarán sujetos a condiciones generales de uso independientes;</p></p>

      <p style={{ fontWeight: 'bold' }}>Servicio de Pago Integrado<p style={{ fontWeight: 'normal' }}>significa el Servicio de pago online de Artículos accesibles para el Comprador en el Sitio. Los servicios de pago corren a cargo del socio de NADIUS;</p></p>

      <p style={{ fontWeight: 'bold' }}>Servicios Principales<p style={{ fontWeight: 'normal' }}>significa la opción que ofrece NADIUS a los Usuarios y Visitantes, especialmente, pero sin limitación, de anunciar los Artículos por parte de Vendedores en el Catálogo correspondiente, examinar los Artículos, participar directamente en compraventas y comunicarse entre ellos en privado;</p></p>

      <p style={{ fontWeight: 'bold' }}>Sitio<p style={{ fontWeight: 'normal' }}>significa el sitio web de NADIUS, así como las aplicaciones móviles de NADIUS descargadas en smartphones o tabletas;</p></p>

      <p style={{ fontWeight: 'bold' }}>Transacción<p style={{ fontWeight: 'normal' }}>significa todas las transacciones que transfieran  y de un Artículo de un Usuario a otro, incluyendo los contratos de venta formalizados directamente entre el Comprador y el Vendedor y cualquier otra transacción llevada a cabo entre Usuarios;</p></p>

      <p style={{ fontWeight: 'bold' }}>Usuario<p style={{ fontWeight: 'normal' }}>significa cualquier persona o empresa que, tras haberse registrado en el Sitio, está autorizada para usar todos los Servicios del Sitio para satisfacer sus necesidades personales o empresariales.</p></p>

      <p style={{ fontWeight: 'bold' }}>Vendedor<p style={{ fontWeight: 'normal' }}>significa cualquier Usuario autorizado por NADIUS que anuncie uno o más Artículos en el Catálogo correspondiente del Sitio;</p></p>

      <p style={{ fontWeight: 'bold' }}>Visitante<p style={{ fontWeight: 'normal' }}>significa cualquier persona no registrada en el Sitio que pueda usar el Sitio sin registrarse, de acuerdo con las Condiciones Generales.</p></p>

      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        3. SERVICIOS
                  </h4>
                  3.1 NADIUS es un intermediario online de servicios de alojamiento (hosting service) que ofrece a los Compradores y Vendedores un lugar de venta virtual, denominado el Sitio o Aplicación y un Catálogo sistematizado. Los Usuarios con caracter de Vendendor tienen la oportunidad de anunciar sus Artículos en el Catálogo correspondiente del Sitio y los Compradores tienen la oportunidad de comprar cualquier Articulo que aparezca en el Catálogo. Así como también, los Usuarios pueden comunicarse mediante el envío de Mensajes Privados y usar otros Servicios Adicionales ofrecidos por el Sitio. NADIUS no participa en ninguna Transacción entre Usuarios. Bajo ninguna circunstancia NADIUS comprará, venderá o intercambiará los Artículos presentados en el Sitio ni afirmará hacerlo. NADIUS ofrece Servicios que facilitan las Transacciones.
      <br /><br />
                  3.2 Las Condiciones Generales, la Política de privacidad y las demás normas publicadas en el Sitio de NADIUS son aplicables a los Usuarios y Visitantes de las Cuentas de Redes Sociales de NADIUS, y deberán ajustarse a ellas.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        4. ANUNCIO DE ARTÍCULOS
                  </h4>
                  4.1 Como requisito previo:
      <br /><br />
                  Solo los Usuarios verificados y aprobados por NADIUS serán aptos para anunciar Artículos. <br />
                  Solo los Artículos estipulados en las reglas del Catálogo pueden ser listados en el Sitio.<br />
                  El Vendedor deberá garantizar que sus Artículos cumplan las normas del Catálogo. Algunas categorías de artículos no pueden ser subidas.<br />
                  El Vendedor deberá ser el propietario de estos Artículos y tener el derecho a venderlos o donarlos.<br />
                  La venta, uso o posesión de dichos Artículos no deberá violar los derechos de terceros, deberá respetar los derechos de propiedad intelectual y no deberá violar ninguna ley o normativa local, nacional o internacional.<br />
                  El Artículo deberá, dependiendo de la naturaleza de la Transacción, cumplir los requisitos mínimos del mercado para el tipo de Artículo a menos que Comprador y Vendedor acuerden lo contrario.<br />
      <br /><br />
                  4.2 El Vendedor, al anunciar un Artículo en el Catálogo, deberá completar un cuestionario en relación al anuncio del Artículo. El Vendedor deberá describir el Artículo lo más precisamente posible y establecer el precio del Artículo. Al poner el Artículo en venta, el Vendedor declara que éste cumple con la descripción proporcionada y que esta descripción es exhaustiva. El anuncio de Artículos en el Catálogo del Sitio es gratuito. Los Vendedores pueden optar por pagar Servicios Adicionales, por ejemplo, para mejorar la visibilidad de sus artículos.
      <br /><br />
                  4.3 El Artículo anunciado en el Catálogo no incluirá solo una descripción sino que también incluirá una fotografía. La fotografía se colocará al lado de la descripción del Artículo. Deberá descargarse al menos una fotografía de buena calidad al anunciar el Artículo (se prohíbe el uso de fotografías descargadas de Internet y/o fotografías de un Artículo similar). La fotografía debe reflejar el aspecto real.
      <br /><br />
                  4.4 La cantidad de Artículos anunciados en el Catálogo del Sitio es ilimitada. Se prohíbe anunciar más de una vez el mismo Artículo.
      <br /><br />
                  4.5 El Vendedor que haya puesto a la venta un Artículo podrá retirar el Artículo o modificar su precio en cualquier momento, pero sin afectar ventas pasadas ni en proceso de venta.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        5. VENTA DE ARTÍCULOS
                  </h4>
                  5.1 El hecho de poner a la venta un Artículo del Catálogo constituirá una oferta de venta del Artículo en cuestión por parte del Vendedor, que podrá ser aceptada por un Comprador.
      <br /><br />
                  5.2 Sin acuerdo establecido por NADIUS y el Vendedor, el Vendedor será el único responsables de organizar el envío del Artículo, sin opción de que NADIUS pueda proporcionar asistencia.
      <br /><br />
                  5.3 En el caso de una venta, el Comprador reconoce que la aceptación de la oferta se ha efectuado teniendo en cuenta la descripción del Artículo que es objeto de venta y que representa un compromiso firme de formalizar un contrato con el Vendedor según las condiciones de la oferta.
      <br /><br />
                  5.4 El Vendedor se hace plenamente responsable del estado de los productos y que estos cumplan la normativa necesaria para su venta.
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        6. SERVICIO DE PAGO INTEGRADO
                  </h4>
      <br /><br />
                  6.1 En el caso de que se usen los Servicios Integrados de Pago, se facilitará y garantizará el pago. Por tanto, en virtud de dicha Protección adicional del Comprador, NADIUS cobra las comisiones correspondientes a dichos servicios. En tal caso, por cada Transacción, se cobrará al Vendedor una comisión que se añadirá al precio de compra por el uso de este Servicio ofrecido por NADIUS. Dicha comisión sera establecida por NADIUS y el Vendedor en el proceso de autorización como Vendendor en el Sitio.
      <br /><br />
                  6.2 Como parte del Servicio de Pago Integrado, el Comprador deberá abonar por adelantado el/los Artículo(s) comprado(s) al Vendedor mediante tarjeta de crédito o débito, o cualquier otro método de pago que pueda ser introducido en el futuro, utilizando el Servicio de Pago Integrado disponible en el Sitio.
      <br /><br />
                  6.3 Los importes abonados por el Comprador serán retenidos por un proveedor de servicios financieros seleccionado por NADIUS en un monedero electrónico que funcionará como una cuenta de garantía hasta que (i) el Comprador confirme a NADIUS que el Artículo se ha recibido, que cumple con lo ofertado y que está satisfecho al respecto o (ii) una semana después de la fecha en que el Artículo debía ser entregado. La transacción de venta se completará (i) una vez que el Comprador confirme a NADIUS que está satisfecho con el Artículo y que cumple con lo ofertado, o (ii) tras el vencimiento del período de controversia, o (iii) tras la resolución de una controversia, si la hubiera. Una vez completada la transacción los fondos se transferirán inmediatamente al monedero electrónico del Vendedor. El dinero del monedero electrónico del Usuario podrá retirarse en cualquier momento de su cuenta bancaria, sin ningún cargo por retirada, independientemente del importe.
      <br /><br />
                  6.4 Si surgen sospechas de fraude de cualquier tipo respecto a un Usuario, NADIUS suspenderá de inmediato la cuenta del Usuario en cuestión, así como las transacciones en curso, en espera de los resultados de la investigación que se lleve a cabo.
      <br /><br />
                  6.5 En el caso de cualquier problema notificado a NADIUS por el proveedor responsable del Servicio de Pago Integrado, NADIUS suspenderá de inmediato la cuenta del Usuario en cuestión, así como las transacciones en curso, en espera de los resultados de la investigación que se lleve a cabo.
      <br /><br />
                  6.6 NADIUS se reserva el derecho de suspender la cuenta de un Usuario si el Usuario plantease una disputa a través de un proveedor de servicios de pago externo y NADIUS tuviese motivos legítimos para hacerlo (por ej. en caso de incumplimiento de la legislación aplicable y/o de las presentes Condiciones Generales, y/o en caso de que sea probable un uso indebido del Sitio).
      <br /><br />
                  6.7 NADIUS cuenta con un proveedor de servicios externo para procesar el pago y archivar la información respecto de la tarjeta de crédito o débito. A fin de poder utilizar el Servicio de Pago Integrado, el Comprador declara que acepta las condiciones generales de este proveedor. El comprador será el único responsable de la transmisión de información relativa a la tarjeta de crédito o débito y de conformidad con las presentes condiciones generales NADIUS queda exonerada de toda responsabilidad.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        7. Servicio Integrado de Pago / Reembolso / Aceptación de los artículos / Protección al Comprador
                  </h4>
                  7.1 El Comprador que use el Servicio Integrado de Pago será apto para recibir un reembolso con arreglo a la Protección del Comprador si se cumplen las condiciones que se describen a continuación:
      <br /><br />
                  7.1.1 Si el artículo no cumplía sustancialmente con la descripción ofrecida por el Vendedor, inclusive si el artículo ha resultado dañado durante el envío, el Comprador tendrá cinco (5) días hábiles, contados a partir de la fecha de entrega del artículo, para informar a NADIUS haciendo clic en el apartado de reporte de incidencia en los detalles del pedido. Esto suspenderá la Transacción y NADIUS retendrá la totalidad del precio de compra. El Comprador y el Vendedor tendrán derecho a resolver la situación por su cuenta y a informar a NADIUS acerca de su decisión conjunta. En tal caso, NADIUS se comprometerá a acatar dicha decisión y a reembolsar al Comprador o al Vendedor. Si el Comprador y el Vendedor no llegasen a un acuerdo de forma directa, o si no deseasen resolver su situación de forma directa, cualquiera de ellos podrá elevar la cuestión a NADIUS. Una vez que NADIUS haya analizado la situación y en el caso de que NADIUS considere que, efectivamente, el artículo no es sustancialmente tal como se describía en el catálogo, NADIUS cancelará la Transacción informando al proveedor de servicios financieros terceros que gestione la cuenta en garantía de que reembolse al Comprador una vez que el artículo en cuestión haya sido devuelto al Vendedor. El Comprador recibirá un reembolso por el precio de compra total. Tenga en cuenta que NADIUS no ofrece servicios de envío de devoluciones y que el Comprador deberá pagar el envío de su devolución, salvo que se acuerde algo distinto con el Vendedor. Podrán darse excepciones a la obligación de devolver el artículo concretamente cuando existan sólidos indicios de que el artículo es una falsificación.
      <br /><br />
                  7.1.2 Si el Comprador no recibió un artículo, deberá informar a NADIUS indicando el problema haciendo clic en el apartado de reporte de incidencia en los detalles del pedido que suspende la Transacción dentro de un plazo de cinco (5) días a partir de la fecha mencionada al Comprador en la que se esperaba que el Artículo fuese entregado. En tal caso, y como el Vendedor es el responsable total del proceso de Envío, se le informará al Vendedor y este se tiene que hacer cargo de gestionar dicha situación. En caso de no resolverse en un plazo de una (1) semana, se reembolsará al Comprador el importe completo y se le restará la comisión de procesamiento de pago que NADIUS ha podido tener en el momento de procesar el pago durante el proceso de compra al Vendedor.
      <br /><br />
                  7.1.3 Si el Comprador no se pone en contacto con NADIUS en los cinco (5) días estipulados en los apartados 7.1.1 y 7.1.2, o confirma en el Sitio que el artículo recibido era aceptable (durante dicho plazo de 5 días), se considerará completada la Transacción y finalizará la protección del reembolso del Comprador. En tal caso, el Comprador no tendrá derecho a ningún reembolso por parte de NADIUS.
      <br /><br />
                  7.1.4 En el caso de que el artículo se ajuste a la descripción ofrecida por el Vendedor, el Comprador no podrá solicitar el reembolso a través de NADIUS y NADIUS completará la Transacción. Una vez que se haya completado la Transacción y que el dinero mantenido en garantía se haya puesto a disposición del Vendedor, el Comprador podrá intentar negociar directamente con el Vendedor la devolución del artículo (por ej. acuerdo relativo al reembolso, gastos de envío de la devolución, etc.). Tenga en cuenta que, en tal caso, el Vendedor también podrá negarse a colaborar con el Comprador alegando que el Vendedor ha cumplido con sus obligaciones relativas a la Transacción.
      <br /><br />
                  7.2 Si el Comprador no usa el Servicio Integrado de Pago:
      <br /><br />
                  7.2.1 En tal caso, NADIUS no cobrará comisión alguna, pero tampoco garantizará la seguridad de los pagos que se realicen. NADIUS no ofrecerá asistencia al Comprador ni al Vendedor. El Comprador y el Vendedor serán los responsables exclusivos de la implementación de la Transacción y deberán negociar todos los detalles de la Transacción directamente por sí mismos (por ej. condiciones de pago, envío, condiciones de devolución del artículo, paquetes perdidos, etc.).
      <br /><br />
                  7.2.2 En caso de conducta indebida por parte del Comprador o del Vendedor, NADIUS podrá ofrecer asistencia a la parte afectada. NADIUS también colaborará con las instituciones gubernamentales.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        8. PAGO
                  </h4>
                  8.1 NADIUS está autorizado a cobrar los importes como parte de los Servicios prestados de acuerdo con los precios indicados en la Lista de Precios. El Usuario acepta que NADIUS está autorizado a modificar la Lista de Precios según las condiciones del apartado 1.5 anterior. Si el Usuario no estuviese de acuerdo con alguna de las modificaciones propuestas, podrá optar por finalizar su relación con NADIUS y cerrar su cuenta NADIUS, tal como se describe en la apartado 14.3. Las modificaciones en los Precios no se aplicarán a Transacciones que estén en curso (especialmente aquellas para las cuales el Comprador ya haya realizado el pago).
      <br /><br />
                  8.2 Todos los precios están expresados en euros (EUR) e incluyen todos los impuestos.
      <br /><br />
                  8.3 En el caso de que una Transacción se efectúe mediante el Servicio Integrado de Pago, el pago del Comprador se efectuará mediante tarjeta virtual o bancaria, o mediante cualquier otro método de pago que se introduzca en su momento, en el interfaz de pago integrado del Sitio. Si no se utiliza el Pago Integrado, el pago se efectuará mediante el método que se establezca entre el Vendedor y el Comprador.
      <br /><br />
                  8.4 En el caso de que una Transacción se efectúe mediante el Servicio Integrado de Pago, se cobrará al Comprador el importe total (precio del artículo(s) y el Vendedor cobrará el importe que se le adeude una vez confirmada la recepción del artículo por parte del Comprador menos la comision establecida por NADIUS, en el momento de autorización como Vendendor. Si el Comprador no recibe el artículo o no está satisfecho con él, se efectuará un abono en su cuenta.
      <br /><br />
                  8.5 En el caso de que se lleve a cabo una transacción sin el Servicio Integrado de Pago, el Comprador y el Vendedor deberán acordar las condiciones de pago tal como se describen en el apartado 7.2.1 anterior. En tal caso, NADIUS no cobrará comisión alguna sobre la Transacción.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        9. INTERACCIÓN Y MENSAJES EN EL SITIO
                  </h4>
                  9.1 Mensajes privados
      <br /><br />
                  El intercambio de Mensajes privados tiene como finalidad principal el intercambio de información entre Usuarios, con respecto a un pedido o Artículo. En consecuencia, si un Usuario o un Visitante envía Mensajes Privados a otro Usuario, deberá asegurarse de no enviar:
      <br /><br />
                      mensajes o información que incluyan publicidad;<br />
                      spam o contenido que propague virus o troyanos;<br />
                      mensajes de envío masivo de ningún tipo (si un mensaje se envía a más de cinco Usuarios o si el mismo mensaje se copia y envía a Usuarios que no han solicitado recibirlo);<br />
                      mensajes que contengan textos contrarios a los estándares de moralidad y orden público, mensajes insultantes o difamatorios, y también aquellos que se consideren incompatibles con estas Condiciones Generales y los intereses de los Usuarios;<br />
                      mensajes de naturaleza ilícita o que pretendan causar daños a otros Usuarios y/o al Sitio.<br />
      <br /><br />
                  En el uso de algoritmos, NADIUS utiliza un software automatizado que le permita detectar cualquier oferta contraria a los estándares de moralidad u orden público, o aquellas que sean inapropiadas, insultantes o difamatorias y comunicadas por un Usuario a través de Mensajes Privados. En el supuesto de que un Mensaje Privado contenga alguna de las condiciones antes mencionadas sea detectado por el software automatizado, el Mensaje Privado podrá ser automáticamente bloqueado y/u ocultado al Usuario que reciba el Mensaje Privado. El Usuario que esté enviando el Mensaje Privado que ha sido bloqueado y/u ocultado, será advertido sobre su obligación de cumplir las Condiciones Generales. NADIUS tiene acceso al contenido de los Mensajes Privados, incluyendo aquellos que son bloqueados y/u ocultados por el software automatizado.
      <br /><br />
                  9.2 Intercambio de evaluación entre Usuarios
      <br /><br />
                  Un Comprador tiene derecho a escribir evaluaciones sobre un Vendedor exclusivamente si se ha efectuado una Transacción entre ellos. No se ofrecerá ninguna remuneración a los Compradores ni a NADIUS a cambio de sus valoraciones en Internet.
      <br /><br />
                  La evaluación de un Comprador respecto a un Vendedor deberá ser siempre justa y honesta. Se prohíben las mentiras y los insultos.
      <br /><br />
                  NADIUS no revisa las valoraciones antes de que sean publicadas por los Usuarios.
      <br /><br />
                  Si la valoración obtenida es inadecuada o abusiva, los Usuarios deberán comunicarlo a NADIUS.
      <br /><br />
                  NADIUS está autorizado a retirar del Sitio cualquier evaluación que infrinja estas Condiciones Generales o los derechos de otros Usuarios, incluyendo Usuarios que no tuvieran derecho a escribir evaluaciones según los términos de estas Condiciones Generales.
      <br /><br />
                  NADIUS, tras identificar al Usuario que no ha respetado estas normas, podrá hacer uso de sus derechos de bloqueo de la cuenta del Usuario en cuestión, total o parcialmente.
      <br /><br />
                  Los visitantes ni los Vendedores, tienen derecho a escribir evaluaciones sobre Vendedores.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        10. RESPONSABILIDAD
                  </h4>
                  10.1 Cada Vendedor es plenamente responsable en su calidad de editor, de toda la información que publique en el Sitio y, si procede, de los Artículos que en consecuencia anuncien y/o vendan. En especial, se manifiesta que si un Vendedor publica Artículos en el Catálogo correspondiente, el Vendedor reconoce y acepta ser totalmente responsable de la descarga del Artículo en el Catálogo correspondiente, de su descripción, de la precisión de los demás datos proporcionados, de la comunicación con Compradores y, en general, de las Transacciones efectuadas con Compradores y las controversias que puedan derivarse de dichas Transacciones (que, en lo sucesivo, se denominará el “Contenido”).
      <br /><br />
                  10.2 En este sentido, el Visitante y el Usuario deben respetar todas las normas aplicables. Los Usuarios y Visitantes deberán abstenerse de: (i) vulnerar los derechos de terceros, (ii) vulnerar los derechos de propiedad intelectual o anunciar artículos falsificados, (iii) incitar ofensas de delitos, discriminación, odio o violencia racial, por razón del origen étnico o de nacionalidad, (iv) comunicar información incorrecta o confidencial, (v) realizar ofertas difamatorias, (vi) emprender acciones que pudieran poner en peligro a menores, (vi) publicar los datos personales de otras personas o violar los derechos de privacidad o (vii) usurpar la identidad de otros.
      <br /><br />
                  10.3 En caso de que el Contenido no respete las normas aplicables de acuerdo con el artículo 10.2 o, en general, un Usuario o Visitante no respete estas Condiciones Generales, el Usuario o Visitante acepta ser el único responsable de los daños directos y/o indirectos respecto de un tercero o de NADIUS. En consecuencia, el Usuario o Visitante reconoce y acepta que, en su calidad de anfitrión, NADIUS no llevará a cabo bajo ninguna circunstancia ninguna comprobación del Contenido ni se responsabilizará por tales pérdidas salvo que (i) haya sido informado de la existencia de contenido ilegal en el sentido de la legislación vigente, de acuerdo con el procedimiento descrito en el apartado 10.4, y (ii) y no haya actuado diligentemente para retirarlo de la Web. En concreto, NADIUS no será responsable de: (i) acciones u omisiones de Visitantes y Usuarios, (ii) la información publicada en el Sitio por Usuarios y Visitantes, sus temas, su precisión, su exhaustividad y/o su cumplimiento de las normas aplicables, o (iii) la calidad y cantidad de Artículos que vendan o compren mediante el uso del Sitio, ni de su conformidad con la descripción facilitada.
      <br /><br />
                  Catálogo
      <br /><br />
                  10.4 NADIUS no es responsable del Contenido online. Los Vendedores serán responsables del Contenido cargado online y NADIUS podrá eliminar contenidos de los Vendedores que le hayan sido debidamente notificados de acuerdo con la legislación aplicable y que infrinjan dicha legislación y/o las políticas de NADIUS. Asimismo, podremos tomar medidas contra la cuenta del Vendedor. Si un Usuario o Visitante descubre Contenido que haga apología de crímenes contra la humanidad, incite al odio racial y/o a la violencia o se refiera a pornografía infantil, NADIUS debe ser informado inmediatamente:
      <br /><br />
                      mediante el procedimiento de información descrito aquí;<br />
                      o escribiendo un correo electrónico a la dirección hola@nadius.cat;<br />
      <br /><br />
                  En todos estos casos, NADIUS suspenderá de inmediato la cuenta del Usuario en cuestión, así como las transacciones en curso, en espera de los resultados de la investigación que se realizará.
      <br /><br />
                  Si el Usuario considera que algún Contenido es susceptible de afectar a sus derechos o a los de un tercero (falsificación, insulto, vulneración de la privacidad), el Usuario puede notificarlo a NADIUS:
      <br /><br />
                      mediante el procedimiento de información descrito aquí;<br />
                      o escribiendo un correo electrónico a la dirección hola@nadius.cat;<br />
      <br /><br />
                  De manera más general, los Usuarios deberán abstenerse de subir contenido que sea deshonesto, falso o inapropiado. Los Usuarios deberán actuar y usar la plataforma de buena fe.
      <br /><br />
                  10.5 Una vez que el Comprador haya efectuado la compra de los Articulos expuestos por el Vendedor, el Vendedor es responsable de entregar el artículo de forma correcta y en el plazo adecuado al Comprador. En todo caso, si se suscita una discrepancia entre el Comprador y el Vendedor con respecto a la entrega del Artículo, el Vendedor debe demostrar que el Artículo se ha enviado (puede hacerlo mediante el número de seguimiento, una factura o un documento de la empresa de mensajería urgente).
      <br /><br />
                  10.6 En el caso de una Transacción efectuada sin el Sistema Integrado de Pago ofrecido por NADIUS, el Comprador es responsable del pago del Artículo solicitado de forma correcta y en el plazo adecuado, con arreglo a las condiciones comunicadas por el Vendedor o según las condiciones conjuntamente pactadas.
      <br /><br />
                  10.7 En el caso de que se lleve a cabo una Transacción sin el Servicio Integrado de Pago ofrecido por NADIUS, el Usuario acepta que NADIUS no es responsable de las controversias que se produzcan entre Compradores y Vendedores, que no ofrecerá asistencia y que no se involucrará en su resolución. Tales controversias pueden resolverse de conformidad con la legislación nacional aplicable, a menos que las partes hayan decidido conjuntamente que sean de aplicación las leyes de otro país.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        11. OBLIGACIONES DEL USUARIO
                  </h4>
                  11.1 El Usuario se compromete:
      <br /><br />
                      al registrarse en el Sitio, a proporcionar información veraz sobre sí mismo, en concreto su nombre de usuario y su dirección de correo electrónico;<br />
                      a registrarse en el Sitio una sola vez no creando más de una Cuenta, En caso de que un tercero tenga acceso a su Cuenta, se podrá crear otra nueva siempre y cuando haya informado a NADIUS al respecto y bloqueado la Cuenta anterior;<br />
                      a abstenerse de usar el Sitio y/o los Servicios con el fin de realizar acciones o transacciones deshonestas o llevar a cabo acciones fraudulentas;<br />
                      al usar el Sitio, a proporcionar información objetiva, correcta, exhaustiva y detallada sobre la venta que se pretende efectuar;<br />
                      a asegurarse de que el precio propuesto para la venta de los Artículos, así como otra información con respecto a los mismos, sean correctos;<br />
                      a no copiar la información que aparece en el Sitio y es publicada por NADIUS u otro Usuario en el Sitio, ni utilizarla de forma deshonesta.<br />
      <br /><br />
                  11.2 El Usuario y el Visitante se comprometen, al utilizar el Sitio, a proporcionar información y datos, en especial datos electrónicos (archivos, mensajes privados, etc.) que:
      <br />
                      no sean engañosos o incorrectos;<br />
                      no promuevan la adquisición de bienes cuya venta esté prohibida o restringida;<br />
                      no usen fotografías (i) de las que no se tengan los derechos de propiedad intelectual (en general, se trata de fotografías encontradas en Internet), o (ii) que contengan enlaces a otros sitios web;<br />
                      no usen fotografías (i) en las que sean visibles otras personas además de ellos, a menos que las personas hayan dado su consentimiento para la publicación de estas fotografías; o (ii) que sean o puedan ser consideradas fotografías eróticas o pornográficas, contrarias a los estándares morales o al orden público;<br />
                      no ofrezcan para la venta/compra/intercambio o transferencia Artículos que se hubieran fabricado en violación de los derechos de propiedad intelectual de los propietarios de la marca comercial registrada;<br />
                      no vulneren los derechos de propiedad ni los derechos personales que no sean derechos patrimoniales de terceros (incluyendo derechos de propiedad intelectual);<br />
                      no vulneren las normas aplicables;<br />
                      no sean contrarios al orden público o a los estándares morales; no contengan virus, programas o archivos (i) susceptibles de perturbar el funcionamiento normal del Sitio y/o los Servicios, (ii) que estén ubicados en los ordenadores de los Usuarios y, por tanto, que generen pérdidas al Usuario y que priven al Usuario del uso del Sitio, los Servicios y el ordenador del Usuario;<br />
                      no ofrezcan enlaces a otros sitios web que presten servicios similares a los ofrecidos por el Sitio.<br />
      <br /><br />
                  11.3 El Usuario y el Visitante se comprometen a abstenerse de recopilar, mantener simultáneamente, transmitir a terceros, hacer públicos, publicar o divulgar, datos de los Usuarios del Sitio o datos sobre las acciones de los Usuarios del Sitio, incluyendo Transacciones, su número, tipo, precio etc., si esta información se recibe de forma ilegal o a raíz de una acción deshonesta o una omisión (salvo en relación con información estadística que se publica en el Sitio). El Usuario y el Visitante se comprometen también a no recopilar, mantener simultáneamente, transmitir a terceros, hacer pública, publicar o divulgar información que aparece en el Sitio, si es probable que afecte a los derechos de otros Usuarios. Esta restricción no se aplica a la función de “compartir” que existe en el Sitio y brinda a los Usuarios la opción de compartir información pública disponible en el Sitio y en las Cuentas de Redes Sociales, para ser enviada por correo electrónico.
      <br /><br />
                  11.4 Dentro de los límites de la legislación aplicable, NADIUS no es responsable del comportamiento de un Usuario cuando este utiliza el Sitio o los Servicios. En especial, NADIUS no es responsable de un defecto o fallo en las Transacciones por parte de los Usuarios.
      <br /><br />
                  11.5 El Usuario se compromete a mantener la confidencialidad de sus datos de inicio de sesión y su contraseña, sin que se tengan en cuenta las personas que han sido autorizadas por el Usuario para usar sus datos de inicio de sesión.
      <br /><br />
                  11.6 El Usuario se compromete a actualizar inmediatamente en el Sitio la información que ya no sea veraz debido a modificaciones en sus datos (en especial, la proporcionada durante su registro en el Sitio), así como la información sobre los Artículos anunciados en el Catálogo y su estado.
      <br /><br />
                  11.7 Al utilizar el Sitio, el Usuario y el Visitante declaran:
      <br /><br />
                      que tienen como mínimo 18 años de edad (de lo contrario, su(s) padre(s) o su tutor legal deberán registrarse en su lugar), que utilizan todos los Servicios del Sitio para satisfacer sus necesidades personales sin estar asociados con ninguna actividad profesional, y que tienen plena capacidad y todos los derechos para realizar las Transacciones en el Sitio;<br />
                      que aceptan y cumplen las Condiciones Generales y cualquier otro documento contractual, tal como la Política de Privacidad, la Política de Seguridad, la Política de Cookies y otras normas del Sitio;<br />
                      que entienden que son plenamente responsables de las Transacciones llevadas a cabo;<br />
                      que entienden que, tras haber pedido un Artículo, se comprometen a comprarlo, y que el hecho de no llevar a cabo esta Transacción les puede obligar al reembolso de las pérdidas sufridas por el Vendedor;<br />
                      que entienden que deben pagar a NADIUS si deciden usar los Servicios de pago de acuerdo con los cargos y el procedimiento indicados en la Lista de Precios.<br />
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        12. DERECHOS Y OBLIGACIONES DE NADIUS
                  </h4>
                  12.1 NADIUS puede prohibir total o parcialmente el uso del Sitio (estableciendo las condiciones de limitación aplicables) o finalizar la opción del Usuario o Visitante de usar el Sitio, en particular eliminando el Contenido anunciado en el Sitio, cancelando la cuenta del Usuario e impidiendo al Usuario que vuelva a registrarse en el Sitio o privando al Visitante del acceso al Sitio, sujeto al envío por parte de NADIUS de una notificación oficial previa a este Usuario y Visitante, si este:
      <br /><br />
                      infringe o no cumple con las obligaciones derivadas de las Condiciones Generales, la Política de Privacidad u otras normas del Sitio;<br />
                      proporciona información incorrecta, engañosa y/o incompleta según las Condiciones Generales, en el momento de su registro en el Sitio o en la utilización del Sitio;<br />
                      divulga deliberada e intencionadamente información incorrecta del Sitio, insulta a otras personas o actúa de forma inadecuada;<br />
                      usa la identidad de otro Usuario o actúa de forma deshonesta;<br />
                      Inicia sesión desde la misma dirección IP o desde el mismo ordenador que se bloqueó por motivo de la comisión de infracciones; o<br />
                      ha recibido al menos tres advertencias de NADIUS.<br />
      <br /><br />
                  12.2 Con base en a las mismas circunstancias que las descritas anteriormente, NADIUS puede, enviando una notificación oficial al respecto al Usuario/Visitante en cuestión, limitar total o parcialmente la Cuenta del Usuario o el acceso del Visitante. El bloqueo parcial significa que el Usuario no podrá anunciar artículos en el Catálogo ni comunicarse con otros Usuarios. El bloqueo total significa que la Cuenta del Usuario se bloqueará y/o cancelará, bloqueándose la opción de utilizar el Sitio desde el dispositivo del Usuario. El Usuario debe saber que, tras el bloqueo total, no tendrá derecho a volver a registrarse en el Sitio. La cancelación de la Cuenta del Usuario no significa que NADIUS elimine toda la información relevante de la Cuenta, en particular, los datos personales del Usuario, en la medida en que puedan guardarse para averiguar más sobre las circunstancias que rodean la decisión de NADIUS de cancelar la Cuenta del Usuario, y para transferir estos datos a las instituciones y autoridades responsables de aplicar la legislación, de acuerdo con la Política de Privacidad.
      <br /><br />
                  12.3 NADIUS, tras haber sido informado de acuerdo con el artículo
                  10.5 anterior, eliminará el Contenido deshonesto. NADIUS puede en cualquier momento eliminar del Sitio los Artículos anunciados por el Usuario en el Catálogo o cualquier otra información proporcionada por el Usuario en el caso de infracción de las Condiciones Generales, las leyes aplicables o si afecta a la moralvo al orden público.
      <br /><br />
                  12.4 Si el Usuario o el Visitante no acepta este derecho de NADIUS, deberá abstenerse de usar el Sitio. NADIUS puede investigar cualquier infracción de las Condiciones Generales e informar a las instituciones y autoridades competentes, responsables de aplicar la legislación sobre la materia.
      <br /><br />
                  12.5 NADIUS puede en cualquier momento reorganizar el Catálogo, los espacios de anuncios u otra información sobre los Artículos, sujeto a que estos cambios no modifiquen el Contenido facilitado por el Usuario, a con el objetivo de facilitar el uso del Sitio. NADIUS puede publicar Noticias, mediante la publicación de la descripción, las instrucciones o las normas asociadas.
      <br /><br />
                  12.6 NADIUS puede en cualquier momento publicar ofertas a corto y a largo plazo en el Sitio, concursos, juegos o loterías que cumplan con la legislación nacional, para ofrecer nuevos Servicios y, como resultado, estas Condiciones Generales no se modificarán. Se proporcionará en el Sitio información con respecto a las ofertas, concursos, juegos y loterías que cumplan con la legislación nacional. En el caso de incoherencia entre las Condiciones Generales y las condiciones especiales publicadas en el sitio (tales como normas, instrucciones y descripciones relativas a Noticias), estas últimas prevalecerán.
      <br /><br />
                  12.7 NADIUS puede finalizar, suspender o ceder la operación del Sitio a un tercero, sujeto a la notificación a los Usuarios y Visitantes del Sitio, respetando un período de notificación de treinta (30) días.
      <br /><br />
                  12.8 NADIUS podrá aplicar un estado de «pausa» a cualquier Usuario que haya estado inactivo durante un periodo razonablemente largo (por ej. 60 días o más). En tal caso, los artículos ofrecidos a la venta por dicho Usuario quedarán ocultos para otros Usuarios. NADIUS se reserva el derecho de eliminar los artículos ofrecidos a la venta por cualquier Usuario en el caso de que dichos artículos no se hayan vendido durante un periodo de tiempo razonablemente largo.
      <br /><br />
                  Antes de la aplicación del estado de «pausa» y/o la eliminación de cualquier artículo, NADIUS enviará una notificación al Usuario, por la cual informará al Usuario del cambio previsto para su estado y/o los artículos anunciados en el catálogo y permitirá al Usuario rechazar tal cambio. El Usuario deberá informar a NADIUS de su rechazo del cambio en el plazo de notificación que se indique en la comunicación. Si el Usuario no expresase su rechazo al cambio, se considerará que ha aceptado el cambio por parte de NADIUS de su estado y/o la eliminación de los artículos anunciados.
      <br /><br />
      <h4 className="mt-3 mb-16 " data-reveal-delay="200">
        13. DATOS DE CARÁCTER PERSONAL
                  </h4>
                  La Política de privacidad está disponible en el enlace https://productor.nadius.cat/privacy que describe qué Datos Personales del Usuario recopila NADIUS y con qué fines NADIUS procesa dichos datos.
      <br /><br />
                  14 DISPOSICIONES FINALES
      <br /><br />
                  14.1 Las Condiciones Generales se rigen por la legislación nacional del Usuario.
      <br /><br />
                  14.2 En el caso de desacuerdo entre NADIUS y el usuario, el usuario puede:
      <br /><br />
                      En primer lugar, dirigir una reclamación escrita al equipo de asistencia mediante el formulario de contacto.<br />
                      En ausencia de respuesta por parte del departamento mencionado en un plazo razonable de (1) mes, o si el usuario no está satisfecho con la respuesta obtenida, el usuario y NADIUS tratarán de resolver el caso mediante un acuerdo amistoso, antes de elevar cualquier discrepancia ante el Tribunal de jurisdicción correspondiente.<br />
      <br /><br />
                      Los Usuarios pueden recurrir a otros métodos de resolución de litigios como mediación del consumidor, a pesar de que NADIUS no está comprometido ni obligado a utilizar tales métodos para resolver las disputas con usuarios. Además, las disputas entre Compradores y Vendedores no se aplican a las organizaciones mediadoras del consumidor.<br />
                      En España, La Oficina Municipal del Consumidor está disponible.<br />
                      La Comisión Europea ha elaborado una plataforma para la resolución de litigios para recoger las posibles reclamaciones de los consumidores al realizar una compra online y para dirigirla a las instituciones competentes de mediación nacional. Esta plataforma está disponible aquí: http://ec.europa.eu/consumers/odr.<br />
      <br /><br />
                  14.3 El Usuario podrá poner fin a su relación con NADIUS en cualquier momento y con efecto inmediato tras quedar liberado de todas sus obligaciones y una vez cancelada su Cuenta en el Sitio. El Usuario podrá poner fin a su relación con NADIUS por escrito o por correo electrónico. NADIUS puede terminar su relación con el Usuario por medio de una notificación cursada con un preaviso de 30 días por las siguientes razones: (1) la decisión de NADIUS de dejar de prestar los Servicios, (2) la decisión de NADIUS de dejar de realizar sus actividades, (3) la decisión de NADIUS de cambiar el administrador de sus actividades en el Sitio total o parcialmente. NADIUS puede terminar su relación con el Usuario, con efecto inmediato, en caso de infracción de la ley o de las Condiciones Generales por parte del Usuario.
      <br /><br />
                  14.4 NADIUS ostenta todos los derechos de propiedad intelectual con respecto al Sitio, su sistema (el Catálogo, su transmisión, etc.), el diseño del Sitio y el software usado por el Sitio, incluyendo las marcas y los nombres de dominio.
      <br /><br />
                  14.5 El hecho de facilitar información o datos —en especial fotografías en el Sitio, es decir, el «Contenido»— significará la concesión por la presente, por parte de los Usuarios que utilicen el Sitio o los Servicios, a NADIUS y a cualquier empresa perteneciente al Grupo NADIUS de una licencia universal no exclusiva para el uso del Contenido en todo el mundo, que será aplicable durante el periodo de vigencia de los derechos aplicables, si los hubiese (y sus posibles ampliaciones). Dicha licencia incluye el derecho a usar, copiar, reproducir, mostrar y adaptar el Contenido. El Usuario acepta que NADIUS pueda usar dicho Contenido , en cualquier soporte conocido o desconocido hasta la fecha y, en particular, televisión, papel, Internet (como banners y artículos, o colocarlos en otros sitios web) y redes sociales (Facebook, Twitter, Instagram etc.) con fines comerciales, publicitarios e internos del Grupo NADIUS. El Usuario será el responsable exclusivo del Contenido y confirma que posee todos los derechos sobre el mismo. Al cargar el Contenido en el Sitio, el Usuario podrá prohibir el uso del Contenido en la forma y con los fines indicados en este apartado, modificando la Configuración de Usuario y desactivando una opción dentro de la configuración Política de Privacidad..
      <br /><br />
                  14.6 Todas las notificaciones, solicitudes y demás información entre el Usuario y NADIUS se enviarán tal y como se indica en las Condiciones Generales mediante el formulario de contacto que aparece en el Sitio, y al Usuario por correo electrónico, a la dirección de correo electrónico que proporcionó al registrarse en el Sitio.
      <br /><br />
                  14.7 En ningún caso se considerará que existe una relación de agencia o de franquicia entre NADIUS y los Usuarios en virtud de las presentes Condiciones Generales.
      <br /><br />
                  14.8 NADIUS podrá, sujeto a la legislación en materia de protección de datos aplicable, transferir y/o ceder todos los derechos y obligaciones derivados de las presentes Condiciones Generales, a terceros, en especial en el caso de transferir un sector de actividad, una fusión para la fundación de una nueva empresa, una fusión por absorción, escisión o cualquier cambio en el control que afecte a NADIUS. Dicha transferencia liberaría a NADIUS en el futuro. En el caso de que NADIUS transfiera y/o ceda estos derechos y obligaciones derivados de las presentes Condiciones Generales a terceros, el Usuario tendrá el derecho de finalizar de inmediato sus relaciones con NADIUS.
      <br /><br />
                  14.9 En el marco de la legislación española aplicable, y de cara a transmitir una información honesta, clara y transparente a nuestros usuarios, le rogamos que se dirija a los portales de las Autoridades Fiscales y de Seguridad Social Españolas, cuyas direcciones figuran a continuación para conocer a que obligaciones fiscales y sociales está sujeto como parte de las transacciones que realizará en nuestro sitio:
      <br /><br />
                      Obligaciones fiscales;<br />
                      Obligaciones sociales.<br />
      <br />
                  14.10 Los Usuarios pueden expresar sus objeciones respecto al acceso, uso o funcionamiento del Sitio o los Servicios mediante notificación a NADIUS. Las notificaciones deben presentarse a NADIUS mediante el formulario que aparece en el Sitio. Por favor, pónganse en contacto con esta dirección para todos los asuntos legales: hola@nadius.cat.
      <br /><br />
    </>
  );
}
import React, { useState, useContext } from 'react';
import { Realm } from '@platform'

const RealmContext = React.createContext(null);

const RealmProvider = ({ children }) => {
    const [realm, setRealm] = useState(undefined);

    const openRealm = async () => {
        let user;
        try {
            // ...
            console.log(`Logged in with the user: ${user?.id}`);
            const config = {
                schema: [TaskModel],
                sync: {
                    user: user,
                    partitionValue: '"myPartition"',
                },
            };

            setRealm(await Realm.open(config));
        } catch (error) {
            throw `Error opening realm: ${JSON.stringify(error, null, 2)}`;
        }
    }

    return (
        <RealmContext.Provider
            value={{
                openRealm,
                realm
            }}>
            {children}
        </RealmContext.Provider>
    );
};

// The useRealm hook can be used by components under an AuthProvider to access
// the auth context value.
const useRealm = () => {
    const realm = useContext(RealmContext);
    if (realm == null) {
        throw new Error('useRealm() called outside of a AuthProvider?');
    }
    return realm;
};

export { RealmProvider, useRealm };
import {
    StyleSheet,
    View,
    TextInput
} from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@quarks'

import { Icons } from '@icons';
import { SimpleText } from '@quarks'
import { AppStyle, TextStyle } from '@style'

export const autoCompleteType = {
    off: 'off',
    username: 'username',
    password: 'password',
    email: 'email',
    name: 'name',
    tel: 'tel',
    streetAddress: 'street-address',
    postalCode: 'postal-code',
    ccNumber: 'ofccNumberf',
    ccCsc: 'ccCsc',
    ccExp: 'ccExp',
    ccExpMonth: 'ccExpMonth',
    ccExpYear: 'ccExpYear',
}

export const autoCapitalize = {
    none: 'none',
    sentences: 'sentences',
    words: 'words',
    characters: 'characters',
}

export const clearButtonMode = {
    on: 'while-editing',
    off: 'never'
}

export const keyboardType = {
    default: 'default',
    numberPad: 'number-pad',
    decimalPad: 'decimal-pad',
    numeric: 'numeric',
    emailAddress: 'email-address',
    phonePad: 'phone-pad'
}

export function InputIcon(props) {

    let _inputError = props.inputError
    let _onChangeText = (text) => {
        if (typeof props.onChangeText !== 'undefined') {
            props.onChangeText(text);
        }
        if (text.length === 0) {
            if (typeof props.onSubmit !== 'undefined'){
                props.onSubmit(text);
            }
        }
    }
    let _inputColor = _inputError ? AppStyle.errorColor : AppStyle.getColor('secondaryAccent',1);
    
    let _onSubmitEditing = (text) => {
        if (typeof props.onSubmit !== 'undefined'){
            props.onSubmit(text);
        }
    }

    const styles = updateStyles()
    
    return (
        <View style={{flex:1}}>
            <View style={[styles.view, props.style, {borderBottomColor: _inputColor}]} >
                <Icon 
                    name={props.icon}
                    size={20} 
                    color={AppStyle.textColor}
                    style={{width: 30}}/>
                <TextInput 
                    value={props.value}
                    onChangeText={_onChangeText}
                    onSubmitEditing={_onSubmitEditing}
                    clearButtonMode={props.clearButtonMode}
                    style={[styles.text, props.textStyle]}
                    placeholder={props.placeholder}
                    placeholderTextColor={styles.placeholder.color}
                    keyboardType={props.keyboardType}
                    secureTextEntry={props.password}
                    autoCapitalize={props.autoCapitalize}
                    maxLength={props.maxLength ? props.maxLength : 100}/>
            </View>
            {_inputError != null && <SimpleText style={[styles.errorMessage]}>
                {_inputError}
            </SimpleText>}
        </View>
    );
}

InputIcon.defaultProps = {
    autoCompleteType: autoCompleteType.off,
    clearButtonMode: clearButtonMode.on,
    iconColor: 'white',
    icon: Icons.QUESTION,
    keyboardType: keyboardType.default,
    password: false
}

InputIcon.propTypes = {
    autoCompleteType: PropTypes.oneOf(Object.keys(autoCompleteType)),
    clearButtonMode: PropTypes.oneOf(Object.values(clearButtonMode)),
    icon: PropTypes.oneOf(Object.values(Icons)),
    placeholder: PropTypes.string,
    keyboardType: PropTypes.oneOf(Object.values(keyboardType)),
}

const updateStyles = () => StyleSheet.create({
    text:{
        ...TextStyle.size.medium,
        ...TextStyle.weight.bold,
        color: AppStyle.textColor,
        flex: 8
    },
    view:{
        flex: 1, 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        maxHeight: 50,
        borderBottomWidth :1,
        borderBottomColor: AppStyle.backgroundColorInverted,
        paddingBottom: 5
    },
    placeholder:{
        color: AppStyle.placeHolderColor
    },
    errorMessage: {
        marginTop: 10,
        marginLeft: 10,
        color: AppStyle.errorColor
    }
});


export const PERFORMED_QUERY = "session/PERFORMED_QUERY";

export const STORE_REGISTER_FORM = "session/STORE_REGISTER_FORM";
export const WAITING_VERIFICATION = "session/WAITING_VERIFICATION";

export const STORE_KYC_FORM = "session/STORE_KYC_FORM";
export const PENDING_KYC_FORM = "session/PENDING_KYC_FORM";
export const DONE_KYC_FORM = "session/DONE_KYC_FORM";
export const RESET_KYC_FORM = "session/RESET_KYC_FORM";

export const STORE_COMPANY_FORM = "session/STORE_COMPANY_FORM";

export const STORE_QUERY = "session/STORE_QUERY";
export const REFETCH_MAIN_QUERIES = "session/REFETCH_MAIN_QUERIES"

export const PRODUCT_MODAL = "session/PRODUCT_MODAL";
export const ALERT_MODAL = "session/ALERT_MODAL";
export const USER_MODAL = "session/USER_MODAL";

export const UPDATE_LANGUAGE = "session/UPDATE_LANGUAGE";
import React, { useState } from 'react';
import {
    Animated, Platform,
    StyleSheet, Keyboard, LayoutAnimation
} from 'react-native';

import { ButtonText } from '@quarks'

export function FloatingButton(props) {

    const styles = updateStyles(props.bottomMargin, props.topMargin)

    const _onPress = () => {
        if (props.onPress) {
            props.onPress()
        }
    }
    const [keyboardVisible, setKeyboardVisible] = useState(false)

    const _keyboardDidShow = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setKeyboardVisible(true);
    }
    const _keyboardDidHide = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setKeyboardVisible(false);
    }

    React.useEffect(() => {
        Keyboard.addListener(Platform.OS == 'android' ? 'keyboardDidShow' : 'keyboardWillShow', _keyboardDidShow)
        Keyboard.addListener('keyboardDidHide', _keyboardDidHide)
        return () => {
            Keyboard.removeListener(Platform.OS == 'android' ? 'keyboardDidShow' : 'keyboardWillShow', _keyboardDidShow);
            Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
        }
    }, [])

    return (keyboardVisible || props.visible === false ? null : (
        <Animated.View style={[styles.floatingView, props.style]}>
            <ButtonText
                disabled={props.disabled}
                style={[{
                    width: '100%',
                    shadowOffset: {
                        width: 0,
                        height: 0
                    },
                    shadowOpacity: 0.2,
                    shadowRadius: 5,
                    elevation: 4,
                    height: 50, borderRadius: 50, marginBottom: 10
                }, props.buttonStyle]}
                type={'solid'}
                icon={props.icon}
                loading={props.loading}
                iconSize={props.iconSize}
                onPress={_onPress}
                text={props.text}>
            </ButtonText>
        </Animated.View>)
    );
}

const updateStyles = (bottomMargin, topMargin) => StyleSheet.create({
    floatingView: {
        backgroundColor: 'transparent',
        position: 'absolute',
        alignSelf: 'center',
        bottom: typeof bottomMargin !== 'undefined' ?
            bottomMargin : (Platform.OS === 'android' ? 0 : 0),
        top: topMargin,
        width: '60%',
        maxWidth: 300,
        zIndex: 10
    },
});
//
// External module imports
//
import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { View } from 'react-native';
import { gql, useQuery } from '@apollo/client';
import { Image, useWindowDimensions } from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';
//
// Own imports
//
import { Alert } from "@platform";
import { NadiusIcons } from '@icons'
import { AppStyle, TextStyle } from '@style';
import { Icon } from '@quarks'
import PublicNavigation from './public'
import OrdersNavigation from './orders'
import PorfileNavigation from './profile'
import NextStepsNavigation from './nextSteps'
import FiscalScreen from '_scenes/companyForm/fiscal';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { ButtonText, SimpleText } from '@quarks';
import { ToolbarDropdown, LoadingView } from '@atoms';
import { useAuth } from '@realm';
import { useDispatch } from 'react-redux';
import { producer as codes } from 'nexto-codes'
import { sessionOperations } from 'redux/ducks/session';

const MainStack = createBottomTabNavigator();

const baseOptions = {
  headerTintColor: AppStyle.mainColor,
  headerTransparent: true,
  headerBackTitleVisible: false,
  animationEnabled: true,
}

const optionsWithoutBack = {
  ...baseOptions,
  headerShown: false,
}

const Drawer = createDrawerNavigator();


function MainDrawerScreen() {
  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator
      screenOptions={({ route, navigation }) => ({
          title: `Nadius - ${`$root.screens.${route.name}`.localise()}`
      })}
      drawerStyle={{
        backgroundColor: AppStyle.getColor('dark', 1),
        color: AppStyle.textColor,
        maxWidth: 250
      }}
      drawerContentOptions={{
        activeBackgroundColor: AppStyle.mainColor,
      }}
      drawerType={dimensions.width >= 768 ? 'permanent' : 'front'}>
      <Drawer.Screen
        name={"Comandes"}
        options={{
          ...optionsWithoutBack,
          drawerIcon: ({ focused, color, size }) => {
            return (
              <View
                style={{ marginLeft: 5, width: 15, alignItems: 'center', marginRight: -15 }}
              >
                <Icon
                  name={NadiusIcons.ORDER}
                  iconSet={NadiusIcons.iconSet}
                  size={22}
                  color={focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 3)}
                />
              </View>
            )
          },
          drawerLabel: ({ focused, color, size }) => <SimpleText style={{ color: focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 4) }}>$root.firstTab</SimpleText>,


        }}
        component={OrdersNavigation}
      />
      <Drawer.Screen
        name={"Productes"}
        options={{
          ...optionsWithoutBack,
          headerTitle: '',
          drawerIcon: ({ focused, color, size }) => {
            return (
              <View
                style={{ marginLeft: 5, width: 15, alignItems: 'center', marginRight: -15 }}
              >
                <Icon
                  name={NadiusIcons.PRODUCT}
                  iconSet={NadiusIcons.iconSet}
                  size={22}
                  color={focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 3)}
                />
              </View>
            )
          },
          drawerLabel: ({ focused, color, size }) => <SimpleText style={{ color: focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 4) }}>$root.productsTab</SimpleText>,

        }}
        component={PublicNavigation}
      />
      <Drawer.Screen
        name={"ProducerProfile"}
        options={{
          ...optionsWithoutBack,
          headerTitle: '',
          drawerIcon: ({ focused, color, size }) => {
            return (
              <View
                style={{ marginLeft: 5, width: 15, alignItems: 'center', marginRight: -15 }}
              >
                <Icon
                  name={NadiusIcons.FARM}
                  iconSet={NadiusIcons.iconSet}
                  size={22}
                  color={focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 3)}
                />
              </View>

            )
          },
          drawerLabel: ({ focused, color, size }) => <SimpleText style={{ color: focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 4) }}>$root.profileTab</SimpleText>,
        }}
        component={PorfileNavigation}
      />
      <Drawer.Screen
        name={"Business"}
        options={{
          ...optionsWithoutBack,
          drawerIcon: ({ focused, color, size }) => {
            return (
              <View
                style={{ marginLeft: 5, width: 15, alignItems: 'center', marginRight: -15 }}
              >
                <Icon
                  name={NadiusIcons.INFO}
                  iconSet={NadiusIcons.iconSet}
                  size={22}
                  color={focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 3)}
                />
              </View>
            )
          },
          drawerLabel: ({ focused, color, size }) => <SimpleText style={{ color: focused ? AppStyle.getColor('light', 1) : AppStyle.getColor('secondaryAccent', 4) }}>$root.business</SimpleText>,
        }}
        component={FiscalScreen}
      />
    </Drawer.Navigator>
  );
}

const MainNavigation = (props) => {

  const height = useHeaderHeight()
  const { logOut, user } = useAuth()
  const dispatch = useDispatch()

  if (user == null || typeof user === 'undefined' || Object.keys(user).length == 0) {
    props.navigation.reset({
        index: 0,
        routes: [{ name: 'Login' }],
    });
  }

  React.useEffect(() => {
    props.navigation.setOptions({
      headerRight: (() => (<ToolbarDropdown
        name={user?.name}
        headerHeight={height} >
        <ButtonText
          text={'El teu usuari'}
          style={{ width: '100%', marginBottom: 10 }}
          textStyle={{ color: AppStyle.textColor }}
          onPress={async () => {
            dispatch(sessionOperations.toggleUserModal(true))
          }} />
        <ButtonText
          text={'Tancar sessió'}
          style={{ width: '100%' }}
          onPress={async () => {
            Alert(dispatch).alert(
              '$choose.alertTitle'.localise(),
              '$choose.alertDescription'.localise(),
              [
                {
                  text: '$choose.alertCancel'.localise(),
                  style: 'cancel',
                },
                {
                  text: '$choose.alertConfirm',
                  onPress: async () => {
                    await logOut(true, false)
                    props.navigation.reset({
                      index: 0,
                      routes: [{ name: 'Login' }]
                    })
                  }
                }
              ],
              { cancelable: false }
            )
          }} />
      </ToolbarDropdown>)),
    })
  }, [height])

  let code = user?.producer?.status?.code
  if (typeof user === 'undefined' || typeof code === 'undefined') {
    return <View style={{
      width: '100%', height: '100%',
      backgroundColor: AppStyle.backgroundColor
    }} >
      <LoadingView />
    </View>;
  }

  let MainNavigation = MainDrawerScreen
  if (//code == codes.STATUS.VALIDATED ||
    code == codes.STATUS.WAITING_OUR_VALIDATION ||
    code == codes.STATUS.WAITING_REQUIRED_INFORMATION
  ) {
    MainNavigation = NextStepsNavigation
  }

  return (
    <View style={{ flex: 1, backgroundColor: AppStyle.backgroundColor }}>
      <MainNavigation />
    </View>
  )
};

export default MainNavigation;

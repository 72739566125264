import React, { useState } from 'react';
import { View, StyleSheet, ScrollView, Dimensions, Platform } from 'react-native';
import { useQuery } from '@apollo/client';
import * as FileSystem from 'expo-file-system';
import moment from "moment/min/moment-with-locales"
import { useSelector, useDispatch } from 'react-redux'

import { ButtonText, SimpleText } from '@quarks'
import {
    CardInput, FileInput,
    TabbedCarousel, keyboardType, FeedbackModal
} from '@molecules'
import { AppStyle, TextStyle } from "@style";
import { Icons, NadiusIcons } from '@icons'
import { Producer } from '@api'
import { useAuth } from '@realm'
import { producer as codes } from 'nexto-codes'
import { ErrorView, Banner, FloatingButton, LoadingView } from '@atoms';
import { LocationInput, DateCardInput } from '@organisms';
import { COUNTRY_CODES } from '_enums'
import { mangopay } from 'nexto-codes'

import { kycFormValidationErrors, isFormValid } from 'nexto-formvalidator'
import { sessionOperations } from '_ducks/session'
import { KeyboardAwareScrollView, Alert } from '@platform'
import { sub } from 'react-native-reanimated';


const KYCFormScreen = (props) => {

    const { user, callFunction } = useAuth();
    const dispatch = useDispatch()
    const { query, param } = Producer.getCompany(user?.producer)
    const { loading, error, data, called } = useQuery(query, {
        ...param,
        fetchPolicy: 'cache-and-network'
    });
    const reduxForm = useSelector(state => state.session.kycForm)

    const storedForm = reduxForm.form

    let initialUbo = {
        FirstName: undefined,
        LastName: undefined,
        Address: {
            AddressLine1: undefined,
            City: undefined,
            PostalCode: undefined,
            Country: undefined
        },
        Nationality: COUNTRY_CODES[0].value,
        Birthday: moment().year(1975),
        Birthplace: {
            City: undefined,
            Country: COUNTRY_CODES[0].value
        }
    }

    let initialState = {
        IDENTITY_PROOF: typeof storedForm?.IDENTITY_PROOF !== 'undefined' ?
            storedForm.IDENTITY_PROOF : {
                files: [],
                type: undefined
            },
        REGISTRATION_PROOF: typeof storedForm?.REGISTRATION_PROOF !== 'undefined' ?
            storedForm.REGISTRATION_PROOF : {
                files: [],
                type: undefined
            },
        ARTICLES_OF_ASSOCIATION: typeof storedForm?.ARTICLES_OF_ASSOCIATION !== 'undefined' ?
            storedForm.ARTICLES_OF_ASSOCIATION : {
                files: [],
                type: undefined
            },
        ubos: typeof storedForm?.ubos !== 'undefined' ?
            storedForm.ubos : [initialUbo],
    }

    let tabbedRef = React.useRef(null)

    let company;
    if (typeof data?.company !== 'undefined' && data?.company != null) {
        company = data.company
    }

    if (props.navigation.isFocused() &&
        typeof company?.payment?.kyc !== 'undefined' &&
        company?.payment?.kyc !== null) {

        let kyc = company.payment.kyc
        console.log('KYC ', kyc)
        if (kyc?.done === true) {
            props.navigation.goBack()
            dispatch(sessionOperations.doneKycForm())
        } else if (kyc?.pending === true) {
            props.navigation.replace('KYCSubmit')
            dispatch(sessionOperations.pendingKycForm())
        }
        let values = Object.keys(company.payment.kyc)
        for (let index = 0; index < values.length; index++) {
            const element = company.payment.kyc[values[index]];
            if (element != null) {
                initialState[values[index]] = { done: true }
            }
        }

    }

    const styles = updateStyles()
    const [status, setStatus] = useState({
        show: false,
        uploading: false,
        success: false,
        errorView: undefined
    })

    let [errorForm, setErrorForm] = useState({
        ubos: []
    })
    let [form, setForm] = useState(initialState)

    let _onValueChange = (field, index, subfield) => (value) => {
        let tmp = { ...form }

        if (typeof index !== 'undefined') {
            let tmpError = { ...errorForm }

            if (typeof tmp.ubos[index] === 'undefined') tmp.ubos[index] = {}

            if (typeof subfield !== 'undefined') {
                if (typeof tmp.ubos[index][field] === 'undefined') tmp.ubos[index][field] = {}

                tmp.ubos[index][field][subfield] = value
                if (typeof tmpError.ubos[index]?.[field]?.[subfield] !== 'undefined') {
                    tmpError.ubos[index][field][subfield] = undefined
                }
            } else {
                if (field === 'Address') {
                    tmp.ubos[index][field] = {
                        ...value,
                        AddressLine1: tmp.ubos[index][field].AddressLine1,
                    }
                } else {
                    tmp.ubos[index][field] = value
                }
                if (typeof tmpError.ubos[index]?.[field] !== 'undefined') {
                    tmpError.ubos[index][field] = undefined
                }
            }

            setErrorForm(tmpError)
        } else {
            if (typeof mangopay.DOCUMENT_TYPES[field] !== 'undefined') {
                if (Array.isArray(value)) {
                    if (value.length !== tmp[field].files.length) {
                        tmp[field].done = false
                    }
                    tmp[field].files = value
                } else {
                    tmp[field].type = value
                }
            } else {
                tmp[field] = value
            }
        }
        setForm(tmp)
    }

    let _onContinue = async () => {

        console.log('_onContinue ')
        let newForm = { ...form }

        dispatch(sessionOperations.storeKycForm({ ...form }))

        if (company.LegalPersonType === codes.COMPANY_TYPES.BUSINESS) {
            for (let index = 0; index < newForm.ubos.length; index++) {
                const ubo = newForm.ubos[index];
                if (typeof ubo.Address !== 'undefined') {
                    ubo.Address.PostalCode = ubo.Address.postal_code
                    ubo.Address.City = ubo.Address.place_name
                    ubo.Address.Country = ubo.Address.country_code
                }
            }

            let formState = kycFormValidationErrors(form)
            if (!formState.isValid) {
                setErrorForm(formState.formErrors)
                return
            }
        }

        if (!newForm.IDENTITY_PROOF.done || !newForm.REGISTRATION_PROOF.done ||
            (company.LegalPersonType !== codes.COMPANY_TYPES.SOLETRADER && !newForm.ARTICLES_OF_ASSOCIATION.done)) {
            setStatus({ ...status, errorView: '$finances.kycForm.missingDocuments' })
            return
        }

        setStatus({ ...status, uploading: true })

        let result = await callFunction("MANGOPAY_kycForm", {
            ubos: newForm.ubos
        })
        console.log('Check form2 ', result)
        switch (result.status) {
            case mangopay.RESPONSE_CODES.OK:
                setStatus({ ...status, success: true, uploading: false })
                dispatch(sessionOperations.pendingKycForm())
                setTimeout(() => {
                    setStatus({ ...status, success: false })
                    props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'Main' }],
                    });
                }, 1000)
                break;
            case mangopay.RESPONSE_CODES.UBO_FORM_NOT_VALID:
                if (typeof result?.formErrors != 'undefined') {
                    if (typeof result.formErrors === 'string') {
                        setErrorForm(JSON.parse(result.formErrors))
                    }
                }
            default:
                if (typeof mangopay.RESPONSE_MESSAGES[result.status] === 'undefined') {
                    setStatus({ uploading: false, success: false, errorView: mangopay.RESPONSE_MESSAGES[mangopay.RESPONSE_CODES.UNKNOWN_ERROR] })
                } else {
                    setStatus({ uploading: false, success: false, errorView: mangopay.RESPONSE_MESSAGES[result.status] })
                }
                break
        }
    }

    let _onSave = (documentType) => async () => {
        /*setStatus({
            ...status,
            uploading: true
        })*/

        let newForm = { ...form }

        if (newForm[documentType].files.length < 1) {
            return false
        }

        let success = true
        let message;

        for (let index = 0; index < newForm[documentType].files.length; index++) {
            const element = newForm[documentType].files[index];

            let base64;
            if (Platform.OS == 'native') {
                let info = await FileSystem.getInfoAsync(element.uri)
                if (!info.exists) {
                    success = false
                    message = `${'$finances.kycForm.unableFindDocument'.localise()} (${element.name})`
                    break
                }

                base64 = await FileSystem.readAsStringAsync(element.uri, {
                    encoding: FileSystem.EncodingType.Base64
                })
            } else {
                base64 = element.uri.replace(/data:.*;base64,/gm, '')
            }

            let result = await callFunction("MANGOPAY_uploadDocument", {
                documentType: mangopay.DOCUMENT_TYPES[documentType],
                type: newForm[documentType].type,
                file: base64
            })

            if (result.status !== 0) {
                success = false
                message = (mangopay.RESPONSE_MESSAGES[result.status]).localise()
                break
            }
        }

        if (success) {
            newForm[documentType].done = success
            setForm(newForm)
            dispatch(sessionOperations.storeKycForm(newForm))
        }

        return { status: success, message: message }
    }

    const _ubo = (errorForm) => ({ item, index }) => {

        let actualUbo = form.ubos[index]
        let errorUbo = errorForm.ubos[index]

        return (<ScrollView
            key={index}
            style={{
                flex: 1, width: '100%', height: '100%', maxWidth: 500, alignSelf: 'center',
                padding: 20, paddingTop: 10, borderWidth: 1, borderRadius: 20
            }}>
            <SimpleText style={styles.titleText}>
                $finances.kycForm.nameAndSurnames
            </SimpleText>
            <View style={[styles.cardConatiner, { marginTop: 5, marginBottom: 10 }]}>
                <CardInput
                    style={[styles.halfCard, { marginRight: 15 }]}
                    locale={'ca'}
                    onChangeText={_onValueChange('FirstName', index)}
                    keyboardType={keyboardType.default}
                    inputText={actualUbo?.FirstName}
                    autoCompleteType={undefined}
                    errorText={errorUbo?.FirstName}
                    placeholder={'$finances.kycForm.namePlaceholder'}
                    returnKeyType={'next'}
                />
                <CardInput
                    style={styles.halfCard}
                    locale={'ca'}
                    onChangeText={_onValueChange('LastName', index)}
                    keyboardType={keyboardType.default}
                    inputText={actualUbo?.LastName}
                    errorText={errorUbo?.LastName}
                    autoCompleteType={undefined}
                    placeholder={'$finances.kycForm.surnamePlaceholder'}
                    returnKeyType={'next'}
                />
            </View>

            <CardInput
                title={'$finances.kycForm.street'}
                icon={Icons.DIRECTIONS}
                onChangeText={_onValueChange('Address', index, 'AddressLine1')}
                keyboardType={keyboardType.default}
                autoCompleteType={undefined}
                inputText={actualUbo?.Address?.AddressLine1}
                errorText={errorUbo?.Address?.AddressLine1}
                placeholder={'$finances.kycForm.streetPlaceholder'}
                returnKeyType={'next'}
                style={{ width: '100%', marginBottom: 10 }}
            />

            <LocationInput
                key={actualUbo?.Address}
                style={{ width: '100%', alignSelf: 'center', marginBottom: 10 }}
                value={actualUbo?.Address}
                errors={errorUbo?.Address}
                onValueChange={_onValueChange('Address', index)}
            />

            <DateCardInput
                onValueChange={_onValueChange('Birthday', index)}
                title={'$finances.kycForm.birthday'}
                date={typeof actualUbo?.Birthday === 'string' ? moment(actualUbo?.Birthday) : actualUbo?.Birthday}
                errorText={errorUbo?.Birthday}
                cardStyle={{ width: '100%', marginBottom: 10 }} />

            <SimpleText style={[styles.titleText, { paddingTop: 5 }]}>
                $finances.kycForm.birthplace
            </SimpleText>
            <View style={[styles.cardConatiner, { marginBottom: 10 }]}>
                <CardInput
                    title={'$finances.kycForm.city'}
                    style={[styles.halfCard, { marginRight: 15 }]}
                    onChangeText={_onValueChange('Birthplace', index, 'City')}
                    keyboardType={keyboardType.default}
                    autoCompleteType={undefined}
                    inputText={actualUbo?.Birthplace?.City}
                    errorText={errorUbo?.Birthplace?.City}
                    placeholder={'$finances.kycForm.city'}
                    returnKeyType={'next'}
                />
                <CardInput
                    title={'$finances.kycForm.country'}
                    style={styles.halfCard}
                    onChangeText={_onValueChange('Birthplace', index, 'Country')}
                    icon={Icons.World}
                    inputText={actualUbo?.Birthplace?.Country}
                    errorText={errorUbo?.Birthplace?.Country}
                    noInput
                    picker
                    pickerItems={COUNTRY_CODES}
                    returnKeyType={'next'}
                />
            </View>

            <CardInput
                title={'$finances.kycForm.nationality'}
                icon={Icons.World}
                noInput
                picker
                pickerItems={COUNTRY_CODES}
                inputText={actualUbo?.Nationality}
                onChangeText={_onValueChange('Nationality', index)}
                style={{ width: '100%', marginBottom: 10 }}
            />
            { index > 0 && <ButtonText
                text={'$finances.kycForm.delete'.localise()}
                color={AppStyle.errorColor}
                type={'outline'}
                onPress={() => {
                    Alert(dispatch).alert(
                        '$finances.kycForm.confirm'.localise(),
                        "$finances.kycForm.confirmDesc".localise(),
                        [
                            {
                                text: "$finances.kycForm.delete".localise(),
                                onPress: () => {
                                    let newUbos = { ...form }
                                    tabbedRef.current.indexPress(index - 1)
                                    newUbos.ubos.splice(index, 1)
                                    setForm(newUbos)
                                }
                            },
                            {
                                text: '$finances.kycForm.cancelAlert'.localise(),
                                style: 'cancel',
                            },
                        ],
                        { cancelable: true }
                    );
                }}
                style={{ marginTop: 15 }}
                textStyle={{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5 }} />}
        </ScrollView>)
    }

    const _addUbo = () => {

        if (form.ubos.length >= 4) {
            return
        }

        return (<View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <ButtonText
                text={'$finances.kycForm.addAnotherUBO'}
                icon={NadiusIcons.MORE}
                iconSet={NadiusIcons.iconSet}
                type={'outline'}
                onPress={() => {
                    let newUbos = { ...form }
                    newUbos.ubos.push(initialUbo)
                    setForm(newUbos)
                }}
                style={{ marginLeft: 10, padding: 10, paddingTop: 5, paddingBottom: 5 }} />
        </View>)
    }

    const _ubos = () => {
        if (loading) {
            return
        }

        let errorUbos = errorForm.ubos
        let tabHasError = []
        if (typeof errorUbos !== 'undefined' && errorUbos.length > 0) {
            let errorMessage = '$finances.kycForm.reviewTab'.localise()
            for (let index = 0; index < errorUbos.length; index++) {
                if (!isFormValid(errorUbos[index])) {
                    tabHasError.push(errorMessage)
                } else {
                    tabHasError.push('')
                }
            }
        }

        return (<View
            style={{
                width: '90%', alignSelf: 'center',
                justifyContent: 'center',
            }}>
            {_addUbo()}
            <TabbedCarousel
                badges={tabHasError}
                ref={tabbedRef}
                containerStyle={{ marginTop: 30, minHeight: 700 }}
                style={{ marginTop: 10, marginBottom: 15 }}
                values={(() => {
                    let toReturn = []
                    Object.keys(form.ubos).forEach(element => {
                        toReturn.push(`${parseInt(element) + 1}`)
                    });
                    return toReturn
                })()}
                errors={errorForm}
                renderItem={_ubo(errorForm)}
                selectedIndex={0}
                contentWidth={0.9}
            />
        </View>)
    }

    const _registrationProof = () => {
        if (loading) {
            return
        }

        let pickerItems;
        let infoText;

        switch (company.LegalPersonType) {
            case codes.COMPANY_TYPES.BUSINESS:
                pickerItems = [
                    { value: '', label: '$finances.kycForm.selectTypeOfDocument'.localise() },
                    { value: 'REGISTER', label: '$finances.kycForm.labelRegister'.localise() },
                    { value: 'REGISTRATORS', label: '$finances.kycForm.labelRegistrators'.localise() },
                    { value: 'MODEL600', label: '$finances.kycForm.labelModel600'.localise() },
                ]
                infoText = '$finances.kycForm.lessThan3Months'.localise()
                break;
            case codes.COMPANY_TYPES.ORGANIZATION:
                pickerItems = [
                    { value: '', label: '$finances.kycForm.selectTypeOfDocument'.localise() },
                    { value: 'REGISTRO', label: '$finances.kycForm.labelRNA'.localise() },
                ]
                break;
            case codes.COMPANY_TYPES.SOLETRADER:
                pickerItems = [
                    { value: '', label: '$finances.kycForm.selectTypeOfDocument'.localise() },
                    { value: 'REGISTRO', label: '$finances.kycForm.labelRegisterSoletrader'.localise() },
                    { value: 'CARD', label: '$finances.kycForm.labelFiscalCard'.localise() },
                ]
                infoText = '$finances.kycForm.lessThan3MonthsResolution'.localise()
                break;
        }

        return (<View>
            <CardInput
                title={'$finances.kycForm.selectTitle'}
                noInput
                onValueChange={_onValueChange('REGISTRATION_PROOF')}
                style={{ marginTop: 15, width: '90%' }}
                picker
                pickerValue={form.REGISTRATION_PROOF?.type}
                pickerItems={pickerItems}
            />
            <SimpleText style={styles.warningText}>
                {infoText}
            </SimpleText>
        </View>)
    }

    React.useEffect(() => {
        props.navigation.setOptions({
            headerTitle: '$finances.kycForm.identityVerification'.localise()
        })
        const unsubscribe = props.navigation.addListener('blur', () => {
            dispatch(sessionOperations.storeKycForm(form))
        });
        return unsubscribe;
    }, [props.navigation, form]);

    if (loading) {
        return <View style={{
            width: '100%', height: '100%',
            backgroundColor: AppStyle.backgroundColor
        }} >
            <LoadingView />
        </View>;
    }

    if (error || (called && typeof company?.LegalPersonType === 'undefined' ||
        company?.LegalPersonType == null) ||
        (called && typeof codes.COMPANY_TYPES[company?.LegalPersonType] === 'undefined')) {
        return <View style={{
            width: '100%', height: '100%', justifyContent: 'center',
            backgroundColor: AppStyle.backgroundColor
        }} >
            <ErrorView error={error} />
        </View>;
    }

    return (<ScrollView style={{
        height: '100%', paddingLeft: 0,
        backgroundColor: AppStyle.backgroundColor
    }}><KeyboardAwareScrollView style={{
        flex: 1,
        width: '100%',
        alignSelf: 'center',
        backgroundColor: 'white', maxWidth: Dimensions.get('window').width < 768 ? '95%' : '80%'
    }}
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
            <Banner
                style={{ alignSelf: 'center', marginTop: 20, marginBottom: 20 }}
                type={'info'}
                textStyle={{ textAlign: 'center' }}
                numberOfLines={10}
                message={'$finances.kycForm.bannerDescription'} />

            <SimpleText style={[styles.descriptionText]}>
                $finances.kycForm.disclaimer
            </SimpleText>

            <SimpleText style={styles.directionText}>
                $finances.kycForm.identityProof
            </SimpleText>
            <View style={styles.separator}></View>
            <SimpleText style={styles.descriptionText}>
                $finances.kycForm.identityProofDescription
            </SimpleText>
            {!(typeof form.IDENTITY_PROOF.type == 'undefined' && form.IDENTITY_PROOF.done == true) &&
                <CardInput
                    title={'$finances.kycForm.selectTitle'}
                    noInput
                    style={{ marginTop: 15, width: '90%' }}
                    onValueChange={_onValueChange('IDENTITY_PROOF')}
                    picker
                    pickerValue={form.IDENTITY_PROOF?.type}
                    pickerItems={[
                        { value: '', label: '$finances.kycForm.selectTypeOfDocument'.localise() },
                        { value: 'PASSPORT', label: '$finances.kycForm.pasaport'.localise() },
                        { value: 'DNI', label: 'DNI' },
                        { value: 'DRIVING', label: '$finances.kycForm.drivingLicence'.localise() },
                        { value: 'RESIDENCE', label: '$finances.kycForm.residencePermit'.localise() },
                    ]}
                />}
            {(typeof form.IDENTITY_PROOF.type !== 'undefined' || form.IDENTITY_PROOF.done == true) &&
                <FileInput
                    style={{ marginTop: 10, width: '90%', alignSelf: 'center' }}
                    onValueChange={_onValueChange('IDENTITY_PROOF')}
                    onSave={_onSave('IDENTITY_PROOF')}
                    files={form.IDENTITY_PROOF?.files}
                    markAsUploaded={form.IDENTITY_PROOF?.done}
                    possibleFileTypes={['jpg', 'png', 'jpeg', 'pdf', 'gif']}
                    limitFileSizeMb={9}
                    infoText={'$finances.kycForm.possibleDocumentsLimitation'}
                    showHelp
                />}
            <View style={{ marginBottom: company.LegalPersonType === codes.COMPANY_TYPES.SOLETRADER ? 100 : 0 }}>
                <SimpleText style={styles.directionText}>
                    $finances.kycForm.registerProof
                </SimpleText>
                <View style={styles.separator}></View>
                {!(typeof form.REGISTRATION_PROOF.type == 'undefined' && form.REGISTRATION_PROOF.done == true) && _registrationProof()}
                {(typeof form.REGISTRATION_PROOF.type !== 'undefined' || form.REGISTRATION_PROOF.done == true) &&
                    <FileInput
                        style={{ marginTop: 10, width: '90%', alignSelf: 'center' }}
                        onValueChange={_onValueChange('REGISTRATION_PROOF')}
                        onSave={_onSave('REGISTRATION_PROOF')}
                        markAsUploaded={form.REGISTRATION_PROOF?.done}
                        files={form.REGISTRATION_PROOF?.files}
                        possibleFileTypes={['jpg', 'png', 'jpeg', 'pdf', 'gif']}
                        infoText={'$finances.kycForm.possibleDocumentsLimitation'}
                        showHelp
                    />}
            </View>
            {company.LegalPersonType !== codes.COMPANY_TYPES.SOLETRADER &&
                <View style={{ marginBottom: company.LegalPersonType === codes.COMPANY_TYPES.ORGANIZATION ? 100 : 0 }}>
                    <SimpleText style={styles.directionText}>
                        $finances.kycForm.statutes
                </SimpleText>
                    <View style={styles.separator}></View>
                    <FileInput
                        style={{ marginTop: 10, width: '90%', alignSelf: 'center' }}
                        onValueChange={_onValueChange('ARTICLES_OF_ASSOCIATION')}
                        onSave={_onSave('ARTICLES_OF_ASSOCIATION')}
                        markAsUploaded={form.ARTICLES_OF_ASSOCIATION?.done}
                        files={form.ARTICLES_OF_ASSOCIATION?.files}
                        possibleFileTypes={['jpg', 'png', 'jpeg', 'pdf', 'gif']}
                        infoText={'$finances.kycForm.possibleDocumentsLimitation'}
                        showHelp
                    />
                </View>}

            {company.LegalPersonType === codes.COMPANY_TYPES.BUSINESS && <View style={{ paddingBottom: 50 }}>
                <SimpleText style={styles.directionText}>
                    $finances.kycForm.shareholderDeclaration
                </SimpleText>
                <View style={styles.separator}></View>
                <SimpleText style={{ width: '80%', alignSelf: 'center', paddingBottom: 20 }}>
                    $finances.kycForm.shareholderDeclarationDesc
                </SimpleText>
                {_ubos()}
            </View>}

            <FloatingButton
                onPress={_onContinue}
                text={'$finances.kycForm.store'}
                type={'solid'} />

            <FeedbackModal
                style={{ alignSelf: 'center' }}
                error={typeof status.errorView !== 'undefined'}
                errorText={status.errorView}
                onFeedbackPress={() => setStatus({ ...status, errorView: undefined })}
                loading={status.uploading}
                success={status.success}
            />
        </KeyboardAwareScrollView>
    </ScrollView>
    )
}


const updateStyles = () => StyleSheet.create({
    directionText: {
        paddingTop: 30,
        width: '80%',
        alignSelf: 'center',
        ...TextStyle.size.large
    },
    descriptionText: {
        width: '90%',
        alignSelf: 'center',
    },
    titleText: {
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 5
    },
    halfCard: {
        flex: 1,
        alignSelf: 'flex-start'
    },
    cardConatiner: {
        flexDirection: 'row',
        alignSelf: 'center',
        width: '100%',
    },
    warningText: {
        color: AppStyle.warningColor,
        ...TextStyle.size.medium,
        width: '100%',
        maxWidth: 320,
        alignSelf: 'center',
        textAlign: 'left',
    },
    iconStyle: {
        marginRight: 10,
        alignSelf: 'center',
    },
    separator: {
        width: '80%',
        alignSelf: 'center',
        borderBottomWidth: 3,
        paddingTop: 10,
        marginBottom: 10
    },
});

export default KYCFormScreen;
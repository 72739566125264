import React from 'react';

import { TextStyle } from "@style";
import { SimpleText } from '@quarks'

export const PrivacyPolicyEnglish = () => {
  return (
    <>
      <SimpleText style={{
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
      }}>
        Privacy Policy
    </SimpleText>
      <div className="container-s">
        <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
          In the following policy, Nadius refers to the service offered by Nadius Technologies (the "Company" or "We") through the nadius.cat website and Nadius app (the "Service"). This Privacy Policy explains (i) what information we collect through your access and use of our Service (ii) the use we make of such information; and (iii) the security level we provide for protecting such information.
                  <br /> <br />
                  By visiting and using the Services provided here through the nadius.cat and the Nadius Mobile App (jointly "Platform"), you agree to the terms outlined in this privacy policy.
                  <h6 className="mt-3 mb-16 " data-reveal-delay="200">
            Legal Framework
                  </h6>
                  The Company is domiciled in Spain at the following address:
                  <br />
                  Nadius, Avinguda Madrid 191, 08014 Barcelona
                  <br />
                  Data storage infrastructure is located in AWS Frankfurt and Ireland facilities, and thus governed by the laws and regulations of such countries and EU.
                  <h5 className="mt-3 mb-16 " data-reveal-delay="200">
            Data related to the opening of an account
                  </h5>
                  When you register as a member on the Platform, you must provide the following data in order to carry out the registration procedure and access your Nadius account:
                  <br /><br />Your name;
                  <br />Email address;
                  <br />Confirmation that you are aged over 18;
                  <br />Password. <br /> <br />
                  When you register as a member on the Platform as a Seller, you must provide the previous mentioned data and following data in order to carry out the registration procedure and access your Nadius account:
                  <br /><br />Full Name;
                  <br />Name of the company or legal entity responsible of Selling;
                  <br />Fiscal data;
                  <br />Verification documents for the titularity of the company;
                  <br />Phone number;
                  <br />Date of birth;
                  <br />Nacionality;
                  <br />Country of residence;

                  Legal basis for the collection and use of data is the performance of a contract (GTC) to which you are party or in order to take steps at your request prior to entering into a contract (GTC) (Art. 6 (1) (b) of the GDPR).
                  <br /> <br />
                  Such data will only be used to contact you with important notifications about Nadius, to send you information related to security, to keep you updated on orders and products obtained through the Platform, to verify your Nadius account, or to send you password recovery links if you enable the option. We may also inform you about new Nadius products in which you might have an interest. You are free, at any given time, to opt-out of those features through the account settings panel.
                  <br /> <br />
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Data Collection
                  </h4>
          <p style={{ fontWeight: 'bold' }}>Our company’s overriding policy is to collect as little user information as possible to ensure a private user experience when using the Service.</p>
                  Service's user data collection is limited to the following:
                  <br /> <br />
          <p style={{ fontWeight: 'bold' }}>Visiting our website: <p style={{ fontWeight: 'normal' }}>Any request performed through the website, generates a temporal log in our Cloud Service, containing the IP and date of such request, which is later removed after several days.</p></p>
          <p style={{ fontWeight: 'bold' }}>Communicating with Nadius: <p style={{ fontWeight: 'normal' }}>Your communications with the Company, such as support requests, bug reports, or feature requests may be saved by our staff. The legal basis for processing is our legitimate interest to troubleshoot more efficiently and improve the quality of the Nadius platform.</p></p>
          <p style={{ fontWeight: 'bold' }}>IP Logging: <p style={{ fontWeight: 'normal' }}>By default, Nadius does not keep permanent IP logs. However, IP logs may be kept temporarily to combat abuse and fraud. The legal basis of this processing is our legitimate interest to protect our service against nefarious activities.</p></p>
          <p style={{ fontWeight: 'bold' }}>Receive items: <p style={{ fontWeight: 'normal' }}>If you choose to enter your shipping information or when you buy items, we collect and use the following data that you provide for dispatch and shipping purposes:
                  <br /> <br />Full name;
                  <br />Country;
                  <br />City;
                  <br />Address;
                  <br />Telephone number;
                  <br />Other shipping information required by a particular shipping service provider.
                  <br /> <br />
                  Legal basis for the collection and use of data is the performance of a contract (GTC) to which you are party (Art. 6 (1) (b) of the GDPR).
                  <br /> <br />
                  Personal data are required to perform a contract (GTC) with you. In case you do not provide us with this personal data, we will not be able to conclude and execute a contract (GTC) with you.
                  <br /> <br />
                  We provide personal data collected and used for this purpose to Seller of the item and it is up to the seller to follow the instructions and provide your data to the shipping provider.
                  </p></p>
          <p style={{ fontWeight: 'bold' }}>Payment Information: <p style={{ fontWeight: 'normal' }}>The Company relies on third parties to process credit card transactions so the Company necessarily must share payment information with third parties. The legal basis of this processing is the necessity to the execution of the contract between you and us.</p></p>
          <p style={{ fontWeight: 'bold' }}>Native Applications: <p style={{ fontWeight: 'normal' }}>
            When you use our native applications, we (or the mobile app platform providers) may collect certain information in addition to the information mentioned elsewhere in this Policy.
            We may use mobile analytics software (such as Play Store app statistics, App Store app statistics, or appcenter crash reporting and analytics) to send crash information to our developers so that we can fix bugs rapidly.
                    <br /><br />Some platforms (such as the Google Play Store or the Apple App Store) may also collect aggregate, anonymous statistics like which type of devices and operating systems that are most commonly used (like percentage of Android 6.x vs Android 7.x), the total number of installs, total number of uninstalls, and the total number of active users, and may be governed by the privacy policy and terms and conditions of the Google Play Store or the Apple App Store.
                    <br /><br />None of the software on our apps will ever access or track any location-based information from your device at any time.
                    <br /> <br /> Any personal data acquired during this process is anonymized.</p></p>
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Data Use
                  </h4>
                  We do not have any advertising on our site. Any data that we do have will never be shared except under the circumstances described below in the Data Disclosure Section. We may do analysis on the limited data we do posses but always to improve our services and/or provide aggregated statistics to the platform vendors.
                  <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Data Storage
                  </h4>
                  All servers used in connection with the provisioning of the Service are operated by Nadius and owned by MongoDB, Inc. Only employees of the Company have access to the servers. Data is ALWAYS stored in encrypted format on our servers. Offline backups may be stored periodically, but these are also encrypted.
                  <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Right to Access, Rectification, Erasure, Portability, and right to lodge a complaint
                  </h4>
                  Through the Service, you can directly access, edit, delete or export personal data processed by the Company in your use of the Service.
                  <br /><br />
                  If your account has been suspended for a breach of our terms and conditions, and you would like to exercise the rights related to your personal data, you can make a request to our support team.
                  <br /><br />
                  In case of violation of your rights, you have the right to lodge a complaint to the competent supervisory authority.
                  <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Data Retention
                  </h4>
                  When a Nadius account is closed, all data which does not need to be keept under any legal obligation is deleted immidiently. All remaining is marked as unaccessible from our production servers and keept until all legal obligations are meet. Active accounts will have data retained indefinitely.
                  <br /><br />
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Data Disclosure
                  </h4>
                  We will only disclose the limited user data we possess if we are instructed to do so by a fully binding request coming from the competent authorities (legal obligation).
                  <br /><br />
                  While we may comply with electronically delivered notices, the disclosed data can only be used in court after we have received an original copy of the court order by registered post or in person, and provide a formal response.
                  <br /><br />
                  If permitted by law, Nadius will always contact a user first before any data disclosure.
                  <br /><br />
                  Nadius may from time to time, contest requests if there is a public interest in doing so. In such situations, the Company will not comply with the request until all legal or other remedies have been exhausted. Therefore, not all requests will lead to data disclosure.
                  <br /><br />
          <h4 className="mt-3 mb-16 " data-reveal-delay="200">
            Modifications to Privacy Policy
                  </h4>
                  Nadius reserves the right to periodically review and change this policy from time to time. Continued use of the Service will be deemed as acceptance of such changes.
                  </p>
      </div>
    </>
  );
}
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View, Dimensions, RefreshControl, Platform, UIManager, Keyboard, Pressable } from 'react-native';
import { useQuery } from '@apollo/client';
import moment from "moment/min/moment-with-locales"
import SegmentedControlTab from "react-native-segmented-control-tab";

import { Order } from '@api'
import { LoadingView, BlurModal, ErrorView } from '@atoms';
import { AppStyle, TextStyle } from '@style';
import { BottomModal, PhoneCardInput } from '@organisms'
import { OrderComponents, CardInput, keyboardType } from '@molecules';
import { SimpleText, ButtonText } from '@quarks'
import { DateCardInput, RangeHourPicker } from '@organisms';
import { transitOrderFormValidation } from 'nexto-formvalidator';
import { Icons } from '@icons'
import { order } from 'nexto-codes'
import { useAuth } from '@realm'
import { replaceLocale } from '@utilities/locale'
import { KeyboardAwareScrollView } from '@platform'
import { removeField } from 'nexto-utils/objects'


const OrderDetailsScreen = (props) => {

    const styles = updateStyles()

    const { user, callFunction } = useAuth();

    let initialState = {
        errorView: undefined,
        successView: undefined,
        updateModal: undefined,
        updating: undefined,
        timeout: undefined,
        sendingMessage: false
    }

    const [state, setState] = useState(initialState)

    const cancelOptions = [
        { value: order.CANCEL_CODES.INCORRECT_PRODUCTS, label: replaceLocale('$order.details.cancelIncorrectProducts') },
        { value: order.CANCEL_CODES.NO_STOCK, label: replaceLocale('$order.details.cancelNoStock') },
        { value: order.CANCEL_CODES.OTHER, label: replaceLocale('$order.details.cancelOther') }
    ]

    const [orderParam, setOrder] = useState({ ...props.order })

    let initialForm = {
        external: {
            trackingId: undefined,
            company: undefined,
            companyName: undefined,
            sendingTime: undefined,
        },
        personal: {
            message: undefined,
            date: undefined,
            timeRange: undefined
        },
        selectedIndex: undefined
    }

    const [errorForm, setErrorForm] = useState({
        ...initialForm
    })
    const [updateForm, setUpdateForm] = useState(
        {
            ...initialForm,
            cancel: order.CANCEL_CODES.INCORRECT_PRODUCTS,
            personal: {
                ...initialForm.personal,
                date: new Date(),
                timeRange: {
                    start: new Date(moment().set({ hour: 9, minutes: 0 })),
                    end: new Date(moment().set({ hour: 18, minutes: 0 })),
                }
            },
            external: initialForm.external,
            selectedIndex: 0
        })

    const _deliveryCompanyNames = () => {
        return [
            { value: 'MRW', label: 'MRW' },
            { value: 'Seur', label: 'Seur' },
            { value: 'Correos', label: 'Correos' },
            { value: 'Nacex', label: 'Nacex' },
            { value: 'other', label: '$order.details.otherCompany'.localise() }
        ]
    }

    const _onCompleted = (data) => {
        if (typeof data.order !== 'undefined' && data.order !== null) {
            let order = { ...orderParam }
            order.products = []
            if (typeof data.order.products !== 'undefined') {
                let index = 0
                data.order.products.forEach((product) => {
                    order.products.push({ item: product, index: index })
                    index += 1
                })
            }
            order.status = data.order.status
            order.cancel = data.order.cancel
            order.tracking = data.order.tracking
            order.dispute = data.order.dispute
            order.pickup = data.order.pickup
            order.contact = data.order.contact
            order.messages = data.order.messages
            order.direction = data.order.direction
            order.aggregatedId = data.order.aggregatedId
            order.dates = data.order.dates
            order.zone = data.order.zone
            order.delivery = data.order.delivery

            let update = { ...updateForm }
            update.message = undefined
            if (typeof order.zone?.modality?.external !== 'undefined' && order.zone?.modality?.external != null) {
                update = { ...update, selectedIndex: 0, external: { ...update.external, sendingTime: order.zone.modality.external.time } }
            } else if (typeof order.zone?.modality?.personal !== 'undefined' && order.zone?.modality?.personal != null) {
                update = { ...update, selectedIndex: 1 }
            }
            if (typeof order.tracking !== 'undefined' && order.tracking != null) {
                if (typeof order.tracking.external !== 'undefined' && order.tracking.external != null) {
                    update.external = { ...order.tracking.external }
                    update.selectedIndex = 0
                    let name = _deliveryCompanyNames().find(ele => ele.value == order.tracking.external.company)
                    if (!name) {
                        update.external.company = 'other'
                        update.external.companyName = order.tracking.external.company
                    }
                }
                if (typeof order.tracking.personal !== 'undefined' && order.tracking.personal != null) {
                    update.personal = {
                        ...order.tracking.personal,
                        date: order.tracking.personal.date ? new Date(order.tracking.personal.date) : new Date(),
                        timeRange: { ...order.tracking.personal.timeRange }
                    }
                    update.selectedIndex = 1
                }
            }
            if (typeof order.contact !== 'undefined' && order.contact != null) {
                update = {
                    ...update, personal: {
                        ...update.personal, phone: {
                            number: order.contact.phone.number,
                            countryCode: order.contact.phone.countryCode
                        }
                    }
                }
            }
            setUpdateForm(update)
            setOrder(order)
        }
    }

    let { query, param } = Order.getByIdProducer(orderParam._id);
    const { loading, error, data, refetch } = useQuery(query, {
        ...param,
        onCompleted: _onCompleted
    });

    if (typeof data?.order?.status !== 'undefined' && data?.order?.status != null &&
        data.order.status !== orderParam.status) {
        refetch(param).then(result => _onCompleted(result?.data))
    }

    const _successView = (message = '$success') => {
        setState({ ...state, successView: message, updating: undefined, })
    }

    const _errorView = (status) => {
        setState({
            ...state, errorView: replaceLocale(order.RESPONSE_MESSAGES[status]),
            updating: undefined, sendingMessage: false
        })
    }

    React.useEffect(() => {
        props.navigation.setOptions({
            headerStyle: {
                backgroundColor: AppStyle.mainColor,
                shadowColor: 'transparent',
                elevation: 0
            },
            headerTintColor: AppStyle.textColor,
            headerTitle: orderParam.aggregatedId,
            headerBackTitle: '$order.list.title'.localise(),
            headerTruncatedBackTitle: '$button.headerTruncatedBack'.localise(),
            headerTransparent: false,
        })
    }, [props.navigation, orderParam.aggregatedId])

    const _controlPanel = () => {
        let message = "$order.details.updateState"
        let status = orderParam.status
        switch (status) {
            case order.STATUS.CREATED:
                message = "$order.details.acceptOrder"
                break;
            case order.STATUS.PREPARING:
                if (typeof orderParam.pickup !== 'undefined' && orderParam.pickup != null) {
                    message = "$order.details.markAsReadyToPickUp"
                } else {
                    message = "$order.details.markAsSended"
                }
                break;
            case order.STATUS.TRANSIT:
                if (typeof orderParam.pickup !== 'undefined' && orderParam.pickup != null) {
                    message = "$order.details.markAsPickup"
                } else {
                    message = "$order.details.markAsDelivered"
                }
                break;
            case order.STATUS.DISPUTE:
                message = "$order.details.disputeResolved"
                break;
            default:
                break;
        }
        return (
            <View style={{
                height: status > order.STATUS.DISPUTE && status < order.STATUS.DELIVERED ? status === order.STATUS.TRANSIT ? 150 : 140 : 90,
                marginBottom: 20,
                alignSelf: 'center',
                maxWidth: 250,
                minWidth: 250,
                justifyContent: 'center'
            }}>
                {status !== order.STATUS.DELIVERED && status !== order.STATUS.CANCELED &&
                    <View style={{ flex: 1, alignSelf: 'center', 
                    justifyContent: 'center', paddingBottom: 10 }}>
                        <ButtonText
                            onPress={() => {
                                setErrorForm({ ...initialForm })
                                setState({ ...state, updateModal: true })
                            }}
                            text={message}
                            textStyle={{ height: '100%' }}
                            type={'solid'}
                            style={{ height: 50, minWidth: 200, maxWidth: 250 }}>
                        </ButtonText>
                    </View>}
                <View style={{  flex: 1, alignSelf: 'center', 
                justifyContent: 'center' }}>
                    {status != order.STATUS.DISPUTE && <ButtonText
                        onPress={() => setState({ ...state, updateModal: 'cancel' })}
                        text={'$order.details.cancelOrder'}
                        type={'outline'}
                        numberOfLines={2}
                        textStyle={{ height: '100%', color: AppStyle.errorColor }}
                        style={{
                            height: 50, minWidth: 200, maxWidth: 250,
                            alignSelf: 'center', borderColor: AppStyle.errorColor
                        }}>
                    </ButtonText>}
                </View>
            </View>
        )
    }

    const _changeToAcceptedState = () => {
        return (
            <ScrollView contentContainerStyle={{
                alignSelf: 'center',
                justifyContent: 'space-between', width: '90%'
            }}>
                <SimpleText style={[styles.contentTitles]}>$order.details.acceptOrder</SimpleText>
                <View style={styles.titleSeparator}></View>
                <SimpleText style={{ flex: 1 }}>$order.details.acceptOrderDescription</SimpleText>
            </ScrollView>
        )
    }

    const _changeToReadyToPickupState = () => {
        return (
            <ScrollView contentContainerStyle={{
                alignSelf: 'center',
                justifyContent: 'space-between', width: '90%'
            }}>
                <SimpleText style={[styles.contentTitles]}>$order.details.markAsReadyToPickUp</SimpleText>
                <View style={styles.titleSeparator}></View>
                <SimpleText style={{ flex: 1 }}>$order.details.markAsReadyToPickUpDescription</SimpleText>
            </ScrollView>
        )
    }

    const _onValueChange = (field, subfield) => (value) => {
        let form = { ...updateForm }
        if (typeof subfield !== 'undefined') {
            form[field][subfield] = value
        } else {
            form[field] = value
        }
        setUpdateForm(form)
    }

    const _changeToTransitState = () => {

        console.log('CHANGE ', updateForm.personal.date)

        return (
            <ScrollView
                style={{ width: '95%', alignSelf: 'center' }}
            >
                <SimpleText style={[styles.contentTitles]}>$order.details.sendOrder</SimpleText>
                <View style={styles.titleSeparator}></View>
                <SimpleText style={{ paddingBottom: 15 }} >$order.details.selectSendingMethod</SimpleText>
                <SegmentedControlTab
                    tabsContainerStyle={{ width: '100%', alignSelf: 'center', paddingBottom: 10 }}
                    selectedIndex={updateForm.selectedIndex}
                    allowFontScaling={false}
                    values={[replaceLocale('$order.details.extern'), replaceLocale('$order.details.personally')]}
                    onTabPress={_onValueChange('selectedIndex')}
                    tabStyle={{ borderColor: AppStyle.mainColor }}
                    tabTextStyle={{ color: AppStyle.mainColor }}
                    activeTabStyle={{ backgroundColor: AppStyle.mainColor }}
                />
                {updateForm.selectedIndex === 0 &&
                    <View style={{ backgroundColor: AppStyle.backgroundColor }}>
                        <CardInput
                            title={"$order.details.sendingTime"}
                            noInput
                            picker
                            pickerValue={typeof updateForm.external.sendingTime === 'undefined' && typeof orderParam.zone?.modality?.external?.time !== 'undefined' ?
                                orderParam.zone.modality?.external?.time : updateForm.external.sendingTime}
                            pickerPlaceholder={{ label: replaceLocale('$order.details.pickerPlaceholder'), value: undefined }}
                            pickerItems={[
                                { value: order.SENDING_TIME.ONE_DAY, label: replaceLocale('$order.details.oneDay') },
                                { value: order.SENDING_TIME.ONE_TWO_DAYS, label: replaceLocale('$order.details.oneTwoDays') },
                                { value: order.SENDING_TIME.THREE_FOUR_DAYS, label: replaceLocale('$order.details.threeToFour') },
                                { value: order.SENDING_TIME.ONE_WEEK, label: replaceLocale('$order.details.oneWeek') }
                            ]}
                            cardStyle={{ elevation: Platform.OS === 'android' ? 0 : 10, maxWidth: '100%' }}
                            onValueChange={_onValueChange('external', 'sendingTime')}
                            errorText={errorForm.external.sendingTime}
                            style={{ paddingBottom: 10 }}
                        />
                        <CardInput
                            title={"$order.details.idTracking"}
                            icon={Icons.TAG}
                            isOptional={true}
                            onChangeText={_onValueChange('external', 'trackingId')}
                            keyboardType={keyboardType.decimalPad}
                            inputText={updateForm.external.trackingId}
                            placeholder={'123456789'}
                            style={{ marginBottom: 15 }}
                            errorText={errorForm.external.trackingId}
                            cardStyle={{ elevation: Platform.OS === 'android' ? 0 : 10, maxWidth: '100%' }}
                            infoText={"$order.details.idTrackingHelp"}
                            showHelp={true}
                        />
                        <CardInput
                            title={'$order.details.company'}
                            noInput
                            picker
                            pickerValue={updateForm.external.company}
                            pickerPlaceholder={{ label: replaceLocale('$order.details.pickerPlaceholder'), value: undefined }}
                            pickerItems={_deliveryCompanyNames()}
                            style={{ marginBottom: 15 }}
                            cardStyle={{ elevation: Platform.OS === 'android' ? 0 : 10, maxWidth: '100%' }}
                            onValueChange={_onValueChange('external', 'company')}
                            errorText={typeof errorForm.external.company == 'undefined' ? errorForm.external.companyName : errorForm.external.company}
                        />
                        {updateForm.external.company === 'other' && <CardInput
                            title={'$order.details.name'}
                            icon={Icons.TAG}
                            onChangeText={_onValueChange('external', 'companyName')}
                            inputText={updateForm.external.companyName}
                            placeholder={'Empresa S.L.'}
                            errorText={errorForm.external.companyName}
                            cardStyle={{ elevation: Platform.OS === 'android' ? 0 : 10, maxWidth: '100%' }}
                            infoText={"$order.details.nameOfCompany"}
                            showHelp={true}
                        />}
                    </View>}
                {updateForm.selectedIndex === 1 &&
                    <View style={{}}>
                        <DateCardInput
                            onValueChange={_onValueChange('personal', 'date')}
                            title={"$order.details.sendingDate"}
                            cardStyle={{ width: '100%', marginBottom: 10 }}
                            errorText={errorForm?.personal?.date}
                            date={typeof updateForm.personal?.date === 'string' || updateForm.personal.date ?
                                new Date(updateForm.personal.date) : new Date()} />
                        <RangeHourPicker
                            range={updateForm.personal.timeRange}
                            onValueChange={_onValueChange('personal', 'timeRange')}
                            errorText={errorForm?.personal?.timeRange && typeof errorForm?.personal?.timeRange === 'string' ?
                                errorForm?.personal?.timeRange : undefined}
                            style={{ marginBottom: 10 }} />

                        <PhoneCardInput
                            title={'$order.details.contactPhone'}
                            style={{ marginBottom: 10 }}
                            value={updateForm.personal.phone}
                            onValueChange={_onValueChange('personal', 'phone')}
                            errorText={errorForm?.personal?.phone} />

                        <CardInput
                            title={'$order.details.messageToClient'}
                            multiline={true}
                            maxLength={150}
                            minHeight={110}
                            isOptional={true}
                            style={{ marginBottom: 10 }}
                            onChangeText={_onValueChange('personal', 'message')}
                            inputText={updateForm.personal.message}
                            keyboardType={keyboardType.default}
                            placeholder={'$order.details.canContact'}
                            errorText={errorForm?.personal?.message}
                            infoText={'$order.details.messageToClientHelp'}
                            showHelp={true}
                            cardStyle={{ elevation: Platform.OS === 'android' ? 0 : 10, maxWidth: '100%', padding: 10 }}
                        />
                    </View>}
            </ScrollView >
        )
    }

    const _changeDeliveredState = () => {
        return (
            <ScrollView contentContainerStyle={{
                alignSelf: 'center',
                justifyContent: 'space-between', width: '90%'
            }}>
                <SimpleText style={[styles.contentTitles]}>$order.details.markAsDelivered</SimpleText>
                <View style={styles.titleSeparator}></View>
                <SimpleText style={{ flex: 1 }}>$order.details.markAsDeliveredDescription</SimpleText>
            </ScrollView>
        )
    }

    const _changeToPickupState = () => {
        return (
            <ScrollView contentContainerStyle={{
                alignSelf: 'center',
                justifyContent: 'space-between', width: '90%'
            }}>
                <SimpleText style={[styles.contentTitles]}>$order.details.markAsPickup</SimpleText>
                <View style={styles.titleSeparator}></View>
                <SimpleText style={{ flex: 1 }}>$order.details.markAsPickupDescription</SimpleText>
            </ScrollView>
        )
    }

    const _updateDispute = () => {
        return (
            <ScrollView contentContainerStyle={{
                alignSelf: 'center',
                justifyContent: 'space-between', width: '90%'
            }}>
                <SimpleText style={[styles.contentTitles]}>$order.details.disputeResolved</SimpleText>
                <View style={styles.titleSeparator}></View>
                <SimpleText style={{ flex: 1 }}>$order.details.disputeResolvedDescription</SimpleText>
            </ScrollView>
        )
    }

    const _orderConfirm = async () => {
        let result = await callFunction('ORDER_confirm', { orderId: orderParam._id })
        if (result.status === 0) {
            refetch(param).then(result => {
                _onCompleted(result?.data)
                _successView()
            })
        } else {
            _errorView(result.status)
        }
    }

    const _orderReadyToPickup = async () => {
        let result = await callFunction('ORDER_readyToPickup', { orderId: orderParam._id })
        if (result.status === 0) {
            refetch(param).then(result => {
                _onCompleted(result?.data)
                _successView()
            })
        } else {
            _errorView(result.status)
        }
    }

    const _orderTransit = async () => {

        let errorsValidators = transitOrderFormValidation(updateForm)
        if (!errorsValidators.isValid) {
            setErrorForm({ ...errorForm, ...errorsValidators.formErrors })
            setState({ ...state, updating: undefined })
            return
        }

        setErrorForm(initialForm)
        removeField(updateForm, '__typename')

        let result = await callFunction('ORDER_inTransit', { orderId: orderParam._id, form: updateForm })
        if (result.status === 0) {
            refetch(param).then(result => {
                _onCompleted(result?.data)
                _successView()
            })
        } else {
            if (result.status == order.RESPONSE_CODES.INVALID_FORM) {
                if (typeof result.errors !== 'undefined') {
                    setErrorForm(JSON.parse(result.errors))
                }
            }
            _errorView(result.status)
        }
    }

    const _orderDelivered = async () => {
        let result = await callFunction('ORDER_delivered', { orderId: orderParam._id })
        if (result.status === 0) {
            refetch(param).then(result => {
                _onCompleted(result?.data)
                _successView()
            })
        } else {
            _errorView(result.status)
        }
    }

    const _orderDisputeUpdate = async () => {
        let result = await callFunction('ORDER_solveDispute', { orderId: orderParam._id })
        if (result.status === 0) {
            refetch(param).then(result => {
                _onCompleted(result?.data)
                _successView()
            })
        } else {
            _errorView(result.status)
        }
    }

    const _orderCancel = async () => {
        let cancelForm = {
            code: updateForm.cancel
        }
        if (cancelForm.code === order.CANCEL_CODES.OTHER) {
            if (typeof updateForm.cancelMessage !== 'undefined') {
                cancelForm.message = updateForm.cancelMessage.substring(0, 299)
            }
        }
        let result = await callFunction('ORDER_cancel', { orderId: orderParam._id, cancel: cancelForm, fromUser: false })
        if (result.status === 0) {
            refetch(param).then(result => {
                _onCompleted(result?.data)
                _successView()
            })
        } else {
            _errorView(result.status)
        }
    }

    const _cancelOrder = () => {

        let comission = ((orderParam.total + orderParam.delivery) * 0.018 + 0.18).toFixed(2)

        return (
            <ScrollView contentContainerStyle={{
                alignSelf: 'center',
                justifyContent: 'space-between', width: '90%'
            }}>
                <SimpleText style={[styles.contentTitles, { paddingBottom: 0 }]}>$order.details.cancelOrder</SimpleText>
                <View style={styles.titleSeparator}></View>
                <CardInput
                    style={{ marginBottom: 10 }}
                    title={'$order.details.cancelType'}
                    icon={Icons.TAG}
                    noInput
                    picker
                    pickerValue={updateForm.cancel}
                    keyboardType={keyboardType.default}
                    pickerItems={cancelOptions}
                    onValueChange={_onValueChange('cancel')}
                    cardStyle={{ elevation: Platform.OS === 'android' ? 0 : 10, maxWidth: '100%' }}
                    errorText={errorForm.cancel}
                />
                {updateForm.cancel === order.CANCEL_CODES.OTHER && <CardInput
                    title={'$order.details.cancelOtherTitle'}
                    multiline
                    maxLength={300}
                    onChangeText={_onValueChange('cancelMessage')}
                    inputText={updateForm.cancelMessage}
                    keyboardType={keyboardType.default}
                    placeholder={"$order.details.cancelPlaceholder"}
                    errorText={errorForm.cancelMessage}
                    infoText={"$order.details.max300"}
                    showHelp={true}
                    minHeight={120}
                    cardStyle={{ elevation: Platform.OS === 'android' ? 0 : 10, padding: 5, maxWidth: '100%' }}
                />}
                {orderParam.status > order.STATUS.CREATED && <SimpleText style={{ flex: 1, ...TextStyle.size.small }}>
                    {`${replaceLocale('$order.details.cancelDisclaimer')} ${comission} € ${replaceLocale('$order.details.cancelDisclaimerContinue')}`}
                </SimpleText>}
                <SimpleText style={{ flex: 1, ...TextStyle.size.small, paddingTop: 10 }}>
                    $order.details.cancelContact
                </SimpleText>

            </ScrollView>
        )
    }

    const _changeState = () => {
        let status = orderParam.status
        let renderView;
        let execute = async () => { }
        if (state.updateModal === 'cancel') {
            renderView = _cancelOrder()
            execute = _orderCancel
        } else {
            switch (status) {
                case order.STATUS.DISPUTE:
                    renderView = _updateDispute()
                    execute = _orderDisputeUpdate
                    break;
                case order.STATUS.CREATED:
                    renderView = _changeToAcceptedState()
                    execute = _orderConfirm
                    break;
                case order.STATUS.PREPARING:
                    if (orderParam.pickup != null && typeof orderParam.pickup !== 'undefined') {
                        renderView = _changeToReadyToPickupState()
                        execute = _orderReadyToPickup
                    } else {
                        renderView = _changeToTransitState()
                        execute = _orderTransit
                    }
                    break;
                case order.STATUS.TRANSIT:
                    if (state.updateModal === 'edit') {
                        renderView = _changeToTransitState()
                        execute = _orderTransit
                    } else {
                        if (orderParam.pickup != null && typeof orderParam.pickup !== 'undefined') {
                            renderView = _changeToPickupState()
                        } else {
                            renderView = _changeDeliveredState()
                        }
                        execute = _orderDelivered
                    }
                    break;
            }
        }

        return (
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                contentContainerStyle={{
                    justifyContent: 'space-between'
                }}
                showsVerticalScrollIndicator={false}>
                {!state.successView && !state.updating && renderView}
                {!state.successView && !state.updating && <View style={{
                    flexDirection: 'row', justifyContent: 'space-between',
                    paddingTop: 15, paddingBottom: 20, width: '90%', alignSelf: 'center'
                }}>
                    <ButtonText
                        text={'$order.details.cancelChange'}
                        type={'outline'}
                        onPress={() => setState(initialState)}
                        style={{ height: 45, minWidth: 100, }}>
                    </ButtonText>
                    <ButtonText
                        text={'$order.details.changeButton'}
                        type={'solid'}
                        disable={!state.updating}
                        onPress={async () => {
                            if (!state.updating) {
                                setState({ ...state, updating: '$order.details.loading' })
                                await execute()
                            }
                        }}
                        style={{ height: 45, minWidth: 120, }}>
                    </ButtonText>
                </View>}
            </ScrollView >
        )
    }

    const _onModalClose = () => {
        setState(initialState)
    }

    return (<KeyboardAwareScrollView
        refreshControl={
            <RefreshControl
                refreshing={typeof loading === 'undefined' ? false : loading}
                onRefresh={() => refetch(param).then(result => _onCompleted(result?.data))} />
        }
        style={styles.main}>
        <View style={{ flexDirection: 'row', minWidth: 300, flexWrap: 'wrap', width: '80%', justifyContent: 'space-around', alignSelf: 'center' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', minWidth: '100%', flexWrap: 'wrap', flex: 1 }}>
                {OrderComponents.header((loading && typeof data == 'undefined'), error, orderParam.status, {
                    flex: 1,minWidth: 250, backgroundColor: AppStyle.backgroundColor, alignSelf: 'center'
                })
                    (orderParam.dates, orderParam.pickup)}
                <View style={{ alignSelf: 'center', flex: 1, minWidth: 150, justifyContent: 'center' }}>
                    {orderParam.status < order.STATUS.DELIVERED && _controlPanel()}
                </View>
            </View>
            {OrderComponents.cancel(loading && typeof data == 'undefined', error, orderParam.status)(orderParam.cancel)}
            {OrderComponents.dispute(loading && typeof data == 'undefined', error, orderParam.status)(orderParam.dispute)}
            <View style={{ alignSelf: 'center', flex: 1, minWidth: 400, justifyContent: 'center' }}>
                {OrderComponents.summary(loading && typeof data == 'undefined', error)(orderParam.products, orderParam.delivery, orderParam.pickup, orderParam.total)}
            </View>
        </View>
        {typeof orderParam?.aggregatedId !== 'undefined' &&
            orderParam.aggregatedId.length >= 10 && <View style={styles.contentTitlesView}>
                <SimpleText selectable={true}>$order.details.orderNumber</SimpleText>
                <SimpleText selectable={true} style={styles.orderId}>{orderParam.aggregatedId}</SimpleText>
            </View>}
        <BottomModal
            style={{ maxWidth: 500, alignSelf: 'center' }}
            onSuccess={_onModalClose}
            onTimeout={() => {
                setState({ ...state, timeout: true })
            }}
            error={typeof state.errorView !== 'undefined'}
            errorText={state.errorView}
            loading={typeof state.updating !== 'undefined'}
            loadingText={state.updating}
            success={typeof state.successView !== 'undefined'}
            successText={state.successView}
            onRequestClose={_onModalClose}
            visible={typeof state.updateModal !== 'undefined'}>
            {_changeState()}
        </BottomModal>
    </KeyboardAwareScrollView>
    )
};

const updateStyles = () => StyleSheet.create({
    main: {
        flex: 1,
        backgroundColor: AppStyle.backgroundColor
    },
    separator: {
        width: '100%', borderBottomWidth: 1, borderBottomColor: AppStyle.backgroundColorInverted,
        marginTop: 10, marginBottom: 10
    },
    itemViewTitle: {
        width: '100%', alignSelf: 'center',
        flexDirection: 'row', justifyContent: 'center',
        paddingBottom: 5, marginBottom: 5
    },
    itemTitleColumns: {
        ...TextStyle.size.regular,
        ...TextStyle.weight.bold,
        flex: 1,
    },
    activeStatus: {
        ...TextStyle.weight.bold,
        ...TextStyle.size.regular,
        flex: 1,
        alignSelf: 'flex-start',
    },
    statusItem: {
        ...TextStyle.size.regular,
        flex: 1,
        alignSelf: 'flex-start',
    },
    titleSeparator: {
        width: '100%', height: 1, marginTop: 5, marginBottom: 10, backgroundColor: AppStyle.textColorLight
    },
    timeActive: {
        ...TextStyle.size.regular,
        ...TextStyle.weight.bold,
        color: AppStyle.mainColor
    },
    time: {
        ...TextStyle.size.regular,
    },
    itemUnits: {
        flex: 1,
        textAlign: 'center'
    },
    itemName: {
        ...TextStyle.size.regular,
        flex: 3,
        paddingLeft: 10,
        flexWrap: 'wrap'
    },
    itemPrice: {
        flex: 1,
        alignSelf: 'flex-end',
        ...TextStyle.size.regular,
        textAlign: 'center'
    },
    headerTitle: {
        textAlign: 'center',
        ...TextStyle.padding.regular,
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        ...TextStyle.padding.xlarge
    },
    contentTitlesView: {
        alignSelf: 'center',
        width: '90%',
        maxWidth: 600,
        marginBottom: 10,
    },
    contentTitles: {
        ...TextStyle.size.xlarge,
        borderColor: AppStyle.textColorLight
    },
    orderId: {
        ...TextStyle.weight.bold,
        ...TextStyle.size.mini,
        paddingBottom: 10
    },
    aggregatedId: {
        ...TextStyle.weight.bold,
        ...TextStyle.size.mini,
        paddingBottom: 5
    },
    insideTitle: {
        ...TextStyle.size.large,
    },
    listCard: {
        width: '88%',
        marginTop: 10,
        marginBottom: 20,
        alignSelf: 'center',
    },
    direction: {
        minHeight: 80,
        width: '90%',
        marginBottom: 20
    },

});

export default OrderDetailsScreen
import React from 'react';
import { View, StyleSheet, RefreshControl, Platform, ScrollView, LayoutAnimation, UIManager, Image, Pressable, Dimensions } from 'react-native';
import { useQuery } from '@apollo/client';
import moment from "moment/min/moment-with-locales"
import { useSelector, useDispatch } from 'react-redux'
import { Calendar } from 'react-native-calendars';
import { SafeAreaView } from 'react-native-safe-area-context';


import { useAuth } from '@realm'
import { NadiusIcons } from '@icons'
import { PriceUnitString, LoadingView, ErrorView } from '@atoms';
import { SimpleText, ButtonText } from '@quarks';
import { AppStyle, TextStyle } from '@style'
import { Order } from '@api'
import { order } from 'nexto-codes'
import { Icons } from '@icons';
import OrderDetailsScreen from '_scenes/order/details';
import { sessionOperations } from '_ducks/session';
import { Icon } from '@quarks'
import { BottomModal } from '@organisms';
import { getTranslation } from '@utilities/locale';

const OrdersScreen = (props) => {

    const dispatch = useDispatch()
    let config = LayoutAnimation.create(200, 'linear', 'opacity')

    if (Platform.OS === 'android') {
        if (UIManager.setLayoutAnimationEnabledExperimental) {
            UIManager.setLayoutAnimationEnabledExperimental(true);
        }
    }

    let initialState = {
        errorView: undefined,
        successView: undefined,
        visible: undefined,
        updating: undefined,
        timeout: undefined,
        sendingMessage: false,
        selectedOrder: undefined,
        selectedDate: (moment().day(8)).set({ hour: 11, second: 0, minute: 0, millisecond: 0 })
    }
    const [state, setState] = React.useState(initialState)

    const styles = updateStyles()

    let orders = {
        list: [],
        counters: {
            preparing: 0,
            inLogistics: 0,
            disputes: 0
        }
    }

    let computedValue = Math.ceil((Dimensions.get('window').width / 150) * 2)
    const limit = computedValue < 6 ? 6 : computedValue

    const { query, param, name } = Order.getAllByProducer(new Date(state.selectedDate), 0, limit)

    const _onCompleted = () => {
        dispatch(sessionOperations.storeQuery(name))
    }

    let { loading, error, data, refetch, networkStatus, called } = useQuery(query, {
        ...param,
        onCompleted: _onCompleted
    });

    const queryRedux = useSelector(state => state.session?.queries?.[name])

    let received;
    if (typeof data !== 'undefined' && typeof data.OrdersProducer !== 'undefined') {
        orders.list = data.OrdersProducer
        orders.counters.totalIncome = 0
        for (let index = 0; index < orders.list.length; index++) {
            const item = orders.list[index];
            orders.counters.totalIncome += item.total
            if (item.status == order.STATUS.CONSOLIDATION_READY) {
                orders.counters.inLogistics += 1
            } else if (item.status == order.STATUS.PREPARING_READY) {
                orders.counters.preparing += 1
            }
        }
    }

    const _renderHeader = () => {
        return (<View style={{
            width: '100%', minHeight: 60, flexDirection: 'row', justifyContent: 'center',
            backgroundColor: AppStyle.getColor('dark', 6), flexWrap: 1
        }}>
            <SimpleText style={{
                ...TextStyle.weight.bold, textAlign: 'center', flex: 1,
                color: AppStyle.mainColor, alignSelf: 'center'
            }}>{'$orders.tableOrder'.localise().toUpperCase()}</SimpleText>
            <SimpleText style={{
                ...TextStyle.weight.bold, textAlign: 'center', flex: 1,
                color: AppStyle.textColorLight, alignSelf: 'center'
            }}>{'$orders.tableDestination'.localise().toUpperCase()}</SimpleText>
            <SimpleText style={{
                textAlign: 'center', flex: 1, alignSelf: 'center'
            }}>{'$orders.tableTotal'.localise().toUpperCase()} (€)</SimpleText>
            <SimpleText style={{
                textAlign: 'center', flex: 1,
                color: AppStyle.getColor('mainAccent', 2), alignSelf: 'center'
            }}>{'$orders.tableStatus'.localise().toUpperCase()}</SimpleText>

        </View>)
    }

    const _getStatusTitle = (status) => {
        if (status < order.STATUS.CONSOLIDATION_READY) {
            return '$orders.preparingReady'
        } else {
            return '$orders.consolidationReady'
        }
    }

    const _renderProductHeader = () => {
        return (<View style={{
            width: '100%', minHeight: 40, flexDirection: 'row', justifyContent: 'center',
            backgroundColor: AppStyle.getColor('dark', 5), flexWrap: 1
        }}>
            <SimpleText style={{
                ...TextStyle.weight.bold, textAlign: 'center', flex: 1,
                color: AppStyle.textColorLight, alignSelf: 'center'
            }}>Nom</SimpleText>
            <SimpleText style={{
                ...TextStyle.weight.bold, textAlign: 'center', flex: 1,
                color: AppStyle.textColorLight, alignSelf: 'center'
            }}>Unitats</SimpleText>
            <SimpleText style={{
                textAlign: 'center', flex: 1,
                color: AppStyle.getColor('mainAccent', 2), alignSelf: 'center'
            }}>Preu</SimpleText>
        </View>)
    }

    const _renderProduct = (product) => {
        return (<View style={{
            width: '100%', minHeight: 40, flexDirection: 'row',
            justifyContent: 'center', flexWrap: 1
        }}>
            <SimpleText style={{
                ...TextStyle.weight.bold, textAlign: 'center', flex: 1,
                color: AppStyle.textColorLight, alignSelf: 'center'
            }}>{getTranslation(product.translations)?.name}</SimpleText>
            <SimpleText style={{
                textAlign: 'center', flex: 1, alignSelf: 'center'
            }}>{product.units}u de {PriceUnitString(product)?.itemUnit}</SimpleText>
            <SimpleText style={{
                textAlign: 'center', flex: 1,
                color: AppStyle.getColor('mainAccent', 2), alignSelf: 'center'
            }}>{product.price} €</SimpleText>
        </View>)
    }

    const _renderItem = ({ item, index }) => {

        if (state.selectedOrder == index) {
            console.log(item)
        }

        let backgroundColor = AppStyle.getColor('secondaryAccent', 1)
        if (status > order.STATUS.CONSOLIDATION_READY) {
            backgroundColor = AppStyle.get('secondary', 1)
        }

        return (<Pressable
            style={{
                justifyContent: 'center', borderBottomWidth: 0.5,
                width: '100%', borderBottomColor: AppStyle.getColor('dark', 5)
            }}
            onPress={() => {
                setState({ ...state, selectedOrder: state.selectedOrder == index ? undefined : index })
            }}>
            <View style={{ flexDirection: 'row', width: '100%', minHeight: 65 }}>
                <View
                    style={{
                        flex: 1, flexDirection: 'row', justifyContent: 'center',
                        color: AppStyle.mainColor, alignSelf: 'center'
                    }}>
                    <Icon
                        iconSet={NadiusIcons.iconSet}
                        size={20}
                        name={state.selectedOrder == index ? NadiusIcons.DOWN : NadiusIcons.RIGHT}
                        color={AppStyle.mainColor}
                    />
                    {Dimensions.get('window').width > 400 && <SimpleText style={{
                        ...TextStyle.weight.bold, textAlign: 'center', marginLeft: 10,
                        color: AppStyle.mainColor, alignSelf: 'center'
                    }}>{item.aggregatedId}</SimpleText>}
                </View>
                <SimpleText style={{
                    ...TextStyle.weight.bold, textAlign: 'center', flex: 1,
                    color: AppStyle.textColorLight, alignSelf: 'center'
                }}>{item.direction.location.place_name}</SimpleText>
                <SimpleText style={{
                    textAlign: 'center', flex: 1, alignSelf: 'center'
                }}>{parseFloat(item.total).toFixed(2)}€</SimpleText>
                <View style={{ flex: 1, alignSelf: 'center', }}>
                    <SimpleText style={{
                        padding: 20, paddingTop: 5, paddingBottom: 5,
                        alignSelf: 'center', textAlign: 'center', flex: 1,
                        borderRadius: 20, backgroundColor: backgroundColor,
                        ...TextStyle.size.small
                    }}>{_getStatusTitle(item.status)}</SimpleText>
                </View>
            </View>
            {state.selectedOrder == index &&
                <View style={{
                    width: '90%', alignSelf: 'center',
                    paddingBottom: 10, paddingTop: 10
                }}>
                    {_renderProductHeader()}
                    {item.products.map((product) => _renderProduct(product))}
                </View>}
        </Pressable>)
    }

    const emptyView = () => {
        return (<View style={{ alignSelf: 'center', height: 80, justifyContent: 'center', width: '100%' }}>
            <SimpleText style={{ alignSelf: 'center' }}>$orders.noneNow</SimpleText>
        </View>)
    }

    const _refreshData = async () => {
        try {
            await refetch()
        } catch (error) {
            console.log('Unable to perform refetch ', error)
        }
    }

    React.useEffect(() => {
        if (!loading && called && ((queryRedux && queryRedux.needsRefetch) ||
            typeof queryRedux === 'undefined')) {
            dispatch(sessionOperations.storeQuery(name))
            _refreshData()
        }
    }, [queryRedux, loading, called])


    const _onModalClose = () => {
        setState(initialState)
    }

    const _generateDeliveryDays = () => {
        let nextDays = []; //Array where rest of the dates will be stored
        let prevDate = moment().subtract(2, 'months').day('1');//15 days back date from today(This is the from date)
        let nextDate = moment().add(2, 'months').day('1');//Date after 15 days from today (This is the end date)

        //creating JS date objects
        var start = new Date(prevDate);
        var end = new Date(nextDate);

        //Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
        while (start < end) {
            nextDays.push(moment(start).format('YYYY-MM-DD'));
            var newDate = start.setDate(start.getDate() + 7);
            start = new Date(newDate);
        }

        let todayFormatted = moment().format('YYYY-MM-DD')
        let markTodayAsDisabled = false;
        if (!nextDays.includes(todayFormatted)) {
            nextDays.push(todayFormatted)
            markTodayAsDisabled = true
        }

        let newDaysObject = {};
        let formattedSelectedDate = state.selectedDate.format('YYYY-MM-DD')

        nextDays.forEach((day) => {
            if (markTodayAsDisabled && day == todayFormatted) {
                newDaysObject[day] = {
                    disabled: true,
                    marked: false,
                    customStyles: {
                        text: {
                            color: AppStyle.mainColor,
                            fontWeight: 'bold'
                        }
                    }
                };
            } else {
                newDaysObject[day] = {
                    disabled: false,
                    marked: day == formattedSelectedDate,
                    dotColor: AppStyle.textColorInverted,
                    customStyles: {
                        container: {
                            backgroundColor: moment(day) < moment() ?
                                AppStyle.getColor('mainAccent', 4) : AppStyle.getColor('mainAccent', 2)
                        },
                        text: {
                            color: AppStyle.textColorInverted,
                            fontWeight: 'bold'
                        }
                    }
                };
            }
        });
        return newDaysObject
    }

    let dimension = Dimensions.get('window')
    // The first view in the Scroll view, its just to prevent having a white background when 
    // scrolling up... I know its not ideal, but for now is sufficient
    return (<SafeAreaView
        edges={['top']}
        style={{
            height: '100%', width: '100%',
            backgroundColor: AppStyle.mainColor
        }}>
        <ScrollView
            refreshControl={
                <RefreshControl
                    refreshing={loading}
                    onRefresh={_refreshData} />
            }
            contentContainerStyle={{
                backgroundColor: AppStyle.backgroundColor,
                paddingBottom: 50,
            }}
            style={{
                width: '100%',
                backgroundColor: AppStyle.backgroundColor
            }}>
            <View style={{
                flexDirection: 'row', width: '100%',
                alignSelf: 'center', marginTop: 30, marginBottom: 20,
                flexWrap: 'wrap', justifyContent: 'flex-start'
            }}>
                <View style={{
                    flex: 1, justifyContent: 'flex-start', minWidth: 300, height: 350
                }}>
                    <Calendar
                        displayLoadingIndicator={loading}
                        disabledByDefault={true}
                        disableAllTouchEventsForDisabledDays={true}
                        onDayPress={(day) => {
                            setState({ ...state, selectedDate: (moment(day).subtract(1, 'months')).hour(11) })
                        }}
                        style={{
                            maxWidth: 350, minWidth: 300, width: '100%', alignSelf: 'center',
                            shadowOffset: {
                                width: 0,
                                height: 0
                            },
                            shadowOpacity: 0.1,
                            shadowRadius: 8,
                            elevation: 4, flex: 1
                        }}
                        renderArrow={(direction) => (<Icon
                            name={direction === 'right' ? Icons.FORWARD : Icons.BACK}
                            size={15}
                            color={AppStyle.mainColor} />)
                        }
                        markingType={'custom'}
                        markedDates={_generateDeliveryDays()}
                    />
                </View>
                <View style={{ flex: 1, minWidth: 300, marginLeft: 30, justifyContent: 'flex-start' }}>
                    <SimpleText style={{ ...TextStyle.size.large, ...TextStyle.weight.bold }}>$orders.summary</SimpleText>
                    {loading && <LoadingView style={{ height: 100 }} />}
                    {!loading && (!error || error && typeof received !== 'undefined') && <View
                        style={{ marginTop: 10, marginBottom: 10, justifyContent: 'flex-start', alignSelf: 'flex-start' }}>
                        <SimpleText style={styles.name}>{'$orders.preparingTitle'.localise()}: {orders.counters.preparing}</SimpleText>
                        <SimpleText style={styles.name}>{'$orders.total'.localise()}: {orders.counters.totalIncome}€</SimpleText>
                    </View>}
                </View>
            </View>
            <View style={{
                flexWrap: 'wrap', flexDirection: 'row',
                width: '95%', alignSelf: 'center', justifyContent: 'space-around'
            }}>
                <View style={[styles.box]}>
                    {(error && orders.list?.length === 0) && <ErrorView style={{ height: 300 }} error={error} disableConsole />}
                    {!error &&
                        <View style={{
                            flexWrap: 'wrap', alignSelf: 'center', backgroundColor: 'white',
                            justifyContent: 'space-around', padding: 20,
                            width: dimension.width < 400 ? '100%' : '95%',
                            paddingBottom: 50, paddingTop: 20,
                        }}>
                            <SimpleText style={{
                                ...TextStyle.weight.bold, ...TextStyle.size.large,
                                textAlign: 'center', paddingBottom: 20
                            }}>
                                {'$orders.tableTitle'.localise()} {state.selectedDate.format('dddd, D MMMM YYYY')}</SimpleText>
                            <View style={{ width: dimension.width < 400 ? '100%' : '85%', alignSelf: 'center' }}>
                                {_renderHeader()}
                                {orders.list?.length === 0 && emptyView()}
                                {orders.list.length > 0 && orders.list.map((item, index) => _renderItem({ item, index }))}
                            </View>
                        </View>}
                </View>
            </View>
        </ScrollView>
        {typeof state.visible !== 'undefined' && <BottomModal
            closeButton={true}
            style={{ maxWidth: dimension.width < 800 ? dimension.width : 800, alignSelf: 'center' }}
            onSuccess={_onModalClose}
            onTimeout={() => {
                setState({ ...state, timeout: true })
            }}
            error={typeof state.errorView !== 'undefined'}
            errorText={state.errorView}
            loading={typeof state.updating !== 'undefined'}
            loadingText={state.updating}
            success={typeof state.successView !== 'undefined'}
            successText={state.successView}
            onRequestClose={_onModalClose}
            visible={typeof state.visible !== 'undefined'}>
            <OrderDetailsScreen order={state.visible} {...props} />
        </BottomModal>}
    </SafeAreaView>
    )
};

const updateStyles = () => StyleSheet.create({
    sectionTitle: {
        ...TextStyle.weight.bold,
        ...TextStyle.size.large,
        alignSelf: 'center',
    },
    borderBox: {
        borderWidth: 0.5,
        backgroundColor: AppStyle.backgroundColor,
        borderColor: AppStyle.textColorLight,
        borderRadius: 10,
        paddingTop: 15,
        marginTop: 10,
        paddingBottom: 10,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5
    },
    box: {
        width: '90%',
        flex: 1,
        minWidth: 200,
        alignSelf: 'flex-start',
    },
    iconStyle: {
        marginRight: 12,
        alignSelf: 'center',
    },
    name: {
        ...TextStyle.size.small,
        ...TextStyle.weight.bold,
        color: AppStyle.getColor('dark', 2),
        textAlign: 'left'
    },
    number: {
        alignSelf: 'center',
        color: AppStyle.getColor('dark', 3),
    },
    location: {
        ...TextStyle.size.regular,
    },
    locationView: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 10,
    },
    titleView: {
        justifyContent: 'space-evenly',
        paddingLeft: 15,
        paddingRight: 15
    },
    card: {
        alignSelf: 'center',
        height: 120,
        width: '80%',
        marginBottom: 150,
        padding: 20
    },
});


export default OrdersScreen;

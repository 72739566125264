import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import { Icon } from '@quarks'

import { AppStyle, TextStyle } from '@style'
import { CustomizableCardGrid } from '@molecules'
import { ProducerCard } from './producerCard'
import { LoadingView } from '@atoms';
import { SimpleText } from '@quarks';
import { Icons } from '@icons';

import { basketOperations } from '_ducks/basket'

export const ProducerList = (props) => {

    const dispatch = useDispatch()

    const itemSize = {
        height: 150,
        width: Dimensions.get('window').width * 0.95
    }

    const _onItemAdd = (producerName) => (product) => (step) => {
        let newProduct = {
            ...product,
            producer: {name:producerName, ...product.producer }
        }
        dispatch(basketOperations.basketAddItem(newProduct, step))
    }

    const _renderItem = ({ item, index }) => {
        
        if (typeof item === 'undefined') return

        if (index == 0) return props.title
        
        return ( <ProducerCard
            style={{marginTop:20}}
            width={itemSize.width}
            height={itemSize.height}
            producer={item}
            basket={props.basket}
            onProducerPress={props.onProducerPress}
            onProductPress={_onItemAdd(item.name)}
        />
        )

    }

    return (
    <View style={[{ width: '100%', height: '100%'}, props.style]}>
        {!props.loading &&
            <CustomizableCardGrid
                style={[{ backgroundColor: 'transparent', width: '100%', height: '100%' }, props.style]}
                cards={[{}].concat(props.producers)}
                contentContainerStyle={{ padding: 0 }}
                renderItem={_renderItem}
                listType='list'
                itemDimension={240}
                spacing={0}
            />
        }
        {props.loading &&
            <LoadingView/>
        }
        {!props.loading && (typeof props.producers === 'undefined' || props.producers.length == 0) &&
        <View style={[{ alignSelf: 'center', textAlign: 'center', position:'absolute', top:100, justifyContent: 'center' }, props.style]}>
        <Icon
                name={Icons.WARNING}
                size={50}
                color={AppStyle.textColor}
                style={{ textAlign: 'center', paddingBottom: 10 }} />
            <SimpleText style={{
                ...TextStyle.size.xxlarge,
                textAlign: 'center', alignSelf: 'center'
            }}>{props.text ? props.text : 'Upps!'}</SimpleText>
            {props.errorText && <SimpleText style={{
                paddingTop: 10, paddingBottom: 10,
                textAlign: 'center', alignSelf: 'center'
            }}>{props.errorText}</SimpleText>}
            {!props.errorText && <SimpleText style={{
                paddingTop: 10, paddingBottom: 10,
                textAlign: 'center', alignSelf: 'center'
            }}>No hay resultados</SimpleText>}

        </View>
        }
    </View>
    );
}

import { gql } from '@apollo/client';


const getByPostalCode = (postal_code) => {
    return {
        query: gql`
            query ($postal_code: String!) {
                locations (query: {postal_code: $postal_code}){
                  _id
                  place_name
                  admin_name2
                  admin_code2
                  admin_code1
                  location {
                      coordinates
                  }
                  country_code
                }
            }`,
        param: {variables: {postal_code: postal_code}}
    }
}

const getSiblingZones = (parent) => {
    return {
        query: gql`
            query ($parent: String) {
                zones (query: {parent: $parent})  {
                  geoString
                  _id
                  admin_level
                  parent
                  name
                  center {
                      coordinates
                  }
                  hasChilds
                }
            }
        `,
        param: { variables: { parent: parent } }
    }
}

const getContainingZones = (latitude, longitude) => {
    return {
        query: gql`
            query ($lat: Float, $lon: Float) {
                containingZones (input: {lat: $lat, lon: $lon})  {
                  _id
                  admin_level
                  parent
                  parents {
                    _id
                    name
                  }
                  name
                  center {
                      coordinates
                  }
                  hasChilds
                }
            }
        `,
        param: { variables: { lat: latitude, lon: longitude } }
    }
}

const getZones = (zonesList) => {
    return {
        query: gql`
            query ($zonesList: [String]) {
                zones (query: {_id_in: $zonesList})  {
                    geoString
                    _id
                    admin_level
                    parent
                    name
                    center {
                        coordinates
                    }
                    hasChilds
                }
            }
        `,
        param: { variables: { zonesList: zonesList } }
    }
}

const getCurrentZones = (street, location) => {
    return {
        query: gql`
            query ($street: String, $location: CurrentZonesInputLocation) {
                CurrentZones (input: {street: $street, location: $location})  {
                    _id
                    parents {
                        _id
                    }
                    formatted
                    position {
                        lng
                        lat
                    }
                    name
                }
            }
        `,
        param: { variables: { street: street, location: location } }
    }
}

const getLocationZone = (position) => {
    return {
        query: gql`
            query ($position: LocationZoneInputPosition) {
                LocationZone (input: {position: $position})  {
                    _id
                    parents {
                        _id
                    }
                    name
                }
            }
        `,
        param: { variables: { position: position } }
    }
}

export default {
    getByPostalCode,
    getCurrentZones,
    getSiblingZones,
    getContainingZones,
    getLocationZone,
    getZones
}



import React from 'react';
import { View, StyleSheet, ScrollView, Button } from 'react-native';

import { getCountries, getCountryCallingCode } from 'libphonenumber-js/mobile'

import { LEGALPERSON_TYPES } from '_enums'
import { SimpleText } from '@quarks'
import { Card, ContentBorder } from '@atoms'
import { CardInput, autoCompleteType, keyboardType, textContentType, autoCapitalize } from '@molecules'
import { LocationInput } from '@organisms';
import { AppStyle, TextStyle } from "@style";
import { Icons } from '@icons'
import { replaceLocale } from '@utilities/locale'

import { PhoneCardInput } from '@organisms';


export const GeneralForm = (props) => {

    const styles = updateStyles()

    const nifRef = React.useRef(null)
    const phoneRef = React.useRef(null)
    const addressRef = React.useRef(null)
    const postalCodeRef = React.useRef(null)


    const _onValueChange = (field) => (value) => {
        if (props.onValueChange) props.onValueChange(field)(value)
    }

    return (<View style={{ width: '90%', alignSelf: 'center' }}>
        <View style={styles.viewPack}>
            <ContentBorder
                style={styles.viewSubPack}
                titleViewStyle={styles.contentBorderStyle}
                contentStyle={styles.contentBorderStyle}
                title={"$companyForm.general.Identification"}>
                <SimpleText>{LEGALPERSON_TYPES.map(type => type.locale)[0]}</SimpleText>
                <CardInput
                    title={"$companyForm.general.Name"}
                    icon={Icons.TAG}
                    style={styles.cardInput}
                    onChangeText={_onValueChange('Name')}
                    keyboardType={keyboardType.default}
                    textContentType={textContentType.organizationName}
                    autoCompleteType={undefined}
                    inputText={typeof props.form?.Name === 'undefined' ? '' : props.form.Name}
                    placeholder={'Ex. Nadius S.L.'}
                    errorText={typeof props.errors?.Name === 'undefined' ? '' : props.errors.Name}
                    returnKeyType={'next'}
                    onSubmitEditing={() => nifRef.current.focus()}
                    infoText={'$companyForm.general.NameInfo'}
                />
                <CardInput
                    ref={nifRef}
                    style={styles.cardInput}
                    title={"$companyForm.general.NIF"}
                    icon={Icons.CARDNAME}
                    autoCapitalize={'characters'}
                    onChangeText={_onValueChange('CompanyNumber')}
                    keyboardType={keyboardType.default}
                    autoCompleteType={undefined}
                    inputText={typeof props.form?.CompanyNumber === 'undefined' ? '' : props.form.CompanyNumber}
                    placeholder={'Ex. 1234567A'}
                    errorText={typeof props.errors?.CompanyNumber === 'undefined' ? '' : props.errors.CompanyNumber}
                    returnKeyType={'next'}
                />
                <CardInput
                    title={'$companyForm.general.LegalPersonType'}
                    icon={Icons.World}
                    noInput
                    picker
                    pickerValue={typeof props.form?.LegalPersonType === 'undefined' ? '' : props.form.LegalPersonType}
                    pickerItems={LEGALPERSON_TYPES.map(type => {
                        return ({
                            ...type,
                            label: replaceLocale(type.label)
                        })
                    }
                    )}
                    onValueChange={_onValueChange('LegalPersonType')}
                    style={{ marginBottom: 10, marginTop: 5 }}
                />

            </ContentBorder>
        </View>
        <View style={styles.viewPack}>
            <ContentBorder
                style={styles.viewSubPack}
                titleViewStyle={styles.contentBorderStyle}
                contentStyle={styles.contentBorderStyle}
                title={'$companyForm.general.Contact'}>
                <CardInput
                    title={'$companyForm.general.Email'}
                    icon={Icons.MAIL}
                    style={styles.cardInput}
                    textContentType={textContentType.emailAddress}
                    onChangeText={_onValueChange('Email')}
                    keyboardType={keyboardType.emailAddress}
                    autoCapitalize={autoCapitalize.none}
                    autoCompleteType={autoCompleteType.email}
                    inputText={typeof props.form?.Email === 'undefined' ? '' : props.form.Email}
                    placeholder={'Ex. legal@nadius.cat'}
                    errorText={typeof props.errors?.Email === 'undefined' ? '' : props.errors.Email}
                    returnKeyType={'next'}
                    onSubmitEditing={() => phoneRef.current.focus()}
                    infoText={'$companyForm.general.EmailInfo'}
                />

                <PhoneCardInput
                    ref={phoneRef}
                    style={{ width: '100%' }}
                    value={props.form?.PhoneNumber}
                    onValueChange={_onValueChange('phone')}
                    errorText={props.errors?.PhoneNumber}
                    onSubmitEditing={() => addressRef.current.focus()}
                />

            </ContentBorder>
        </View>
        <View style={styles.viewPack}>
            <ContentBorder
                style={styles.viewSubPack}
                titleViewStyle={styles.contentBorderStyle}
                contentStyle={styles.contentBorderStyle}
                title={'$companyForm.general.HeadquartersAddress'}>
                <CardInput
                    ref={addressRef}
                    style={styles.cardInput}
                    title={"$companyForm.common.Direction"}
                    textContentType={textContentType.fullStreetAddress}
                    icon={Icons.DIRECTIONS}
                    onChangeText={_onValueChange('HeadquartersStreet')}
                    keyboardType={keyboardType.default}
                    autoCompleteType={undefined}
                    inputText={typeof props.form?.HeadquartersAddress.AddressLine1 === 'undefined' ? '' : props.form.HeadquartersAddress.AddressLine1}
                    placeholder={'Ex. Av. del sol, 23, 1A'}
                    errorText={typeof props.errors?.HeadquartersAddress?.AddressLine1 === 'undefined' ? '' : props.errors.HeadquartersAddress?.AddressLine1}
                    returnKeyType={'next'}
                    onSubmitEditing={() => postalCodeRef.current.focus()}
                    infoText={"$companyForm.general.HeadquartersStreetInfo"}
                />

                <LocationInput
                    ref={postalCodeRef}
                    style={{ alignSelf: 'center', width: '100%', marginBottom: 10 }}
                    value={typeof props.form?.HeadquartersLocation === 'undefined' ? '' : props.form.HeadquartersLocation}
                    onError={(locationErrors) => {
                        if (typeof props.onLocationError !== 'undefined') props.onLocationError(locationErrors)
                    }}
                    errors={typeof props.errors?.HeadquartersAddress === 'undefined' ? '' : props.errors.HeadquartersAddress}
                    onValueChange={_onValueChange('HeadquartersAddress')}
                />
            </ContentBorder>
        </View>
    </View>
    )
}

export const GeneralView = (props) => {
    const styles = updateStyles()

    const _identificationCard = () => {
        return (
            <View>
                <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, marginLeft: 10 }}>{"$companyForm.general.Name"}</SimpleText>
                    {typeof props.form?.Name !== 'undefined' && <SimpleText style={{ marginLeft: 15 }}>{props.form?.Name}</SimpleText>}
                </View>

                <View style={{ flexDirection: 'column', justifyContent: 'space-between', paddingTop: 15 }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, marginLeft: 10 }}>{"$companyForm.general.NIF"}</SimpleText>
                    {typeof props.form?.CompanyNumber !== 'undefined' && <SimpleText style={{ marginLeft: 15 }}>{props.form?.CompanyNumber}</SimpleText>}
                </View>

                <View style={{ flexDirection: 'column', justifyContent: 'space-between', paddingTop: 15 }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, marginLeft: 10 }}>{"$companyForm.general.Email"}</SimpleText>
                    {typeof props.form?.Email !== 'undefined' && <SimpleText style={{ marginLeft: 15 }}>{props.form?.Email}</SimpleText>}
                </View>

                <View style={{ flexDirection: 'column', justifyContent: 'space-between', paddingTop: 15 }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, marginLeft: 10 }}>{"$companyForm.general.PhoneNumber"}</SimpleText>
                    {typeof props.form?.PhoneNumber !== 'undefined' && <SimpleText style={{ marginLeft: 15 }}>{props.form?.PhoneNumber.countryCode + ' ' + props.form?.PhoneNumber.number}</SimpleText>}
                </View>

                <View style={{ flexDirection: 'column', justifyContent: 'space-between', paddingTop: 15 }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, marginLeft: 10 }}>{"$companyForm.general.HeadquartersAddress"}</SimpleText>
                    {typeof props.form?.HeadquartersAddress?.AddressLine1 !== 'undefined' && <SimpleText style={{ marginLeft: 15 }}>{props.form?.HeadquartersAddress.AddressLine1}</SimpleText>}
                    {typeof props.form?.HeadquartersAddress?.City !== 'undefined' &&
                        typeof props.form?.HeadquartersAddress?.Region !== 'undefined' &&
                        <SimpleText style={{ marginLeft: 15 }}>{props.form?.HeadquartersAddress.City + ', ' + props.form?.HeadquartersAddress.Region}</SimpleText>}
                    {typeof props.form?.HeadquartersAddress?.PostalCode !== 'undefined' &&
                        typeof props.form?.HeadquartersAddress?.Country !== 'undefined' &&
                        <SimpleText style={{ marginLeft: 15 }}>{props.form?.HeadquartersAddress.PostalCode + ', ' + props.form?.HeadquartersAddress.Country}</SimpleText>}
                </View>

            </View>
        )
    }

    return (
        <View>
            <SimpleText style={styles.directionText}>
                $companyForm.general.Identification
            </SimpleText>

            <SimpleText>{LEGALPERSON_TYPES.map(type => type.locale)[0]}</SimpleText>

            {<Card style={{ flex: 1, width: '90%', marginBottom: 20, marginTop: 10 }}>
                <View style={styles.listCard}>
                    {_identificationCard()}
                </View>
            </Card>}

        </View>
    )

}

const updateStyles = () => StyleSheet.create({
    directionText: {
        width: '80%',
        maxWidth: 600,
        alignSelf: 'center',
        ...TextStyle.size.xlarge,
        paddingTop: 20
    },
    separator: {
        width: '80%',
        alignSelf: 'center',
        borderBottomWidth: 3,
        paddingTop: 10,
        marginBottom: 10
    },
    date: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 350,
        height: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 10,
        marginTop: 0
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    card: {
        backgroundColor: AppStyle.cardBackground,
        shadowRadius: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
        maxWidth: 350,
        maxHeight: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 0,
        borderRadius: 30,
    },
    listCard: {
        marginTop: 10,
        flexDirection: 'column',
        marginBottom: 20,
        padding: 5,
        width: '100%'
    },
    contentBorderStyle: {
        maxWidth: 600, justifyContent: 'flex-start',
        alignSelf: 'center', width: '90%'
    },
    viewPack: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: 800,
        width: '100%',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 10,
    },
    viewSubPack: {
        minWidth: 350,
        flex: 1,
        width: '100%',
    },
    cardInput: {
        marginBottom: 20,
    }
});
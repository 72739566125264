import { gql } from '@apollo/client';
import { Realm } from '@platform'
import { user } from 'nexto-codes'
import NetInfo from "@react-native-community/netinfo";

import { object_equals } from 'nexto-utils/objects'

const MAX_RETRIES = 5

const delay = async (time) => {
    return new Promise((resolve) =>
        setTimeout(
            () => { resolve('result') },
            time
        )
    );
}

const refreshCustomData = async (loginAnonymous) => {
    let app = global.app

    let user = app.currentUser;
    let retries = 0, internetIssue = true

    if (user == null || user.customData == null) {
        return
    }

    do {
        console.log('Refreshing custom data')
        try {
            await user.refreshCustomData()
            console.log('Done')
            return user
        } catch (e) {
            console.warn(e)
        }

        console.log('Retrying refresh custom data')

        retries += 1
        await delay(50 * (retries));

        let netInfo = await NetInfo.fetch()
        // With this we check that internet was accessible during retries
        if (internetIssue && netInfo.isConnected && netInfo.isInternetReachable) {
            internetIssue = false
        }

    } while (retries < MAX_RETRIES)

    if (retries === MAX_RETRIES) {
        let netInfo = await NetInfo.fetch()
        if (!internetIssue && netInfo.isConnected && netInfo.isInternetReachable) {
            await app.currentUser.logOut()
            if (loginAnonymous) {
                user = await app.logIn(Realm.Credentials.anonymous())
            } else {
                user = undefined
            }
        }
    }
    return user
}

const login = async (email, password) => {
    let credentials = Realm.Credentials.anonymous();
    if (typeof email !== 'undefined' && typeof password !== 'undefined') {
        credentials = Realm.Credentials.emailPassword(email.toLowerCase(), password)
    }
    try {
        let user = global.app.currentUser
        if (user != null) {
            await user.logOut()
        }
        return await global.app.logIn(credentials)
    } catch (error) {
        if (typeof error.code == 'undefined') error.code = error.statusCode
        console.warn('error login', JSON.stringify(error))
        switch (error.code) {
            case -1:
            case 401:
                if (error.errorCode == 'AuthError' && error.error == "confirmation required") {
                    return user.RESPONSE_MESSAGES[user.RESPONSE_CODES.VALIDATION_NOT_DONE]
                }
                return user.RESPONSE_MESSAGES[user.RESPONSE_CODES.USER_PASSWORD_INVALID]
            case 47:
                return user.RESPONSE_MESSAGES[user.RESPONSE_CODES.VALIDATION_NOT_DONE]
            default:
                break;
        }
        console.warn('error login', error.code, error.message)
        return user.RESPONSE_MESSAGES[user.RESPONSE_CODES.UNKNOWN_ERROR]
    }
}

const signUp = async (form) => {
    if (form.password != form.confirmationPassword) {
        return 'Les contrasenyes no coincideixen'
    }
    try {
        await app.emailPasswordAuth.registerUser(form.email.toLowerCase(), form.password);
    } catch (error) {
        if (typeof error.code == 'undefined') error.code = error.statusCode
        switch (error.code) {
            case 49:
            case 409:
                return user.RESPONSE_MESSAGES[user.RESPONSE_CODES.EMAIL_ALREADY_REGISTERED]
            case 48:
                return user.RESPONSE_MESSAGES[user.RESPONSE_CODES.EMAIL_UNABLE_TO_VERIFY]
            default:
                break;
        }
        return 'Hi ha hagut un error intern, torna a intentar-ho més tard...'
    }
}

const logout = async (anonymous) => {
    try {
        let user = app.currentUser
        if (user != null) {
            await user.logOut()
        }
        if (anonymous) {
            const credentials = Realm.Credentials.anonymous()
            await app.logIn(credentials)
        }
    } catch (error) {
        console.warn(error)
        return error
    }
    return undefined
}

const getUserData = (userId) => {
    return {
        query: gql`
            query ($id: ObjectId!) {
                user(query: {userId: $id}) {
                    _id
                    email
                    name
                }
            }`,
        param: { variables: { id: userId } }
    }
}

const updateNameAndSurname = () => {
    return gql`
    mutation ($userId: String!, $name: String, $surname: String) {
        updateOneUser (query: {userId: $userId}, set: {name : $name, surname: $surname}) {
            _id
        }
    }`
}

const updatePhone = () => {
    return gql`
    mutation ($userId: String!, $phone: UserPhoneUpdateInput) {
        updateOneUser (query: {userId: $userId}, set: {phone : $phone}) {
            _id
        }
    }`
}

const updateLocale = () => {
    return gql`
    mutation ($userId: String!, $locale: String) {
        updateOneUser (query: {userId: $userId}, set: {locale : $locale}) {
            _id
        }
    }`
}

const updateDirections = () => {
    return gql`
    mutation ($userId: String!, $directions: [UserDirectionUpdateInput]) {
        updateOneUser (query: {userId: $userId}, set: {directions : $directions}) {
            _id
        }
    }`
}

const updateValues = (form, user) => {
    return {
        variables: {
            userId: user.userId,
            ...form
        }
    }
}

const cleanUpDirection = (direction) => {
    let newDirection = {}
    Object.keys(direction).forEach(field => {
        if (field === 'phoneNumber') {
            let tmpValue = {}
            tmpValue.number = direction[field].number.trim().replace(/\s{2,}/g, ' ')
            tmpValue.countryCode = direction[field].countryCode.trim().replace(/\s{2,}/g, ' ')
            newDirection.phone = tmpValue
        } else if (field !== 'picker') {
            if (typeof direction[field] === 'string') {
                newDirection[field] = direction[field].trim().replace(/\s{2,}/g, ' ')
            } else {
                newDirection[field] = direction[field]
            }
        }
    });
    return newDirection
}

const addDirection = (direction, user) => {
    let directions = user.directions

    // Create the new direction object
    let newDirection = cleanUpDirection(direction)

    if (typeof directions === 'undefined' || directions === null) {
        directions = []
    } else {
        if (directions.length > 0) {
            let res = directions.find(element => {
                return object_equals(element, newDirection)
            })
            if (typeof res !== 'undefined') {
                return 'Direcció existent'
            }
        }
    }

    directions.push(newDirection)

    return {
        variables: {
            userId: user.userId,
            directions: directions
        }
    }

}

const updateDirection = (direction, index, user) => {

    let directions = user.directions
    let position = index

    // Create the new direction object
    let newDirection = cleanUpDirection(direction)

    if (index >= directions.length || index < 0) {
        return 'Direcció inexistent'
    }

    let savedDirection = Object.assign({}, directions[index])
    if (typeof savedDirection.locationId === 'object') {
        savedDirection.locationId = '' + savedDirection.locationId
    }
    if (object_equals(newDirection, savedDirection)) {
        return
    }

    directions[position] = newDirection

    return { variables: { userId: user.userId, directions: directions } }

}

const deleteDirection = (direction, index, user) => {

    let directions = user.directions

    if (!object_equals(direction, directions[index])) {
        return 'Direcció inexistent'
    }

    directions.splice(index, 1)

    return { variables: { userId: user.userId, directions: directions } }
}

const getCards = () => {
    return {
        query: gql`
            query {
                UserCards {
                    Id
                    Alias
                    CardProvider
                    Currency
                    ExpirationDate
                }
            }`
    }
}

export default {
    addDirection,
    updateDirection,
    updateDirections,
    updateLocale,
    getUserData,
    deleteDirection,
    updateNameAndSurname,
    updatePhone,
    updateValues,
    getCards,
    refreshCustomData,
    login,
    logout,
    signUp,
}
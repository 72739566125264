import React, { useState } from 'react';
import { View, Button, Platform, Dimensions } from 'react-native';
import moment from "moment/min/moment-with-locales"
import { DateTimePicker } from '@platform'

import { CardInput } from '@molecules';
import { Icons } from '@icons';
import { AppStyle, TextStyle } from '@style';
import { BottomModal } from './bottomModal';

export function DateCardInput(props) {

    const [show, setShow] = useState(false);
    const [date, setDate] = useState(typeof props.date === 'undefined' ? moment().year(1975) : moment(props.date));

    let isiOS14 = Platform.OS == 'ios' && parseInt(Platform.Version, 10) > 13
    let isNotiOS14 = Platform.OS == 'ios' && parseInt(Platform.Version, 10) < 14

    const _onChange = (event, selectedDate) => {
        const currentDate = selectedDate || Platform.OS == 'web' ? event : date;
        if (!isNotiOS14) {
            setShow(false);
        }

        setDate(currentDate)
        if (props.onValueChange) props.onValueChange(new Date(currentDate))
    };

    const showMode = (show = true) => {
        setShow(show);
    };

    return (<View style={{
        alignSelf: 'center',
        width: '100%',
    }}>
        {<CardInput
            key={date}
            title={props.title}
            icon={Icons.CALENDAR}
            editable={false}
            onPress={showMode}
            inputText={moment(date).format('DD/MM/YYYY')}
            returnKeyType={'next'}
            style={props.cardStyle}
            readOnly={true}
            noInput={isiOS14 || Platform.OS == 'web' }
            renderCustomInput={isiOS14 ? () => <DateTimePicker
                key={date}
                style={{ flex: 1, marginLeft: 10 }}
                testID="dateTimePicker"
                value={date}
                textColor={AppStyle.mainColor}
                mode={'date'}
                display="compact"
                onChange={_onChange}
            /> : Platform.OS == 'web' && (() => (<DateTimePicker
                key={date}
                date={date}
                textStyle={{...TextStyle.size.regular}}
                getPromptString={(label) => `${'$common.components.dateCardInput.select'.localise()} ${label}`}
                yearName={'$common.components.dateCardInput.year'.localise()}
                monthName={'$common.components.dateCardInput.month'.localise()}
                dayName={'$common.components.dateCardInput.day'.localise()}
                onDatePicked={_onChange}
            />))}
            errorText={props.errorText}
            onPressReadOnly={showMode}
        />}
        {isNotiOS14 && <BottomModal
            onRequestClose={() => {
                setShow(false)
            }}
            visible={show}>
            <DateTimePicker
                style={{ flex: 1, marginLeft: 10 }}
                testID="dateTimePicker"
                value={typeof date == 'undefined' ? new Date() : date}
                textColor={AppStyle.textColor}
                mode={'date'}
                display="compact"
                onChange={_onChange}
            />
        </BottomModal>}

        {Platform.OS == 'android' && show && <DateTimePicker
            testID="dateTimePicker"
            value={date}
            mode={'date'}
            display="spinner"
            onChange={_onChange}
        />}
    </View>);
}

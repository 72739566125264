import React, { useState } from 'react';
import { View } from 'react-native';

import { ButtonText } from '@quarks';
import {
    LoadingView,
    ErrorView,
    SuccessView
} from '@atoms';

export const FeedbackView = (props) => {

    let [feedbackView, setFeedbackView] = useState(undefined)
    let [timeout, setTim] = useState(undefined)

    let newFeedbackView = feedbackView;

    const loading = props.loading
    const error = props.error
    const success = props.success

    const createErrorView = (errorText, extraStyle) => {
        let view = props.errorView
        if (!view) {
            view = <ErrorView
                style={[props.style, extraStyle]}
                errorText={errorText ? errorText : props.errorText}
            />
        }
        if (props.onPress) {
            view = <View >
                {view}
                <ButtonText
                    text={'$common.components.buttons.close'}
                    onPress={props.onPress}
                    type={'secondary'}
                    style={{
                        padding: 5, marginTop: 10, paddingLeft: 10, paddingRight: 10
                    }} />
            </View>
        }
        return view
    }

    const createLoadingView = (loadingText) => {
        let view = props.loadingView
        if (!view) {
            view = <LoadingView
                text={loadingText ? loadingText : props.loadingText}
                style={[{ height: props.height ? props.height : 100, padding: 10 }, props.style]}
            />
        }
        return view
    }

    const errorTimeout = () => {
        if (loading) {
            setFeedbackView({
                timeout: createErrorView('$common.components.feedbackView.errorTimeout', { height: 200, marginBottom: 10 })
            })
            if (props.onTimeout) props.onTimeout(props.visible)
        }
    }

    const timeoutLoading = () => {
        if (loading) {
            setFeedbackView({
                timeout: createLoadingView('$common.components.feedbackView.loadingTimeout')
            })
            setTim(setTimeout((visible) => {
                if (visible) {
                    errorTimeout()
                }
            }, 12000, props.visible))
        }
    }

    if (newFeedbackView?.timeout) {
        return newFeedbackView.timeout
    }

    if (error) {
        newFeedbackView = createErrorView()
    }

    if (loading) {
        newFeedbackView = createLoadingView()
        if (typeof timeout === 'undefined') {
            setTim(setTimeout(() => {
                timeoutLoading()
            }, typeof props.timeoutIn === 'number' ?
                props.timeoutIn * 1000 : 10000))
        }
    } else if (typeof timeout !== 'undefined') {
        clearTimeout(timeout)
        setTim(undefined)
    }

    if (success) {
        newFeedbackView = props.successView
        if (!newFeedbackView) {
            newFeedbackView = <SuccessView
                style={props.style}
                style={[{ height: props.height ? props.height : 100, maxWidth: 150, padding: 10 }, props.style]}
                text={props.successText}
            />
        }
        if (props.onSuccess) setTimeout(props.onSuccess, 1000)
    }

    return newFeedbackView ? newFeedbackView : null
};


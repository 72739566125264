import React, { useState } from 'react';
import {
    Animated,
    TouchableOpacity,
    StyleSheet,
    View
} from 'react-native';

import { ImageCard } from '@atoms'
import { SimpleText } from '../quarks/simpleText';
import { AppStyle } from '@style';

export function ViewCard(props) {

    const [scaleAnim, setScaleAnim] = useState(new Animated.Value(1));

    const styles = updateStyles()
    const _onPresIn = () => {
        Animated.timing(scaleAnim, {
            toValue: 0.96,
            duration: 200,
            useNativeDriver: false,
        }).start();
    };

    const _onPressOut = () => {
        Animated.timing(scaleAnim, {
            toValue: 1,
            duration: 200,
            useNativeDriver: false,
        }).start();
    };

    return (
        <View style={props.viewStyle}>
            <TouchableOpacity
                activeOpacity={0.9}
                style={[styles.itemContainer, props.style]}
                onPressIn={_onPresIn}
                onPressOut={_onPressOut}
                onPress={props.onPress}>
                <Animated.View style={[{
                    transform: [{ scale: scaleAnim }],
                    height: "100%",
                }]}>
                    <ImageCard
                        style={props.imageStyle}
                        image={props.image}
                        imgType={props.imgType}
                        text={props.title}
                        subtitle={props.subtitle}
                        fontSize={props.titleFontSize} />
                    {props.innerFooter}
                </Animated.View>
            </TouchableOpacity>
            {props.footer ? props.footer() : undefined}
        </View>
    );
}


const updateStyles = () => StyleSheet.create({
    itemContainer: {
        borderRadius: 10,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 5,
        backgroundColor: 'transparent',
        alignSelf: 'center',
        width: '100%',
        height: '100%',
    },
    itemViewName: {
        padding: 10,
        marginTop: -10,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    }
});

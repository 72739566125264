import { parsePhoneNumber, ParseError } from 'libphonenumber-js/mobile'
import { removeDiacritics } from '@utilities/stringUtils';

function nameValid(name) {
    name = removeDiacritics(name)
    if (!name || name.length === 0 || /^\s*$/.test(name)) {
        return 'El nom no pot estar buit'
    }
    if (!/^[a-zA-Z ]+$/.test(name)) {
        return 'El nom només pot contenir lletres'
    }
}

function companyNameValid(name) {
    name = removeDiacritics(name)
    if (!name || name.length === 0 || /^\s*$/.test(name)) {
        return 'El nom no pot estar buit'
    }
}

function streetValid(street) {
    let name = removeDiacritics(street)
    if (!name || name.length === 0 || /^\s*$/.test(name)) {
        return 'La direcció no pot estar buida'
    }
    if (!/^[a-zA-Z \-.ªº,0-9/\n]+$/.test(name)) {
        return 'La direcció conté caràcters invàlids'
    }
}

function regionValid(region) {
    let name = removeDiacritics(region)
    if (!name || name.length === 0 || /^\s*$/.test(name)) {
        return 'El camp regió no pot estar buit'
    }
    if (!/^[a-zA-Z ]+$/.test(name)) {
        return 'El nom de la regió conté caràcters invàlids'
    }
}

function cityValid(city) {
    let name = removeDiacritics(city)
    if (!name || name.length === 0 || /^\s*$/.test(name)) {
        return 'El camp ciutat no pot estar buit'
    }
    if (!/^[a-zA-Z ()/\-',]+$/.test(name)) {
        return 'El nom de la ciutat conté caràcters invàlids'
    }
}

function postalCodeValid(postalCode) {
    if (!postalCode || postalCode.length === 0 || /^\s*$/.test(postalCode)) {
        return 'El codi postal no pot estar buit'
    }
    if (!/^\d{5}$/.test(postalCode)) {
        return 'El codi postal ha de ser 5 números'
    }
    if (!/^[0-9]+$/.test(postalCode)) {
        return 'El codi postal conté caràcters invàlids'
    }
}

function surnameValid(surname) {
    surname = removeDiacritics(surname)
    if (!surname || surname.length === 0 || /^\s*$/.test(surname)) {
        return 'El cognom no pot estar buit'
    }
    if (!/^[a-zA-Z ]+$/.test(surname)) {
        return 'El cognom només pot contenir lletres'
    }
}

function cardNumber(card) {
    if (!card || card.length === 0 || /^\s*$/.test(card)) {
        return 'El número no pot estar buit'
    }

    if (card.length !== 16) {
        return
    }

    if (/[^0-9-\s]+/.test(card)) {
        return 'El número de la targeta no és vàlid'
    }

    var nCheck = 0, nDigit = 0, bEven = false;
    card = card.replace(/\D/g, "");

    for (var n = card.length - 1; n >= 0; n--) {
        var cDigit = card.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    if (!((nCheck % 10) == 0)) {
        return 'El número de la targeta no és vàlid'
    }
};

function cardExp(form) {

    if (!form || !form.month || !form.year
        || !form.month.length === 0 || form.year.length === 0 ||
        /^\s*$/.test(form.month) || /^\s*$/.test(form.year)) {
        return 'La data no pot estar buida'
    }

    if (form.month > 12 || form.month < 1) {
        return 'El mes no és vàlid'
    }

    let today = new Date()
    let thisYear = (today.getFullYear() - 2000)
    let yearsDifference = parseInt(form.year) - thisYear

    if (yearsDifference < 0 || yearsDifference === 0 && parseInt(form.month) < (today.getMonth())) {
        return 'La data ha de ser futura'
    }

}

function phoneValid(form) {
    if (typeof form.countryCode === 'undefined'
        || typeof form.number === 'undefined') {
        return 'Telefon esta buit'
    }
    try {
        const phoneNumber = parsePhoneNumber(form.countryCode + form.number)
        if (!phoneNumber.isPossible()) {
            return 'Telefon no es vàlid'
        }
    } catch (error) {
        if (error instanceof ParseError) {
            switch (error.message) {
                case "INVALID_COUNTRY":
                    return 'El pais no es valid'
                case "NOT_A_NUMBER":
                    return 'No és un número'
                case "TOO_LONG":
                    return 'Massa llarg'
                case "TOO_SHORT":
                    return 'Massa curt'
                default:
                    break;
            }
            return 'Telefon no es vàlid'
        } else {
            throw error
        }
    }
}

function emailValid(email) {
    if (!email || email.length === 0) {
        return 'El correu no pot estar buit'
    }
    
    if (!/\S+@\S+/.test(email)) {
        return 'El correu te un format invàlid'
    }
}

function passwordValid(password, login) {
    if (!password || password.length === 0) {
        return 'La contrasenya no pot estar buida'
    }
    if (!login && !/^(?=.*[A-Za-zÀ-ÿàá])(?=.*\d)[A-Za-z\d$@$!%*#?&\-_]{8,}$/.test(password)) {
        return 'La contrasenya ha de contenir, minim 8 caracters, que inclogui 1 minuscula, majuscula i número.'
    }
}

function formIsValid(form) {
    let errors = {}
    Object.keys(form).forEach(val => {
        let error = fieldValidator(val, form[val])
        if (typeof error !== 'undefined' || form[val] && typeof error === 'undefined') {
            errors[val] = error
        }
    })
    return errors
}

const fieldValidator = (fieldName, value) => {
    let validator = undefined
    switch (fieldName) {
        case 'confirmationPassword':
        case 'newPassword':
            validator = passwordValid(value, false)
            break;
        case 'password':
            validator = passwordValid(value, true)
            break;
        case 'phone':
        case 'phoneNumber':
            validator = phoneValid({
                number: value ? value.number : undefined,
                countryCode: value ? value.countryCode : undefined
            })
            break;
        case 'surname':
            validator = surnameValid(value)
            break;
        case 'username':
        case 'name':
            validator = nameValid(value)
            break;
        case 'companyName':
            validator = companyNameValid(value)
            break;
        case 'email':
            validator = emailValid(value)
            break;
        case 'streetAddress':
        case 'street':
            validator = streetValid(value)
            break;
        case 'city':
            validator = cityValid(value)
            break;
        case 'postalCode':
            validator = postalCodeValid(value)
            break;
        case 'region':
            validator = regionValid(value)
            break;
        case 'cardNumber':
            validator = cardNumber(value)
            break;
        case 'cardExp':
            validator = cardExp({ month: value.month, year: value.year })
            break;
        default:
            break;
    }
    return validator
}

export {
    formIsValid,
    fieldValidator
}
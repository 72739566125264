import { ScrollView, View } from 'react-native';
import React from 'react';
import Checkbox from 'expo-checkbox';
import { SimpleDatePicker as DateTimePicker } from '@atoms';
import Carousel from 'react-native-web-swiper';
import { sessionOperations } from '_ducks/session';
import { Picker } from '@react-native-picker/picker'

const Realm = require('realm-web');
const codePush = {};
const Maps = {};
const AppCenter = {};
const KeyboardAwareScrollView = ScrollView;
const ImageView = <View />;
const CheckBox = Checkbox;
const SplashScreen = {}

const Alert = (dispatch) => {
    return {
        alert: (title, description, buttons, cancelable) => {
            dispatch(sessionOperations.toggleAlertModal(true, {
                title, description, buttons, cancelable
            }))
        }
    }
}

export {
    codePush,
    Maps,
    AppCenter,
    KeyboardAwareScrollView,
    ImageView,
    CheckBox,
    Realm,
    SplashScreen,
    DateTimePicker,
    Carousel,
    Alert,
    Picker
}
import React from 'react';
import { View, StyleSheet } from 'react-native';

import { COUNTRY_CODES } from '_enums'
import { SimpleText } from '@quarks'
import { ContentBorder, SimpleCheckBox } from '@atoms'
import { CardInput, autoCompleteType, keyboardType, textContentType, autoCapitalize } from '@molecules'
import { LocationInput, DateCardInput } from '@organisms';
import { AppStyle, TextStyle } from "@style";
import { Icons } from '@icons'
import { Icon } from '@quarks'


export const LegalRepresentativeForm = (props) => {
    const styles = updateStyles()

    const _onValueChange = (field) => (value) => {
        if (props.onValueChange) props.onValueChange(field)(value)
    }


    const surnameRef = React.useRef(null)
    const postalCodeRef = React.useRef(null)

    const locationChecked = typeof props.locationChecked !== 'undefined' ? props.locationChecked : false
    
    return (<View style={{ width: '90%', alignSelf: 'center' }}>
    <View style={styles.viewPack}>
        <ContentBorder
            style={styles.viewSubPack}
            titleViewStyle={styles.contentBorderStyle}
            contentStyle={styles.contentBorderStyle}
                title={'$companyForm.legalRepresentative.header'}>
                <View style={styles.cardConatiner}>
                    <CardInput
                        icon={Icons.USER}
                        title={'$companyForm.legalRepresentative.name'}
                        style={[styles.halfCard, { marginRight: 10 }]}
                        locale={'ca'}
                        onChangeText={_onValueChange('LegalRepresentativeFirstName')}
                        keyboardType={keyboardType.default}
                        textContentType={textContentType.givenName}  
                        autoCapitalize={autoCapitalize.words}
                        autoCompleteType={undefined}
                        inputText={typeof props.form.LegalRepresentativeFirstName !== 'undefined' ? props.form.LegalRepresentativeFirstName : ''}
                        placeholder={'Ex. Richard'}
                        errorText={typeof props.errors?.LegalRepresentativeFirstName === 'undefined' ? '' : props.errors.LegalRepresentativeFirstName}
                        returnKeyType={'next'}
                        onSubmitEditing={() => surnameRef.current.focus()}
                    />
                    <CardInput
                        ref={surnameRef}
                        title={'$companyForm.legalRepresentative.surnames'}
                        style={styles.halfCard}
                        locale={'ca'}
                        onChangeText={_onValueChange('LegalRepresentativeLastName')}
                        keyboardType={keyboardType.default}    
                        autoCapitalize={autoCapitalize.words}
                        textContentType={textContentType.middleName}
                        autoCompleteType={undefined}
                        inputText={typeof props.form.LegalRepresentativeLastName !== 'undefined' ? props.form.LegalRepresentativeLastName : ''}
                        placeholder={'Ex. Philips Feynman'}
                        errorText={typeof props.errors.LegalRepresentativeLastName === 'undefined' ? '' : props.errors.LegalRepresentativeLastName}
                        returnKeyType={'next'}
                    />
                </View>

                <DateCardInput
                    onValueChange={_onValueChange('LegalRepresentativeBirthday')}
                    title={'$companyForm.legalRepresentative.Birthday'}
                    cardStyle={{ width: '100%', marginBottom: 10 }}
                    errorText={props.errors.LegalRepresentativeBirthday}
                    date={typeof props.form.LegalRepresentativeBirthday !== 'undefined' ? new Date(props.form.LegalRepresentativeBirthday) : undefined} />

                <CardInput
                    title={'$companyForm.legalRepresentative.Email'}
                    style={{ marginBottom: 10,  width: '100%' }}
                    icon={Icons.MAIL}
                    onChangeText={_onValueChange('LegalRepresentativeEmail')}
                    keyboardType={keyboardType.emailAddress}                
                    autoCapitalize={autoCapitalize.none}
                    textContentType={textContentType.emailAddress}
                    autoCompleteType={autoCompleteType.email}
                    inputText={typeof props.form.LegalRepresentativeEmail !== 'undefined' ? props.form.LegalRepresentativeEmail : ''}
                    placeholder={'Ex. hola@nadius.cat'}
                    errorText={typeof props.errors?.LegalRepresentativeEmail === 'undefined' ? '' : props.errors.LegalRepresentativeEmail}
                    returnKeyType={'next'}
                    infoText={'$companyForm.legalRepresentative.mailInfo'}
                />

                <CardInput
                    title={'$companyForm.legalRepresentative.Nationality'}
                    icon={Icons.World}
                    noInput
                    picker
                    pickerValue={typeof props.form.LegalRepresentativeNationality !== 'undefined' ? props.form.LegalRepresentativeNationality : 'ES'}
                    pickerItems={COUNTRY_CODES}
                    onValueChange={_onValueChange('LegalRepresentativeNationality')}
                    style={{ marginBottom: 10, width: '100%' }}
                />

                <CardInput
                    title={'$companyForm.legalRepresentative.CountryOfResidence'}
                    icon={Icons.World}
                    noInput
                    picker
                    pickerValue={typeof props.form.LegalRepresentativeCountryOfResidence !== 'undefined' ? props.form.LegalRepresentativeCountryOfResidence : 'ES'}
                    pickerItems={COUNTRY_CODES}
                    onValueChange={_onValueChange('LegalRepresentativeCountryOfResidence')}
                    style={{ marginBottom: 10, width: '100%' }}
                />

            </ContentBorder>
        </View>
        <View style={styles.viewPack}>
            <ContentBorder
                style={styles.viewSubPack}
                titleViewStyle={styles.contentBorderStyle}
                contentStyle={styles.contentBorderStyle}
                title={'$companyForm.legalRepresentative.LegalRepresentativeAddress'}>
                <View style={{
                    width: '100%',
                    maxWidth: 400,
                    alignSelf: 'center'
                }}>
                    <SimpleCheckBox
                        value={locationChecked}
                        onValueChange={_onValueChange('legalRepresentativeChecked')}
                        onTextPressed={(rubish) => {
                            if (typeof props.onLocationChecked !== 'undefined') props.onLocationChecked()
                        }}
                        text={"$companyForm.legalRepresentative.legalRepresentativeChecked"} />
                </View>
                {!locationChecked &&
                    <View>
                        <CardInput
                            style={{ width: '100%', marginBottom: 10 }}
                            title={'$companyForm.common.Direction'}
                            icon={Icons.DIRECTIONS}
                            textContentType={textContentType.fullStreetAddress}
                            onChangeText={_onValueChange('LegalRepresentativeStreet')}
                            keyboardType={keyboardType.default}
                            autoCompleteType={undefined}
                            inputText={typeof props.form?.LegalRepresentativeAddress?.AddressLine1 !== 'undefined' ? props.form?.LegalRepresentativeAddress.AddressLine1 : ''}
                            placeholder={'Ex. Carrer del compte, 23, 1A'}
                            errorText={typeof props.errors?.LegalRepresentativeAddress?.AddressLine1 === 'undefined' ? '' : props.errors.LegalRepresentativeAddress.AddressLine1}
                            returnKeyType={'next'}
                            onSubmitEditing={() => postalCodeRef.current.focus()}
                            infoText={'$companyForm.legalRepresentative.addressInfo'}

                        />

                        <LocationInput
                            ref={postalCodeRef}
                            style={{ alignSelf: 'center', width: '100%' }}
                            value={props.form.LegalRepresentativeLocation}
                            onError={(locationErrors) => {
                                if (typeof props.onLocationError !== 'undefined') props.onLocationError(locationErrors)
                            }}
                            errors={typeof props.errors?.LegalRepresentativeAddress === 'undefined' ? '' : props.errors.LegalRepresentativeAddress}
                            onValueChange={_onValueChange('LegalRepresentativeAddress')}
                        />
                    </View>}
            </ContentBorder>
        </View>
        </View>)

}

const updateStyles = () => StyleSheet.create({
    welcomeTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 100
    },
    inputTitle: {
        ...TextStyle.size.xxxlarge,
        ...TextStyle.weight.bold,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        paddingBottom: 10,
        paddingTop: 50,
    },
    emailText: {
        ...TextStyle.size.xlarge,
        ...TextStyle.weight.bold,
        paddingTop: 10,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    verificationText: {
        paddingTop: 30,
        width: '80%',
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.size.large
    },
    directionText: {
        width: '80%',
        alignSelf: 'center',
        ...TextStyle.size.large
    },
    titleText: {
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.padding.medium,
        paddingLeft: 0
    },
    separator: {
        width: '80%',
        alignSelf: 'center',
        borderBottomWidth: 3,
        paddingTop: 10,
        marginBottom: 10
    },
    date: {
        alignSelf: 'center',
        width: '100%',
        height: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 10,
        marginTop: 0
    },
    halfCard: {
        flex: 2,
    },
    cardConatiner: {
        flexDirection: 'row',
        alignSelf: 'center', marginBottom: 10,
        width: '100%'
    },
    iconStyle: {
        marginRight: 10,
        alignSelf: 'center',
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '90%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    card: {
        backgroundColor: AppStyle.cardBackground,
        shadowRadius: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
        maxHeight: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 0,
        borderRadius: 30,
    },
    cardText: {
        alignSelf: 'center',
        textAlign: 'center',
        ...TextStyle.padding.medium,
        paddingLeft: 0,

    },
    infoText: {
        color: AppStyle.getColor('secondary', 1),
        ...TextStyle.size.medium,
        width: '100%',
        maxWidth: 320,
        alignSelf: 'center',
        textAlign: 'center',
    },
    contentBorderStyle: {
        maxWidth: 600, justifyContent: 'flex-start',
        alignSelf: 'center', width: '90%'
    },
    viewPack: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: 800,
        width: '100%',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 10,
    },
    viewSubPack: {
        minWidth: 350,
        flex: 1,
        width: '100%',
    },
    cardInput: {
        marginBottom: 20,
    }
});
import React from 'react';
import { View, StyleSheet, Platform, StatusBar, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

import { AppStyle } from '@style';
import { ButtonText } from '@quarks';
import { Icons } from '@icons';

export const BlurModal = (props) => {

    const styles = updateStyles()

    const _onClose = () => {
        if (props.onClose) {
            props.onClose()
        }
    }

    if (props.visible) {
        StatusBar.setBarStyle('light-content')
    } else {
        StatusBar.setBarStyle('dark-content')
    }

    if (Platform.OS == 'web' && !(props.visible)) {
        return null
    }

    return (
        <Modal
            avoidKeyboard={true}
            useNativeDriver={true}
            style={{
                justifyContent: 'center',
                margin: 0,
                height: '100%',
                width: '100%',
                alignSelf: 'center',
                maxWidth: 700,
                ...props.style
            }}
            hideModalContentWhileAnimating={true}
            onBackdropPress={_onClose}
            onRequestClose={_onClose}
            backdropOpacity={0.7}
            isVisible={props.visible}
            deviceHeight={Dimensions.get('screen').height}
            {...props}>
            <StatusBar backgroundColor="rgba(0,0,0,0.7)" />
            <View style={[styles.modalView, props.contentStyle]}>
                {props.closeButton && <View style={{
                    top: 20, position: 'absolute', alignSelf: 'flex-end', justifyContent: 'flex-end',
                    zIndex: 10, right: 20
                }}>
                    <ButtonText
                        icon={Icons.CLOSE}
                        onPress={_onClose}
                        iconColor={AppStyle.textColor}
                        style={{
                            elevation: 4,
                            shadowOffset: {
                                width: 0,
                                height: 0
                            },
                            shadowOpacity: 0.2,
                            shadowRadius: 10,
                            paddingLeft: Platform.OS === 'android' ? 2 : 0,
                            backgroundColor: AppStyle.backgroundColor,
                            borderRadius: Platform.OS === 'android' ? 20 : 18,
                            width: 35,
                            height: 35
                        }} />
                </View>}
                {props.children}
            </View>
        </Modal>
    )
};

const updateStyles = () => StyleSheet.create({
    modalView: {
        shadowColor: "#000",
        alignSelf: 'center',
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        justifyContent: 'center',
        width: '95%',
        maxHeight: '95%',
        minWidth: 150,
        paddingLeft: 15, paddingRight: 15,
        alignSelf: 'center',
        justifyContent: 'center',
        borderRadius: 20, paddingTop: 35,
        paddingBottom: 25,
        backgroundColor: AppStyle.backgroundColor,
    },
    blurStyle: {
        alignSelf: 'center',
        zIndex: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    }
});


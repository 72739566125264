import React from 'react';
import { View, StyleSheet } from 'react-native';

import { SimpleText } from '@quarks'
import { ContentBorder, SimpleCheckBox } from '@atoms'
import { Card } from '@atoms'
import { CardInput, keyboardType, textContentType, autoCapitalize } from '@molecules'
import { LocationInput } from '@organisms';
import { AppStyle, TextStyle } from "@style";
import { Icons } from '@icons'

export const FinantialForm = (props) => {
    const styles = updateStyles()

    const locationHeadquartersChecked = typeof props.locationHeadquartersChecked !== 'undefined' ?
        props.locationHeadquartersChecked : false
    const locationLegalRepresentativeChecked = typeof props.locationLegalRepresentativeChecked !== 'undefined' ?
        props.locationLegalRepresentativeChecked : false

    const _onValueChange = (field) => (value) => {
        if (props.onValueChange) props.onValueChange(field)(value)
    }

    const ibanRef = React.useRef(null)
    const addressRef = React.useRef(null)
    const postalCodeRef = React.useRef(null)
    
    let IBAN = props.form?.payment?.IBAN
    if (typeof IBAN !== 'undefined' && IBAN.length > 0) {
        IBAN = IBAN.replace(/\s/g, "").match(/.{1,4}/g).join(" ");
    }

    return (<View style={{ width: '90%', alignSelf: 'center' }}>
        <ContentBorder
            separator
            contentStyle={{ paddingTop: 10 }}
            title={'$companyForm.finantial.FinantialData'}>
            <CardInput
                style={{ width: '100%', marginBottom: 20 }}
                title={"$companyForm.finantial.OwnerName"}
                icon={Icons.USER}
                onChangeText={_onValueChange('OwnerName')}
                keyboardType={keyboardType.default}
                autoCompleteType={undefined}
                textContentType={textContentType.name}           
                autoCapitalize={autoCapitalize.words}
                inputText={props.form.payment?.OwnerName}
                placeholder={'Richard Feynman'}
                errorText={props.errors?.payment?.OwnerName}
                returnKeyType={'next'}
                onSubmitEditing={() => ibanRef.current.focus()}
                infoText={'$companyForm.finantial.nameInfo'}
            />
            <CardInput
                ref={ibanRef}
                style={{ width: '100%' }}
                title={"$companyForm.finantial.IBAN"}
                icon={Icons.BANK}
                autoCapitalize={autoCapitalize.characters}
                onChangeText={_onValueChange('IBAN')}
                keyboardType={keyboardType.default}
                inputText={IBAN}
                placeholder={'Ex. ES91 2100 0418 ...'}
                errorText={props.errors?.payment?.IBAN}
                returnKeyType={'next'}
                infoText={'$companyForm.finantial.ibanInfo'}
            />
        </ContentBorder>
        <ContentBorder
            contentStyle={{ paddingTop: 10 }}
            title={'$companyForm.finantial.OwnerAddress'}>
            <View style={{
                width: '100%',
                alignSelf: 'center',
                marginBottom: 10
            }}>
                <SimpleCheckBox
                    value={locationHeadquartersChecked}
                    onValueChange={_onValueChange('ownerAddressHeadquartersChecked')}
                    onTextPressed={(rubish) => {
                        if (typeof props.onLocationHeadquartersChecked !== 'undefined') props.onLocationHeadquartersChecked()
                    }}
                    text={"$companyForm.finantial.ownerAddressHeadquartersChecked"} />

                <SimpleCheckBox
                    value={locationLegalRepresentativeChecked}
                    onValueChange={_onValueChange('ownerAddressLegalRepresentativeChecked')}
                    onTextPressed={(rubish) => {
                        if (typeof props.onLocationLegalRepresentativeChecked !== 'undefined') props.onLocationLegalRepresentativeChecked()
                    }}
                    text={"$companyForm.finantial.ownerAddressLegalRepresentativeChecked"} />

            </View>

            {!locationHeadquartersChecked && !locationLegalRepresentativeChecked &&
                <View>
                    <CardInput
                        ref={addressRef}
                        title={"$companyForm.common.Direction"}
                        icon={Icons.DIRECTIONS}
                        style={{width: '100%', marginBottom: 20}}
                        textContentType={textContentType.fullStreetAddress}
                        onChangeText={_onValueChange('OwnerStreet')}
                        keyboardType={keyboardType.default}
                        autoCompleteType={undefined}
                        inputText={typeof props.form.payment?.OwnerAddress?.AddressLine1 !== 'undefined' ? props.form.payment.OwnerAddress.AddressLine1 : ''}
                        placeholder={'Ex. Carrer del compte, 23, 1A'}
                        errorText={typeof props.errors?.payment?.OwnerAddress?.AddressLine1 === 'undefined' ? '' : props.errors.payment.OwnerAddress.AddressLine1}
                        returnKeyType={'next'}
                        onSubmitEditing={() => postalCodeRef.current.focus()}
                    />

                    <LocationInput
                        ref={postalCodeRef}
                        style={{ width: '100%', alignSelf: 'center' }}
                        value={typeof props.form.payment?.OwnerLocation !== 'undefined' ? props.form.payment.OwnerLocation : ''}
                        onError={(locationErrors) => {
                            if (typeof props.onLocationError !== 'undefined') props.onLocationError(locationErrors)
                        }}
                        errors={typeof props.errors?.payment?.OwnerLocation === 'undefined' ? '' : props.errors.payment.OwnerLocation}
                        onValueChange={_onValueChange('OwnerAddress')}
                    />
                </View>}
        </ContentBorder>
    </View>
    )
}

export const FinantialView = (props) => {
    const styles = updateStyles()

    let IBAN = props.form?.payment?.IBAN
    if (typeof IBAN !== 'undefined' && IBAN.length > 0) {
        IBAN = IBAN.replace(/\s/g, "").match(/.{1,4}/g).join(" ");
    }

    const _finantialCard = () => {
        return (
            <View style={{paddingLeft: 0, maxWidth: 600}}>
                <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, marginLeft: 10 }}>{"$companyForm.finantial.OwnerName"}</SimpleText>
                    {typeof props.form?.payment?.OwnerName !== 'undefined' && <SimpleText style={{ marginLeft: 15 }}>{props.form.payment.OwnerName}</SimpleText>}
                </View>

                <View style={{ flexDirection: 'column', justifyContent: 'space-between', paddingTop: 15 }}>
                    <SimpleText style={{ ...TextStyle.weight.bold, marginLeft: 10 }}>{"$companyForm.finantial.IBAN"}</SimpleText>
                    {typeof IBAN !== 'undefined' && <SimpleText style={{ marginLeft: 15 }}>{IBAN}</SimpleText>}
                </View>

            </View>
        )
    }

    return (
        <View>
            <SimpleText style={styles.directionText}>
                $companyForm.finantial.FinantialData
            </SimpleText>

            {<Card style={{ flex: 1, width: '90%', marginBottom: 20, marginTop: 10 }}>
                <View style={styles.listCard}>
                    {_finantialCard()}
                </View>
            </Card>}

        </View>
    )

}

const updateStyles = () => StyleSheet.create({
    directionText: {
        width: '80%',
        maxWidth: 600,
        alignSelf: 'center',
        ...TextStyle.size.xlarge,
        paddingTop: 10
    },
    separator: {
        width: '80%',
        alignSelf: 'center',
        borderBottomWidth: 3,
        paddingTop: 10,
        marginBottom: 10
    },
    date: {
        alignSelf: 'center',
        width: '100%',
        height: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 10,
        marginTop: 0
    },
    errorText: {
        color: AppStyle.errorColor,
        width: '100%',
        alignSelf: 'center',
        textAlign: 'center',
    },
    card: {
        backgroundColor: AppStyle.cardBackground,
        shadowRadius: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '100%',
        maxHeight: 50,
        paddingLeft: 5,
        paddingRight: 15,
        marginBottom: 0,
        borderRadius: 30,
    },
    listCard: {
        marginTop: 10,
        flexDirection: 'column',
        marginBottom: 20,
        padding: 5,
        width: '100%'
    },
});
const codes = require('./codes');
const components = require('./components');
const formValidator = require('./formValidator');
const common = require('./common');
import i18n from 'i18n-js';

String.prototype.localise = function (locale) {
    "use strict";
    var text = this.toString();
    if (typeof text !== 'string') {
        return
    }
    //Match keys following this pattern: ($hello $hello.hello ...)
    let pattern = /\$(\w+(\.|))*/gm
    if (typeof locale !== 'undefined') {
        return text.replace(pattern, (key) => i18n.t(key.slice(1), { locale: locale }))
    }
    return text.replace(pattern, (key) => i18n.t(key.slice(1)))
}

String.prototype.transFormat = String.prototype.transFormat ||
function () {
    "use strict";
    var str = this.toString().localise();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
            let val = typeof args[key] == 'string' ? args[key].localise() : args[key]
            if (val == null || typeof val === 'undefined') val = ''
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), val);
            str.localise();
        }
    }

    return str;
};


export const translations = (language) => {
    return {
        common: {
            ...common[language],
            codes: {
                ...codes[language]
            },
            components: {
                ...components[language]
            },
            formValidator : {
                ...formValidator[language]
            }
        }
    }
}
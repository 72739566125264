import React from 'react';
import {
    StyleSheet,
    View
} from 'react-native';

import { ButtonText, SimpleText } from '@quarks'
import { AppStyle, TextStyle } from '@style'

export function ContentBorder(props) {

    const styles = updateStyle(props.backgroundColor ? props.backgroundColor : 'transparent')


    return (
        <View style={[styles.borderStyle, props.style]}>
            <View style={[{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }, props.titleViewStyle]}>
                {typeof props.title != 'undefined' && <SimpleText style={[styles.titleStyle, props.titleStyle]}>{props.title.localise().toUpperCase()}</SimpleText>}
                {props.headerButtonOnPress && <ButtonText
                    style={{
                        zIndex: 10,
                        marginLeft:10,
                        padding: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                        alignSelf: 'flex-end'
                    }}
                    type={'secondary'}
                    icon={props.headerButtonIcon}
                    text={props.headerButtonText}
                    onPress={props.headerButtonOnPress}
                />}
            </View>
            <View style={props.contentStyle}>
                {props.children}
            </View>
            {props.separator && <View style={{
                width: '100%', alignSelf: 'center',
                borderColor: AppStyle.textColorLight,
                borderBottomWidth: 0.5, height: 10, marginTop: 10, marginBottom: 5
            }}></View>}
        </View>
    );
}

let updateStyle = (backgroundColor) => {
    return StyleSheet.create({
        titleStyle: {
            color: AppStyle.textColorLight,
            ...TextStyle.weight.bold,
            backgroundColor: backgroundColor,
            padding: 5
        },
        borderStyle: {
            paddingBottom: 10
        }

    });
}
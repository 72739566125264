import React, { useState } from 'react';

import { View, Keyboard, StyleSheet, ActivityIndicator, LayoutAnimation, UIManager } from 'react-native';
import { Icons } from '@icons'

import { SimpleText } from '@quarks';
import { AppStyle, TextStyle } from '@style';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Location, Producer } from '@api';
import { CardInput, keyboardType, autoCompleteType, textContentType, autoCapitalize } from '@molecules'
import { replaceLocale } from '@utilities/locale';

export const StreetInput = React.forwardRef((props, ref) => {
    /*
        props.value must have at least postal_code, place_name and admin_name2 fields
    */

    let innerRef = React.useRef(null)

    React.useImperativeHandle(ref, () => innerRef.current, [])

    let [form, setForm] = useState({
        name: props.value?.name,
        flat: props.value?.flat,
    })

    const _onValueChange = (field) => (value) => {
        let newForm = { ...form }
        newForm[field] = value
        setForm(newForm)
        if (props.onValueChange) props.onValueChange(newForm)
    }

    return (<View style={[{ alignSelf: 'center', width: '90%' }, props.style]}>
        <CardInput
            ref={innerRef}
            style={{ marginBottom: 10, width: '100%' }}
            title={'$common.components.streetInput.street'}
            icon={Icons.DIRECTIONS}
            onChangeText={_onValueChange('name')}
            autoFocus={props.autoFocus}
            keyboardType={keyboardType.default}
            autoCompleteType={autoCompleteType.streetAddress}
            autoCapitalize={autoCapitalize.words}
            textContentType={textContentType.streetAddressLine1}
            placeholder={'$common.components.streetInput.streetPlaceHolder'}
            returnKeyType={'next'}
            inputText={form.name}
            maxLength={150}
            errorText={props.errors?.name}
        />
        <CardInput
            style={{ marginBottom: 10 }}
            title={'$common.components.streetInput.flat'}
            icon={'door-open'}
            isOptional={true}
            onChangeText={_onValueChange('flat')}
            keyboardType={keyboardType.default}
            autoCompleteType={autoCompleteType.streetAddress}
            textContentType={textContentType.streetAddressLine2}
            placeholder={'$common.components.streetInput.flatPlaceHolder'}
            returnKeyType={'next'}
            maxLength={100}
            inputText={form.flat}
            errorText={props.errors?.flat}
        />

    </View>
    )
})
import * as types from "./types";

export const saveProduct = (content) => ( {
    type: types.ON_SUBMIT,
    db: true,
    form: content,
} );

export const resetForm = () => ( {
    type: types.RESET_FORM,
} );

export const setProduct = (product) => ( {
    type: types.SET_PRODUCT,
    product: product
})
export const errorForm = (errors) => ( {
    type: types.ON_SUBMIT_FAILURE,
    errors:errors,
} );

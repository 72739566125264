//
// External module imports
//
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import NetInfo from "@react-native-community/netinfo";
import { useDispatch, useSelector } from 'react-redux';
import { Image, Pressable, useWindowDimensions } from 'react-native';
//
// Own imports
//

import AccessNavigation from './access';
import MainNavigation from './main';
import InitialNavigation from './initial';
import { useAuth } from '@realm'
import { AppState, LayoutAnimation, View, Dimensions } from 'react-native';
import { AppStyle } from '@style';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { sessionOperations } from '_ducks/session';
import { DrawerActions, NavigationContainer } from '@react-navigation/native';
import { ButtonText } from '@quarks'
import UserInfoScreen from '_scenes/account/userInfo';
import { APP_ICONS, CATEGORIES } from '@images'

import { BlurModal, LoadingView, Banner } from '@atoms';
import { AlertView } from '@molecules';

const RootStack = createStackNavigator();

function RootStackScreen(props) {
  return (
    <RootStack.Navigator
      initialRouteName={props.initialRouteName}>
      <RootStack.Screen
        name="Inital"
        component={InitialNavigation}
        options={{ headerShown: false }}
      />
      <RootStack.Screen
        name="Login"
        component={AccessNavigation}
        options={{ headerShown: false }}
      />
      <RootStack.Screen
        name="Main"
        component={MainNavigation}
        options={({ navigation }) => ({
          headerTitle: () => (
            Dimensions.get('window').width > 500 ?
              <Image //2492 - 200 x 968  
                resizeMode={'contain'}
                style={{ height: 120, width: 150 }}
                source={APP_ICONS.logoHorizontal}
              />
              :
              <Image //2492 - 200 x 968  
                resizeMode={'contain'}
                style={{ height: 40, width: 40, marginLeft: -10 }}
                source={APP_ICONS.logoBase}
              />
          ),
          headerLeft: Dimensions.get('window').width <= 768 && (() => (
            <ButtonText
              icon={'bars'}
              iconSize={18}
              iconColor={AppStyle.mainColor}
              onPress={() => {
                navigation.dispatch(DrawerActions.toggleDrawer())
              }}
              style={{ alignSelf: 'center', paddingLeft: 30 }}
            />)),
        })}
      />
    </RootStack.Navigator>
  );
}

const AlertModal = (props) => {

  const dispatch = useDispatch()

  return <BlurModal visible={props.showModal}
    style={{ maxWidth: 400, alignSelf: 'center', width: '97%' }}
    onClose={() => {
      dispatch(sessionOperations.toggleAlertModal(undefined))
    }}>
    <AlertView />
  </BlurModal>
}


const UserModal = (props) => {

  const dispatch = useDispatch()

  return <BlurModal
    closeButton visible={props.showModal}
    onClose={() => {
      dispatch(sessionOperations.toggleUserModal(undefined))
    }}>
    <UserInfoScreen />
  </BlurModal>
}

const RootNavigation = (props) => {

  let config = LayoutAnimation.create(300, 'easeIn', 'opacity')
  const appState = React.useRef(AppState.currentState);
  const dispatch = useDispatch()
  const insets = useSafeAreaInsets();
  const modals = useSelector(state => state.session.modals)

  const { user, loading, fetchCurrentUser } = useAuth();
  const [netInfo, setNetInfo] = React.useState(undefined)

  let initialRouteName = typeof props.initialRouteName === 'undefined' ? 'Login' : props.initialRouteName

  if (initialRouteName === 'Login' && user != null && typeof user !== 'undefined' && Object.keys(user).length != 0) {
    initialRouteName = 'Main'

    if (typeof user.producer === 'undefined' || user.producer == null) {
      initialRouteName = 'Login'
    }
  }

  React.useEffect(() => {
    if (user != null && typeof user !== 'undefined' &&
      typeof user.producer !== 'undefined') {
      fetchCurrentUser()
    }
  }, [user]);

  const _handleAppStateChange = (nextAppState) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      console.log('checking refetch')
      dispatch(sessionOperations.refetchMainQueries())
    }
    appState.current = nextAppState;
  }

  React.useEffect(() => {
    AppState.addEventListener("change", _handleAppStateChange);

    const unsubscribe = NetInfo.addEventListener(state => {

      if (netInfo && !netInfo.isInternetReachable && state.isInternetReachable) {
        LayoutAnimation.configureNext(config);
        dispatch(sessionOperations.refetchMainQueries(true))
      }

      setNetInfo(state)
    });

    return () => {
      AppState.removeEventListener("change", _handleAppStateChange);
      unsubscribe();
    };
  }, [netInfo]);

  return (
    <View style={{ flex: 1, backgroundColor: AppStyle.backgroundColor }}>
      {netInfo && (typeof netInfo.isInternetReachable === 'boolean' &&
        netInfo.isInternetReachable == false) && <Banner
          style={{
            alignSelf: 'center', top: insets.top,
            position: 'absolute', zIndex: 100000,
          }}
          type={'warning'}
          textStyle={{ textAlign: 'center' }}
          numberOfLines={10}
          message={'$root.noInternet'} />}
      {modals.alert && <AlertModal showModal={typeof modals.alert !== 'undefined'} />}
      {modals.user && <UserModal showModal={typeof modals.user !== 'undefined'} />}
      <RootStackScreen {...props} initialRouteName={initialRouteName} />
    </View>
  )
};


export default RootNavigation;


import React from 'react';
import { View, Dimensions } from 'react-native';
import { Icon } from '@quarks'

import { TextStyle, AppStyle } from '@style';
import { SimpleText } from '@quarks';
import { Icons } from '@icons';

export const ErrorView = (props) => {

    if (typeof props.disableConsole === 'undefined' && typeof props.error !== 'undefined') {
        console.warn(props.error)
    }
    let errorText = props.errorText
    if (typeof errorText === 'undefined') {
        errorText = '$common.errors.unknown'
    }

    if (props.error?.networkError != null) {
        errorText = props.networkErrorText ? props.networkErrorText : '$common.components.errorView.networkErrorText'
    }

    return (
        <View style={[{
            alignSelf: 'center',
            textAlign: 'center',
        }, props.style]}>
            <View style={{ flexDirection: props.horizontal ? 'row' : 'column' }}>
                <Icon
                    name={Icons.ERROR}
                    size={50}
                    color={AppStyle.textColor}
                    style={{ textAlign: 'center', alignSelf: 'center' }} />
                <SimpleText style={{
                    ...TextStyle.size.xxlarge, paddingLeft: props.horizontal ? 20 : 0,
                    textAlign: 'center', alignSelf: 'center'
                }}>{props.text ? props.text : '$common.components.errorView.title'}</SimpleText>
            </View>
            {typeof errorText !== 'undefined' && <SimpleText style={{
                paddingTop: 10, paddingBottom: 10,
                textAlign: 'center', alignSelf: 'center', minWidth: 200, maxWidth: 220
            }}>{errorText}</SimpleText>}
        </View>
    )
};

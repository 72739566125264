import { gql } from '@apollo/client';
import { getTranslationQuery } from '@utilities/locale'

const getById = (id, mainLocale) => {
    return {
        query: gql`
            query  {
                product (query: {_id: "${id}"}) {
                    _id
                    images {
                        list
                    }
                    allergens
                    labels
                    ${getTranslationQuery('description', false, true, mainLocale)}
                }
                
            }`,
    }
}

const getByProducerId = (offset = 0, limit = 20, producerId, categoryId, productsIds) => {
    
    return {
        query: gql`
            query ($offset: Int, $limit: Int, $producer: String, $category: String, $products: [String]) {
                ProductsByProducer(input: {limit: $limit, offset: $offset, producer: $producer, category: $category, products: $products})  {
                    _id
                    amount
                    amountType
                    amountMax
                    amountUnit
                    price
                    priceType
                    salePrice
                    priceUnit
                    packUnits
                    stock
                    category
                    version
                    images {
                        stared
                        list
                    }
                    ${getTranslationQuery('name')}
                }
            }`,
        param: {
            variables: { offset: offset, limit: limit, producer: producerId, category: categoryId, products: productsIds }
        }
    }
}


// Query used in producer app
const getByIdProducer = (id) => {
    return {
        query: gql`
            query {
                product (query: {_id: "${id}"}) {
                    _id                    
                    amount
                    amountType
                    amountMax
                    amountUnit
                    weight
                    weightUnit
                    price
                    priceType
                    salePrice
                    priceUnit
                    packUnits
                    stock
                    category
                    version
                    labels
                    images {
                        stared
                        list
                    }
                    ${getTranslationQuery('name description', true)}
                    isDisabled
                    ownMade
                }
                
            }`,
        name: `Product-getByIdProducer-${id}`
    }
}

// Query used in producer app
const getFromProducerId = (offset = 0, limit = 20) => {
    return {
        query: gql`
            query ($offset: Int, $limit: Int) {
                ProductsProducer(input: {limit: $limit, offset: $offset})  {
                    _id
                    amount
                    amountType
                    amountMax
                    amountUnit
                    weight
                    weightUnit
                    price
                    priceType
                    salePrice
                    priceUnit
                    packUnits
                    stock
                    category
                    images {
                        stared
                    }
                    version
                    ${getTranslationQuery('name')}
                    isDisabled
                }
            }`,
        param: {
            variables: { offset: offset, limit: limit }
        },
        name: 'Product-getFromProducerId'
    }
}

const autocompleteProduct = () => {
    return {
        query: gql`
            query ($input: String, $locale: String, $skip: Float, $limit: Float) {
                autocompleteSearch (input: {input: $input, locale: $locale, skip: $skip, limit: $limit}) {
                    ${getTranslationQuery('name')}
                }
            }`,
        makeParam: (input, locale, skip, limit) => ({ variables: { input: input, locale: locale, skip: skip, limit: limit } })
    }
}

const searchProductAggregatedAtProducer = () => {
    return {
        query: gql`    
            query ($input: String, $locale: String, $skip: Float, $limit: Float, $city:String, $country:String, $province:String, $region:String) {
            searchProduct (input: {input: $input, locale: $locale, skip: $skip, limit: $limit, city:$city, country:$country, province:$province, region:$region}) {
                result {
                _id
                deliveryPrice
                freeDelivery
                name
                categories {
                    categoryId
                }
                images{
                    profile
                    cover
                },
                productsIds,
                products {
                    _id
                    amount
                    amountType
                    amountMax
                    amountUnit
                    price
                    priceType
                    salePrice
                    priceUnit
                    packUnits
                    stock
                    producer {
                        _id
                    }
                    ${getTranslationQuery('name description')}
                    images{
                        stared
                        list
                    }
                }
                }
            }
            }`,
        makeParam: ({ input: input, locale: locale, skip: skip, limit: limit, city: city, province: province, region: region, country: country }) => ({ variables: { input: input, locale: locale, skip: skip, limit: limit, city: city, province: province, region: region, country: country } })
    }
}

const insertProduct = () => {
    return {
        mutation: gql`    
            mutation (
                $version: Int, $translations: ProductTranslationInsertInput, $priceUnit: ProductPriceUnit, $amountUnit: ProductAmountUnit!, $isDisabled: Boolean, $images: ProductImageInsertInput, $labels: [ObjectId], $allergens: [String], $packUnits: Float, $category: ObjectId, $producer: ProductProducerRelationInput, $isDeleted: Boolean, $ownMade: Boolean, $amountMax: Float, $amount: Float!, $amountType: ProductAmountType, $margin: Float, $_id: ObjectId, $weight: Float, $price: Float!, $salePrice: Float, $stock: Float!, $priceType: ProductPriceType, $weightUnit: ProductWeightUnit

            ) {
            productInsert (input: {
                version: $version, translations: $translations, priceUnit: $priceUnit, amountUnit: $amountUnit, isDisabled: $isDisabled, images: $images, labels: $labels, allergens: $allergens, packUnits: $packUnits, category: $category, producer: $producer, isDeleted: $isDeleted, ownMade: $ownMade, amountMax: $amountMax, amount: $amount, amountType: $amountType, margin: $margin, _id: $_id, weight: $weight, price: $price, salePrice: $salePrice, stock: $stock, priceType: $priceType, weightUnit: $weightUnit

            }) {
                status
                message
                formErrors
            }
            }`,
        makeParam: ({ version, translations, priceUnit, amountUnit, isDisabled, images, labels,allergens, packUnits, category, producer, isDeleted, ownMade, amountMax, amount, amountType, margin, _id, weight, price, salePrice, stock, priceType, weightUnit
        }) => 
        ({ variables: { 
            version: version, translations: translations, priceUnit: priceUnit, amountUnit: amountUnit, isDisabled: isDisabled, images: images, labels: labels, allergens: allergens, packUnits: packUnits, category: category, producer: producer, isDeleted: isDeleted, ownMade: ownMade, amountMax: amountMax, amount: amount, amountType: amountType, margin: margin, _id: _id, weight: weight, price: price, salePrice: salePrice, stock: stock, priceType: priceType, weightUnit: weightUnit
         } })
    }
}


const updateProduct = () => {
    return {
        mutation: gql`    
            mutation (
                $margin_inc: Float, $weight: Float, $images: ProductImageUpdateInput, $category: ObjectId, $stock: Float, $packUnits: Float, $isDisabled: Boolean, $price: Float, $priceType_unset: Boolean, $packUnits_unset: Boolean, $version_inc: Int, $stock_unset: Boolean, $salePrice_unset: Boolean, $margin_unset: Boolean, $priceUnit: ProductPriceUnit, $_id: ObjectId, $price_inc: Float, $version: Int, $labels_unset: Boolean, $amount_unset: Boolean, $translations: ProductTranslationUpdateInput, $margin: Float, $weightUnit: ProductWeightUnit, $images_unset: Boolean, $price_unset: Boolean, $amountType_unset: Boolean, $weight_unset: Boolean, $category_unset: Boolean, $labels: [ObjectId], $version_unset: Boolean, $translations_unset: Boolean, $_id_unset: Boolean, $amountType: ProductAmountType, $amountMax_inc: Float, $salePrice_inc: Float, $priceType: ProductPriceType, $salePrice: Float, $allergens_unset: Boolean, $amountMax: Float, $isDisabled_unset: Boolean, $ownMade: Boolean, $ownMade_unset: Boolean, $weightUnit_unset: Boolean, $weight_inc: Float, $producer_unset: Boolean, $isDeleted: Boolean, $stock_inc: Float, $amountUnit: ProductAmountUnit, $allergens: [String], $amount: Float, $amountUnit_unset: Boolean, $amountMax_unset: Boolean, $priceUnit_unset: Boolean, $amount_inc: Float, $isDeleted_unset: Boolean, $producer: ProductProducerRelationInput, $packUnits_inc: Float                
            ) {
            productUpdate (input: {
                margin_inc: $margin_inc,
                weight: $weight,
                images: $images,
                category: $category,
                stock: $stock,
                packUnits: $packUnits,
                isDisabled: $isDisabled,
                price: $price,
                priceType_unset: $priceType_unset,
                packUnits_unset: $packUnits_unset,
                version_inc: $version_inc,
                stock_unset: $stock_unset,
                salePrice_unset: $salePrice_unset,
                margin_unset: $margin_unset,
                priceUnit: $priceUnit,
                _id: $_id,
                price_inc: $price_inc,
                version: $version,
                labels_unset: $labels_unset,
                amount_unset: $amount_unset,
                translations: $translations,
                margin: $margin,
                weightUnit: $weightUnit,
                images_unset: $images_unset,
                price_unset: $price_unset,
                amountType_unset: $amountType_unset,
                weight_unset: $weight_unset,
                category_unset: $category_unset,
                labels: $labels,
                version_unset: $version_unset,
                translations_unset: $translations_unset,
                _id_unset: $_id_unset,
                amountType: $amountType,
                amountMax_inc: $amountMax_inc,
                salePrice_inc: $salePrice_inc,
                priceType: $priceType,
                salePrice: $salePrice,
                allergens_unset: $allergens_unset,
                amountMax: $amountMax,
                isDisabled_unset: $isDisabled_unset,
                weightUnit_unset: $weightUnit_unset,
                weight_inc: $weight_inc,
                producer_unset: $producer_unset,
                stock_inc: $stock_inc,
                amountUnit: $amountUnit,
                allergens: $allergens,
                amount: $amount,
                amountUnit_unset: $amountUnit_unset,
                amountMax_unset: $amountMax_unset,
                priceUnit_unset: $priceUnit_unset,
                amount_inc: $amount_inc,
                isDeleted: $isDeleted,
                isDeleted_unset: $isDeleted_unset,
                ownMade: $ownMade,
                ownMade_unset: $ownMade_unset,
                producer: $producer,
                packUnits_inc: $packUnits_inc        
                
            }) {
                status
                message
                formErrors
                
            }
            }`,
        makeParam: ({ 
            margin_inc,
            weight,
            images,
            category,
            stock,
            packUnits,
            isDisabled,
            price,
            priceType_unset,
            packUnits_unset,
            version_inc,
            stock_unset,
            salePrice_unset,
            margin_unset,
            priceUnit,
            _id,
            price_inc,
            version,
            labels_unset,
            amount_unset,
            translations,
            margin,
            weightUnit,
            images_unset,
            price_unset,
            amountType_unset,
            weight_unset,
            category_unset,
            labels,
            version_unset,
            translations_unset,
            _id_unset,
            amountType,
            amountMax_inc,
            salePrice_inc,
            priceType,
            salePrice,
            allergens_unset,
            amountMax,
            isDisabled_unset,
            weightUnit_unset,
            weight_inc,
            producer_unset,
            isDeleted,
            stock_inc,
            amountUnit,
            allergens,
            amount,
            amountUnit_unset,
            amountMax_unset,
            priceUnit_unset,
            amount_inc,
            isDeleted_unset,
            ownMade,
            ownMade_unset,
            producer,
            packUnits_inc
         }) => 
        ({ variables: { 
            margin_inc: margin_inc,
            weight: weight,
            images: images,
            category: category,
            stock: stock,
            packUnits: packUnits,
            isDisabled: isDisabled,
            price: price,
            priceType_unset: priceType_unset,
            packUnits_unset: packUnits_unset,
            version_inc: version_inc,
            stock_unset: stock_unset,
            salePrice_unset: salePrice_unset,
            margin_unset: margin_unset,
            priceUnit: priceUnit,
            _id: _id,
            price_inc: price_inc,
            version: version,
            labels_unset: labels_unset,
            amount_unset: amount_unset,
            translations: translations,
            margin: margin,
            weightUnit: weightUnit,
            images_unset: images_unset,
            price_unset: price_unset,
            amountType_unset: amountType_unset,
            weight_unset: weight_unset,
            category_unset: category_unset,
            labels: labels,
            version_unset: version_unset,
            translations_unset: translations_unset,
            _id_unset: _id_unset,
            amountType: amountType,
            amountMax_inc: amountMax_inc,
            salePrice_inc: salePrice_inc,
            priceType: priceType,
            salePrice: salePrice,
            allergens_unset: allergens_unset,
            amountMax: amountMax,
            isDisabled_unset: isDisabled_unset,
            weightUnit_unset: weightUnit_unset,
            weight_inc: weight_inc,
            producer_unset: producer_unset,
            isDeleted: isDeleted,
            stock_inc: stock_inc,
            amountUnit: amountUnit,
            allergens: allergens,
            amount: amount,
            amountUnit_unset: amountUnit_unset,
            amountMax_unset: amountMax_unset,
            priceUnit_unset: priceUnit_unset,
            amount_inc: amount_inc,
            isDeleted_unset: isDeleted_unset,
            ownMade: ownMade,
            ownMade_unset: ownMade_unset,
            producer: producer,
            packUnits_inc: packUnits_inc
         } })
    }
}

export default {
    getFromProducerId,
    getByProducerId,
    getByIdProducer,
    getById,
    autocompleteProduct,
    searchProductAggregatedAtProducer,
    insertProduct,
    updateProduct
}


